<!-- ConfirmDialog -->
<p-confirmDialog
  key="itemArchive"
  [style]="{ minWidth: '350px' }"
  header="{{ 'WARNING.CONFIRMATION' | translate }}"
  icon="pi pi-exclamation-triangle"
  rejectButtonStyleClass="p-button-outlined"
></p-confirmDialog>

<div class="grid">
  <div class="col-12 pr-0 pl-0">
    <p-table
      #ia
      [globalFilterFields]="['name']"
      [value]="archivedItems"
      responsiveLayout="block"
      breakpoint="200px"
    >
      >
      <ng-template pTemplate="caption">
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            size="100"
            class="inputfield w-full"
            pInputText
            type="text"
            (input)="ia.filterGlobal($event.target.value, 'contains')"
            placeholder="Search"
          />
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            {{ "COMMON.NAME" | translate
            }}
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="archivedDate" style="width: 130px">
            {{ "ARCHIVE.ARCHIVED" | translate
            }}
            <p-sortIcon field="archivedDate"></p-sortIcon>
          </th>
          <th style="width: 70px">
            <span class="flex justify-content-center">
              {{ "#" }}
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr (click)="undo(item)">
          <td>{{ item.name }}</td>
          <td>{{ item.archivedDate | date: "dd.MM.YYYY" }}</td>
          <td>
            <span
              *ngIf="itemDeviationsMap.has(item.key)"
              class="flex justify-content-end"
            >
              <i
                class="far fa-exclamation-triangle mr-1"
                style="color: #f5a623"
              ></i>
              {{ itemDeviationsMap.get(item.key)?.length }}
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{ "WARNING.NO_DATA" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
