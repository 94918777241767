import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmergencyComponent } from './emergency/emergency.component';
import {Route} from '@angular/router';
import {CarouselModule} from 'primeng/carousel';
import { ExposureComponent } from './exposure/exposure.component';
import {ButtonModule} from 'primeng/button';
import {TranslateModule} from '@ngx-translate/core';
import {CardModule} from 'primeng/card';
import { EmergencyAddComponent } from './emergency-add/emergency-add.component';
import { EmergencyEditComponent } from './emergency-edit/emergency-edit.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {DropdownModule} from "primeng/dropdown";
import {TableModule} from "primeng/table";
import {TabViewModule} from "primeng/tabview";
import {AutoCompleteModule} from "primeng/autocomplete";
import {PipesModule} from "../../pipes/pipes.module";
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import { EmergencyAddExposuresComponent } from './emergency-add-exposures/emergency-add-exposures.component';
import {RippleModule} from 'primeng/ripple';
import { EmergencyAddOvertimeComponent } from './emergency-add-overtime/emergency-add-overtime.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { AddEvaluationComponent } from './add-evaluation/add-evaluation.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {MenuModule} from 'primeng/menu';
import {DataViewModule} from 'primeng/dataview';
import { ExposureAddComponent } from './exposure-add/exposure-add.component';
import { EmergencyReadComponent } from './emergency-read/emergency-read.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import {PanelModule} from 'primeng/panel';
import { AddRolesComponent } from './add-roles/add-roles.component';
import { ExposureReadComponent } from './exposure-read/exposure-read.component';
import { ExposureEditComponent } from './exposure-edit/exposure-edit.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {CanActivateService} from '../../services/can-activate.service';
import { EmergencyTypesComponent } from './emergency-types/emergency-types.component';

export const EventRoutes: Route = {
  path: 'events', canActivate: [CanActivateService],
  children: [
    {path: 'emergency', component: EmergencyComponent},
    {path: 'exposure', component: ExposureComponent}
  ]
};

@NgModule({
  declarations: [
    EmergencyComponent,
    ExposureComponent,
    EmergencyAddComponent,
    EmergencyEditComponent,
    EmergencyAddExposuresComponent,
    EmergencyAddOvertimeComponent,
    AddEvaluationComponent,
    ExposureAddComponent,
    EmergencyReadComponent,
    AddRolesComponent,
    ExposureReadComponent,
    ExposureEditComponent,
    EmergencyTypesComponent,
  ],
    imports: [
        CommonModule,
        CarouselModule,
        ButtonModule,
        TranslateModule,
        CardModule,
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        DropdownModule,
        TableModule,
        TabViewModule,
        AutoCompleteModule,
        PipesModule,
        ToastModule,
        ReactiveFormsModule,
        CalendarModule,
        CheckboxModule,
        MultiSelectModule,
        RippleModule,
        ConfirmDialogModule,
        SplitButtonModule,
        MenuModule,
        DataViewModule,
        SelectButtonModule,
        PanelModule,
        PipesModule,
        OverlayPanelModule
    ]
})
export class EventsModule { }
