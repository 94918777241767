<div class="grid">
  <div class="col-12">
    <p-table [value]="relevantAttestations" dataKey="key" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'EXERCISE.EXERCISE' | translate}}</th>
          <th>{{'COMMON.VALID_FROM' | translate}}</th>
          <th>{{'COMMON.VALID_TO' | translate}}</th>
          <th style="width: 3rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-attestation let-expanded="expanded">
        <tr>
          <td>{{attestation.type}}</td>
          <td>{{getDate(attestation.validFrom)}}</td>
          <td>{{getDate(attestation.validTo)}}</td>
          <td>
            <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    [pRowToggler]="attestation"
                    class="p-button-text p-button-rounded p-button-plain"
                    pButton
                    type="button">
            </button>
          </td>
        </tr>
      </ng-template>
<!--      <ng-template pTemplate="rowexpansion" let-product>-->
<!--        <tr>-->

<!--      </ng-template>-->
  </p-table>
  </div>
</div>
