<div class="grid p-fluid">
  <div class="col-12">
      <input id="float-input" type="text" pInputText [(ngModel)]="item.name" placeholder="{{'COMMON.NAME' | translate}}*">
  </div>

  <div class="col-12">
      <textarea  [style]="{maxHeight: '140px'}" rows="6" cols="30" [(ngModel)]="item.comment" pInputTextarea autoResize="autoResize" placeholder="{{'COMMON.COMMENT' | translate}}"></textarea>
  </div>

  <div class="col-12 footer">
      <span class="p-buttonset float-right">
      <button pRipple pButton type="button" label="{{'BUTTON.CLOSE' | translate}}" class="p-button-outlined"
              (click)="close()"></button>
      <button pButton type="button" [disabled]="item.name.length < 1" label="{{'BUTTON.SAVE' | translate}}"
              (click)="save(item)" class="p-button-raised"></button>
    </span>
  </div>
</div>
