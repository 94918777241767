import {Component, OnDestroy, OnInit} from '@angular/core';
import {FunctionModel} from "../../../model/function";
import {FunctionService} from "../../../services/service/function.service";
import {BehaviorSubject, Subscription} from 'rxjs';
import {DialogService} from "primeng/dynamicdialog";
import {AddFunctionComponent} from "../add-function/add-function.component";
import {TranslateService} from "@ngx-translate/core";
import {EditFunctionComponent} from "../edit-function/edit-function.component";
import {PersonService} from "../../../services/service/person.service";

import {FunctionPersonDetailComponent} from "../function-person-detail/function-person-detail.component";

@Component({
  selector: 'app-functions',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss'],
  providers: [DialogService]
})
export class FunctionsComponent implements OnInit, OnDestroy {
  functions: FunctionModel[] = [];
  personNameMap = new Map();
  personCompetenciesMap = new Map();

  loading = true;

  functionSubject = new BehaviorSubject(null);
  personCompetenciesSubject = new BehaviorSubject(null);

  subscription = new Subscription();

  constructor(
    private functionsService: FunctionService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private personService: PersonService,
  ) {
    this.subscription.add(
      this.functionsService.getFunctions().subscribe(functions => {
        this.functions = functions.map(f => {
          return {key: f.key, ...f.payload.val()};
        });
        this.functionSubject.next(this.functions);
        this.loading = false;
      })
    );

    this.subscription.add(
      this.personService.getPersons().subscribe(persons => {
        this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
      })
    );
  }

  ngOnInit(): void {

  }

  addFunction() {
    this.dialogService.open(AddFunctionComponent, {
      header: this.translateService.instant('FUNCTION.ADD_FUNCTION'),
      styleClass: 'max-size-width-dialog'
    });
  }

  editFunction(functionData) {
    this.dialogService.open(EditFunctionComponent, {
      header: this.translateService.instant('FUNCTION.EDIT_FUNCTION') + ' : ' + functionData.name,
      styleClass: 'max-size-dialog',
      data: {
        key: functionData.key
      }
    });
  }

  getObjectSize(o) {
    return (o) ? Object.keys(o).length : 0;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  checkIfValid(key, functionData) {
    const ct = Object.keys(functionData.competencyTypes);
    const personValidCompetency = this.personCompetenciesMap.get(key).filter(c => ct.includes(c.competencyTypeId));
    return null;
  }

  getStatus(valid) {
    if(valid){
      return {'color' : '#73BF21'};
    } else {
      return {'color' : '#F5A623'};
    }
  }

  personDetail(fKey: any, pKey: any) {
    this.dialogService.open(FunctionPersonDetailComponent, {
      header: this.personNameMap.get(pKey),
      styleClass: 'max-size-dialog',
      data: {
        pKey: pKey,
        fKey: fKey,
      }
    });
  }

  // getRepeatStatus(subjects: any) {
  //   if (subjects){
  //     Object.values(subjects).forEach(s => {
  //       console.log(s);
  //       // if (s.competencyTypes){
  //       //   s.competencyTypes.forEach(entry =>{
  //       //     if (entry.status === "REPEAT_EXPIRING" || entry.status === "REPEAT_EXPIRED"){
  //       //       return {'color' : '#F5A623'};
  //       //     }
  //       //   });
  //       // } else {
  //       //   return {'color' : '#73BF21'};
  //       // }
  //     });
  //   } else {
  //     return {'color' : '#73BF21'};
  //   }
  // }
  //
  // getCount(competencyTypes: any) {
  //
  // }
}
