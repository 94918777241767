import { Component, OnInit } from '@angular/core';
import {FileData, FileTag, FileTagRelation} from '../../../model/fileRelated';
import {Observable, Subscription} from 'rxjs';
import {FileService} from '../../../services/service/file.service';
import {HttpClient} from '@angular/common/http';
import {FileStorageService} from '../../../services/service/file-storage.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/service/auth.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {take} from 'rxjs/operators';
import * as moment from 'moment';
import {Md5} from 'ts-md5';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-exercise-file-upload',
  templateUrl: './exercise-file-upload.component.html',
  styleUrls: ['./exercise-file-upload.component.scss'],
  providers: [ConfirmationService]
})
export class ExerciseFileUploadComponent implements OnInit {

  fileName: string;
  file: File;
  adminOnly = false;

  tagRelations: FileTagRelation[];
  fileTags: FileTag[];
  files: FileData[];

  subscription = new Subscription();

  stateOptions: any[];
  description = "";

  uploadProgress: Observable<number>;

  uploading = false;
  progress = 0;

  constructor(
      private fileService: FileService,
      private http: HttpClient,
      private fileStorageService: FileStorageService,
      private translateService: TranslateService,
      private authService: AuthService,
      private ref: DynamicDialogRef,
      private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit(): void {
    this.stateOptions = [{label: this.translateService.instant('FILE.ALL'), value: false},
      {label: this.translateService.instant('FILE.ADMIN_ONLY'), value: true}];

  }

  /**
   *
   */
  confirm(filename: string, fileData: FileData) {
    this.confirmationService.confirm({
      message: this.translateService.instant('FILE.EXISTS_EXERCISE'),
      accept: () => {
        this.ref.close([Md5.hashStr(this.fileName).toString(), fileData]);
      },
      reject: () => {
        this.uploading = false;
      }
    });
  }

  /**
   * Upload new file and add file-info / tag-relation to tenant
   */
  upload(){

    this.uploading = true;
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      const extensionIndex = this.fileName.indexOf('.');
      const fileData: FileData = {
        admin: this.adminOnly,
        exerciseFile: true,
        archived: false,
        createdById: uid,
        date: moment().toISOString(),
        description: this.description,
        extension: this.fileName.substring(extensionIndex),
        name: this.fileName,
        progress: 0,
        restrictions: '',
        url: ''
      };

        this.subscription.add(this.fileStorageService.checkIfFileExist(Md5.hashStr(this.fileName).toString())
            .pipe(take(1)).subscribe(result =>{
              if (result.length > 0){
                this.confirm(Md5.hashStr(this.fileName).toString(), fileData);
              }else{
                this.uploadProgress = this.fileStorageService.uploadNewExerciseFile(this.file, this.adminOnly, fileData);
                this.uploadProgress.subscribe(value => {
                  if (value === 100){
                    this.ref.close([Md5.hashStr(this.fileName).toString(), fileData]);
                  }
                });
              }
            })
        );
    });
  }

  /**
   * On file select set file value and fileName
   * @param $event
   */
  onFileSelected($event) {
    this.file = $event.target.files[0];
    this.fileName = this.file.name;
  }
}
