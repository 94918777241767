import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exerciseParticipantsFilter'
})
export class ExerciseParticipantsFilterPipe implements PipeTransform {

  transform(output, participantMap){
    const copyOutput = JSON.parse(JSON.stringify(output));
    const filteredOutput = [];
    copyOutput.forEach(team => {
      if(team.items){
        team.items = team.items.filter(person => !participantMap.has(person.value));
        if(team.items.length > 0){
          filteredOutput.push(team);
        }
      }
    });
    return filteredOutput;
  }

}
