<div class="grid">
  <div class="col-12">
    <div class="grid flex justify-content-end">
      <div class=" col-3 w-max ">
        <button class="p-button-raised" pButton type="button" (click)="evalInfo(exercise)"
                label="{{'EXERCISE.EVALUATION.EVALUATION' | translate}}"></button>
      </div>
      <div class=" col-2 w-max ">
        <button class="p-button-raised" pButton type="button" label="{{'FILE.FILES' | translate}}"
                (click)="viewFiles(exercise)"></button>
      </div>
    </div>
  </div>
  <!-- Description -->
  <div class="field col-12 mt-2 mb-2">
          <span class="p-float-label">
            <textarea id="textarea"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      class="w-full"
                      pInputTextarea
                      readonly
                      [ngModel]="exercise.details"
            >
            </textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
          </span>
  </div>
  <!-- Participant table -->
  <div class="col-12 flex w-full">
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="participants"
             dataKey="key"
    >
      <ng-template pTemplate="header" class="w-full">
        <tr>
          <th>{{'EXERCISE.PARTICIPANTS' | translate}}</th>
          <th style="width: 4rem">
            <span class="flex justify-content-center">
              <i class="far fa-graduation-cap"></i>
            </span>
          </th>
          <th style="width: 8rem">
            <span class="flex justify-content-center">
              {{'EXERCISE.ABSENCE' | translate}}
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-participant let-expanded="expanded">
        <tr [pRowToggler]="participant">
          <td>{{participant.name}}</td>
          <td>
            <div class="flex justify-content-center">
              {{participant.competencyCount}}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center">
              {{getAbsence(participant.absence)}}
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-participant>
        <tr>
          <td colspan="3">
            <!-- MOCK HEADER -->
            <div class="grid">
              <div class="col" style="background-color: #f8f9fa">
                {{'COMPETENCY.COMPETENCY' | translate}}
              </div>
              <div class=" flex justify-content-center" style="width: 8rem; background-color: #f8f9fa">
                {{'COMMON.APPROVED' | translate}}
              </div>
            </div>
            <ng-template ngFor let-competency [ngForOf]="competencyTypeArray">
              <div class="grid">
                <div class="col">{{this.competencyTypeMap.get(competency.type)}}</div>
                <div class="flex flex-wrap justify-content-center align-content-center" style="width: 8rem"
                     *ngIf="participant.competencyTypes.get(competency.type) === true; else elseBlock">
                  <i class="far fa-check" style="color: green"></i>
                </div>
                <ng-template #elseBlock>
                  <div class="flex flex-wrap justify-content-center align-content-center" style="width: 8rem">
                    <i class="far fa-times" style="color: red"></i>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
