import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompetencyService} from "../../../services/service/competency.service";
import {combineLatest, Observable, Subscription} from "rxjs";
import {SdaService} from "../../../services/service/sda.service";
import {map, take} from "rxjs/operators";
import {Competency} from "../../../model/competency";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {PersonService} from "../../../services/service/person.service";
import {SubjectService} from "../../../services/service/subject.service";
import {AccessControlService} from "../../../services/service/access-control.service";
import {DialogService} from 'primeng/dynamicdialog';
import {SetCompetenciesComponent} from '../set-competencies/set-competencies.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-competencies',
    templateUrl: './competencies.component.html',
    styleUrls: ['./competencies.component.scss'],
    providers: [DialogService]
})
export class CompetenciesComponent implements OnInit, OnDestroy {
    competencies: Competency[];
    subscription = new Subscription();
    groupedFilter = [];
    parentChildMap;

    isAdmin$: Observable<boolean>;
    isSuper$: Observable<boolean>;

    constructor(
        private competencyService: CompetencyService,
        private sdaService: SdaService,
        private competencyTypeService: CompetencyTypeService,
        private personService: PersonService,
        private subjectService: SubjectService,
        private acService: AccessControlService,
        private dialogService: DialogService,
        private translateService: TranslateService,
    ) {

        // TODO check for usage
        this.isAdmin$ = this.acService.isAdmin();
        this.isSuper$ = this.acService.isSuperUser();
      const competencyTypeMap = new Map();
        this.subscription.add(
            combineLatest([
                this.competencyTypeService.getCompetencyTypes(),
                this.personService.getPersons(),
                this.competencyService.getCompetencies(),
                this.sdaService.getActiveSDA()])
                .pipe(
                    map(([cts, persons, competencies, activeSDAs]) => {
                        // const competencyTypeMap = new Map();
                        const personIdMap = new Map();
                        const sdaMap = new Map();
                        cts.forEach(ct => {
                            if (ct.payload.val().archived === false
                                || ct.payload.val().archived === null
                                || ct.payload.val().archived === undefined) {
                                competencyTypeMap.set(ct.key, ct.payload.val().name);
                            }
                        });
                        persons.forEach(person => personIdMap.set(person.key, person.payload.val().name));
                        activeSDAs.forEach(sda => sdaMap.set(sda.subjectId + sda.competencyTypeId, true));

                        let activeCompetencies = competencies.filter(competency =>
                            !sdaMap.has(competency.personId + competency.competencyTypeId));

                        const result = [];
                        let status;

                        const aC = [];
                        activeCompetencies.forEach(c => {
                          if (competencyTypeMap.has(c.competencyTypeId)){
                            aC.push(c);
                          }
                        });
                        activeCompetencies = aC;

                        activeCompetencies.forEach(competency => {
                            let validDateIso;
                            if (!competency.valid && !competency.expiring) {
                                validDateIso = competency.expiredSince;
                                status = 1; // Expired
                            } else {
                                if (competency.expiring) {
                                    status = 2; // Expiring
                                } else {
                                    status = 3; // Valid
                                }
                                validDateIso = competency.validTo;
                            }
                            result.push({
                                ...competency,
                                competencyType: competencyTypeMap.get(competency.competencyTypeId),
                                person: personIdMap.get(competency.personId),
                                validDate: validDateIso,
                                status: status
                            });
                        });
                        return result;
                    }),
                ).subscribe(competencies => {
                    const cTemp = [];
                    competencies.forEach(c =>{
                        if (c.competencyType !== ""){
                            cTemp.push(c);
                        }
                    });
                this.competencies = cTemp;
                })
        );
    }


    ngOnInit(): void {
        this.subscription.add(
            combineLatest([
                this.subjectService.getStations(),
                this.subjectService.getTeams(),
                this.subjectService.getRelations(),
                this.subjectService.getAncestor(),
                this.acService.isSuperUser(),
            ]).subscribe(([stations, teams, relations, ancestors, isSuperuser]) => {
                const stationMap = new Map(stations.map(station => [station.key, station.payload.val().name]));
                const teamMap = new Map(teams.map(team => [team.key, team.payload.val().name]));
                this.parentChildMap = new Map();

                relations.forEach(relation => {
                    if (this.parentChildMap.has(relation.parentId)) {
                        const childArray = this.parentChildMap.get(relation.parentId);
                        childArray.push(relation.childId);
                        this.parentChildMap.set(relation.parentId, childArray);
                    } else {
                        const childArray = [];
                        childArray.push(relation.childId);
                        this.parentChildMap.set(relation.parentId, childArray);
                    }
                });
                if(isSuperuser){
                    this.personService.getOwnStation().pipe(
                        take(1),
                    ).subscribe(station => {
                        this.buildFilter(station.key, stationMap, teamMap);
                    });
                } else {
                    const ancestor = ancestors[0];
                    const ancestorChildren = this.parentChildMap.get(ancestor.key);

                    ancestorChildren.forEach(child => {
                        this.buildFilter(child, stationMap, teamMap);
                    });
                }
            })
        );
    }

    /**
     * Edit competencies
     */
    editCompetencies() {
        this.dialogService.open(SetCompetenciesComponent, {
            header: this. translateService.instant('BUTTON.ADD_COMPETENCY'),
            styleClass: "max-width-dialog",
            closable: false
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private buildFilter(parent, stationMap, teamMap) {
        if(stationMap.has(parent) || teamMap.has(parent)){
            const obj: any = {};
            if (stationMap.has(parent)) {
                obj.label = stationMap.get(parent);
                obj.type = 'FIRESTATION';
            } else if (teamMap.has(parent)) {
                obj.label = teamMap.get(parent);
                obj.type= 'FIRETEAM';
            }
            obj.value = parent;
            this.groupedFilter.push(obj);
            const children = this.parentChildMap.get(parent);
            if(children?.length > 0){
                children.forEach(child => this.buildFilter(child, stationMap, teamMap));
            }
        }

    }
}
