<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-circle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12">

    <div class="formgrid grid">
      <!-- Date -->
      <div class="field col-6 mt-3">
        <span class="p-float-label">
            <input type="text" id="date"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="exposure.date"
            >
            <label for="date">{{'COMMON.DATE.DATE' | translate}}</label>
        </span>
      </div>

      <!-- Select type -->
      <div class="col-6 mt-3">
    <span class="p-float-label">
            <input type="text" id="type"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="getType()">

            <label for="type">{{'EVENT.EXPOSURE' | translate}}</label>
        </span>
      </div>

      <!-- Time From -->
      <div class="field col-6 mt-3">
        <span class="p-float-label">
          <input type="text"
                 id="startTime"
                 pInputText
                 class="inputfield w-full"
                 readonly
                 [ngModel]="exposure.timeStart"
          >
          <label for="startTime">{{'COMMON.FROM' | translate}}</label>
        </span>
      </div>

      <!-- To Time -->
      <div class="field col-6 mt-3">
         <span class="p-float-label">
           <input type="text"
                  id="endTime"
                  pInputText
                  class="inputfield w-full"
                  readonly
                  [ngModel]="exposure.timeEnd"
           >
           <label for="endTime">{{'COMMON.TO' | translate}}</label>
         </span>
      </div>

      <!-- Address -->
      <div class="field col-12 mt-2">
        <div class="p-inputgroup">
          <span class="p-float-label">
            <input type="text" id="address"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="exposure.location.address"
            >
            <label for="address">{{'SUBJECTS.ADDRESS' | translate}}</label>
          </span>
        </div>
      </div>

      <!-- Zip -->
      <div class="field col-6 mt-2" *ngIf="exposure.location.zip">
          <span class="p-float-label">
            <input type="text" id="zip"
                   pInputTextarea
                   class="inputfield w-full"
                   [ngModel]="exposure.location.zip">
            <label for="zip">{{'SUBJECTS.ZIP' | translate}}</label>
          </span>
      </div>

      <!-- Postal -->
      <div class="field col-6 mt-2" *ngIf="exposure.location.postal">
          <span class="p-float-label">
              <input type="text" id="postal"
                     pInputTextarea
                     class="inputfield w-full"
                     readonly
                     [ngModel]="exposure.location.postal"
              >
            <label for="postal">{{'SUBJECTS.POSTAL' | translate}}</label>
          </span>
      </div>

      <!-- Description -->
      <div class="field col-12 mt-2 mb-2" *ngIf="exposure.details">
          <span class="p-float-label">
            <textarea id="textarea"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      class="w-full"
                      pInputTextarea
                      readonly
                      [ngModel]="exposure.details"
            >
            </textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
          </span>
      </div>

      <!-- Bris number -->
      <div class="field col-12 mt-3" *ngIf="exposure.brisNumber">
            <span class="p-float-label">
            <input type="text" id="bris"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="exposure.brisNumber">
            <label for="bris">{{'EVENT.BRIS' | translate}}</label>
          </span>
      </div>
    </div>

    <!-- Person table -->
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="personArray">

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'FDC.PERSONS' | translate}}</th>
          <th>
            <div *ngIf="exposure.exposureType === 'Sot' || exposure.exposureType === 'Royk' ">
                <span class="flex justify-content-center">
                  <i class="far fa-stopwatch"></i>
                </span>
            </div>
          </th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body" let-personId>
        <tr>
          <td>
            {{personNameMap.get(personId)}}
          </td>
          <td class="flex justify-content-center">
            <div *ngIf="exposure.exposureType === 'Sot' || exposure.exposureType === 'Royk'">
              {{printTime(personId)}}
            </div>
          </td>
        </tr>
      </ng-template>

      <!-- Shows message if table is empty -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">
            {{'WARNING.NO_DATA' | translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- Evaluation table -->
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="evalBool"

    >
      <!-- Header -->
      <ng-template pTemplate="header" class="w-full">
        <tr>
          <th>{{'COMMON.EVALUATION' | translate}}</th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body">
        <tr>
          <td>
            <div class="grid">
              <div class="col-12">
                  <span class="p-float-label mt-3" *ngIf="evaluation.description">
                    <textarea id="description" class="w-full" rows="5"
                              pInputTextarea [ngModel]="evaluation.description" readonly></textarea>
                    <label for="description">{{'COMMON.DESCRIPTION' | translate}}</label>
                  </span>
                <span class="p-float-label mt-4" *ngIf="evaluation.measures" >
                  <textarea id="measuresOnSite" class="w-full" rows="5"
                            pInputTextarea [ngModel]="evaluation.measures" readonly></textarea>
                  <label for="measuresOnSite">{{'EVENT.EVAL.MEASURES_ON_SITE' | translate}}</label>
                  </span>
                <span class="p-float-label mt-4" *ngIf="evaluation.learningPoints">
                    <textarea id="learningPoints" class="w-full" rows="5"
                              pInputTextarea [ngModel]="evaluation.learningPoints" readonly></textarea>
                    <label for="learningPoints">{{'EVENT.EVAL.LEARNING_POINTS' | translate}}</label>
                  </span>
                <span class="p-float-label mt-4" *ngIf="evaluation.various">
                    <textarea id="various" class="w-full" rows="5"
                              pInputTextarea [ngModel]="evaluation.various" readonly></textarea>
                    <label for="various">{{'EVENT.EVAL.VARIOUS' | translate}}</label>
                  </span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>

      <!-- Shows message if table is empty -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>
            {{'WARNING.NO_DATA' | translate}}
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>
