import { Pipe, PipeTransform } from '@angular/core';
import {FileTag} from '../model/fileRelated';

@Pipe({
  name: 'existingTagFilter'
})
export class ExistingTagFilterPipe implements PipeTransform {

  transform(tags: FileTag[],search?: string): any {
    if(!tags || !search){
      return tags;
    } else {
      return tags.filter(tag => tag.tagName.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
