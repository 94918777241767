<div class="grid">
  <div *ngIf="!fromDashboard" class="col-12 flex align-items-center justify-content-between">
    <h2 style="margin-top: 10px">{{'CHECKLIST.ONGOING' | translate}}</h2>
    <div class="justify-content-end">
      <button pButton (click)="addChecklist()" type="button" icon="pi pi-plus"
              class="p-button-rounded p-button-raised"></button>
    </div>
  </div>
  <div class="col-12 w-full banner flex justify-content-center" style="background-color: #16214D" *ngIf="fromDashboard">
    <h3 style="color: white" class="m-1">{{'CHECKLIST.LIST.ONGOING' | translate}}</h3>
  </div>
  <div class="col-12 pl-0 pr-0">
    <p-table #oct [value]="checklists | isoDateASC: 0 | dashboardFilter: 'checklist' : selectedStation"
             [rowHover]="true" [loading]="checklistLoading" [globalFilterFields]="['name']" breakpoint="200px"
             [rows]="rows" [showCurrentPageReport]="true" responsiveLayout="scroll"
             [paginator]="true"
             currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last}
                                         {{'TABLE.OF' | translate}} {totalRecords} {{'MAIN_FRAME.CHECKLISTS2' | translate}}"
    >
      <ng-template style="max-width: 100%" pTemplate="caption" *ngIf="!fromDashboard">
        <div class="formgrid grid">
          <div class="field col" style="min-width: 100px; max-width: 400px">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input class="inputfield w-full" size="100" pInputText type="text"
                     (input)="oct.filterGlobal($event.target.value, 'contains')"
                     placeholder="{{'COMMON.SEARCH' | translate}}"/>
            </div>
          </div>

          <div class="field col">
            <p-dropdown class="inputfield w-full" [options]="checklistFilter" [(ngModel)]="selectedStation"
                        placeholder="{{'COMMON.CHOOSE_STATION' | translate}}" optionLabel="name"
                        optionValue="key" [style]="{'width':'100%'}"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 40%" class="p-2">{{'COMMON.NAME' | translate}}</th>
          <th class="p-2">{{'ASSET.ASSET' | translate}}</th>
          <th class="p-2">{{'COMMON.DATE.DATE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-checklist>
        <tr (click)="onTableClick(checklist)">
          <td class="p-2"><span class="p-column-title">{{'COMMON.NAME' | translate}}:</span>{{checklist.name}}</td>
          <td [ngStyle]="setStatus(checklist)"><span class="p-column-title p-2">{{'ASSET.ASSET' | translate}}
            :</span>{{checklist.asset?.name}}</td>
          <td><span class="p-column-title p-2">{{'COMMON.DATE.DATE' | translate}}
            :</span>{{checklist.repeatDate | amDateFormat: 'DD.MM.YY'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'CHECKLIST.NO_CHECKLISTS' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
