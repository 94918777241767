import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {FileService} from '../../../services/service/file.service';
import {FileSetCategoryComponent} from '../file-set-category/file-set-category.component';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class FilesComponent implements OnInit, OnDestroy {


  subscription = new Subscription();
  archivedFiles: File[] = [];
  allArchivedFiles: File[] = [];

  loading = true;
  search: string;
  selectedFiles = [];
  constructor(
      private translateService: TranslateService,
      private messageService: MessageService,
      private dialogService: DialogService,
      private confirmationService: ConfirmationService,
      private fileService: FileService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.fileService.getAllArchivedFiles().subscribe(
        files => {
          this.allArchivedFiles = files.map(file => {
            return {key: file.key, ...file.payload.val()};
          }).sort((a, b) =>{
            return a.name.localeCompare(b.name);
          });
          this.archivedFiles = this.allArchivedFiles;
          this.loading = false;
        })

    );
  }

  /**
   * Restore File
   */
  restore(selectedFiles: any[]) {
    this.dialogService.open(FileSetCategoryComponent,{
      header: this.translateService.instant('FILE.CATEGORIES'),
      width: "500px",
      closable: false,
    }).onClose.subscribe(fileTags => {
      if (fileTags) {
        selectedFiles.forEach(file => {
          fileTags.forEach(fileTag =>{
            const fileTagRelation = {
              fileId: file.key,
              tagId: fileTag.key,
            };
            this.fileService.addFileTagRelation(fileTagRelation);
          });
          file.archived = false;
          const key = file.key;
          delete file.key;
          this.fileService.updateFile(key, file);
        });
      }
    });
  }

  /**
   * Confirm activate person.
   */
  confirm(person) {
    this.confirmationService.confirm({
      message: this.translateService.instant('ADMIN.USER_OVERVIEW_COMPONENT.ALERTS.REACTIVATE') + " " + person.name + "?",
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.restore(person);
      }
    });
  }

  /**
   * Unsubscribe on destroy.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * return boolean as word
   * @param admin boolean value
   */
  getAdmin(admin: boolean) {
    if (admin){
      return this.translateService.instant("CONFIRMATION.YES");
    } else {
      return this.translateService.instant("CONFIRMATION.NO");
    }
  }
}
