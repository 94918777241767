import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-emergency-add-overtime',
  templateUrl: './emergency-add-overtime.component.html',
  styleUrls: ['./emergency-add-overtime.component.scss'],
})
export class EmergencyAddOvertimeComponent implements OnInit {

  start: string;
  end: string;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
    this.start = this.config.data.start;
    this.end = this.config.data.end;
  }

  ngOnInit(): void {
  }

  /**
   * Save and close
   */
  save() {
    this.ref.close({start: this.start, end: this.end})
  }
}
