import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Observable, Subscription} from "rxjs";
import {ChecklistService} from "../../../services/service/checklist.service";
import {SubjectService} from "../../../services/service/subject.service";
import {Checklist} from "../../../model/checklist";
import {AssetService} from "../../../services/service/asset.service";
import {PersonService} from "../../../services/service/person.service";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {CommentModalComponent} from "../comment-modal/comment-modal.component";
import {EditItemComponent} from "../edit-item/edit-item.component";
import {ReadDeviationComponent} from "../../deviation/read-deviation/read-deviation.component";

@Component({
  selector: 'app-completed-checklist-detail',
  templateUrl: './completed-checklist-detail.component.html',
  styleUrls: ['./completed-checklist-detail.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class CompletedChecklistDetailComponent implements OnInit, OnDestroy {

  checklistId: string;

  subscription = new Subscription()
  checklist: Checklist;
  stationsMap: Map<string, string>;
  assetMap: Map<string, string>;
  deviationMap: Map<string, string> = new Map();

  name$: Observable<any>;
  splitMenu: MenuItem[];
  menuItemsMap = new Map();
  checklistItemDeviationMap: Map<any, any> = new Map();

  color = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private checklistService: ChecklistService,
    private subjectService: SubjectService,
    private assetService: AssetService,
    private personService: PersonService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {

    // INPUT checklistID
    this.checklistId = this.config.data.checklistId;

    this.checklistService.getChecklistDeviationsFromChecklistID(this.checklistId).subscribe(checklistDeviations => {
      if(checklistDeviations){
        this.deviationMap = new Map(checklistDeviations.map(deviation => [deviation.payload.val().itemId, true]));
      }
    })

    // Get checklist
    this.subscription.add(
      this.checklistService.getChecklist(this.checklistId).subscribe(checklist => {
        this.checklist = checklist;
        this.name$ = this.personService.getPersonName(checklist.completedById);

        if (checklist.items) {
          Object.keys(checklist.items).forEach(key => {
            this.menuItemsMap.set(key, this.buildMenuItem({key: key, ...checklist.items[key]}));
          });
        }
      })
    );

    // Get the deviations to the checklist and a map of which items that has deviation
    this.subscription.add(
      this.checklistService.getDeviationsMatchingChecklist(this.checklistId).subscribe(deviations => {
          this.checklistItemDeviationMap = new Map();
          deviations.forEach(deviation => {
            if (this.checklistItemDeviationMap.has(deviation.itemId)) {
              this.checklistItemDeviationMap.get(deviation.itemId).push(deviation.deviationId)
            } else {
              const deviationArray = [];
              deviationArray.push(deviation.deviationId);
              this.checklistItemDeviationMap.set(deviation.itemId, deviationArray);
            }
          })
        }
      )
    )

    // Get stations
    this.subscription.add(
      this.subjectService.getStations().subscribe(stations => {
        this.stationsMap = new Map(stations.map(station => [station.key, station.payload.val().name]));
      })
    );

    // Get assets
    this.subscription.add(
      this.assetService.getAssets().subscribe(assets => {
        this.assetMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
      })
    );

    this.splitMenu = [
      {
        label: this.translateService.instant('BUTTON.REMOVE'),
        icon: 'pi pi-trash',
        command: () => {
          this.delete()
        }
      },
    ]
  }

  ngOnInit(): void {}


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  undo() {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.UNDO'),
      accept: () => {
        this.checklist.completed = false;
        this.checklistService.updateChecklist(this.checklistId, this.checklist);
        this.ref.close();
      }
    })
  }

  delete() {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE'),
      accept: () => {
        this.checklistService.deleteChecklist(this.checklistId);
        this.ref.close();
      }
    })
  }

  getLength(comments): number {
    if (comments) {
      return Object.keys(comments).length;
    } else {
      return 0;
    }
  }

  /**
   * Open CommentModalComponent, Read or Crea
   */
  openComments() {
    this.dialogService.open(CommentModalComponent, {
      header: this.translateService.instant('COMMON.COMMENTS'),
      data: {
        id: this.checklistId,
        mode: 'checklist'
      },
      styleClass: 'max-size-sm-dialog-long'
    })
  }

  /**
   * Builds the MenuItem used for each item.
   * Changes depending on if the item has deviations or not
   *
   * @param item
   * @private
   */
  private buildMenuItem(item): MenuItem[] {

    if(this.deviationMap.has(item.key)){
      return [
        {
          label: item.name,
          items: [
            {
              label: this.translateService.instant('BUTTON.READ_MORE'),
              icon: 'pi pi-book',
              command: () => {
                this.editItem(item);
              }
            },
            {separator: true},
            {
              label: this.translateService.instant('CHECKLIST.READ_ABOUT_DEVIATIONS'),
              icon: 'pi pi-book',
              command: () => {
                this.readAboutDeviation(item);
              }
            },
          ]
        },
      ];
    } else {
      return [
        {
          label: item.name,
          items: [
            {
              label: this.translateService.instant('BUTTON.READ_MORE'),
              icon: 'pi pi-book',
              command: () => {
                this.editItem(item);
              }
            },
          ]
        }
      ];
    }

  }

  /**
   * Open EditItemComponent, used to edit an item.
   * @param item
   */
  editItem(item){
    this.dialogService.open(EditItemComponent, {
      header: item.name,
      data: {
        item: item,
        readOnly: true,
      },
      styleClass: 'max-size-sm-dialog'
    })
  }

  /**
   * Open ReadDeviationComponent if there exists one active deviation.
   *
   * @param item
   */
  private readAboutDeviation(item) {
    const idArray = this.checklistItemDeviationMap.get(item.key);
    if (!idArray) {
      this.messageService.add(
        {
          key: 'ccd',
          severity: 'info',
          detail: this.translateService.instant('ASSET.ASSET_CARD.NO_DEVIATION'),
          life: 4000
        });
    } else if (idArray.length > 1) {
      console.log('TODO: Deviation overview')
    } else {
      this.dialogService.open(ReadDeviationComponent, {
        header: item.name,
        data: {
          deviationId: idArray[0]
        },
        styleClass: 'max-size-width-dialog'
      })
    }
  }

  /**
   * Get the style color on the warning icon.
   *
   * Yellow: There is one or more active deviations
   * Green: There is no active deviations, but there exists closed deviation to this item
   *
   * @param item
   */
  getStyle(item) {
    if(this.checklistItemDeviationMap.has(item.key)){
      return {'color': '#F5A623'}
    } else {
      return {'color': '#73BF21'}
    }
  }
}

