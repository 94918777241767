import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {map, mergeMap, publish} from "rxjs/operators";
import {SubjectDisableAttestations} from "../../model/subjectDisableAttestation";

@Injectable({
  providedIn: 'root'
})
export class SdaService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getActiveSDA(): Observable<SubjectDisableAttestations[]>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/subjectDisableAttestations`, ref => {
          return ref.orderByChild('status').equalTo(true);
        }).valueChanges()
      )
    ) as Observable<SubjectDisableAttestations[]>;
  }

  getPersonActiveSDA(personId): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/subjectDisableAttestations`, ref => {
          return ref.orderByChild('activePersonId').equalTo(personId);
        }).snapshotChanges()
      )
    );
  }

  addSDA(sda: SubjectDisableAttestations) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/subjectDisableAttestations`).push(sda)
      )
    )).connect();
  }

  updateSDA(SDAId: string, newSDA: SubjectDisableAttestations) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/subjectDisableAttestations/${SDAId}`).set(newSDA)
        )
    )).connect();
  }
}
