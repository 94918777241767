import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import {Subscription} from 'rxjs';
import {ChecklistService} from '../../../services/service/checklist.service';
import {CronJob} from '../../../model/cronJob';
import {AssetService} from '../../../services/service/asset.service';
import {SubjectService} from '../../../services/service/subject.service';
import {Asset} from '../../../model/asset';
import {Station} from '../../../model/station';
import {Checklist} from '../../../model/checklist';
import {DeviationService} from '../../../services/service/deviation.service';
import {MenuItem, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {EditItemComponent} from '../edit-item/edit-item.component';
import {take} from 'rxjs/operators';
import {ItemDeviationDetailComponent} from '../item-deviation-detail/item-deviation-detail.component';
import {EditRepeatChecklistComponent} from '../edit-repeat-checklist/edit-repeat-checklist.component';
import {AddNewItemComponent} from '../add-new-item/add-new-item.component';
import {ItemArchiveComponent} from "../item-archive/item-archive.component";
import {AuthService} from "../../../services/service/auth.service";
import {NotificationDialogComponent} from "../notification-dialog/notification-dialog.component";

@Component({
  selector: 'app-checklist-repeat-detail',
  templateUrl: './checklist-repeat-detail.component.html',
  styleUrls: ['./checklist-repeat-detail.component.scss'],
  providers: [DialogService],
})
export class ChecklistRepeatDetailComponent implements OnInit, OnDestroy {
  // Variables
  asset: Asset;
  station: Station;
  cronJob: CronJob;
  cronJobs: CronJob[];
  repeatingChecklist: Checklist;
  rcId: string; // ChecklistId
  cjId: string; // CronJobId

  // Maps
  deviationMap: Map<string, boolean>;
  itemMenuMap: Map<string, MenuItem[]> = new Map();
  itemDeviationsMap: Map<string, any[]> = new Map();

  // Menu for Split Button
  splitItems: MenuItem[];

  // Holding the subscribes and unsubscribe onDestroy
  subscription = new Subscription();

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    public checklistService: ChecklistService,
    private assetService: AssetService,
    private subjectService: SubjectService,
    private deviationService: DeviationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    // Input Checklist Id.
    this.rcId = this.config.data.rcId;

    this.subscription.add(
      // Get Checklist
      this.checklistService.getChecklist(this.rcId).subscribe((rc) => {
        this.repeatingChecklist = rc;

        this.assetService
          .getAsset(this.repeatingChecklist.assetId)
          .subscribe((asset: Asset) => {
            this.asset = {key: this.repeatingChecklist.assetId, ...asset};
          });

        this.subjectService
          .getStation(this.repeatingChecklist.stationId)
          .subscribe((station: Station) => {
            this.station = station;
          });

        // Build MenuMap for Items if they exist.
        if (this.repeatingChecklist.items) {
          Object.entries(this.repeatingChecklist.items).forEach((entry) => {
            const key = entry[0];
            const item = entry[1];

            // Hold a map for the number of deviations a item has.
            this.checklistService.getDeviationsForItem(key).subscribe(deviations => {
              if (deviations.length > 0) {
                this.itemDeviationsMap.set(key, deviations.map(deviation => deviation['deviationId']));
              } else {
                this.itemDeviationsMap.delete(key);
              }
              this.itemMenuMap.set(key, this.buildMenu(key, item));
            })
          });
        }
      })
    );

    // Get CronJob to this Checklist.
    this.checklistService
      .getCronJobWithChecklistId(this.rcId)
      .subscribe((cj) => {
        this.cjId = cj[0]?.key;
        this.cronJob = cj[0]?.payload.val();

      });

    // Map that contains the assets with deviation.
    this.subscription.add(
      this.deviationService.getOpenDeviationsAsMap().subscribe((dMap) => {
        this.deviationMap = dMap;
      })
    );

    // Menu for SplitButton
    this.splitItems = [
      {
        label: this.translateService.instant('BUTTON.REPEAT_TEMPLATE'),
        icon: 'far fa-clone',
        command: () => {
          this.repeatToday();
        }
      },
      {
        label: this.translateService.instant('NOTIFICATIONS.NOTIFICATION'),
        icon: 'far fa-bell',
        command: () => {
          this.openNotification();
        }
      },
      {
        label: this.translateService.instant('BUTTON.NEW_ITEM'),
        icon: 'pi pi-plus-circle',
        command: () => {
          this.addNewItem();
        },
      },
      {
        label: this.translateService.instant('ARCHIVE.ARCHIVE'),
        icon: 'far fa-archive',
        command: () => {
          this.openArchive();
        }
      },
      {separator: true},
      {
        label: this.translateService.instant('BUTTON.REMOVE'),
        icon: 'pi pi-trash',
        command: () => {
          this.deleteChecklist()
        }
      },
    ];
  }

  /**
   * Unsubscribe onDestroy.
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setStatus(asset: Asset) {
    if (this.deviationMap) {
      return this.assetService.getAssetStatus(asset, this.deviationMap);
    }
  }

  changeState() {
    this.cronJob.pause = !this.cronJob.pause;
    this.checklistService.updateCronJob(this.cjId, this.cronJob);
  }

  edit() {
    this.dialogService.open(EditRepeatChecklistComponent, {
      header: this.repeatingChecklist.name,
      styleClass: 'max-size-width-dialog',
      data: {
        checklistId: this.rcId,
        checklist: this.repeatingChecklist,
        cronJobId: this.cjId,
        cronJob: this.cronJob,
      },
    }).onClose.subscribe((checklist) => {
      if (checklist) {
        // We have updated the checklist, have to close the module and open again to update header
        this.ref.close(checklist);
      }
    });
  };

  buildMenu(key, item): MenuItem[] {
    const menuItem: MenuItem[] = [];

    // EDIT
    menuItem.push({
      label: item.name,
      items: [
        {
          label: this.translateService.instant('BUTTON.EDIT'),
          icon: 'far fa-edit',
          command: () => {
            this.dialogService
              .open(EditItemComponent, {
                header: this.translateService.instant('BUTTON.EDIT'),
                styleClass: 'max-size-sm-dialog',
                data: {
                  item: item,
                },
              })
              .onClose.pipe(take(1))
              .subscribe((updatedItem) => {
                if (updatedItem) {
                  this.checklistService.updateChecklistItem(
                    this.repeatingChecklist.key,
                    key,
                    updatedItem
                  );
                  this.itemMenuMap.set(key, this.buildMenu(key, updatedItem));
                }
              });
          },
        },
      ],
    });

    // PAUSE OR ACTIVATE ITEM
    if (!item.pause) {
      menuItem[0].items.push({
        label: this.translateService.instant('BUTTON.PAUSE'),
        icon: 'far fa-pause',
        command: () => {
          item.pause = true;
          this.checklistService.updateChecklistItem(
            this.repeatingChecklist.key,
            key,
            item
          );
          this.itemMenuMap.set(key, this.buildMenu(key, item));
        },
      });
    } else {
      menuItem[0].items.push({
        label: this.translateService.instant('BUTTON.ACTIVATE'),
        icon: 'far fa-play',
        command: () => {
          item.pause = false;
          this.checklistService.updateChecklistItem(
            this.repeatingChecklist.key,
            key,
            item
          );
          this.itemMenuMap.set(key, this.buildMenu(key, item));
        },
      });
    }

    menuItem[0].items.push({
      label: this.translateService.instant('CHECKLIST.READ_ABOUT_DEVIATIONS'),
      icon: ' pi pi-book',
      command: () => {
        if (!this.itemDeviationsMap.has(key)) {
          this.messageService.add({
            key: 'crd',
            severity: 'info',
            detail: this.translateService.instant('CHECKLIST.NO_DEVIATIONS'),
            life: 4000,
          });
        } else {
          this.dialogService.open(ItemDeviationDetailComponent, {
            header:
              this.translateService.instant('MAIN_FRAME.DEVIATIONS') +
              ' : ' +
              item.name,
            styleClass: 'max-size-width-dialog',
            data: {
              deviations: this.itemDeviationsMap.get(key),
            },
          });
        }
      },
    });

    // Separator
    menuItem[0].items.push({
      separator: true
    });


    // Archive or Delete
    if (this.itemDeviationsMap.has(key)) {
      menuItem[0].items.push({
        label: this.translateService.instant('BUTTON.ARCHIVE'),
        icon: 'far fa-archive',
        command: () => {
          this.authService.getUserUID().pipe(
            take(1),
          ).subscribe(uid => {
            item.archive = true;
            item.pause = true;
            item.archivedBy = uid;
            item.archivedDate = new Date().toISOString();
            this.checklistService.archiveChecklistItem(this.rcId, key, item);
          });
        },
      });
    } else {
      menuItem[0].items.push({
        label: this.translateService.instant('BUTTON.REMOVE'),
        icon: 'pi pi-trash',
        command: () => {
          // Remove Item
          this.checklistService.deleteChecklistItem(this.rcId, key);
          this.itemDeviationsMap.delete(key);
        }
      });
    }

    return menuItem;
  }

  private addNewItem() {
    this.dialogService.open(AddNewItemComponent, {
      header: this.translateService.instant('CHECKLIST.ADD_ITEM.TITLE'),
      data: {
        checklistId: this.rcId,
      },
      styleClass: 'max-size-sm-dialog',
    });
  }

  private openArchive() {
    this.dialogService.open(ItemArchiveComponent, {
      header: this.translateService.instant('CHECKLIST.ARCHIVED_POINTS'),
      data: {
        checklistId: this.rcId,
        itemDeviationsMap: this.itemDeviationsMap
      },
      styleClass: 'max-size-width-dialog'
    })
  }

  private repeatToday() {

    this.authService.getUserUID().pipe(
      take(1),
    ).subscribe(uid => {

      if (!this.repeatingChecklist.items) {
        this.messageService.add({
          key: 'crd',
          severity: 'error',
          detail: this.translateService.instant('CHECKLIST.NO_ITEMS'),
          life: 4000,
        });
      } else {
        const checklist: Checklist = {
          name: this.repeatingChecklist.name,
          assetId: this.repeatingChecklist.assetId,
          stationId: this.repeatingChecklist.stationId,
          createdById: uid,
          createdDate: new Date().toISOString(),
          completed: false,
          repeatDate: new Date().toISOString(),
          items: this.removePausedAndArchived(this.repeatingChecklist.items),
        }

        this.checklistService.addChecklist(checklist);

        this.messageService.add({
          key: 'crd',
          severity: 'info',
          detail: this.translateService.instant('CHECKLIST.CHECKLIST_CREATED'),
          life: 4000,
        });
      }
    })
  }

  removePausedAndArchived(items) {
    return Object.fromEntries(Object.entries(items).filter(([key, value]: any) => {
      return !value.archive || !value.pause
    }));
  }

  private deleteChecklist() {
    if (this.repeatingChecklist.items) {
      const itemKeys = Object.keys(this.repeatingChecklist.items)
      if (itemKeys.some(key => this.itemDeviationsMap.has(key))) {
        this.messageService.add({
          key: 'crd',
          severity: 'error',
          detail: this.translateService.instant('CHECKLIST.WARNING.ERROR_DELETE'),
          life: 4000,
        });
      } else {
        this.checklistService.deleteChecklist(this.rcId);
        this.checklistService.deleteCronJob(this.cjId);
        this.ref.close();
      }
    } else {
      this.checklistService.deleteChecklist(this.rcId);
      this.checklistService.deleteCronJob(this.cjId);
      this.ref.close();
    }
  }

  private openNotification() {
    this.dialogService.open(NotificationDialogComponent, {
      header: this.translateService.instant('NOTIFICATIONS.NOTIFICATION'),
      styleClass: 'max-size-width-dialog',
      data: {
        cId: this.rcId,
      }
    })
  }
}
