<div class="grid">
  <div class="col-8 pb-3">
    <h2 style="margin-top: 10px; margin-left: 5px">{{'MAIN_FRAME.COMPETENCIES' | translate}}</h2>
  </div>
  <div class="col-4 pb-3 flex justify-content-end">
    <button pButton (click)="editCompetencies()" type="button" icon="pi pi-plus"
            class="p-button-rounded p-button-raised"></button>
  </div>
</div>


<p-blockUI [blocked]="!(groupedFilter.length > 0)"></p-blockUI>

<div class="grid">
  <div class="col-12 pr-0 pl-0" style="padding: 0" *ngIf="groupedFilter.length > 0">
    <app-competencies-list
      [competencies]="competencies"
      [groupedFilter]="groupedFilter"
      [parentChildMap]="parentChildMap"
    ></app-competencies-list>
  </div>
</div>


