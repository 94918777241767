import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {combineLatest, Subscription} from 'rxjs';
import {AddRolesComponent} from '../add-roles/add-roles.component';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, FilterService} from 'primeng/api';
import {ExposureService} from '../../../services/service/exposure.service';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {PersonService} from '../../../services/service/person.service';
import {AuthService} from '../../../services/service/auth.service';
import {RolesService} from '../../../services/service/roles.service';
import {SubjectService} from '../../../services/service/subject.service';
import {Roles, RoleType} from '../../../model/role';
import {Exposure} from '../../../model/exposure';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {LocationData} from '../../../model/locationData';
import {AddEvaluationComponent} from '../add-evaluation/add-evaluation.component';
import {EmergencyAddOvertimeComponent} from '../emergency-add-overtime/emergency-add-overtime.component';
import {ExposureEval} from '../../../model/exposureEval';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';

@Component({
  selector: 'app-exposure-add',
  templateUrl: './exposure-add.component.html',
  styleUrls: ['./exposure-add.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class ExposureAddComponent implements OnInit, OnDestroy{

  // Exposure
  exposureForm: UntypedFormGroup;
  exposureTypes: any[]; // {key: "Royk", label: Røyk}

  // Subscription
  subscription = new Subscription();

  // Person
  personMap = new Map();
  personNameMap = new Map<string, string>();
  outputPerson = []; // [{items: {label: Per Person, value: personId}, label: Station 1 Team 1, value: stationId}]
  personArray = []; // [name-1, name-2, ..., name-n]
  selectedPerson: '';

  // Teams
  groupedSubjectFilter = [];
  teamNameMap = new Map();
  parentChildMap = new Map();
  personParentNameMap = new Map();

  // Roles
  isRoles = false;
  roleTypes: RoleType[];
  roleMap: Map<string, string[]> = new Map<string, string[]>();
  selectedRolesMap: Map<string, string[]> = new Map<string, string[]>();

  // Evaluation
  evaluation = new Map<string, string>();

  // Event time space
  workTimeMap = new Map<string, {hours, minutes}>();
  timeMap = new Map<string, {start, end}>();
  timePeriodChange = new Map<string, boolean>();

  constructor(
    private exposureService: ExposureService,
    private evaluationService: EvaluationService,
    private personService: PersonService,
    private filterService: FilterService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private authService: AuthService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private roleService: RolesService,
    private subjectService: SubjectService,
    private gSService: GroupedSubjectsService
  ) {

    this.exposureTypes = exposureService.getExposureTypes();

    this.subscription.add(
      combineLatest([
        this.personService.getPersons(),
        this.subjectService.getTeams(),
        this.subjectService.getRelations(),
      ]).subscribe(([persons, teams, relations]) => {

        const data = this.gSService.build(persons, teams, relations);
        this.personNameMap = data[0];
        this.teamNameMap = data[1];
        this.personParentNameMap = data[2];
        this.groupedSubjectFilter = data[3];
      })
    );

    // Get roles
    this.subscription.add(
      this.roleService.getRoleTypes().subscribe( roles => {
        this.roleTypes = roles.map(role => {
          return {key: role.key, ...role.payload.val()};
        });
      })
    );

  }

  ngOnInit(): void {

    this.exposureForm = new UntypedFormGroup({
      brisNumber: new UntypedFormControl(''),
      date: new UntypedFormControl(new Date(), Validators.required),
      details: new UntypedFormControl(''),
      exposureType: new UntypedFormControl('', Validators.required),
      timeStart: new UntypedFormControl('', Validators.required),
      timeEnd: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.required),
      zip: new UntypedFormControl(''),
      postal: new UntypedFormControl(''),
    });

  }

  /**
   * Add team to table
   * @param team Team array
   */
  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  /**
   * Add person to table
   * @param subject Input person
   */
  addPerson(subject) {
    if (!this.personMap.has(subject.value)) {
      // For work hours
      this.workTimeMap.set(subject.value, this.getHoursAndMin(this.exposureForm.value.timeStart, this.exposureForm.value.timeEnd));
      this.timeMap.set(subject.value, {start: this.exposureForm.value.timeStart, end: this.exposureForm.value.timeEnd});
      this.timePeriodChange.set(subject.value, false);

      this.personMap.set(subject.value, true);
      this.personArray.push(subject.value);
      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a).toLowerCase().localeCompare(this.personNameMap.get(b).toLowerCase());
      });
      this.selectedPerson = '';
    }
  }

  /**
   * Remove person from table
   * @param personId person to remove
   */
  deletePerson(personId: string) {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE') + ':  <br/>' + this.personNameMap.get(personId),
      accept: () => {
        this.personMap.delete(personId);
        const index = this.personArray.findIndex(pId => pId === personId);
        if(index >= 0) {
          this.personArray.splice(index, 1);
        }
      }
    });
  }

  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Get hours and minutes from moment
   * @param s Start moment
   * @param e End moment
   */
  getHoursAndMin(s, e){

    if (s && e) {

      const start = moment(this.exposureForm.value.date).add(s);
      const end = moment(this.exposureForm.value.date).add(e);

      if (end.diff(start) < 0) {
        end.add(1, 'days');
      }
      return {hours: end.diff(start, "hours"), minutes: end.diff(start, "minutes") % 60};

    } else {
      return {hours: 0, minutes: 0};
    }
  }

  /**
   * Display exposed time in hours and minutes.
   * @param personId Unique person
   */
  printTime(personId: string) {

    const hours = this.workTimeMap.get(personId).hours;
    const minutes = this.workTimeMap.get(personId).minutes;

    if (hours !== 0){
      if (minutes !== 0){
        return hours + this.translateService.instant('EVENT.HOUR') + " " + minutes + this.translateService.instant('EVENT.MINUTES');
      } else {
        return hours + this.translateService.instant('EVENT.HOUR');
      }
    } else {
      return minutes + this.translateService.instant('EVENT.MINUTES');
    }

  }

  /**
   * Add exposed time interval.
   * @param personId Unique person
   */
  exposureTime(personId: string) {
    if (this.exposureForm.value.exposureType === 'Sot' || this.exposureForm.value.exposureType === 'Royk') {
      let s;
      let e;
      if (this.timePeriodChange.get(personId)) {
        s = this.timeMap.get(personId).start;
        e = this.timeMap.get(personId).end;
      } else {
        s = this.exposureForm.value.timeStart;
        e = this.exposureForm.value.timeEnd;
      }

      this.dialogService.open(EmergencyAddOvertimeComponent, {
        header: this.personNameMap.get(personId) + ': ' + this.translateService.instant('EVENT.CHANGE_EXPOSURETIME'),
        width: "300px",
        data: {
          start: s,
          end: e
        }
      }).onClose.subscribe(time => {
        if (time) {
          const date = this.exposureForm.value.date;
          this.workTimeMap.set(personId, this.getHoursAndMin(moment(date).add(time.start), moment(date).add(time.end)));
          this.timeMap.set(personId, {start: time.start, end: time.end});
          this.timePeriodChange.set(personId, true);
        }
      });
    }
  }

  /**
   * Add roles to persons
   */
  addRoles() {
    this.dialogService.open(AddRolesComponent, {
      header: this.translateService.instant('ROLES.ADD_ROLE'),
      styleClass: 'max-size-width-dialog',
      data: {
        personArray: this.personArray,
        personNameMap: this.personNameMap,
        selectedRolesMap: this.selectedRolesMap,
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.roleMap = data[0];
        this.selectedRolesMap = data[1];

        this.selectedRolesMap.forEach(personRoles => {
          if (personRoles.length > 0){
            this.isRoles = true;
          }
        });
      }
    });
  }

  /**
   * Add evaluations
   */
  addEvaluation() {
    this.dialogService.open(AddEvaluationComponent, {
      header: this.translateService.instant('COMMON.EVALUATE'),
      styleClass: 'max-size-width-dialog',
      data: {
        evaluation: this.evaluation,
        fromExpo: true,
      }
    }).onClose.subscribe(evaluation => {
      if (evaluation) {
        this.evaluation = evaluation;
      }
    });
  }

  /**
   * Submit form
   */
  onSubmit() {

    const today = moment().toISOString(true);
    const ongoing = this.exposureForm.value;

    const locationData: LocationData = {
      address: ongoing.address,
      postal: ongoing.postal,
      zip: ongoing.zip
    };

    const participants = [];
    this.personArray.forEach(personKey => {

      const roles = [];
      for(const [roleKey, list] of this.roleMap){
        list.forEach(pKey => {
          if (pKey === personKey){
            roles.push(roleKey);
          }
        });
      }

      participants.push({
        key: personKey,
        newData: true,
        exposureEnd: this.timeMap.get(personKey).end,
        exposureStart: this.timeMap.get(personKey).start,
        roles: roles
      });

    });

    const dateMoment = moment(ongoing.date).format("DD.MM.YYYY");

    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

      const exposure: Exposure = {
        brisNumber: ongoing.brisNumber,
        createdById: uid,
        date: dateMoment,
        dateCreated: today,
        details: ongoing.details,
        exposureType: ongoing.exposureType,
        location: locationData,
        participants: participants,
        timeEnd: ongoing.timeEnd,
        timeStart: ongoing.timeStart
      };

      if (!this.isRoles && this.evaluation.size === 0){
        this.exposureService.addExposure(exposure);
      } else {
        let exposureKey = "";
        this.exposureService.addExposureGetKey(exposure).pipe(take(1)).subscribe(exKey => {
          exposureKey = exKey;
          if (this.isRoles){
            this.addRolesToDB(exposureKey, dateMoment, today);
          }
          if (this.evaluation.size > 0){
            const exposureEval: ExposureEval = {
              createdById: uid,
              dateCreated: dateMoment,
              exposureId: exposureKey,
              description: this.evaluation.get('description'),
              measures: this.evaluation.get('measuresOnSite'),
              learningPoints:	this.evaluation.get('learningPoints'),
              various: this.evaluation.get('various'),
            };
            this.evaluationService.addExposureEvaluationGetKey(exposureEval).pipe(take(1)).subscribe(
              evalKey => this.updateExposure(evalKey, exposureKey));
          } else {
            this.exposureService.addExposure(exposure);
          }

        });
      }

    });

    this.ref.close();
  }

  /**
   * Updating exposure with evaluations key.
   * @param evaluationKey Unique evaluations key
   * @param exposureKey Unique exposure key
   */
  updateExposure(evaluationKey, exposureKey) {
    this.subscription.add(
      this.exposureService.getExposure(exposureKey).subscribe(exposure => {
        exposure.evaluationId = evaluationKey;
        this.exposureService.updateExposure(exposureKey, exposure);
      })
    );
  }

  /**
   * Add all selected roles to DB
   * @param emergencyKey EmergencyId
   * @param date Date for event
   * @param today Date created
   */
  addRolesToDB(emergencyKey, date, today){
    for(const [key, list] of this.roleMap){
      list.forEach(person => {
        const role: Roles = {
          shiftReport: false, eventKey: emergencyKey, dateCreated: today, date: date, personKey: person, typekey: key
        };
        this.roleService.addRole(role);
      });
    }
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
