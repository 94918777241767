<div class="grid">

  <ng-container *ngIf="!fromDashboard">
    <ng-container *ngIf="!isUser; else withoutButton">
    <div  class="col-6">
      <h2 style="margin-top: 10px">{{'EXERCISE.PLANNED' | translate}}</h2>
    </div>
    <div class="col-6 flex justify-content-end">
      <button pButton (click)="addExercise()" type="button" icon="pi pi-plus"
              class="p-button-rounded p-button-raised"></button>
    </div>
    </ng-container>
    <ng-template #withoutButton>
      <div  class="col-12">
        <h2 style="margin-top: 10px">{{'EXERCISE.PLANNED' | translate}}</h2>
      </div>
    </ng-template>
  </ng-container>
  <div class="col-12 w-full banner flex justify-content-center" style="background-color: #16214D" *ngIf="fromDashboard">
    <h3 style="color: white" class="m-1">{{'EXERCISE.MY_EXERCISE' | translate}}</h3>
  </div>

  <!-- EXERCISE TABLE -->
  <div class="col-12 pl-0 pr-0">
    <p-table #et [globalFilterFields]="['name']" [loading]="exerciseLoading"
             [rowHover]="true" [value]="exercises | dashboardFilter : 'exercise' : selectedExerciseFilter?.key"
             breakpoint="200px" dataKey="key"
             [rows]="rows" [showCurrentPageReport]="true" responsiveLayout="scroll"
             [paginator]="true" currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'TABLE.EXERCISE' | translate}}">
      <ng-template pTemplate="caption" class="pt-5" *ngIf="!fromDashboard">
        <div class="grid">
          <div class="col-8 pl-0">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                      (input)="et.filterGlobal($event.target.value, 'contains')"
                      pInputText
                      placeholder="{{'COMMON.SEARCH' | translate}}"
                      type="text"/>
            </span>
          </div>
          <div class="col-4 pl-0">
            <!-- Filter dropdown -->
            <p-dropdown [(ngModel)]="selectedExerciseFilter" [options]="exerciseFilter" [style]="{'width':'100%'}"
                        class="ml-auto" optionLabel="name" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}">
              <ng-template pTemplate="selectedItem">
                <div class="exercise-item exercise-item-value" *ngIf="selectedExerciseFilter">
                  <div>{{selectedExerciseFilter.name | translate}}</div>
                </div>
              </ng-template>
              <ng-template let-filter pTemplate="item">
                <div class="filter-item">
                  <div>{{filter.name | translate}}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

      </ng-template>
      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <ng-container *ngIf="!isUser">
            <th style="width: 3rem">
              <div class="flex justify-content-center">
                <i class="far fa-cog"></i>
              </div>
            </th>
          </ng-container>
          <ng-container *ngIf="!isUser; else limitAccess">
            <th style="width: 40%" class="p-2">
              {{'COMMON.NAME' | translate}}
            </th>
          </ng-container>
          <ng-template #limitAccess>
            <th style="width: 40%" class="p-2" colspan="2">
              {{'COMMON.NAME' | translate}}
            </th>
          </ng-template>
          <th class="p-2">
            {{'COMMON.DATE.DATE' | translate}}
          </th>
          <th class="p-2">
            {{'COMMON.TIME' | translate}}
          </th>
          <th style="width: 5rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-exercise let-expanded="expanded">
        <tr>
          <ng-container *ngIf="!isUser">
            <td class="p-2">
              <div class="flex justify-content-center">
                <button [icon]="'far fa-cog'" class="p-button-text p-button-rounded p-button-plain"
                        pButton type="button" (click)="exerciseDetails(exercise)">
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="!isUser; else limitAccess">
            <td class="p-2">
              {{exercise.name}}
            </td>
          </ng-container>
          <ng-template #limitAccess>
            <td class="p-2" colspan="2">
              {{exercise.name}}
            </td>
          </ng-template>
          <td class="p-2">
            {{exercise.date | amDateFormat: 'DD.MM.YY'}}
          </td>
          <td class="p-2">
            {{exercise.date | amDateFormat: 'HH:mm'}}
          </td>
          <td class="p-2">
            <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                    [pRowToggler]="exercise" class="p-button-text p-button-rounded p-button-plain"
                    pButton type="button">
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-e>
        <tr>
          <td colspan="5">
            <div class="grid col-12 p-3">
              <div class="field mb-0 mt-2 w-full">
                <span class="p-float-label">
                  <textarea [(ngModel)]="e.details"
                            class="inputfield w-full"
                            cols="30"
                            disabled
                            id="details"
                            pInputTextarea
                            rows="4"
                            style="resize: none;"
                  ></textarea>
                  <label for="details">{{'COMMON.DESCRIPTION' | translate}}</label>
                </span>
              </div>
              <div class="field mb-0 mt-4 w-full">
                <span class="p-float-label">
                  <textarea [(ngModel)]="e.evaluation.goal"
                            class="inputfield w-full"
                            cols="30"
                            disabled
                            id="goal"
                            pInputTextarea
                            rows="4"
                            style="resize: none;"
                  ></textarea>
                  <label for="goal">{{'EXERCISE.EVALUATION.GOAL' | translate}}</label>
                </span>
              </div>
              <!-- Competency MOCK table -->
              <div [style]="{'height' : '55px'}"
                   class="col-12 flex flex-wrap align-content-center mockHeader mt-3">
                {{'COMPETENCY.CONNECTED_TO_EXERCISE' | translate}}
              </div>
              <ng-container *ngIf="cTLength(e.competencyTypes) > 0; else noCompetencies">
                <div *ngFor="let competencyId of templateCTMap.get(e.key); index as i"
                     [style]="{'height' : '55px'}"
                     class="col-12 flex flex-wrap align-content-center mockRow">
                  {{ctNameMap.get(competencyId)}}
                </div>
              </ng-container>
              <ng-template #noCompetencies>
                <div [style]="{'height' : '55px'}" class="col-12 flex flex-wrap align-content-center mockRow">
                  {{'COMMON.NO_COMPETENCY' | translate}}
                </div>
              </ng-template>

              <!-- Files MOCK table -->
              <div [style]="{'height' : '55px'}"
                   class="col-8  flex flex-wrap align-content-center mockHeader mt-3">
                {{'FILE.ATTACHED_FILES' | translate}}
              </div>
              <div [style]="{'height' : '55px'}"
                   class="col-4 flex flex-wrap align-content-center justify-content-end mockHeader mt-3">
                {{'FILE.DOWNLOAD' | translate}}
              </div>
              <ng-container *ngIf="filesLength(e.exerciseFiles) > 0; else noFiles">
                <div *ngFor="let fileID of e.exerciseFiles; index as i" [style]="{'height' : '55px'}"
                     class="col-12 flex flex-wrap align-content-center mockRow">
                  <div class="col-8 p-0">
                    {{fileNameMap.get(fileID)}}
                  </div>
                  <div class="col-4 p-0 flex justify-content-end" (click)="open(fileID)">
                    <i class="far fa-download"></i>
                  </div>
                </div>
              </ng-container>
              <ng-template #noFiles>
                <div [style]="{'height' : '55px'}" class="col-12 flex flex-wrap align-content-center mockRow">
                  {{'FILE.NO_FILES' | translate}}
                </div>
              </ng-template>

              <!-- Participants MOCK table -->
              <div [style]="{'height' : '55px'}"
                   class="col-12 flex flex-wrap align-content-center mockHeader mt-3">
                {{'EXERCISE.PARTICIPANTS' | translate}}
              </div>
              <ng-container *ngIf="participantLength(e.participants) > 0; else noParticipants">
                <div *ngFor="let participantId of exerciseParticipantMap.get(e.key); index as i"
                     [style]="{'height' : '55px'}"
                     class="col-12 flex flex-wrap align-content-center mockRow">
                  {{subjectNameMap.get(participantId)}}
                </div>
              </ng-container>
              <ng-template #noParticipants>
                <div [style]="{'height' : '55px'}" class="col-12 flex flex-wrap align-content-center mockRow">
                  {{'EXERCISE.NO_PARTICIPANTS' | translate}}
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">{{'EXERCISE.NO_EXERCISE' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
