import { Component, OnInit } from '@angular/core';
import {AccessControlService} from "../../../services/service/access-control.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-add-role-dialog',
  templateUrl: './add-role-dialog.component.html',
  styleUrls: ['./add-role-dialog.component.scss']
})
/**
 * Sets Admin role(s)
 */
export class AddRoleDialogComponent implements OnInit {
  roles;
  selectedRole;
  role
  constructor(
    private acService: AccessControlService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig
  ) {
    this.roles = this.acService.getRoles()

    this.role = this.config.data.role
    if(this.role) {
      if(this.role.admin){
        this.selectedRole = {name: 'ADMIN.ROLES.ADMIN', value: {admin: true}}
      } else if (this.role.superuser){
        this.selectedRole = {name: 'ADMIN.ROLES.SUPERUSER', value: {superuser: true}}
      } else if (this.role.user) {
        this.selectedRole = {name: 'ADMIN.ROLES.USER', value: {user: true}}
      }
    }
  }

  ngOnInit(): void {
  }

  add() {
    if(this.selectedRole){
      this.ref.close(this.selectedRole.value)
    }
  }
}
