import { Injectable } from '@angular/core';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map, mergeMap, publish} from 'rxjs/operators';
import {Exposure} from '../../model/exposure';

@Injectable({
  providedIn: 'root'
})

export class ExposureService {
  tenantId$: Observable<any>



  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
    public translateService: TranslateService,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Return Exposure types
   */
  getExposureTypes(){

    return [
      {key: 'Sot', label: this.translateService.instant('EVENT.EXPOSURES.SOOT')},
      {key: 'Royk', label: this.translateService.instant('EVENT.EXPOSURES.SMOKE')},
      {key: 'Uhell', label: this.translateService.instant('EVENT.EXPOSURES.ACCIDENT')},
      {key: 'Asbest', label: this.translateService.instant('EVENT.EXPOSURES.ASBESTOS')},
      {key: 'Psykisk', label: this.translateService.instant('EVENT.EXPOSURES.MENTAL_STRAIN')},
      {key: 'Kjemikalier', label: this.translateService.instant('EVENT.EXPOSURES.CHEMICALS')},
    ];

  }

  /**
   * Get all exposures
   */
  getExposures(): Observable<any[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/events/exposureEvents`).snapshotChanges()
      )
    );
  }

  /**
   * Add exposures from emergency and return key
   * @param exposure Exposure to create
   */
  addExposureGetKey(exposure: Exposure): Observable<string> {
    return this.tenantId$.pipe(
      map(tenantId => {
        const ref = this.db.list(`tenants/${tenantId}/events/exposureEvents`).push(exposure);
        return ref.key;
      }));
  }

  /**
   * Get specific exposure
   * @param key Exposure fbid.
   */
  getExposure(key: string): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/events/exposureEvents/${key}`).valueChanges()
      )
    );
  }

  /**
   * Update existing exposure
   * @param key Exposure fbid.
   * @param exposure Updated exposure
   */
  updateExposure(key: string, exposure: Exposure) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/events/exposureEvents/${key}`).update(exposure)
      )
    )).connect();
  }

  /**
   * Delete given exposure key.
   * @param key Exposure fbid.
   */
  deleteExposure(key: string) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/events/exposureEvents/${key}`).remove()
      )
    )).connect();
  }

  /**
   * Add Exposure
   */
  addExposure(exposure: Exposure) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/events/exposureEvents`).push(exposure)
      )
    )).connect();
  }
}
