import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Subscription} from "rxjs";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {FilterService} from "primeng/api";

@Component({
  selector: 'app-exercise-competency-type-detail',
  templateUrl: './exercise-competency-type-detail.component.html',
  styleUrls: ['./exercise-competency-type-detail.component.scss']
})
export class ExerciseCompetencyTypeDetailComponent implements OnInit {
  exerciseCT = [];
  output: any;
  selectedCT: any;

  competencyTypes: any;


  ctNameMap = new Map();
  eCompetencyTypeMap = new Map();
  removedCTMap = new Map();

  subscription = new Subscription();


  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private ctService: CompetencyTypeService,
    private filterService: FilterService
  ) {
    if (this.config.data.exerciseCT) {
      this.exerciseCT = Object.keys(JSON.parse(JSON.stringify(this.config.data.exerciseCT)));
      this.eCompetencyTypeMap = new Map(Object.keys(JSON.parse(JSON.stringify(this.config.data.exerciseCT))).map(key => [key, true]));
    } else {
      this.exerciseCT = [];
      this.eCompetencyTypeMap = new Map();
    }

    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.competencyTypes = [];
        cts.forEach(ct => {
          if (!ct.payload.val().archived){
            this.ctNameMap.set(ct.key, ct.payload.val().name);
            this.competencyTypes.push({key: ct.key, ...ct.payload.val()});
          }
        });
        // this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        this.competencyTypes.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.exerciseCT = this.exerciseCT.map(eCT => {
          if(eCT.key){
            return {key: eCT.key, name: this.ctNameMap.get(eCT.key)};
          } else {
            return {key: eCT, name: this.ctNameMap.get(eCT)};
          }
        }).sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      })
    );
  }

  ngOnInit(): void {
  }

  search(event: any) {
    const query = event.query;
    this.output = this.filterService.filter(this.competencyTypes, ['name'], query, "contains");
  }

  save() {
    const ctObj = {};
    this.exerciseCT.forEach(ct => {
      ctObj[ct.key] = true;
    });
    this.ref.close({ct: ctObj, removedCTMap: this.removedCTMap});
  }

  addCompetencyType(event: any) {
    this.exerciseCT.push({key: event.key, name: this.ctNameMap.get(event.key)});
    this.exerciseCT.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    this.eCompetencyTypeMap.set(event.key, true);
    this.removedCTMap.delete(event.key);
  }

  remove(competencyType: any) {
    const index = this.exerciseCT.findIndex(eCT => eCT.key === competencyType.key);
    if(index >= 0){
      this.exerciseCT.splice(index, 1);
      this.eCompetencyTypeMap.delete(competencyType.key);
      this.removedCTMap.set(competencyType.key, true);
    }
  }
}
