import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {AssetService} from "../../../services/service/asset.service";
import {SubjectService} from "../../../services/service/subject.service";
import {Subscription} from "rxjs";
import {Checklist, ChecklistTemplate} from "../../../model/checklist";
import {CronJob, Daily} from "../../../model/cronJob";
import * as moment from "moment";
import {AuthService} from "../../../services/service/auth.service";
import {ChecklistService} from "../../../services/service/checklist.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {take} from "rxjs/operators";
import {DateService} from "../../../services/service/date.service";
import {AccessControlService} from "../../../services/service/access-control.service";

@Component({
  selector: 'app-add-checklist',
  templateUrl: './add-checklist.component.html',
  styleUrls: ['./add-checklist.component.scss']
})


export class AddChecklistComponent implements OnInit, OnDestroy {
  selectedType;
  typeSelect;
  assetSelect: any = [];
  stationMap = new Map();

  windowWidth = window.innerWidth;

  // Changes depending on weekly (0) or monthly (1)
  tabIndex = 0;

  // Changes depending on if one want to use Date (0) or When (1) on monthly
  monthMode = 0;

  days: any = [];

  months: any = [];
  whenOption: any;
  numberOfDates = [];

  subscription = new Subscription();

  ongoingForm: UntypedFormGroup;
  templateForm: UntypedFormGroup;
  cronJobForm: UntypedFormGroup;

  template: Checklist = {createdById: '', createdDate: '', items: [], name: ''};
  fromTemplate: Checklist;

  isAdmin$;
  isSuper$;
  isUser$;

  loading = true;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private translateService: TranslateService,
    private assetService: AssetService,
    private subjectService: SubjectService,
    private authService: AuthService,
    private checklistService: ChecklistService,
    private dateService: DateService,
    private acService: AccessControlService,
  ) {

    this.fromTemplate = this.config.data.template;
    if (this.config.data.checklist) {
      this.template = this.config.data.checklist;
    }
    // Set type based on what tab user has open when clicking addChecklist
    switch (this.config.data.index) {
      case 0:
        this.selectedType = {name: translateService.instant('CHECKLIST.ONGOING'), index: 0};
        break;
      case 1:
        this.selectedType = {name: translateService.instant('CHECKLIST.REPETITIVE'), index: 1};
        break;
      case 2:
        this.selectedType = {name: translateService.instant('CHECKLIST.TEMPLATE'), index: 2};
        break;
      default:
        this.selectedType = {name: translateService.instant('CHECKLIST.ONGOING'), index: 0};
    }
    // Create a days option
    this.days = this.dateService.getDaysOptions();

    // Create months option
    this.months = this.dateService.getMonthOptions();

    // create when option
    this.whenOption = [
      {name: translateService.instant('COMMON.FIRST'), value: 0},
      {name: translateService.instant('COMMON.LAST'), value: 1}
    ];

    // Create typeSelect
    this.typeSelect = [
      {name: translateService.instant('CHECKLIST.ONGOING'), index: 0},
      {name: translateService.instant('CHECKLIST.REPETITIVE'), index: 1},
    ];

    // Get Assets
    this.subscription.add(
      this.assetService.getAssets().subscribe(assets => {
        this.assetSelect = assets.map(asset => {
          return {key: asset.key, ...asset.payload.val()};
        });
      })
    );

    // Get Stations
    this.subscription.add(
      this.subjectService.getStations().subscribe(stations => {
        this.stationMap = new Map(stations.map(station => {
          return [station.key, station.payload.val().name];
        }));
      })
    );
  }

  ngOnInit(): void {

    this.acService.getOwnAccessControl().subscribe(value => {
      if (value.roles.admin){
        this.isAdmin$ = value.roles.admin;
      } else {
        this.isAdmin$ = false;
      }
      if (value.roles.superuser){
        this.isSuper$ = value.roles.superuser;
      } else {
        this.isSuper$ = false;
      }
      if (value.roles.user){
        this.isUser$ = value.roles.user;
      } else {
        this.isUser$ = false;
      }
    });

    this.ongoingForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      asset: new UntypedFormControl('', Validators.required),
      station: new UntypedFormControl('', Validators.required),
    });

    this.templateForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      appAccess: new UntypedFormControl(false, Validators.required),
    });

    this.cronJobForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      asset: new UntypedFormControl('', Validators.required),
      station: new UntypedFormControl('', Validators.required),

      weekly: new UntypedFormGroup({
        interval: new UntypedFormControl(1, [
          Validators.required,
          Validators.min(1),
          Validators.max(8)
        ]),
        delay: new UntypedFormControl(0, [
          Validators.required,
          Validators.min(0),
          Validators.max(8)
        ]),
        day: new UntypedFormControl(0, Validators.required),
      }),

      monthly: new UntypedFormGroup({
        interval: new UntypedFormControl(1, Validators.required),
        date: new UntypedFormControl('', Validators.required),
        when: new UntypedFormControl('', Validators.required),
        day: new UntypedFormControl('', Validators.required),
        startMonth: new UntypedFormControl(moment().month(), Validators.required),
      })
    });

    // Calculate days of the month to numbersOfDates
    this.getDays(this.cronJobForm.get('monthly').value.startMonth);
    // Update what mode we use.
    this.weeklyOrMonthly();
    this.loading = false;
  }

  /**
   * Method to calculate days in the month for the select date
   */
  getDays(month) {
    this.numberOfDates = this.dateService.getDays(month);
  }

  /**
   * Save and Exit
   */
  onSubmit() {
    const today = new Date().toISOString();

    this.authService.getUserUID()
      .pipe(take(1))
      .subscribe(uid => {

        // Ongoing
        if (this.selectedType.index === 0) {
          const ongoing = this.ongoingForm.value;
          const checklist: Checklist = {
            createdById: uid,
            createdDate: today,
            name: ongoing.name,
            assetId: ongoing.asset.key,
            stationId: ongoing.asset.stationId,
            completed: false,
            repeatDate: today,
          };
          if (this.fromTemplate){
            if (this.fromTemplate.items){
              checklist.items = this.fromTemplate.items;
              this.checklistService.addChecklist(checklist);
            } else {
              this.checklistService.addChecklist(checklist);
            }
          } else {
            if (this.template.items){
              checklist.items = this.template.items;
              this.checklistService.addChecklist(checklist);
            } else {
              this.checklistService.addChecklist(checklist);
            }
          }
        }

        // CronJob
        else if (this.selectedType.index === 1) {

          const cjForm = this.cronJobForm.value;

          const checklist: Checklist = {
            createdById: uid,
            createdDate: today,
            name: cjForm.name,
            assetId: cjForm.asset.key,
            stationId: cjForm.asset.stationId,
            completed: false,
          };

          if (this.fromTemplate){
            if (this.fromTemplate.items){
              checklist.items = this.fromTemplate.items;
            }
          } else {
            if (this.template.items){
              checklist.items = this.template.items;
            }
          }

          const cronJob: CronJob = {
            archive: false,
            checklistId: "",
            pause: false,
            newChecklistCron: true,
          };

          // Weekly

          if (this.tabIndex === 0) {
            cronJob.weeklyMode = true;
            cronJob.monthlyMode = false;

            cronJob.weekly = {
              day: this.getDaily(cjForm.weekly.day),
              delay: cjForm.weekly.delay,
              interval: cjForm.weekly.interval
            };
          }
          // Monthly

          else if (this.tabIndex === 1) {

            cronJob.weeklyMode = false;
            cronJob.monthlyMode = true;

            cronJob.monthly = {
              interval: cjForm.monthly.interval,
              startMonth: cjForm.monthly.startMonth,
            };

            // Date
            if (this.monthMode === 0) {
              cronJob.monthly.date = cjForm.monthly.date;
            }
            // When
            else if (this.monthMode === 1) {
              this.getWhen(cronJob.monthly, cjForm.monthly.when);
              cronJob.monthly.day = this.getDay(cjForm.monthly.day);
            }
          }
          this.checklistService.addChecklistGetKey(checklist)
            .pipe(take(1))
            .subscribe(key => {
              cronJob.checklistId = key;
              this.checklistService.addCronJob(cronJob);
            });
        }

        // Template
        else if (this.selectedType.index === 2) {
          const form = this.templateForm.value;

          const checklist: Checklist = {
            createdById: uid,
            createdDate: today,
            name: form.name,
            completed: false,
          };

          if (this.template.items){
            checklist.items = this.template.items;
          }

          const template: ChecklistTemplate = {
            appAccess: form.appAccess,
            checklistId: "",
            created: today
          };

          this.checklistService.addChecklistGetKey(checklist)
            .pipe(take(1))
            .subscribe(key => {
              template.checklistId = key;
              this.checklistService.addChecklistTemplate(template);
            });

        }
        this.ref.close();
      });
  }

  /**
   * CronJob when/date disable/enable control
   */
  swapDisable() {
    const group: any = this.cronJobForm.controls.monthly;

    switch (this.monthMode) {
      case 0:
        group.controls.date.enable();
        group.controls.when.disable();
        group.controls.day.disable();
        break;
      case 1:
        group.controls.date.disable();
        group.controls.when.enable();
        group.controls.day.enable();
        break;
    }
  }

  /**
   * Set Station Name on the Form
   *
   * @param form Ongoing/CronJob
   */
  setStation(form) {
    form.controls.station.setValue(this.stationMap.get(form.value.asset.stationId));
  }

  /**
   * Control for CronJob Form, disabling/enabling depending on weekly or monthly
   */
  weeklyOrMonthly() {
    switch (this.tabIndex) {
      case 0:
        this.cronJobForm.get('weekly').enable();
        this.cronJobForm.get('monthly').disable();
        break;
      case 1:
        this.cronJobForm.get('weekly').disable();
        this.cronJobForm.get('monthly').enable();
        this.swapDisable();
    }
  }

  /**
   *
   * @param day, day represented by 0-6
   * @return boolean obj with day in short form (mon, tue, ...) = true, and rest false.
   */
  getDaily(day): Daily {
    return this.dateService.convertDayNumberToDayObject(day);
  }

  /**
   *
   * @param monthly: CronJob monthly object
   * @param when: 0 if first, 1 if last.
   *
   * Add to first/last to monthly.
   */
  getWhen(monthly, when) {
    switch (when) {
      case 0:
        monthly.first = true;
        monthly.last = false;
        break;
      case 1:
        monthly.last = true;
        monthly.first = false;
        break;
    }
  }

  /**
   *
   * @param day: int value of weekday from 0 to 6
   *
   * Set monthly day in string value
   */
  getDay(day: number): string {
    switch (day) {
      case 0:
        return 'monday';
      case 1:
        return 'tuesday';
      case 2:
        return 'wednesday';
      case 3:
        return 'thursday';
      case 4:
        return 'friday';
      case 5:
        return 'saturday';
      case 6:
        return 'sunday';
    }
  }

  /**
   * Unsub on destroy
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
