import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {MenuItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../services/service/auth.service";
import {TenantService} from "../../services/service/tenant.service";
import {MenuService} from "../../services/service/menu.service";
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AccessControlService} from "../../services/service/access-control.service";

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
  menuItems: Observable<MenuItem[]>;
  @Output() toggle = new EventEmitter<boolean>();
  languageSubscription;
  isBigView: boolean;
  state = 0;

  isRegional$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isSuperUser$: Observable<boolean>;
  isUser$: Observable<boolean>;


  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private tenantService: TenantService,
    private menuService: MenuService,
    private accessControlService: AccessControlService
  ) {
    this.isRegional$ = this.tenantService.isRegional();
    this.isAdmin$ = this.accessControlService.isAdmin();
    this.isSuperUser$ = this.accessControlService.isSuperUser();
    this.isUser$ = this.accessControlService.isUser();
  }

  ngOnInit(): void {
    /** Check if width is max 720px -> Mobile */
    this.isBigView = window.matchMedia('(min-width: 1920px)').matches;

    /** Build onInit **/
    this.menuItems = this.buildMenubar();

    /** To use I18N on PrimeNG menubar we have to rebuild MenuItem on language change **/
    this.languageSubscription =
      this.translateService.onLangChange.subscribe(() => {
        this.menuItems = this.buildMenubar();
      });
  }

  /**
   * Change language
   *
   * @param lang: language in short form (en, nb)
   */
  changeLanguage(lang) {
    this.translateService.use(lang);
  }

  /**
   * Build menubar
   */
  private buildMenubar(): Observable<MenuItem[]> {

    return combineLatest([this.isAdmin$, this.isSuperUser$, this.isRegional$]).pipe(
      map(([isAdmin, isSuperUser, isRegional]) => {
        if (isRegional) {
          return this.buildMenuBarRegional();
        } else {
          return this.buildMenuBarRegular(isAdmin, isSuperUser);
        }
      })
    );
  }

  private buildMenuBarRegional(): MenuItem[] {
    const menuBar: MenuItem[] = [];

    menuBar.push(
      {
        /** What language is current in use */
        label: this.translateService.instant('LOGIN.GET_LANG'),

        /** Select language to use. */
        items: [
          {
            label: 'Norsk', command: () => {
              this.changeLanguage('nb');
            }
          },
          {
            label: 'English', command: () => {
              this.changeLanguage('en');
            }
          },
          {
            label: 'Íslenska', command: () => {
              this.changeLanguage('is');
            }
          },
          {
            label: 'Dansk', command: () => {
              this.changeLanguage('da');
            }
          },
          {
            label: 'Svenska', command: () => {
              this.changeLanguage('sv');
            }
          },
          {
            label: 'Suomi', command: () => {
              this.changeLanguage('fi');
            }
          },
        ]
      },
    );

    menuBar.push({
      label: this.translateService.instant('MENU.LOGOUT'),
      command: () => {
        this.authService.logout();
      }
    });

    return menuBar;
  }

  private buildMenuBarRegular(isAdmin, isSuperUser) {
    const menuBar: MenuItem[] = [];

    menuBar.push(
      {
        /** What language is current in use */
        label: this.translateService.instant('LOGIN.GET_LANG'),

        /** Select language to use. */
        items: [
          {
            label: 'Norsk', command: () => {
              this.changeLanguage('nb');
            }
          },
          {
            label: 'English', command: () => {
              this.changeLanguage('en');
            }
          },
          {
            label: 'Íslenska', command: () => {
              this.changeLanguage('is');
            }
          },
          {
            label: 'Dansk', command: () => {
              this.changeLanguage('da');
            }
          },
          {
            label: 'Svenska', command: () => {
              this.changeLanguage('sv');
            }
          },
          {
            label: 'Suomi', command: () => {
              this.changeLanguage('fi');
            }
          },
        ]
      },
    );

    if(isAdmin || isSuperUser){
      menuBar.push(
        /** Send SMS **/
        {
          label: this.translateService.instant('BUTTON.SMS'),
          routerLink: ['sms'],
          replaceUrl: true
        },
      );
    }

    if(isAdmin){
      /** Admin panel **/
      menuBar.push(
        {
          label: this.translateService.instant('USER_ROLES.ADMIN'),
          routerLink: ['admin'],
          replaceUrl: true
        }
      );
    }

    menuBar.push({
      label: this.translateService.instant('MENU.LOGOUT'),
      command: () => {
        this.authService.logout();
      }
    });
    return menuBar;
  }
}
