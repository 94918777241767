import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyInfoComponent } from './my-info/my-info.component';
import { ChangeMailComponent } from './change-mail/change-mail.component';
import { ChangePwComponent } from './change-pw/change-pw.component';
import {Route} from '@angular/router';
import {TranslateModule} from "@ngx-translate/core";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {CalendarModule} from 'primeng/calendar';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ToastModule} from 'primeng/toast';
import {PasswordModule} from 'primeng/password';
import {DividerModule} from 'primeng/divider';



export const MyPageRoutes: Route = {
  path: 'my-page',
  children: [
    {path: 'my-info', component: MyInfoComponent},
    {path: 'change-mail', component: ChangeMailComponent},
    {path: 'change-pw', component: ChangePwComponent},
  ]
}
@NgModule({
  declarations: [
    MyInfoComponent,
    ChangeMailComponent,
    ChangePwComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        MenuModule,
        ButtonModule,
        InputTextModule,
        FormsModule,
        CalendarModule,
        DropdownModule,
        InputTextareaModule,
        ToastModule,
        PasswordModule,
        DividerModule
    ]
})
export class MyPageModule { }
