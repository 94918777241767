import { Pipe, PipeTransform } from '@angular/core';
import {CompetencyType} from '../model/competencyType';

@Pipe({
  name: 'competencyTypeFilter'
})
export class CompetencyTypeFilterPipe implements PipeTransform {

  transform(competencyTypes: CompetencyType[], search?: string): any {
    if ( !search){
      return competencyTypes;
    } else {
      return competencyTypes.filter(competencyType => competencyType.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
