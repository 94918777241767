import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {PersonService} from '../../../services/service/person.service';
import {EmergencyService} from '../../../services/service/emergency.service';
import {ExposureService} from '../../../services/service/exposure.service';
import {EmergencyResponse} from '../../../model/emergencyResponse';
import {combineLatest, Subscription} from 'rxjs';
import {EmergencyEval} from '../../../model/emergencyEval';
import {EmergencyAddExposuresComponent} from '../emergency-add-exposures/emergency-add-exposures.component';
import {ConfirmationService, FilterService, MenuItem} from 'primeng/api';
import * as moment from 'moment';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EmergencyAddOvertimeComponent} from '../emergency-add-overtime/emergency-add-overtime.component';
import {LocationData} from '../../../model/locationData';
import {EmergencyParticipant, ExposureParticipant} from '../../../model/participant';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';
import * as _ from 'lodash';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {Exposure} from '../../../model/exposure';
import {AddRolesComponent} from '../add-roles/add-roles.component';
import {Roles, RoleType} from '../../../model/role';
import {AddEvaluationComponent} from '../add-evaluation/add-evaluation.component';
import {RolesService} from '../../../services/service/roles.service';
import {ChangeLog} from '../../../model/changeLog';
import {SubjectService} from '../../../services/service/subject.service';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';

@Component({
  selector: 'app-emergency-edit',
  templateUrl: './emergency-edit.component.html',
  styleUrls: ['./emergency-edit.component.scss'],
  providers: [ConfirmationService, DialogService],
})
export class EmergencyEditComponent implements OnInit, OnDestroy {
  // Teams
  groupedSubjectFilter = [];
  teamNameMap = new Map();
  parentChildMap = new Map();
  personParentNameMap = new Map();

  // Change log
  changeLog: any[] = [];
  // Subscription
  subscription = new Subscription();

  // Menu items
  menuItems: MenuItem[];

  // Person
  persons: any[] = [];
  outputPerson = [];
  selectedPerson: '';
  personMap = new Map();
  personNameMap = new Map<string, string>();

  // Emergency response
  emergency: EmergencyResponse;
  emergencyKey;
  participants: any[] = [];

  // Event time space
  workTimeMap = new Map<string, {hours, minutes}>();
  timeMap = new Map<string, {start, end}>();
  timePeriodChange = new Map<string, boolean>(); // Initially false

  // Exposures
  exposures: any[];
  exposureTypes: any[];
  exposureMap = new Map<string, string[]>();


  exposureTimeChange =  new Map<string, Map<string, boolean>>();
  exposedMap = new Map<string, Map<string, {boolValue, time: {start, end}}>>();
  exposedMapCopy = new Map<string, Map<string, {boolValue, time: {start, end}}>>();

  // Evaluation
  evaluation: EmergencyEval;
  evalBool = [];

  types = [];
  addedPersons = [];

  emergencyCopy: EmergencyResponse;
  evaluationCopy: EmergencyEval;
  exposureMapCopy = new Map<string, string[]>();

  personArray: any[] = [];
  personArrayCopy: any[] = [];
  editedEmergency: UntypedFormGroup;

  isUpdated = new Map<string, boolean>();

  // Roles
  isRoles: boolean;
  roleTypes: RoleType[];
  roleMap: Map<string, string[]> = new Map<string, string[]>(); // {key: "smoke diver", persons: [personKey_1, ..., personKey_n]}
  selectedRolesMap: Map<string, string[]> = new Map<string, string[]>(); // {key: personId, [roleId, roleId]}
  roles: Roles[];
  existingRolesMap = new Map<string, string[]>();


  constructor(
    public translateService: TranslateService,
    private config: DynamicDialogConfig,
    private evaluationService: EvaluationService,
    private ref: DynamicDialogRef,
    private personService: PersonService,
    private emergencyService: EmergencyService,
    private exposureService: ExposureService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private filterService: FilterService,
    private authService: AuthService,
    private roleService: RolesService,
    private subjectService: SubjectService,
    private gSService: GroupedSubjectsService,
  ) {

    // Emergency data
    this.emergency = this.config.data.emergency;
    this.emergencyKey = this.emergency.key$;
    delete this.emergency.key$;
    if (this.emergency.participants) {
      this.participants = this.emergency.participants;
      for (const participant of this.emergency.participants) {
        this.personArray.push(participant.key);
      }
    }
    // Emergency copy
    this.emergencyCopy = _.clone(this.emergency);

    if (this.emergency.changeLog){
      this.changeLog = [this.emergency.changeLog];
    }
    // For Evaluation
    if (!this.config.data.evaluation){
      this.evaluation = {
        createdById: '',
        dateCreated: '',
        description: '',
        emergencyId: '',
        learningPoints: '',
        measuresOnSite: '',
        various: '',
      };
      this.evaluationCopy = _.clone(this.evaluation);
    } else {
      this.evaluation = this.config.data.evaluation;
      this.evaluationCopy = _.clone(this.evaluation);
    }
    this.evalBool.push(true);

    // For Exposures
    this.exposures = this.config.data.exposures;
    this.personNameMap = this.config.data.personNameMap;
    this.exposureTypes = exposureService.getExposureTypes();

    // Generate leader array & exposure map
    for (const person of this.participants) {
      // Exposure map
      if (person.exposureIds) {
        for (const exposureId of person.exposureIds) {
          for (const exposure of this.exposures) {
            if (exposure.key === exposureId){
              for (const type of this.exposureTypes) {
                if (exposure.exposureType === type.key){

                  this.isUpdated.set(type.label, false);
                  if (!this.exposureMapCopy.has(person.key)) {
                    this.exposureMapCopy.set(person.key, [type.key]);
                    this.exposureMap.set(person.key, [type.key]);
                    break;
                  } else {
                    this.exposureMapCopy.get(person.key).push(type.key);
                    this.exposureMap.get(person.key).push(type.key);
                    break;
                  }
                }
              }
              break;
            }
          }
        }
      }
      // Work hour map
      this.workTimeMap.set(person.key, this.getHoursAndMin(person.timeStart, person.timeEnd));
      this.timeMap.set(person.key, {start: person.timeStart, end: person.timeEnd});
      if (person.timeStart !== this.emergency.timeStart || person.timeEnd !== this.emergency.timeEnd){
        this.timePeriodChange.set(person.key, true);
      } else {this.timePeriodChange.set(person.key, false);}

      // Exposure hours
      const eMap = new Map<string, {boolValue, time: {start, end}, label}>();
      const tMap = new Map<string, boolean>();
      this.exposureTypes.forEach(type => {
        const label = type.label;
        if (this.exposureMap.get(person.key)){
          const expoList = this.exposureMap.get(person.key);
          if (expoList.includes(type.key)){
            this.exposures.forEach(exposure => {
              if (exposure.emergencyId === this.emergencyKey && exposure.exposureType === type.key){
                exposure.participants.forEach(participant => {
                  if(participant.key === person.key){
                    eMap.set(type.key, {boolValue: true, time:
                        {start: participant.exposureStart, end: participant.exposureEnd}, label});
                  }
                });
              }
            });
          } else {
            eMap.set(type.key, {boolValue: false, time:
                {start: this.emergency.timeStart, end: this.emergency.timeEnd}, label});
          }
        } else {
          eMap.set(type.key, {boolValue: false, time:
              {start: this.emergency.timeStart, end: this.emergency.timeEnd}, label});
        }
        this.exposedMap.set(person.key, eMap);

        tMap.set(type.key, false);
        this.exposureTimeChange.set(person.key, tMap);
      });

      // Added persons
      this.personMap.set(person.key, true);

      // Leader array
      this.addedPersons.push({name: this.personNameMap.get(person.key), personKey: person.key});
    }


    // Get emergency types
    this.subscription.add(
      this.emergencyService.getTypes().subscribe(
        eventTypes => {
          this.types = eventTypes.map(myType => {
            return {key: myType.key, ...myType.payload.val()};
          });
        }
      )
    );

    this.subscription.add(
      combineLatest([
        this.personService.getPersons(),
        this.subjectService.getTeams(),
        this.subjectService.getRelations(),
      ]).subscribe(([persons, teams, relations]) => {
        const data = this.gSService.build(persons, teams, relations);
        this.personNameMap = data[0];
        this.teamNameMap = data[1];
        this.personParentNameMap = data[2];
        this.groupedSubjectFilter = data[3];
      })
    );

    // Get roleTypes
    this.subscription.add(
      this.roleService.getRoleTypes().subscribe( roles => {
        this.roleTypes = roles.map(role => {
          return {key: role.key, ...role.payload.val()};
        });
      })
    );


    this.roles = this.config.data.roles;

    this.roles.forEach(role => {
      if (role.eventKey === this.emergencyKey){
        if (this.selectedRolesMap.has(role.personKey)){
          this.existingRolesMap.get(role.personKey).push(role.typekey);
          this.selectedRolesMap.get(role.personKey).push(role.typekey);
        } else {
          this.existingRolesMap.set(role.personKey, [role.typekey]);
          this.selectedRolesMap.set(role.personKey, [role.typekey]);
        }
      }
    });
    this.personArrayCopy = _.clone(this.personArray);
    this.exposedMapCopy = _.clone(this.exposedMap);
  }


  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  buildFilter(subject) {
    return {
      label: this.personNameMap.get(subject),
      value: subject,
    };
  }

  updateMenu(){
    this.menuItems = [
      {
        label: this.translateService.instant('EVENT.EXPOSURE'),
        icon: 'far fa-exclamation-triangle',
        disabled: this.personArray.length < 1,
        command: () => {this.addExposures();}
      },
      {
        label: this.translateService.instant('ROLES.ROLES'),
        icon: 'far fa-clipboard-user',
        disabled: this.personArray.length < 1,
        command: () => {this.addRoles();}
      },
      {
        label: this.translateService.instant('COMMON.EVALUATE'),
        icon: 'far fa-clipboard-check',
        command: () => {this.addEvaluation();}
      }
    ];
  }

  ngOnInit(): void {
    this.editedEmergency = new UntypedFormGroup({
      date: new UntypedFormControl(this.emergencyCopy.date, Validators.required),
      startTime: new UntypedFormControl(this.emergencyCopy.timeStart, Validators.required),
      endTime: new UntypedFormControl(this.emergencyCopy.timeEnd, Validators.required),
      address: new UntypedFormControl(this.emergencyCopy.location.address, Validators.required),
      typeKey: new UntypedFormControl(this.emergencyCopy.typeKey),
      brisNumber: new UntypedFormControl(this.emergencyCopy.brisNumber),
      zip: new UntypedFormControl(this.emergencyCopy.location.zip),
      postal: new UntypedFormControl(this.emergencyCopy.location.postal),
      description: new UntypedFormControl(this.emergencyCopy.details),
      leaderKey: new UntypedFormControl({value: this.emergencyCopy.leaderKey, disabled: true}),
    });

    if (this.addedPersons){
      this.editedEmergency.get('leaderKey').enable();
    }
    combineLatest(
      [this.editedEmergency.get('startTime').valueChanges, this.editedEmergency.get('endTime').valueChanges]
    ).subscribe(time => this.timePeriod(time));

    this.updateMenu();
  }

  /**
   * Check that time is set before allowing to select participants
   */
  timeSet() {
    return !(this.emergency.timeStart && this.emergency.timeEnd);
  }

  /**
   * Add roles
   */
  addRoles() {
    this.dialogService.open(AddRolesComponent, {
      header: this.translateService.instant('ROLES.ADD_ROLE'),
      styleClass: 'max-size-width-dialog',
      data: {
        personArray: this.personArray,
        personNameMap: this.personNameMap,
        roleTypes: this.roleTypes,
        selectedRolesMap: this.selectedRolesMap,
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.roleMap = data[0];
        this.selectedRolesMap = data[1];

        this.isRoles = false;
        this.selectedRolesMap.forEach(personRoles => {
          if (personRoles.length > 0){
            this.isRoles = true;
          }
        });
      }
    });
  }

  /**
   * Add/edit evaluations
   */
  addEvaluation() {
    this.dialogService.open(AddEvaluationComponent, {
      header: this.translateService.instant('COMMON.EVALUATE'),
      styleClass: 'max-size-width-dialog',
      data: {
        evaluation: this.evaluation,
        evaluationCopy: this.evaluationCopy,
      }
    }).onClose.subscribe(evaluation => {
      if (evaluation) {
        this.evaluationCopy.description = evaluation.get("description");
        this.evaluationCopy.learningPoints = evaluation.get("learningPoints");
        this.evaluationCopy.measuresOnSite = evaluation.get("measuresOnSite");
        this.evaluationCopy.various = evaluation.get("various");
      }
    });
  }

  // PERSON
  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Get person name form name or from nameMap
   */
  getName(personId) {
    return this.personNameMap.get(personId);
  }

  /**
   * Add person to table
   * @param subject Input person
   */
  addPerson(subject) {
    if (!this.personMap.has(subject.value)) {
      // For work hours
      this.workTimeMap.set(subject.value, this.getHoursAndMin(this.emergencyCopy.timeStart, this.emergencyCopy.timeEnd));
      this.timeMap.set(subject.value, {start: this.emergencyCopy.timeStart, end: this.emergencyCopy.timeEnd});
      this.timePeriodChange.set(subject.value, false);


      // Exposure hours
      const eMap = new Map<string, {boolValue, time: {start, end}, label}>();
      const tMap = new Map<string, boolean>();
      this.exposureTypes.forEach(type => {
        const label = type.label;
        eMap.set(type.key, {boolValue: false, time:
            {start: this.emergency.timeStart, end: this.emergency.timeEnd}, label});
        this.exposedMap.set(subject.value, eMap);

        tMap.set(type.key, false);
        this.exposureTimeChange.set(subject.value, tMap);
      });

      // Add person
      this.personMap.set(subject.value, true);
      this.personArray.push(subject.value);

      // For selecting leader
      this.addedPersons.push({name: this.personNameMap.get(subject.key), personKey: subject.value});

      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a).toLowerCase().localeCompare(this.personNameMap.get(b).toLowerCase());
      });

      this.updateMenu();
    }
  }

  /**
   * Remove person from table
   * @param personId person to remove
   */
  deletePerson(personId: string) {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE')
        + ':  <br/><br/>' + this.personNameMap.get(personId)  + '<br/><br/>'
        + this.translateService.instant('EVENT.EDIT.DELETE_PERSON'),
      accept: () => {
        this.personMap.delete(personId);
        this.exposureMap.delete(personId);
        this.workTimeMap.delete(personId);
        this.timePeriodChange.delete(personId);
        this.selectedRolesMap.delete(personId);

        const index = this.personArray.findIndex(pId => pId === personId);
        if(index >= 0) {
          this.personArray.splice(index, 1);
        }
      }
    });
    this.updateMenu();
  }

  // TIME
  /**
   * Print work hours
   * @param personId Unique person ID
   */
  printTime(personId: string) {

    const hours = this.workTimeMap.get(personId).hours;
    const minutes = this.workTimeMap.get(personId).minutes;

    if (hours !== 0){
      if (minutes !== 0){
        return hours + this.translateService.instant('EVENT.HOUR') + " " + minutes + this.translateService.instant('EVENT.MINUTES');
      } else {
        return hours + this.translateService.instant('EVENT.HOUR');
      }
    } else {
      return minutes + this.translateService.instant('EVENT.MINUTES');
    }
  }

  /**
   * Get hours and minutes from moment
   * @param s Start moment
   * @param e End moment
   */
  getHoursAndMin(s, e){

    if (s && e) {

      const start = moment(this.emergencyCopy.dateCreated).add(s);
      const end = moment(this.emergencyCopy.dateCreated).add(e);

      if (end.diff(start) < 0) {
        end.add(1, 'days');
      }
      return {hours: end.diff(start, "hours"), minutes: end.diff(start, "minutes") % 60};

    } else {
      return {hours: 0, minutes: 0};
    }
  }

  /**
   * Change person time period.
   * @param personId unique person ID
   */
  changePersonTimePeriod(personId: string){

    let s; let e;
    if (this.timePeriodChange.get(personId)){
      s = this.timeMap.get(personId).start;
      e = this.timeMap.get(personId).end;
    } else {
      s = this.emergencyCopy.timeStart;
      e = this.emergencyCopy.timeEnd;
    }

    this.dialogService.open(EmergencyAddOvertimeComponent, {
      header: this.personNameMap.get(personId) + ': ' + this.translateService.instant('EVENT.CHANGE_TIME_SPACE'),
      width: "300px",
      data: {
        start: s,
        end: e
      }
    }).onClose.subscribe(time => {
      if(time) {
        const date = this.emergencyCopy.dateCreated;
        this.workTimeMap.set(personId, this.getHoursAndMin(moment(date).add(time.start), moment(date).add(time.end)));
        this.timeMap.set(personId, {start: time.start, end: time.end});
        this.timePeriodChange.set(personId, true);
      }
    });
  }

  /**
   * Set time period
   */
  timePeriod(time: [string, string]) {
    const start = moment(this.editedEmergency.value.date).add(time[0]);
    const end = moment(this.editedEmergency.value.date).add(time[1]);

    this.editedEmergency.value.startTime = start;
    this.editedEmergency.value.endTime= end;

    if (end.diff(start) < 0) {
      end.add(1, 'days');
    }

    for (const personId in this.workTimeMap) {
      if (this.timePeriodChange.get(personId)){
        this.workTimeMap.set(personId, this.getHoursAndMin(start, end));
        this.timeMap.set(personId, {start: start, end: end});
      } else {
      }
    }
  }

  // EXPOSURES
  /**
   * Count Exposures
   * @param personId Unique person ID
   */
  exposureCounter(personId: string){
    const personExpo = this.exposedMap.get(personId);
    let counter = 0;
    for (const [, list] of personExpo) {
      if (list.boolValue === true){
        counter++;
      }
    }
    return counter;
  }

  /**
   * Quick add exposures
   */
  addExposures() {
    this.dialogService.open(EmergencyAddExposuresComponent, {
      header: this.translateService.instant('EVENT.ADD_EXPOSURES'),
      styleClass: 'max-size-width-dialog',
      data: {
        personArray: this.personArray,
        personNameMap: this.personNameMap,
        exposedMap: this.exposedMap,
        exposureTimeChange: this.exposureTimeChange,
        date: this.emergency.date,
        edit: true
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.exposedMap = data[0];
        this.exposureTimeChange = data[1];
      }
    });
  }

  /**
   * Returns true if any form data is changed
   * (Does not check evaluations addedParticipants or addedParticipants to exposures)
   * @param emergencyResponse Form data
   */
  checkForChanges(emergencyResponse: EmergencyResponse){
    for (const emergencyValueField in emergencyResponse) {
      if (emergencyValueField !== "createdById" && emergencyValueField !== "dateCreated" && emergencyValueField !== "changeLog") {
        if (!_.isEqual(emergencyResponse[emergencyValueField], this.emergency[emergencyValueField])) {
          return true;
        }
      }
    }
    return false;
  }

  // UPDATE
  /**
   * Add all selected roles to DB
   * @param emergencyKey EmergencyId
   * @param rolesToCreate New roles to save
   * @param date Date for event
   * @param today Date created
   */
  addRolesToDB(emergencyKey, rolesToCreate, date, today){
    for(const [key, list] of rolesToCreate){
      list.forEach(roleType => {
        const role: Roles = {
          shiftReport: false, eventKey: emergencyKey, dateCreated: today, date: date, personKey: key, typekey: roleType
        };
        this.roleService.addRole(role);
      });
    }
  }

  /**
   * Add all selected roles to DB
   * @param emergencyKey EmergencyId
   * @param rolesToRemove Roles to delete
   */
  deleteRoles(emergencyKey, rolesToRemove){
    this.roles.forEach(role => {
      if (role.eventKey === emergencyKey && rolesToRemove.get(role.personKey)){
        if (rolesToRemove.get(role.personKey).includes(role.typekey)){
          this.roleService.deleteRole(role);
        }
      }
    });
  }

  /**
   * Generate new emergency and check for addedParticipants
   */
  onSubmit() {

    const deletedPersons = [];
    this.personArrayCopy.forEach(Id => {
      if(!this.personArray.includes(Id)){
        deletedPersons.push(Id);
      }
    });
    const addedPersons = [];
    this.personArray.forEach(Id => {
      if(!this.personArrayCopy.includes(Id)){
        addedPersons.push(Id);
      }
    });
    const rolesToRemove = new Map();
    const rolesToCreate = new Map();

    // Check for deleted persons and compare existing ones
    for(const [personId, roleList] of this.existingRolesMap){
      if(!this.selectedRolesMap.has(personId)){
        rolesToRemove.set(personId, roleList);
      } else {
        const roleChange = this.selectedRolesMap.get(personId);
        // Get elements in roleList that are not in roleChange
        const oldRoles = roleList.filter(role => !roleChange.includes(role));
        // Get elements in roleChange that are not in roleList
        const newRoles = roleChange.filter(role => !roleList.includes(role));

        if (oldRoles.length > 0) {
          rolesToRemove.set(personId, oldRoles);
        }
        if (newRoles.length > 0) {
          rolesToCreate.set(personId, newRoles);
        }
      }
    }

    // Add new persons to create list
    for(const [personId, roleList] of this.selectedRolesMap){
      if (!this.existingRolesMap.has(personId)){
        rolesToCreate.set(personId, roleList);
      }
    }

    const today = new Date().toISOString();
    const ongoing = this.editedEmergency.value;

    const locationData: LocationData = {
      address: ongoing.address,
      postal: ongoing.postal,
      zip: ongoing.zip

    };
    const emergencyParticipants: EmergencyParticipant[] = [];
    const exposureParticipants: ExposureParticipant[] = [];

    this.personArray.forEach(personKey => {

      const startEnd = this.timeMap.get(personKey);
      const time = this.workTimeMap.get(personKey).hours + ":" + this.workTimeMap.get(personKey).minutes;

      const roles = [];
      for(const [roleKey, list] of this.roleMap){
        if (list.includes(personKey)){
          roles.push(roleKey);
        }
      }

      emergencyParticipants.push({
        key: personKey,
        newData: true,
        time: time,
        timeEnd: startEnd.end,
        timeStart: startEnd.start,
        exposureIds: [],
        roles: roles
      });

      exposureParticipants.push({
        key: personKey,
        newData: true,
        exposureEnd: '',
        exposureStart: '',
        roles: roles
      });

    });

    // const participants: EmergencyParticipant[] = [];
    // const added = [];
    // this.emergency.participants.forEach(participant =>{
    //   if (!deletedPersons.includes(participant.key)) {
    //     added.push(participant.key);
    //     const roles = [];
    //     for (const [roleKey, list] of this.roleMap) {
    //       if (list.includes(participant.key)) {
    //         roles.push(roleKey);
    //       }
    //     }
    //     if (participant.exposureIds) {
    //       participants.push({
    //         key: participant.key,
    //         newData: true,
    //         time: participant.time,
    //         timeEnd: participant.timeEnd,
    //         timeStart: participant.timeStart,
    //         exposureIds: participant.exposureIds,
    //         roles: roles
    //       });
    //     } else {
    //       participants.push({
    //         key: participant.key,
    //         newData: true,
    //         time: participant.time,
    //         timeEnd: participant.timeEnd,
    //         timeStart: participant.timeStart,
    //         roles: roles
    //       });
    //     }
    //   }
    // });

    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

      let date = this.emergency.date;
      if (ongoing.date !== date){
        date = moment(ongoing.date).format("DD.MM.YYYY");
      }

      const emergencyResponse: EmergencyResponse = {
        brisNumber: ongoing.brisNumber,
        createdById: this.emergency.createdById,
        date: date,
        dateCreated: this.emergency.dateCreated,
        changeLog: this.emergency.changeLog,
        details: ongoing.description,
        leaderKey: ongoing.leaderKey,
        evaluationId: this.emergency.evaluationId,
        location: locationData,
        participants: emergencyParticipants,
        timeEnd: ongoing.endTime,
        timeStart: ongoing.startTime,
        typeKey: ongoing.typeKey,
      };

      if (rolesToCreate){
        this.addRolesToDB(this.emergencyKey, rolesToCreate, this.emergency.date, today);
      }
      if (rolesToRemove){
        this.deleteRoles(this.emergencyKey, rolesToRemove);
      }

      if (!emergencyResponse.changeLog){
        emergencyResponse.changeLog = [];
      }
      if (emergencyResponse.leaderKey === undefined || emergencyResponse.leaderKey === null){
        emergencyResponse.leaderKey = "";
      }
      if (emergencyResponse.brisNumber === undefined || emergencyResponse.brisNumber === null){
        emergencyResponse.brisNumber = "";
      }
      if (emergencyResponse.typeKey === undefined || emergencyResponse.typeKey === null){
        emergencyResponse.typeKey = "";
      }
      if (!_.isEqual(this.exposedMapCopy, this.exposedMap)){
        if (!_.isEqual(this.evaluationCopy, this.evaluation)){
          this.saveEvaluation(uid, today, this.emergencyKey, emergencyParticipants,
            exposureParticipants, ongoing, locationData, emergencyResponse, this.evaluationCopy);
        } else {
          this.saveExposures('', uid, today, this.emergencyKey, emergencyParticipants,
            exposureParticipants, ongoing, locationData, emergencyResponse);
        }
      } else if (!_.isEqual(this.evaluationCopy, this.evaluation)){
        this.saveEvaluation(uid, today, this.emergencyKey, emergencyParticipants,
          exposureParticipants, ongoing, locationData, emergencyResponse, this.evaluationCopy);
      } else if (this.checkForChanges(emergencyResponse)){
        this.updateEmergency(this.emergencyKey, today, '', emergencyResponse);
      }
    });

    this.ref.close(true);
  }

  /**
   * Create Evaluation and returns key for emergency update.
   * @param emergencyParticipants
   * @param uid Created by
   * @param today
   * @param emergencyKey Emergency key
   * @param exposureParticipants
   * @param ongoing
   * @param locationData
   * @param emergencyResponse Emergency data
   * @param evaluationData
   */
  saveEvaluation(uid, today, emergencyKey, emergencyParticipants, exposureParticipants,
                 ongoing, locationData, emergencyResponse, evaluationData) {

    const emergencyEval: EmergencyEval = {
      createdById: uid,
      dateCreated: today,
      emergencyId: emergencyKey,
      description: evaluationData.description,
      measuresOnSite: evaluationData.measuresOnSite,
      learningPoints:	evaluationData.learningPoints,
      various: evaluationData.various,
    };
    if (evaluationData.key){
      const evaluationKey = evaluationData.key;
      delete emergencyEval.key;
      this.evaluationService.updateEmergencyEvaluation(evaluationKey, emergencyEval);
      if (_.isEqual(this.exposureMapCopy, this.exposureMap)){
        this.updateEmergency(emergencyKey, today, evaluationKey, emergencyResponse);
      } else {
        this.saveExposures(evaluationKey, uid, today, emergencyKey, emergencyParticipants,
          exposureParticipants, ongoing, locationData, emergencyResponse);
      }
    } else {
      this.evaluationService.addEmergencyEvaluationGetKey(emergencyEval).pipe(take(1))
        .subscribe(evaluationKey => {
          if (_.isEqual(this.exposureMapCopy, this.exposureMap)){
            this.updateEmergency(emergencyKey, today, evaluationKey, emergencyResponse);
          } else {
            this.saveExposures(evaluationKey, uid, today, emergencyKey, emergencyParticipants,
              exposureParticipants, ongoing, locationData, emergencyResponse);
          }
        });
    }
  }

  /**
   * Create Exposures and returns keys for emergency update.
   * @param evaluationKey
   * @param uid Created by
   * @param today Creation date
   * @param emergencyKey Emergency db key
   * @param emergencyParticipants List of emergency participants
   * @param exposureParticipants List of exposure participants
   * @param ongoing Ongoing emergency form
   * @param locationData Location data
   * @param emergencyResponse Emergency data
   */
  saveExposures(evaluationKey, uid, today, emergencyKey, emergencyParticipants, exposureParticipants,
                ongoing, locationData, emergencyResponse){

    let date = this.emergency.date;
    if (ongoing.date !== date){
      date = moment(ongoing.date).format("DD.MM.YYYY");
    }

    const changeInterface: ChangeLog = {
      changedBy: uid,
      changedDate: today,
      added: [],
      deleted: [],
      edited: []
    };

    // Generate exposures
    const newExpos = new Map<string, string[]>();
    for (const [personId, map] of this.exposedMap) {
      for (const [typeKey, data] of map) {
        if (data.boolValue === true){
          if (newExpos.has(typeKey)){
            newExpos.get(typeKey).push(personId);
          } else {
            newExpos.set(typeKey, [personId]);
          }
        }
      }
    }

    const exposToUpdate = new Map<string, {key: string, Ids: string[]}>();
    const exposNoChange = new Map<string, {key: string, Ids: string[]}>();

    // Compare to old exposures
    this.exposures.forEach(oldExpo => {
      if (newExpos.get(oldExpo.exposureType) && oldExpo.emergencyId === this.emergencyKey){
        const personIds = [];

        const time = new Map<string, {start: string, end: string}>();
        oldExpo.participants.forEach(participant => {
          personIds.push(participant.key);
          time.set(participant.key, {start: participant.exposureStart, end: participant.exposureEnd});
        });
        let timeChange = false;
        if (oldExpo.exposureType === 'Sot' || oldExpo.exposureType === 'Royk'){
          for (const [personId, map] of this.exposedMap) {
            if (time.get(personId)){
              if (map.get(oldExpo.exposureType).time.start !== time.get(personId).start ||
                map.get(oldExpo.exposureType).time.end !== time.get(personId).end){
                timeChange = true;
              }
            }
          }
        }
        if (personIds.filter(Id => !newExpos.get(oldExpo.exposureType).includes(Id))
          .concat(newExpos.get(oldExpo.exposureType).filter(x => !personIds.includes(x))).length !== 0)
        {
          const updatedIdList = personIds.filter(Id => newExpos.get(oldExpo.exposureType).includes(Id))
            .concat(newExpos.get(oldExpo.exposureType).filter(Id => !personIds.includes(Id)));
          exposToUpdate.set(oldExpo.exposureType, {key: oldExpo.key, Ids: updatedIdList});
          newExpos.delete(oldExpo.exposureType);
        } else{
          if (timeChange){
            exposToUpdate.set(oldExpo.exposureType, {key: oldExpo.key, Ids: newExpos.get(oldExpo.exposureType)});
            newExpos.delete(oldExpo.exposureType);
          } else {
            const list = [];
            oldExpo.participants.forEach(participant => list.push(participant.key));
            exposNoChange.set(oldExpo.exposureType, {key: oldExpo.key, Ids: list});
            newExpos.delete(oldExpo.exposureType);
          }
        }
      }
    }); // END Compare to old exposures

    // For deleting exposures
    const l = [];
    this.exposures.forEach(exposure => {
      if (exposure.emergencyId === this.emergencyKey && !exposToUpdate.get(exposure.exposureType)
        && !newExpos.get(exposure.exposureType) && !exposNoChange.get(exposure.exposureType)){
        changeInterface.deleted.push(exposure);
        l.push(exposure);
        this.exposureService.deleteExposure(exposure.key);
      }
    });

    // For updating exposures
    const exposureKeys = new Map<string, any>();
    for (const [key, obj] of exposToUpdate){
      const theseParticipants = [];
      for (const participant of exposureParticipants) {
        if (obj.Ids.includes(participant.key)){
          theseParticipants.push(participant);
          exposureKeys.set(participant.key, [obj.key]);
        }
      }

      if (key === 'Sot' || key === 'Royk'){
        theseParticipants.forEach(participant => {
          participant.exposureStart = this.exposedMap.get(participant.key).get(key).time.start;
          participant.exposureEnd = this.exposedMap.get(participant.key).get(key).time.end;
        });
      }

      let index = 0;
      for (let i = 0; i < this.exposures.length; i++) {
        if (this.exposures[i].key === obj.key){
          index = i;
          break;
        }
      }

      const exposure: Exposure = {
        brisNumber: ongoing.brisNumber,
        createdById: uid,
        date: date,
        dateCreated: today,
        details: ongoing.description,
        exposureType: key,
        location: locationData,
        participants: theseParticipants,
        timeEnd: ongoing.endTime,
        timeStart: ongoing.startTime,
        emergencyId: emergencyKey
      };

      changeInterface.edited.push({updated: exposure, old: this.exposures[index]});
      this.exposureService.updateExposure(obj.key, exposure);
    }// END updating exposures

    // New exposures
    for (const [key, values] of newExpos){
      const theseParticipants = [];

      for (const participant of exposureParticipants) {
        if (values.includes(participant.key)){
          theseParticipants.push(participant);
        }
      }
      if (key === 'Sot' || key === 'Royk'){
        theseParticipants.forEach(participant => {
          participant.exposureStart = this.exposedMap.get(participant.key).get(key).time.start;
          participant.exposureEnd = this.exposedMap.get(participant.key).get(key).time.end;
        });
      }

      const exposure: Exposure = {
        brisNumber: ongoing.brisNumber,
        createdById: uid,
        date: date,
        dateCreated: today,
        details: ongoing.description,
        exposureType: key,
        location: locationData,
        participants: theseParticipants,
        timeEnd: ongoing.endTime,
        timeStart: ongoing.startTime,
        emergencyId: emergencyKey
      };
      changeInterface.added.push(exposure);

      this.exposureService.addExposureGetKey(exposure)
        .pipe(take(1))
        .subscribe(exposureKey => {

          for (const participant of theseParticipants) {
            if (!exposureKeys.has(participant.key)) {
              exposureKeys.set(participant.key, [exposureKey]);
            } else {
              exposureKeys.get(participant.key).push(exposureKey);
            }
          }
        });
    } // END new exposures

    setTimeout(() => {
      this.updateEmergencyExposureIds(evaluationKey, emergencyKey, emergencyParticipants, exposureKeys,
        changeInterface, exposNoChange, emergencyResponse);
    }, 3000);

  }

  /**
   * Update newly created emergency
   * @param evaluationKey
   * @param emergencyKey Emergency db $$$$$$key
   * @param emergencyParticipants Emergency participants
   * @param exposureKeys Map of persons with corresponding exposure db keys
   * @param changeInterface ChangeLog
   * @param exposNoChange Exposures with no addedParticipants
   * @param emergencyResponse Emergency response
   */
  updateEmergencyExposureIds(evaluationKey, emergencyKey, emergencyParticipants, exposureKeys,
                             changeInterface, exposNoChange, emergencyResponse){

    for (const participant of emergencyParticipants) {
      if (exposureKeys.has(participant.key)) {
        participant.exposureIds = exposureKeys.get(participant.key);
      }
      exposNoChange.forEach(expo => {
        if (expo.Ids.includes(participant.key)){
          participant.exposureIds.push(expo.key);
        }
      });
    }

    this.changeLog.push(changeInterface);

    if (evaluationKey != null) {
      emergencyResponse.evaluationId = evaluationKey;
    }
    emergencyResponse.participants = emergencyParticipants;
    emergencyResponse.changeLog = this.changeLog;
    this.emergencyService.updateEmergency(emergencyKey, emergencyResponse);

  }

  /**
   * Updating emergency with evaluations key.
   * @param evaluationKey Unique evaluations key
   * @param emergencyKey Unique emergency key
   * @param emergencyData Emergency data
   * @param today
   */
  updateEmergency(emergencyKey, today, evaluationKey?, emergencyData?) {

    this.exposures.forEach(exposure => {
      if (exposure.emergencyId === this.emergencyKey){
        const expo: Exposure = {
          brisNumber: emergencyData.brisNumber,
          createdById: exposure.createdById,
          date: emergencyData.date,
          dateCreated: today,
          details: emergencyData.details,
          exposureType: exposure.exposureType,
          location: emergencyData.location,
          participants: exposure.participants,
          timeEnd: emergencyData.timeEnd,
          timeStart: emergencyData.timeStart,
          emergencyId: emergencyKey
        };
        this.exposureService.updateExposure(exposure.key, expo);
      }
    });

    this.emergencyService.updateEmergency(emergencyKey, emergencyData);
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.ref.close();
  }
}
