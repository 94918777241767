import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map, mergeMap, publish} from 'rxjs/operators';
import {TenantService} from './tenant.service';
import {FilterService} from 'primeng/api';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {EmergencyEval} from '../../model/emergencyEval';
import {ExposureEval} from '../../model/exposureEval';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private filterService: FilterService,
    private db: AngularFireDatabase
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Add new emergency evaluations and return key
   * @param evaluation Interface form data
   */
  addEmergencyEvaluationGetKey(evaluation: EmergencyEval): Observable<string>{
    return this.tenantId$.pipe(
      map(tenantId => {
        const ref = this.db.list(`tenants/${tenantId}/evaluations/emergencyEvaluations`).push(evaluation);
        return ref.key;
      })
    );
  }

  /**
   * Get all emergency evaluations
   */
  getEmergencyEvaluations(): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/evaluations/emergencyEvaluations`).snapshotChanges()
      )
    );
  }

  /**
   * Add new exposure evaluations and return key
   * @param evaluation Interface form data
   */
  addExposureEvaluationGetKey(evaluation: ExposureEval): Observable<string>{
    return this.tenantId$.pipe(
      map(tenantId => {
        const ref = this.db.list(`tenants/${tenantId}/evaluations/exposureEvaluations`).push(evaluation);
        return ref.key;
      })
    );
  }

  /**
   * Get all exposure evaluations
   */
  getExposureEvaluations(): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/evaluations/exposureEvaluations`).snapshotChanges()
      )
    );
  }

  /**
   * Update existing evaluation.
   * @param key Evaluation fbid
   * @param evaluation evaluation data
   */
  updateExposureEvaluation(key, evaluation: ExposureEval) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/evaluations/exposureEvaluations/${key}`).set(evaluation)
      )
    )).connect();
  }

  updateEmergencyEvaluation(key: string, evaluation: EmergencyEval) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/evaluations/emergencyEvaluations/${key}`).set(evaluation)
        )
    )).connect();
  }
}
