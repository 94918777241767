<div class="grid">
  <div class="col-12">

    <form
      [formGroup]="cronJobForm"
      (ngSubmit)="save()">

      <!-- NAME -->
      <div class="field">
        <label for="name">{{'COMMON.NAME' | translate}} *</label>
        <input
          id="name"
          type="text"
          formControlName="name"
          pInputText
          required
          class="inputfield w-full form-control"/>
      </div>

      <!-- ASSET -->
      <div class="field">
        <label for="assetSelect">{{'ASSET.ASSET' | translate}} *</label>
        <p-dropdown
          id="assetSelect"
          [options]="assetOptions"
          formControlName="assetId"
          (onChange)="setStation($event)"
          required
          [style]="{'width': '100%'}"
          optionValue="key"
          optionLabel="name"
          placeholder="{{'BUTTON.SELECT' | translate}}"
        >
        </p-dropdown>
      </div>

      <!-- STATION -->
      <div class="field">
        <label for="station">{{'COMMON.STATION' | translate}} *</label>
        <input
          id="station"
          type="text"
          [value]="stationNameMap.get(this.cronJobForm.value?.stationId)"
          [readOnly]="true"
          pInputText
          placeholder="{{'ASSET.ASSET_CARD.NO_STATION' | translate}}"

          class="inputfield w-full form-control"/>
      </div>

      <!-- CRON JOB -->
      <p-tabView [(activeIndex)]="tabIndex" (onChange)="weeklyOrMonthly()">
        <!-- WEEKLY -->
        <p-tabPanel formGroupName="weekly" header="{{'CHECKLIST.REPEAT_WEEKLY' | translate}}">
          <div class="grid mt-2">

            <!-- WEEK INTERVAL -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="weekInt">{{'CHECKLIST.INTERVAL' | translate}}{{' (1-8)'}} *</label>
                <p-inputNumber
                  id="weekInt"
                  class="mt-2 inputfield w-full"
                  formControlName="interval"
                  [style]="{'width': '100%'}"
                  [min]="1"
                  [max]="8"
                  suffix="&nbsp;{{'COMMON.WEEKS' | translate}}">
                </p-inputNumber>
              </div>
            </div>


            <!-- WEEK DELAY -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="weekDelay">{{'CHECKLIST.DELAY' | translate}}{{' (0-8)'}} *</label>
                <p-inputNumber
                  id="weekDelay"
                  class="mt-2 inputfield w-full"
                  formControlName="delay"
                  [style]="{'width': '100%'}"
                  [min]="0"
                  [max]="8"
                  suffix="&nbsp;{{'COMMON.WEEKS' | translate}}">
                </p-inputNumber>
              </div>
            </div>


            <!-- SELECT DAY -->
            <div class="col-12">
              <label for="selectDay">{{'CHECKLIST.CHOOSE_DAY' | translate}} *</label>
              <div class="field mt-2">
                <p-dropdown
                  id="selectDay"
                  formControlName="day"
                  placeholder="{{'COMMON.CHOOSE' | translate}}"
                  [options]="daysOption"
                  [style]="{'width': '50%'}"
                  optionValue="value"
                  optionLabel="name">
                </p-dropdown>
              </div>
            </div>

          </div>
        </p-tabPanel>

        <!-- MONTHLY -->
        <p-tabPanel formGroupName="monthly" header="{{'CHECKLIST.REPEAT_MONTHLY' | translate}}">
          <div class="grid mt-2">

            <!-- MONTH INTERVAL -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="monthInt">{{'CHECKLIST.INTERVAL' | translate}}{{' (1-24)'}}</label>
                <p-inputNumber
                  id="monthInt"
                  class="mt-2 inputfield w-full"
                  formControlName="interval"
                  [style]="{'width': '100%'}"
                  [min]="1"
                  [max]="24"
                  suffix="&nbsp;{{'COMMON.MONTHS.MONTHS' | translate}}">
                </p-inputNumber>
              </div>
            </div>

            <!-- START MONTH -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="selectMonth">{{'CHECKLIST.START_MONTH' | translate}}</label>
                <div class="field mt-2">
                  <p-dropdown
                    id="selectMonth"
                    [options]="monthOption"
                    formControlName="startMonth"
                    (ngModelChange)="getDays($event)"
                    [style]="{'width': '100%'}"
                    optionValue="value"
                    optionLabel="name">
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <!--CHOOSE DATE OR WHEN -->

          <!-- DATE -->
          <div class="col-12 flex flex align-items-center">

            <p-radioButton name="monthMode" [value]="0" [ngModelOptions]="{standalone: true}" [(ngModel)]="dayOrWhen" (ngModelChange)="swapDisable()"></p-radioButton>

            <p-dropdown
              appendTo="body"
              class="ml-3"
              formControlName="date"
              placeholder="{{'COMMON.DATE.DATE' | translate}}"
              [style]="{'width': '100px'}"
              [options]="days">
            </p-dropdown>

          </div>

          <!-- WHEN -->
          <div class="col-12 flex align-items-center">

            <p-radioButton name="monthMode" [value]="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="dayOrWhen" (ngModelChange)="swapDisable()"></p-radioButton>

            <p-dropdown
              appendTo="body"
              class="ml-3"
              placeholder="{{'COMMON.WHEN' | translate}}"
              [options]="whenOption"
              formControlName="when"
              [style]="{'width': '125px'}"
              optionValue="value"
              optionLabel="name">
            </p-dropdown>

            <p-dropdown
              appendTo="body"
              class="ml-3"
              [options]="daysOption"
              formControlName="day"
              placeholder="{{'CHECKLIST.CHOOSE_DAY' | translate}}"
              optionValue="value"
              optionLabel="name">
            </p-dropdown>
          </div>

        </p-tabPanel>
      </p-tabView>

      <div class="col-12 flex justify-content-end">
        <button
          pButton
          class="p-button-raised"
          [disabled]="!cronJobForm.valid"
          type="submit"
          label="{{'BUTTON.SAVE' | translate}}"
          icon="pi pi-bookmark"
        ></button>
      </div>

    </form>
  </div>
</div>
