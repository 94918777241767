<div class="grid">
  <div class="col-12 flex justify-content-end">
    <span class="p-buttonset">
      <button pButton pRipple icon="pi pi-plus" class="p-button-outlined" type="button" (click)="addAll()"
              label="{{'BUTTON.ADD_ALL' | translate}}"></button>
       <button pButton class="p-button-raised" icon="pi pi-bookmark" type="button" (click)="save()"
               label="{{'BUTTON.SAVE' | translate}}"></button>
    </span>

  </div>
  <div class="col-12">
    <p-table [value]="ctKeys" [loading]="loading" breakpoint="200px">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th style="width: 50px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ctKey>
        <tr>
          <td (click)="updateCTList(ctKey)" *ngIf="ctNameMap.has(ctKey)">{{ctNameMap?.get(ctKey)}}</td>
          <td *ngIf="!ctNameMap.has(ctKey)"><b>{{'COMMON.DELETED' | translate}}</b></td>
          <td><i style="color: #73BF21" [ngClass]="getStatus(ctKey)"></i></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
