import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFilter'
})
export class NameFilterPipe implements PipeTransform {

  transform(allTableFiles: File[], search?: string): any {
    if(!allTableFiles || !search){
      return allTableFiles;
    } else {
      return allTableFiles.filter(file => file.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
