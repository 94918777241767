<div class="formgrid grid">
    <input type="file" class="file-input"
           (change)="onFileSelected($event)" #fileUpload hidden>

    <!-- Upload file -->
    <div class="file-upload col-12 formgroup-inline pb-2" style="height:48px">
        <div class="col-fixed p-0" style="width:39px">
            <button class="upload-btn" pButton type="button" icon="far fa-cloud-upload" (click)="fileUpload.click()"></button>
        </div>
        <div class="field col-10 pl-0">
            <input  class="w-full" type="text" pInputText placeholder="{{'FILE.CHOOSE_FILE' | translate }}" [(ngModel)]="fileName" />
        </div>
    </div>

    <ng-container *ngIf="uploading">
        <div class="col-12 pb-2">
            <p-progressBar mode="indeterminate" [style]="{height: '4px', color:'#16214D'}"></p-progressBar>
        </div>
    </ng-container>

    <div class="col-8">
        <p-selectButton [options]="stateOptions" [(ngModel)]="adminOnly" optionLabel="label" optionValue="value"></p-selectButton>
    </div>

    <div class="col-4 flex justify-content-end">
        <p-confirmDialog [style]="{width: '50vw'}"
                         [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                         rejectButtonStyleClass="p-button-text"
                         header="{{'CONFIRMATION.EXISTS' | translate}}"
                         acceptLabel="{{'CONFIRMATION.YES' | translate}}"
                         rejectLabel="{{'CONFIRMATION.ABORT' | translate}}"></p-confirmDialog>
        <button class="p-button-raised" pButton label="{{'BUTTON.UPLOAD' | translate}}" (click)="upload()"
                [disabled]="uploading"></button>
    </div>

</div>
