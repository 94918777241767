import { Injectable } from '@angular/core';
import {map, mergeMap, publish} from 'rxjs/operators';
import {SendSMS} from '../../model/sendSMS';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {OrderSMS} from '../../model/orderSMS';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get all sent sms.
   */
  getAllSMS(): Observable<any[]>{
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/sendSMS`).snapshotChanges()
        )
    );
  }
  /**
   * Get available SMS count.
   */
  getAvailableSMS(){
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/smsOverview/available`).valueChanges()
      )
    );
  }

  /**
   * Send SMS.
   * @param sendSMS SMS object.
   */
  addSendSMS(sendSMS: SendSMS){
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/sendSMS`).push(sendSMS))
      )
    ).connect();
  }

  /**
   * Place sms order
   * @param smsOrder Order object
   */
  placeOrder(smsOrder: OrderSMS) {
    return publish()(this.tenantId$.pipe(
            map(
                tenantId => this.db.list(`tenants/${tenantId}/smsOrders`).push(smsOrder))
        )
    ).connect();
  }
}
