<!-- ConfirmDialog -->
<p-confirmDialog
  [style]="{minWidth: '350px'}"
  header="{{'WARNING.CONFIRMATION' | translate}}"
  icon="pi pi-exclamation-circle"
  rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>

<!-- Toast -->
<p-toast key='ccd' position="top-right" styleClass="customToast"></p-toast>

<div class="grid">
  <div class="col-12">

    <!-- STATUS FIELD SET -->
    <p-fieldset legend="{{'COMMON.STATUS' | translate}}" [toggleable]="true">
      <div class="grid">

        <div class="col-6"><i class="far fa-building"></i> {{stationsMap?.get(checklist.stationId)}}</div>

        <div class="col-6"><i class="far fa-car"></i> {{assetMap?.get(checklist.assetId)}}</div>

        <div class="col-12">
          <b>{{'CHECKLIST.COMPLETED_BY' | translate}}{{': '}}</b>{{name$ | async}}
        </div>
      </div>
    </p-fieldset>

  </div>

  <!-- BUTTON SET -->
  <div class="col-12 flex justify-content-end">

    <!-- OPEN COMMENTS -->
    <button
      pButton
      class="p-button-outlined p-button-raised"
      type="button"
      icon="pi pi-comments"
      (click)="openComments()"
      label="{{'COMMON.COMMENTS' | translate}}{{'(' + (getLength(checklist?.comments)) + ')'}}">
    </button>

    <!-- SPLIT BUTTON -->
    <p-splitButton label="{{'BUTTON.UNDO' | translate}}" icon="pi pi-replay" (onClick)="undo()" [model]="splitMenu"></p-splitButton>

  </div>

  <div class="col-12 p-0">

    <!-- TABLE -->
    <p-table [value]="checklist?.items | keyvalue" responsiveLayout="scroll">

      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <th class="col-fixed" style="width: 3rem">#</th>
          <th class="col">{{'COMMON.NAME' | translate}}</th>
          <th class="col-fixed" style="width: 3rem"></th>
        </tr>
      </ng-template>

      <!-- BODY -->
      <ng-template pTemplate="body" let-item>
        <tr>
          <td *ngIf="!deviationMap.has(item.key)"><p-checkbox [ngModel]="item.value.checked" [readonly]="true" [binary]="true"></p-checkbox></td>
          <td *ngIf="deviationMap.has(item.key)">
            <span [ngStyle]="getStyle(item)">
              <i class="far fa-exclamation-triangle"></i>
            </span>
          </td>
          <td>{{item.value.name}}</td>
          <td>
            <p-menu #menu  appendTo="body" [popup]="true" [model]="menuItemsMap.get(item.key)"></p-menu>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-cog"
              (click)="menu.toggle($event)"
              class="p-button-rounded p-button-text">
            </button>
          </td>
        </tr>
      </ng-template>

      <!-- EMPTY MESSAGE -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>
