
<form [formGroup]="competencyForm" (ngSubmit)="onSubmit()">
    <div class="grid">
        <div  class="col-6">
            <p-dropdown [options]="groupedSubjects" formControlName="personId"
                        placeholder="{{'SUBJECTS.CHOOSE_PERSON' | translate}}*"
                        [group]="true" [style]="{'width' : '100%'}" [filter]="true" filterBy="label" [showClear]="true">
                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                        <i class="far fa-warehouse"></i>&nbsp;{{group.label}}
                    </div>
                </ng-template>
                <ng-template let-person pTemplate="item">
                    <div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{person.label}}
                    </div>
                </ng-template>
            </p-dropdown>

        </div>
        <div class="col-6">
            <!-- TODO: FIX CALENDAR LANGUAGE ALL PLACES-->
            <p-calendar placeholder="{{'COMMON.VALID_FROM' | translate}}"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%'}"
                        appendTo="body"
                        class="w-full inputfield"
                        formControlName="validFrom"
            ></p-calendar>
        </div>
        <div  class="col-12">
            <p-dropdown [options]="ctList" formControlName="competencyTypeId"
                        [filter]="true" filterBy="name" [showClear]="true"
                        placeholder="{{'BUTTON.CHOOSE_COMPETENCY' | translate}}*"
                        optionValue="key" optionLabel="name"
                        [style]="{'width' : '100%'}">
            </p-dropdown>
        </div>
        <div class="col-12 mt-2">
            <span class="p-float-label">
                <textarea id="textarea" rows="10"
                          style="resize: none;" cols="30"
                          pInputTextarea class="inputfield w-full"
                          formControlName="comment"></textarea>
                <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
            </span>
        </div>
        <!-- Save button -->
        <div class="col-12 flex justify-content-end">
            <button pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
                    (click)="close()" class="p-button-outlined float-right p-button-raised"></button>
            <p-button label="{{'BUTTON.SAVE' | translate}}"
                      icon="pi pi-bookmark"
                      [disabled]="!competencyForm.valid"
                      (onClick)="onSubmit()"
            ></p-button>
        </div>
    </div>
</form>
