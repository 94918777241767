<div class="grid">
  <div class="col-8" *ngIf="!fromDashboard">
      <h2>{{'MAIN_FRAME.TASKS' | translate}}</h2>
  </div>
  <div class="col-12 w-full banner flex justify-content-center" style="background-color: #16214D" *ngIf="fromDashboard">
    <h3 style="color: white" class="m-1">{{'TABLE.TASKS' | translate}}</h3>
  </div>
  <div class="col-4 flex justify-content-end align-content-center" *ngIf="!fromDashboard">
    <button pButton pRipple (click)="addTask()" type="button" icon="pi pi-plus"
            class="p-button-rounded"></button>
  </div>
  <!-- TASK TABLE -->
  <div class="col-12 pr-0 pl-0">
    <p-table #att [value]="tasks | dashboardFilter: 'task' : selectedTaskFilter?.key : stations" [rowHover]="true"
             [loading]="taskLoading" [globalFilterFields]="['name']"  breakpoint="200px"
             [rows]="rows" [showCurrentPageReport]="true" responsiveLayout="scroll"
             [paginator]="true" currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'TABLE.TASKS' | translate}}"
    >
      <ng-template pTemplate="header">
        <tr *ngIf="!fromDashboard">
          <th colspan="2">
            <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="att.filterGlobal($event.target.value, 'contains')"
                   placeholder="{{'COMMON.SEARCH' | translate}}"/>
          </span>
          </th>
          <th colspan="2">
           <div class="flex justify-content-end">
             <p-dropdown [options]="taskFilter" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}" [(ngModel)]="selectedTaskFilter" optionLabel="name">
               <ng-template pTemplate="selectedItem">
                 <div class="task-item task-item-value" *ngIf="selectedTaskFilter">
                   <div>{{selectedTaskFilter.name | translate}}</div>
                 </div>
               </ng-template>
               <ng-template let-filter pTemplate="item">
                 <div class="filter-item">
                   <div>{{filter.name | translate}}</div>
                 </div>
               </ng-template>
             </p-dropdown>
           </div>
          </th>
        </tr>
        <tr>
          <th style="width: 40%" class="p-2">{{'COMMON.NAME' | translate}}</th>
          <th class="p-2">{{'ASSET.ASSET' | translate}}</th>
          <th class="p-2">{{'COMMON.DATE.DATE' | translate}}</th>
          <th class="p-2">{{'COMMON.TIME' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-task>
        <tr (click)="viewTask(task)">
          <td style="width: 40%" class="p-2">{{task.name}}</td>
          <td class="p-2">{{assetNameMap.get(task.assetId)}}</td>
          <td class="p-2">{{getDate(task.deadline)}}</td>
          <td class="p-2">{{task.deadline | amDateFormat: 'HH:mm'}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">{{'DASHBOARD.TASK_CARD_NOT_FOUND' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
