<div class="grid">

  <div class="col-12">
    <h2 style="margin-top: 10px">{{'EXERCISE.COMPLETED' | translate}}</h2>
  </div>
  <div class="row">
    <div class="col flex justify-content-between">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="et.filterGlobal($event.target.value, 'contains')"
          placeholder="{{'COMMON.SEARCH' | translate}}"/>
      </span>
      <!-- Filter dropdown -->
      <p-dropdown *ngIf="userStatus.get('admin') || userStatus.get('superUser')"
                  class="ml-auto float-right" [options]="exerciseFilter" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"
                  [(ngModel)]="selectedExerciseFilter" optionLabel="name">
        <ng-template pTemplate="selectedItem">
          <div class="exercise-item exercise-item-value" *ngIf="selectedExerciseFilter">
            <div>{{selectedExerciseFilter.name | translate}}</div>
          </div>
        </ng-template>
        <ng-template let-filter pTemplate="item">
          <div class="filter-item">
            <div>{{filter.name | translate}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <!-- EXERCISE TABLE -->
  <div class="col-12 pl-0 pr-0">
    <p-table #et [value]="exercises | dashboardFilter : 'exercise' : selectedExerciseFilter?.key" [rowHover]="true"
             [loading]="exerciseLoading" [globalFilterFields]="['name']" responsiveLayout="stack" breakpoint="200px">

      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th style="width:35%">{{'COMMON.DATE.DATE' | translate}}</th>
          <th style="width:3rem">
            <span class="flex justify-content-center">
              <i class="far fa-graduation-cap"></i>
            </span>
          </th>
          <th style="width:3rem">
             <span class="flex justify-content-center">
               <i class="far fa-users"></i>
             </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-exercise>
        <tr (click)="exerciseDetails(exercise)">
          <td>{{exercise.name}}</td>
<!--          <td>{{exercise.date | amDateFormat: 'DD.MM.YY HH:mm'}}</td>-->
          <td class="breakAll">{{printDate(exercise)}}</td>
          <td>
            <div class="flex justify-content-center">
              {{competencyCount(exercise)}}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center">
              {{participantCount(exercise)}}
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'EXERCISE.NO_EXERCISE' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
