import {Pipe, PipeTransform} from '@angular/core';
import {Checklist} from "../model/checklist";

@Pipe({
  name: 'checklistFilter'
})
export class ChecklistFilterPipe implements PipeTransform {

  transform(checklists, stationId): Checklist[] {
    if(!stationId){
      return checklists
    } else {
      return checklists.filter(checklist => checklist.stationId === stationId)
    }
  }

}
