import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubjectService} from "../../../services/service/subject.service";
import {ConfirmationService, MenuItem, MessageService, TreeDragDropService, TreeNode} from "primeng/api";
import {combineLatest, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {NewSubjectComponent} from "../new-subject/new-subject.component";
import {PersonDetailComponent} from "../person-detail/person-detail.component";
import {TeamDetailComponent} from "../team-detail/team-detail.component";
import {StationDetailComponent} from "../station-detail/station-detail.component";

@Component({
  selector: 'app-subject-list',
  templateUrl: './subject-list.component.html',
  styleUrls: ['./subject-list.component.scss'],
  providers: [ConfirmationService, TreeDragDropService, DialogService, DynamicDialogRef]
})
/**
 * This Component shows a Subjects Tree, and reacts to click on subject by showing details.
 */
export class SubjectListComponent implements OnInit, OnDestroy {
  subjects: TreeNode[];
  loading = true;
  subscription = new Subscription();
  selectedSubject;
  splitButton: MenuItem[];
  foundNode = false;

  constructor(
    private subjectService: SubjectService,
    private translateService: TranslateService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
  ) {

    /**
     * Update tree when addedParticipants is made to subject, or relation.
     */
    this.subscription.add(
      combineLatest([this.subjectService.getSubjects(), this.subjectService.getRelations()])
        .subscribe(([subjects, relations]) => {

          // Get the children of Ancestor, and load Tree
          this.subjectService.getChildrenOfAncestor().pipe(
            take(1),
          ).subscribe(c => {

            this.subjects = c;
            this.subjects.forEach(subject => {
              this.getChildren(subject);
            });

            const addedPersons = new Map();
            if (this.subjects) {
              subjects = [];
              const subjectMap = new Map(subjects.map(subject => {
                if (subject.payload.val().archive === false){
                  return [subject.key, subject.payload.val()];
                }
              }));
              const parentChildMap = new Map();
              relations.forEach(relation => {
                if (parentChildMap.has(relation.parentId)) {
                  const childArray = parentChildMap.get(relation.parentId);
                  childArray.push(relation.childId);
                  parentChildMap.set(relation.parentId, childArray);
                  addedPersons.set(relation.childId, true);
                } else {
                  const childArray = [];
                  childArray.push(relation.childId);
                  parentChildMap.set(relation.parentId, childArray);
                  addedPersons.set(relation.childId, true);
                }
              });
              this.subjectService.getAncestor().pipe(
                take(1),).subscribe(ancestors => {
                const ancestor = ancestors[0];
                const children = new Map(parentChildMap.get(ancestor.key).map(child => [child, true]));
                let deleteArray = null;

                this.subjects.forEach((node, index) => {
                  if (children.has(node.key)) {
                    children.delete(node.key);
                  } else {
                    deleteArray = index;
                  }
                });
                if (deleteArray) {
                  this.subjects.splice(deleteArray, 1);
                }
                if (children.size > 0) {
                  for (const key of children.keys()) {
                    const subject = subjectMap.get(key);
                    this.subjects.push(this.createNode(subject, key));
                  }
                }
                this.subjects.forEach(node => {
                  this.updateTreeRecursive(node, subjectMap, parentChildMap);
                });
              });
            }

            this.loading = false;
          });
          }
        )
    );

    // Build split button on create.
    this.buildSplitButton();


    // Update split button on language change
    this.subscription.add(
      this.translateService.onLangChange.pipe().subscribe(() => {
        this.buildSplitButton();
      })
    );
  }

  ngOnInit(): void {
  }

  /**
   * Create treeNode
   * @param subject Subject Node
   * @param key Subject key
   */
  createNode(subject: any, key) {
    if (subject.type === 'FIRETEAM') {
      return {
        label: subject.name,
        key: key,
        data: "FIRETEAM",
        icon: 'far fa-users',
        leaf: false,
      };
    } else if (subject.type === 'PERSON') {
      return {
        label: subject.name,
        key: key,
        data: "PERSON",
        icon: 'far fa-user',
        leaf: true,
        droppable: false,
      };
    } else if (subject.type === "FIRESTATION") {
      return {
        label: subject.name,
        key: key,
        data: "FIRESTATION",
        icon: 'far fa-building',
        leaf: false,
        draggable: false,
      };
    }

  }

  /**
   * Update tree structure recursively
   * @param node A node
   * @param subjectMap Subject map
   * @param parentChildMap Parent/child map
   */
  updateTreeRecursive(node: TreeNode, subjectMap, parentChildMap) {
    const subject = subjectMap.get(node.key);
    let children = new Map();
    if (parentChildMap.has(node.key)) {
      children = new Map(parentChildMap.get(node.key).map(child => [child, true]));
    }
    if(!node.children){
      node.children = [];
    }

    if (node.label !== subject.name) {
      node.label = subject.name;
    }

    if(node.children.length > 0){
      node.children.forEach((child,index) => {
        if (children.has(child.key)) {
          children.delete(child.key);
        } else {
          node.children = node.children.filter(c => c.key !== child.key);
        }
      });
    }
    if (children.size > 0) {
      for (const key of children.keys()) {
        node.children.push(this.createNode(subjectMap.get(key), key));
      }
    }

    if(node.children.length > 0){
      node.children.forEach(child => {
        this.updateTreeRecursive(child, subjectMap, parentChildMap);
      });
    } else {
      node.expanded = false;
    }
  }

  /**
   * Load the tree on Construct, so that we can use search
   * @param node
   */
  getChildren(node) {
    this.subjectService.getChildren(node.key).pipe(take(1))
      .subscribe(children => {
        node.children = children;
        if (children.length > 0) {
          children.forEach(child => this.getChildren(child));
        }
      });
  }

  /**
   * Handles the event of Node Expand
   * @param event
   */
  nodeExpand(event: any) {
    this.subjectService.getChildren(event.node.key).pipe(
      take(1),
    ).subscribe(children => {
      event.node.children = children;
      if (children.length === 0) {
        this.messageService.add(
          {key: 'subject-list', severity: 'info', detail: this.translateService.instant('WARNING.NO_DATA'), life: 4000});
        event.node.expanded = false;
      }
    });
  }

  /**
   * Handles the event of Node Drop
   * @param event
   */
  nodeDrop(event: any) {
    console.log(event);
  }

  /**
   * Handles the event of Node Unselect
   * @param event
   */
  nodeUnselect(event: any) {
    // this.confirmationService.confirm({
    //   message: this.translateService.instant('WARNING.REMOVE'),
    //   accept: () => {
    //     const subjectId = event.node.key;
    //     this.subjectService.getChildren(subjectId).emergencyFilter(
    //       take(1),
    //     ).subscribe(children => {
    //       if (children.length > 0) {
    //         this.messageService.add(
    //           {
    //             key: 'subject-list', severity: 'error',
    //             detail: this.translateService.instant('WARNING.REMOVE_ERR_NOT_EMPTY'), life: 4000
    //           });
    //       } else {
    //         this.subjectService.deleteSubject(subjectId);
    //         this.subjectService.deleteRelationByChild(subjectId)
    //       }
    //     })
    //   }
    // })
  }

  /**
   * Handles the event of node Select
   * @param event
   */
  nodeSelect(event: any) {
    if (event.node.data === 'PERSON') {
      this.collapseAll();
      const personDetail = this.dialogService.open(PersonDetailComponent, {
        header: event.node.label, styleClass: 'max-size-dialog',
        data: {
          subjectId: event.node.key,
        }
      });
      personDetail.onClose.pipe(
        take(1),
      ).subscribe(subjectId => {
        if (subjectId) {
          this.ref.close(subjectId);
        }
      });
    } else if (event.node.data === 'FIRETEAM') {
      this.collapseAll();
      this.dialogService.open(TeamDetailComponent, {
        header: event.node.label, styleClass: 'max-size-width-dialog',
        data: {
          subjectId: event.node.key,
        },
      });
    } else if (event.node.data === 'FIRESTATION') {
      this.collapseAll();
      this.dialogService.open(StationDetailComponent, {
        header: event.node.label, styleClass: 'max-size-width-dialog',
        data: {
          subjectId: event.node.key,
        },
      });
    }
  }

  /**
   * Add a new subject.
   * @param type
   */
  addSubject(type) {
    this.dialogService.open(NewSubjectComponent, {
      header: this.translateService.instant('BUTTON.ADD'),
      data: {
        type: type
      },
    });
  }

  /**
   * Recursive method to collapse the tree
   */
  collapseAll() {
    this.subjects.forEach(node => {
      this.expandRecursive(node, false);
    });
  }

  /**
   * Recursive method to expand the tree
   */
  expandAll() {
    this.subjects.forEach(node => {
      this.expandRecursive(node, true);
    });
  }

  /**
   * Unsubscribe on Destroy to prevent memory leak.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  /**
   * Method to build split button
   *
   * @private
   */
  private buildSplitButton() {
    this.splitButton = [
      {
        label: this.translateService.instant('SUBJECTS.NEW_TEAM'), icon: 'far fa-users', command: () => {
          this.addSubject('FIRETEAM');
        }
      },
      {separator: true},
      {
        label: this.translateService.instant('STATION.NEW'), icon: 'far fa-building', command: () => {
          this.addSubject('FIRESTATION');
        }
      },
      {separator: true},
      {
        label: this.translateService.instant('BUTTON.COLLAPSE_ALL'), icon: 'far fa-minus', command: () => {
          this.collapseAll();
        }
      }
    ];
  }

  /**
   * Is used by @collapseAll and @expandAll
   * @param node
   * @param isExpand
   * @private
   */
  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach(childNode => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

}
