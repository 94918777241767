import { Component, OnInit } from '@angular/core';
import {PersonSubject} from '../../../model/subject';
import {AccessControlService} from '../../../services/service/access-control.service';
import {AuthService} from '../../../services/service/auth.service';
import {SubjectService} from '../../../services/service/subject.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import { getAuth, updatePassword } from "firebase/auth";
import {Password} from 'primeng/password';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-change-pw',
  templateUrl: './change-pw.component.html',
  styleUrls: ['./change-pw.component.scss'],
  providers: [MessageService, TranslateService, Password]

})
export class ChangePwComponent implements OnInit {

  pw1: string;
  pw2: string;

  subject: PersonSubject;
  subjectId: string;

  constructor(
      private acService: AccessControlService,
      private authService: AuthService,
      private subjectService: SubjectService,
      private messageService: MessageService,
      private translateService: TranslateService,
      private ref: DynamicDialogRef,
  ) {
  }

  ngOnInit(): void {
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      this.acService.getSubjectIdFromUID(uid).pipe(take(1)).subscribe(subjectId => {
        this.subjectId = subjectId;
        this.subjectService.getPerson(subjectId).pipe(take(1)).subscribe(subject => {
          this.subject = subject;
        })
      })
    });
  }

  updatePW() {
    if (this.pw1 === this.pw2) {
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,})/.test(this.pw1)) {
        this.messageService.add({
          severity: 'error', summary: this.translateService.instant('TOAST.ERROR'),
          detail: this.translateService.instant('PASSWORD.TRY_AGAIN')
        });
      } else {
        const auth = getAuth();
        const user = auth.currentUser;
        updatePassword(user, this.pw1).then(() => {
          this.messageService.add({
            severity: 'success', summary: this.translateService.instant('TOAST.SUCCESS'),
            detail: this.translateService.instant('PASSWORD.SUCCESS')
          });
          this.ref.close();
        }).catch((error) => {
          console.log(error)
          this.messageService.add({
            severity: 'error', summary: this.translateService.instant('TOAST.SUCCESS'),
            detail: this.translateService.instant('TOAST.FAILED'), life: 10000
          });
        });
      }

    } else {
      this.messageService.add({
        severity: 'error', summary: this.translateService.instant('TOAST.ERROR'),
        detail: this.translateService.instant('MY_PAGE.INPUT_DONT_MATCH')
      });
    }
  }
}
