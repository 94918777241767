import {Component, OnDestroy, OnInit} from '@angular/core';
import {FileTag} from '../../../model/fileRelated';
import {FileService} from '../../../services/service/file.service';
import {combineLatest, Subscription} from 'rxjs';
import {ExistingTagFilterPipe} from '../../../pipes/existing-tag-filter.pipe';
import {AuthService} from '../../../services/service/auth.service';
import {take} from 'rxjs/operators';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-file-tags',
  templateUrl: './file-tags.component.html',
  styleUrls: ['./file-tags.component.scss'],
  providers: [ExistingTagFilterPipe, ConfirmationService]
})
export class FileTagsComponent implements OnInit, OnDestroy {
  existingTagRelations = new Map<string, string[]>();
  totalRecords: number;
  tableTags: FileTag[] = [];
  existingTags: FileTag[] = [];
  tagName = "";

  subscription = new Subscription();

  loading = true;

  constructor(
      private fileService: FileService,
      private tagFilter: ExistingTagFilterPipe,
      private authService: AuthService,
      private messageService: MessageService,
      private translateService: TranslateService,
      private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.subscription.add(combineLatest([this.fileService.getFileTags(), this.fileService.getFileTagRelations()])
        .subscribe(([tags, relations]) => {
          this.existingTags = tags.map(tag => {
            if (!tag.archived) {
              return {key: tag.key, ...tag.payload.val()};
            }
          });
          this.totalRecords = this.existingTags.length;
          relations.forEach(relation => {
            if (this.existingTagRelations.has(relation.payload.val().tagId)){
              this.existingTagRelations.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
            } else {
              this.existingTagRelations.set(relation.payload.val().tagId, [relation.payload.val().fileId]);
            }
          });
        })
    );
    this.loading = true;
  }

  /**
   * LazyLoad table
   * @param $event LoadEvent
   */
  loadFileTags($event: LazyLoadEvent) {
    this.loading = true;

    setTimeout(() => {
      if (this.existingTags){
        this.tableTags = [];
        if ($event.first + $event.rows <= this.totalRecords){
          for (let i = $event.first; i < $event.first + $event.rows; i++) {
            this.tableTags.push(this.existingTags[i]);
          }
        } else {
          for (let i = $event.first; i < this.totalRecords; i++) {
            this.tableTags.push(this.existingTags[i]);
          }
        }
        this.loading = false;
      }
    }, 100);
  }

  /**
   * Add new tag
   * @param tagName Tag name
   */
  addTag(tagName: string) {
    if (this.validateName(tagName)){
      tagName = tagName.toLowerCase();
      this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
        const first = tagName.charAt(0).toUpperCase();
        const rest = tagName.slice(1);
        this.fileService.addTag({createdById: uid, archived: false, tagName: first+rest});
        tagName = "";
        this.messageService.add({key: 'error', severity:'success',
          summary: this.translateService.instant('TOAST.SUCCESS'),
          detail: this.translateService.instant('SUCCESS.SAVED')}
        );
      });
    } else {
      this.messageService.add({key: 'error', severity:'error',
        summary: this.translateService.instant('TOAST.ERROR'),
        detail: this.translateService.instant('WARNING.TAG_EXISTS')}
      );
    }
  }

  /**
   * Returns true if tag does not already exist
   * @param tagName string
   */
  validateName(tagName: string){
    let value = true;
    this.existingTags.some(tag =>{
      if (tag.tagName.toLowerCase() === tagName.toLowerCase()){
        value = false;
      }
    });
    return value;
  }

  /**
   * Confirm Archive or of creation fileTag
   * @param tagName Tag Name string
   * @param tagObj Tag object.
   */
  confirm(tagName: string, tagObj?: any,) {
    let message;
    if (!tagObj){
      message = this.translateService.instant('WARNING.ARE_YOU_SURE_CREATE_FILE_TAG');
    } else {
      message = this.translateService.instant('WARNING.ARE_YOU_SURE_ARCHIVE');
    }
    this.confirmationService.confirm({
      message: message,
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        if (tagObj) {
          this.archive(tagObj.key);
        } else {
          this.addTag(tagName);
        }
      }
    });
  }

  /**
   * Archive fileTag
   * @param tagObj tagObject
   */
  archive(tagObj: any) {
    tagObj.archived = true;
    this.fileService.updateTag(tagObj.key, tagObj);
  }

  /**
   * Search for existing tag name.
   * @param $event
   */
  searchExisting($event: any) {
    this.existingTags = this.tagFilter.transform(this.existingTags, $event.query);
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
