import {Injectable} from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {map, mergeMap, publish} from "rxjs/operators";
import {CronJob} from "../../model/cronJob";
import {AssetService} from "./asset.service";
import {AccessControlService} from "./access-control.service";
import {DeviationService} from "./deviation.service";
import {Checklist, ChecklistItem, ChecklistTemplate} from "../../model/checklist";
import {TranslateService} from "@ngx-translate/core";

// noinspection JSPotentiallyInvalidUsageOfClassThis
@Injectable({
  providedIn: 'root'
})
/**
 * Handles everything with checklists
 */
export class ChecklistService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
    private assetService: AssetService,
    private acService: AccessControlService,
    private deviationService: DeviationService,
    public translateService: TranslateService,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getChecklist(key): Observable<Checklist> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}`).valueChanges()
      )
    ) as Observable<Checklist>;
  }

  getChecklistStationId(key): any {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}/stationId`).valueChanges()
      )
    );
  }

  getChecklistAssetId(key): any {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}/assetId`).valueChanges()
      )
    );
  }

  /**
   * Get all Uncompleted Checklists
   */
  getUncompletedChecklists(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklists`, ref => {
          return ref.orderByChild('completed').equalTo(false);
        }).snapshotChanges()
      )
    );
  }

  /**
   * Get CronJobs
   */
  getCronJobs(): Observable<CronJob[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/cronJobs`).valueChanges()
      )
    ) as Observable<CronJob[]>;
  }

  /**
   * Get CronJob with ChecklistId
   */

  getCronJobWithChecklistId(checklistId): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/cronJobs`, ref => {
          return ref.orderByChild('checklistId').equalTo(checklistId);
        }).snapshotChanges()
      )
    );
  }

  /**
   * Get ChecklistTemplates
   */
  getChecklistTemplates(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklistTemplates`).valueChanges()
      )
    );
  }

  /**
   * Get ChecklistTemplates
   */
  getChecklistTemplatesWithKeys(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/checklistTemplates`).snapshotChanges()
        )
    );
  }

  /**
   * Get Payload of ActiveChecklists
   */
  getActiveChecklistsPayload(): Observable<any> {
    return combineLatest([
      this.getCronJobs(),
      this.getUncompletedChecklists(),
      this.getChecklistTemplates(),
    ]).pipe(
      map(([cronJobs, checklists, templates]) => {
        const hasCron = new Map;
        const hasTemplate = new Map;

        cronJobs.forEach(cj => hasCron.set(cj.checklistId, true));
        templates.forEach(temp => hasTemplate.set(temp.checklistId, true));

        return checklists
          .filter(cl => (!hasCron.has(cl.key) && !hasTemplate.has(cl.key)))
          .map(cl => {
            return (cl);
          });
      }));
  }

  /**
   * Get Active Checklists and Asset combined into an object.
   */
  getActiveChecklistsWithAsset(): Observable<any> {
    return combineLatest([this.getActiveChecklistsPayload(), this.assetService.getAssets()])
      .pipe(
        map(([checklists, assets]) => {
          const assetMap = new Map();
          assets.map(asset => {
            const key = asset.key;
            const payload = asset.payload.val();
            assetMap.set(asset.key, {key, ...payload});
          });
          return checklists.map(c => {
            const checklist = c.payload.val();
            const key = c.key;
            const asset = (assetMap.get(checklist.assetId));
            return {key, ...checklist, asset};
          });
        })
      );
  }

  /**
   * Get the checklist that's connected to ChecklistTemplates
   */
  getChecklistWithTemplate(): Observable<any> {
    return combineLatest([this.acService.getOwnAccessControl(), this.getUncompletedChecklists(), this.getChecklistTemplates()])
      .pipe(
        map(([ac, checklists, templates]) => {

          let templatesMap;
          const result = [];

          if (ac.roles.user) {
            templates = templates.filter(temp => temp.appAccess);
          }

          const appAccess = templates.filter(temp => temp.appAccess);
          templatesMap = new Map(templates.map(temp => [temp.checklistId, true]));

          checklists.forEach(c => {
            if (templatesMap.has(c.key)) {
              let app = false;
              if (appAccess.length > 0){
                appAccess.forEach(t => {
                  if (t.checklistId === c.key && t.appAccess){
                    app = true;
                  }
                });
              }
              const checklist = c.payload.val();
              const key = c.key;
              result.push({key, appAccess: app, ...checklist});
            }
          });
          return result;
        })
      );
  }

  /**
   * Get Repeating Checklists
   */
  getRepeatingChecklists(): Observable<any> {
    return combineLatest([this.getUncompletedChecklists(), this.getCronJobs()])
      .pipe(
        map(([checklists, cronJobs]) => {
          let cronJobMap;
          const result = [];

          cronJobMap = new Map(cronJobs.map(cron => [cron.checklistId, cron]));
          checklists.forEach(c => {
            const cron = cronJobMap.get(c.key);
            if (cron && !cron.archive) {
              const checklist = c.payload.val();
              const key = c.key;
              result.push({key, ...checklist, cron});
            }
          });
          return result;
        })
      );
  }

  /**
   * Get the checklist deviations for this checklist
   * @param key
   */
  getChecklistDeviationsFromChecklistID(key): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklistDeviations`, ref => {
          return ref.orderByChild('checklistId').equalTo(key);
        }).snapshotChanges()
      )
    );
  }

  /**
   * Get all ChecklistDeviations as a SNAPSHOT
   */
  getChecklistDeviations(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklistDeviations`).snapshotChanges()
      )
    );
  }

  /**
   * Get deviations matching checklistDeviations
   */
  getDeviationsMatchingChecklist(key) {
    return combineLatest([this.getChecklistDeviationsFromChecklistID(key), this.deviationService.getOpenDeviationsPayload()])
      .pipe(
        map(([cdSnap, dSnap]) => {
          const deviations = [];
          cdSnap.map(cd => {
            dSnap.map(d => {
              if (d.key === cd.payload.val().deviationId) {
                deviations.push({...d.payload.val(), ...cd.payload.val()});
              }
            });
          });
          return deviations;
        })
      );
  }

  checklistDeviationStatus(setWarning) {
    const success = '#73BF21';
    const warning = '#F5A623';

    if (setWarning) {
      return {'color': warning};
    } else {
      return {'color': success};
    }
  }

  updateChecklistItem(checklistId: any, itemId: any, item): any {
    console.log(checklistId, itemId, item);
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${checklistId}/items/${itemId}`).update(item)
      )
    )).connect();
  }

  /**
   *
   * @param checklistId
   *
   * @return items that's not archived in this checklist.
   */
  getChecklistItems(checklistId) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${checklistId}/items`).valueChanges()
      ),
      map(items => {
        return Object.fromEntries(Object.entries(items).filter((entry: any) => !entry[1].archive));
      })
    );
  }

  /**
   *
   * @param checklistId
   *
   * @return Item that's archived in this checklist.
   */
  getArchivedChecklistItems(checklistId) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${checklistId}/items`).valueChanges()
      ),
      map(items => {
        if(items){
          return Object.fromEntries(Object.entries(items).filter((entry: any) => entry[1].archive));
        } else {
          return null;
        }
      })
    );
  }

  updateChecklistItems(checklistId, items: {}) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${checklistId}/items/`).update(items)
      )
    )).connect();
  }

  updateChecklist(key, checklist: Checklist) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}`).update(checklist)
      )
    )).connect();
  }

  isChecklistCompleted(checklistId): Observable<boolean> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${checklistId}/completed`).valueChanges()
      )
    ) as Observable<boolean>;
  }

  getComments(checklistId): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklists/${checklistId}/comments`).valueChanges()
      )
    ) as Observable<any>;
  }

  setComments(key, comments: any[]) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}/comments`).set(comments)
      )
    )).connect();
  }

  addChecklistItem(key, item: ChecklistItem) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/checklists/${key}/items`).push(item)
      )
    )).connect();
  }

  deleteChecklist(key) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}`).remove()
      )
    )).connect();
  }

  deleteChecklistItem(key, itemKey) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${key}/items/${itemKey}`).remove()
      )
    )).connect();
  }

  addChecklist(checklist: Checklist) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/checklists`).push(checklist)
      )
    )).connect();
  }

  addChecklistGetKey(checklist: Checklist): Observable<string> {
    return this.tenantId$.pipe(
      map(tenantId => {
        const ref = this.db.list(`tenants/${tenantId}/checklists`).push(checklist);
        return ref.key;
      }));
  }

  /**
   * Add Checklist Template
   * @param checklistTemplate: a ChecklistTemplate object
   */
  addChecklistTemplate(checklistTemplate: ChecklistTemplate) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/checklistTemplates`).push(checklistTemplate)
      )
    )).connect();
  }

  /**
   * Add a Cron Job
   * @param cronJob: a CronJob object
   */
  addCronJob(cronJob: CronJob) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/cronJobs`).push(cronJob)
      )
    )).connect();
  }

  getCompletedChecklists(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklists`, ref => {
          return ref.orderByChild('completed').equalTo(true);
        }).snapshotChanges()
      ),
    );
  }

  /**
   * Get Completed Checklist Without Deviations.
   */
  getCompletedChecklistWithoutDeviations(): Observable<any> {
    return combineLatest([this.getCompletedChecklists(), this.getChecklistDeviations()])
      .pipe(
        map(([checklists, checklistDeviations]) => {
          // Map for checklists with deviations
          const checklistDeviationMap = new Map(checklistDeviations.map(deviation => [deviation.payload.val().checklistId, true]));

          // Filter completed checklists
          return checklists.filter(checklist => {
            // Check if not empty object
            if (checklist) {
              const value = checklist.payload.val();
              // Check if checklist has items
              if (value.items) {
                const items = Object.values(value.items);
                return (!items.some((item: any) => item.adminOnly === false) && !checklistDeviationMap.has(checklist.key));
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }));
  }

  /**
   * Get unfinished checklists.
   */
  getNotFinishedChecklists() {
    return combineLatest([this.getCompletedChecklists()])
      .pipe(
        map(([checklists]) => {

          // Filter completed checklists
          return checklists.filter(checklist => {
            // Check if not empty object
            if (checklist) {
              const value = checklist.payload.val();
              // Check if checklist has items
              if (value.items) {
                const items = Object.values(value.items);
                return (items.some((item: any) => item.checked === false));
              } else {
                return true;
              }
            } else {
              return false;
            }
          });
        }));
  }

  /**
   * Get checklists with deviations.
   */
  getChecklistWithDeviations() {
    return combineLatest([this.getCompletedChecklists(), this.getChecklistDeviations()])
      .pipe(
        map(([checklists, checklistDeviations]) => {

          // Map for checklists with deviations
          const checklistDeviationMap = new Map(checklistDeviations.map(deviation => [deviation.payload.val().checklistId, true]));

          // Filter completed checklists
          return checklists.filter(checklist => {
            // Check if not empty object
            if (checklist) {
              const value = checklist.payload.val();
              // Check if checklist has items
              if (value.items) {
                return checklistDeviationMap.has(checklist.key);
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }));
  }

  getDeviationsForItem(key: any) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklistDeviations`, ref => {
          return ref.orderByChild('itemId').equalTo(key);
        }).valueChanges()
      )
    );
  }

  updateCronJob(id, cronJob){
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/cronJobs/${id}`).update(cronJob)
      )
    )).connect();
  }

  archiveChecklistItem(cId: string, iId: string, item) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/checklists/${cId}/items/${iId}`).update(item)
      )
    )).connect();
  }

  deleteCronJob(cjId: string) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/cronJobs/${cjId}`).remove()
      )
    )).connect();
  }

  /**
   * Delete checklistTemplate
   * @param templateID checklistTemplateKey
   */
  deleteTemplate(templateID) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => {
              this.db.object(`tenants/${tenantId}/checklistTemplates/${templateID}`).remove();
            }
        )
    )).connect();
  }
}
