import {Component, OnDestroy, OnInit} from '@angular/core';
import {ExerciseTemplate} from '../../../model/exercise';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ConfirmationService, FilterService, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/service/auth.service';
import {ExerciseService} from '../../../services/service/exercise.service';
import {Subscription} from 'rxjs';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';
import {ExerciseFilesComponent} from '../exercise-files/exercise-files.component';
import {take} from 'rxjs/operators';
import {FileService} from '../../../services/service/file.service';
import {FileStorageService} from '../../../services/service/file-storage.service';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class EditTemplateComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  completed = false;
  editKey;

  templateCT = [];
  outputCT: any;
  outputTheme: any;
  selectedCT: any;
  selectedTheme: any;

  competencyTypes: any[];
  themes: any[];

  files = new Map();
  autoFiles = [];
  fileNameMap = new Map();
  exerciseFilesMap = new Map();
  relationMap: Map<string, string[]> = new Map();

  ctNameMap = new Map();
  themeNameMap = new Map();
  competencyTypeMap = new Map();
  themeMap = new Map();
  removedCTMap = new Map();

  templateKey: string;

  template: ExerciseTemplate = {
    competencyTypes: [],
    completed: false,
    created: '',
    createdById: '',
    details: '',
    exerciseFiles: [],
    name: '',
    template: true,
    evaluation: {
      goal: "",
      evaluation: "",
      learningPoints: "",
      other: "",
    },
  };
  readOnly = true;
  tempCreation = false;
  windowWidth: number;

  constructor(
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService,
      private translateService: TranslateService,
      private authService: AuthService,
      private exerciseService: ExerciseService,
      private filterService: FilterService,
      private ctService: CompetencyTypeService,
      private dialogService: DialogService,
      private fileService: FileService,
      private fileStorageService: FileStorageService,
      private confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    if(this.config.data.key){
      this.editKey = this.config.data.key;
    }
    if(this.config.data.template){
      this.template = JSON.parse(JSON.stringify(this.config.data.template));
      this.templateCT = [];
      if (this.template.competencyTypes){
        this.competencyTypeMap = new Map(Object.keys(JSON.parse(JSON.stringify(this.template.competencyTypes))).map(key => [key, true]));
        for (const key of this.competencyTypeMap.keys()) {
          this.templateCT.push(key);
        }
      }
    } else {
      this.templateCT = [];
      this.competencyTypeMap = new Map();
    }

    this.readOnly = this.config.data.readOnly;

    if (!this.template.evaluation){
      this.template.evaluation = {
        goal: "",
        evaluation: "",
        learningPoints: "",
        other: "",
      };
    }
    this.subscription.add(
        this.ctService.getCompetencyTypes().subscribe(cts => {
          this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
          this.competencyTypes = cts.map(ct => {
            return {key: ct.key, ...ct.payload.val()};
          }).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          this.templateCT = this.templateCT.map(eCT => {
            if(eCT.key){
              return {key: eCT.key, name: this.ctNameMap.get(eCT.key)};
            } else {
              return {key: eCT, name: this.ctNameMap.get(eCT)};
            }
          }).sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        })
    );

    this.subscription.add(
        this.exerciseService.getThemes().subscribe(themes => {
          this.themeNameMap = new Map(themes.map(theme => [theme.key, theme.payload.val().name]));
          this.themes = themes.map(theme => {
            return {key: theme.key, ...theme.payload.val()};
          }).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        })
    );

    this.subscription.add(this.fileService.getAllFiles().subscribe(files => {
      this.fileNameMap = new Map(files.map(file => [file.key, file.payload.val().name]));
      this.files = new Map();
      files.map(file => {
        if (!file.payload.val().exerciseFile){
          this.files.set(file.key, file.payload.val());
        }
      });
    }));

    this.subscription.add(this.fileService.getFileTagRelations().subscribe(relations => {
      this.relationMap = new Map();
      relations.map(relation => {
        if(this.relationMap.has(relation.payload.val().tagId)){
          this.relationMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
        } else{
          this.relationMap.set(relation.payload.val().tagId, [relation.payload.val().fileId]);
        }
      });
    }));

    this.subscription.add(this.exerciseService.getAllExercises().subscribe(exercises => {
      exercises.map(exercise => {return {key: exercise.key, ...exercise.payload.val()};}).forEach(exercise => {
        if (exercise.exerciseFiles){
          this.exerciseFilesMap.set(exercise.key, exercise.exerciseFiles);
        }
      });
    }));
  }


  validation(input) {
    if(this.completed){
      if(!input){
        return {'border-color': '#D0021B'};
      }
    }
  }

  /**
   * Confirm deletion of asset
   */
  confirm() {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE') + " " + this.template.name + "?",
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.delete();
      }
    });
  }

  /**
   * Search competency.
   * @param event Search event.
   */
  searchCT(event: any) {
    const query = event.query;
    this.outputCT = this.filterService.filter(this.competencyTypes, ['name'], query, "contains");
  }

  /**
   * Search for theme.
   * @param event Search event.
   */
  searchTheme(event: any) {
    const query = event.query;
    this.outputTheme = this.filterService.filter(this.themes, ['name'], query, "contains");
  }

  /**
   * Add competency theme.
   * @param $event Theme click event
   */
  addTheme($event: any) {
    $event.competencyTypes.forEach(key => {
      this.addCompetencyType({key: key});
    });
    this.selectedTheme = "";
  }

  /**
   * Add competency type
   * @param $event Competency click event
   */
  addCompetencyType($event: any) {
    this.templateCT.push({key: $event.key, name: this.ctNameMap.get($event.key)});
    this.templateCT.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    if (this.relationMap.has($event.key)){
      this.relationMap.get($event.key).forEach(file => {
        this.template.exerciseFiles.push(file);
        this.autoFiles.push(file);
      });
    }
    this.competencyTypeMap.set($event.key, true);
    this.removedCTMap.delete($event.key);
    this.selectedCT = "";
  }

  /**
   * Remove competency
   * @param competencyType competency key
   */
  removeCompetency(competencyType: any) {
    const index = this.templateCT.findIndex(eCT => eCT.key === competencyType.key);
    if(index >= 0){
      this.templateCT.splice(index, 1);
      this.competencyTypeMap.delete(competencyType.key);
      this.removedCTMap.set(competencyType.key, true);
    }
  }

  /**
   * Add files to template.
   */
  addFiles() {
    if (!this.editKey){
      this.authService.getUserUID()
          .pipe(take(1))
          .subscribe(uid => {
            this.template.createdById = uid;
            this.template.created = new Date().toISOString();

            this.exerciseService.addExerciseTemplateGetKey(this.template)
                .pipe(take(1)).subscribe(
                tKey => {
                  this.templateKey = tKey;
                  this.dialogService.open(ExerciseFilesComponent, {
                    header: this.translateService.instant('FILE.FILES'),
                    styleClass: 'max-size-dialog',
                    data: {
                      exercise: this.template,
                      key: this.templateKey,
                    }
                  });
                  this.tempCreation = true;
                });
          });
    } else {
      this.dialogService.open(ExerciseFilesComponent, {
        header: this.translateService.instant('FILE.FILES'),
        styleClass: 'max-size-dialog',
        data: {
          exercise: this.template,
          key: this.editKey,
        }
      });
    }
  }

  /**
   * Save new or change template
   */
  save() {
    if (!this.templateKey && !this.editKey){
      this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
        this.template.createdById = uid;
        this.template.created = new Date().toISOString();
        this.exerciseService.addExerciseTemplateGetKey(this.template).pipe(take(1)).subscribe(key => {
          if (this.templateCT.length > 0){
            this.templateCT.forEach(ct => {
              this.template.competencyTypes[ct.key] = true;
            });
            this.exerciseService.updateExercise(key, this.template);
          }
        });
      });
    } else {

      if (this.templateCT.length > 0){
        this.templateCT.forEach(ct => {
          this.template.competencyTypes[ct.key] = true;
        });
      }

      const k = this.template.key;
      delete this.template.key;
      this.exerciseService.updateExercise(k, this.template);
    }
    this.ref.close();
  }

  /**
   * Close
   */
  close() {
    if (this.tempCreation){
      if (this.template.exerciseFiles.length > 0){
        this.template.exerciseFiles.forEach(fileID => {
          if (!this.autoFiles.includes(fileID)) {
            let otherConnection = false;
            for (const [key, list] of this.exerciseFilesMap.entries()) {
              if (key !== this.templateKey) {
                if (list.includes(fileID)) {
                  otherConnection = true;
                }
              }
            }
            if (!otherConnection) {
              const file = this.files.get(fileID);
              this.fileStorageService.deleteExerciseFile(file.admin, file.name);
              this.fileService.deleteFile(fileID);
            }
          }
        });
      }
      this.exerciseService.deleteExercise(this.templateKey);
    }
    this.ref.close();
  }

  /**
   * Delete template
   */
  delete() {
    if (this.template.exerciseFiles.length > 0){
      this.template.exerciseFiles.forEach(fileID => {
        let otherConnection = false;
        for (const [key, list] of this.exerciseFilesMap.entries()) {
          if (key !== this.templateKey){
            if (list.includes(fileID)){
              otherConnection = true;
            }
          }
        }
        if (!otherConnection){
          const file = this.files.get(fileID);
          this.fileStorageService.deleteExerciseFile(file.admin, file.name);
          this.fileService.deleteFile(fileID);
        }
      });
    }
    this.exerciseService.deleteExercise(this.template.key);
    this.ref.close();
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
