import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {PersonService} from '../../../services/service/person.service';
import {Exposure} from '../../../model/exposure';
import {ExposureEval} from '../../../model/exposureEval';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-exposure-read',
  templateUrl: './exposure-read.component.html',
  styleUrls: ['./exposure-read.component.scss'],
  providers: [ConfirmationService]
})
export class ExposureReadComponent implements OnInit, OnDestroy {

  exposure: Exposure;
  exposureType: string;
  evaluation: ExposureEval;
  evalBool = [];

  // Participants
  personArray = [];
  personNameMap = new Map<string, string>();

  // Event time space
  workTimeMap = new Map<string, {hours, minutes}>();

  // Subscription
  subscription = new Subscription();

  constructor(private config: DynamicDialogConfig,
              private ref: DynamicDialogRef,
              public translateService: TranslateService,
              private personService: PersonService,
              )
  {
    this.exposure = this.config.data.exposure;
    this.exposureType = this.config.data.exposureType;
    this.evaluation = this.config.data.evaluation;
    if (this.config.data.evaluation){
      this.evalBool.push(true);
    }
    this.subscription.add(
      this.personService.getPersons().subscribe(persons => {
        this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
      })
    );

    this.exposure.participants.forEach(participant => {
      this.personArray.push(participant.key);
      if (this.exposure.exposureType === 'Sot' || this.exposure.exposureType === 'Royk'){
        this.workTimeMap.set(participant.key, this.getHoursAndMin(participant.exposureStart, participant.exposureEnd));
      }
    });

  }

  ngOnInit(): void {
  }

  /**
   * Print work hours
   * @param personId Unique person ID
   */
  printTime(personId: string) {
    const hours = this.workTimeMap.get(personId).hours;
    const minutes = this.workTimeMap.get(personId).minutes;

    if (hours !== 0){
      if (minutes !== 0){
        return hours + this.translateService.instant('EVENT.HOUR') + " " + minutes + this.translateService.instant('EVENT.MINUTES');
      } else {
        return hours + this.translateService.instant('EVENT.HOUR');
      }
    } else {
      return minutes + this.translateService.instant('EVENT.MINUTES');
    }
  }

  /**
   * Get hours and minutes from moment
   * @param s Start moment
   * @param e End moment
   */
  getHoursAndMin(s, e){

    if (s && e) {

      const start = moment(new Date(this.exposure.date.split('.').reverse().join('-'))).add(s);
      const end = moment(new Date(this.exposure.date.split('.').reverse().join('-'))).add(e);

      if (end.diff(start) < 0) {
        end.add(1, 'days');
      }
      return {hours: end.diff(start, "hours"), minutes: end.diff(start, "minutes") % 60}

    } else {
      return {hours: 0, minutes: 0}
    }
  }

  /**
   * Get exposureType
   */
  getType() {
    return this.exposureType;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
