import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {AccessControlService} from "../../../services/service/access-control.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-read-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit, OnDestroy {
  item;
  comment;
  name;
  isObserver;
  readOnly;

  subscription: Subscription;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private acService: AccessControlService
  ) {
    this.item = this.config.data.item;

    // Boolean check, if no data/ false = false; if true = true
    this.readOnly = !!(this.config.data.readOnly);

    if(!this.item.comment){
      this.item.comment = '';
    }
    if(!this.item.name){
      this.item.name = '';
    }
    this.subscription = this.acService.isObserver().subscribe(isObserver => this.isObserver = isObserver)
    this.comment = JSON.parse(JSON.stringify(this.item.comment))
    this.name = JSON.parse(JSON.stringify(this.item.name))
  }

  ngOnInit(): void {
  }

  close() {
    this.ref.close();
  }

  ifEqual() {
    return this.item.name === this.name && this.item.comment === this.comment
  }

  save() {

    this.item.name = this.name;
    this.item.comment = this.comment
    this.name = '';
    this.comment = '';
    this.ref.close(this.item)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
