import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ExposureService} from '../../../services/service/exposure.service';
import {EmergencyAddOvertimeComponent} from '../emergency-add-overtime/emergency-add-overtime.component';
import {ConfirmationService} from 'primeng/api';
import * as moment from 'moment';


@Component({
  selector: 'app-emergency-add-exposures',
  templateUrl: './emergency-add-exposures.component.html',
  styleUrls: ['./emergency-add-exposures.component.scss'],
})


export class EmergencyAddExposuresComponent implements OnInit {

  // Date
  date: string;

  // Subscription
  subscription = new Subscription();

  // Persons
  personNameMap = new Map();
  personArray: [];
  tableArray = [];

  // Exposures
  selectedExpoKeys = new Map<string, any[]>();
  exposedMap = new Map();
  exposureTypes: any[];
  exposureTimeMap = new Map<string, {start, end}>();
  exposureTimeChange =  new Map<string, Map<string, boolean>>();

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private exposureService: ExposureService,
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    private dialogService: DialogService,
  ) {
    if (this.config.data.edit){
      let date = this.config.data.date.split('.');
      date = date.reverse();
      this.date = date[0] + '-' + date[1] + '-' +date[2]
    } else {
      this.date = this.config.data.date;
    }
    this.personArray = this.config.data.personArray;
    this.personNameMap = this.config.data.personNameMap;
    this.exposedMap = this.config.data.exposedMap;
    this.exposureTypes = exposureService.getExposureTypes();
    this.exposureTimeMap = this.config.data.exposureTime;
    this.exposureTimeChange = this.config.data.exposureTimeChange;

    this.selectedExpoKeys = new Map<string, []>(
      this.personArray.map(personId => [personId, []])
    )
    this.personArray.forEach(personId => {
      this.tableArray.push({key: personId});
    });
    if (!this.exposedMap){}
  }

  ngOnInit(): void {
  }

  /**
   * Save and close
   */
  save(){
    this.ref.close([this.exposedMap, this.exposureTimeChange])
  }

  /**
   * Set selected exposures to personId
   * @param event The event
   * @param personId Unique person ID
   */
  setExposures(event: any, personId) {
    this.selectedExpoKeys.set(personId, event.value)
  }
  /**
   * Change person time period.
   * @param personId unique person ID
   * @param key Exposure key
   */
  changePersonTimePeriod(personId: string, key: string){

    this.dialogService.open(EmergencyAddOvertimeComponent, {
      header: this.personNameMap.get(personId) + ': ' + this.translateService.instant('EVENT.CHANGE_EXPOSURETIME'),
      width: "300px",
      data: {
        start: this.exposedMap.get(personId).get(key).time.start,
        end: this.exposedMap.get(personId).get(key).time.end
      }
    }).onClose.subscribe(newTime => {
      if(newTime) {
        if (newTime.start !== this.exposedMap.get(personId).get(key).time.start
          || newTime.end !== this.exposedMap.get(personId).get(key).time.end) {
          this.exposedMap.get(personId).get(key).time.start = newTime.start;
          this.exposedMap.get(personId).get(key).time.end = newTime.end;
          this.exposureTimeChange.get(personId).set(key, !this.exposureTimeChange.get(personId).get(key));
        }
      }
    });
  }

  /**
   * Print exposure time
   * @param time Person exposed time
   */
  printTime(time: {start: string, end: string}) {
    const start = moment(this.date).add(time.start);
    const end = moment(this.date).add(time.end);

    if (end.diff(start) < 0) {
      end.add(1, 'days');
    }

    const hours = end.diff(start, "hours");
    const minutes = end.diff(start, "minutes") % 60;

    if (hours !== 0){
      if (minutes !== 0){
        return hours + this.translateService.instant('EVENT.HOUR') + " " + minutes + this.translateService.instant('EVENT.MINUTES')
      } else {
        return hours + this.translateService.instant('EVENT.HOUR')
      }
    } else {
      return minutes + this.translateService.instant('EVENT.MINUTES')
    }
  }

  /**
   * Use for showing exposure checkbox value true/false
   * @param personId Unique personId
   * @param key Exposure type key
   */
  getTypeBool(personId: string, key: string) {
    return this.exposedMap.get(personId).get(key).boolValue
  }

  /**
   * Count current selected exposures on selected person
   * @param personId Unique personId
   */
  countExposures(personId: string) {
    const personExpo = this.exposedMap.get(personId);
    let counter = 0;
    for (const [, list] of personExpo) {
      if (list.boolValue === true) {
        counter++;
      }
    }
    return counter;
  }
}
