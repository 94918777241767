import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetHoursComponent } from './get-hours/get-hours.component';
import {Route} from "@angular/router";
import {TableModule} from "primeng/table";
import {AutoCompleteModule} from "primeng/autocomplete";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {PipesModule} from "../../pipes/pipes.module";
import {DropdownModule} from "primeng/dropdown";
import {MultiSelectModule} from "primeng/multiselect";
import {CalendarModule} from "primeng/calendar";
import { PrintHoursComponent } from './print-hours/print-hours.component';
import {TooltipModule} from "primeng/tooltip";

export const ReportRoutes: Route = {
  path: 'reports',
  children: [
    {path: 'hours', component: GetHoursComponent}
  ]
};

@NgModule({
  declarations: [
    GetHoursComponent,
    PrintHoursComponent
  ],
    imports: [
        CommonModule,
        TableModule,
        AutoCompleteModule,
        TranslateModule,
        FormsModule,
        PipesModule,
        DropdownModule,
        MultiSelectModule,
        CalendarModule,
        TooltipModule
    ]
})


export class ReportsModule { }
