<div class="grid" *ngIf="!loading">
  <ng-container *ngIf="windowWidth < 500; else bigScreenBlock">
    <div class="col-12">
      <!-- ONGOING FORM -->
      <form
        *ngIf="selectedType.index === 0"
        [formGroup]="ongoingForm"
        (ngSubmit)="onSubmit()">
        <ng-container *ngIf="!fromTemplate; else activateTemplateBlock">
          <!-- NAME -->
          <div class="field col-12">
            <label for="aName">{{'COMMON.NAME' | translate}} *</label>
            <input
              id="aName"
              type="text"
              formControlName="name"
              pInputText
              required
              class="inputfield w-full form-control"/>
          </div>
        </ng-container>
        <ng-template #activateTemplateBlock>
          <div class="field col-12">
            <label for="bName">{{'COMMON.NAME' | translate}} *</label>
            <input
              id="bName"
              type="text"
              formControlName="name"
              pInputText
              required
              class="inputfield w-full form-control"/>
          </div>
          <!-- CHOOSE TYPE -->
          <ng-container *ngIf="isAdmin$ || isSuper$">
            <div class="field col-12">
              <label for="aTypeSelect">{{'COMMON.TYPE' | translate}}</label>
              <p-dropdown
                id="aTypeSelect"
                [options]="typeSelect"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="selectedType"
                [style]="{'width': '100%'}"
                optionLabel="name">
              </p-dropdown>
            </div>
          </ng-container>
        </ng-template>
        <!-- ASSET -->
        <div class="field">
          <label for="assetSelect">{{'ASSET.ASSET' | translate}} *</label>
          <p-dropdown
            id="assetSelect"
            [options]="assetSelect"
            formControlName="asset"
            (onChange)="setStation(ongoingForm)"
            required
            [style]="{'width': '100%'}"
            optionLabel="name"
            placeholder="{{'BUTTON.SELECT' | translate}}"
          >
          </p-dropdown>
        </div>

        <!-- STATION -->
        <div class="field">
          <label for="station">{{'COMMON.STATION' | translate}} *</label>
          <input
            id="station"
            type="text"
            formControlName="station"
            [readOnly]="true"
            pInputText
            placeholder="{{'ASSET.ASSET_CARD.NO_STATION' | translate}}"
            required
            class="inputfield w-full form-control"/>
        </div>

        <!-- SAVE -->
        <div class="col-12 flex justify-content-end">
          <button
            pButton
            class="p-button-raised"
            [disabled]="!ongoingForm.valid"
            type="submit"
            label="{{'BUTTON.SAVE' | translate}}"
          ></button>
        </div>
      </form>

      <!-- //** CRONJOB FORM **// -->
      <form *ngIf="selectedType.index === 1"
            [formGroup]="cronJobForm"
            (ngSubmit)="onSubmit()">

        <!-- NAME -->
        <div class="field">
          <label for="cronName">{{'COMMON.NAME' | translate}} *</label>
          <input
            id="cronName"
            name="name"
            type="text"
            formControlName="name"
            pInputText
            required
            class="inputfield w-full form-control"/>
        </div>

        <!-- ASSET -->
        <div class="field">
          <label for="cronAssetSelect">{{'ASSET.ASSET' | translate}} *</label>
          <p-dropdown
            id="cronAssetSelect"
            [options]="assetSelect"
            name="asset"
            formControlName="asset"
            required
            (onChange)="setStation(cronJobForm)"
            [style]="{'width': '100%'}"
            optionLabel="name"
            placeholder="{{'BUTTON.SELECT' | translate}}"
          >
          </p-dropdown>
        </div>

        <!-- STATION -->
        <div class="field">
          <label for="cronStation">{{'COMMON.STATION' | translate}} *</label>
          <input
            id="cronStation"
            type="text"
            formControlName="station"
            pInputText
            [readonly]="true"
            placeholder="{{'ASSET.ASSET_CARD.NO_STATION' | translate}}"
            required
            class="inputfield w-full form-control"/>
        </div>

        <!-- CRON JOB -->
        <p-tabView [(activeIndex)]="tabIndex" (onChange)="weeklyOrMonthly()">
          <!-- WEEKLY -->
          <p-tabPanel header="{{'CHECKLIST.REPEAT_WEEKLY' | translate}}">
            <div class="grid mt-2" formGroupName="weekly">

              <!-- WEEK INTERVAL -->
              <div class="col-12 md:col-6">
                <div class="field">
                  <label for="weekInt">{{'CHECKLIST.INTERVAL' | translate}}{{' (1-8)'}} *</label>
                  <p-inputNumber
                    id="weekInt"
                    class="mt-2 inputfield w-full"
                    formControlName="interval"
                    [style]="{'width': '100%'}"
                    [min]="1"
                    [max]="8"
                    suffix="&nbsp;{{'COMMON.WEEKS' | translate}}">
                  </p-inputNumber>
                </div>
              </div>


              <!-- WEEK DELAY -->
              <div class="col-12 md:col-6">
                <div class="field">
                  <label for="weekDelay">{{'CHECKLIST.DELAY' | translate}}{{' (0-8)'}} *</label>
                  <p-inputNumber
                    id="weekDelay"
                    class="mt-2 inputfield w-full"
                    formControlName="delay"
                    [style]="{'width': '100%'}"
                    [min]="0"
                    [max]="8"
                    suffix="&nbsp;{{'COMMON.WEEKS' | translate}}">
                  </p-inputNumber>
                </div>
              </div>


              <!-- SELECT DAY -->
              <div class="col-12">
                <label for="selectDay">{{'CHECKLIST.CHOOSE_DAY' | translate}} *</label>
                <div class="field mt-2">
                  <p-dropdown
                    id="selectDay"
                    formControlName="day"
                    placeholder="{{'COMMON.CHOOSE' | translate}}"
                    [options]="days"
                    [style]="{'width': '50%'}"
                    optionValue="value"
                    optionLabel="name">
                  </p-dropdown>
                </div>
              </div>

            </div>
          </p-tabPanel>

          <!-- MONTHLY -->
          <p-tabPanel formGroupName="monthly" header="{{'CHECKLIST.REPEAT_MONTHLY' | translate}}">
            <div class="grid mt-2">

              <!-- MONTH INTERVAL -->
              <div class="col-12 md:col-6">
                <div class="field">
                  <label for="monthInt">{{'CHECKLIST.INTERVAL' | translate}}{{' (1-24)'}}</label>
                  <p-inputNumber
                    id="monthInt"
                    class="mt-2 inputfield w-full"
                    formControlName="interval"
                    [style]="{'width': '100%'}"
                    [min]="1"
                    [max]="24"
                    suffix="&nbsp;{{'COMMON.MONTHS.MONTHS' | translate}}">
                  </p-inputNumber>
                </div>
              </div>

              <!-- START MONTH -->
              <div class="col-12 md:col-6">
                <div class="field">
                  <label for="selectMonth">{{'CHECKLIST.START_MONTH' | translate}}</label>
                  <div class="field mt-2">
                    <p-dropdown
                      id="selectMonth"
                      [options]="months"
                      formControlName="startMonth"
                      (ngModelChange)="getDays($event)"
                      [style]="{'width': '100%'}"
                      optionValue="value"
                      optionLabel="name">
                    </p-dropdown>
                  </div>
                </div>
              </div>
            </div>

            <!--CHOOSE DATE OR WHEN -->

            <!-- DATE -->
            <div class="col-12 flex flex align-items-center">

              <p-radioButton name="monthMode" [value]="0"  [ngModelOptions]="{standalone: true}" [(ngModel)]="monthMode" (ngModelChange)="swapDisable()"></p-radioButton>

              <p-dropdown
                appendTo="body"
                class="ml-3"
                formControlName="date"
                placeholder="{{'COMMON.DATE.DATE' | translate}}"
                [style]="{'width': '100px'}"
                [options]="numberOfDates">
              </p-dropdown>

            </div>

            <!-- WHEN -->
            <div class="col-12 flex align-items-center">

              <p-radioButton name="monthMode" [value]="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="monthMode" (ngModelChange)="swapDisable()"></p-radioButton>

              <p-dropdown
                appendTo="body"
                class="ml-3"
                placeholder="{{'COMMON.WHEN' | translate}}"
                [options]="whenOption"
                formControlName="when"
                [style]="{'width': '125px'}"
                optionValue="value"
                optionLabel="name">
              </p-dropdown>

              <p-dropdown
                appendTo="body"
                class="ml-3"
                [options]="days"
                formControlName="day"
                placeholder="{{'CHECKLIST.CHOOSE_DAY' | translate}}"
                optionValue="value"
                optionLabel="name">
              </p-dropdown>
            </div>

          </p-tabPanel>
        </p-tabView>

        <!-- SAVE -->
        <div class="col-12 flex justify-content-end">
          <button
            pButton
            class="p-button-raised"
            [disabled]="!cronJobForm.valid"
            type="submit"
            label="{{'BUTTON.SAVE' | translate}}"
          ></button>
        </div>

      </form>
    </div>
  </ng-container>
</div>

<ng-template #bigScreenBlock>
  <!-- ONGOING FORM -->
  <form
    *ngIf="selectedType.index === 0"
    [formGroup]="ongoingForm"
    (ngSubmit)="onSubmit()"
    class="w-full pl-2">
    <div class="grid col-12 mr-0">

      <ng-container *ngIf="!fromTemplate; else activateTemplateBlock">
        <!-- NAME -->
        <div class="field col-12">
          <label for="bigScreenTName">{{'COMMON.NAME' | translate}} *</label>
          <input
            id="bigScreenName"
            type="text"
            formControlName="name"
            pInputText
            required
            class="inputfield w-full form-control"/>
        </div>
      </ng-container>
      <ng-template #activateTemplateBlock>
        <ng-container *ngIf="isUser$; else adminBlock">
          <!-- NAME -->
          <div class="field col-12">
            <label for="bigScreenTName2">{{'COMMON.NAME' | translate}} *</label>
            <input
              id="bigScreenName2"
              type="text"
              formControlName="name"
              pInputText
              required
              class="inputfield w-full form-control"/>
          </div>
        </ng-container>
        <ng-template #adminBlock>
          <div class="field col-6">
            <label for="TName">{{'COMMON.NAME' | translate}} *</label>
            <input
              id="TName"
              type="text"
              formControlName="name"
              pInputText
              required
              class="inputfield w-full form-control"/>
          </div>
          <!-- CHOOSE TYPE -->
          <div class="field col-6">
            <label for="bigScreenTypeSelect">{{'COMMON.TYPE' | translate}}</label>
            <p-dropdown
              id="bigScreenTypeSelect"
              [options]="typeSelect"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="selectedType"
              [style]="{'width': '100%'}"
              optionLabel="name">
            </p-dropdown>
          </div>
        </ng-template>
      </ng-template>
      <!-- ASSET -->
      <div class="field col-6">
        <label for="bigScreenAssetSelect">{{'ASSET.ASSET' | translate}} *</label>
        <p-dropdown
          id="bigScreenAssetSelect"
          [options]="assetSelect"
          formControlName="asset"
          (onChange)="setStation(ongoingForm)"
          required
          [style]="{'width': '100%'}"
          optionLabel="name"
          placeholder="{{'BUTTON.SELECT' | translate}}"
        >
        </p-dropdown>
      </div>

      <!-- STATION -->
      <div class="field col-6">
        <label for="bigScreenStation">{{'COMMON.STATION' | translate}} *</label>
        <input
          id="bigScreenStation"
          type="text"
          formControlName="station"
          [readOnly]="true"
          pInputText
          placeholder="{{'ASSET.ASSET_CARD.NO_STATION' | translate}}"
          required
          class="inputfield w-full form-control"/>
      </div>

      <!-- SAVE -->
      <div class="col-12 flex justify-content-end">
        <button
          pButton
          class="p-button-raised"
          [disabled]="!ongoingForm.valid"
          type="submit"
          label="{{'BUTTON.SAVE' | translate}}"
        ></button>
      </div>
    </div>
  </form>

  <!-- //** CRONJOB FORM **// -->
  <form *ngIf="selectedType.index === 1"
        [formGroup]="cronJobForm"
        (ngSubmit)="onSubmit()"
        class="w-full pl-2">
    <div class="grid col-12">

      <ng-container *ngIf="!template; else activateTemplateBlock">
        <!-- NAME -->
        <div class="field col-12">
          <label for="cronTName">{{'COMMON.NAME' | translate}} *</label>
          <input
            id="cronTName"
            type="text"
            formControlName="name"
            pInputText
            required
            class="inputfield w-full form-control"/>
        </div>
      </ng-container>
      <ng-template #activateTemplateBlock>

        <ng-container *ngIf="isUser$; else adminBlock">
          <!-- NAME -->
          <div class="field col-12">
            <label for="bigScreenTName3">{{'COMMON.NAME' | translate}} *</label>
            <input
              id="bigScreenName3"
              type="text"
              formControlName="name"
              pInputText
              required
              class="inputfield w-full form-control"/>
          </div>
        </ng-container>
        <ng-template #adminBlock>
          <div class="field col-6">
            <label for="TName1">{{'COMMON.NAME' | translate}} *</label>
            <input
              id="TName1"
              type="text"
              formControlName="name"
              pInputText
              required
              class="inputfield w-full form-control"/>
          </div>
          <!-- CHOOSE TYPE -->
          <div class="field col-6">
            <label for="bigScreenTypeSelect1">{{'COMMON.TYPE' | translate}}</label>
            <p-dropdown
              id="bigScreenTypeSelect1"
              [options]="typeSelect"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="selectedType"
              [style]="{'width': '100%'}"
              optionLabel="name">
            </p-dropdown>
          </div>
        </ng-template>

      </ng-template>

      <!-- ASSET -->
      <div class="field col-6">
        <label for="bigScreenCronAssetSelect">{{'ASSET.ASSET' | translate}} *</label>
        <p-dropdown
          id="bigScreenCronAssetSelect"
          [options]="assetSelect"
          name="asset"
          formControlName="asset"
          required
          (onChange)="setStation(cronJobForm)"
          [style]="{'width': '100%'}"
          optionLabel="name"
          placeholder="{{'BUTTON.SELECT' | translate}}"
        >
        </p-dropdown>
      </div>

      <!-- STATION -->
      <div class="field col-6">
        <label for="bigScreenCronStation">{{'COMMON.STATION' | translate}} *</label>
        <input
          id="bigScreenCronStation"
          type="text"
          formControlName="station"
          pInputText
          [readonly]="true"
          placeholder="{{'ASSET.ASSET_CARD.NO_STATION' | translate}}"
          required
          class="inputfield w-full form-control"/>
      </div>

      <!-- CRON JOB -->
      <p-tabView [(activeIndex)]="tabIndex" (onChange)="weeklyOrMonthly()">
        <!-- WEEKLY -->
        <p-tabPanel header="{{'CHECKLIST.REPEAT_WEEKLY' | translate}}">
          <div class="grid mt-2" formGroupName="weekly">

            <!-- WEEK INTERVAL -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="bigScreenWeekInt">{{'CHECKLIST.INTERVAL' | translate}}{{' (1-8)'}} *</label>
                <p-inputNumber
                  id="bigScreenWeekInt"
                  class="mt-2 inputfield w-full"
                  formControlName="interval"
                  [style]="{'width': '100%'}"
                  [min]="1"
                  [max]="8"
                  suffix="&nbsp;{{'COMMON.WEEKS' | translate}}">
                </p-inputNumber>
              </div>
            </div>


            <!-- WEEK DELAY -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="bigScreenWeekDelay">{{'CHECKLIST.DELAY' | translate}}{{' (0-8)'}} *</label>
                <p-inputNumber
                  id="bigScreenWeekDelay"
                  class="mt-2 inputfield w-full"
                  formControlName="delay"
                  [style]="{'width': '100%'}"
                  [min]="0"
                  [max]="8"
                  suffix="&nbsp;{{'COMMON.WEEKS' | translate}}">
                </p-inputNumber>
              </div>
            </div>


            <!-- SELECT DAY -->
            <div class="col-12">
              <label for="bigScreenSelectDay">{{'CHECKLIST.CHOOSE_DAY' | translate}} *</label>
              <div class="field mt-2">
                <p-dropdown
                  id="bigScreenSelectDay"
                  formControlName="day"
                  placeholder="{{'COMMON.CHOOSE' | translate}}"
                  [options]="days"
                  [style]="{'width': '50%'}"
                  optionValue="value"
                  optionLabel="name">
                </p-dropdown>
              </div>
            </div>

          </div>
        </p-tabPanel>

        <!-- MONTHLY -->
        <p-tabPanel formGroupName="monthly" header="{{'CHECKLIST.REPEAT_MONTHLY' | translate}}">
          <div class="grid mt-2">

            <!-- MONTH INTERVAL -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="bigScreenMonthInt">{{'CHECKLIST.INTERVAL' | translate}}{{' (1-24)'}}</label>
                <p-inputNumber
                  id="bigScreenMonthInt"
                  class="mt-2 inputfield w-full"
                  formControlName="interval"
                  [style]="{'width': '100%'}"
                  [min]="1"
                  [max]="24"
                  suffix="&nbsp;{{'COMMON.MONTHS.MONTHS' | translate}}">
                </p-inputNumber>
              </div>
            </div>

            <!-- START MONTH -->
            <div class="col-12 md:col-6">
              <div class="field">
                <label for="bigScreenSelectMonth">{{'CHECKLIST.START_MONTH' | translate}}</label>
                <div class="field mt-2">
                  <p-dropdown
                    id="bigScreenSelectMonth"
                    [options]="months"
                    formControlName="startMonth"
                    (ngModelChange)="getDays($event)"
                    [style]="{'width': '100%'}"
                    optionValue="value"
                    optionLabel="name">
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>

          <!--CHOOSE DATE OR WHEN -->

          <!-- DATE -->
          <div class="col-12 flex flex align-items-center">

            <p-radioButton name="monthMode" [value]="0"  [ngModelOptions]="{standalone: true}" [(ngModel)]="monthMode" (ngModelChange)="swapDisable()"></p-radioButton>

            <p-dropdown
              appendTo="body"
              class="ml-3"
              formControlName="date"
              placeholder="{{'COMMON.DATE.DATE' | translate}}"
              [style]="{'width': '100px'}"
              [options]="numberOfDates">
            </p-dropdown>

          </div>

          <!-- WHEN -->
          <div class="col-12 flex align-items-center">

            <p-radioButton name="monthMode" [value]="1" [ngModelOptions]="{standalone: true}" [(ngModel)]="monthMode" (ngModelChange)="swapDisable()"></p-radioButton>

            <p-dropdown
              appendTo="body"
              class="ml-3"
              placeholder="{{'COMMON.WHEN' | translate}}"
              [options]="whenOption"
              formControlName="when"
              [style]="{'width': '125px'}"
              optionValue="value"
              optionLabel="name">
            </p-dropdown>

            <p-dropdown
              appendTo="body"
              class="ml-3"
              [options]="days"
              formControlName="day"
              placeholder="{{'CHECKLIST.CHOOSE_DAY' | translate}}"
              optionValue="value"
              optionLabel="name">
            </p-dropdown>
          </div>

        </p-tabPanel>
      </p-tabView>

      <!-- SAVE -->
      <div class="col-12 flex justify-content-end">
        <button
          pButton
          class="p-button-raised"
          [disabled]="!cronJobForm.valid"
          type="submit"
          label="{{'BUTTON.SAVE' | translate}}"
        ></button>
      </div>
    </div>
  </form>
</ng-template>

<!-- //** TEMPLATE FORM **//-->
<form *ngIf="selectedType.index === 2"
      [formGroup]="templateForm"
      (ngSubmit)="onSubmit()"
      class="w-full pl-2">
  <!-- NAME -->
  <div class="field col-12">
    <label for="bigScreenTemplateName">{{'COMMON.NAME' | translate}} *</label>
    <input
      id="bigScreenTemplateName"
      name="name"
      type="text"
      formControlName="name"
      pInputText
      required
      class="inputfield w-full form-control"/>
  </div>

  <!-- USER ACCESS : Choose if USER should have Access to the template (PS: Old name, from when users only had APP)-->
  <div class="mt-4 flex align-items-center">
    <p-inputSwitch
      class="ml-2"
      formControlName="appAccess">
    </p-inputSwitch>
    <span class="ml-3">{{'CHECKLIST.SHOW_USER' | translate}}</span>
  </div>

  <!-- SAVE -->
  <div class="col-12 flex justify-content-end">
    <button
      pButton
      class="p-button-raised"
      [disabled]="!templateForm.valid"
      type="submit"
      label="{{'BUTTON.SAVE' | translate}}"
    ></button>
  </div>
</form>
