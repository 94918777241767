<div class="grid">
    <div  class="col-6">
        <h2 style="margin-top: 10px">{{'EXERCISE.EXERCISE_TEMPLATE' | translate}}</h2>
    </div>
    <div class="col-6 flex justify-content-end">
        <button pButton (click)="addTemplate()" type="button" icon="pi pi-plus"
                class="p-button-rounded p-button-raised"></button>
    </div>

    <!-- EXERCISE TABLE -->
    <div class="col-12 pl-0 pr-0">
        <p-table #et [globalFilterFields]="['name']" [loading]="templateLoading"
                 [rowHover]="true" [value]="templates | dashboardFilter : 'exercise' : selectedExerciseFilter?.key"
                 breakpoint="200px" dataKey="key" responsiveLayout="stack">
            <ng-template pTemplate="caption" class="pt-5">
                <div class="grid">
                    <div class="col-8 pl-0">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                                    (input)="et.filterGlobal($event.target.value, 'contains')"
                                    pInputText
                                    placeholder="{{'COMMON.SEARCH' | translate}}"
                                    type="text"/>
                        </span>
                    </div>
                </div>
            </ng-template>
            <!-- HEADER -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <div class="flex justify-content-center">
                            <i class="far fa-cog"></i>
                        </div>
                    </th>
                    <th class="p-2">
                        {{'COMMON.NAME' | translate}}
                    </th>
                    <th style="width: 5rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-exercise let-expanded="expanded">
                <tr>
                    <td class="p-2">
                        <div class="flex justify-content-center">
                            <button [icon]="'far fa-cog'" class="p-button-text p-button-rounded p-button-plain"
                                    pButton type="button" (click)="templateDetails(exercise)">
                            </button>
                        </div>
                    </td>
                    <td class="p-2">
                        {{exercise.name}}
                    </td>
                    <td class="p-2">
                        <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                [pRowToggler]="exercise" class="p-button-text p-button-rounded p-button-plain"
                                pButton type="button">
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-e>
                <tr>
                    <td colspan="3">
                        <div class="grid p-3 flex justify-content-center">
                            <!-- Description -->
                            <div class="field col-12 m-0 p-0">
                                    <span class="p-float-label">
                                        <textarea id="descriptionL"
                                                  rows="4"
                                                  style="resize: none;"
                                                  cols="30"
                                                  pInputTextarea
                                                  [ngModel]="e.details"
                                                  disabled
                                                  class="inputfield w-full"></textarea>
                                        <label for="descriptionL">{{'COMMON.DESCRIPTION' | translate}}</label>
                                    </span>
                            </div>
                            <!-- Goals -->
                            <div class="field col-12 m-0 p-0 pt-4">
                                    <span class="p-float-label">
                                        <textarea id="goalsL"
                                                  rows="4"
                                                  style="resize: none;"
                                                  cols="30"
                                                  pInputTextarea
                                                  [ngModel]="e.evaluation.goal"
                                                  disabled
                                                  class="inputfield w-full"></textarea>
                                        <label for="goalsL">{{'EXERCISE.EVALUATION.GOAL' | translate}}</label>
                                    </span>
                            </div>
                            <!-- Competency MOCK table -->
                            <div [style]="{'height' : '55px'}"
                                 class="col-12 flex flex-wrap align-content-center mockHeader">
                                {{'COMPETENCY.CONNECTED_TO_EXERCISE' | translate}}
                            </div>
                            <ng-container *ngIf="cTLength(e.competencyTypes) > 0; else noCompetencies">
                                <div *ngFor="let competencyId of templateCTMap.get(e.key); index as i"
                                     [style]="{'height' : '55px'}"
                                     class="col-12 flex flex-wrap align-content-center mockRow">
                                    {{ctNameMap.get(competencyId)}}
                                </div>
                            </ng-container>
                            <ng-template #noCompetencies>
                                <div [style]="{'height' : '55px'}" class="col-12 flex flex-wrap align-content-center mockRow">
                                    {{'COMMON.NO_COMPETENCY' | translate}}
                                </div>
                            </ng-template>

                            <!-- Files MOCK table -->
                            <div [style]="{'height' : '55px'}"
                                 class="col-12  flex flex-wrap align-content-center mockHeader mt-1">
                                {{'FILE.ATTACHED_FILES' | translate}}
                            </div>
                            <ng-container *ngIf="e.exerciseFiles.length > 0; else noFiles">
                                <div *ngFor="let fileID of e.exerciseFiles; index as i" [style]="{'height' : '55px'}"
                                     class="col-12 flex flex-wrap align-content-center mockRow">
                                    {{fileNameMap.get(fileID)}}
                                </div>
                            </ng-container>
                            <ng-template #noFiles>
                                <div [style]="{'height' : '55px'}" class="col-12 flex flex-wrap align-content-center mockRow">
                                    {{'FILE.NO_FILES' | translate}}
                                </div>
                            </ng-template>
                            <div class="col-12 flex justify-content-end">
                                <button pButton type="button"
                                        class="p-button-raised"
                                        label="{{'EXERCISE.CREATE_EXERCISE' | translate}}"
                                        (click)="create(e)"
                                ></button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">{{'EXERCISE.NO_TEMPLATES' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
