<!-- Block UI when someone complete the checklist -->
<p-blockUI [blocked]="isCompleted$ | async" [target]="container">
  <i class="pi pi-check-circle" style="font-size: 3rem; color: #73BF21"></i>
</p-blockUI>

<!-- Block UI when someone deletes the checklist -->
<p-blockUI [blocked]="!checklist" [target]="container">
  <i *ngIf="wait === 0" class="pi pi-trash" style="font-size: 3rem; color: #D0021B"></i>
  <i *ngIf="wait > 0" class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</p-blockUI>


<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<!-- Toast -->
<p-toast key='error' position="top-left" styleClass="customToast"></p-toast>


<p-panel #container>
  <div class="grid">
    <div class="col-12">

      <!-- STATUS -->
      <p-fieldset legend="{{'COMMON.STATUS' | translate}}" [toggleable]="true">
        <div class="grid flex justify-content-around flex-wrap">

          <!-- Show Station connected to checklist -->
          <div>
            <i class="far fa-warehouse"></i>&nbsp;{{station?.name}}
          </div>

          <!-- Show Asset and Asset Status -->
          <div>
          <span [ngStyle]="setStatus(asset)">
            <i class="far fa-truck"></i>&nbsp;{{asset?.name}}
          </span>
          </div>

          <div>
            <span *ngIf="checklistDeviations.length > 0" style="color: #f5a623">
              <i class="far fa-exclamation-triangle"></i>
              {{checklistDeviations.length}}
            </span>
            <span *ngIf="checklistDeviations.length === 0" style="color: #73BF21">
              <i class="far fa-exclamation-triangle"></i>
              {{0}}
            </span>
          </div>
        </div>
      </p-fieldset>
    </div>

    <!--Complete and Add new Item button-->
    <div class="col-12 flex justify-content-end">

      <button type="button"
              pButton
              label="{{'COMMON.COMMENTS' | translate}}{{'('+checklist?.comments?.length+')'}}"
              icon="pi pi-comments"
              class="p-button-outlined p-button-raised"
              (click)="openComments()">
      </button>

      <p-splitButton [disabled]="isObserver"
                     label="{{'BUTTON.COMPLETE' | translate}}"
                     icon="pi pi-check-circle"
                     appendTo="body"
                     class="p-button-raised"
                     (onClick)="complete()"
                     [model]="splitButton">
      </p-splitButton>
    </div>

    <div class="col-12 p-0 pt-2">
      <!-- TABLE -->
      <p-table [value]="checklist?.items | keyvalue" responsiveLayout="scroll">


        <!-- HEADER -->
        <ng-template pTemplate="header">
          <tr>
            <th class="col-fixed" style="width: 3rem">#</th>
            <th class="col">{{'COMMON.NAME' | translate}}</th>
            <th class="col-fixed" style="width: 3rem"></th>
          </tr>
        </ng-template>

        <!-- BODY -->
        <ng-template pTemplate="body" let-item>
          <tr>
            <td *ngIf="!this.itemDeviationsMap.has(item.key)"><p-checkbox [readonly]="isObserver" [(ngModel)]="item.value.checked" [binary]="true"></p-checkbox></td>
            <td *ngIf="this.itemDeviationsMap.has(item.key)"><i class="far fa-exclamation-triangle" style="color: #F5A623"></i></td>
            <td>{{item.value.name}}</td>
            <td>
              <p-menu
                #menu
                appendTo="body"
                [popup]="true"
                [model]="checklistMenuItemsMap.get(item.key)">
              </p-menu>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-cog"
                (click)="menu.toggle($event)"
                class="p-button-rounded p-button-text">
              </button>
            </td>
          </tr>
        </ng-template>

        <!-- EMPTY MESSAGE -->
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">{{'WARNING.NO_DATA' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-panel>



