<div class="grid">
    <div class="col-12 flex justify-content-center">
        <p>{{'DASHBOARD.ORDER.MESSAGE' | translate}}</p>
    </div>
    <div class="col-12 flex justify-content-center">
        <p-selectButton [options]="amountOptions"
                        [(ngModel)]="smsOrder.numberOfSMS"
                        optionLabel="name"
                        optionValue="value">
        </p-selectButton>
    </div>
    <div class="col-12 flex justify-content-center">
        <button pButton type="button"
                label="{{'DASHBOARD.ORDER_SMS (2)' | translate}}"
                class="p-button-raised"
                (click)="orderSMS()"
        ></button>
    </div>
</div>
