<div class="grid">
  <div class="col-12">
    <!-- Exposure eval -->
    <div *ngIf="fromExpo; else elseBlockA">
      <span class="p-float-label mt-3">
        <textarea id="description1" class="w-full" rows="5" pInputTextarea [(ngModel)]="description"></textarea>
        <label for="description1">{{'COMMON.DESCRIPTION' | translate}}</label>
      </span>
    </div>
    <!-- Emergency eval -->
    <ng-template #elseBlockA>
      <span class="p-float-label mt-3">
        <textarea id="description2" class="w-full" rows="5" pInputTextarea [(ngModel)]="description"></textarea>
        <label for="description2">{{'COMMON.DESCRIPTION' | translate}} (Call out)</label>
      </span>
    </ng-template>
    <!-- Exposure eval -->
    <div *ngIf="fromExpo; else elseBlockB">
      <span class="p-float-label mt-3">
        <textarea id="measures" class="w-full" rows="5" pInputTextarea [(ngModel)]="measuresOnSite"></textarea>
        <label for="measures">{{'EVENT.EVAL.MEASURES' | translate}}</label>
      </span>
    </div>
    <!-- Emergency eval -->
    <ng-template #elseBlockB>
      <span class="p-float-label mt-3">
        <textarea id="measuresOnSite" class="w-full" rows="5" pInputTextarea [(ngModel)]="measuresOnSite"></textarea>
        <label for="measuresOnSite">{{'EVENT.EVAL.MEASURES_ON_SITE' | translate}}</label>
      </span>
    </ng-template>
    <span class="p-float-label mt-3">
      <textarea id="learningPoints" class="w-full" rows="5" pInputTextarea [(ngModel)]="learningPoints"></textarea>
      <label for="learningPoints">{{'EVENT.EVAL.LEARNING_POINTS' | translate}}</label>
    </span>
    <span class="p-float-label mt-3">
      <textarea id="various" class="w-full" rows="5" pInputTextarea [(ngModel)]="various"></textarea>
      <label for="various">{{'EVENT.EVAL.VARIOUS' | translate}}</label>
    </span>
    <div class="col-12 flex justify-content-end">
      <p-button label="{{'BUTTON.SAVE' | translate}}"
                icon="pi pi-check"
                iconPos="right"
                (click)="save()"
      ></p-button>
    </div>
  </div>
</div>
