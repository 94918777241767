import { Injectable } from '@angular/core';
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {Daily} from "../../model/cronJob";

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(
      private translateService: TranslateService,
  ) { }

  /**
   * Method to calculate days in the month for the select date
   * @param month: number 1-12?
   */
  getDays(month) {
    const today = moment();
    // -1 to compensate that db uses 1-12 and moment uses 0-11
    const startMonth = moment().month(month-1);

    if (startMonth.isBefore(today, 'month')) {
      startMonth.add(1, 'year');
    }

    return Array(startMonth.daysInMonth()).fill(0).map((x, i) => i + 1);
  }

  /**
   * @return A dateOption
   */
  getDaysOptions() {
    return [
      {name: this.translateService.instant('COMMON.DATE.MON'), value: 0},
      {name: this.translateService.instant('COMMON.DATE.TUE'), value: 1},
      {name: this.translateService.instant('COMMON.DATE.WED'), value: 2},
      {name: this.translateService.instant('COMMON.DATE.THU'), value: 3},
      {name: this.translateService.instant('COMMON.DATE.FRI'), value: 4},
      {name: this.translateService.instant('COMMON.DATE.SAT'), value: 5},
      {name: this.translateService.instant('COMMON.DATE.SUN'), value: 6},
    ];
  }

  /**
   * @return A monthOption
   */
  getMonthOptions(){
    return [
      {name: this.translateService.instant('COMMON.MONTHS.JANUARY'), value: 1},
      {name: this.translateService.instant('COMMON.MONTHS.FEBRUARY'), value: 2},
      {name: this.translateService.instant('COMMON.MONTHS.MARCH'), value: 3},
      {name: this.translateService.instant('COMMON.MONTHS.APRIL'), value: 4},
      {name: this.translateService.instant('COMMON.MONTHS.MAY'), value: 5},
      {name: this.translateService.instant('COMMON.MONTHS.JUNE'), value: 6},
      {name: this.translateService.instant('COMMON.MONTHS.JULY'), value: 7},
      {name: this.translateService.instant('COMMON.MONTHS.AUGUST'), value: 8},
      {name: this.translateService.instant('COMMON.MONTHS.SEPTEMBER'), value: 9},
      {name: this.translateService.instant('COMMON.MONTHS.OCTOBER'), value: 10},
      {name: this.translateService.instant('COMMON.MONTHS.NOVEMBER'), value: 11},
      {name: this.translateService.instant('COMMON.MONTHS.DECEMBER'), value: 12},
    ];
  }

  /**
   * Converts a DayOption to a Day Number
   * @param day
   *
   * @return number 0-6;
   */
  convertDayOptionToNumber(day){
    if(day.mon){
      return 0;
    } else if(day.tue){
      return 1;
    } else if(day.wed){
      return 2;
    } else if(day.thu){
      return 3;
    } else if(day.fri){
      return 4;
    } else if(day.sat){
      return 5;
    } else if(day.sun){
      return 6;
    } else {
      return null;
    }
  }

  /**
   * @param dayNumber: value 0-6 representing day in number.
   * @return a boolean object with day corresponding dayNumber in short form (mon, tue, ...) = true, and rest false
   */
  convertDayNumberToDayObject(dayNumber): Daily{
    const daily: Daily = {
      fri: false,
      mon: false,
      sat: false,
      sun: false,
      thu: false,
      tue: false,
      wed: false
    };

    switch (dayNumber) {
      case 0:
        daily.mon = true;
        break;
      case 1:
        daily.tue = true;
        break;
      case 2:
        daily.wed = true;
        break;
      case 3:
        daily.thu = true;
        break;
      case 4:
        daily.fri = true;
        break;
      case 5:
        daily.sat = true;
        break;
      case 6:
        daily.sun = true;
        break;
    }

    return daily;
  }

  /**
   * Takes in an object to edit if when = 0: obj.first = true ; if when = 1: obj.last = true
   *
   * @param obj: Object that you want to edit with first/last
   * @param when: number 0 or 1
   */
  setWhen(obj: any, when: number){
    switch (when) {
      case 0:
        obj.first = true;
        obj.last = false;
        return obj;
      case 1:
        obj.last = true;
        obj.first = false;
        return obj;
    }
  }

  /**
   *
   * @param dayNumber: Representing mon-sunday with 0-6
   *
   * @return the english word of the day in small letters
   */
  convertDayNumberToEnglish(dayNumber: number){
    switch (dayNumber) {
      case 0:
        return 'monday';
      case 1:
        return 'tuesday';
      case 2:
        return 'wednesday';
      case 3:
        return 'thursday';
      case 4:
        return 'friday';
      case 5:
        return 'saturday';
      case 6:
        return 'sunday';
      default: return null;
    }
  }

  convertEnglishToDayNumber(english){
    switch (english) {
      case 'monday':
        return 0;
      case 'tuesday':
        return 1;
      case 'wednesday':
        return 2;
      case 'thursday':
        return 3;
      case 'friday':
        return 4;
      case 'saturday':
        return 5;
      case 'sunday':
        return 6;
      default: return null;
    }
  }
}
