<div class="grid">
    <div class="col-12">
        <h2 style="margin-top: 10px; margin-left: 5px">{{'MAIN_FRAME.MY_COMPETENCIES' | translate}}</h2>
    </div>
</div>

<div class="grid">
    <div class="col-12">
        <p-table #ct [value]="competencies" breakpoint="200px">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 45%" pSortableColumn="competencyType">
                        {{'COMPETENCY.COMPETENCY' | translate}}
                        <p-sortIcon field="competencyType"></p-sortIcon>
                    </th>
                    <th>
                        {{'COMMON.NAME' | translate}}
                    </th>
                    <th pSortableColumn="validDate">
                        {{'COMMON.DATE.DATE' | translate}}
                        <p-sortIcon field="validDate"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-competency>
                <tr [ngStyle]="setStatus(competency.status)">
                    <td>
                        {{competency.competencyType}}
                    </td>
                    <td>
                        {{competency.person}}
                    </td>
                    <td>
                        {{newDate(competency.validDate)}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">{{'EVENT.NO_RESULT' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>
