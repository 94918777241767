import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {map, mergeMap, publish} from "rxjs/operators";
import {Asset} from "../../model/asset";

@Injectable({
  providedIn: 'root'
})
/**
 * Handles everything with Assets
 */
export class AssetService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get assets
   */
  getAssets(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/assets`).snapshotChanges()
      )
    );
  }

  /**
   * Get archived assets
   */
  getArchivedAssets(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/assets`, ref => {
              return ref.orderByChild('archived').equalTo(true);
            }).snapshotChanges()
        )
    );
  }

  /**
   * Get asset
   * @param assetId asset fBid
   */
  getAsset(assetId: string): Observable<Asset> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/assets/${assetId}`).valueChanges()
      )
    ) as Observable<Asset>;
  }

  /**
   * Get asset types
   */
  getAssetTypes(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/assetTypes`).snapshotChanges()
        )
    );
  }

  /**
   * Delete asset type
   * @param key Type fbid
   */
  deleteType(key) {
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/assetTypes/${key}`).remove()
        )
    )).connect();
  }

  /**
   * Set the status color of the asset (danger, warning og success)
   * @param asset
   * @param deviationMap
   */
  getAssetStatus(asset, deviationMap) {
    const success = '#73BF21';
    const warning = '#F5A623';
    const danger =  '#D0021B';
    if (!asset || !asset.inCommission) {
      return {'color': danger};
    } else {
      return deviationMap.has(asset.key) ? {'color': warning} : {'color': success};
    }
  }

  getInCommission(assetId: string) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/assets/${assetId}/inCommission`).valueChanges()
      )
    );
  }

  updateInCommission(assetId: string, inCommission: boolean) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/assets/${assetId}/inCommission`).set(inCommission)
      )
    )).connect();
  }

  /**
   * Add new asset type.
   * @param type AssetType object
   */
  addAssetType(type: any) {
    return publish()(this.tenantId$.pipe(
        map(tenantId => this.db.list(`tenants/${tenantId}/assetTypes/`).push(type))
    )).connect();
  }

  /**
   * Add new asset.
   * @param asset Asset object
   */
  addAsset(asset: Asset) {
    return publish()(this.tenantId$.pipe(
        map(tenantId => this.db.list(`tenants/${tenantId}/assets/`).push(asset))
    )).connect();
  }

  /**
   * Update asset
   * @param assetId Asset fbid
   * @param asset Asset object
   */
  updateAsset(assetId: string, asset: Asset) {
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/assets/${assetId}`).update(asset)
        )
    )).connect();
  }

  /**
   * Delete Asset
   * @param assetId Asset fbid
   */
  deleteAsset(assetId: string) {
    return publish()(this.tenantId$.pipe(
        map(tenantId => this.db.list(`tenants/${tenantId}/assets/`).remove(assetId))
    )).connect();
  }
}
