<p-toast position="top-center"></p-toast>
<div class="grid">
    <div class="col-12 w-full flex justify-content-center flex-wrap align-items-baseline">
        <ul class="pl-2 ml-2 mt-0" >
            <li>{{'PASSWORD.LOWER' | translate}}</li>
            <li>{{'PASSWORD.UPPER' | translate}}</li>
            <li>{{'PASSWORD.NUMERIC' | translate}}</li>
            <li>{{'PASSWORD.LENGTH' | translate}}</li>
        </ul>
    </div>
    <div class="col-12 flex justify-content-center">
       <span class="p-float-label">
             <p-password id="pw1" [(ngModel)]="pw1" class="p-password-meter"
                         promptLabel="{{'PASSWORD.WRITE' | translate}}"
                         weakLabel="{{'PASSWORD.WEAK' | translate}}"
                         mediumLabel="{{'PASSWORD.MEDIUM' | translate}}"
                         strongLabel="{{'PASSWORD.STRONG' | translate}}">

             </p-password>
            <label for="pw1">{{'MY_PAGE.PASSWORD' | translate}}</label>
        </span>
    </div>
    <div class="col-12 flex justify-content-center mt-2">
        <span class="p-float-label">
           <p-password id="pw2" [(ngModel)]="pw2" strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                       [feedback]="false">
           </p-password>
            <label for="pw2">{{'MY_PAGE.REPEAT_PASSWORD' | translate}}</label>
        </span>
    </div>
    <div class="col-12 flex justify-content-center mt-2">
        <button [disabled]="!pw1" class="p-button-raised"
                pButton label="{{'MY_PAGE.UPDATE_PASSWORD' | translate}}"
                (click)="updatePW()"></button>
    </div>
</div>
