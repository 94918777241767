import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {TranslateService} from '@ngx-translate/core';
import {map, mergeMap, publish} from 'rxjs/operators';
import {Md5} from "ts-md5";
import {FileData, FileTag, FileTagRelation} from '../../model/fileRelated';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  tenantId$: Observable<any>;

  constructor(
      private tenantService: TenantService,
      private db: AngularFireDatabase,
      public translateService: TranslateService,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  // SHARED ################################################################################################################################

  /**
   * Get shared fileTags
   */
  getSharedFileTags(): Observable<any>{
    return this.tenantId$.pipe(
        mergeMap(
            () => this.db.list(`shared/Files/fileTags`).snapshotChanges()
        )
    );
  }

  /**
   * Get shared files (Files available to all tenants)
   */
  getSharedFiles(): Observable<any> {
    return this.tenantId$.pipe(mergeMap(() => this.db.list(`shared/Files/commonFiles`).snapshotChanges()));
  }

  /**
   * Get shared file relations
   */
  getSharedFileTagRelations(): Observable<any> {
    return this.tenantId$.pipe(mergeMap(() => this.db.list(`shared/Files/commonFileTagRelations`).snapshotChanges()));
  }

  checkIfSharedFileExist(filename: string){
    return this.tenantId$.pipe(
        mergeMap(
            () => this.db.object(`shared/Files/${Md5.hashStr(filename)}`).valueChanges()
        )
    );
  }

  addSharedTag(key: string, tagObj: FileTag){
    return publish()(this.tenantId$.pipe(map(() => this.db.list('shared/Files/tags').set(key, tagObj)))).connect();
  }
  // TENANT ################################################################################################################################

  /**
   * Get fileTags
   */
  getFileTags(): Observable<any>{
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/fileTags/`).snapshotChanges()
    ));
  }

  /**
   *
   * Get fileTags
   */
  getArchivedFileTags(): Observable<any>{
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/fileTags`, ref => {
              return ref.orderByChild('archived').equalTo(true);
            }).snapshotChanges()
        ));
  }

  /**
   * Get exercise file relations
   */
  getExerciseFileRelations(){
    return this.tenantId$.pipe(mergeMap(tenantId => this.db.list(`tenants/${tenantId}/exerciseFileRelations`).snapshotChanges()));
  }

  /**
   * Get Common file relations
   */
  getFileTagRelations(): Observable<any> {
    return this.tenantId$.pipe(mergeMap(tenantId => this.db.list(`tenants/${tenantId}/fileTagRelations`).snapshotChanges()));
  }

  addFileTagRelation(relation: FileTagRelation){
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.list(`tenants/${tenantId}/fileTagRelations`).push(relation)
        )
    )).connect();
  }

  /**
   * Get all active files (Admin & Public)
   */
  getAllFiles(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(tenantId => this.db.list(`tenants/${tenantId}/files`, ref => {
          return ref.orderByChild('archived').equalTo(false);
        }).snapshotChanges())
    );
  }

  /**
   * Get all archived files (Admin & Public)
   */
  getAllArchivedFiles(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap( tenantId => this.db.list(`tenants/${tenantId}/files`, ref => {
          return ref.orderByChild('archived').equalTo(true);
        }).snapshotChanges()
    ));
  }

  /**
   * Get file
   * @param key File key
   */
  getFile(key): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/files/${key}`).valueChanges()
        )
    );
  }

  /**
   * Get all Admin files
   */
  getAdminFiles(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/files`, ref => {
              return ref.orderByChild('admin').equalTo(true);
            }).snapshotChanges()
        )
    );
  }

  /**
   * Get all Public files
   */
  getNonAdminFiles(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/files/`, ref => {
              return ref.orderByChild('admin').equalTo(false);
            }).snapshotChanges()
        )
    );
  }

  /**
   * Add file to DB.
   * @param file File data
   */
  addFile(file: FileData){
    const dbKey = Md5.hashStr(file.name).toString();
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.list(`tenants/${tenantId}/files/`).set(dbKey, file)
        )
    )).connect();
  }

  /**
   * Add new fileTag
   * @param tagObj Tag object
   */
  addTag(tagObj: FileTag) {
    return publish()(this.tenantId$.pipe(
        map(tenantId => this.db.list(`tenants/${tenantId}/fileTags/`).push(tagObj))
    )).connect();
  }

  /**
   * Delete fileTag
   * @param key Tag object
   */
  deleteTag(key: string) {
    console.log(key);
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/fileTags/${key}`).remove()
        )
    )).connect();
  }

  /**
   * Update file info
   * @param key File key
   * @param fileData File object
   */
  updateFile(key: string, fileData: FileData) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/files/${key}`).set(fileData)
        )
    )).connect();
  }

  /**
   * Delete file / tag relation
   * @param key
   */
  deleteFileTagRelation(key: string) {
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/fileTagRelations/${key}`).remove()
        )
    )).connect();
  }

  /**
   * Delete file
   * @param key fileId
   */
  deleteFile(key: string) {
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/files/${key}`).remove()
        )
    )).connect();
  }

  updateTag(key: string, tag: FileTag) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/fileTags/${key}`).set(tag)
        )
    )).connect();
  }

  addCTTag(key: string, tag: FileTag) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/fileTags/`).set(key, tag)
      )
    )).connect();
  }
}
