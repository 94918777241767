import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from './services/service/auth.service';
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Injectable()

/**
 * Auth Guard checks if user is logged in, if not return to /login
 */
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth,
  ) {
    this.authObservable = new Observable((observer) => {
      afAuth.authState.subscribe((auth) => {
        observer.next(!!auth);

        if(!auth){
          router.navigate(['/login'], {replaceUrl: true})
            .catch(console.log);
        }
      });
    });
  }

  canActivate(): any{
    return this.authObservable;
  }

  private readonly authObservable: Observable<boolean>;
}
