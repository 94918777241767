import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {FilterService} from 'primeng/api';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {map, mergeMap, publish} from 'rxjs/operators';
import {Appointment} from '../../model/appointment';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    tenantId$: Observable<any>;

    constructor(
        private tenantService: TenantService,
        private filterService: FilterService,
        private db: AngularFireDatabase
    ) {
        this.tenantId$ = this.tenantService.getTenantId();
    }

    /**
     * Get all Appointments.
     */
    getAppointments(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/events/appointments`).snapshotChanges()
            )
        );
    }
    /**
     * Get all Appointments.
     */
    getOldAppointments(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/events/miscEvents`).snapshotChanges()
            )
        );
    }

    /**
     * Get all Appointment types.
     */
    getAppointmentTypes(): Observable<any>{
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/events/types`, ref => {
                    return ref.orderByChild('type').equalTo("misc");
                }).snapshotChanges()
            )
        );
    }

    /**
     * Add appointmentType.
     * @param type AppointmentType Object.
     */
    addAppointmentType(type: { name: string; type: string }) {
        return publish()(this.tenantId$.pipe(
            map(tenantId => this.db.list(`tenants/${tenantId}/events/types`).push(type))
        )).connect();
    }

    /**
     * Delete Appointment type.
     * @param typeKey AppointmentType <FBID>.
     */
    deleteAppointmentType(typeKey) {
        return publish()(this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.object(`tenants/${tenantId}/events/types/${typeKey}`).remove()
            )
        )).connect();
    }

    /**
     * Add new Appointment and return key.
     * @param newAppointment Appointment Object.
     */
    addAppointmentGetKey(newAppointment: Appointment) {
        return this.tenantId$.pipe(
            map(tenantId => {
                const ref = this.db.list(`tenants/${tenantId}/events/appointments/`).push(newAppointment);
                return ref.key;
            })
        );
    }

    /**
     * Update Appointment.
     * @param key Appointment <FBID>.
     * @param appointment Appointment Object.
     */
    updateAppointment(key, appointment) {
        return publish()(this.tenantId$.pipe(
            map(
                tenantId => this.db.object(`tenants/${tenantId}/events/appointments/${key}`).set(appointment)
            )
        )).connect();
    }

    /**
     * Add participant to Appointment.
     * @param appointmentId Appointment <FBID>.
     * @param id Subject <FBID>.
     * @param b Boolean (Always true).
     */
    addParticipantToTask(appointmentId, id: any, b: boolean) {
        return publish()(this.tenantId$.pipe(
            map(tenantId =>
                this.db.object(`tenants/${tenantId}/events/appointments/${appointmentId}/participants/${id}`).set(b)))).connect();
    }

    /**
     * Delete Appointment.
     * @param key Appointment <FBID>.
     */
    deleteAppointment(key: string) {
        return publish()(this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.object(`tenants/${tenantId}/events/appointments/${key}`).remove()
            )
        )).connect();
    }
}
