<div class="grid">
  <div class="col-12">
    <h2 style="margin-top: 10px; margin-left: 5px">{{'COMPETENCY.TYPES' | translate}}</h2>
  </div>
</div>

<div class="grid">
  <div class="col-12 p-0">
    <p-table #ct
             [value]="ctList"
             sortMode="single"
             [globalFilterFields]="['name']"
             [rowHover]="true"
             breakpoint="200px"
             [rows]="13" [showCurrentPageReport]="true" [loading]="loading" responsiveLayout="scroll"
             [paginator]="true" currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'TABLE.CTYPES' | translate}}"
    >
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col" style="min-width: 200px; max-width: 500px">
            <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input
                      pInputText
                      type="text"
                      (input)="ct.filterGlobal($event.target.value, 'contains')"
                      placeholder="{{'COMMON.SEARCH' | translate}}"
                    />
            </span>
          </div>
          <div class="col">
            <div class="float-right">
              <button pButton pRipple (click)="addCompetencyType()" type="button" icon="pi pi-plus" class="p-button-rounded"></button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 40%" pSortableColumn="name">
            {{'COMMON.NAME' | translate}}
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th style="width: 30%" pSortableColumn="type">
            {{'COMMON.TYPE' | translate}}
            <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th style="width: 15%"><i class="far fa-bell"></i></th>
          <th pSortableColumn="defaultValidity">
            <i class="far fa-calendar-check"></i>
            <p-sortIcon field="defaultValidity"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ct>
        <tr (click)="editCompetencyType(ct)">
          <td>{{ct.name}}</td>
          <td>{{getType(ct.type)}}</td>
          <td *ngIf="ct.timeToExpire">{{ct.timeToExpire}}</td>
          <td *ngIf="!ct.timeToExpire">{{'—'}}</td>
          <td *ngIf="ct.defaultValidity">{{ct.defaultValidity}}</td>
          <td *ngIf="!ct.defaultValidity">{{'—'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
