<p-toast key='temp-login-error' position="top-right" styleClass="customToast"></p-toast>

<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid" style="margin-top: 15px">
  <div class="col-12 md:col-6">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="pi pi-user"></i></span>
      <input [(ngModel)]="name" placeholder="{{'COMMON.NAME' | translate}}" type="text" pInputText>
    </div>
  </div>

  <div class="col-12 md:col-6">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="pi pi-envelope"></i></span>
      <input [(ngModel)]="email" placeholder="{{'PERSONAL.EMAIL' | translate}}" type="email" pInputText>
    </div>
  </div>

  <div class="col">
    <p-button class="float-right" pRipple [disabled]="!(name && email)" (onClick)="add()"
              label="{{'BUTTON.ADD' | translate}}"></p-button>
  </div>
</div>
