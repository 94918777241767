<div class="grid p-fluid">
  <div class="col-12">
    <div class="card">
      <div class="field">
        <label for="roleSelect">{{'ADMIN.NEW_EXISTING_USER_COMPONENT.USER_ACCESS' | translate}}</label>
        <p-dropdown id="roleSelect" [options]="roles" [(ngModel)]="selectedRole" optionLabel="name" appendTo="body"
                    placeholder="{{'ADMIN.ROLES.CHOOSE' | translate}}">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedRole">
              <div>{{selectedRole.name | translate}}</div>
            </div>
          </ng-template>
          <ng-template let-filter pTemplate="item">
            <div>
              <div>{{filter.name | translate}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <div class="col-12">
    <button type="button" pButton *ngIf="!this.role" pRipple (click)="add()" [disabled]="!this.selectedRole" label="{{'BUTTON.ADD' | translate}}"></button>
    <button type="button" pButton *ngIf="this.role" pRipple (click)="add()" [disabled]="!this.selectedRole" label="{{'BUTTON.SAVE' | translate}}"></button>
  </div>
</div>
