<div class="grid">
  <div class="col-12 flex align-items-center justify-content-between">
    <h2 style="margin-top: 10px">{{'CHECKLIST.TEMPLATE' | translate}}</h2>
    <div class="justify-content-end" *ngIf="isAdmin$ || isSuper$">
      <button pButton (click)="addChecklist()" type="button" icon="pi pi-plus"
              class="p-button-rounded p-button-raised"></button>
    </div>
  </div>
  <ng-container *ngIf="isUser$; else adminBlock">
    <div class="col-12" style="padding-right: 0; padding-left: 0;">
      <p-table
        #ct
        [globalFilterFields]="['name']"
        [value]="checklistsWithTemplates"
        [rowHover]="true"
        [loading]="checklistLoading"
        breakpoint="200px">
        <ng-template pTemplate="caption">
          <div class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="ct.filterGlobal($event.target.value, 'contains')"
                   placeholder="{{'COMMON.SEARCH' | translate}}"/>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 80%">{{'COMMON.NAME' | translate}}</th>
            <th class="flex justify-content-center">{{'BUTTON.ACTIVATE_TEMPLATE' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-checklist>
          <tr>
            <td (click)="editTemplate(checklist)">{{checklist.name}}</td>
            <td>
              <div class="flex justify-content-center" (click)="activateChecklistCopy(checklist)">
                <i class="pi pi-plus"></i>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">{{'CHECKLIST.NO_CHECKLISTS' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
  <ng-template #adminBlock>
    <div class="col-12" style="padding-right: 0; padding-left: 0;">
      <p-table
        #ct
        [globalFilterFields]="['name']"
        [value]="checklistsWithTemplates"
        [rowHover]="true"
        [loading]="checklistLoading"
        breakpoint="200px">
        <ng-template pTemplate="caption">
          <div class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="ct.filterGlobal($event.target.value, 'contains')"
                   placeholder="{{'COMMON.SEARCH' | translate}}"/>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 50%">{{'COMMON.NAME' | translate}}</th>
            <th>
              <div class="flex justify-content-center">
                {{'CHECKLIST.VISIBLE_USER' | translate}}
              </div>
            </th>
            <th>
              <div class="flex justify-content-center">
                {{'BUTTON.ACTIVATE_TEMPLATE' | translate}}
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-checklist>
          <tr>
            <td (click)="editTemplate(checklist)">{{checklist.name}}</td>
            <td>
              <div class="flex justify-content-center">
                {{getVisible(checklist)}}
              </div>
            </td>
            <td>
              <div class="flex justify-content-center" (click)="activateChecklistCopy(checklist)">
                <i class="pi pi-plus"></i>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">{{'CHECKLIST.NO_CHECKLISTS' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</div>
