import { Component, OnInit, Input} from '@angular/core';
import {ShiftReport} from '../../../model/shiftReport';
import {combineLatest, Subscription} from 'rxjs';
import {AppointmentService} from '../../../services/service/appointment.service';
import {PersonService} from '../../../services/service/person.service';
import {SubjectService} from '../../../services/service/subject.service';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {FilterService} from 'primeng/api';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../../../services/service/auth.service';
import * as moment from 'moment';
import {TenantService} from '../../../services/service/tenant.service';
import {RolesService} from '../../../services/service/roles.service';
import {take} from 'rxjs/operators';
import {ShiftReportService} from '../../../services/service/shift-report.service';

@Component({
  selector: 'app-report-add',
  templateUrl: './report-add.component.html',
  styleUrls: ['./report-add.component.scss']
})
export class ReportAddComponent implements OnInit {
  subscription = new Subscription();

  windowWidth: number;
  shiftReport: ShiftReport;

  edit: boolean;
  outputPerson = [];
  personMap = new Map();

  subjectMap: Map<string, any[]>;
  personNameMap;
  teamNameMap;
  stationNameMap;
  personParentNameMap;

  groupedSubjectFilter: any[];
  groupedAssetsFilter: any[];

  selectedSubjects: any[] = [];
  selectedSubjectsMap = new Map<string, {name: string, role: string}>();
  selectedPerson = "";
  checked = true;

  teamStationMap = new Map<string, string>();
  roleTypes: any[];

  loading = true;
  constructor(
      private config: DynamicDialogConfig,
      private appointmentService: AppointmentService,
      private personService: PersonService,
      private subjectService: SubjectService,
      private gsService: GroupedSubjectsService,
      private filterService: FilterService,
      private tenantService: TenantService,
      private roleService: RolesService,
      private ref: DynamicDialogRef,
      private authService: AuthService,
      private shService: ShiftReportService,
  ) {
  }

  ngOnInit(): void {

    this.subscription.add(combineLatest([
        this.personService.getPersons(), this.subjectService.getTeams(), this.subjectService.getStations(),
      this.subjectService.getRelations(), this.tenantService.getTenantId(), this.roleService.getRoleTypes(),
    ]).subscribe(([persons, teams, stations, relations, tenantId, roleTypes]) => {
      const subjectData = this.gsService.build(persons, teams, relations);
      this.personNameMap = subjectData[0];
      this.teamNameMap = subjectData[1];
      this.personParentNameMap = subjectData[2];
      this.groupedSubjectFilter = <any[]> subjectData[3];
      this.windowWidth = window.innerWidth;
      const temp = [];
      relations.forEach(relation => {
        if (relation.parentId === tenantId){
          temp.push(relation.childId);
        }
      });
      relations.forEach(relation => {
        if (temp.includes(relation.parentId)){
          this.teamStationMap.set(relation.childId, relation.parentId);
        }
      });
      this.stationNameMap = new Map(stations.map(station => [station.key, station.payload.val().name]));
      this.roleTypes = roleTypes.map(roleType => {
        return {key: roleType.key, ...roleType.payload.val()};
      });

      this.subjectMap = new Map(persons.map(person => [person.key, person.payload.val()]));

      if (this.config.data.edit){
        this.shiftReport = this.config.data.shiftReport;
        this.edit = this.config.data.edit;
        Object.entries(this.shiftReport.subjects).map(subject => {
          this.addPerson({label: subject[1]['name'], value: subject[0]}, subject[1]['role']);
        });
        this.setLeader(this.shiftReport.leader);
      } else {
        this.shiftReport = {
          daily: true,
          createdBy: "",
          date: moment().format("DD.MM.YYYY"),
          dateTo: moment().format("DD.MM.YYYY"),
          description: "",
          leader: "",
          station: "",
          subjects: [],
          team: "",
          timeEnd: "",
          timeStart: ""
        };
      }

      this.loading = false;

    }));
  }

  /**
   * Add team
   * @param team Team object
   */
  addTeam(team: any) {
    this.shiftReport.team = team.value;
    this.shiftReport.station = this.teamStationMap.get(team.value);
    team.items.forEach(participant => this.addPerson(participant));
  }

  /**
   * Add Person
   * @param subject Subject to add
   * @param role Role
   */
  addPerson(subject, role?: string){
    if (!this.personMap.has(subject)) {
      this.personMap.set(subject.value, true);
      this.selectedSubjects.push(subject.value);
      if (role){
        this.selectedSubjectsMap.set(subject.value, {name: this.personNameMap.get(subject.value), role: role});
      } else {
        this.selectedSubjectsMap.set(subject.value, {name: this.personNameMap.get(subject.value), role: ""});
      }
    }
  }

  /**
   * Remove person
   * @param personId
   */
  removePerson(personId: any) {
    this.personMap.delete(personId);
    this.selectedSubjectsMap.delete(personId);
    const index = this.selectedSubjects.findIndex(pId => pId === personId);
    if(index >= 0) {
      this.selectedSubjects.splice(index, 1);
    }
  }

  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Validate required shift report data.
   * @param shiftReport Shift report object
   */
  validate(shiftReport: ShiftReport) {
    return shiftReport.date === "" || shiftReport.dateTo === "" ||shiftReport.station === "" || shiftReport.team === ""
        || shiftReport.timeStart === "" || shiftReport.timeEnd === "";
  }

  /**
   * Set shift leader
   * @param personId Subject fbid.
   */
  setLeader(personId: any) {
    this.shiftReport.leader = personId;
  }

  /**
   * Get team name.
   * @param teamId Team fbid.
   */
  getTeam(teamId: string) {
    if (teamId) {
      return this.teamNameMap.get(teamId);
    } else {
      return "";
    }
  }

  /**
   * Get station name.
   * @param stationId Station fbid.
   */
  getStation(stationId: string) {
    if (stationId){
      return this.stationNameMap.get(stationId);
    } else {
      return "";
    }

  }

  /**
   * Save shift report to db.
   */
  save() {
    if (this.shiftReport.key$){
      if (this.shiftReport.date.toString().length > 10){
        this.shiftReport.date = moment(this.shiftReport.date).format("DD.MM.YYYY");
      }
      if (this.shiftReport.dateTo.toString().length > 10){
        this.shiftReport.dateTo = moment(this.shiftReport.dateTo).format("DD.MM.YYYY");
      }
      const date = this.shiftReport.date.replace(".", "").replace(".", "");

      const shKey = this.shiftReport.key$;
      delete this.shiftReport.key$;
      delete this.shiftReport.subjects;
      this.shService.updateShiftReport(this.shiftReport.station, date, shKey, this.shiftReport);
      this.selectedSubjects.forEach(subjectId => {
        this.shService.addSubjectsToShiftReport(
          this.shiftReport.station, date, shKey, subjectId, this.selectedSubjectsMap.get(subjectId)
        );
      });

    } else {
      this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
        this.shiftReport.createdBy = uid;
        if (this.shiftReport.date.toString().length > 10){
          this.shiftReport.date = moment(this.shiftReport.date).format("DD.MM.YYYY");
        }
        if (this.shiftReport.dateTo.toString().length > 10){
          this.shiftReport.dateTo = moment(this.shiftReport.dateTo).format("DD.MM.YYYY");
        }
        const date = this.shiftReport.date.replace(".", "").replace(".", "");
        this.shService.addShiftReportGetKey(this.shiftReport.station, date, this.shiftReport).pipe(take(1)).subscribe(shKey => {
             this.selectedSubjects.forEach(subjectId => {
               this.shService.addSubjectsToShiftReport(
                   this.shiftReport.station, date, shKey, subjectId, this.selectedSubjectsMap.get(subjectId)
               );
             });
        });
      });
    }
    this.close();
  }

  /**
   * Close
   */
  close() {
    this.ref.close();
  }
}
