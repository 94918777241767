<div class="grid">
    <div class="col-6 mb-4">
        <h2 style="margin-top: 10px">{{'CHECKLIST.ONGOING' | translate}}</h2>
    </div>
    <div class="col-6 mb-4 flex justify-content-end">
        <button type="button" pButton class="p-button-raised" icon="pi pi-bars" label="{{'COMMON.MENU' |
            translate}}" (click)="menu.toggle($event)"></button>
        <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
    </div>
    <div class="col-12" *ngIf="!loading">
        <full-calendar [options]="calendarOptions"></full-calendar>
    </div>
</div>
