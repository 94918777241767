import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Exercise} from "../../../model/exercise";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {ExerciseService} from "../../../services/service/exercise.service";
import {CalendarEvent} from '../../../model/calendarEvent';
import {CalendarService} from '../../../services/service/calendar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-exercise-detail',
  templateUrl: './edit-exercise.component.html',
  styleUrls: ['./edit-exercise.component.scss']
})
export class EditExerciseComponent implements OnInit {

  dateFrom = this.convertDateToString(new Date());
  timeFrom = '00:00';
  dateTo = this.convertDateToString(new Date());
  timeTo = '00:00';

  completed = false;
  key;

  exercise: Exercise = {
    originId: '',
    completed: false,
    template: false,
    created: "",
    createdById: "",
    evaluation: {
      goal: "",
      evaluation: "",
      learningPoints: "",
      other: "",
    },
    date: "",
    dateEnd: "",
    name: "",
  };
  readOnly = true;

  constructor(
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private messageService: MessageService,
      private translateService: TranslateService,
      private authService: AuthService,
      private exerciseService: ExerciseService,
      private calendarService: CalendarService,
  ) {
    if(config.data.key){
      this.key = config.data.key;
    }
    if(config.data.exercise){
      this.exercise = JSON.parse(JSON.stringify(config.data.exercise));
      const from = new Date(this.exercise.date);
      const to = new Date(this.exercise.dateEnd);
      this.dateFrom = this.convertDateToString(from);
      this.dateTo = this.convertDateToString(to);
      this.timeFrom = from.toLocaleTimeString();
      this.timeTo = to.toLocaleTimeString();
    }
    this.readOnly = config.data.readOnly;

    if (!this.exercise.evaluation){
      this.exercise.evaluation = {
        goal: "",
        evaluation: "",
        learningPoints: "",
        other: "",
      };
    }
  }

  ngOnInit(): void {}

  // Takes inn a new Date() object and returns YYYY-MM-DD
  convertDateToString(date){
    return date.getFullYear() + '-' + ('0'+(date.getMonth()+1)).slice(-2) + '-' + ('0'+ date.getDate()).slice(-2);
  }

  save() {
    this.completed = true;
    if(this.exerciseGuard()){
      this.authService.getUserUID()
          .pipe(take(1))
          .subscribe(uid => {
            this.exercise.date = moment(this.dateFrom).add(this.timeFrom).toISOString();
            this.exercise.dateEnd = moment(this.dateTo).add(this.timeTo).toISOString();
            this.exercise.createdById = uid;
            this.exercise.created = new Date().toISOString();

            if(this.exercise.date > this.exercise.dateEnd){
              this.messageService.add(
                  {
                    key: 'exercise-add', severity: 'error',
                    detail: this.translateService.instant('WARNING.FROM_TO_DATE_ERROR'), life: 4000
                  }
              );
            } else {
              const exerciseEvent: CalendarEvent = {
                completed: false,
                calendarType: 'EXERCISE',
                end: this.exercise.dateEnd,
                start: this.exercise.date,
                title: this.exercise.name
              };

              if(this.key){
                this.exerciseService.updateExercise(this.key, this.exercise);
                exerciseEvent.exerciseId = this.key;
                this.calendarService.updateCalendarEvent(exerciseEvent, this.key);
                // Return a TRUE to signal that we shall close and open detail to refresh header.
                this.ref.close(this.exercise.name);
              } else {
                this.exerciseService.addExerciseGetKey(this.exercise)
                    .pipe(take(1)).subscribe(exerciseKey => {
                      exerciseEvent.exerciseId = exerciseKey;
                      this.calendarService.addCalendarEvent(exerciseEvent, this.key);
                    });
                this.ref.close();
              }
            }
          });
    } else {
      this.messageService.add(
          {
            key: 'exercise-add', severity: 'error',
            detail: this.translateService.instant('ERROR.INVALID_VALUES'), life: 4000
          }
      );
    }
  }

  close() {
    this.ref.close();
  }
  exerciseGuard(){
    if(!this.exercise.name){
      return false;
    }
    if(!(this.dateTo && this.dateFrom && this.timeTo && this.timeFrom)){
      return false;
    }
    if(this.exercise.smsNotification){
      if(!this.exercise.smsDaysBefore){
        return false;
      }
    }
    if(this.exercise.emailNotification){
      if(!this.exercise.emailDaysBefore){
        return false;
      }
    }

    // IF all is OK
    return true;
  }

  validation(input) {
    if(this.completed){
      if(!input){
        return {'border-color': '#D0021B'};
      }
    }
  }
  validationSMSNotification(){
    if(this.completed){
      if(this.exercise.smsNotification){
        if(!this.exercise.smsDaysBefore) {
          return {'color': '#D0021B'};
        }
      }
    }
  }

  validationEmailNotification() {
    if(this.completed){
      if(this.exercise.emailNotification){
        if(!this.exercise.emailDaysBefore){
          return {'color': '#D0021B'};
        }
      }
    }
  }
}
