import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PersonService} from '../../../services/service/person.service';
import {PersonSubject} from '../../../model/subject';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {CompetencyService} from '../../../services/service/competency.service';
import {SdaService} from '../../../services/service/sda.service';
import {AttestationService} from '../../../services/service/attestation.service';
import {AuthService} from '../../../services/service/auth.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import {take} from 'rxjs/operators';
import {SubjectService} from '../../../services/service/subject.service';
import {PersonSetStationTeamComponent} from '../person-set-station-team/person-set-station-team.component';

@Component({
    selector: 'app-person',
    templateUrl: './person.component.html',
    styleUrls: ['./person.component.scss'],
    providers: [DialogService, ConfirmationService]
})
export class PersonComponent implements OnInit, OnDestroy {

    subscription = new Subscription();
    archivedPersons: PersonSubject[];
    allArchivedPersons: PersonSubject[];

    loading = true;
    search: string;
    constructor(
        private personService: PersonService,
        private translateService: TranslateService,
        private messageService: MessageService,
        private dialogService: DialogService,
        private competencyService: CompetencyService,
        private sdaService: SdaService,
        private attestationService: AttestationService,
        private confirmationService: ConfirmationService,
        private authService: AuthService,
        private acService: AccessControlService,
        private subjectService: SubjectService,
    ) { }

    ngOnInit(): void {
        this.subscription.add(this.personService.getArchivedPersons().subscribe(
            persons => {
                this.allArchivedPersons = persons.map(person => {
                    return {key: person.key, ...person.payload.val()};
                }).sort((a, b) =>{
                    return a.name.localeCompare(b.name);
                });
                this.archivedPersons = this.allArchivedPersons;
                this.loading = false;
            })
        );
    }

    /**
     * Check if person has cell if not return "-"
     * @param cell String
     */
    getCell(cell: string) {
        if (cell){
            return cell;
        } else {
            return "-";
        }
    }

    /**
     * Restore person
     */
    restore(person) {
        this.dialogService.open(PersonSetStationTeamComponent,{
            header: this.translateService.instant('COMMON.CHOOSE_STATION_TEAM'),
            width: "400px",
        }).onClose.subscribe(teamId => {
            if (teamId) {
                this.acService.updateTenantUserAdminOperationStatus(person.key, 'active');
                person.archive = false;
                const key = person.key;
                delete person.key;
                this.subjectService.updateSubject(key, person);
                const relation = {
                    childId: person.key,
                    parentId: teamId,

                };
                this.subjectService.addRelation(relation);
                this.authService.getUserUID()
                    .pipe(take(1),).subscribe(uid => {
                    this.sdaService.getPersonActiveSDA(person.key)
                        .pipe(take(1)).subscribe(SDAs => {
                        if (SDAs.length > 0){
                            const temp = SDAs.map(SDA => {
                                return {key: SDA.key, ...SDA.payload.val()};
                            });

                            temp.forEach(SDA => {
                                const newSDA = JSON.parse(JSON.stringify(SDA));
                                newSDA.dateEnd = new Date().toISOString();
                                newSDA.status = false;
                                newSDA.activePersonId = false;
                                newSDA.closedBy = uid;
                                delete newSDA.key;

                                this.sdaService.updateSDA(SDA.key, newSDA);
                            });
                        }
                    });
                });
            }
        });
    }

    /**
     * Confirm activate person.
     */
    confirm(person) {
        this.confirmationService.confirm({
            message: this.translateService.instant('ADMIN.USER_OVERVIEW_COMPONENT.ALERTS.REACTIVATE') + " " + person.name + "?",
            acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
            rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
            accept: () => {
                this.restore(person);
            }
        });
    }

    /**
     * Unsubscribe on destroy.
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
