<p-toast position="top-right" styleClass="customToast"></p-toast>

<ng-container *ngIf="from === 'main'; else otherBlock">
  <div class="grid">
    <div class="col-12">
      <div class="field">
        <label for="name">{{'COMMON.NAME' | translate}}*</label>
        <input id="name" aria-describedby="name-help" type="text" class="inputfield w-full" pInputText [(ngModel)]="deviation.name">
        <small id="name-help" class="block">{{'DEVIATION.HELPER' | translate}}</small>
      </div>
      <div class="field">
        <label for="description">{{'COMMON.DESCRIPTION' | translate}}*</label>
        <textarea [style]="{maxHeight: '140px'}" id="description" rows="6" cols="30"
                  [(ngModel)]="deviation.description" class="inputfield w-full" pInputTextarea autoResize="autoResize"></textarea>
      </div>

      <div class="field">
        <label for="assetSelect">{{'ASSET.ASSET' | translate}}</label>
        <select name="asset" id="assetSelect" class="w-full inputfield" [(ngModel)]="deviation.asset" style="appearance: auto" pInputText>
          <option *ngFor="let asset of assets" [value]="asset.key">{{asset.name}}</option>
        </select>
      </div>

      <div class="field-checkbox">
        <div class="col-12">
          <p-checkbox name="inCommission" [disabled]="disableCheckbox" [binary]="true"
                      [ngModel]="!inCommission" (onChange)="changeStatus()"></p-checkbox>
          &nbsp;{{'DEVIATION.OUT_COMMISSION' | translate}}
        </div>
      </div>

      <div class="field flex justify-content-end">
        <p-button type="button" icon="pi pi-bookmark" label="{{'BUTTON.SAVE' | translate}}" (click)="save()"></p-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #otherBlock>
  <div class="grid">
    <div class="col-12">
      <div class="field">
        <label for="nameOther">{{'COMMON.NAME' | translate}}</label>
        <input id="nameOther" aria-describedby="nameOther-help" type="text" class="inputfield w-full" pInputText
               [(ngModel)]="deviation.name">
        <small id="nameOther-help" class="block">{{'DEVIATION.HELPER' | translate}}</small>
      </div>
      <div class="field">
        <label for="descriptionOther">{{'COMMON.DESCRIPTION' | translate}}</label>
        <textarea [style]="{maxHeight: '140px'}" id="descriptionOther" rows="6" cols="30"
                  [(ngModel)]="deviation.description" class="inputfield w-full" pInputTextarea autoResize="autoResize"></textarea>
      </div>

      <div class="field">
        <label for="assetSelectOther">{{'ASSET.ASSET' | translate}}</label>
        <select [disabled]="true" name="asset" id="assetSelectOther" class="w-full inputfield" [(ngModel)]="deviation.asset" style="appearance: auto" pInputText>
          <option *ngFor="let asset of assets" [value]="asset.key">{{asset.name}}</option>
        </select>
      </div>

      <div class="field-checkbox">
        <div class="col-12">
          <p-checkbox name="inCommission" [disabled]="disableCheckbox" [binary]="true"
                      [ngModel]="!inCommission" (onChange)="changeStatus()"></p-checkbox>
          &nbsp;{{'DEVIATION.OUT_COMMISSION' | translate}}
        </div>
      </div>

      <div class="field flex justify-content-end">
        <p-button type="button" icon="pi pi-bookmark" label="{{'BUTTON.SAVE' | translate}}" (click)="save()"></p-button>
      </div>
    </div>
  </div>
</ng-template>
