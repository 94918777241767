import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ChecklistService} from "../../../services/service/checklist.service";
import {take} from "rxjs/operators";
import {combineLatest, Observable, Subscription} from "rxjs";
import {PersonService} from "../../../services/service/person.service";
import {ConfirmationService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {DeviationService} from "../../../services/service/deviation.service";
import {AccessControlService} from "../../../services/service/access-control.service";

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
  providers: [ConfirmationService]
})
export class CommentModalComponent implements OnInit, OnDestroy {
  comments: Observable<any[]>;
  commentText: string;
  id;
  mode;

  isObserver;
  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private checklistService: ChecklistService,
    private personService: PersonService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private deviationService: DeviationService,
    private acService: AccessControlService,
  ) {
    this.id = this.config.data.id;
    this.mode = this.config.data.mode;

    if(this.mode === 'checklist'){
      this.comments = this.checklistService.getComments(this.id);
    } else if(this.mode === 'deviation'){
      this.comments = this.deviationService.getComments(this.id);
    }

    this.subscription.add(
      this.acService.isObserver().subscribe(isObserver => {
        this.isObserver = isObserver;
      })
    );

  }

  ngOnInit(): void {
    this.commentText = '';
  }

  save(commentText: string) {
    if (commentText.length > 0) {
      const comment = {};
      comment['commentText'] = commentText;

      combineLatest([this.comments, this.personService.getOwnPerson()]).pipe(
        take(1),
      ).subscribe(([comments, person]) => {
        comment['time'] = new Date().toISOString();
        comment['commenter'] = {
          key: person.key,
          name: person.payload.val().name
        };
        comments.push(comment);
        this.commentText = '';
        this.update(comments);
      });
    }
  }

  close() {
    this.ref.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Update comments depending on what mode.
   *
   * @param comments
   * @private
   */
  private update(comments: any[]) {
    if(this.mode === 'checklist'){
      this.checklistService.setComments(this.id, comments);
    } else if(this.mode === 'deviation'){
      this.deviationService.setComments(this.id, comments);
    }
  }
}
