import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {SubjectService} from '../../../services/service/subject.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
    selector: 'app-person-set-station-team',
    templateUrl: './person-set-station-team.component.html',
    styleUrls: ['./person-set-station-team.component.scss']
})
export class PersonSetStationTeamComponent implements OnInit, OnDestroy {

    subscription = new Subscription();

    stations: any[];
    teams: any[];

    childParentMap = new Map<string, string>();
    stationTeamMap = new Map<string, any[]>();

    selectedStation: string;
    selectedTeam: string;

    constructor(
        private subjectService: SubjectService,
        private ref: DynamicDialogRef,

    ) { }

    ngOnInit(): void {
        this.subscription.add(combineLatest(
            [this.subjectService.getStations(), this.subjectService.getTeams(), this.subjectService.getRelations()])
            .subscribe(([stations, teams, relations]) => {

                relations.forEach(relation => {
                    this.childParentMap.set(relation.childId, relation.parentId);
                });

                this.stations = stations.map(station => {
                    return {key: station.key, name: station.payload.val().name};
                });

                teams.forEach(team => {
                    if (this.stationTeamMap.has(this.childParentMap.get(team.key))){
                        this.stationTeamMap.get(this.childParentMap.get(team.key)).push({key: team.key, name: team.payload.val().name});
                    } else {
                        this.stationTeamMap.set(this.childParentMap.get(team.key),[{key: team.key, name: team.payload.val().name}]);
                    }

                });
                this.teams = this.stationTeamMap.get(this.stations[0].key);
            })
        );
    }

    /**
     * Get teams from selected station.
     * @param selectedStation Station fbid
     */
    getTeams(selectedStation: string) {
        this.teams = this.stationTeamMap.get(selectedStation);
    }

    /**
     * Abort.
     */
    close() {
        this.ref.close();
    }

    /**
     * Check for values
     */
    validator() {
        return !(this.selectedStation || this.selectedTeam);
    }
    /**
     * Return values.
     */
    complete() {
        this.ref.close(this.selectedTeam);
    }

    /**
     * Unsubscribe on close.
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
