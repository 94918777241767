import { Component, OnInit } from '@angular/core';
import {AccessControlService} from "../../../services/service/access-control.service";
import {PersonService} from "../../../services/service/person.service";
import {DialogService} from "primeng/dynamicdialog";
import {AddRoleDialogComponent} from "../add-role-dialog/add-role-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {take} from "rxjs/operators";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.scss'],
  providers: [DialogService]
})
export class NewLoginComponent implements OnInit {
  persons;

  constructor(
    private acService: AccessControlService,
    private personService: PersonService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {
    this.personService.getPersonsWithoutAccess().subscribe(persons => {
      this.persons = persons.map(person => {
        return {key: person.key, ...person.payload.val()}
      })
    })
  }

  ngOnInit(): void {
  }


  add(person) {
    if(person.email){
      const role  = this.dialogService.open(AddRoleDialogComponent, {
        header: this.translateService.instant('ADMIN.NEW_EXISTING_USER_COMPONENT.USER_ACCESS') + ': ' + person.name,
        data: {
          role: null
        },
      })
      role.onClose.pipe(
        take(1),
      ).subscribe(roleObject => {
        if(roleObject){
          this.acService.createUser(person, roleObject)
        }
      })
    } else {
      this.messageService.add(
        {key: 'info', severity: 'info',
          detail: this.translateService.instant('ADMIN.NEW_EXISTING_USER_COMPONENT.ALERTS.EMAIL_NOT_FOUND'), life: 4000}
      )
    }
  }
}
