import {Injectable} from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import { map, mergeMap, publish, take} from "rxjs/operators";
import {Station} from "../../model/station";
import {TreeNode} from "primeng/api";
import {PersonSubject} from "../../model/subject";

@Injectable({
    providedIn: 'root'
})
/**
 * Handles Subjects
 */
export class SubjectService {
    tenantId$: Observable<any>;

    constructor(
        private tenantService: TenantService,
        private db: AngularFireDatabase,
    ) {
        this.tenantId$ = this.tenantService.getTenantId();
    }

    /**
     * @return SnapshotChanges of stations so we can access $key
     */
    getStations(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/subjects`, ref => {
                    return ref.orderByChild('type').equalTo('FIRESTATION');
                }).snapshotChanges()
            ));
    }

    getRelations(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/subjectRelations`).valueChanges()
            )
        );
    }

    getStation(stationId: any): Observable<Station> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.object(`tenants/${tenantId}/subjects/${stationId}`).valueChanges()
            )) as Observable<Station>;
    }

    getSubjects(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/subjects/`).snapshotChanges()
            ));
    }

    /**
     * Returns the children as TreeMap list
     * @param key
     */
    getChildren(key): Observable<any> {
        return combineLatest([this.getRelations(), this.getSubjects()]).pipe(
            map(([relations, subjects]) => {
                const relationMap = new Map;
                relations.forEach(rel => {
                    if (rel.parentId === key) {
                        relationMap.set(rel.childId, true);
                    }
                });
                const result: TreeNode[] = [];
                subjects.forEach(subject => {
                    if (relationMap.has(subject.key)) {
                        const payload = subject.payload.val();
                        if (payload.type === 'FIRETEAM') {
                            result.push({
                                label: subject.payload.val().name,
                                key: subject.key,
                                leaf: false,
                                data: "FIRETEAM",
                                icon: 'far fa-users',
                            });
                        } else if (payload.type === 'PERSON') {
                            result.push({
                                label: subject.payload.val().name,
                                key: subject.key,
                                data: "PERSON",
                                leaf: true,
                                icon: 'far fa-user',
                                droppable: false,
                            });
                        } else if (payload.type === "FIRESTATION") {
                            result.push({
                                label: subject.payload.val().name,
                                key: subject.key,
                                data: "FIRESTATION",
                                icon: 'far fa-building',
                                draggable: false,
                            });
                        }
                    }
                });
                return result;
            })
        );
    }

    /**
     * @return SnapshotChanges of teams so we can access $key
     */
    getTeams(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => {
                    return this.db.list(`tenants/${tenantId}/subjects`, ref => {
                        return ref.orderByChild('type').equalTo('FIRETEAM');
                    }).snapshotChanges();
                }
            ));
    }

    /**
     * @return key of added subject, IMPORTANT! : You have to subscribe or publish()().connect() this to push to firebase.
     * @param subject
     */
    addSubject(subject) {
        return this.tenantId$.pipe(
            map(
                tenantId => {
                    const ref = this.db.list(`tenants/${tenantId}/subjects`).push(subject);
                    return ref.key;
                }
            )
        );
    }

    addRelation(relation: { childId: string; parentId: any }) {
        return publish()(this.tenantId$.pipe(
            map(tenantId => {
                return this.db.list(`tenants/${tenantId}/subjectRelations`).push(relation);
            })
        )).connect();
    }

    deleteSubject(subjectId: any) {
        return publish()(this.tenantId$.pipe(
            map(tenantId => {
                return this.db.list(`tenants/${tenantId}/subjects`).remove(subjectId);
            })
        )).connect();
    }

    deleteRelationByChild(subjectId) {

        return publish()(this.tenantId$.pipe(
            map(tenantId => {
                const relation = this.db.list(`tenants/${tenantId}/subjectRelations`, ref => {
                    return ref.orderByChild('childId').equalTo(subjectId);
                });

                return publish()(relation.snapshotChanges().pipe(
                        map(relationSnap => {
                            relationSnap.forEach(rel => {
                                return this.db.list(`tenants/${tenantId}/subjectRelations`).remove(rel.key);
                            });
                        })
                    )
                ).connect();
            })
        )).connect();
    }

    getAncestor(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => {
                    return this.db.list(`tenants/${tenantId}/subjects`).snapshotChanges();
                }
            ));
    }

    getJobTitles() {
        return this.tenantService.getDefaultLang().pipe(
            map(defLang => {
                switch (defLang) {
                    case 'nb':
                        return this.jobTitlesNB();
                    case 'is':
                        return this.jobTitlesIS();
                    default:
                        return this.jobTitlesNB();
                }
            })
        );
    }

    jobTitlesNB() {
        return [
            {title: '-'},
            {title: 'MANNSKAP'},
            {title: 'UTTRYKNINGSLEDER'},
            {title: 'BRANNSJEF'},
            {title: 'VARABRANNSJEF'},
            {title: 'BEREDSKAPSSJEF'},
            {title: 'NESTLEDER BEREDSKAP'},
            {title: 'OVERBRANNMESTER'},
            {title: 'BRANNKONSTABEL'},
            {title: 'FEIER'},
            {title: 'KHMS-INGENIØR/RÅDGIVER'},
            {title: 'BRANNINGENIØR'},
            {title: 'LEDER FOREBYGGENDE'},
            {title: 'FEIERMESTER'},
            {title: 'OVERBEFAL'},
            {title: 'BRANNMESTER'},
            {title: 'UNDERBRANNMESTER'},
            {title: 'DELTID'},
            {title: 'VIKAR'},
            {title: 'BRANNINSPEKTØR'}
        ];
    }

    jobTitlesIS() {
        return [
            {title: '-'},
            {title: 'Slökkviliðsstjóri'},
            {title: 'Varaslökkviliðsstjóri'},
            {title: 'Deildarstjóri'},
            {title: 'Varðstjóri'},
            {title: 'Aðstoðarvarðstjóri'},
            {title: 'Slökkviliðsmaður 1'},
            {title: 'Slökkviliðsmaður 2'},
            {title: 'Slökkviliðsmaður 3'},
            {title: 'Nýliði'},
        ];
    }

    getPerson(subjectId: any): Observable<PersonSubject> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => {
                    return this.db.object(`tenants/${tenantId}/subjects/${subjectId}`).valueChanges();
                }
            )
        ) as Observable<PersonSubject>;
    }

    updateSubject(subjectId, subject) {
        return publish()(this.tenantId$.pipe(
            map(tenantId => {
                return this.db.list(`tenants/${tenantId}/subjects`).update(subjectId, subject);
            })
        )).connect();
    }

    updateRelation(key, relation: { childId: string; parentId: any }) {
        return publish()(this.tenantId$.pipe(
            map(tenantId => {
                return this.db.list(`tenants/${tenantId}/subjectRelations`).update(key, relation);
            })
        )).connect();
    }

    getRelationByChildId(subjectId): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/subjectRelations`, ref => {
                    return ref.orderByChild('childId').equalTo(subjectId);
                }).snapshotChanges()
            )
        );
    }

    getChildrenOfAncestor() {
        return this.getAncestor().pipe(
            mergeMap(
                subjects => {
                    const subjectsVal = subjects.map(s => {
                        return {key: s.key, ...s.payload.val()};
                    });
                    let ancestor;
                    subjectsVal.forEach(subject => {
                        if (subject.type === "REGIONAL" || subject.type === "FIREDEPARTMENT"){
                            ancestor = subject;
                        }
                    });
                    return this.getChildren(ancestor.key);
                }
            )
        );
    }

    removeRelationWithChildId(subjectId) {
        this.getRelationByChildId(subjectId).pipe(
            take(1),
        ).subscribe(relation => {
            if (relation[0].key) {
                publish()(this.tenantId$.pipe(
                    mergeMap(
                        tenantId => this.db.object(`tenants/${tenantId}/subjectRelations/${relation[0].key}`).remove()
                    )
                )).connect();
            }
        });

    }

    getSubjectsChildren(subjectId){
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/subjectRelations`, ref => {
                    return ref.orderByChild('parentId').equalTo(subjectId);
                }).snapshotChanges()
            )
        );
    }

    getParent(subjectId): Observable<any> {
        return this.getRelationByChildId(subjectId).pipe(
            mergeMap(relation => {
                const parent = relation[0].payload.val().parentId;
                if (parent) {
                    return this.tenantId$.pipe(
                        mergeMap(
                            tenantId => this.db.object(`tenants/${tenantId}/subjects/${parent}`).snapshotChanges()
                        )
                    );
                }
            })
        );
    }

    getTeamRelationsMap() {
        return combineLatest([this.getTeams(), this.getRelations()])
            .pipe(
                map(([teams, relations]) => {
                    const relationMap = new Map<string, any[]>(teams.map(team => [team.key, []]));

                    relations.forEach(relation => {
                        if(relationMap.has(relation.parentId)){
                            relationMap.get(relation.parentId).push(relation.childId);
                        }
                    });

                    return relationMap;
                })
            );
    }
}
