import { Pipe, PipeTransform } from '@angular/core';
import {Roles} from '../model/role';

@Pipe({
  name: 'rolesPersonFilter'
})
export class RolesPersonFilterPipe implements PipeTransform {

  /**
   * Filter Role table on name
   * @param tableRoles To be filtered
   * @param personNameMap Name map {key: personId, value: person name}
   * @param search Search string
   */
  transform(tableRoles: Roles[], personNameMap: Map<string, string>, search?: string): any {
    if(!tableRoles || !search){
      return tableRoles;
    } else {
      return tableRoles.filter(role => personNameMap.get(role.personKey).toLowerCase().includes(search.toLowerCase()));
    }
  }
}
