<!-- Toast -->
<p-toast key='info' position="top-right" styleClass="customToast"></p-toast>

<div class="grid">
  <div class="col-12" style="padding-right: 0; padding-left: 0">
    <p-table #dt1 [value]="persons" [rowHover]="true" [globalFilterFields]="['name']" responsiveLayout="stack" breakpoint="200px">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                   placeholder="{{'COMMON.SEARCH' | translate}}"/>
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">{{'COMMON.NAME' | translate}}<p-sortIcon field="name"></p-sortIcon></th>
          <th>{{'PERSONAL.EMAIL' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-person>
        <tr (click)="add(person)">
          <td>{{person.name}}</td>
          <td>{{person.email}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'SUBJECTS.NO_PERSON_FOUND' | translate}}.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
