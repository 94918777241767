import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AssetService} from '../../../services/service/asset.service';
import {SubjectService} from '../../../services/service/subject.service';
import {Asset} from '../../../model/asset';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';
import {TenantService} from '../../../services/service/tenant.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TaskService} from '../../../services/service/task.service';
import {DeviationService} from '../../../services/service/deviation.service';
import {ConfirmationService} from 'primeng/api';
import {Task} from '../../../model/task';
import {Deviation} from '../../../model/deviation';
import * as moment from 'moment/moment';

@Component({
    selector: 'app-add',
    templateUrl: './add.component.html',
    styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit, OnDestroy {

    subscription = new Subscription();

    asset: string;
    assetObj: Asset;
    assetTypes: any[];
    assetForm: UntypedFormGroup;
    stations: any[];
    stateOptions: any[];
    archived: boolean;
    isIUA$: boolean;
    isFDC$: boolean;
    connections = false;
    taskConnections = 0;
    deviationConnections = 0;
    origin: string;

    assetTaskMap = new Map<string, any[]>();
    assetDeviationMap = new Map<string, any[]>();
    sent: Boolean = false;

    constructor(
        private translateService: TranslateService,
        private assetService: AssetService,
        private subjectService: SubjectService,
        private authService: AuthService,
        private tenantService: TenantService,
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private taskService: TaskService,
        private deviationService: DeviationService,
        private confirmationService: ConfirmationService,
    ) {
        this.subscription.add(combineLatest(
            [this.assetService.getAssetTypes(), this.subjectService.getStations(),
                this.tenantService.isIUA(), this.tenantService.isFDC(), this.taskService.getTasks(), this.deviationService.getDeviations()])
            .subscribe(([assetTypes, stations, isIUA, isFDC, tasks, deviations]) => {
                this.assetTypes = assetTypes.map(assetType => {
                    return {key: assetType.key, ...assetType.payload.val()};
                });
                this.stations = stations.map(station => {
                    return {key: station.key, ...station.payload.val()};
                });
                this.isIUA$ = isIUA;
                this.isFDC$ = isFDC;

                if (this.origin === "edit"){
                    tasks.forEach(task => {
                        if (task.payload.val().assetId === this.assetObj.key){
                            this.connections = true;
                            if (!task.payload.val().completed){
                                this.taskConnections += 1;
                                if (!this.assetTaskMap.has(this.assetObj.key)){
                                    this.assetTaskMap.set(this.assetObj.key, [{key: task.key, taskObj: task.payload.val()}]);
                                } else {
                                    this.assetTaskMap.get(this.assetObj.key).push({key: task.key, taskObj: task.payload.val()});
                                }
                            }
                        }
                    });
                    deviations.forEach(deviation => {
                        if (deviation.payload.val().asset === this.assetObj.key){
                            this.connections = true;
                            if (!deviation.payload.val().closed){
                                this.deviationConnections += 1;
                                if (!this.assetDeviationMap.has(this.assetObj.key)){
                                    this.assetDeviationMap.set(
                                        this.assetObj.key, [{key: deviation.key, deviationObj: deviation.payload.val()}]
                                    );
                                } else {
                                    this.assetDeviationMap.get(this.assetObj.key).push(
                                        {key: deviation.key, deviationObj: deviation.payload.val()}
                                    );
                                }
                            }
                        }
                    });
                }
            })
        );
    }

    ngOnInit(): void {
        this.stateOptions = [
            {label: this.translateService.instant('CONFIRMATION.NO'), value: false},
            {label: this.translateService.instant('CONFIRMATION.YES'), value: true}
        ];

        this.origin = this.config.data.origin;
        if (this.origin === "edit"){
            this.assetObj = this.config.data.assetObj;
            this.assetForm = new UntypedFormGroup({
                type: new UntypedFormControl(this.assetObj.type, Validators.required),
                name: new UntypedFormControl(this.assetObj.name, Validators.required),
                stationId: new UntypedFormControl(this.assetObj.stationId, Validators.required),
                inCommission: new UntypedFormControl(this.assetObj.inCommission, Validators.required),
            });
        } else {
            this.assetForm = new UntypedFormGroup({
                type: new UntypedFormControl('', Validators.required),
                name: new UntypedFormControl('', Validators.required),
                stationId: new UntypedFormControl('', Validators.required),
                inCommission: new UntypedFormControl(true, Validators.required),
            });
        }
    }

    /**
     * Confirm deletion of asset
     */
    // TODO fix
    confirm(assetObj: Asset, type: string) {
        let message;
        if (type === 'delete'){
            message = this.translateService.instant('WARNING.REMOVE') + " " + assetObj.name + "?";
        } else if(type === 'archive') {
            message = this.translateService.instant('WARNING.ARE_YOU_SURE_ARCHIVE') + " " + assetObj.name + "?\n"
                + this.translateService.instant('WARNING.ALL_OPEN_D&T_WILL_BE_CLOSED');
        }

        this.confirmationService.confirm({
            message: message,
            acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
            rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
            accept: () => {
                if (type === 'archive') {
                    this.archiveAsset(assetObj);
                }else {
                    this.deleteAsset(assetObj.key);
                }
            }
        });
    }

    /**
     * Delete asset
     * @param assetId Asset Fbid
     */
    deleteAsset(assetId: string) {
        this.assetService.deleteAsset(assetId);
        this.ref.close();
    }

    /**
     * Archive asset
     * @param assetObj
     */
    archiveAsset(assetObj: Asset) {
        this.ref.close();
        if (this.assetTaskMap.get(assetObj.key)){
            this.assetTaskMap.get(assetObj.key).forEach(
                task => {
                    this.completeTask(task.key, task.taskObj);
                });
        }
        if (this.assetDeviationMap.get(assetObj.key)){
            this.assetDeviationMap.get(assetObj.key).forEach(
                deviation => {
                    this.completeDeviation(deviation.key, deviation.deviationObj);
                });
        }
        assetObj.archived = true;
        this.assetService.updateAsset(this.assetObj.key, assetObj);
    }

    /**
     * Complete Task
     */
    completeTask(taskFBID: string, task: Task) {
        this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
            task.completed = moment().toISOString();
            task.completedById = uid;
            this.taskService.updateTask(task, taskFBID);

        });
    }

    /**
     * Complete Deviation
     */
    completeDeviation(deviationFBID: string, deviation: Deviation) {
        this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
            this.authService.getUserUID().pipe(take(1),).subscribe((userUID) => {
                deviation.closedById = userUID;
                deviation.closed = new Date().toISOString();
                this.deviationService.updateDeviation(deviationFBID, deviation);
            });
        });
    }

    /**
     * On submit save new asset or update existing asset.
     */
    onSubmit() {
        // TODO Why does it send double???
        if (this.sent === false) {

            this.sent = true;

            const ongoing = this.assetForm.value;
            this.authService.getUserUID().pipe(take(1))
                .subscribe(uid => {
                    let asset: Asset;
                    if (this.isIUA$ === undefined) {
                        this.isIUA$ = false;
                    }
                    if (this.isFDC$ === undefined) {
                        this.isFDC$ = false;
                    }
                    if (this.origin === 'add') {
                        asset = {
                            ConnIUA: this.isIUA$,
                            ConnFDC: this.isFDC$,
                            archived: false,
                            createdById: uid,
                            inCommission: ongoing.inCommission,
                            name: ongoing.name,
                            stationId: ongoing.stationId,
                            type: ongoing.type,
                        };
                    } else {
                        if (this.assetObj.archived === undefined) {
                            this.archived = false;
                        } else {
                            this.archived = this.assetObj.archived;
                        }
                        asset = {
                            ConnIUA: this.isIUA$,
                            ConnFDC: this.isFDC$,
                            archived: this.archived,
                            createdById: uid,
                            inCommission: ongoing.inCommission,
                            name: ongoing.name,
                            stationId: ongoing.stationId,
                            type: ongoing.type,
                        };
                    }
                    if (this.origin === "edit") {
                        this.assetService.updateAsset(this.assetObj.key, asset);
                    } else {

                        this.assetService.addAsset(asset);
                    }
                    this.ref.close();
                });
        }
    }

    /**
     * Unsubscribe all
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
