import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FilesMainComponent } from './files-main/files-main.component';
import {Route} from "@angular/router";
import {ButtonModule} from "primeng/button";
import {TranslateModule} from "@ngx-translate/core";
import {FileUploadModule} from "primeng/fileupload";
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {RippleModule} from 'primeng/ripple';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MultiSelectModule} from 'primeng/multiselect';
import {CheckboxModule} from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import { FileUploadReplaceComponent } from './file-upload-replace/file-upload-replace.component';
import {TableModule} from 'primeng/table';
import {TreeModule} from "primeng/tree";
import {DropdownModule} from 'primeng/dropdown';
import { FileViewComponent } from './file-view/file-view.component';
import {MenuModule} from 'primeng/menu';
import { FileTagsComponent } from './file-tags/file-tags.component';
import {ToastModule} from 'primeng/toast';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PipesModule} from '../../pipes/pipes.module';

export const FilesRoutes: Route = {
  path: 'files',
  children: [
    {path: 'files-main', component: FilesMainComponent}
  ]
};

@NgModule({
  declarations: [
    FileUploadComponent,
    FilesMainComponent,
    FileUploadReplaceComponent,
    FileViewComponent,
    FileTagsComponent
  ],
    imports: [
        CommonModule,
        ButtonModule,
        TranslateModule,
        FileUploadModule,
        InputTextModule,
        FormsModule,
        RippleModule,
        InputTextareaModule,
        MultiSelectModule,
        CheckboxModule,
        SelectButtonModule,
        TableModule,
        TreeModule,
        DropdownModule,
        MenuModule,
        ToastModule,
        AutoCompleteModule,
        ConfirmDialogModule,
        PipesModule,
    ]
})
export class FilesModule { }
