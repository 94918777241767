<div class="grid">

  <div class="col-12">
    <h2>{{'COMMON.COMPLETED' | translate}}</h2>
  </div>

  <div class="col-12 pl-0 pr-0" *ngIf="!loading">

    <!-- TABLE for completed Checklist-->
    <p-table
      [value]="completedChecklists | isoDateASC: 0 | checklistFilter: selectedStation"
      breakpoint="200px"
      [virtualScroll]="true"
      scrollHeight="75vh"
      [scrollable]="true"
      [virtualRowHeight]="40"
      [rows]="100"
    >
      <!-- CAPTION -->
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end">
          <div>
            <p-dropdown class="inputfield w-full"
                        [options]="stationOptions"
                        [(ngModel)]="selectedStation"
                        placeholder="{{'COMMON.CHOOSE_STATION' | translate}}"
                        optionLabel="name"
                        optionValue="key">
            </p-dropdown>
          </div>
        </div>
      </ng-template>

      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th>{{'COMMON.DATE.DATE' | translate}}</th>
          <th>{{'ASSET.ASSET' | translate}}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-checklist>
        <tr (click)="openDetail(checklist)">
          <td>{{checklist.name}}</td>
          <td>{{checklist.dateOfCompletion | date : 'dd.MM.yy'}}</td>
          <td>{{assetMap?.get(checklist.assetId)}}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td>{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
