import {Injectable} from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AttestationService} from "./attestation.service";
import {CompetencyTypeService} from "./competency-type.service";
import {Competency} from "../../model/competency";
import * as moment from "moment";
import {map} from "rxjs/operators";
import {Attestation} from "../../model/attestation";
@Injectable({
  providedIn: 'root'
})
export class CompetencyService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
    private attestationService: AttestationService,
    private competencyTypesService: CompetencyTypeService
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getCompetenciesWithKey(): Observable<Competency[]> {
    return combineLatest([this.attestationService.getAttestations(), this.competencyTypesService.getCompetencyTypes()]).pipe(
      map(([attestations, competencyTypes]) => {
        return this.findCompetencies(attestations, competencyTypes);
      })
    );
  }

  getCompetencies(): Observable<Competency[]> {
    return combineLatest([this.attestationService.getAttestations(), this.competencyTypesService.getCompetencyTypes()]).pipe(
      map(([attestations, competencyTypes]) => {
        return this.findCompetencies(attestations, competencyTypes);
      })
    );
  }

  getCompetenciesForPerson(subjectId): Observable<Competency[]> {
    return combineLatest([this.attestationService.getPersonAttestations(subjectId), this.competencyTypesService.getCompetencyTypes()]).pipe(
      map(([attestations, competencyTypes]) => {
        return this.findCompetencies(attestations, competencyTypes);
      })
    );
  }


  getPersonsCompetenciesMap(): Observable<any> {
    const pcMap = new Map();
    return this.getCompetencies().pipe(
      map(
        competencies => {

          competencies.forEach(c => {
            if (pcMap.has(c.personId)) {
              const array = pcMap.get(c.personId);
              array.push(c);
              pcMap.set(c.personId, array);
            } else {
              const array = [];
              array.push(c);
              pcMap.set(c.personId, array);
            }
          });
          return pcMap;
        })
    );
  }

  private findCompetencies(attestations: Attestation[], competencyTypes: any) {
    const now = moment();

    const group = new Map();

    attestations.forEach(attestation => {
      if (!group.has(attestation.personId)) {
        group.set(attestation.personId, new Map());
      }
      if (!group.get(attestation.personId).has(attestation.competencyTypeId)) {
        group.get(attestation.personId).set(attestation.competencyTypeId, []);
      }
      const array = group.get(attestation.personId).get(attestation.competencyTypeId);
      array.push(attestation);
      group.get(attestation.personId).set(attestation.competencyTypeId, array);
    });

    const competencies = new Array<Competency>();

    for (const [pId, ctAttsMap] of group) {
      for (const [ctId, atts] of ctAttsMap) {
        const validRanges = this.attestationService.findValidDateRanges(atts);
        const currentRange = this.attestationService.currentRange(validRanges);
        const competencyInvalidated = this.attestationService.competencyInvalidated(atts);
        const expiredSince = this.attestationService.lastExpiryDate(validRanges);

        const competency: Competency = {
          attestations: atts,
          competencyTypeId: ctId,
          expiring: false,
          personId: pId,
          valid: currentRange.currentlyInRange && !competencyInvalidated,
          validRanges: validRanges
        };
        if (currentRange.validFrom) {
          competency.validFrom = currentRange.validFrom;
        }
        if (currentRange.validTo) {
          competency.validTo = currentRange.validTo;
        }
        if (competency.validTo) {
          const competencyType = competencyTypes.find((e) => e.key === ctId).payload.val();
          const timeToExpire: number = competencyType.timeToExpire || 0;
          const unit: moment.unitOfTime.DurationConstructor = <moment.unitOfTime.DurationConstructor>competencyType.timeToExpireUnit || 'month';
          const expiringPeriodStart = moment(competency.validTo).subtract(timeToExpire, unit);

          competency.expiring = now.isSameOrAfter(expiringPeriodStart);
        }
        if (!competency.valid && expiredSince) {
          competency.expiredSince = expiredSince;
        }
        competencies.push(competency);
      }
    }
    return competencies;
  }
}
