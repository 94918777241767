import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendSmsComponent } from './send-sms/send-sms.component';
import {Route} from '@angular/router';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {FormsModule} from '@angular/forms';
import {TreeTableModule} from 'primeng/treetable';
import {TreeModule} from 'primeng/tree';
import {InputTextareaModule} from 'primeng/inputtextarea';

export const SmsRoutes: Route = {
  path: 'sms', component: SendSmsComponent
}

@NgModule({
  declarations: [
    SendSmsComponent
  ],
    imports: [
        CommonModule,
        TableModule,
        TranslateModule,
        ButtonModule,
        FormsModule,
        TreeTableModule,
        TreeModule,
        InputTextareaModule
    ]
})
export class SmsModule { }
