<!-- Block UI when someone deletes the deviation-->
<p-blockUI [blocked]="!deviation" [target]="container">
  <i class="pi pi-trash" style="font-size: 3rem; color: #D0021B"></i>
</p-blockUI>


<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-circle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<p-panel #container>
  <div class="grid">
    <div class="col-12">
      <p-fieldset legend="{{'COMMON.STATUS' | translate}}" [toggleable]="true">
        <div class="flex justify-content-around align-items-center">
          <!-- Show Station connected to Asset -->
          <span><i class="far fa-warehouse"></i>&nbsp;{{station?.name}}</span>
          <!-- Show Asset and Asset Status -->
          <span [ngStyle]="setStatus(asset)"><i class="far fa-truck"></i>&nbsp;{{asset?.name}}</span>
        </div>
      </p-fieldset>
    </div>


    <div class="col-12">
        <span class="p-buttonset">
          <p-splitButton
            *ngIf="!(isObserver || (isClosed$ | async))"
            [disabled]="isObserver || (isClosed$ | async)"
            class="float-right"
            pRipple
            icon="pi pi-bookmark"
            appendTo="body"
            (onClick)="save()"
            [model]="splitMenu"
            label="{{'BUTTON.SAVE' | translate}}"
          ></p-splitButton>

          <button
            type="button"
            pButton pRipple
            label="{{'COMMON.COMMENTS' | translate}}{{'('+((comments$ | async)?.length)+')'}}"
            icon="pi pi-comments"
            class="p-button-outlined float-right"
            (click)="openComments()"
          ></button>
        </span>
    </div>

    <div class="col-12">

      <!-- NAME -->
      <div class="field">
        <label for="name">{{'COMMON.NAME' | translate}}</label>
        <input type="text"
               [disabled]="(isClosed$ | async)"
               id="name"
               class="inputfield w-full"
               pInputText
               [(ngModel)]="deviation.name"/>
      </div>

      <!-- CLOSED BY -->
      <div *ngIf="(isClosed$ | async)" class="field">
        <label for="name">{{'COMMON.CLOSED_BY' | translate}}</label>
        <input type="text"
               [disabled]="(isClosed$ | async)"
               id="name"
               class="inputfield w-full"
               pInputText
               value='{{uidNameMap.get(deviation.closedById)}}'/>
      </div>

      <!-- CLOSED -->
      <div *ngIf="(isClosed$ | async)" class="field">
        <label for="name">{{'COMMON.CLOSED' | translate}}</label>
        <input type="text"
               [disabled]="(isClosed$ | async)"
               id="name"
               class="inputfield w-full"
               pInputText
               value='{{deviation.closed | amDateFormat: "DD.MM.YY"}}'/>
      </div>

      <!-- CREATED BY -->
      <div class="field">
        <label for="createdBy">{{'COMMON.CREATED_BY' | translate}}</label>
        <input type="text"
               [disabled]="true"
               id="createdBy"
               pInputText
               class="inputfield w-full"
               value='{{uidNameMap.get(deviation.createdById)}}'/>
      </div>

      <!-- CREATED -->
      <div class="field">
        <label for="created">{{'COMMON.CREATED' | translate}}</label>
        <input type="text"
               [disabled]="true"
               id="created"
               pInputText
               class="inputfield w-full"
               value='{{deviation.created | amDateFormat: "DD.MM.YY"}}'/>
      </div>

      <!-- DESCRIPTION -->
      <div class="field">
        <label for="description">{{'COMMON.DESCRIPTION' | translate}}</label>
        <textarea [style]="{maxHeight: '140px'}" [disabled]="(isClosed$ | async)" class="inputfield w-full"
                  id="description"
                  inputId="textarea" rows="6"
                  cols="30"
                  [(ngModel)]="deviation.description" pInputTextarea autoResize="autoResize"></textarea>
      </div>
    </div>
  </div>
</p-panel>

