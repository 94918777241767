import { Pipe, PipeTransform } from '@angular/core';
import {Competency} from "../model/competency";

@Pipe({
  name: 'competencyPersonFilter'
})
export class CompetencyPersonFilterPipe implements PipeTransform {

  subject: any

  transform(competencies: Competency[], selectedSubject: String, parentChildMap: any, loading: boolean): unknown {
    if(loading){
      return [];
    }
    if(!selectedSubject){
      return competencies
    }
    if(parentChildMap.has(selectedSubject)){
      this.subject = new Map()

      parentChildMap.get(selectedSubject).forEach(child => {
        this.getChildren(child, parentChildMap);
      })

      return competencies.filter(competency => this.subject.has(competency.personId)).sort((a: any, b: any) => a.status - b.status)
    }
  }

  getChildren(parent, parentChildMap){
    this.subject.set(parent, true);

    if(parentChildMap.has(parent)){
     parentChildMap.get(parent).forEach(child => {
       this.getChildren(child, parentChildMap)
     })
    }
  }

}
