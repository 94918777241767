<p-confirmDialog header="{{'WARNING.CONFIRMATION' | translate}}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="grid">

    <div class="col-8 flex flex-wrap align-content-center">
        <h2 style="margin-top: 10px">{{'DEVIATION.ONGOING' | translate}}</h2>
    </div>
    <div class="col-4 flex justify-content-end align-content-center">
        <button pButton (click)="addDeviation()" type="button" icon="pi pi-plus"
                class="p-button-rounded p-button-raised"></button>
    </div>

    <ng-container *ngIf="screenWidth(); else elseBlock">
        <div class="col-12 w-full">
            <span class="p-float-label">
                <input class="w-full" id="search" type="text" pInputText (input)="deviationFilter(getEventValue($event))">
                <label for="search" class="breakDots">{{'DEVIATION.SEARCH' | translate}}</label>
            </span>
        </div>
        <div class="col-12 w-full">
            <p-dropdown [options]="filterOptions" [(ngModel)]="selectedFilter" [style]="{'width':'100%'}"
                        (onChange)="setDeviationFilter()" optionLabel="label" optionValue="value"
            ></p-dropdown>
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <div class="col-6">
            <span class="p-float-label">
                <input class="w-full" id="search2" type="text" pInputText (input)="deviationFilter(getEventValue($event))">
                <label for="search2">{{'DEVIATION.SEARCH' | translate}}</label>
            </span>
        </div>
        <div class="col-6">
            <p-dropdown [options]="filterOptions" [(ngModel)]="selectedFilter" [style]="{'width':'100%'}"
                        (onChange)="setDeviationFilter()" optionLabel="label" optionValue="value"
            ></p-dropdown>
        </div>
    </ng-template>

    <ng-container *ngFor="let deviation of deviations">
        <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-card styleClass="p-card-shadow box">
                <ng-template pTemplate="header">
                    <div style="background-color: #F5A623; min-height: 30px; color: white" class="w-full banner">
                        <div class="col-12 flex justify-content-center pb-0">
                            <h2 class="m-0" style="font-weight: 500">{{deviation.name}}</h2>
                        </div>
                        <div class="col-12 flex justify-content-center p-0">
                            <h5 class="m-0" style="font-weight: normal">{{getAsset(deviation.asset)}}</h5>
                        </div>
                        <div class="col-12 flex justify-content-center">
                            {{'FILE.LAST_UPDATED' | translate}}: {{getUpdatedDate(deviation.updated)}}
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="body" class="pt-0">
                    <textarea rows="4" cols="30" placeholder="{{'COMMON.DESCRIPTION' | translate}}"
                              pInputTextarea style="width: 100%" disabled [autoResize]="true"
                    >{{deviation.description}}</textarea>
                    <div class="grid">
                        <div class="col-5" style="max-height: 58px">
                            {{'COMMON.CREATED' | translate}}
                        </div>
                        <div class="col-7" style="max-height: 58px">
                            {{getCreatedDate(deviation.created)}}
                        </div>
                        <div class="col-5" style="max-height: 58px">
                            {{'COMMON.CREATED_BY' | translate}}
                        </div>
                        <div class="col-7" style="max-height: 58px">
                            {{personNameMap.get(uidPersonIdMap.get(deviation.createdById))}}
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="footer">
                    <div class="grid col-12 p-0">
                      <div class="col-3 pl-2">
                        <button (click)="editDeviation(deviation)" class="p-button-rounded p-button-raised"
                                icon="pi pi-cog" pButton
                        ></button>
                      </div>
                        <div class="col-3 pl-2">
                          <button (click)="openComments(deviation)"
                                  class="p-button-rounded p-button-outlined p-button-raised"
                                  icon="far fa-comments" pButton type="button"
                          ></button>

                        </div>
                        <div class="col-6 pr-0 flex justify-content-end">
                            <button pButton label="{{'DEVIATION.CLOSE' | translate}}"
                                    icon="pi pi-check" class="p-button-raised"
                                    (click)="confirm(deviation)"
                            ></button>
                        </div>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </ng-container>
</div>
