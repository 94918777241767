import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChecklistService} from "../../../services/service/checklist.service";
import {AddChecklistComponent} from "../add-checklist/add-checklist.component";
import {DialogService} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {Checklist} from '../../../model/checklist';
import {EditTemplateComponent} from '../edit-template/edit-template.component';
import {AccessControlService} from '../../../services/service/access-control.service';

@Component({
  selector: 'app-checklist-templates',
  templateUrl: './checklist-templates.component.html',
  styleUrls: ['./checklist-templates.component.scss'],
  providers: [DialogService]
})
export class ChecklistTemplatesComponent implements OnInit, OnDestroy{
  checklistsWithTemplates;
  checklistLoading = true;
  checklistWithTemplatesSubscription;
  isAdmin$; isSuper$; isUser$;
  constructor(
    private checklistService: ChecklistService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
  ) {

  }

  /**
   * Find templates at startup
   */
  ngOnInit(): void {
    this.checklistWithTemplatesSubscription =
      this.checklistService.getChecklistWithTemplate().subscribe(cl => {
        this.checklistsWithTemplates = cl;
        this.checklistLoading = false;
      });
    this.acService.getOwnAccessControl().subscribe(value => {
      if (value.roles.admin){
        this.isAdmin$ = value.roles.admin;
      } else {
        this.isAdmin$ = false;
      }
      if (value.roles.superuser){
        this.isSuper$ = value.roles.superuser;
      } else {
        this.isSuper$ = false;
      }
      if (value.roles.user){
        this.isUser$ = value.roles.user;
      } else {
        this.isUser$ = false;
      }
    });
  }

  /**
   * Add new Checklist
   */
  addChecklist() {
    this.dialogService.open(AddChecklistComponent, {
      header: this.translateService.instant('CHECKLIST.ADD_CHECKLIST.NEW_CHECKLIST'),
      styleClass: 'max-size-width-dialog',
      data: {
        index: 2,
      }
    });
  }

  /**
   * Open template for edit
   * @param template The template
   */
  editTemplate(template: Checklist) {
    if (this.isAdmin$ || this.isSuper$) {
      this.dialogService.open(EditTemplateComponent, {
        header: this.translateService.instant('CHECKLIST.LIST.TEMPLATE'),
        styleClass: 'max-size-width-dialog',
        data: {
          templateKey: template.key,
        }
      });
    }
  }

  /**
   * Generate checklist from template
   * @param template Checklist template
   */
  activateChecklistCopy(template: any) {
    this.dialogService.open(AddChecklistComponent, {
      header: this.translateService.instant('CHECKLIST.GENERATE_TEMP.GENERATE'),
      styleClass: 'max-size-width-dialog',
      data: {
        index: 0,
        template: template,
      }
    });
  }

  /**
   * On close unsubscribe all
   */
  ngOnDestroy(): void {
    if(this.checklistWithTemplatesSubscription){
      this.checklistWithTemplatesSubscription.unsubscribe();
    }
  }

  getVisible(checklist: any) {
    if (checklist.appAccess){
      return this.translateService.instant("CONFIRMATION.YES");
    } else {
      return this.translateService.instant("CONFIRMATION.NO");
    }
  }
}
