import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionsComponent } from './functions/functions.component';
import {Route} from "@angular/router";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {TranslateModule} from "@ngx-translate/core";
import { AddFunctionComponent } from './add-function/add-function.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoCompleteModule} from "primeng/autocomplete";
import {PipesModule} from "../../pipes/pipes.module";
import { EditFunctionComponent } from './edit-function/edit-function.component';
import {TabViewModule} from "primeng/tabview";
import {DropdownModule} from "primeng/dropdown";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { FunctionPersonDetailComponent } from './function-person-detail/function-person-detail.component';
import {TooltipModule} from "primeng/tooltip";
import {OverlayPanelModule} from "primeng/overlaypanel";
import { EditCompetencyTypesValidityComponent } from './edit-competency-types-validity/edit-competency-types-validity.component';
import {SplitButtonModule} from "primeng/splitbutton";
import {CanActivateService} from '../../services/can-activate.service';

export const FunctionRoutes: Route = {
  path: 'functions', component: FunctionsComponent, canActivate: [CanActivateService],
};


@NgModule({
  declarations: [
    FunctionsComponent,
    AddFunctionComponent,
    EditFunctionComponent,
    FunctionPersonDetailComponent,
    EditCompetencyTypesValidityComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    AutoCompleteModule,
    PipesModule,
    TabViewModule,
    DropdownModule,
    ConfirmDialogModule,
    TooltipModule,
    OverlayPanelModule,
    SplitButtonModule
  ]
})
export class FunctionModule { }
