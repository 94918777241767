<div class="grid">
  <div class="col-12">
    <h2 style="margin-top: 10px">{{'USER_ROLES.ADMIN' | translate}}</h2>
  </div>
</div>

<div class="grid justify-content-end align-items-center pb-2">
  <div style="padding-right: 10px">
    <p-dropdown [options]="adminFilter" [(ngModel)]="selectedFilter" optionLabel="name">
      <ng-template pTemplate="selectedItem">
        <div *ngIf="selectedFilter">
          <div>{{selectedFilter.name | translate}}</div>
        </div>
      </ng-template>
      <ng-template let-filter pTemplate="item">
        <div>
          <div>{{filter.name | translate}}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</div>

<app-new-login *ngIf="selectedFilter.value === 0"></app-new-login>
<app-temp-login *ngIf="selectedFilter.value === 1"></app-temp-login>
<app-login-list *ngIf="selectedFilter.value === 2"></app-login-list>
