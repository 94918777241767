import {Component, OnDestroy, OnInit} from '@angular/core';
import {AssetService} from "../../../services/service/asset.service";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {DeviationService} from "../../../services/service/deviation.service";
import {ChecklistService} from "../../../services/service/checklist.service";
import {SubjectService} from "../../../services/service/subject.service";
import {TranslateService} from "@ngx-translate/core";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {combineLatest, Observable, Subscription} from "rxjs";
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {CommentModalComponent} from "../comment-modal/comment-modal.component";
import {AddNewItemComponent} from "../add-new-item/add-new-item.component";
import {EditItemComponent} from "../edit-item/edit-item.component";
import {ReadDeviationComponent} from "../../deviation/read-deviation/read-deviation.component";
import {AddDeviationComponent} from "../../deviation/add-deviation/add-deviation.component";
import {AccessControlService} from "../../../services/service/access-control.service";
import {Checklist} from "../../../model/checklist";
import {ItemDeviationDetailComponent} from "../item-deviation-detail/item-deviation-detail.component";

@Component({
  selector: 'app-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss'],
  providers: [ConfirmationService, DialogService]
})
export class ChecklistDetailComponent implements OnInit, OnDestroy {

  checklistId;
  checklist: Checklist;

  // If User is Temp; True
  isObserver;

  // Observable that reacts if checklist is closed
  isCompleted$: Observable<boolean>;

  station;
  asset;
  deviationMap: any;

  splitButton = [];

  // MenuMap
  checklistMenuItemsMap: Map<string, MenuItem[]> = new Map();

  isUser$;

  // Wait for things to load.
  wait = 3;
  interval;

  // Deviations of Checklist
  checklistDeviations = [];

  // Open Deviations connected to Items.
  itemDeviationsMap = new Map();

  subscription = new Subscription();

  constructor(
    private assetService: AssetService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private deviationService: DeviationService,
    public checklistService: ChecklistService,
    private subjectService: SubjectService,
    private translateService: TranslateService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private acService: AccessControlService
  ) {

    this.acService.getOwnAccessControl().subscribe(rValue => {
      if (rValue.roles.user){
        this.isUser$ = rValue.roles.user;
      } else {
        this.isUser$ = false;
      }

      if (!this.isUser$) {
        this.splitButton = [
          {
            label: this.translateService.instant('BUTTON.NEW_ITEM'), icon: 'pi pi-plus-circle', command: () => {
              this.newItem();
            }
          },
          {separator: true},
          {
            label: this.translateService.instant('BUTTON.SAVE'), icon: 'pi pi-bookmark', command: () => {
              this.save();
            }
          },
          {separator: true},
          {
            label: this.translateService.instant('BUTTON.REMOVE'), icon: 'pi pi-trash', command: () => {
              this.delete();
            }
          }
        ];
      } else {
        this.splitButton = [
          {
            label: this.translateService.instant('BUTTON.SAVE'), icon: 'pi pi-bookmark', command: () => {
              this.save();
            }
          }
        ];
      }

      this.checklistId = this.config.data.checklistId;

      this.subscription.add(
        this.acService.isObserver().subscribe(isObserver => {
          this.isObserver = isObserver;
        })
      );


      // Get Checklist
      this.subscription.add(
        this.checklistService.getChecklist(this.checklistId).subscribe(checklist => {
          this.checklist = checklist;
          this.checklistMenuItemsMap = new Map();

          // Check if checklist has Items
          if (!checklist.items) {
            this.checklist.items = [];
          } else {
            Object.keys(checklist.items)
              .forEach(key => {
                // Build Item Menu
                this.checklistMenuItemsMap.set(key, this.buildMenuItem({key: key, ...checklist.items[key]}));
              });
          }

          // Check if Checklist has Comments
          if (!checklist.comments) {
            this.checklist.comments = [];
          }
          // Get Checklist Station
          this.subjectService.getStation(checklist.stationId).subscribe(station => this.station = station);

          // Get Checklist Asset
          this.assetService.getAsset(checklist.assetId).subscribe(asset => this.asset = {key: checklist.assetId, ...asset});

        })
      );

      // Listen to completed flag on the checklist
      this.isCompleted$ =
        this.checklistService.isChecklistCompleted(this.checklistId);


      // Map that contains the assets with deviation.
      this.subscription.add(
        this.deviationService.getOpenDeviationsAsMap().subscribe(dMap => {
          this.deviationMap = dMap;
        })
      );

      this.subscription.add(
        this.checklistService.getDeviationsMatchingChecklist(this.checklistId).subscribe(deviations => {
          this.checklistDeviations = deviations;
        })
      );

      const cDeviations$ = this.checklistService.getChecklistDeviations();
      const deviations$ = this.deviationService.getDeviations();

      // Create Item Deviation Map
      this.subscription.add(
        combineLatest([cDeviations$, deviations$])
          .subscribe(([cDeviations, deviations]) => {
            // Start with an empty map
            this.itemDeviationsMap = new Map();
            // Creates deviationId boolean map, which holds info if deviation is closed or not.
            const dMap = new Map(deviations.map(deviation => [deviation.key, !!deviation.payload.val().closed]));

            // Creates a itemDeviationMap of open deviations
            cDeviations.forEach((deviation) => {
              const value = deviation.payload.val();

              // Check if dMap has deviationId and that deviation is not closed
              if (dMap.has(value.deviationId) && !dMap.get(value.deviationId)) {
                if (this.itemDeviationsMap.has(value.itemId)) {
                  this.itemDeviationsMap.get(value.itemId).push(value.deviationId);
                } else {
                  this.itemDeviationsMap.set(value.itemId, [value.deviationId]);
                }
              }
            });
          })
      );

    });
  }


  ngOnInit() {
  }

  /**
   * Get Status for Asset
   * @param asset
   */
  setStatus(asset) {
    return this.assetService.getAssetStatus(asset, this.deviationMap);
  }

  /**
   * Unsubscribe on Destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Complete Checklist
   */
  complete() {
    this.confirmationService.confirm({
      message: this.translateService.instant('CHECKLIST.WARNING.COMPLETE'),
      accept: () => {
        this.authService.getUserUID()
          .pipe(
            take(1),
          ).subscribe((userUID) => {
          this.checklist.completed = true;
          this.checklist.completedById = userUID;
          this.checklist.dateOfCompletion = new Date().toISOString();
          this.checklistService.updateChecklist(this.checklistId, this.checklist);
          this.ref.close();
        });
      }
    });
  }

  openComments() {
    this.dialogService.open(CommentModalComponent, {
      header: this.translateService.instant('COMMON.COMMENTS'),
      data: {
        id: this.checklistId,
        mode: 'checklist'
      },
      styleClass: 'max-size-sm-dialog-long'
    });
  }

  newItem() {
    this.dialogService.open(AddNewItemComponent, {
      header: this.translateService.instant('CHECKLIST.ADD_ITEM.TITLE'),
      data: {
        checklistId: this.checklistId
      },
      styleClass: 'max-size-sm-dialog'
    });
  }

  editItem(item) {
    const editItem = this.dialogService.open(EditItemComponent, {
      header: item.name,
      data: {
        item: item
      },
      styleClass: 'max-size-sm-dialog'
    });

    editItem.onClose.pipe(
      take(1),
    ).subscribe(newItem => {
      if (newItem) {
        this.save(newItem);
      }
    });
  }

  newDeviation(item) {
    this.dialogService.open(AddDeviationComponent, {
      header: this.translateService.instant('DEVIATION.NEW'),
      data: {
        itemId: item.key,
        item: item,
        checklist: this.checklist,
        checklistId: this.checklistId,
        from: "cList"
      },
      styleClass: 'max-size-width-dialog'
    });
  }

  readAboutDeviation(item) {
    const idArray = this.itemDeviationsMap.get(item.key);
    if (!idArray) {
      this.messageService.add(
        {
          key: 'error',
          severity: 'info',
          detail: this.translateService.instant('ASSET.ASSET_CARD.NO_DEVIATION'),
          life: 4000
        });
    } else if (idArray.length > 1) {
      this.dialogService.open(ItemDeviationDetailComponent, {
        header: this.translateService.instant('MAIN_FRAME.DEVIATIONS') + ' : ' + item.name,
        styleClass: 'max-size-width-dialog',
        data: {
          deviations: idArray,
        }
      });
    } else {
      this.dialogService.open(ReadDeviationComponent, {
        header: item.name,
        data: {
          deviationId: idArray[0]
        },
        styleClass: 'max-size-width-dialog'
      });
    }
  }

  deleteItem(item) {
    if (!this.itemDeviationsMap.has(item.key)) {
      this.confirmationService.confirm({
        message: this.translateService.instant('WARNING.REMOVE') + ':<br>' + item.name,
        accept: () => {
          this.checklistService.deleteChecklistItem(this.checklistId, item.key);
        }
      });
    } else {
      this.messageService.add(
        {
          key: 'error',
          severity: 'error',
          detail: this.translateService.instant('CHECKLIST.WARNING.ERROR_DELETE'),
          life: 4000
        });
    }
  }

  private save(item?) {
    if (item) {
      const key = item.key;
      delete item.key;
      this.checklist.items[key] = item;
      this.checklistService.updateChecklistItems(this.checklistId, this.checklist.items);
    } else {
      this.checklistService.updateChecklistItems(this.checklistId, this.checklist.items);
    }
  }

  private delete() {
    if (this.checklistDeviations.length < 1) {
      this.confirmationService.confirm({
        message: this.translateService.instant('WARNING.REMOVE') + ':<br>' + this.checklist.name,
        accept: () => {
          this.checklistService.deleteChecklist(this.checklistId);
          this.ref.close();
        }
      });
    } else {
      this.messageService.add(
        {
          key: 'error',
          severity: 'error',
          detail: this.translateService.instant('CHECKLIST.WARNING.ERROR_DELETE'),
          life: 4000
        });
    }
  }

  private buildMenuItem(item): MenuItem[] {
    if(!this.isUser$) {
      return [
        {
          label: item.name,
          items: [
            {
              label: this.translateService.instant('BUTTON.READ_MORE'),
              icon: 'pi pi-book',
              command: () => {
                this.editItem(item);
              }
            },
            {
              label: this.translateService.instant('BUTTON.NEW_ERROR'),
              icon: 'pi pi-plus-circle',
              command: () => {
                this.newDeviation(item);
              }
            },
            {
              label: this.translateService.instant('CHECKLIST.READ_ABOUT_DEVIATIONS'),
              icon: 'pi pi-book',
              command: () => {
                this.readAboutDeviation(item);
              }
            },
            {separator: true},
            {
              label: this.translateService.instant('BUTTON.REMOVE'),
              icon: 'pi pi-trash',
              command: () => {
                this.deleteItem(item);
              }
            },
          ]
        }
      ];
    } else {
      return [
        {
          label: item.name,
          items: [
            {
              label: this.translateService.instant('BUTTON.READ_MORE'),
              icon: 'pi pi-book',
              command: () => {
                this.editItem(item);
              }
            },
            {
              label: this.translateService.instant('BUTTON.NEW_ERROR'),
              icon: 'pi pi-plus-circle',
              command: () => {
                this.newDeviation(item);
              }
            },
            {
              label: this.translateService.instant('CHECKLIST.READ_ABOUT_DEVIATIONS'),
              icon: 'pi pi-book',
              command: () => {
                this.readAboutDeviation(item);
              }
            },
          ]
        }
      ];
    }
  }
}

