<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-circle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12">
    <div class="col-12 flex justify-content-end">
      <span class="p-buttonset">
        <button label="{{'BUTTON.CANCEL' | translate}}"
                pButton
                icon="pi pi-times"
                iconPos="left"
                class="p-button-raised p-button-text"
                (click)="close()"
        ></button>
      <button label="{{'BUTTON.SAVE' | translate}}"
              icon="pi pi-check"
              iconPos="left"
              pButton
              class="p-button-raised"
              [disabled]="!editedEmergency.valid"
              (click)="onSubmit()"
      ></button>
      </span>
    </div>
    <!-- Emergency Form -->
    <form [formGroup]="editedEmergency" (ngSubmit)="onSubmit()">
      <div class="formgrid grid">

        <!-- Date -->
        <div class="field col-6">
          <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                      dateFormat="dd.mm.yy"
                      [style]="{'width' : '100%'}"
                      class="w-full inputfield"
                      formControlName="date"
          ></p-calendar>
        </div>

        <!-- Select type -->
        <div class="col-6">
          <p-dropdown
            placeholder="{{'EVENT.CHOOSE_TYPE' | translate}}"
            [options]="types"
            formControlName="typeKey"
            optionLabel="name"
            optionValue="key"
            [style]="{'width' : '100%'}"
            class="w-full"
          ></p-dropdown>
        </div>

        <!-- Time From -->
        <div class="field col-6">
          <label for="startTime">{{'COMMON.FROM' | translate}}*</label>
          <input type="time"
                 id="startTime"
                 pInputText
                 class="inputfield w-full"
                 formControlName="startTime"
          >
        </div>

        <!-- To Time -->
        <div class="field col-6">
          <label for="endTime">{{'COMMON.TO' | translate}}*</label>
          <input type="time"
                 id="endTime"
                 pInputText
                 class="inputfield w-full"
                 formControlName="endTime"
          >
        </div>

        <!-- Address -->
        <div class="field col-12 mt-2">
          <span class="p-float-label">
            <input type="text" id="address"
                   pInputTextarea
                   class="inputfield w-full"
                   formControlName="address">
            <label for="address">{{'SUBJECTS.ADDRESS' | translate}}</label>
          </span>
        </div>

        <!-- Zip -->
        <div class="field col-6 mt-2">
          <span class="p-float-label">
            <input type="text" id="zip"
                   pInputTextarea
                   class="inputfield w-full"
                   formControlName="zip">
            <label for="zip">{{'SUBJECTS.ZIP' | translate}}</label>
          </span>
        </div>

        <!-- Postal -->
        <div class="field col-6 mt-2">
          <span class="p-float-label">
              <input type="text" id="postal"
                     pInputTextarea
                     class="inputfield w-full"
                     formControlName="postal">
            <label for="postal">{{'SUBJECTS.POSTAL' | translate}}</label>
          </span>
        </div>

        <!-- Description -->
        <div class="field col-12 mt-2">
          <span class="p-float-label">
            <textarea id="textarea"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      pInputTextarea
                      formControlName="description"
                      class="inputfield w-full"></textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
          </span>
        </div>

        <!-- Bris number -->
        <div class="field col-12 mt-2">
            <span class="p-float-label">
            <input type="text" id="bris"
                   pInputTextarea
                   class="inputfield w-full"
                   formControlName="brisNumber">
            <label for="bris">{{'EVENT.BRIS' | translate}}</label>
          </span>
        </div>

        <!-- Leader selection -->
        <div class="col-6 mb-4">
          <p-dropdown
            placeholder="{{'EVENT.ERE.EMERGENCY_LEADER' | translate}}"
            [options]="addedPersons"
            optionLabel="name"
            optionValue="personKey"
            formControlName="leaderKey"
            [style]="{'width' : '100%'}"
            class="w-full"
          ></p-dropdown>
        </div>

        <!-- Menu -->
        <div class="col-6 mb-4 flex justify-content-end">
          <p-splitButton
            #sb (onClick)="sb.onDropdownButtonClick(null)"
            icon="pi pi-list"
            label="{{'COMMON.MENU' | translate}}"
            [model]="menuItems"
            appendTo="body"
          >
          </p-splitButton>
        </div>

        <!-- Person table -->
        <div class="col-12 flex w-full">
          <p-table responsiveLayout="block"
                   breakpoint="200px"
                   [value]="personArray"
                   dataKey="key"
          >
            <!-- Search bar -->
            <ng-template pTemplate="caption" class="pt-5">
              <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                              [(ngModel)]="selectedPerson"
                              (onSelect)="addPerson(selectedPerson)"
                              [ngModelOptions]="{standalone: true}"
                              placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}"
                              [dropdown]="true"
                              (completeMethod)="filterPerson($event)"
                              size="100%"
                              field="label"
                              type="search"
                              scrollHeight="400px"
                              class="inputfield w-full"
                              appendTo="body"
                              [forceSelection]="true"
                              [group]="true"
                              [disabled]="timeSet()"
              >

                <ng-template let-team pTemplate="group">
                  <div class="flex align-items-center p-2" (click)="addTeam(team)">
                    <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                  </div>
                </ng-template>
                <ng-template let-subject pTemplate="item">
                  <div class="person-item">
                    {{subject.label}}
                  </div>
                </ng-template>

              </p-autoComplete >
            </ng-template>

            <!-- Header -->
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 40%">{{'FDC.PERSONS' | translate}}</th>
                <th style="width: 30%">
                  <span class="flex justify-content-center">
                    <i class="far fa-stopwatch"></i>
                  </span>
                </th>
                <th style="width: 15%">
                  <span class="flex justify-content-center">
                    <i style="color: #F5A623" class="far fa-exclamation-triangle"></i>
                  </span>
                </th>
                <th style="width: 15%">
                   <span class="flex justify-content-center">
                     <i class="pi pi-trash" style="color: #D0021B"></i>
                  </span>
                </th>
              </tr>
            </ng-template>

            <!-- Body -->
            <ng-template pTemplate="body" let-personId>
              <tr>
                <td>
                  {{getName(personId)}}
                </td>
                <td>
                  <div class="flex justify-content-center" (click)="changePersonTimePeriod(personId)">
                    {{printTime(personId)}}
                  </div>
                </td>
                <td>
                  <div class="flex justify-content-center">
                    {{exposureCounter(personId)}}
                  </div>
                </td>
                <td (click)="deletePerson(personId)">
                   <span class="flex justify-content-center">
                      <i class="pi pi-trash" style="color: #D0021B"></i>
                  </span>
                </td>
              </tr>
            </ng-template>

            <!-- Shows message if table is empty -->
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3">
                  {{'WARNING.NO_DATA' | translate}}
                </td>
              </tr>
            </ng-template>

          </p-table>
        </div>

      </div>
    </form>
  </div>
</div>
