<div class="grid">
  <ng-container *ngIf="!fromInfo; else editBlock">
    <div class="col-12">

    <span class="p-float-label mt-3">
      <textarea id="description" class="w-full" rows="5" pInputTextarea [(ngModel)]="goal"></textarea>
      <label for="description">{{'EXERCISE.EVALUATION.GOAL' | translate}}</label>
    </span>

      <span class="p-float-label mt-4">
      <textarea id="evaluation" class="w-full" rows="5" pInputTextarea [(ngModel)]="evaluation"></textarea>
      <label for="evaluation">{{'EXERCISE.EVALUATION.EVALUATION' | translate}}</label>
    </span>

      <span class="p-float-label mt-4">
      <textarea id="learningPoints" class="w-full" rows="5" pInputTextarea [(ngModel)]="learningPoints"></textarea>
      <label for="learningPoints">{{'EXERCISE.EVALUATION.LEARNINGPOINTS' | translate}}</label>
    </span>

      <span class="p-float-label mt-4">
      <textarea id="various" class="w-full" rows="5" pInputTextarea [(ngModel)]="other"></textarea>
      <label for="various">{{'EXERCISE.EVALUATION.OTHER' | translate}}</label>
    </span>

      <div class="col-12 flex justify-content-end" *ngIf="!fromInfo">
        <button label="{{'BUTTON.SAVE' | translate}}"
                icon="pi pi-check"
                iconPos="right" pButton
                class="p-button-raised"
                (click)="save()"
        ></button>
      </div>

    </div>
  </ng-container>
  <ng-template #editBlock>
    <div class="col-12">

    <span class="p-float-label mt-3">
      <textarea id="descriptionNE" class="w-full" rows="5" pInputTextarea [(ngModel)]="goal" disabled></textarea>
      <label for="descriptionNE">{{'EXERCISE.EVALUATION.GOAL' | translate}}</label>
    </span>

      <span class="p-float-label mt-4">
      <textarea id="evaluationNE" class="w-full" rows="5" pInputTextarea [(ngModel)]="evaluation" disabled></textarea>
      <label for="evaluationNE">{{'EXERCISE.EVALUATION.EVALUATION' | translate}}</label>
    </span>

      <span class="p-float-label mt-4">
      <textarea id="learningPointsNE" class="w-full" rows="5" pInputTextarea [(ngModel)]="learningPoints" disabled></textarea>
      <label for="learningPointsNE">{{'EXERCISE.EVALUATION.LEARNINGPOINTS' | translate}}</label>
    </span>

      <span class="p-float-label mt-4">
      <textarea id="variousNE" class="w-full" rows="5" pInputTextarea [(ngModel)]="other" disabled></textarea>
      <label for="variousNE">{{'EXERCISE.EVALUATION.OTHER' | translate}}</label>
    </span>
    </div>
  </ng-template>
</div>
