import { Component, Input, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppointmentService} from '../../../services/service/appointment.service';
import {combineLatest, Subscription} from 'rxjs';
import {PersonService} from '../../../services/service/person.service';
import {SubjectService} from '../../../services/service/subject.service';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {FilterService, MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {CalendarEvent} from '../../../model/calendarEvent';
import {AuthService} from '../../../services/service/auth.service';
import {Appointment} from '../../../model/appointment';
import {CalendarService} from '../../../services/service/calendar.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  providers: [MessageService]
})
export class AppointmentComponent implements OnInit {
  @Input() fromCalendarEvent: Appointment; @Input() fromCalendarEventKey: string; @Input() fromCalendar: boolean;
  subscription = new Subscription();
  appointmentForm: UntypedFormGroup;

  appointmentTypes: any[];
  aTypeMap = new Map<string, string>();

  windowWidth: number;

  outputPerson = [];
  personMap = new Map();

  personNameMap;
  teamNameMap;
  personParentNameMap;

  groupedSubjectFilter: any[];
  groupedAssetsFilter: any[];

  selectedSubjects: any[] = [];
  selectedPerson = "";
  checked: boolean;

  initialDate: string;
  initialDateTo: string;
  timeStart: string;
  timeEnd: string;

  smsNotification: boolean;
  smsDaysBefore: number;
  emailNotification: boolean;
  emailDaysBefore: number;

  loading = true;
  constructor(
      private appointmentService: AppointmentService,
      private personService: PersonService,
      private subjectService: SubjectService,
      private gsService: GroupedSubjectsService,
      private filterService: FilterService,
      private ref: DynamicDialogRef,
      private messageService: MessageService,
      private translateService: TranslateService,
      private authService: AuthService,
      private calendarService: CalendarService,
  ) { }

  ngOnInit(): void {

    this.appointmentForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      dateTo: new UntypedFormControl(''),
      details: new UntypedFormControl(''),
      location: new UntypedFormControl(''),
      typeKey: new UntypedFormControl('', Validators.required),
    });

    this.subscription.add(combineLatest([
      this.personService.getPersons(),
      this.subjectService.getTeams(),
      this.subjectService.getRelations(),
      this.appointmentService.getAppointmentTypes()])
        .subscribe(([persons, teams, relations, appointmentTypes]) => {
          this.appointmentTypes =
              appointmentTypes.map(appointmentType => {
                return {key: appointmentType.key, ...appointmentType.payload.val()};
              });
          this.aTypeMap = new Map(appointmentTypes.map(appointmentType => [appointmentType.key, appointmentType.payload.val().name]));
          const subjectData = this.gsService.build(persons, teams, relations);
          this.personNameMap = subjectData[0];
          this.teamNameMap = subjectData[1];
          this.personParentNameMap = subjectData[2];
          this.groupedSubjectFilter = <any[]> subjectData[3];

          if (this.fromCalendarEvent){
            this.timeStart = this.fromCalendarEvent.timeStart;
            this.timeEnd = this.fromCalendarEvent.timeEnd;

            if (this.fromCalendarEvent.date.length === 10) {
              const start = this.fromCalendarEvent.date.split(".");
              const end = this.fromCalendarEvent.dateTo.split(".");
              this.initialDate = moment([start[2], start[1], start[0]]).format("DD.MM.YYYY");
              this.initialDateTo = moment([end[2], end[1], end[0]]).format("DD.MM.YYYY");
            } else {
              this.initialDate = moment(this.fromCalendarEvent.date).format("DD.MM.YYYY");
              this.initialDateTo = moment(this.fromCalendarEvent.dateTo).format("DD.MM.YYYY");
            }

            this.emailNotification = this.fromCalendarEvent.emailNotification;
            this.smsNotification = this.fromCalendarEvent.smsNotification;

            if (this.emailNotification) {
              this.emailDaysBefore = this.fromCalendarEvent.emailDaysBefore;
            }
            if (this.smsNotification) {
              this.smsDaysBefore = this.fromCalendarEvent.smsDaysBefore;
            }

            const location = this.fromCalendarEvent.location;

            if (this.fromCalendarEvent.details && this.fromCalendarEvent.location){
              if (this.initialDate !== this.initialDateTo){
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, this.initialDateTo,
                    this.fromCalendarEvent.details, location, this.fromCalendarEvent.typeKey);
              } else {
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, '',
                    this.fromCalendarEvent.details, location, this.fromCalendarEvent.typeKey);
              }
            } else if (this.fromCalendarEvent.details){
              if (this.initialDate !== this.initialDateTo){
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, this.initialDateTo,
                    this.fromCalendarEvent.details, '', this.fromCalendarEvent.typeKey);
              } else {
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, '',
                    this.fromCalendarEvent.details, '', this.fromCalendarEvent.typeKey);
              }

            } else if(this.fromCalendarEvent.location){
              if (this.initialDate !== this.initialDateTo){
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, this.initialDateTo, '',
                    location, this.fromCalendarEvent.typeKey);
              } else {
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, '',
                    null, location, this.fromCalendarEvent.typeKey);
              }
            } else {
              if (this.initialDate !== this.initialDateTo){
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, this.initialDateTo,
                    '', '', this.fromCalendarEvent.typeKey);
              } else {
                this.appointmentForm = this.setFormValues(this.fromCalendarEvent.name, this.initialDate, '',
                    '', '', this.fromCalendarEvent.typeKey);
              }
            }
            if (this.fromCalendarEvent.participants){
                Object.keys(this.fromCalendarEvent.participants).forEach(key => {
                  if (key.includes("-")){
                    const subject = {label: this.personNameMap.get(key), value: key};
                    this.addPerson(subject);
                  } else {
                    const subject = {
                      label: this.personNameMap.get(this.fromCalendarEvent.participants[key].key),
                      value: this.fromCalendarEvent.participants[key].key
                    };
                    this.addPerson(subject);
                  }
                });
            }
          }
        })
    );

    this.windowWidth = window.innerWidth;
  }

  /**
   * Set Form values
   * @param name String
   * @param date DateISO
   * @param dateTo DateToISO
   * @param details String
   * @param location String
   * @param typeKey String
   */
  setFormValues(name, date, dateTo, details, location, typeKey){
    return new UntypedFormGroup({
      name: new UntypedFormControl(name, Validators.required),
      date: new UntypedFormControl(date, Validators.required),
      dateTo: new UntypedFormControl(dateTo),
      details: new UntypedFormControl(details),
      location: new UntypedFormControl(location),
      typeKey: new UntypedFormControl(typeKey, Validators.required),
    });
  }

  /**
   * Set/unset all day
   */
  setTime(event) {
    if (event.checked){
      this.timeStart = "00:00";
      this.timeEnd = "23:59";
    } else {
      this.timeStart = "";
      this.timeEnd = "";
    }
  }

  /**
   * Add team
   * @param team Team object
   */
  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  /**
   * Add Person
   * @param subject Subject to add
   */
  addPerson(subject){
    if (!this.personMap.has(subject)) {
      this.personMap.set(subject.value, true);
      this.selectedSubjects.push(subject.value);
    }
  }

  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Remove person
   * @param personId
   */
  removePerson(personId: any) {
    this.personMap.delete(personId);

    const index = this.selectedSubjects.findIndex(pId => pId === personId);
    if(index >= 0) {
      this.selectedSubjects.splice(index, 1);
    }
  }

  /**
   * Submit new appointment
   */
  onSubmit() {
    if ((this.smsNotification && moment(this.appointmentForm.value.date)
            .subtract(this.smsDaysBefore, "days").toISOString() < moment().toISOString())
        || (this.emailNotification && moment(this.appointmentForm.value.date)
            .subtract(this.emailDaysBefore, "days").toISOString() < moment().toISOString())
    ) {
      this.messageService.add({
        severity: 'error', summary: 'Error', life: 3000,
        detail: this.translateService.instant('NOTIFICATIONS.ERROR_NOTIFICATION')
      });
    } else {

      const ongoingForm = this.appointmentForm.value;
      this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

        if (ongoingForm.dateTo === "") {
          ongoingForm.dateTo = ongoingForm.date;
        }
        let appointment: Appointment;

        if (ongoingForm.date !== this.initialDate || ongoingForm.dateTo !== this.initialDateTo) {
          let ongoingDate; let ongoingDateTo;

          if (ongoingForm.date.length === 10){
            const split = ongoingForm.date.split('.');
            ongoingDate = moment(split[2] + '-' + split[1] + '-' + split[0]).toISOString();
          } else {
            ongoingDate = moment(ongoingForm.date).toISOString();
          }

          if (ongoingForm.dateTo.length === 10){
            const split = ongoingForm.dateTo.split('.');
            ongoingDateTo = moment(split[2] + '-' + split[1] + '-' + split[0]).toISOString();
          } else {
            ongoingDateTo = moment(ongoingForm.dateTo).toISOString();
          }

          appointment = {
            createdById: uid,
            dateCreated: moment().toISOString(),
            date: ongoingDate,
            dateTo: ongoingDateTo,
            details: ongoingForm.details,
            name: ongoingForm.name,
            location: ongoingForm.location,
            timeStart: this.timeStart,
            timeEnd: this.timeEnd,
            typeKey: ongoingForm.typeKey
          };
        } else {
          const split = this.initialDate.split('.');
          const splitTo = this.initialDateTo.split('.');
          appointment = {
            createdById: uid,
            dateCreated: moment().toISOString(),
            date: moment(split[2] + '-' + split[1] + '-' + split[0]).toISOString(),
            dateTo: moment(splitTo[2] + '-' + splitTo[1] + '-' + splitTo[0]).toISOString(),
            details: ongoingForm.details,
            location: ongoingForm.location,
            name: ongoingForm.name,
            timeStart: this.timeStart,
            timeEnd: this.timeEnd,
            typeKey: ongoingForm.typeKey
          };
        }

        if (this.smsNotification) {
          appointment.smsNotification = true;
          appointment.smsDaysBefore = this.smsDaysBefore;
        }

        if (this.emailNotification) {
          appointment.emailNotification = true;
          appointment.emailDaysBefore = this.emailDaysBefore;
        }
        const calendarEvent: CalendarEvent = {
          completed: false,
          calendarType: "EVENT",
          start: moment(moment(appointment.date).format("YYYY-MM-DD") + " " + this.timeStart).toISOString(),
          end: moment(moment(appointment.dateTo).format("YYYY-MM-DD") + " " + this.timeEnd).toISOString(),
          title: "",
        };

        if (ongoingForm.typeKey) {
          calendarEvent.title = this.aTypeMap.get(ongoingForm.typeKey) + " " + ongoingForm.name;
        } else {
          calendarEvent.title = ongoingForm.name;
        }

        if (!this.fromCalendar) {
          this.appointmentService.addAppointmentGetKey(appointment).pipe(take(1)).subscribe(aKey => {
            calendarEvent.taskId = aKey;
            this.calendarService.addCalendarEvent(calendarEvent, aKey);
            this.selectedSubjects.forEach(subjectId => {
              this.appointmentService.addParticipantToTask(aKey, subjectId, true);
            });
          });
        } else {
          this.appointmentService.updateAppointment(this.fromCalendarEventKey, appointment);
          this.selectedSubjects.forEach(subjectId => {
            this.appointmentService.addParticipantToTask(this.fromCalendarEventKey, subjectId, true);
          });
          this.calendarService.updateCalendarEvent(calendarEvent, this.fromCalendarEventKey);
        }
        this.ref.close();
      });
    }
  }

  /**
   * Abort
   */
  close() {
    this.ref.close();
  }

  /**
   * Delete Appointment
   */
  delete() {
    this.appointmentService.deleteAppointment(this.fromCalendarEventKey);
    this.calendarService.deleteCalendarEvent(this.fromCalendarEventKey);
    this.ref.close();
  }
}
