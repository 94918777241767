<div class="grid">
    <div class="col-12">
        <ng-container *ngIf="calendarType === 'EXERCISE'">
            <app-exercise-detail [fromCalendarEventKey]="eventId"></app-exercise-detail>
        </ng-container>

        <ng-container *ngIf="calendarType === 'TASK'">
            <app-view-task [fromCalendarEventKey]="eventId"></app-view-task>
        </ng-container>

        <ng-container *ngIf="calendarType === 'EVENT'">
            <app-appointment [fromCalendarEvent]="event" [fromCalendarEventKey]="eventId"
                             [fromCalendar]="true"></app-appointment>
        </ng-container>
    </div>
</div>
