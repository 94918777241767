<!-- Toast -->
<p-toast key="crd" position="top-left" styleClass="customToast"></p-toast>

<div class="grid">
  <div class="col-12">
    <!-- STATUS -->
    <p-fieldset legend="{{ 'COMMON.STATUS' | translate }}" [toggleable]="true">
      <div class="grid flex justify-content-around flex-wrap">
        <!-- STATION -->
        <div><i class="far fa-warehouse"></i>&nbsp;{{ station?.name }}</div>
        <!-- Show Asset and Asset Status -->
        <div>
          <span [ngStyle]="setStatus(asset)">
            <i class="far fa-truck"></i>&nbsp;{{ asset?.name }}
          </span>
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="col-12 flex justify-content-end">
    <!-- PAUSE/PLAY-->
    <button
      pButton
      type="button"
      class="p-button-outlined p-button-raised"
      (click)="changeState()"
    >
      <div *ngIf="!cronJob?.pause">
        <i class="far fa-pause" style="color: #f5a623"></i>
        &nbsp;{{ "BUTTON.PAUSE" | translate }}
      </div>

      <div *ngIf="cronJob?.pause">
        <i class="far fa-play" style="color: #73bf21"></i>
        &nbsp;{{ "BUTTON.ACTIVATE" | translate }}
      </div>
    </button>

    <!-- Split Button -->
    <p-splitButton
      label="{{ 'BUTTON.EDIT' | translate }}"
      icon="pi pi-cog"
      (onClick)="edit()"
      [model]="splitItems"
      appendTo="body"
      class="p-button-raised"
    ></p-splitButton>
  </div>

  <!-- TABLE -->
  <div class="col-12 p-0">
    <p-table
      [value]="repeatingChecklist?.items | keyvalue | hideArchived"
      responsiveLayout="scroll"
    >
      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1rem"><i class="far fa-clock"></i></th>
          <th>{{ "COMMON.NAME" | translate }}</th>
          <th style="width: 1rem"></th>
          <th style="width: 1rem"></th>
        </tr>
      </ng-template>

      <!-- BODY -->
      <ng-template pTemplate="body" let-item>
        <tr>
          <!-- SHOW status pause of item -->
          <td *ngIf="item.value.pause">
            <i class="far fa-pause" style="color: #f5a623"></i>
          </td>

          <td *ngIf="!item.value.pause">
            <i class="far fa-play" style="color: #73bf21"></i>
          </td>

          <!-- NAME -->
          <td>{{ item.value.name}}</td>

          <!-- SHOW number of deviations if any. -->
          <td>
            <span
              *ngIf="itemDeviationsMap.has(item.key)"
              class="flex justify-content-end"
            >
              <i
                class="far fa-exclamation-triangle mr-1"
                style="color: #f5a623"
              ></i>
              {{ itemDeviationsMap.get(item.key)?.length }}
            </span>
          </td>

          <!-- MENU -->
          <td>
            <p-menu
              #menu
              appendTo="body"
              [popup]="true"
              [model]="itemMenuMap.get(item.key)"
            ></p-menu>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-cog"
              (click)="menu.toggle($event)"
              class="p-button-rounded p-button-text"
            ></button>
          </td>
        </tr>

        <!-- EMPTYMESSAGE -->
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">{{ "WARNING.NO_DATA" | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
