import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Deviation} from "../../../model/deviation";
import {DeviationService} from "../../../services/service/deviation.service";
import {PersonService} from "../../../services/service/person.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../../services/service/auth.service";
import {ReadDeviationComponent} from "../../deviation/read-deviation/read-deviation.component";

@Component({
  selector: 'app-item-deviation-detail',
  templateUrl: './item-deviation-detail.component.html',
  styleUrls: ['./item-deviation-detail.component.scss'],
  providers: [DialogService]
})
export class ItemDeviationDetailComponent implements OnInit{
  deviations: string[];
  deviationMap: Map<string, Deviation> = new Map();
  personMap: Map<string, string> = new Map();

  subscription = new Subscription();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private deviationService: DeviationService,
    public personService: PersonService,
    private dialogService: DialogService,
  ) {
    this.deviations = this.config.data.deviations;

    this.deviationService.getDeviations().subscribe(deviations => {
      this.deviationMap = new Map(deviations.map(deviation => [deviation.key, deviation.payload.val()]));
    });

    this.personService.getUIDPersonNameMap().subscribe(uidNameMap => {
      this.personMap = uidNameMap;
    });
  }

  ngOnInit(): void {
  }

  openDeviation(id) {
    this.dialogService.open(ReadDeviationComponent, {
      header: this.deviationMap.get(id).name,
      data: {
        deviationId: id
      },
      styleClass: 'max-size-width-dialog'
    })
  }
}
