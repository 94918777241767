import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {SubjectService} from '../../../services/service/subject.service';
import {PersonService} from '../../../services/service/person.service';
import {TaskService} from '../../../services/service/task.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {AssetService} from '../../../services/service/asset.service';
import * as moment from 'moment';
import {Task} from '../../../model/task';
import {ViewCompletedComponent} from '../view-completed/view-completed.component';

@Component({
  selector: 'app-completed-tasks',
  templateUrl: './completed-tasks.component.html',
  styleUrls: ['./completed-tasks.component.scss'],
  providers: [DialogService]
})
export class CompletedTasksComponent implements OnInit, OnDestroy {
  tasks;
  selectedTaskFilter: any;
  stations: any;
  taskLoading = true;
  taskFilter = [];
  subscription = new Subscription();

  assetNameMap = new Map<string, string>();
  constructor(
      private subjectService: SubjectService,
      private personService: PersonService,
      private taskService: TaskService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private assetService: AssetService,
  ) {
    this.subscription.add(combineLatest([this.subjectService.getStations(), this.personService.getOwnSubjectId(),
      this.taskService.getTasks(), this.assetService.getAssets()])
        .subscribe(([stationsPayload, subjectId, tasks, assets]) => {

          this.assetNameMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
          this.tasks = [];
          tasks.forEach(task => {
             if (task.payload.val().completed){
                 this.tasks.push({key: task.key, ...task.payload.val()});
             }
          });
          this.tasks.sort((a, b) => {
              if(a.completed < b.completed) { return 1; }
              if(a.completed > b.completed) { return -1; }
              return 0;
          });

          this.taskLoading = false;

          if(!subjectId){
            this.selectedTaskFilter = '';
          } else {
            this.selectedTaskFilter = {key: subjectId, name: 'TASK.MY_TASKS'};
            this.taskFilter.push({key: subjectId, name: 'TASK.MY_TASKS'});
          }
          this.taskFilter.push({key: '', name: 'CHECKLIST.SHOW_ALL'});

          const stations = stationsPayload.map(
              station => {
                return ({key: station.key, name: station.payload.val().name});
              }
          );
          this.taskFilter.push(...stations);

          this.stations = stationsPayload.map(
              station => {
                return station.key;
              }
          );
        })
    );
  }

  ngOnInit(): void {
  }


  /**
   * Get date format
   * @param deadline time stamp
   */
  getDate(deadline: any) {
    const m = moment(new Date).format('YYYY');
    if (m === moment(deadline).format('YYYY')){
      return moment(deadline).format('DD MMM');
    } else {
      return moment(deadline).format('DD.MM.YY');
    }
  }

  /**
   * View task
   * @param task task object
   */
  viewTask(task: Task) {
    this.dialogService.open(ViewCompletedComponent, {
      header: task.name,
      styleClass: 'max-size-dialog',
      data: {
          task: task,
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
