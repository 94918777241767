import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, FilterService} from "primeng/api";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {AuthService} from "../../../services/service/auth.service";
import {ExerciseService} from "../../../services/service/exercise.service";
import {Subscription} from "rxjs";
import {Theme} from "../../../model/theme";
import {take} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-theme',
  templateUrl: './edit-theme.component.html',
  styleUrls: ['./edit-theme.component.scss'],
  providers: [ConfirmationService]
})
export class EditThemeComponent implements OnInit, OnDestroy {
  key;
  theme: Theme = {
    competencyTypes: [], createdBy: "", createdDate: "", name: ""
  };

  selectedCT: any;
  output: any;
  ctMap = new Map();
  competencyTypes;
  ctNameMap: Map<string, string> = new Map();
  loading = true;

  subscription = new Subscription();
  splitItems: any;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private filterService: FilterService,
    private ctService: CompetencyTypeService,
    private authService: AuthService,
    private exerciseService: ExerciseService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    this.key = this.config.data.key;
    this.exerciseService.getTheme(this.key).pipe(
      take(1)
    ).subscribe(theme => {
      this.theme = theme;
      if(!this.theme.competencyTypes){
        this.theme.competencyTypes = [];
      }
      this.ctMap = new Map(this.theme.competencyTypes.map(ct => [ct, true]));
    });

    this.splitItems = [
      {label: this.translateService.instant('BUTTON.REMOVE'), icon: 'pi pi-trash', command: () => {
        this.deleteTheme();
        }}
    ];
  }

  ngOnInit(): void {
    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.competencyTypes = cts.map(ct => {
          return {key: ct.key, ...ct.payload.val()};
        }).sort((a, b) => a.name > b.name ? 1 : -1);

        this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        this.theme.competencyTypes.sort((a,b) => this.ctNameMap.get(a).toLowerCase().localeCompare(this.ctNameMap.get(b).toLowerCase()));
        this.loading = false;
      })
    );
  }

  save() {
    this.authService.getUserUID().pipe(
      take(1),
    ).subscribe(uid => {
      this.theme.updatedBy = uid;
      this.exerciseService.updateTheme(this.key, this.theme);
      this.ref.close();
    });
  }

  delete(ct: any) {
    const index = this.theme.competencyTypes.findIndex(Tct => Tct === ct);
    if(index >= 0){
      this.theme.competencyTypes.splice(index, 1);
      this.ctMap.delete(ct);
    }
  }

  addCompetencyType() {
    if(!this.ctMap.has(this.selectedCT.key)){
      this.theme.competencyTypes.push(this.selectedCT.key);
      this.ctMap.set(this.selectedCT.key, true);
      this.theme.competencyTypes.sort((a,b) => this.ctNameMap.get(a).toLowerCase().localeCompare(this.ctNameMap.get(b).toLowerCase()));
    }
  }

  search(event: any) {
    this.output = this.filterService.filter(this.competencyTypes, ['name'], event.query, "contains");
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private deleteTheme() {
    this.confirmationService.confirm({
      key: 'eTheme',
      message: this.translateService.instant('WARNING.REMOVE'),
      accept: () => {
        this.exerciseService.deleteTheme(this.key);
        this.ref.close();
      }
    });
  }
}
