import {Component, OnDestroy, OnInit} from '@angular/core';
import {MenuItem, PrimeNGConfig, TreeNode} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subscription} from 'rxjs';
import {FileService} from '../../../services/service/file.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import {SelectedFileFilterPipe} from '../../../pipes/selected-file-filter.pipe';
import {Exercise} from '../../../model/exercise';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {FileData} from '../../../model/fileRelated';
import {TenantService} from '../../../services/service/tenant.service';
import {ExerciseFileUploadComponent} from '../exercise-file-upload/exercise-file-upload.component';
import {ExerciseService} from '../../../services/service/exercise.service';
import {FileStorageService} from '../../../services/service/file-storage.service';

@Component({
  selector: 'app-exercise-files',
  templateUrl: './exercise-files.component.html',
  styleUrls: ['./exercise-files.component.scss'],
  providers: [SelectedFileFilterPipe]
})
export class ExerciseFilesComponent implements OnInit, OnDestroy{
  menuItems: MenuItem[];
  stateOptions: any[];
  fileOptions: any[];
  selectedAction = 'attachedFiles';

  status = {tags: false, externalRelations: false, internalRelations: false, externalFiles: false, internalFiles: false};
  userStatus = new Map<string, boolean>();
  projectType: string;
  components: {roles: boolean, events: boolean, sharedFiles: boolean};

  subscription = new Subscription();

  exercise: Exercise;
  exerciseKey: string;
  exerciseFiles: any[] = [];

  fileTags = new Map<string, {key: string, createdById: string, tagName: string}>();
  sharedTags = new Map<string, {key: string, createdById: string, tagName: string}>();
  allFilesMap = new Map<string, FileData>();
  sharedFileMap = new Map<string, FileData>();

  parentChildMap = new Map<string, string[]>();
  commonParentChildMap = new Map<string, string[]>();
  tagFbidMap = new Map<string, string[]>();
  fileSelect = '';
  fileTree: [TreeNode[], TreeNode[], TreeNode[], TreeNode[]] = [[], [], [], []];

  selectedFiles: string[] = [];
  loading: boolean;

  exercises: any[] = [];

  exerciseFilesMap = new Map<string, string[]>();

  count = 0;

  fromInfo = false;
  constructor(
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService,
    private fileService: FileService,
    private fileStorageService: FileStorageService,
    private acService: AccessControlService,
    private selectedFileFilter: SelectedFileFilterPipe,
    private config: DynamicDialogConfig,
    private tenantService: TenantService,
    private dialogService: DialogService,
    private exerciseService: ExerciseService,

  ) {}


  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
    this.exercise = this.config.data.exercise;
    this.exerciseKey = this.config.data.key;
    if (this.config.data.fromInfo){
      this.fromInfo = this.config.data.fromInfo;
    }
    this.menuItems= [
      {   label: this.translateService.instant('FILE.UPLOAD_EXERCISE_FILE'),
        icon: 'far fa-cloud-upload',
        command: () => {
          this.uploadFile();
        }
      },
      {   label: this.translateService.instant('FILE.ADD_FILES'),
        icon: 'pi pi-plus',
        command: () => {
          this.selectedAction = 'addFiles';
        }
      },
      {   label: this.translateService.instant('FILE.ATTACHED_FILES'),
        icon: 'far fa-file',
        command: () => {
          this.selectedAction = '';
        }
      },

    ];

    this.subscription.add(
      combineLatest(
        [this.tenantService.getProjectType(), this.tenantService.getComponents(),
          this.acService.isSuperUser(), this.acService.isAdmin(), this.fileService.getAllFiles(),
          this.fileService.getFileTagRelations(), this.fileService.getSharedFiles(), this.fileService.getSharedFileTagRelations(),
          this.fileService.getFileTags(), this.fileService.getSharedFileTags(), this.exerciseService.getAllExercises(),
          this.exerciseService.getEFRelations(), this.fileService.getAllArchivedFiles()])
        .subscribe(
          ([type, components, isSuperUser, isAdmin, files, fileTagRelations, sharedFiles,
             sharedFileTagRelations, fileTags, sharedTags, exercises, EFRelations, archivedFiles]) => {
            exercises.map(exercise => {return {key: exercise.key, ...exercise.payload.val()};}).forEach(exercise => {
              if (exercise.exerciseFiles){
                this.exerciseFilesMap.set(exercise.key, exercise.exerciseFiles);
              }
            });

            this.projectType = type;
            this.components = components;
            this.userStatus.set('superUser', isSuperUser);
            this.userStatus.set('admin', isAdmin);

            this.fileOptions = [
              {name: this.translateService.instant("FILE.INTERNAL"), value: "internal"},
              {name: this.translateService.instant("FILE.ADD_FLEXIT_FILES"), value: "flexit"},
            ];

            this.allFilesMap = new Map(files.map(file => [file.key, file.payload.val()]));
            const archivedMap = new Map(archivedFiles.map(file => [file.key, file.payload.val()]));
            this.sharedFileMap = new Map(sharedFiles.map(file => [file.key, file.payload.val()]));
            // this.fileTags = new Map(fileTags.map(tag => [tag.key, tag.payload.val()]));
            fileTags.forEach(tag => {
              this.fileTags.set(tag.key, {...tag.payload.val()});
            });
            this.sharedTags = new Map(sharedTags.map(tag => [tag.key, tag.payload.val()]));

            const allFiles = new Map([...this.allFilesMap, ...this.sharedFileMap]);

            this.exerciseFiles = [];

            EFRelations.forEach(relation => {
              if (relation.payload.val().exerciseId === this.exercise.key) {
                if (this.allFilesMap.get(relation.payload.val().fileId)) {
                  this.exerciseFiles.push(
                    {
                      key: relation.payload.val().fileId,
                      file: this.allFilesMap.get(relation.payload.val().fileId)
                    }
                  );
                } else {
                  this.exerciseFiles.push(
                    {
                      key: relation.payload.val().fileId,
                      file: archivedMap.get(relation.payload.val().fileId)
                    }
                  );
                }
              }
            });
            this.parentChildMap = new Map<string, string[]>();
            fileTagRelations.forEach(relation => {
              if (this.parentChildMap.has(relation.payload.val().tagId)){
                this.parentChildMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
              } else {
                this.parentChildMap.set(relation.payload.val().tagId, [relation.payload.val().fileId]);
              }
            });

            this.commonParentChildMap = new Map<string, string[]>();
            sharedFileTagRelations.forEach(relation => {
              if (this.commonParentChildMap.has(relation.payload.val().tagId)){
                this.commonParentChildMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
                this.tagFbidMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
              } else {
                this.commonParentChildMap.set(relation.payload.val().tagId, [relation.payload.val().fileId]);
                this.tagFbidMap.set(relation.payload.val().tagId, [relation.payload.val().key]);
              }
            });

            if (this.exercise.exerciseFiles){
              if (!this.userStatus.get('admin') && !this.userStatus.get('superUser')) {
                this.exercise.exerciseFiles.forEach(key => {
                  if (!allFiles.get(key).admin) {
                    this.exerciseFiles.push({key: key, file: allFiles.get(key)});
                  }
                });
              } else {
                this.exercise.exerciseFiles.forEach(key => {
                  this.exerciseFiles.push({key: key, file: allFiles.get(key)});
                });
              }
            }
            this.fileTree = this.buildTree(this.parentChildMap, this.commonParentChildMap, this.allFilesMap,
              this.sharedFileMap, this.fileTags, this.translateService, this.userStatus.get('admin'), this.sharedTags);

            this.loading = false;

          })
    );
    this.stateOptions = [
      { label: this.translateService.instant('FILE.ATTACHED_FILES'), value: 'attachedFiles' },
      { label: this.translateService.instant('FILE.ADD_FILES'), value: 'addFiles' },
    ];
  }

  /**
   * Download / display file
   * @param fileObj FileData
   */
  openFile(fileObj) {
    if (fileObj.file){
      window.open(fileObj.file.url);
    } else {
      window.open(fileObj.url);
    }
  }

  /**
   * Upload file to exercise
   */
  uploadFile() {
    this.dialogService.open(ExerciseFileUploadComponent, {
      header: this.translateService.instant('FILE.UPLOAD_EXERCISE_FILE'),
      styleClass: 'max-size-sm-dialog',
    }).onClose.subscribe(data => {
      if (data) {
        this.exerciseFiles.push({key: data[0], file: data[1]});
        this.loading = true;
        if (this.exercise.exerciseFiles){
          if (!this.exercise.exerciseFiles.includes(data[0])) {
            this.exercise.exerciseFiles.push(data[0]);
          }
        } else {
          this.exercise.exerciseFiles = [data[0]];
        }
        this.exerciseService.updateExercise(this.exerciseKey, this.exercise);
      }
    });
  }

  /**
   * Delete file association & delete file IF the file is exercise specific and NOT in use in other exercises
   * @param obj Object {key: string, file: FileData}
   */
  deleteFile(obj: {key: string, file: FileData}) {
    if (obj.file.exerciseFile){
      let otherConnection = false;
      for (const [key, list] of this.exerciseFilesMap.entries()) {
        if (key !== this.exerciseKey){
          if (list.includes(obj.key)){
            otherConnection = true;
          }
        }
      }
      if (otherConnection){
        this.removeFileFromExercise(obj);
      } else {
        this.fileStorageService.deleteExerciseFile(obj.file.admin, obj.file.name);
        this.fileService.deleteFile(obj.key);
      }
    } else {
      this.removeFileFromExercise(obj);
    }
  }

  /**
   * Remove selected file from exercise
   * @param obj Object {key: string, file: FileData}
   */
  removeFileFromExercise(obj: {key: string, file: FileData}){
    const index = this.exercise.exerciseFiles.indexOf(obj.key);
    this.exercise.exerciseFiles.splice(index, 1);
    this.exerciseService.updateExercise(this.exerciseKey, this.exercise);
    const temp = [];
    this.exerciseFiles.forEach(element => {
      if (element.key !== obj.key){
        temp.push(element);
      }
    });
    this.exerciseFiles = temp;
  }

  /**
   * Add selected file to list.
   * @param $event File selected
   */
  nodeSelect($event: any) {
    if ($event.node.leaf){
      this.selectedFiles.push($event.node.key);
    } else {
      const children = $event.node.children;
      children.forEach(child => {
        if (child.leaf) {
          this.selectedFiles.push(child.key);
        } else{
          child.children.forEach(grandChild => {
            this.selectedFiles.push(grandChild.key);
          });
        }
      });
    }
  }

  /**
   * Remove unselected file key from list.
   * @param $event File selected
   */
  nodeUnselect($event: any) {
    if ($event.node.leaf){
      const index = this.selectedFiles.indexOf($event.node.key);
      this.selectedFiles.splice(index, 1);
    } else {
      $event.node.children.forEach(child => {
        if (child.leaf) {
          const index = this.selectedFiles.indexOf(child.key);
          this.selectedFiles.splice(index, 1);
        } else {
          child.children.forEach(grandChild => {
            const index = this.selectedFiles.indexOf(grandChild.key);
            this.selectedFiles.splice(index, 1);
          });
        }
      });
    }
  }

  /**
   * Add files to exercise (Removes duplicates)
   */
  addSelectedFiles() {

    if (this.exercise.exerciseFiles){
      this.exercise.exerciseFiles = this.exercise.exerciseFiles.concat(this.selectedFiles);
      this.exercise.exerciseFiles = [...new Set(this.exercise.exerciseFiles)];
    } else {
      this.exercise.exerciseFiles = this.selectedFiles;
    }
    this.exerciseService.updateExercise(this.exerciseKey, this.exercise);

    this.selectedFiles = [];
    this.selectedAction = 'attachedFiles';

  }

  /**
   * Build tree
   * @param parentChildMap        {tagId, [fileId_1, ..., fileId_n]} internal file relations.
   * @param publicParentChildMap  {tagId, [fileId_1, ..., fileId_n]} external file relations.
   * @param allFilesMap           {fileId, {file object}} file map with admin files.
   * @param sharedFileMap         {fileId, {file object}} file map shared flexitfire files.
   * @param fileTags              {tagId, {createdById, tagName}} tag map linking tagId and name.
   * @param translateS            TranslateService
   * @param isAdmin               Boolean
   * @param sharedTags            FlexitFire tags
   */
  buildTree(parentChildMap: Map<string, any[]>, publicParentChildMap: Map<string, any[]>,
            allFilesMap: Map<string, FileData>, sharedFileMap: Map<string, FileData>,
            fileTags: Map<string, {key: string, createdById: string, tagName: string}>,
            translateS, isAdmin, sharedTags) {

    let fileTree: [TreeNode[], TreeNode[], TreeNode[], TreeNode[]] = [[], [], [], []];

    if (parentChildMap.size > 0 && publicParentChildMap.size > 0 && allFilesMap.size > 0 &&
      sharedFileMap.size > 0 && fileTags.size > 0) {

      // User and admin files
      if (isAdmin) {
        for (const [key, files] of parentChildMap.entries()) {
          const tagNode: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: false,
            children: [] = [],
            key: key,
          };
          const admin: TreeNode = {
            label: translateS.instant('FILE.ADMIN_FILER'),
            leaf: false,
            selectable: false,
            children: [],
            parent: tagNode,
          };
          const tagNodeArchived: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: false,
            children: [] = [],
            key: key,
          };
          const adminArchived: TreeNode = {
            label: translateS.instant('FILE.ADMIN_FILER'),
            leaf: false,
            selectable: false,
            children: [],
            parent: tagNode,
          };
          files.forEach(fileId => {
            if (allFilesMap.get(fileId)) {
              if (!allFilesMap.get(fileId).archived && !allFilesMap.get(fileId).exerciseFile) {
                if (allFilesMap.get(fileId).admin) {
                  const fileNode1: TreeNode = {
                    label: allFilesMap.get(fileId).name,
                    data: allFilesMap.get(fileId),
                    leaf: true,
                    parent: admin,
                    key: fileId,
                  };
                  admin.children.push(fileNode1);
                } else {
                  const fileNode: TreeNode = {
                    label: allFilesMap.get(fileId).name,
                    data: allFilesMap.get(fileId),
                    leaf: true,
                    parent: tagNode,
                    key: fileId,
                  };
                  tagNode.children.push(fileNode);
                }
              } else {
                if (allFilesMap.get(fileId).admin) {
                  const fileNode1: TreeNode = {
                    label: allFilesMap.get(fileId).name,
                    data: allFilesMap.get(fileId),
                    leaf: true,
                    parent: adminArchived,
                    key: fileId,
                  };
                  adminArchived.children.push(fileNode1);
                } else {
                  const fileNode: TreeNode = {
                    label: allFilesMap.get(fileId).name,
                    data: allFilesMap.get(fileId),
                    leaf: true,
                    parent: tagNodeArchived,
                    key: fileId,
                  };
                  tagNodeArchived.children.push(fileNode);
                }
              }
            }
          });

          if (admin.children.length > 0) {
            tagNode.children.push(admin);
          }
          if (tagNode.children.length > 0) {
            fileTree[0].push(tagNode);
            fileTree[1].push(tagNode);
          }

          if (adminArchived.children.length > 0) {
            tagNodeArchived.children.push(adminArchived);
          }
          if (tagNodeArchived.children.length > 0) {
            fileTree[3].push(tagNodeArchived);
          }
        }
      }
      // User files
      else {
        for (const [key, files] of parentChildMap.entries()) {
          const tagNode1: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: false,
            children: [] = [],
            key: key,
          };
          const tagNode2: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: true,
            children: [] = [],
            key: key,
          };
          files.forEach(fileId => {
            if (!allFilesMap.get(fileId).admin && !allFilesMap.get(fileId).exerciseFile && !allFilesMap.get(fileId).archived) {
              const fileNode1: TreeNode = {
                label: allFilesMap.get(fileId).name,
                data: allFilesMap.get(fileId),
                leaf: true,
                parent: tagNode1,
                key: fileId,
              };
              const fileNode2: TreeNode = {
                label: allFilesMap.get(fileId).name,
                data: allFilesMap.get(fileId),
                leaf: true,
                parent: tagNode2,
                key: fileId,
              };
              tagNode1.children.push(fileNode1);
              tagNode2.children.push(fileNode2);
            }
          });
          fileTree[0].push(tagNode1);
          fileTree[1].push(tagNode2);
        }
      }

      // Flexit fire files
      for (const [key, files] of publicParentChildMap.entries()) {
        const tagNode: TreeNode = {
          label: sharedTags.get(key).tagName,
          leaf: false,
          selectable: false,
          children: [] = [],
          key: key,
        };
        files.forEach(fileId => {
          const fileNode: TreeNode = {
            label: sharedFileMap.get(fileId).name,
            data: sharedFileMap.get(fileId),
            leaf: true,
            parent: tagNode,
            key: fileId,
          };
          if (!sharedFileMap.get(fileId).archived) {
            tagNode.children.push(fileNode);
          }
        });

        if (tagNode.children.length > 0) {
          fileTree[2].push(tagNode);
        }
      }
      // Sort trees by name
      fileTree.forEach(tree => {
        tree.sort((a, b) =>{
          const nameA = a['label'].toLowerCase(); // ignore upper and lowercase
          const nameB = b['label'].toLowerCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      });
      if (fileTree[0].length > 0){
        this.fileSelect = "internal";
      }
      return fileTree;
    } else {
      fileTree = [[], [], [], []];
      return fileTree;
    }
  }

  /**
   * Unsubscribe on exit.
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
