import { Injectable } from '@angular/core';
import {finalize, mergeMap} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {TranslateService} from '@ngx-translate/core';
import {FileData} from '../../model/fileRelated';
import {FileService} from './file.service';
import { getStorage, ref, deleteObject } from "firebase/storage";

@Injectable({
  providedIn: 'root'
})
export class FileStorageService {

  tenantId$: Observable<any>;
  tenantId: string;
  defaultLang: string;
  subscription = new Subscription();

  constructor(
      private tenantService: TenantService,
      private db: AngularFireDatabase,
      private storage: AngularFireStorage,
      private translateService: TranslateService,
      private fileService: FileService,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
    this.subscription.add(
        this.tenantService.getDefaultLang().subscribe(defaultLang => this.defaultLang = defaultLang)
    );
    this.subscription.add(
        this.tenantService.getTenantId().subscribe(tenantId => this.tenantId = tenantId)
    );

  }

  /**
   * Check if file already exist.
   * @param filename File name.
   */
  checkIfFileExist(filename: string){
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/files/${filename}`).valueChanges()
        )
    );
  }

  /**
   * Upload new file to firebase storage
   * @param upload File to be uploaded
   * @param admin Admin only file
   * @param fileData File JSON object to be added to DB
   */
  uploadNewFile(upload: File, admin: boolean, fileData: FileData){
    let basePath;
    if(this.tenantId === "-LYlXo4tUqlK7XiC6fGO"){
      basePath = "/storage/shared";
    } else {
      if (!this.defaultLang){
        this.defaultLang = 'nb';
      }
      if(admin){
        basePath = this.tenantId + "/admin/";
      } else {
        basePath = this.tenantId + "/shared/";
      }
    }
    const filePath = basePath + upload.name;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, upload);
    uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            fileData.url = downloadURL;
            fileData.archived = false;
            console.log(fileData);
            this.fileService.addFile(fileData);
          });
        })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  /**
   * Upload new exercise file to firebase storage
   * @param upload File to be uploaded
   * @param admin Admin only file
   * @param fileData File JSON object to be added to DB
   */
  uploadNewExerciseFile(upload: File, admin: boolean, fileData: FileData) {
    let basePath;
    if (!this.defaultLang){
      this.defaultLang = 'nb';
    }
    if(admin){
      basePath = this.tenantId + "/exercise/admin/";
    } else {
      basePath = this.tenantId + "/exercise/shared/";
    }
    const filePath = basePath + upload.name;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, upload);
    uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            fileData.url = downloadURL;
            this.fileService.addFile(fileData);
          });
        })
    ).subscribe();
    return uploadTask.percentageChanges();
  }

  /**
   * Delete file from /exercise/ folder
   * @param admin Boolean
   * @param filename filename
   */
  deleteExerciseFile(admin: boolean, filename: string) {
    let basePath;
    if (!this.defaultLang){
      this.defaultLang = 'nb';
    }
    if (admin) {
      basePath = this.tenantId + "/exercise/admin/";
    } else {
      basePath = this.tenantId + "/exercise/shared/";
    }
    const filePath = basePath + filename;
    const deleteRef = ref(getStorage(), filePath);
    deleteObject(deleteRef).then(() => console.log("Success")).catch((Error) => console.log(Error));
  }

  /**
   * Delete file from /exercise/ folder
   * @param admin Boolean
   * @param filename filename
   */
  deleteFile(admin: boolean, filename: string){
    let basePath;
    if(this.tenantId === "-LYlXo4tUqlK7XiC6fGO"){
      basePath = "/storage/shared";
    } else {
      if (!this.defaultLang){
        this.defaultLang = 'nb';
      }
      if(admin){
        basePath = this.tenantId + "/admin/";
      } else {
        basePath = this.tenantId + "/shared/";
      }
    }
    const filePath = basePath + filename;
    const deleteRef = ref(getStorage(), filePath);
    deleteObject(deleteRef).then(() => console.log("Success")).catch((Error) => console.log(Error));
  }
}
