import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {CompetencyType} from "../../../model/competencyType";
import {AccessControlService} from "../../../services/service/access-control.service";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {AttestationService} from "../../../services/service/attestation.service";
import {FileService} from "../../../services/service/file.service";
import {FileTag} from "../../../model/fileRelated";

@Component({
  selector: 'app-edit-competency-type',
  templateUrl: './edit-competency-type.component.html',
  styleUrls: ['./edit-competency-type.component.scss'],
  providers: [ConfirmationService]
})
export class EditCompetencyTypeComponent implements OnInit {
  competencyType: CompetencyType;
  newCT: boolean;

  splitItems = [];

  documentationTypes: any[];
  docType: string;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private translateService: TranslateService,
    private ctService: CompetencyTypeService,
    private acService: AccessControlService,
    private authService: AuthService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private attestationService: AttestationService,
    private fileService: FileService,
  ) {
    this.competencyType = JSON.parse(JSON.stringify(this.config.data.competencyType));
    this.newCT = JSON.stringify(this.competencyType) === '{}';
  }

  ngOnInit(): void {
    this.splitItems = [
      {
        label: this.translateService.instant('BUTTON.ARCHIVE'), icon: 'far fa-archive',
        command: () => {
          this.archive();
        }
      },
      {separator: true},
      {
        label: this.translateService.instant('BUTTON.REMOVE'), icon: 'pi pi-trash',
        command: () => {
          this.delete();
        }
      }
    ];

    this.documentationTypes = [
      { value: 'LICENCE', label: this.translateService.instant("SUBJECTS.DOC_TYPES.LICENCE")},
      { value: 'EDUCATION', label: this.translateService.instant("SUBJECTS.DOC_TYPES.EDUCATION")},
      { value: 'CERTIFICATE', label: this.translateService.instant("SUBJECTS.DOC_TYPES.CERTIFICATE")},
      { value: 'COURSE', label: this.translateService.instant("SUBJECTS.DOC_TYPES.COURSE")},
      { value: 'TOOLS', label: this.translateService.instant("ASSET.ASSET")},
      { value: 'EXERCISE_EFFORT', label: this.translateService.instant("SUBJECTS.DOC_TYPES.EXERCISE_EFFORT")},
      { value: 'EXERCISE', label: this.translateService.instant("EXERCISE.EXERCISE")},
      { value: 'EXERCISE_SKILL', label: this.translateService.instant("SUBJECTS.DOC_TYPES.EXERCISE_SKILL")},
      { value: 'CHECKOUT', label: this.translateService.instant("SUBJECTS.DOC_TYPES.CHECKOUT")},
      { value: 'TRAINING', label: this.translateService.instant("SUBJECTS.DOC_TYPES.TRAINING")},
    ].sort((a, b) => {
      const nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  public save() {
    if (this.ctGuard()) {
      this.confirmationService.confirm({
        message: this.translateService.instant('CONFIRMATION.CREATE_FILECATEGORY'),
        accept: () => {
          this.authService.getUserUID()
            .pipe(take(1))
            .subscribe(uid => {

              const tag: FileTag = {
                createdById: uid,
                tagName: this.competencyType.name
              };

              // Existing CT
              if (this.competencyType.key) {
                const ctId = this.competencyType.key;
                this.fileService.addCTTag(ctId, tag);
                delete this.competencyType.key;
                this.competencyType.updatedById = uid;
                this.ctService.updateCompetencyType(ctId, this.competencyType);
              }
              // New CT
              else {
                this.competencyType.createdById = uid;
                this.ctService.addCompetencyTypeGetKey(this.competencyType).pipe(take(1)).subscribe(key => {
                  this.fileService.addCTTag(key, tag);
                });
              }
              this.ref.close();
            });
        },
        reject: () => {
          this.authService.getUserUID()
            .pipe(take(1))
            .subscribe(uid => {
              // Existing CT
              if (this.competencyType.key) {
                const ctId = this.competencyType.key;
                delete this.competencyType.key;
                this.competencyType.updatedById = uid;
                this.ctService.updateCompetencyType(ctId, this.competencyType);
              }
              // New CT
              else {
                this.competencyType.createdById = uid;
                this.ctService.addCompetencyType(this.competencyType);
              }
              this.ref.close();
            });
        }
      });

    } else {
      this.messageService.add(
        {
          key: 'ct', severity: 'error',
          detail: this.translateService.instant('ERROR.INVALID_VALUES'), life: 4000
        }
      );
    }
  }

  public close() {
    this.ref.close();
  }

  checkName() {
    if (!this.competencyType.name && !this.newCT) {
      return {'border-color': '#D0021B'};
    }
  }

  checkValue(number: number) {
    if (number < 0) {
      return {'border-color': '#D0021B'};
    }
  }

  private archive() {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.ARE_YOU_SURE_ARCHIVE'),
      accept: () => {
        this.authService.getUserUID()
          .pipe(take(1))
          .subscribe(uid => {

            const ctId = this.competencyType.key;
            delete this.competencyType.key;

            this.competencyType.archivedDate = new Date().toISOString();
            this.competencyType.archived = true;
            this.competencyType.archivedById = uid;

            this.ctService.updateCompetencyType(ctId, this.competencyType);

            this.ref.close();
          });
      }
    });

  }

  private ctGuard() {
    // Check if CT has been named
    if (!this.competencyType.name) {
      return false;
    }
    else if (!this.competencyType.type || this.competencyType.type === ""){
      return false;
    }
    // Check if timeToExpire exists then its value is greater than 0
    else if (this.competencyType.timeToExpire && this.competencyType.timeToExpire < 0) {
      return false;
    }
    // Check if defaultValidity exists then its value is greater than 0
    else {return !(this.competencyType.defaultValidity && this.competencyType.defaultValidity < 0);}
  }

  private delete() {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE'),
      accept: () => {
        this.attestationService.getAttestationsByCompetencyTypeId(this.competencyType.key)
          .pipe(
            take(1),
          ).subscribe(attestations => {
            if(attestations.length === 0){
              this.ctService.deleteCompetencyType(this.competencyType.key);
              this.ref.close();
            } else {
              this.messageService.add(
                {
                  key: 'ct', severity: 'error',
                  detail: this.translateService.instant('WARNING.CANNOT_DELETE'), life: 4000
                }
              );
            }
          }
        );
      }
    });

  }
}
