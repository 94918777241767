<!-- ConfirmDialog -->
<p-confirmDialog key="edit-participant" [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12" *ngIf="!acRole?.user">
    <div class="p-buttonset float-right">
      <button pButton type="button" class="p-button-danger p-button-raised" (click)="delete()" icon="pi pi-trash"
              label="{{'BUTTON.REMOVE' | translate}}"></button>
      <button pButton type="button" class="p-button-raised" icon="pi pi-bookmark" (click)="save()"
              label="{{'BUTTON.SAVE' | translate}}"></button>
    </div>
  </div>

  <div class="col-12 p-0">
    <p-table [loading]="!ctNameMap" [value]="participantCompetencyTypes" breakpoint="200px">
      <ng-template pTemplate="caption" *ngIf="!acRole?.user">
        <div class="grid">
          <div class="col-12">
            <p-autoComplete [suggestions]="output | removeExistingFilter: pCompetencyTypeMap"
                            [(ngModel)]="selectedCompetencyType"
                            (onSelect)="addCompetencyType()"
                            placeholder="{{'COMPETENCY.ADD_COMPETENCY_TYPE' | translate}}"
                            [dropdown]="true"
                            (completeMethod)="search($event)"
                            field="name"
                            size="100%"
                            type="search"
                            class="inputfield w-full"
                            appendTo="body"
                            emptyMessage="{{'EVENT.NO_RESULT' | translate}}"
                            [forceSelection]="true"
            >
              <ng-template let-ct pTemplate="item">
                <div class="ct-item">
                  {{ct.name}}
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 85%">{{'COMMON.NAME' | translate}}</th>
          <th *ngIf="!acRole?.user"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-competencyType>
        <tr>
          <td>{{ctNameMap.get(competencyType.key)}}</td>
          <td *ngIf="!acRole?.user" (click)="remove(competencyType.key)"><i style="color: #D0021B" class="pi pi-trash"></i></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'COMMON.NO_COMPETENCY' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
