<div class="grid">

  <div class="col-8 flex flex-wrap align-content-center">
    <h2 style="margin-top: 10px">{{'ROLES.ROLES' | translate}}</h2>
  </div>
  <div class="col-4 flex justify-content-end align-content-center" *ngIf="isAdmin$">
    <button pButton type="button" icon="pi pi-plus"
            class="p-button-rounded p-button-raised"
            (click)="addRole()"
    ></button>
  </div>

  <div class="col-12" *ngIf="isAdmin$ || isSuper$; else elseBlock">
    <p-table [value]="tableRoles | rolesPersonFilter: personNameMap : search"
             dataKey="key" (sortFunction)="customSort($event)" [customSort]="true" [scrollable]="true"
    >
      <ng-template pTemplate="caption">
        <div class="grid mt-1">

          <ng-container *ngIf="screenWidth(); else elseBlock">
            <div class="col-12">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" [(ngModel)]="search" class="inputfield w-full"
                     placeholder="{{'COMMON.SEARCH_PERSON' | translate}}" size="100%"
              />
            </span>
            </div>

            <div class="col-12">
              <p-dropdown [options]="groupedFilter" [(ngModel)]="selectedTeam"
                          (onChange)="filterRoles()"
                          optionLabel="label" optionValue="value"
                          [group]="true" [style]="{'width': '100%'}"
              >
                <ng-template let-station pTemplate="item">
                  <div class="flex align-items-center p-2" (click)="setStationTeam(station)">
                    <i class="far fa-warehouse"></i>&nbsp;{{station.label}}
                  </div>
                </ng-template>
                <ng-template let-team pTemplate="item">
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{team.label}}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <div class="col-12">
              <p-dropdown [options]="years" [(ngModel)]="selectedYear" (onChange)="filterRoles()"
                          optionLabel="YYYY" optionValue="YYYY" class="inputfield w-full" [style]="{'width' : '100%'}">
              </p-dropdown>
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <div class="col-5">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" [(ngModel)]="search" class="inputfield w-full"
                     placeholder="{{'COMMON.SEARCH_PERSON' | translate}}" size="100%"
              />
            </span>
            </div>

            <div class="col-4">
              <p-dropdown [options]="groupedFilter" [(ngModel)]="selectedTeam"
                          (onChange)="filterRoles()"
                          optionLabel="label" optionValue="value"
                          [group]="true" [style]="{'width': '100%'}"
              >
                <ng-template let-station pTemplate="item">
                  <div class="flex align-items-center p-2" (click)="setStationTeam(station)">
                    {{station.label}}
                  </div>
                </ng-template>
                <ng-template let-team pTemplate="item">
                  <div>
                    &nbsp;&nbsp;&nbsp;{{team.label}}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <div class="col-3">
              <p-dropdown [options]="years" [(ngModel)]="selectedYear" (onChange)="filterRoles()"
                          optionLabel="YYYY" optionValue="YYYY" class="inputfield w-full" [style]="{'width' : '100%'}">
              </p-dropdown>
            </div>
          </ng-template>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">{{'ROLES.ROLE' | translate}}<p-sortIcon field="name"></p-sortIcon></th>
          <th>{{'FDC.PERSON' | translate}}</th>
          <th style="size: 3rem">{{'COMMON.DATE.DATE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-role>
        <tr>
          <td>{{getRoleType(role)}}</td>
          <td>{{getPersonName(role)}}</td>
          <td>{{role.date}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'ROLES.NO_ROLES' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template #elseBlock>
    <div class="col-12">
      <p-table [value]="tableRoles | rolesPersonFilter: personNameMap : search "
               dataKey="key" (sortFunction)="customSort($event)" [customSort]="true" [scrollable]="true"
      >
        <ng-template pTemplate="caption">
          <div class="grid flex flex-wrap justify-content-start mt-1">
            <p-dropdown [options]="years" [(ngModel)]="selectedYear" (onChange)="filterRoles()"
                        optionLabel="YYYY" optionValue="YYYY" class="inputfield w-full" [style]="{'width' : '100%'}">
            </p-dropdown>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">{{'ROLES.ROLE' | translate}}<p-sortIcon field="name"></p-sortIcon></th>
            <th>{{'FDC.PERSON' | translate}}</th>
            <th style="size: 3rem">{{'COMMON.DATE.DATE' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-role>
          <tr>
            <td>{{getRoleType(role)}}</td>
            <td>{{getPersonName(role)}}</td>
            <td>{{role.date}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">{{'ROLES.NO_ROLES' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</div>
