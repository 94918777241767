import { Component, OnInit } from '@angular/core';
import {ChecklistItem} from "../../../model/checklist";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ChecklistService} from "../../../services/service/checklist.service";

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.scss']
})
export class AddNewItemComponent implements OnInit {

  item: ChecklistItem = {
    name: '',
    checked: false,
    comment: '',
    pause: false,
  };

  checklistId: string;
  checklistItemId: string;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private checklistService: ChecklistService,
  ) {
    this.checklistId = this.config.data.checklistId;
    if (this.config.data.element){
      this.item = this.config.data.element.item;
      this.checklistItemId = this.config.data.element.key;
    }
  }

  ngOnInit(): void {}

  close(){
    this.ref.close();
  }
  /**
   * Save item to checklist
   * @param item ChecklistItem
   */
  save(item: ChecklistItem) {
    if(item.name.length > 1){
      if (this.config.data.element){
        this.checklistService.updateChecklistItem(this.checklistId, this.checklistItemId, this.item);
      } else {
        this.checklistService.addChecklistItem(this.checklistId, item);
      }
      this.close();
    }
  }
}
