import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {map, mergeMap, publish} from 'rxjs/operators';
import {EmergencyResponse} from '../../model/emergencyResponse';
import {FilterService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class EmergencyService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private filterService: FilterService,
    private db: AngularFireDatabase
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get all emergencies
   */
  getEmergencies(): Observable<any[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/events/emergencyResponseEvents`).snapshotChanges()
      )
    );
  }

  /**
   * Get all emergencies
   */
  getEmergenciesForReport(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/events/emergencyResponseEvents`).snapshotChanges()
      )
    );
  }
  /**
   * Get emergencies by date
   */
  getEmergenciesFromDate(date): Observable<any[]> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/events/emergencyResponseEvents`, ref => {
              return ref.orderByChild('date').equalTo(date);
            }).snapshotChanges()
        )
    );
  }
  /**
   * Get specific emergency event
   * @param key Unique emergency ID
   */
  getEmergency(key: string): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/events/emergencyResponseEvents/${key}`).valueChanges()
      )
    );
  }

  /**
   * Update existing emergency
   * @param key Emergency to update
   * @param emergency Emergency Interface
   */
  updateEmergency(key, emergency: EmergencyResponse) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/events/emergencyResponseEvents/${key}`).set(emergency)
      )
    )).connect();
  }

  /**
   * Get event types
   */
  getTypes(): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/events/types`, ref => {
          return ref.orderByChild('type').equalTo('emergency');
        }).snapshotChanges()
      )
    );
  }

  /**
   * Add new emergency type
   * @param emergencyType type object
   */
  addType(emergencyType: { name: string; type: string }) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.list(`tenants/${tenantId}/events/types`).push(emergencyType)
        )
    )).connect();
  }

  updateType(key, emergencyType: { archived: boolean; name: any; type: string }) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/events/types/${key}`).set(emergencyType)
        )
    )).connect();
  }
  /**
   * Add new emergency
   * @param emergency Emergency data
   */
  addEmergency(emergency: EmergencyResponse) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/events/emergencyResponseEvents`).push(emergency)
      )
    )).connect();
  }

  /**
   * Add new emergency and return key
   * @param emergency Emergency data
   */
  addEmergencyGetKey(emergency: EmergencyResponse): Observable<string>{
    return this.tenantId$.pipe(
      map(tenantId => {
        const ref = this.db.list(`tenants/${tenantId}/events/emergencyResponseEvents`).push(emergency);
        return ref.key;
      })
    );
  }

  /**
   * Count Exposures
   * @param personId Unique person ID
   * @param personExposureMap Map of exposed persons
   */
  exposureCounter(personId: string, personExposureMap: Map<any, any>){
    if (personExposureMap.has(personId)){
      return personExposureMap.get(personId).length;
    }else {
      return 0;
    }
  }

  /**
   * Filter person by name
   * @param event Search event
   * @param outputPerson Output person list
   * @param persons Persons list
   */
  filterPerson(event: any, outputPerson: string[], persons: string[]) {
    const query = event.query;
    outputPerson = this.filterService.filter(persons, ['name'], query, 'contains');
    return outputPerson;
  }
}
