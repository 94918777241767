import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {combineLatest, Subscription} from "rxjs";
import {ExerciseService} from "../../../services/service/exercise.service";
import {FilterService} from "primeng/api";
import {PersonService} from "../../../services/service/person.service";
import {SubjectService} from "../../../services/service/subject.service";
import {Exercise} from "../../../model/exercise";
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';


@Component({
  selector: 'app-add-participants',
  templateUrl: './add-participants.component.html',
  styleUrls: ['./add-participants.component.scss'],
  providers: [DialogService]
})
export class AddParticipantsComponent implements OnInit, OnDestroy {

  eKey;

  exercise: Exercise;
  participantsMap = new Map();
  output = [];
  selectedSubject: any;
  groupedSubjectFilter = [];
  personNameMap = new Map();
  teamNameMap = new Map();
  parentChildMap = new Map();
  personParentNameMap = new Map();

  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public exerciseService: ExerciseService,
    private filterService: FilterService,
    private personService: PersonService,
    private subjectService: SubjectService,
    private gSService: GroupedSubjectsService,
  ) {
    this.eKey = this.config.data.eKey;

    this.subscription.add(
      this.exerciseService.getExercise(this.eKey)
        .subscribe(exercise => {
          this.exercise = exercise;
          if(this.exercise.participants){
            this.participantsMap = new Map(this.exercise.participants.map(participant => [participant.key, true]));
          } else {
            this.exercise.participants = [];
          }
        })
    );

    this.subscription.add(
      combineLatest([
        this.personService.getPersons(),
        this.subjectService.getTeams(),
        this.subjectService.getRelations(),
      ]).subscribe(([persons, teams, relations]) => {

        const data = this.gSService.build(persons, teams, relations);
        this.personNameMap = data[0];
        this.teamNameMap = data[1];
        this.personParentNameMap = data[2];
        this.groupedSubjectFilter = data[3];
      })

    );
  }

  ngOnInit(): void {
  }

  addPerson(person: any) {
    const participant: any = {};
    if(this.exercise.competencyTypes){
      participant.competencyTypes = this.exercise.competencyTypes;
    } else {
      participant.competencyTypes = {};
    }
    participant.key = person.value;
    this.exercise.participants.push(participant);
    this.participantsMap.set(participant.key, true);
  }

  search(event: any) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.output = filteredGroups;
  }

  buildFilter(subject) {
    return {
      label: this.personNameMap.get(subject),
      value: subject,
    };
  }

  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  getSize(competencyTypes: any) {
    return Object.keys(competencyTypes).length;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save() {
    this.ref.close(this.exercise.participants);
  }

  remove(participant: any) {
    const index = this.exercise.participants.findIndex(par => par.key === participant.key);
    if(index >= 0) {
      this.exercise.participants.splice(index, 1);
      this.participantsMap.delete(participant.key);
    }
  }
}
