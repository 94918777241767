import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../services/service/auth.service";
import {Router} from "@angular/router";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, MessageService} from "primeng/api";
import {AccessControlService} from "../../services/service/access-control.service";
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  email;
  resetEmail;
  password;
  display = false;
  menuItems: MenuItem[];
  languageSubscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private acService: AccessControlService
  ) {
  }

  ngOnInit() {

    /** Build onInit **/
    this.menuItems = this.buildLoginMenu();

    /** To use I18N on PrimeNG panelMenu we have to rebuild MenuItem on language change **/
    this.languageSubscription =
      this.translateService.onLangChange.subscribe(() => {
        this.menuItems = this.buildLoginMenu();
      });
  }

  /**
   * Method to build Menu on language change
   */
  buildLoginMenu(): MenuItem[] {

    if (!this.translateService.currentLang) {
      this.translateService.use(this.translateService.getDefaultLang());
    }

    return [
      {
        /**
         * What language is current in use
         */
        label: this.translateService.instant('LOGIN.GET_LANG'),

        /**
         * Select language to use.
         */
        items: [
          {
            label: 'Norsk', command: () => {
              this.changeLanguage('nb');
            }
          },
          {
            label: 'English', command: () => {
              this.changeLanguage('en');
            }
          },
          {
            label: 'Íslenska', command: () => {
              this.changeLanguage('is');
            }
          },
          {
            label: 'Dansk', command: () => {
              this.changeLanguage('da');
            }
          },
          {
            label: 'Svenska', command: () => {
              this.changeLanguage('sv');
            }
          },
          {
            label: 'Suomi', command: () => {
              this.changeLanguage('fi');
            }
          },
        ]
      },
      {
        /**
         * Help
         */
        label: this.translateService.instant('MENU.HELP'),
        command: () => {
          this.navigateToHelpPage();
        }
      },
    ];
  }

  /**
   * Show reset password modal
   *
   * @param bool, show or hide
   */
  showDialog(bool) {
    this.display = bool;
    if (bool === true && this.email) {
      this.resetEmail = this.email;
    } else {
      this.resetEmail = '';
    }
  }

  /**
   * Check if user is valid and login or reject.
   *
   * @param login: Credentials (email, password)
   */
  login(login) {
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 5000);

    this.authService.login(login.email, login.password)
      .then(() => {
        this.authService.getUserUID()
          .pipe(
            take(1),
          ).subscribe(uid => {
          this.acService.isDisabled(uid)
            .pipe(
              take(1),
            ).subscribe(isDisabled => {
            if (isDisabled) {
              this.messageService.add(
                {key: 'login', severity: 'warn', detail: this.translateService.instant('WARNING.THIS_USER_IS_DISABLED'), life: 4000});
              this.ngxService.stop();
            } else {
              this.router.navigate([''], {replaceUrl: true})
                .then(() => {
                  this.ngxService.stop();
                });
            }
          });
        });
      })
      .catch(() => {
        this.ngxService.stop();
        this.messageService.add({key: 'login', severity: 'error', detail: this.translateService.instant('LOGIN.WRONG'), life: 3000});
      });
  }

  /**
   * Send reset password mail if valid email.
   *
   * @param email: Email input
   */
  resetPassword(email) {
    this.authService.resetPassword(email)
      .then(() => {
        this.messageService.add(
          {key: 'login', severity: 'info', detail: this.translateService.instant('LOGIN.RESET_CONFIRM'), life: 4000});
      })
      .catch(() => {
        this.messageService.add(
          {key: 'login', severity: 'info', detail: this.translateService.instant('LOGIN.RESET_CONFIRM'), life: 4000});
      });
    this.showDialog(false);
  }

  /**
   * Changes current lang used
   *
   * @param lang: What lang to change to, short form (nb, en , .. ,)
   */
  changeLanguage(lang) {
    this.translateService.use(lang);
  }

  /**
   * If help button is pressed -> Navigate to help page
   */
  navigateToHelpPage() {
    window.open('https://flexitefiremanual-stage.firebaseapp.com/');
  }

  /**
   * Unsubscribe and clear messageService stack for 'login'
   */
  ngOnDestroy(): void {
    this.messageService.clear('login');
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }
}
