<div class="grid">
  <div class="col-12">
    <p-table [value]="participantList" selectionMode="multiple"
             [(selection)]="selectedParticipants" dataKey="privateCell"
             [scrollable]="true" scrollHeight="{{getScrollHeight()}}px"
    >
      <ng-template pTemplate="header">
        <tr [pSelectableRow]="participantList">
          <th>{{'COMMON.NAME' | translate}}</th>
          <th>{{'SUBJECTS.CELLPHONE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-person let-rowIndex="rowIndex">
        <tr [pSelectableRow]="person" [pSelectableRowIndex]="rowIndex">
          <td>{{person.name}}</td>
          <td>{{person.privateCell}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-12 mt-2">
     <span class="p-float-label">
        <textarea class="w-full" id="float-input" rows="5" cols="30" pInputTextarea [(ngModel)]="message"></textarea>
        <label for="float-input">{{'EXERCISE.MESSAGE' | translate}}</label>
      </span>
  </div>
  <div class="row">
    <div class="col">
      <span>{{"EXERCISE.SMS_LENGTH" | translate}}: <b>{{message.length}}</b></span>
      <span style="margin-left: 20px">{{"EXERCISE.RECIPIENTS" | translate}}: <b>{{selectedParticipants?.length}}</b></span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <span>{{"EXERCISE.TOTAL_SMS" | translate}}: <b>{{numberOfSms(message?.length)}}</b></span>
      <span style="margin-left: 20px">{{"EXERCISE.REMAINING_SMS" |translate}}: <b>{{availableSMS}}</b></span>
    </div>
  </div>
  <div class="col-12 flex justify-content-end">
    <button label="{{'SMS.SEND' | translate}}"
            icon="pi pi-check" iconPos="right"
            class="p-button-raised" pButton
            [disabled]="enableDisable()"
            (click)="send()"
    ></button>
  </div>
</div>
