import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Subscription} from "rxjs";
import {PersonService} from "../../../services/service/person.service";
import {FunctionService} from "../../../services/service/function.service";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {take} from "rxjs/operators";

const moment = require('moment');

@Component({
  selector: 'app-person-detail',
  templateUrl: './function-person-detail.component.html',
  styleUrls: ['./function-person-detail.component.scss']
})
export class FunctionPersonDetailComponent implements OnInit, OnDestroy {
  personKey;
  functionKey;

  ctNameMap = new Map();
  ctStatusMap = new Map();
  ctValidMap = new Map();

  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private personService: PersonService,
    private functionService: FunctionService,
    private ctService: CompetencyTypeService,
  ) {
    this.functionKey = this.config.data.fKey;
    this.personKey = this.config.data.pKey;

    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
      })
    );
    this.functionService.getFunction(this.functionKey)
      .pipe(take(1))
      .subscribe(f => {
        this.ctStatusMap = new Map(Object.entries(f.subjects[this.personKey].competencyTypes).map(ct => [ct[0], ct[1]]));
      });

    this.subscription.add(
      this.functionService.getFunctionCompetencyTypes(this.functionKey).subscribe(cts => {
        this.ctValidMap = new Map(Object.entries(cts).map(ct => [ct[0], ct[1].validity]));
      })
    );
    this.subscription.add(
      this.functionService.getPersonCompetencyOnUpdate(this.functionKey, this.personKey).subscribe(cSnap => {
        this.ctStatusMap.set(cSnap.key, cSnap.payload.val());
      })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Expired: The competency has expired
   * Valid: The competency has no repeat and is valid
   * Repeat_Valid: The competency has repeat and is valid
   * Repeat_Expired: The competency has repeat and is not valid.
   * Missing: The competency has no attestations
   * Expiring: The competency is expiring but is still valid
   * Waiting: The person is waiting for functions to update its status
   *
   * @param status
   */
  getStatus(status: any) {

    if (status === 'EXPIRED') {
      return {'color': '#D0021B'};
    } else if (status === 'VALID' || status === 'REPEAT_VALID') {
      return {'color': '#73BF21'};
    } else if (status === 'REPEAT_EXPIRING') {
      return {'color': '#F5A623'};
    }else if (status === 'REPEAT_EXPIRED') {
      return {'color': '#D0021B'};
    } else if (status === 'MISSING') {
      return {'color': '#4A90E2'};
    } else if (status === 'EXPIRING') {
      return {'color': '#F5A623'};
    } else if (status === 'WAITING') {
      return {'color': '#808080'};
    }
  }

  getIcon(status: any) {
    if (status === 'EXPIRED') {
      return 'pi pi-times float-right mr-2';
    } else if (status === 'VALID') {
      return 'pi pi-check float-right mr-2';
    } else if (status === 'REPEAT_VALID') {
      return 'pi pi-refresh float-right mr-2';
    } else if (status === 'REPEAT_EXPIRING') {
      return 'pi pi-replay float-right mr-2';
    } else if (status === 'REPEAT_EXPIRED') {
      return 'pi pi-replay float-right mr-2';
    } else if (status === 'MISSING') {
      return 'far fa-times-circle float-right mr-2';
    } else if (status === 'EXPIRING') {
      return 'pi pi-check float-right mr-2';
    } else if (status === 'WAITING') {
      return 'pi pi-question float-right mr-2';
    }
  }

  getRepeatDate(key) {
    const lastRepeat = moment(this.ctStatusMap.get(key).lastRepeatDate);
    const validity = this.ctValidMap.get(key);
    return lastRepeat.add(validity, 'months').format('DD.MM.YY');
  }
}
