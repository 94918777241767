<div class="grid">
  <div class="col-12 field">
    <input id="float-input" class="inputfield w-full" type="text" [readonly]="isObserver || readOnly" pInputText [(ngModel)]="name" placeholder="{{'COMMON.NAME' | translate}}*">
  </div>

  <div class="col-12 field">
    <textarea  [style]="{maxHeight: '140px'}" class="inputfield w-full" [readonly]="isObserver || readOnly" rows="6" cols="30" [(ngModel)]="comment" pInputTextarea autoResize="autoResize" placeholder="{{'COMMON.COMMENT' | translate}}"></textarea>
  </div>

  <div class="col-12 footer">
      <span class="p-buttonset float-right">
      <button pButton type="button" label="{{'BUTTON.CLOSE' | translate}}" (click)="close()"
              class="p-button-outlined p-button-raised"></button>
      <p-button *ngIf="!readOnly" pRipple type="button" [disabled]="ifEqual() || isObserver || readOnly" label="{{'BUTTON.SAVE' | translate}}" (onClick)="save()"></p-button>
    </span>
  </div>
</div>
