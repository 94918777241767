import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {Asset} from '../../../model/asset';
import {AssetService} from '../../../services/service/asset.service';
import {SubjectService} from '../../../services/service/subject.service';
import {AssetFilterPipe} from '../../../pipes/asset-filter.pipe';

@Component({
    selector: 'app-asset',
    templateUrl: './asset.component.html',
    styleUrls: ['./asset.component.scss'],
    providers: [AssetFilterPipe]
})
export class AssetComponent implements OnInit, OnDestroy {

    subscription = new Subscription();
    archivedAssets: Asset[];
    allArchivedAssets: Asset[];
    stationNameMap: Map<string, string>;
    typesNameMap: Map<string, string>;

    loading = true;
    search: string;

    constructor(
        private assetService: AssetService,
        private subjectService: SubjectService,
        private assetFilter: AssetFilterPipe,
    ) { }

    ngOnInit(): void {
        this.subscription.add(combineLatest([this.subjectService.getStations(), this.assetService.getAssetTypes(),
            this.assetService.getArchivedAssets()]).subscribe(([stations, types, assets]) => {

                this.archivedAssets = assets.map(asset => {
                    return {key: asset.key, ...asset.payload.val()};
                }).sort((a, b) =>{
                    return a.name.localeCompare(b.name);
                });
                this.allArchivedAssets = this.archivedAssets;
                this.stationNameMap = new Map(stations.map(station => [station.key, station.payload.val().name]));
                this.typesNameMap = new Map(types.map(type => [type.key, type.payload.val().name]));

                this.loading = false;
            })
        );
    }

    /**
     * Get type name from fbid.
     * @param stationId Firebase ID.
     */
    getStation(stationId: string) {
        return this.stationNameMap.get(stationId);
    }

    /**
     * Get type name from fbid.
     * @param typeId Firebase ID.
     */
    getType(typeId: string) {
        return this.typesNameMap.get(typeId);
    }

    /**
     * Unarchived asset.
     * @param asset Object.
     */
    restore(asset: Asset) {
        const assetKey = asset.key;
        delete asset.key;
        asset.archived = false;
        this.assetService.updateAsset(assetKey, asset);
    }

    /**
     * Filter assets on name.
     * @param search String
     */
    filterAssets(search: string) {
        this.archivedAssets = this.assetFilter.transform(this.allArchivedAssets, search);
    }

    /**
     * Unsubscribe on close.
     */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
