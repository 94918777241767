<div class="grid">
  <div class="col-12">
    <h2>{{'EXERCISE.THEMES' | translate}}</h2>
  </div>

  <div class="col-12">
    <p-table
      #tt
      [value]="themes"
      [loading]="loading"
      breakpoint="200px"
      [globalFilterFields]="['name']" [sortField]="'name'" [sortOrder]="1"
      responsiveLayout="block"
    >
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText
                   type="text"
                   (input)="tt.filterGlobal($event.target.value, 'contains')"
                   placeholder="{{'COMMON.SEARCH' | translate}}"
            />
          </span>
          <button pButton
                  pRipple
                  (click)="addTheme()"
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-rounded">
          </button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th style="width: 90px;">{{'COMMON.STATUS' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-theme>
        <tr>
          <td (click)="editTheme(theme)">{{theme.name}}</td>
          <td><i class="far fa-graduation-cap"></i>&nbsp;{{(theme?.competencyTypes?.length) ? theme.competencyTypes.length : 0}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
