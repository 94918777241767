import {Component, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {NgxUiLoaderService} from "ngx-ui-loader";
import {TranslateService} from "@ngx-translate/core";
import {PrimeNGConfig} from "primeng/api";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'flexitfire';
  lang: string;
  constructor(
    private updates: SwUpdate,
    private ngxLoader: NgxUiLoaderService,
    private translateService: TranslateService,
    private primengConfig: PrimeNGConfig,
  ) {
    this.updates.available.subscribe(() => {
      if (confirm('New Version is available! Ok to refresh')) {
        window.location.reload();
      }
    });
  }



  ngOnInit() {
    this.ngxLoader.start();
    this.primengConfig.ripple = true;
    this.translateService.addLangs(this.systemLanguage);
    this.ngxLoader.stop();
  }

  private systemLanguage = ['nb', 'en', 'is','da','sv','fi'];

}
