import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Deviation} from "../../../model/deviation";
import {AssetService} from "../../../services/service/asset.service";
import {take} from "rxjs/operators";
import {UserService} from "../../../services/service/user.service";
import {DeviationService} from "../../../services/service/deviation.service";
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Checklist} from "../../../model/checklist";

@Component({
  selector: 'app-add-new-deviation',
  templateUrl: './add-deviation.component.html',
  styleUrls: ['./add-deviation.component.scss']
})
export class AddDeviationComponent implements OnInit, OnDestroy {
  itemId = "";
  checklist: Checklist;
  item;
  checklistId = "";

  disableCheckbox = false;
  deviation: Deviation = {
    created: "",
    createdById: "",
    name: "",
    asset: "",
    comments: [],
  };
  inCommission = true;
  assets = [];
  inCommissionSubscribe;

  from = "";
  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private assetService: AssetService,
    private userService: UserService,
    private deviationService: DeviationService,
  ) {
    this.from = this.config.data.from;

    if (this.from === "edit"){

      this.deviation.name = this.config.data.deviation.name;
      this.deviation.asset = this.config.data.deviation.asset;
      if (this.config.data.deviation.comments) {
        this.deviation.comments = this.config.data.deviation.comments;
      }
      this.inCommission = this.config.data.deviation.inCommission;
      this.deviation.created = this.config.data.deviation.created;
      this.deviation.createdById = this.config.data.deviation.createdById;
      this.deviation.description = this.config.data.deviation.description;

    } else {

      this.itemId = this.config.data.itemId;
      this.checklist = this.config.data.checklist;
      this.item = this.config.data.item;
      this.checklistId = this.config.data.checklistId;
      this.deviation.name = this.item.name;

    }
    if (this.checklist) {
      this.inCommissionSubscribe =
        this.assetService.getInCommission(this.checklist.assetId).subscribe(inCommission => {
            if (!inCommission) {
              this.inCommission = false;
              this.disableCheckbox = true;
            } else {
              this.inCommission = true;
              this.disableCheckbox = false;
            }
          }
        );

      if (this.checklist.assetId) {
        this.deviation.asset = this.checklist.assetId;
      }

    } else {
      this.inCommission = true;
    }
    this.assetService.getAssets().pipe(
      take(1),
    ).subscribe(assets => {
      assets.forEach(asset => {
        this.assets.push({key: asset.key, name: asset.payload.val().name});
      });
    });
  }

  ngOnInit(): void {
  }

  /**
   * Save deviation
   */
  save() {

    this.userService.getUserUID().pipe(
      take(1),
    ).subscribe(uid => {
      this.deviation.createdById = uid;

      if (this.from !== "main"){
        if (this.from === "edit"){

          this.assetService.updateInCommission(this.deviation.asset, this.inCommission);
          this.deviation.updated = new Date().toISOString();
          this.deviationService.updateDeviation(this.config.data.deviation.key, this.deviation);

        } else {
          // If NOT IN COMMISSION and THE Change is made now, update Commission.  Default is true.
          if (!this.inCommission) {
            this.assetService.updateInCommission(this.checklist.assetId, this.inCommission);
          }
          this.deviation.created = new Date().toISOString();
          this.deviationService.addDeviation(this.deviation).pipe(
            take(1),
          ).subscribe(deviationId => {
            this.deviationService.addChecklistDeviation(deviationId, this.checklistId, this.itemId);
          });
        }
      } else {
        // If NOT IN COMMISSION and THE Change is made now, update Commission.  Default is true.
        if (!this.inCommission) {
          this.assetService.updateInCommission(this.deviation.asset, this.inCommission);
        }
        this.deviation.created = new Date().toISOString();
        this.deviationService.addDeviation(this.deviation);
      }
    });
    this.ref.close();
  }

  /**
   * Change status
   */
  changeStatus() {
    this.inCommission = !this.inCommission;
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    if (this.inCommissionSubscribe) {
      this.inCommissionSubscribe.unsubscribe();
    }
  }
}
