<!-- ConfirmDialog -->
<p-confirmDialog
        [style]="{width: '50vw'}" [baseZIndex]="10000"
        header="{{'WARNING.CONFIRMATION' | translate}}"
        icon="pi pi-exclamation-triangle"
        rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>
<div class="grid">
    <ng-container *ngIf="isAdmin$ || isSuper$; else userBlock">
        <div class="col-6">
            <h2 style="margin-top: 10px">{{'ASSET.ASSET' | translate}}</h2>
        </div>
        <div class="col-6 flex justify-content-end">
            <button type="button" pButton class="p-button-raised" icon="pi pi-bars" label="{{'COMMON.MENU' |
            translate}}" (click)="menu.toggle($event)"></button>
            <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
        </div>
    </ng-container>
    <ng-template #userBlock>
        <div class="col-12">
            <h2 style="margin-top: 10px">{{'ASSET.ASSET' | translate}}</h2>
        </div>
    </ng-template>
    <ng-container *ngIf="screenWidth(); else elseBlock">
        <div class="col-12 w-full">
            <span class="p-float-label">
                <input class="w-full" id="search" type="text" pInputText (input)="assetFilter(getEventValue($event))">
                <label for="search" class="breakDots">{{'ASSET.SEARCH_ASSET' | translate}}</label>
            </span>
        </div>
        <div class="col-12 w-full">
            <p-dropdown [options]="stations" [(ngModel)]="selectedStation" [style]="{'width':'100%'}"
                        (onChange)="setStationFilter()" optionLabel="label" optionValue="value"
            ></p-dropdown>
        </div>
    </ng-container>
    <ng-template #elseBlock>
        <div class="col-6">
            <span class="p-float-label">
                <input class="w-full" id="search2" type="text" pInputText (input)="assetFilter(getEventValue($event))">
                <label for="search2">{{'ASSET.SEARCH_ASSET' | translate}}</label>
            </span>
        </div>
        <div class="col-6">
            <p-dropdown [options]="stations" [(ngModel)]="selectedStation" [style]="{'width':'100%'}"
                        (onChange)="setStationFilter()" optionLabel="label" optionValue="value"
            ></p-dropdown>
        </div>
    </ng-template>
    <ng-container *ngFor="let asset of assets">
        <div class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-card styleClass="p-card-shadow box">
                <ng-template pTemplate="header">
                    <div [style]="setStatus(asset)" class="w-full banner">
                        <div class="grid">
                            <div class="col-12 pb-1 flex justify-content-center pb-0">
                                <h2 class="m-0" style="font-weight: 500">{{asset.name}}</h2>
                            </div>
                            <div class="col-12 flex justify-content-evenly pt-0">
                                <div class="col-6 p-0 flex justify-content-center">
                                    <h4 class="m-0" style="font-weight: normal">{{'COMMON.TYPE' | translate}}:
                                        {{assetTypeMap.get(asset.type)}}</h4>
                                </div>
                                <div class="col-6 p-0 flex justify-content-center">
                                    <h4 class="m-0"
                                        style="font-weight: normal">{{stationNameMap.get(asset.stationId)}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="grid">
                    <div class="col-12">
                        <p-card>
                            <ng-container *ngIf="tasks.get(asset.key); else noTask">
                                <p-carousel [value]="tasks.get(asset.key)" class="carousel-indicators">
                                    <ng-template let-task pTemplate="item">
                                        <div (click)="openTask(task)">
                                            <div class="flex justify-content-center">
                                                <i class="far fa-calendar-alt"></i>
                                            </div>
                                            <div class="flex justify-content-center">
                                                {{task.name}}
                                            </div>
                                            <div class="flex justify-content-center">
                                                {{getTaskDate(task)}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-carousel>
                            </ng-container>
                            <ng-template #noTask>
                                <p-carousel [value]="noTasks">
                                    <ng-template let-taskName pTemplate="item">
                                        <div style="height: 48px" class="flex justify-content-center align-items-center">
                                            {{taskName}}
                                        </div>
                                    </ng-template>
                                </p-carousel>
                            </ng-template>
                        </p-card>
                    </div>
                    <div class="col-12">
                        <p-card>
                            <ng-container *ngIf="deviations.get(asset.key); else noDev">
                                <p-carousel [value]="deviations.get(asset.key)">
                                    <ng-template let-deviation pTemplate="item">
                                        <div (click)="openDeviation(deviation)">
                                            <div class="flex justify-content-center">
                                                <i class="far fa-exclamation-triangle"></i>
                                            </div>
                                            <div class="flex justify-content-center">
                                                {{deviation.name}}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-carousel>
                            </ng-container>
                            <ng-template #noDev>
                                <p-carousel [value]="noDeviations">
                                    <ng-template let-deviationName pTemplate="item">
                                        <div  style="height: 48px" class="flex justify-content-center align-items-center">
                                            {{deviationName}}
                                        </div>
                                    </ng-template>
                                </p-carousel>
                            </ng-template>
                        </p-card>
                    </div>
                </div>
                <ng-template pTemplate="footer">
                    <div class="grid">
                        <ng-container *ngIf="isSuper$ || isAdmin$">
                            <div class="col-6">
                                <button pButton type="button" icon="{{getIcon(asset)}}" class="p-button-raised"
                                        label="{{getLabel(asset)}}" (click)="changeInCommission(asset)"
                                ></button>
                            </div>
                            <div class="col-6 flex justify-content-end">
                                <button pButton type="button" icon="pi pi-cog" class="p-button-raised"
                                        label="{{'BUTTON.EDIT' | translate}}" (click)="editAsset(asset)"
                                ></button>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </p-card>
        </div>
    </ng-container>
</div>
