import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {EmergencyResponse} from '../../../model/emergencyResponse';
import {Subscription} from "rxjs";
import {PersonService} from "../../../services/service/person.service";
import {EmergencyService} from "../../../services/service/emergency.service";
import { ExposureService } from 'src/app/services/service/exposure.service';
import {EmergencyEval} from '../../../model/emergencyEval';
import {Exposure} from '../../../model/exposure';
import {RolesService} from '../../../services/service/roles.service';

@Component({
  selector: 'app-emergency-read',
  templateUrl: './emergency-read.component.html',
  styleUrls: ['./emergency-read.component.scss'],
})
export class EmergencyReadComponent implements OnInit, OnDestroy {

  emergency: EmergencyResponse;
  exposures: Exposure[];
  personArray: any[];
  exposureMap = new Map<string, string[]>();
  exposureTypes: any[];

  evaluation: EmergencyEval;
  evalBool = [];
  // Subscription
  subscription = new Subscription();

  personNameMap = new Map<string, string>();
  types = new Map();

  roleTypes = new Map<string, string>();

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    public translateService: TranslateService,
    private personService: PersonService,
    private emergencyService: EmergencyService,
    private exposureService: ExposureService,
    private rolesService: RolesService,
  ) {
    this.emergency = this.config.data.emergency;
    if (this.emergency.participants) {
      this.personArray = this.emergency.participants;
    }
    this.exposures = this.config.data.exposures;
    this.exposureTypes = exposureService.getExposureTypes();
    this.evaluation = this.config.data.evaluation;
    if (this.config.data.evaluation){
      this.evalBool.push(true);
    }
    // Get list of persons
    this.subscription.add(
      this.personService.getPersons().subscribe(
        persons => {
          this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
        }
      )
    );

    // Get emergency types
    this.subscription.add(
      this.emergencyService.getTypes().subscribe(
        eventTypes => {
          this.types = new Map(eventTypes.map(type => [type.key, type.payload.val().name]));
        }
      )
    );

    // Get RoleTypes
    this.subscription.add(
      this.rolesService.getRoleTypes().subscribe(
        roleTypes => {
          this.roleTypes = new Map(roleTypes.map(role => [role.key, role.payload.val().name]));
        }
      )
    );

  }

  ngOnInit(): void {
  }

  /**
   * Print exposures in form of string
   * @param person Person object
   */
  printExposures(person: any) {
    const expo = [];
    if (person.exposureIds) {
      for (const exposureId of person.exposureIds) {
        for (const exposure of this.exposures) {
          if (exposure.key === exposureId){
            for (const type of this.exposureTypes) {
              if (exposure.exposureType === type.key){
                expo.push(type.label);

                break;
              }
            }

            break;
          }
        }
      }
    }
    return expo.toString().replace(',', ', ');
  }

  /**
   * Print work hours
   * @param person
   */
  printTime(person: any) {
    if (!person.timeStart){ // Old data
      let time =  '00:00';
      if (person.time !== '') {
        time = person.time.split(':');
      }
      let suppTime = '00:00';
      if (person.supplementaryTime !== ''){
        suppTime = person.supplementaryTime.split(':');
      }
      const hh = Number(time[0]) + Number(suppTime[0]);
      const mm = Number(time[1]) + Number(suppTime[1]);
      if (hh !== 0){
        if (mm !== 0) {
          return String(hh) + this.translateService.instant('EVENT.HOUR') + " " + String(mm)
            + this.translateService.instant('EVENT.MINUTES');
        } else {
          return String(hh) + this.translateService.instant('EVENT.HOUR');
        }
      } else{
        return String(mm) + this.translateService.instant('EVENT.MINUTES');
      }
    } else { // New data
      const time = person.time.split(":");
      if (time[0] !== '0'){
        if (time[1] !== '0') {
          return time[0] + this.translateService.instant('EVENT.HOUR') + " "
            + time[1] + this.translateService.instant('EVENT.MINUTES');
        } else {
          return time[0] + this.translateService.instant('EVENT.HOUR');
        }
      } else {
        return time[1] + this.translateService.instant('EVENT.MINUTES');
      }
    }

  }

  /**
   * Count Exposures
   * @param person Unique person ID
   */
  exposureCounter(person: any){
    if (this.emergency.participants) {
      for (const participant of this.emergency.participants) {
        if (participant.key === person.key) {
          if (participant.exposureIds) {
            return participant.exposureIds.length;
          } else {
            return 0;
          }
        }
      }
    }
  }

  /**
   * Close Info view
   */
  close(){
    this.ref.close();
  }

  /**
   * Get leader Name form leaderKey
   */
  getLeader() {
    if (this.emergency.emergencyLeader){
      return this.personNameMap.get(this.emergency.emergencyLeader);
    } else {
      return "";
    }
  }

  /**
   * Get emergency type from typeKey
   */
  getType() {
    if (this.emergency.typeKey){
      return this.types.get(this.emergency.typeKey);
    } else {
      return "";
    }
  }

  /**
   * Get person name form name or from nameMap
   */
  getName(person) {
    if (!person.name){
      return this.personNameMap.get(person.key);
    } else {
      return person.name;
    }
  }

  printNewRoles(person: any) {
    if (!person.roles){ return this.translateService.instant('ROLES.NO_ROLES');}
    const roles = [];
    person.roles.forEach(key =>{
      roles.push(this.roleTypes.get(key));
    });
    return roles.toString().replace(',', ', ');
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
