import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {DeviationService} from "../../../services/service/deviation.service";
import {AssetService} from "../../../services/service/asset.service";
import {SubjectService} from "../../../services/service/subject.service";
import {Deviation} from "../../../model/deviation";
import {Station} from "../../../model/station";
import {Asset} from "../../../model/asset";
import {PersonService} from "../../../services/service/person.service";
import {CommentModalComponent} from "../../checklist/comment-modal/comment-modal.component";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationService, MenuItem} from "primeng/api";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {Subscription} from "rxjs";
import {AccessControlService} from "../../../services/service/access-control.service";

@Component({
  selector: 'app-read-deviation',
  templateUrl: './read-deviation.component.html',
  styleUrls: ['./read-deviation.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class ReadDeviationComponent implements OnInit, OnDestroy {
  deviationId;
  deviationMap;
  uidNameMap = new Map();
  deviation: Deviation = {
    comments: [], created: "", createdById: "", description: "", name: ""
  };
  station: Station;
  asset: Asset;
  comments$;
  isClosed$;
  isObserver;
  subscription = new Subscription();
  splitMenu: MenuItem[];

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private deviationService: DeviationService,
    private assetService: AssetService,
    private subjectService: SubjectService,
    private personService: PersonService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private acService: AccessControlService,
  ) {
    this.deviationId = this.config.data.deviationId;

    // Deviation Comments.
    this.comments$ = this.deviationService.getComments(this.deviationId);
    // Listen to if the deviation is closed, if closed block UI
    this.isClosed$ = this.deviationService.isClosed(this.deviationId);

    this.subscription.add(
      this.acService.isObserver().subscribe(isObserver => this.isObserver = isObserver)
    );

    // Get Deviation, Person/Asset/Station that´s connected to it
    this.subscription.add(
      this.deviationService.getDeviation(this.deviationId).subscribe(deviation => {

        this.deviation = deviation;

        if (!this.deviation.comments) {
          this.deviation.comments = [];
        }

        this.assetService.getAsset(deviation.asset).subscribe(asset => {
          this.asset = {key: deviation.asset, ...asset};
          this.subjectService.getStation(asset.stationId).subscribe(station => {
            this.station = station;
          });
        });
      })
    );

    this.splitMenu = [
      {
        label: this.translateService.instant('DEVIATION.CLOSE'),
        icon: 'pi pi-check-circle',
        command: () => {
          this.close();
        }
      },
    ];

    this.subscription.add(
      this.personService.getUIDPersonNameMap().subscribe(uidMap => {
        this.uidNameMap = uidMap;
      })
    );

    // Map that contains the assets with deviation.
    this.subscription.add(
      this.deviationService.getOpenDeviationsAsMap().subscribe(dMap => {
        this.deviationMap = dMap;
      })
    );
  }

  ngOnInit(): void {
  }

  /**
   * Set status on asset, depending on open deviations or out of commission
   * @param asset
   */
  setStatus(asset: Asset) {
    return this.assetService.getAssetStatus(asset, this.deviationMap);
  }


  /**
   * Open the comment modal
   */
  openComments() {
    this.dialogService.open(CommentModalComponent, {
      header: this.translateService.instant('COMMON.COMMENTS'),
      data: {
        comments: this.comments$,
        id: this.deviationId,
        mode: 'deviation'

      },
      styleClass: 'max-size-sm-dialog-long'
    });
  }


  /** On destroy unsubscribe **/
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  /**
   * Close the deviation
   */
  close() {
    this.confirmationService.confirm({
      message: this.translateService.instant('DEVIATION.CLOSING') + ':' + '<br>' + this.deviation.name,
      accept: () => {
        this.authService.getUserUID()
          .pipe(
            take(1),
          ).subscribe((userUID) => {
          this.deviation.closedById = userUID;
          this.deviation.closed = new Date().toISOString();
          this.deviationService.updateDeviation(this.deviationId, this.deviation);
          this.ref.close();
        });
      }
    });
  }

  save() {
   this.deviationService.updateDeviation(this.deviationId, this.deviation);
   this.ref.close();
  }
}
