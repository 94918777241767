<ng-container *ngIf="!loading">
  <!-- ConfirmDialog -->
  <p-confirmDialog key="eDetail" [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                   icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

  <!-- Toast -->
  <p-toast key='exercise-detail' position="top-left" styleClass="customToast"></p-toast>
  <p-toast key='error' position="top-left" styleClass="customToast"></p-toast>

  <div class="grid">
    <div class="col-12">
      <div class="p-buttonset float-right" *ngIf="!acRole?.user">
        <p-splitButton #sb appendTo="body" label="{{'COMMON.MENU' | translate}}" icon="pi pi-list" class="p-button-raised"
                       (onClick)="sb.onDropdownButtonClick(null)" styleClass="p-button-help" [model]="splitLeft"
        >
        </p-splitButton>
        <p-button label="{{'EXERCISE.APPROVE_PARTICIPATION' | translate}}" icon="pi pi-check-circle"
                  (onClick)="this.complete()" class="p-button-raised"
        >
        </p-button>
      </div>
      <div class="flex justify-content-end" *ngIf="acRole?.user">
        <p-splitButton appendTo="body" label="{{'BUTTON.READ_MORE' | translate}}" icon="pi pi-book"
                       (onClick)="edit(true)" styleClass="p-button-help" [model]="splitLeft"
                       class="p-button-raised"
        >
        </p-splitButton>
      </div>
    </div>

    <div class="col-12" style="padding: 0">
      <p-table [value]="exercise?.participants" breakpoint="200px">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 40%">{{'COMMON.NAME' | translate}}</th>
            <th style="width: 40%">{{'SUBJECTS.TYPES.FIRETEAM' | translate}}</th>
            <th><i class="far fa-graduation-cap"></i></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-participant>
          <tr (click)="editParticipant(participant)">
            <td>{{personNameMap.get(participant.key)}}</td>
            <td>{{personParentNameMap.get(participant.key)}}</td>
            <td>{{(participant.competencyTypes) ? getSize(participant.competencyTypes) : 0}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">{{'REPORT.NO_SUBJECTS' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</ng-container>
