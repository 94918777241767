<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12 pr-0 pl-0">
    <p-table #dt1 [value]="persons" [rowHover]="true" [loading]="loading" [globalFilterFields]="['name']" breakpoint="200px">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-end">
          <p-dropdown [options]="adminFilter" [(ngModel)]="selectedFilter" (ngModelChange)="changeFilter()"
                      optionLabel="name">
            <ng-template pTemplate="selectedItem">
              <div class="country-item country-item-value" *ngIf="selectedFilter">
                <div>{{selectedFilter.name | translate}}</div>
              </div>
            </ng-template>
            <ng-template let-mode pTemplate="item">
              <div class="mode-item">
                <div>{{mode.name | translate}}</div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
             <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                   placeholder="{{'COMMON.SEARCH' | translate}}"/>
          </span>
          </th>
          <th *ngIf="bigView"></th>
          <th></th>
          <th style="width: 3rem"></th>
        </tr>
        <tr>
          <th pSortableColumn="name">{{'COMMON.NAME' | translate}}
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th *ngIf="bigView">{{'PERSONAL.EMAIL' | translate}}</th>
          <th>{{'ADMIN.NEW_EXISTING_USER_COMPONENT.USER_ACCESS' | translate}}</th>
          <th style="width: 3rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-person>
        <tr>
          <td (click)="changeRole(person)">{{person.name}}</td>
          <td *ngIf="bigView" (click)="changeRole(person)">{{person.email}}</td>
          <td (click)="changeRole(person)">{{getRole(person.roles) | translate}}</td>
          <td *ngIf="([0,2].indexOf(selectedFilter.value) >= 0)" (click)="disable(person)"><i class="pi pi-times-circle"></i></td>
          <td *ngIf="([1,3].indexOf(selectedFilter.value) >= 0)" (click)="activate(person)"><i class="pi pi-refresh"></i></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'SUBJECTS.NO_PERSON_FOUND' | translate}}.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
