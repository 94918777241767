import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IMComponent } from './i-m/i-m.component';
import {Route} from "@angular/router";
import {DropdownModule} from "primeng/dropdown";
import {TranslateModule} from "@ngx-translate/core";
import {TableModule} from "primeng/table";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";

export const InfoMessageRoutes: Route = {
  path: 'info-message',
  children: [
    {path: 'i-m', component: IMComponent},
  ]
};

@NgModule({
  declarations: [
    IMComponent
  ],
  imports: [
    CommonModule,
    DropdownModule,
    TranslateModule,
    TableModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    ConfirmDialogModule,
    ToastModule
  ]
})

export class InfoMessageModule { }
