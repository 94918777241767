import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChecklistService} from "../../../services/service/checklist.service";
import {Subscription} from "rxjs";
import * as moment from "moment";
import {AssetService} from "../../../services/service/asset.service";
import {CompletedChecklistDetailComponent} from "../completed-checklist-detail/completed-checklist-detail.component";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-deviations-checklists',
  templateUrl: './deviations-checklists.component.html',
  styleUrls: ['./deviations-checklists.component.scss'],
  providers: [DialogService]
})
export class DeviationsChecklistsComponent implements OnInit, OnDestroy {

  selectedStation = '';
  stationOptions = [];

  assetMap = new Map();

  checklistsWithDeviations = [];

  subscription = new Subscription();

  checklistLoading = true;

  constructor(
    private checklistService: ChecklistService,
    private assetService: AssetService,
    private dialogService: DialogService,
  ) {

    // Get Checklists with Deviations
    this.subscription.add(
      this.checklistService.getChecklistWithDeviations().subscribe(checklists => {
        this.checklistsWithDeviations = checklists.map(checklist => {
          return {key: checklist.key, ...checklist.payload.val()}
        }).sort((a,b) => moment(b.dateOfCompletion).diff(moment(a.dateOfCompletion)));
        this.checklistLoading = false;
      })
    )

    // Get asset for AssetMap
    this.subscription.add(
      this.assetService.getAssets().subscribe(assets => {
        this.assetMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]))
      })
    )
  }

  ngOnInit(): void {
  }

  openDetail(checklist: any) {
    this.dialogService.open(CompletedChecklistDetailComponent, {
      header: checklist.name,
      data: {
        checklistId: checklist.key
      },
      styleClass: 'max-size-dialog'
    })
  }

  /**
   * Unsubscribe on Destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
