<p-confirmDialog header="{{'WARNING.CONFIRMATION' | translate}}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="grid">
    <div class="col-6 pl-3">
        <button type="button" pButton icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
                (click)="menu.toggle($event)" class="p-button-raised"></button>
        <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
    </div>
    <p-table
        #ct
        [globalFilterFields]="['name']"
        [value]="templateItems"
        [rowHover]="true"
        breakpoint="200px">
        <ng-template pTemplate="caption">
            <div class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="ct.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{'COMMON.SEARCH' | translate}}"/>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>{{'COMMON.NAME' | translate}}</th>
                <th style="width: 6rem">
                    <div class="flex justify-content-center">
                        {{'BUTTON.EDIT' | translate}}
                    </div>
                </th>
                <th style="width: 5rem">
                    <div class="flex justify-content-center">
                        {{'BUTTON.REMOVE' | translate}}
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-element>
            <tr>
                <td>{{element.item.name}}</td>
                <td style="width: 6rem" (click)="editItem(element)">
                    <div class="flex justify-content-center">
                        <i class="far fa-edit"></i>
                    </div>
                </td>
                <td style="width: 5rem" (click)="confirmDeleteItem(element)">
                    <div class="flex justify-content-center">
                        <i class="far fa-trash-alt" style="color: #D0021B"></i>
                    </div>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">{{'CHECKLIST.NO_ITEMS' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
