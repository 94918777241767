<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid" *ngIf="!loading">

    <div class="col-12 flex flex-wrap align-content-center">
        <h2 style="margin-top: 10px">{{'FILES.ARCHIVED_FILES' | translate}}</h2>
    </div>

    <div class="col-12">

        <p-table [(selection)]="selectedFiles" [value]="archivedFiles | nameFilter: search"
                 dataKey="key" responsiveLayout="scroll" selectionMode="multiple">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-8">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" [(ngModel)]="search"  class="inputfield"
                               placeholder="{{'FILE.FILENAME_SEARCH' | translate}}"/>
                    </span>
                    </div>
                    <div class="col-4 flex justify-content-end">
                        <button pButton type="button" class="p-button-raised" label="{{'ARCHIVE.RESTORE' | translate}}"
                                (click)="restore(selectedFiles)" [disabled]="selectedFiles.length === 0"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 70%">
                        {{'FILE.FILENAME' | translate}}
                    </th>
                    <th style="width: 30%">
                        <div class="flex justify-content-center">
                            {{'FILE.ADMIN_FILER' | translate}}
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file let-rowIndex="rowIndex">
                <tr [pSelectableRow]="file" [pSelectableRowIndex]="rowIndex">
                    <td>
                        {{file.name}}
                    </td>
                    <td>
                        <div class="flex justify-content-center">
                            {{getAdmin(file.admin)}}
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!--            <ng-template pTemplate="header">-->
        <!--                <tr>-->
        <!--                    <th>{{'FILE.FILENAME' | translate}}</th>-->
        <!--                    <th>{{'FILE.ADMIN_FILER' | translate}}</th>-->
        <!--                </tr>-->
        <!--            </ng-template>-->
        <!--            <ng-template pTemplate="body" let-file let-rowIndex="rowIndex">-->
        <!--                <tr [pSelectableRow]="file" [pSelectableRowIndex]="rowIndex">-->
        <!--                    <td>{{file.name}}</td>-->
        <!--                    <td>{{getAdmin(file.admin)}}</td>-->
        <!--                </tr>-->
        <!--            </ng-template>-->

    </div>
    <!--    <ng-template #emptyBlock>-->
    <!--        <div class="col-12">-->
    <!--            <h4>{{'ARCHIVE.NO_SUBJECTS' | translate}}</h4>-->
    <!--        </div>-->
    <!--    </ng-template>-->
</div>
