import { Pipe, PipeTransform } from '@angular/core';
import {SendSMS} from '../model/sendSMS';
import * as moment from 'moment';

@Pipe({
  name: 'sMSFilter'
})
export class SMSFilterPipe implements PipeTransform {

  transform(allSMS: SendSMS[], dateRange: string[]): SendSMS[] {

    const filteredSMS = [];

    if(!allSMS || (dateRange[0] === null && dateRange[1] === null)){
      return allSMS;
    } else {
      const from = moment(dateRange[0]).toISOString();
      let to = "";

      if (dateRange[1] !== null){
        to = moment(dateRange[1]).toISOString();
      }
      if (to !== ""){
        allSMS.filter(sms => {
          if(sms.date >= from && sms.date <= to){
            filteredSMS.push(sms);
          }
        });
      } else {
        allSMS.filter(sms => {
          if(sms.date >= from){
            filteredSMS.push(sms);
          }
        });
      }
    }
    return filteredSMS;
  }

}
