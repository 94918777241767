import { Component, OnInit } from '@angular/core';
import {RoleType} from '../../../model/role';
import {AppointmentService} from '../../../services/service/appointment.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-appointment-types',
  templateUrl: './appointment-types.component.html',
  styleUrls: ['./appointment-types.component.scss'],
  providers: [MessageService]
})
export class AppointmentTypesComponent implements OnInit {
  subscription = new Subscription();

  appointmentTypes: any[];
  appointmentType: string;
  existingTypes: any[];
  appointmentConnections: any[];
  color: string;


  constructor(
      private appointmentService: AppointmentService,
      private messageService: MessageService,
      private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.appointmentService.getAppointmentTypes()
        .subscribe(types => {this.appointmentTypes = types.map(type => {return {key: type.key, ...type.payload.val()};});})
    );
    // TODO Retire 2023
    let old;
    this.subscription.add(this.appointmentService.getOldAppointments()
        .subscribe(appointments => {
          old = appointments
              .map(appointment => {return {key: appointment.key, ...appointment.payload.val()}; });
        })
    );
    this.subscription.add(this.appointmentService.getAppointments()
        .subscribe(appointments => {
          this.appointmentConnections = old.concat(appointments
              .map(appointment => {return {key: appointment.key, ...appointment.payload.val()}; }));

          console.log(this.appointmentConnections);

        })
    );
  }

  /**
   * Search existing roles
   * @param $event Search query
   */
  searchExisting($event){
    const filtered: RoleType[] = [];
    const query = $event.query;

    this.appointmentTypes.forEach(appointmentType => {
      if (appointmentType.name.toLowerCase().indexOf(query.toLowerCase()) === 0){
        filtered.push(appointmentType);
      }
    });

    this.existingTypes = filtered;
  }
  /**
   * Add new role
   * @param type appointment type name
   */
  addAppointmentType(type) {
    if(type) {
      let value = 0;
      this.existingTypes.findIndex( appointmentType => {
        if (appointmentType.name.toLowerCase() === type.name.toLowerCase()){
          value = 1;
          this.appointmentType = '';
        }
      });
      if (value === 0){
        this.appointmentService.addAppointmentType({name: type, type: "misc"});
        this.appointmentTypes.push({name: type});
        this.appointmentType = '';
      }
    }
  }

  deleteAppointmentType(appointmentType: any) {
    const index = this.appointmentTypes.findIndex(aType => aType.name === appointmentType.name);
    if (index >= 0){
      let connection = false;
      this.appointmentConnections.some(roleConnection => {
        this.appointmentTypes.some(r => {
          if(r.key === roleConnection.typekey){
            if (r.name === appointmentType.name){
              connection = true;
            }
          }
        });
      });
      if (!connection){
        this.appointmentTypes.splice(index, 1);
        this.appointmentService.deleteAppointmentType(appointmentType.key);
      } else {
        this.messageService.add(
            {key: 'error', severity: 'error', detail: this.translateService.instant('ROLES.ERROR_MESSAGE'), life: 4000}
        );
      }
    }
  }
}
