<div class="grid">
  <div class="col-12">

    <!-- PERSON TABLE -->
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="tableArray"
             dataKey="key">

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'FDC.PERSONS' | translate}}</th>
          <th>{{'ROLES.ROLES' | translate}}</th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body" let-person>
        <tr>
          <td>{{personNameMap.get(person.key)}}</td>
          <td>
            <p-multiSelect [options]="roleTypes"
                           (onChange)="setRoles($event, person.key)"
                           optionLabel="name"
                           optionValue="key"
                           appendTo="body"
                           [ngModel]="selectedRolesMap.get(person.key)"
                           defaultLabel="{{'ROLES.ADD_ROLE' | translate}}"
                           [style]="{'width':'100%'}"
            >
            </p-multiSelect>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="col-12 flex justify-content-end">
      <p-button label="{{'BUTTON.SAVE' | translate}}"
                icon="pi pi-check"
                iconPos="right"
                (click)="save()"
      ></p-button>
    </div>

  </div>
</div>
