import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Exercise} from "../../../model/exercise";
import {ExerciseService} from "../../../services/service/exercise.service";
import {combineLatest, Subscription} from 'rxjs';
import {ConfirmationService, FilterService, MenuItem, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {SubjectService} from "../../../services/service/subject.service";
import {PersonService} from "../../../services/service/person.service";
import {EditExerciseComponent} from "../edit-exercise/edit-exercise.component";
import {EditParticipantComponent} from "../edit-participant/edit-participant.component";
import {ExerciseCompetencyTypeDetailComponent} from "../exercise-competency-type-detail/exercise-competency-type-detail.component";
import {ExerciseFilesComponent} from "../exercise-files/exercise-files.component";
import {ExerciseEvaluationComponent} from "../exercise-evaluation/exercise-evaluation.component";
import {AccessControlService} from "../../../services/service/access-control.service";
import {ExerciseApproveComponent} from "../exercise-approve/exercise-approve.component";
import * as moment from "moment";
import {ExerciseThemesListComponent} from "../exercise-themes-list/exercise-themes-list.component";
import {AddParticipantsComponent} from "../add-participants/add-participants.component";
import {SendSMSComponent} from '../send-sms/send-sms.component';
import {CalendarService} from '../../../services/service/calendar.service';
import {CalendarEvent} from '../../../model/calendarEvent';
import {take} from 'rxjs/operators';
import {FileService} from '../../../services/service/file.service';

@Component({
  selector: 'app-exercise-detail',
  templateUrl: './exercise-detail.component.html',
  styleUrls: ['./exercise-detail.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class ExerciseDetailComponent implements OnInit, OnDestroy {
  @Input() fromCalendarEventKey;
  exercise: Exercise;
  exerciseKey: string;
  splitLeft: MenuItem[];
  personNameMap = new Map();
  teamNameMap = new Map();
  participantsMap = new Map();
  personParentNameMap = new Map();
  acRole;

  subscription = new Subscription();
  loading = true;

  files = new Map();
  fileNameMap = new Map();
  relationMap: Map<string, string[]> = new Map();

  constructor(
      public ref: DynamicDialogRef, public config: DynamicDialogConfig,
      private exerciseService: ExerciseService,
      private translateService: TranslateService,
      private subjectService: SubjectService,
      private personService: PersonService,
      private filterService: FilterService,
      private messageService: MessageService,
      private dialogService: DialogService,
      private confirmationService: ConfirmationService,
      private acService: AccessControlService,
      private calendarService: CalendarService,
      private fileService: FileService,
  ) {}

  ngOnInit(): void {
    if (this.config.data.key) {
      this.exerciseKey = this.config.data.key;
      this.subscription.add(
          this.exerciseService.getExercise(this.exerciseKey)
              .subscribe(exercise => {
                this.exercise = exercise;
                if (this.exercise.participants) {
                  this.participantsMap = new Map(this.exercise.participants.map(participant => [participant.key, true]));
                } else {
                  this.exercise.participants = [];
                }
              })
      );
      this.loading = false;
    } else {
      this.exerciseKey = this.fromCalendarEventKey;
      this.subscription.add(
          this.exerciseService.getExercise(this.exerciseKey)
              .subscribe(exercise => {
                this.exercise = exercise;
                if (this.exercise.participants) {
                  this.participantsMap = new Map(this.exercise.participants.map(participant => [participant.key, true]));
                } else {
                  this.exercise.participants = [];
                }
              })
      );
      this.loading = false;
    }
    this.subscription.add(this.fileService.getAllFiles().subscribe(files => {
      this.fileNameMap = new Map(files.map(file => [file.key, file.payload.val().name]));
      this.files = new Map();
      files.map(file => {
        if (!file.payload.val().exerciseFile){
          this.files.set(file.key, file.payload.val());
        }
      });
    }));

    this.subscription.add(this.fileService.getFileTagRelations().subscribe(relations => {
      this.relationMap = new Map();
      relations.map(relation => {
        if(this.relationMap.has(relation.payload.val().tagId)){
          this.relationMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
        } else{
          this.relationMap.set(relation.payload.val().tagId, [relation.payload.val().fileId]);
        }
      });
    }));
    this.subscription.add(
        combineLatest([
          this.personService.getPersons(),
          this.subjectService.getTeams(),
          this.subjectService.getRelations(),
        ]).subscribe(([persons, teams, relations]) => {

          this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
          this.teamNameMap = new Map(teams.map(team => [team.key, team.payload.val().name]));


          relations.forEach(relation => {
            if(this.personNameMap.has(relation.childId)){
              if(this.teamNameMap.has(relation.parentId)){
                this.personParentNameMap.set(relation.childId, this.teamNameMap.get(relation.parentId));
              } else {
                this.personParentNameMap.set(relation.childId, 'N/A');
              }
            }
          });
        })
    );
    this.subscription.add(
        this.acService.getOwnAccessControl().subscribe(ac => {
          this.acRole = ac.roles;

          if(!this.acRole.user){
            this.splitLeft = [
              { label: this.translateService.instant('BUTTON.EDIT'),
                icon: 'pi pi-cog',
                command: () => {this.edit();}
              },

              { label: this.translateService.instant('EVENT.ADD_PERSON'),
                icon: 'pi pi-plus',
                command: () => {this.addPersons();}
              },

              { label: this.translateService.instant('MAIN_FRAME.COMPETENCIES'),
                icon: 'pi far fa-graduation-cap',
                command: () => {this.exerciseCompetencies();}
              },
              { label: this.translateService.instant('EXERCISE.THEMES'),
                icon: 'far fa-ballot',
                command: () => {
                  this.addThemeCompetencies();
                }
              },
              { label: this.translateService.instant('FILE.FILES'),
                icon: 'pi pi-file',
                command: () => {
                  this.exerciseFiles();
                }
              },
              { label: this.translateService.instant('BUTTON.EVALUATE'),
                icon: 'pi pi-thumbs-up',
                command: () => {this.exerciseEvaluate();}
              },
              { label: this.translateService.instant('BUTTON.SMS'),
                icon: 'far fa-sms',
                command: () => {this.sms();}
              },

              { label: this.translateService.instant('BUTTON.COPY'),
                icon: 'pi pi-copy',
                command: () => {this.copy();}
              },
              {separator: true},

              { label: this.translateService.instant('BUTTON.REMOVE'),
                icon: 'pi pi-trash',
                command: () => {this.delete();}
              }
            ];
          } else {
            this.splitLeft = [
              { label: this.translateService.instant('FILE.FILES'),
                icon: 'pi pi-file',
                command: () => {
                  this.exerciseFiles();
                }
              },
            ];
          }
        })
    );
  }

  /**
   * Save method
   */
  save() {

    const exerciseEvent: CalendarEvent = {
      completed: false,
      calendarType: 'EXERCISE',
      end: this.exercise.dateEnd,
      start: this.exercise.date,
      title: this.exercise.name
    };

    this.calendarService.updateCalendarEvent(exerciseEvent, this.exerciseKey);

    this.exerciseService.updateExercise(this.exerciseKey, this.exercise);
    this.messageService.add(
        {
          key: 'exercise-detail', severity: 'success',
          detail: this.translateService.instant('SUCCESS.SAVED'), life: 2000
        }
    );
  }

  /**
   * EDIT Exercise
   * @param readOnly, only have reading access if user.
   */
  edit(readOnly?) {
    const modalRef = this.dialogService.open(EditExerciseComponent, {
      header: this.translateService.instant('BUTTON.EDIT') + ' ' + this.exercise.name,
      styleClass: 'max-size-width-dialog',
      data: {
        key: this.exerciseKey,
        exercise: this.exercise,
        readOnly: (readOnly) ? readOnly : false,
      }
    });

    this.subscription.add(
        modalRef.onClose.subscribe(header => {
          if(header){
            // Send close/open signal down
            this.ref.close(header);
          }
        })
    );
  }

  /**
   * Approve Exercise.
   */
  complete() {

    if (this.exercise.competencyTypes && this.exercise.participants.length > 0) {
      const exerciseEnd = moment(this.exercise.dateEnd);
      const now = moment().add(5, "h");


      if (exerciseEnd.isBefore(now)) {
        const modalRef = this.dialogService.open(ExerciseApproveComponent, {
          header: this.translateService.instant('EXERCISE.APPROVAL_TITLE'),
          styleClass: 'max-size-width-dialog',
          data: {
            key: this.exerciseKey,
            exercise: this.exercise,
          }
        });

        modalRef.onClose.subscribe((close) => {
          if (close) {
            this.ref.close();
          }
        });
      } else {
        this.messageService.add(
            {
              key: 'exercise-detail', severity: 'warn',
              detail: this.translateService.instant('WARNING.EXERCISE_WARNING'), life: 4000
            }
        );
      }
    } else {
      this.messageService.add({
        key: 'error',
        severity:'error',
        summary: this.translateService.instant('ERROR.ERROR'),
        detail: this.translateService.instant('ERROR.ERROR_MESSAGE')
      });
    }
  }

  /**
   * Method to remove a person as participant
   * @param participant
   */
  remove(participant: any) {
    const index = this.exercise.participants.findIndex(par => par.key === participant.key);
    if(index >= 0){
      this.exercise.participants.splice(index, 1);
      this.participantsMap.delete(participant.key);
      this.save();
    }

  }

  /**
   * Method to get CompetencyTypes length of a person.
   * @param competencyTypes
   */
  getSize(competencyTypes: any) {
    return Object.keys(competencyTypes).length;
  }

  /**
   * Edit Participant from Exercise
   * @param participant
   */
  editParticipant(participant) {
    const modalRef = this.dialogService.open(EditParticipantComponent, {
      header: this.personNameMap.get(participant.key),
      styleClass: 'max-size-dialog',
      data: {
        participantCT: (participant.competencyTypes) ? participant.competencyTypes: {},
        acRole: this.acRole,
      }
    });

    this.subscription.add(
        modalRef.onClose.subscribe(data => {
          if(data instanceof Object){
            participant.competencyTypes = data;
            this.save();
          } else if(data === true){
            this.remove(participant);
          }
        })
    );


  }

  /**
   * Unsubscribe on Destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Open Exercise Competencies modal
   * @private
   */
  private exerciseCompetencies() {
    const modalRef = this.dialogService.open(ExerciseCompetencyTypeDetailComponent, {
      header: this.translateService.instant('COMPETENCY.TYPES'),
      styleClass: 'max-size-dialog',
      data: {
        exerciseCT: this.exercise.competencyTypes
      }
    });

    this.subscription.add(
        modalRef.onClose.subscribe(obj => {
          if(obj){
            this.exercise.competencyTypes = obj.ct;
            this.exercise.exerciseFiles = [];
            Object.keys(obj.ct).forEach(key => {
              if (this.relationMap.has(key)) {
                this.relationMap.get(key).forEach(file => {
                  this.exercise.exerciseFiles.push(file);
                });
              }
            });
            const removedCTMap = obj.removedCTMap;
            this.exercise.participants.forEach(participant => {
              participant.competencyTypes = {...participant.competencyTypes, ...obj.ct};
              if(removedCTMap.size > 0){
                Object.keys(participant.competencyTypes).forEach(ctKey => {
                  if(removedCTMap.has(ctKey)){
                    delete participant.competencyTypes[ctKey];
                  }
                });
              }
            });
            this.save();
          }
        })
    );
  }

  /**
   * Open Exercise files
   * @private
   */
  private exerciseFiles() {
    this.dialogService.open(ExerciseFilesComponent, {
      header: this.translateService.instant('FILE.FILES'),
      styleClass: 'max-size-dialog',
      data: {
        exercise: this.exercise,
        key: this.exerciseKey,
      }
    });
  }

  /**
   * Open Evaluate modal.
   * @private
   */
  private exerciseEvaluate() {
    this.dialogService.open(ExerciseEvaluationComponent, {
      header: this.translateService.instant('COMMON.EVALUATE'),
      styleClass: 'max-size-dialog',
      data: {
        exercise: this.exercise,
        exerciseKey: this.exerciseKey,
        fromInfo: false
      }
    });
  }

  private sms() {
    this.dialogService.open(SendSMSComponent, {
      header: this.translateService.instant('EXERCISE.SEND_SMS'),
      styleClass: 'max-size-dialog',
      data: {
        exercise: this.exercise,
        exerciseKey: this.exerciseKey,
      }
    });
  }

  /**
   * Makes Copy of the Exercise and mark it Name + (Copy)
   * @private
   */
  private copy() {
    this.confirmationService.confirm({
      key: 'eDetail',
      message: this.translateService.instant('CONFIRMATION.COPY'),
      accept: () => {
        const exerciseCopy = JSON.parse(JSON.stringify(this.exercise));
        exerciseCopy.name += ' (Copy)';

        if (this.exercise.originId){
          exerciseCopy.originId = this.exercise.originId;
        } else {
          exerciseCopy.originId = this.exerciseKey;
        }

        const exerciseEvent: CalendarEvent = {
          completed: false,
          calendarType: 'EXERCISE',
          end: this.exercise.dateEnd,
          start: this.exercise.date,
          title: this.exercise.name
        };

        this.exerciseService.addExerciseGetKey(exerciseCopy)
            .pipe(take(1)).subscribe(exerciseKey => {
          exerciseEvent.exerciseId = exerciseKey;
          this.calendarService.addCalendarEvent(exerciseEvent, exerciseKey);
        });

        this.messageService.add(
            {
              key: 'exercise-detail', severity: 'success',
              detail: this.translateService.instant('TOAST.COPIED'), life: 4000
            }
        );
        this.ref.close();
      }
    });
  }

  /**
   * Delete Exercise on Confirm
   * @private
   */
  private delete() {
    this.confirmationService.confirm({
      key: 'eDetail',
      message: this.translateService.instant('WARNING.REMOVE'),
      accept: () => {
        this.calendarService.deleteCalendarEvent(this.exerciseKey);
        this.exerciseService.deleteExercise(this.exerciseKey);
        this.ref.close();
      }
    });
  }

  /**
   * Add Themes Competencies to the Exercise
   * @private
   */
  private addThemeCompetencies() {
    this.dialogService.open(ExerciseThemesListComponent, {
      header: this.translateService.instant('EXERCISE.THEMES'),
      styleClass: 'max-size-dialog',
      data: {
        eKey: this.exerciseKey,
      }
    });
  }

  /**
   * Add Person Modal
   * @private
   */
  private addPersons() {
    const modalRef = this.dialogService.open(AddParticipantsComponent, {
      header: this.translateService.instant('EXERCISE.PARTICIPANTS'),
      styleClass: 'max-size-dialog',
      data: {
        eKey: this.exerciseKey,
      }
    });

    this.subscription.add(
        modalRef.onClose.subscribe((participants) => {
          if(participants){
            this.exercise.participants = participants;
            this.save();
          }
        })
    );
  }
}
