import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {CompetencyType} from '../../../model/competencyType';
import * as moment from 'moment';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';
import {CompetencyTypeFilterPipe} from '../../../pipes/competency-type-filter.pipe';

@Component({
  selector: 'app-competency-type',
  templateUrl: './competency-type.component.html',
  styleUrls: ['./competency-type.component.scss'],
  providers: [CompetencyTypeFilterPipe]
})
export class CompetencyTypeComponent implements OnInit, OnDestroy {

  subscription = new Subscription();

  archivedCTT: CompetencyType[];
  allArchivedCTT: CompetencyType[];

  loading = true;
  search: string;

  constructor(
      private CTTypeService: CompetencyTypeService,
      private CTTFilter: CompetencyTypeFilterPipe,
  ) { }

  ngOnInit(): void {
    this.subscription.add(combineLatest([this.CTTypeService.getArchivedCompetencyTypes()]).subscribe(([CTTypes]) => {
          this.allArchivedCTT = CTTypes.map(CTType => {
            return {key: CTType.key, ...CTType.payload.val()};
          }).sort((a, b) =>{
            return a.name.localeCompare(b.name);
          });
          this.archivedCTT = this.allArchivedCTT;
          this.loading = false;
        })
    );
  }

  /**
   * Filter archived competency types.
   * @param search String.
   */
  filterCompetencies(search: string) {
    this.archivedCTT = this.CTTFilter.transform(this.allArchivedCTT, search);
  }

  /**
   * Returns ISOString as DD.MM.YYYY format.
   * @param archivedDate ISOString.
   */
  getDate(archivedDate: string) {
    return moment(archivedDate).format("DD.MM.YYYY");
  }

  /**
   * Reactivate competency type.
   * @param competencyType Object
   */
  restore(competencyType: CompetencyType) {
    const CTTKey = competencyType.key;
    delete competencyType.key;
    delete competencyType.archived;
    delete competencyType.archivedById;
    delete competencyType.archivedDate;
    this.CTTypeService.updateCompetencyType(CTTKey, competencyType);
  }

  /**
   * Unsubscribe on close.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
