<div class="grid">
  <div class="col-12">
    <label for="validity">{{'COMPETENCY.VALIDITY' | translate}}</label>
  </div>
  <div class="col-12">
    <div class="p-inputgroup">
      <input id="validity" type="number" [(ngModel)]="ct.validity" pInputText [min]="0" class="inputfield w-full">
      <span class="p-inputgroup-addon">{{'COMMON.MONTHS.MONTHS' | translate}}</span>
    </div>
  </div>
  <div class="col-12 flex justify-content-end mt-2">
    <button pButton class="p-button-raised" (click)="save()" label="{{'BUTTON.SAVE' | translate}}"></button>
  </div>
</div>
