import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedFileFilter'
})
export class SelectedFileFilterPipe implements PipeTransform {

  /**
   * Build file map
   * @param fileTagMap Map of all internal / external and admin files
   * @param admin True if user is admin
   * @param options Selected filter option
   * @param tags Nonempty tags
   */
  transform(admin: boolean, options: [], tags: Map<string, {internal: [], external: []}>,
            fileTagMap: Map<string, {admin: {internal: [], external: []}, user: {internal: [], external: []}}>,): any {

    if (!options || !fileTagMap || !tags){
      return [];
    } else {
      const selectedFileTagMap = new Map<string, []>(); // {tag: id, [files]}

      // tags = {admin: {internal: [tags], external: [tags]}, user: {internal: [tags], external: [tags]}}
      if (admin){
        if (options === ['external']){
          tags.get('admin').external.forEach(tag => {
            // @ts-ignore
            selectedFileTagMap.set(tag, fileTagMap.get(tag).admin.external.concat(fileTagMap.get(tag).user.external));
          });
          return selectedFileTagMap;

        } else if (options === ['internal']){
          tags.get('admin').internal.forEach(tag => {
            // @ts-ignore
            selectedFileTagMap.set(tag, fileTagMap.get(tag).admin.internal.concat(fileTagMap.get(tag).user.internal));
          });
          return selectedFileTagMap;

        } else if (options === ['external', 'internal'] || options === ['internal', 'external']){
          for (const tag of tags.get('admin').internal) {
            // @ts-ignore
            selectedFileTagMap.set(tag, fileTagMap.get(tag).admin.internal.concat(fileTagMap.get(tag).user.internal));
          }
          for (const tag of tags.get('admin').external) {
            if (!selectedFileTagMap.has(tag)){
              // @ts-ignore
              selectedFileTagMap.set(tag, fileTagMap.get(tag).admin.external.concat(fileTagMap.get(tag).user.external));
            } else {
              const list = selectedFileTagMap.get(tag).concat(fileTagMap.get(tag).admin.external).concat(fileTagMap.get(tag).user.external);
              // @ts-ignore
              selectedFileTagMap.set(tag, list);
            }
          }
          return selectedFileTagMap;
        }
      } else{
        if (options === ['external']){
          tags.get('user').external.forEach(tag => {
            // @ts-ignore
            selectedFileTagMap.set(tag, fileTagMap.get(tag).user.external);
          });
        } else if (options === ['internal']){
          tags.get('user').internal.forEach(tag => {
            // @ts-ignore
            selectedFileTagMap.set(tag, fileTagMap.get(tag).user.internal);
          });
        } else if (options === ['external', 'internal'] || options === ['internal', 'external']){
          for (const tag of tags.get('user').internal) {
            // @ts-ignore
            selectedFileTagMap.set(tag, fileTagMap.get(tag).user.internal);
          }
          for (const tag of tags.get('user').external) {
            if (!selectedFileTagMap.has(tag)){
              // @ts-ignore
              selectedFileTagMap.set(tag, fileTagMap.get(tag).user.external);
            } else {
              const list = selectedFileTagMap.get(tag).concat(fileTagMap.get(tag).user.external);
              // @ts-ignore
              selectedFileTagMap.set(tag, list);
            }
          }
        }
        return selectedFileTagMap;
      }
    }
  }

}
