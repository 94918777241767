import { Component, OnInit } from '@angular/core';
import {MenuItem} from "primeng/api";
import {SubjectService} from "../../../services/service/subject.service";
import {Subscription} from "rxjs";
import {AccessControlService} from "../../../services/service/access-control.service";
import {take} from "rxjs/operators";
import {AuthService} from "../../../services/service/auth.service";
import {PersonSubject} from "../../../model/subject";
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ChangePwComponent} from '../change-pw/change-pw.component';
import {ChangeMailComponent} from '../change-mail/change-mail.component';

@Component({
    selector: 'app-my-info',
    templateUrl: './my-info.component.html',
    styleUrls: ['./my-info.component.scss'],
    providers: [DialogService]
})
export class MyInfoComponent implements OnInit {
    menuItems: MenuItem[];
    subscription = new Subscription();
    subject: PersonSubject;
    subjectId: string;
    loading = true;
    jobTitles: any[];

    constructor(
        private subjectService: SubjectService,
        private acService: AccessControlService,
        private authService: AuthService,
        private translateService: TranslateService,
        private dialogService: DialogService,
    ) {
        this.authService.getUserUID().pipe(take(1))
            .subscribe(uid => {
                this.acService.getSubjectIdFromUID(uid).pipe(take(1))
                    .subscribe(subjectId => {
                        this.subjectId = subjectId;
                        this.subscription.add(this.subjectService.getPerson(subjectId)
                            .subscribe(subject =>{
                                this.subject = subject;
                                if (this.subject.dateOfBirth) {
                                    this.subject.dateOfBirth = moment(this.subject.dateOfBirth).format("DD.MM.YYYY");
                                } else {
                                    this.subject.dateOfBirth = "";
                                }
                                this.loading = false;
                            })
                        );
                    });
            });
        this.subjectService.getJobTitles().pipe(
            take(1),
        ).subscribe(jobTitles => {
            this.jobTitles = jobTitles;
        });
    }

    ngOnInit(): void {
        this.menuItems = [
            {   label: this.translateService.instant('MY_PAGE.CHANGE_PASSWORD (2)'),
                icon: 'far fa-at',
                command: () => {
                    this.changePW();
                }
            },
            {   label: this.translateService.instant('MY_PAGE.CHANGE_EMAIL (2)'),
                icon: 'far fa-key',
                command: () => {
                    this.changeMail();
                }
            },
        ];
    }

    screenWidth() {
        return window.innerWidth > 441;
    }

    /**
     * Save subject changes
     */
    save() {
        this.subject.dateOfBirth = moment(this.subject.dateOfBirth).format("YYYY.MM.DD");
        this.subject.dateOfBirth = moment(this.subject.dateOfBirth).toISOString();
        this.subjectService.updateSubject(this.subjectId, this.subject);
        this.loading = true;
    }

    private changePW() {
        this.dialogService.open(ChangePwComponent, {
            header: this.translateService.instant('MY_PAGE.CHANGE_PASSWORD (2)'),
            width: "300px"
        });
    }

    private changeMail() {
        this.dialogService.open(ChangeMailComponent, {
            header: this.translateService.instant('MY_PAGE.CHANGE_EMAIL (2)'),
            width: "300px"
        });
    }
}
