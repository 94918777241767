<div class="grid">
  <div class="col-12 w-full">
    <span class="p-float-label">
      <input class="w-full" id="search" type="text" pInputText (input)="evalFilter(getEventValue($event))">
      <label for="search" class="breakDots">{{'EXERCISE.SEARCH_NAME' | translate}}</label>
    </span>
  </div>
  <div *ngFor="let evaluation of evaluations" class="col-12 sm:col-6 md:col-4 lg:col-3">
    <p-card styleClass="p-card-shadow box">
      <ng-template pTemplate="header">
        <div style="background-color: #16214D; min-height: 30px;"
             class="flex align-content-center flex-wrap justify-content-center banner">
          <div class="flex align-items-center" style="color: white">
            {{'EXERCISE.EVALUATION.EVALUATION' | translate}}: {{evaluation.name}}
          </div>
        </div>
      </ng-template>
      <div class="grid">

        <div class="col-2">{{'COMMON.DATE.DATE' | translate}}</div>
        <div class="col-10">{{evaluation.date}}</div>

        <div class="col-12">{{'EXERCISE.EVALUATION.GOAL' | translate}}</div>
        <textarea pInputTextarea class="col-12" [rows]="3">{{evaluation.goals}}</textarea>

        <div class="col-12">{{'EXERCISE.EVALUATION.EVALUATION' | translate}}</div>
        <textarea pInputTextarea class="col-12" [rows]="3">{{evaluation.evaluation}}</textarea>

        <div class="col-12">{{'EXERCISE.EVALUATION.LEARNINGPOINTS' | translate}}</div>
        <textarea pInputTextarea class="col-12" [rows]="3">{{evaluation.learningPoints}}</textarea>

        <div class="col-12">{{'COMPETENCY.DISABLE_OPTIONS.OTHERS' | translate}}</div>
        <textarea pInputTextarea class="col-12" [rows]="3">{{evaluation.other}}</textarea>

      </div>
    </p-card>
  </div>
</div>
