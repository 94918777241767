import {Injectable} from '@angular/core';
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {combineLatest, Observable, zip} from "rxjs";
import {AccessControlService} from "./access-control.service";
import {map, mergeMap, take} from 'rxjs/operators';
import {SubjectService} from "./subject.service";
import {Station} from "../../model/station";
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})

/**
 * Handles everything that's about a PERSON
 */
export class PersonService {

    tenantId$: Observable<any>;

    constructor(
        private tenantService: TenantService,
        private db: AngularFireDatabase,
        private acService: AccessControlService,
        private subjectService: SubjectService,
        private userService: UserService,
    ) {
        this.tenantId$ = this.tenantService.getTenantId();
    }

    /**
     * Get person Team
     */
    getOwnTeam(): Observable<any> {
        return this.acService.getOwnAccessControl().pipe(
            mergeMap(ac => {
                if (!ac.subjectId) {
                    return new Observable<{ name: any; type: string; key: any }>(subscriber => {
                        subscriber.next(null);
                    });
                } else {
                    const subjectId = ac.subjectId;
                    return combineLatest([this.subjectService.getTeams(), this.subjectService.getRelationByChildId(subjectId)]).pipe(
                        map(([teams, relations]) => {
                            const relation = relations[0].payload.val();
                            const teamMap = new Map(teams.map(team => [team.key, team.payload.val().name]));
                            if (teamMap.has(relation.parentId)) {
                                return {
                                    key: relation.parentId, name: teamMap.get(relation.parentId), type: "FIRETEAM"
                                };
                            } else {
                                return {};
                            }
                        })
                    );
                }
            })
        );
    }

    /**
     * Get person station
     */
    getOwnStation(): Observable<Station> {
        return this.userService.getUserUID().pipe(
            mergeMap(uid => {
                return combineLatest([this.subjectService.getStations(), this.subjectService.getRelations(),
                    this.acService.getSubjectIdFromUID(uid)]).pipe(
                    map(([stations, relations, subjectId]) => {
                        const stationMap = new Map();
                        stations.map(station => {
                            stationMap.set(station.key, station.payload.val().name);
                        });
                        const stationId = this.getStation(subjectId, stationMap, relations);
                        return {
                            key: stationId, name: stationMap.get(stationId), type: "FIRESTATION"
                        };
                    })
                );
            })
        );
    }

    getStation(subjectId, stationMap, relations) {
        if (stationMap.has(subjectId)) {
            return subjectId;
        } else {
            const newRel = relations.find(rel => rel.childId === subjectId);
            return this.getStation(newRel.parentId, stationMap, relations);
        }
    }

    getOwnSubjectId() {
        return this.acService.getOwnAccessControl().pipe(
            map(ac => ac.subjectId)
        );
    }
    getOwnPerson(): Observable<any> {
        return zip(this.tenantId$, this.getOwnSubjectId()).pipe(
            mergeMap(([tenantId, subjectId]) => this.db.object(`tenants/${tenantId}/subjects/${subjectId}`).snapshotChanges()
            )
        );
    }

    getPersonName(uid: string) {
        return zip(this.tenantId$, this.acService.getSubjectIdFromUID(uid)).pipe(
            mergeMap(([tenantId, subjectId]) => this.db.object(`tenants/${tenantId}/subjects/${subjectId}/name`).valueChanges()
            )
        );
    }

    /**
     * Get Persons as Snapshot
     */
    getPersons(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(tenantId => this.db.list(`tenants/${tenantId}/subjects`, ref => {
                return ref.orderByChild('type').equalTo('PERSON');
            }).snapshotChanges())
        );
    }

    /**
     * Get Persons as Snapshot
     */
    getArchivedPersons(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(tenantId => this.db.list(`tenants/${tenantId}/subjects`, ref => {
                return ref.orderByChild('archive').equalTo(true);
            }).snapshotChanges())
        );
    }

    /**
     * @return A payload of Persons without Access
     */
    getPersonsWithoutAccess(): Observable<any> {
        return combineLatest([this.getPersons(), this.acService.getAccessControlsWithSubjectId()]).pipe(
            map(([persons, acs]) => {
                const acMap = new Map(acs.map(ac => [ac.payload.val().subjectId, true]));
                return persons.filter(person => !acMap.has(person.key));
            })
        );
    }

    getPersonsWithAccess(): Observable<any> {
        return combineLatest([this.getPersons(), this.acService.getAccessControlsWithSubjectId(),
            this.acService.getTenantUserAdminDisabled()]).pipe(
            map(([persons, acs, disabledUsers]) => {
                const duMap = new Map(disabledUsers.map(du => [du.key, true]));
                const acMap = new Map<string, any>(acs.map(ac => [ac.payload.val().subjectId, ac]));
                const personArr = [];
                persons.forEach(person => {
                    if (acMap.has(person.key) && !duMap.has(acMap.get(person.key).key)) {
                        personArr.push(
                            {
                                key: person.key,
                                ...person.payload.val(),
                                acKey: (acMap.get(person.key)).key,
                                ...acMap.get(person.key).payload.val()
                            });
                    }
                });
                return personArr;
            })
        );
    }

    getDisabledPersons() {
        return combineLatest([this.getPersons(), this.acService.getAccessControlsPayload(),
            this.acService.getTenantUserAdminDisabled()]).pipe(
            map(([persons, acs, disabledUsers]) => {
                const duMap = new Map(disabledUsers.map(du => [du.key, true]));
                const acMap = new Map<string, any>(acs.map(ac => [ac.payload.val().subjectId, ac]));
                const personArr = [];
                persons.forEach(person => {
                    if (acMap.has(person.key) && duMap.has(acMap.get(person.key).key)) {
                        personArr.push(
                            {
                                key: person.key,
                                ...person.payload.val(),
                                acKey: (acMap.get(person.key)).key,
                                ...acMap.get(person.key).payload.val()
                            });
                    }
                });
                return personArr;
            })
        );
    }

    getTemp() {
        return combineLatest([this.getPersons(), this.acService.getAccessControlsPayload(), this.acService.getTenantUserAdmins()]).pipe(
            map(([persons, acs, users]) => {
                const uMap = new Map(users.map(user => [user.key, user]));
                const acArray = acs.filter(ac => !ac.payload.val().subjectId);
                const personArr = [];
                acArray.forEach(ac => {
                    if (uMap.has(ac.key)) {
                        const user: any = uMap.get(ac.key).payload.val();
                        if (user.operation !== "disabled") {
                            personArr.push(
                                {
                                    acKey: ac.key,
                                    ...ac.payload.val(),
                                    email: user.email
                                }
                            );
                        }
                    }
                });
                return personArr;
            })
        );
    }

    getDisabledTemp() {
        return combineLatest([this.getPersons(), this.acService.getAccessControlsPayload(), this.acService.getTenantUserAdmins()]).pipe(
            map(([persons, acs, users]) => {
                const uMap = new Map(users.map(user => [user.key, user]));
                const acArray = acs.filter(ac => !ac.payload.val().subjectId);
                const personArr = [];
                acArray.forEach(ac => {
                    if (uMap.has(ac.key)) {
                        const user: any = uMap.get(ac.key).payload.val();
                        if (user.operation === "disabled") {
                            personArr.push(
                                {
                                    acKey: ac.key,
                                    ...ac.payload.val(),
                                    email: user.email
                                }
                            );
                        }
                    }
                });
                return personArr;
            })
        );
    }

    /**
     * Get a Map with <uid, personName>
     */
    getUIDPersonNameMap(): Observable<any> {
        return combineLatest([this.acService.getAccessControlsPayload(), this.getPersons()]).pipe(
            map(([acs, persons]) => {

                // Create a <subjectId, UID> Map
                const acMap = new Map<string, string>();
                acs.forEach(ac => {
                    if (ac.payload.val().subjectId) {
                        acMap.set(ac.payload.val().subjectId, ac.key);
                    }
                });

                // Create the <UID, name> Map
                const uidNameMap = new Map<string, string>();
                persons.forEach(person => {
                    if(acMap.has(person.key)){
                        uidNameMap.set(acMap.get(person.key), person.payload.val().name);
                    }
                });

                return uidNameMap;
            })
        );
    }

}
