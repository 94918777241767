<ng-container *ngIf="!loading">
    <div class="grid">
        <!--Complete and Add new Item button-->
        <div class="col-12 flex justify-content-between">

            <button type="button"
                    pButton
                    label="{{'COMMON.COMMENTS' | translate}}{{getNumber()}}"
                    icon="pi pi-comments"
                    class="p-button-outlined p-button-raised"
                    (click)="openComments()"
            >
            </button>

            <button type="button"
                    pButton
                    class="p-button-outlined p-button-raised"
                    label="{{'DEVIATION.CLOSE' | translate}}"
                    icon="pi pi-check-circle"
                    (click)="complete()">
            </button>
        </div>
        <div class="col-6 field m-0">
            <label for="createdBy">{{"COMMON.CREATED_BY" | translate}}</label>
            <input id="createdBy" type="text" class="inputfield w-full" pInputText [ngModel]="getName(deviation)" disabled>
        </div>
        <div class="col-6 field m-0">
            <label for="created">{{'COMMON.CREATED' | translate}}</label>
            <input id="created" type="text" class="inputfield w-full" pInputText [ngModel]="getDate(deviation)"
                   disabled>
        </div>
        <div class="col-12">
            <div class="field m-0">
                <label for="description">{{'COMMON.DESCRIPTION' | translate}}</label>
                <textarea [style]="{maxHeight: '140px'}" id="description" rows="6" cols="30" disabled
                          [(ngModel)]="deviation.description" class="inputfield w-full" pInputTextarea autoResize="autoResize"></textarea>
            </div>

            <div class="field m-0">
                <label for="assetSelect">{{'ASSET.ASSET' | translate}}</label>
                <input id="assetSelect" type="text" class="inputfield w-full" pInputText disabled
                       [ngModel]="assetNameMap.get(deviation.asset)">
            </div>

            <div class="field-checkbox">
                <div class="col-12">
                    <p-checkbox name="inCommission" disabled [binary]="true"
                                [ngModel]="getStatus(deviation)"></p-checkbox>
                    &nbsp;{{'DEVIATION.OUT_COMMISSION' | translate}}
                </div>
            </div>
        </div>
    </div>
</ng-container>
