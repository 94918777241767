import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileData, FileTagRelation} from '../../../model/fileRelated';
import {TreeNode} from 'primeng/api';
import {FileService} from '../../../services/service/file.service';
import {TranslateService} from '@ngx-translate/core';
import {AccessControlService} from '../../../services/service/access-control.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-file-upload-replace',
  templateUrl: './file-upload-replace.component.html',
  styleUrls: ['./file-upload-replace.component.scss']
})
export class FileUploadReplaceComponent implements OnInit {
  fileTag: { createdById: string, key: string, tagName: string };
  tagRelations: FileTagRelation[];
  files: FileData[];
  fileTree: TreeNode[] = [];
  fileMap = new Map<string, FileData>();

  selectedFilesMap = new Map<string, boolean[]>();
  selectedFiles: string[] = [];

  isAdmin$: Observable<boolean>;

  constructor(
      private ref: DynamicDialogRef,
      private config: DynamicDialogConfig,
      private fileService: FileService,
      private translateService: TranslateService,
      private acService: AccessControlService,
  ) {}

  ngOnInit(): void {
    this.isAdmin$ = this.acService.isAdmin();
    const changeNameMap = new Map<string, string>();
    changeNameMap.set("Innsatsøvelse Skipsbrann – brann i passasjerferge og mindre nyttefartøy",
        "Innsatsøvelse Brann i passasjerferge og mindre nyttefartøy");
    changeNameMap.set("Ferdighetsøvelse - Høydeberedskap-stigebil/lift",
        "Ferdighetsøvelse Stigebil/lift");
    changeNameMap.set("Ferdighetsøvelse– motorsag",
        "Ferdighetsøvelse Motorsag");
    changeNameMap.set("Innsatsøvelse Bilbrann – faremomenter",
        "Innsatsøvelse Bilbrann");
    changeNameMap.set("Innsatsøvelse - Bratt lende tauredning",
        "Innsatsøvelse Bratt lende tauredning");
    changeNameMap.set("Innsatsøvelse Brann i elektriske anlegg – lavspenning",
        "Innsatsøvelse Brann i elektriske anlegg");

    this.fileTag = this.config.data.fileTag;
    this.tagRelations = this.config.data.tagRelations;
    this.files = this.config.data.files;

    let tag = this.fileTag.tagName;
    if (changeNameMap.has(this.fileTag.tagName)){
      tag = changeNameMap.get(this.fileTag.tagName);
    }

    const tagNode: TreeNode = {
      label:tag,
      leaf: false,
      selectable: false,
      children: [] = [],
      key: this.fileTag.key,
    };
    const adminNode: TreeNode = {
      label: this.translateService.instant('FILE.ADMIN_FILER'),
      leaf: false,
      selectable: false,
      children: [] = [],
      parent: tagNode,
    };

    const tagMap = new Map<string, string[]>();
    tagMap.set(this.fileTag.key, []);
    this.tagRelations.forEach(relation => {
      if (relation.tagId === this.fileTag.key){
        tagMap.get(relation.tagId).push(relation.fileId)
      }
    });
    this.files.forEach(file => {
      if (tagMap.get(this.fileTag.key).includes(file.$key) && file.archived === false){
        this.fileMap.set(file.$key, file);
        const fileNode: TreeNode = {
          label: file.name,
          data: file,
          leaf: true,
          parent: tagNode,
          key: file.$key,
        };
        if (file.admin === true){
          adminNode.children.push(fileNode)
        } else {
          tagNode.children.push(fileNode);
        }
      }
    });
    if (adminNode.children.length > 0 && this.isAdmin$){
      tagNode.children.push(adminNode);
    }
    this.fileTree.push(tagNode);
  }

  /**
   * Add selected file to archive queue.
   * @param $event File selected
   */
  nodeSelectArchive($event: any) {
    if ($event.node.leaf){
      this.selectedFiles.push($event.node.key);
    } else {
      const children = $event.node.children;
      children.forEach(child => {
        if (child.leaf) {
          this.selectedFiles.push(child.key);
        } else{
          child.children.forEach(grandChild => {
            this.selectedFiles.push(grandChild.key);
          })
        }
      });
    }
  }

  /**
   * Remove unselected file key from archive queue.
   * @param $event File selected
   */
  nodeUnselectArchive($event: any) {
    if ($event.node.leaf){
      const index = this.selectedFiles.indexOf($event.node.key);
      this.selectedFiles.splice(index, 1);
    } else {
      $event.node.children.forEach(child => {
        if (child.leaf) {
          const index = this.selectedFiles.indexOf(child.key);
          this.selectedFiles.splice(index, 1);
        } else {
          child.children.forEach(grandChild => {
            const index = this.selectedFiles.indexOf(grandChild.key);
            this.selectedFiles.splice(index, 1);
          })
        }
      });
    }
  }

  /**
   * Update selected files with "archived = true"
   */
  updateFiles() {
    if (this.selectedFiles.length > 0){
      this.selectedFiles.forEach(key => {
        const file = this.fileMap.get(key);
        file.archived = true;
        this.fileService.updateFile(key, file);
      });
    }
    this.ref.close();
  }
}
