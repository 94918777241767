import { Injectable } from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {map, mergeMap} from "rxjs/operators";
import {Observable} from "rxjs";
import {SideMenuConfig} from "../../model/sideMenuConfig";

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  tenantId$: Observable<any>;

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
  ) {
    /**
     * Find what tenantID current user is connected to
     */
    this.tenantId$ = this.afAuth.authState.pipe(
      mergeMap(auth => {
        return this.db.object(`/userTenant/${auth.uid}`).valueChanges();
      })
    );
  }

  /**
   * Get TenantID
   */
  getTenantId(): Observable<any>{
    return this.tenantId$;
  }

  /**
   * Get TenantID
   */
  getTenantIdNotObservable(){
    return this.tenantId$.pipe();
  }

  /**
   * Get Tenant Object.
   */
  getTenant(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenant => this.db.object(`tenants/${tenant}`).valueChanges()
      )
    );
  }
  /**
   * Get Tenant Object.
   */
  getProjectType(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenant => this.db.object(`tenants/${tenant}/projectType`).valueChanges()
        )
    );
  }
  /**
   * Get Tenant Object.
   */
  getComponents(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenant => this.db.object(`tenants/${tenant}/components`).valueChanges()
        )
    );
  }
  /**
   * Get Tenant Name
   */
  getTenantName(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenant => this.db.object(`tenants/${tenant}/name`).valueChanges()
      )
    );
  }

  /**
   * Get SideMenuConfig, this is used to control what to show on sidemenu.
   */
  getSideMenuConfig(): Observable<SideMenuConfig> {
    return this.tenantId$.pipe(
      mergeMap(
        tenant => this.db.object(`tenants/${tenant}/sideMenuConfig`).valueChanges()
      )
    );
  }

  /**
   * Check if a project is Regional
   */
  isRegional(): Observable<boolean> {
    return this.getTenant().pipe(
      map(tenant => {return (tenant.tenantType === 'REGIONAL');})
    );
  }

  // TODO Activate connection node
  /**
   * Check if IUA
   */
  isIUA(): Observable<boolean> {
    return this.getTenant().pipe(
      map(tenant => {
        return (tenant.connection.IUA);
      })
    );
  }

  /**
   * Check if "BrannDokumentasjon" project.
   */
  isFDC(): Observable<boolean> {
    return this.getTenant().pipe(
      map(tenant => {
        return (tenant.connection.FDC);
      })
    );
  }

  /**
   * Check if STD.
   */
  isSTD(): Observable<boolean> {
    return this.getTenant().pipe(
        map(tenant => {
          return (tenant.connection.STD);
        })
    );
  }
  /**
   * Check if RNT.
   */
  isRNT(): Observable<boolean> {
    return this.getTenant().pipe(
        map(tenant => {
          return (tenant.connection.RNT);
        })
    );
  }
  // /**
  //  * Check if IUA
  //  */
  // isIUA(): Observable<boolean> {
  //   return this.getTenant().pipe(
  //       map(tenant => {
  //         return (tenant.ConnIUA);
  //       })
  //   );
  // }
  //
  // /**
  //  * Check if "BrannDokumentasjon" project.
  //  */
  // isFDC(): Observable<boolean> {
  //   return this.getTenant().pipe(
  //       map(tenant => {
  //         return (tenant.ConnFDC);
  //       })
  //   );
  // }
  //
  // /**
  //  * Check if IKS.
  //  */
  // isIKS(): Observable<boolean> {
  //   return this.getTenant().pipe(
  //       map(tenant => {
  //         return (tenant.ConnIKS);
  //       })
  //   );
  // }
  //
  /**
   * Check if ISL.
   */
  isISL(): Observable<boolean> {
    return this.getTenant().pipe(
        map(tenant => {
          return (tenant.ConnISL);
        })
    );
  }
  //
  // /**
  //  * Check if STD.
  //  */
  // isSTD(): Observable<boolean> {
  //   return this.getTenant().pipe(
  //       map(tenant => {
  //         return (tenant.ConnSTD);
  //       })
  //   );
  // }
  /**
   * Get tenants default language.
   */
  getDefaultLang(): any{
    return this.tenantId$.pipe(
      mergeMap(
        tenant => this.db.object(`tenants/${tenant}/defaultLang`).valueChanges())
    );
  }
}
