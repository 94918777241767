<p-toast position="top-right"></p-toast>
<div class="grid col-12">
  <h2 style="margin-top: 10px">{{'FILE.FILES' | translate}}</h2>
</div>
<ng-container *ngIf="windowWidth > 480; else smallScreenBlock">
  <ng-container *ngIf="userStatus.get('admin') || userStatus.get('superUser')">
    <div class="grid">
      <div class="col-6">
        <ng-container *ngIf="userStatus.get('admin'); else superUserBlock">
          <button type="button" class="p-button-raised" pButton icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
                  (click)="menu.toggle($event)"></button>
          <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </ng-container>
        <ng-template #superUserBlock>
          <button pButton class="p-button-raised" label="{{'FILE.ADD_FILES' | translate}}" (click)="addFile()"
                    [disabled]="fileTags.size < 1"></button>
        </ng-template>
      </div>
      <div class="col-6 flex justify-content-end">
        <p-selectButton [options]="archiveOptions" [(ngModel)]="archiveFiles" optionLabel="name"
                        optionValue="value" class="p-button-raised"></p-selectButton>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #smallScreenBlock>
  <ng-container *ngIf="userStatus.get('admin') || userStatus.get('superUser')">
    <div class="grid">
      <div class="col-12">
        <ng-container *ngIf="userStatus.get('admin'); else superUserBlock">
          <button class="p-button-raised" type="button" pButton icon="pi pi-bars"
                  label="{{'COMMON.MENU' | translate}}" (click)="menu.toggle($event)"></button>
          <p-menu #menu [popup]="true" [model]="items"></p-menu>
        </ng-container>
        <ng-template #superUserBlock>
          <button pButton class="p-button-raised" label="{{'FILE.ADD_FILES' | translate}}" (click)="addFile()"></button>
        </ng-template>
      </div>
      <div class="col-12 flex justify-content-center">
        <p-selectButton [options]="archiveOptions" [(ngModel)]="archiveFiles" optionLabel="name"
                        optionValue="value" class="p-button-raised"></p-selectButton>
      </div>
    </div>
  </ng-container>
</ng-template>

<div class="grid">
  <div class="col-12" *ngIf="!archiveFiles; else archiveBlock">
    <!-- MOCK HEADER -->
    <div class="col-12 flex justify-content-center" style="background-color: #f1f2f3">
      <p-selectButton [options]="stateOptions"
                      [(ngModel)]="fileSelect"
                      optionLabel="name"
                      optionValue="value"
                      class="p-button-raised">
      </p-selectButton>
    </div>
    <ng-container *ngIf="fileSelect === 'flexit'">
      <p-tree [value]="fileTree[2]"
              selectionMode="single" [(selection)]="selectedFile" [filter]="true" loading
              filterPlaceholder="{{'COMMON.SEARCH' | translate}}" (onNodeSelect)="nodeSelect($event)"
      >
      </p-tree>
    </ng-container>
    <ng-container *ngIf="fileSelect === 'internal'">
      <p-tree [value]="fileTree[0]"
              selectionMode="single" [(selection)]="selectedFile" [filter]="true" loading
              filterPlaceholder="{{'COMMON.SEARCH' | translate}}" (onNodeSelect)="nodeSelect($event)"
      >
      </p-tree>
    </ng-container>
  </div>
  <ng-template #archiveBlock>
    <div class="col-12 flex justify-content-end">
      <p-button label="{{'BUTTON.ARCHIVE_FILES' | translate}}" (onClick)="setArchived()"
                [disabled]="selectedFiles.length < 1"></p-button>
    </div>
    <div class="col-12">
      <p-tree [value]="fileTree[1]"
              selectionMode="checkbox" [filter]="true" loading
              filterPlaceholder="{{'COMMON.SEARCH' | translate}}" (onNodeSelect)="nodeSelectArchive($event)"
              (onNodeUnselect)="nodeUnselectArchive($event)">
      </p-tree>
    </div>
  </ng-template>
</div>
