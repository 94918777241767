import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService, FilterService} from 'primeng/api';
import {combineLatest, Subscription} from 'rxjs';
import {Task} from '../../../model/task';
import {SubjectService} from '../../../services/service/subject.service';
import {AssetService} from '../../../services/service/asset.service';
import {PersonService} from '../../../services/service/person.service';
import {AuthService} from '../../../services/service/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {TaskService} from '../../../services/service/task.service';
import {CalendarService} from '../../../services/service/calendar.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import {TaskSharedService} from '../../../services/sharedService/task-shared.service';
import * as moment from 'moment';

@Component({
    selector: 'app-view-completed',
    templateUrl: './view-completed.component.html',
    styleUrls: ['./view-completed.component.scss'],
    providers: [ConfirmationService]
})
export class ViewCompletedComponent implements OnInit, OnDestroy {
    acRole;
    subscription = new Subscription();

    task: Task;

    assetNameMap;
    stationAssetMap;
    assetStationMap;
    asMap;
    personNameMap;
    teamNameMap;
    personParentNameMap;

    groupedSubjectFilter;
    groupedAssetsFilter;

    selectedSubjects: any[] = [];
    selectedPerson: '';
    selectedLeader = "";

    outputPerson = [];
    personMap = new Map();

    windowWidth

    constructor(
        private subjectService: SubjectService,
        private assetService: AssetService,
        private personService: PersonService,
        private filterService: FilterService,
        private authService: AuthService,
        private translateService: TranslateService,
        private taskService: TaskService,
        private calendarService: CalendarService,
        private ref: DynamicDialogRef,
        private gSService: GroupedSubjectsService,
        private config: DynamicDialogConfig,
        private acService: AccessControlService,
        private tsService: TaskSharedService
    ) {
        this.windowWidth = window.innerWidth;

    }

    ngOnInit(): void {

        this.task = this.config.data.task;
        this.selectedLeader = this.task.responsible;

        this.subscription.add(combineLatest([
            this.personService.getPersons(),
            this.subjectService.getTeams(),
            this.subjectService.getStations(),
            this.subjectService.getRelations(),
            this.assetService.getAssets(),])
            .subscribe(([persons, teams, stations, relations, assets]) => {

                const data = this.gSService.build(persons, teams, relations);
                this.personNameMap = data[0];
                this.teamNameMap = data[1];
                this.personParentNameMap = data[2];
                this.groupedSubjectFilter = data[3];

                const assetData = this.tsService.buildAssets(assets, stations);
                this.stationAssetMap = assetData[0];
                this.assetStationMap = assetData[1];
                this.assetNameMap = assetData[2];
                this.groupedAssetsFilter = assetData[3];

                for (const subjectsKey in this.task.subjects) {
                    if (!this.personMap.has(subjectsKey)) {
                        this.personMap.set({label: this.personNameMap.get(subjectsKey), value: subjectsKey}, true);
                        this.selectedSubjects.push(subjectsKey);
                    }
                }
                this.asMap = new Map<string, string>();
                assets.forEach(asset => {
                    this.asMap.set(asset.key, asset.payload.val().name);
                });
                stations.forEach(station => {
                    this.asMap.set(station.key, station.payload.val().name);
                });
            })
        );
    }


    getAssetStation(assetId: string) {
        if (this.asMap) {
            return this.asMap.get(assetId);
        }
    }

    getTime(deadline: string) {
        return moment(deadline).format('HH:mm');
    }

    getDate(deadline: string) {
        return moment(deadline).format('DD MMM YYYY');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getLeader(personId: any) {
        return this.task.responsible === personId;
    }
}
