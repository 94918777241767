import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {SubjectService} from '../../../services/service/subject.service';
import {AssetService} from '../../../services/service/asset.service';
import {PersonService} from '../../../services/service/person.service';
import {FilterService, MessageService} from 'primeng/api';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Task} from '../../../model/task';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';
import * as moment from 'moment';
import {CalendarEvent} from '../../../model/calendarEvent';
import {TranslateService} from '@ngx-translate/core';
import {TaskService} from '../../../services/service/task.service';
import {CalendarService} from '../../../services/service/calendar.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {TaskSharedService} from '../../../services/sharedService/task-shared.service';

@Component({
    selector: 'app-add-task',
    templateUrl: './add-task.component.html',
    styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit, OnDestroy {

    taskForm: UntypedFormGroup;

    windowWidth;
    subscription;

    outputPerson = [];
    personMap = new Map();

    assetNameMap;
    stationAssetMap;
    assetStationMap;
    personNameMap;
    teamNameMap;
    personParentNameMap;

    groupedSubjectFilter: any[];
    groupedAssetsFilter: any[];

    selectedSubjects: any[] = [];
    selectedPerson = "";
    selectedLeader = "";

    smsNotification: boolean;
    smsDaysBefore: number;
    emailNotification: boolean;
    emailDaysBefore: number;

    constructor(
        private subjectService: SubjectService,
        private assetService: AssetService,
        private personService: PersonService,
        private filterService: FilterService,
        private authService: AuthService,
        private translateService: TranslateService,
        private taskService: TaskService,
        private calendarService: CalendarService,
        private ref: DynamicDialogRef,
        private gsService: GroupedSubjectsService,
        private tsService: TaskSharedService,
        private messageService: MessageService,
    ) {
        this.windowWidth = window.innerWidth;
    }

    ngOnInit(): void {

        this.taskForm = new UntypedFormGroup({
            taskName: new UntypedFormControl('', Validators.required),
            assetId: new UntypedFormControl(''),
            date: new UntypedFormControl('', Validators.required),
            time: new UntypedFormControl(''),
            description: new UntypedFormControl('', Validators.required),
        });

        this.subscription = new Subscription();
        this.subscription.add(combineLatest([
            this.personService.getPersons(),
            this.subjectService.getTeams(),
            this.subjectService.getStations(),
            this.subjectService.getRelations(),
            this.assetService.getAssets(),])
            .subscribe(([persons, teams, stations, relations, assets]) => {

                const subjectData = this.gsService.build(persons, teams, relations);
                this.personNameMap = subjectData[0];
                this.teamNameMap = subjectData[1];
                this.personParentNameMap = subjectData[2];
                this.groupedSubjectFilter = <any[]> subjectData[3];

                const assetData = this.tsService.buildAssets(assets, stations);
                this.stationAssetMap = assetData[0];
                this.assetStationMap = assetData[1];
                this.assetNameMap = assetData[2];
                this.groupedAssetsFilter = <any[]> assetData[3];
            })
        );
    }

    /**
     * Add team
     * @param team Team object
     */
    addTeam(team: any) {
        team.items.forEach(participant => this.addPerson(participant));
    }

    /**
     * Add Person
     * @param subject Subject to add
     */
    addPerson(subject){
        if (!this.personMap.has(subject)) {
            this.personMap.set(subject, true);
            this.selectedSubjects.push(subject.value);
        }
    }

    /**
     * Filter person by name
     * @param event Search event
     */
    filterPerson(event) {
        const query = event.query;
        const filteredGroups = [];

        for (const group of this.groupedSubjectFilter) {
            if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
                filteredGroups.push(group);
            } else {
                const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
                if (filteredSubOptions && filteredSubOptions.length) {
                    filteredGroups.push({
                        label: group.label,
                        value: group.value,
                        items: filteredSubOptions
                    });
                }
            }
        }
        this.outputPerson = filteredGroups;
    }

    /**
     * Remove person
     * @param personId
     */
    removePerson(personId: any) {
        this.personMap.delete(personId);

        const index = this.selectedSubjects.findIndex(pId => pId === personId);
        if(index >= 0) {
            this.selectedSubjects.splice(index, 1);
        }
        if (this.selectedLeader === personId){
            this.selectedLeader = "";
        }
    }

    /**
     * OnSubmit save task and calender event
     */
    onSubmit() {
        if (this.taskForm.value.taskName.length > 20){
            this.messageService.add({severity:'error', summary: 'Error',  life: 3000,
                detail: this.translateService.instant('DEVIATIONS.NAME.ERROR')});
        } else if(
            (this.smsNotification && moment(this.taskForm.value.date).subtract(this.smsDaysBefore, "days").toISOString()
                < moment().toISOString()) ||
            (this.emailNotification && moment(this.taskForm.value.date).subtract(this.emailDaysBefore, "days").toISOString()
                < moment().toISOString())
        ){
            this.messageService.add({severity:'error', summary: 'Error',  life: 3000,
                detail: this.translateService.instant('NOTIFICATIONS.ERROR_NOTIFICATION')});
        } else {
            const ongoingForm = this.taskForm.value;
            this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

                if (ongoingForm.time === "") {
                    ongoingForm.time = '23:59:59';
                }
                const date = moment(ongoingForm.date).format("YYYY-MM-DD");
                const deadline = moment(date + " " + ongoingForm.time);

                let assetId = ongoingForm.assetId;
                let station = this.assetStationMap.get(ongoingForm.assetId);
                if (this.stationAssetMap.has(ongoingForm.assetId)) {
                    assetId = "";
                    station = ongoingForm.assetId;
                }


                const newTask: Task = {
                    description: ongoingForm.description,
                    name: ongoingForm.taskName,
                    createdById: uid,
                    responsible: this.selectedLeader,
                    assetId: assetId,
                    station: station,
                    deadline: deadline.toISOString(),
                    subjects: [],
                };
                if (this.smsNotification){
                    newTask.smsNotification = true;
                    newTask.smsDaysBefore = this.smsDaysBefore;
                }
                if (this.emailNotification){
                    newTask.emailNotification = true;
                    newTask.emailDaysBefore = this.emailDaysBefore;
                }

                const calendarEvent: CalendarEvent = {
                    completed: false,
                    calendarType: "TASK",
                    start: deadline.toISOString(),
                    end: deadline.toISOString(),
                    title: this.translateService.instant('TASK.TASK') + ": " + ongoingForm.taskName
                };
                this.taskService.addTaskGetKey(newTask).pipe(take(1)).subscribe(tKey => {
                    calendarEvent.taskId = tKey;
                    this.calendarService.addCalendarEvent(calendarEvent, tKey);
                    this.selectedSubjects.forEach(subjectId => {
                        this.taskService.addSubjectsToTask(tKey, subjectId, true);
                    });
                    this.ref.close();
                });

            });
        }
    }

    close() {
        this.ref.close();
    }

    /**
     * Unsubscribe all
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
