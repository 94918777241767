<!-- Toast -->
<p-toast key='exercise-add' position="top-right" styleClass="customToast"></p-toast>


<div class="grid">
  <!-- Name -->
  <div class="field col-12 mb-0 mt-3">
          <span class="p-float-label">
            <input [ngStyle]="validation(exercise.name)" [readOnly]="readOnly" id="name" type="text"
                   [(ngModel)]="exercise.name" pInputText class="inputfield w-full">
            <label for="name">{{'COMMON.NAME' | translate}}</label>
          </span>
  </div>

  <!-- Date From -->
  <div class="field col-6 mb-0">
    <label for="fromDate">{{'COMMON.FROM' | translate}}</label>
    <input [ngStyle]="validation(dateFrom)" [readOnly]="readOnly" type="date" id="fromDate" (change)="dateTo = dateFrom"
           [(ngModel)]="dateFrom" pInputText class="inputfield w-full">
  </div>

  <!-- Time From -->
  <div class="field col-6 mb-0">
    <label for="fromTime">&nbsp;</label>
    <input [ngStyle]="validation(timeFrom)" [readOnly]="readOnly" type="time" id="fromTime" [(ngModel)]="timeFrom" pInputText class="inputfield w-full">
  </div>

  <!-- Date To -->
  <div class="field col-6 mb-0">
    <label for="toDate">{{'COMMON.TO' | translate}}</label>
    <input [ngStyle]="validation(dateTo)" [readOnly]="readOnly" type="date" id="toDate" [(ngModel)]="dateTo" pInputText class="inputfield w-full">
  </div>

  <!-- Time From -->
  <div class="field col-6 mb-0">
    <label for="toTime">&nbsp;</label>
    <input [ngStyle]="validation(timeTo)" type="time" [readOnly]="readOnly" id="toTime" [(ngModel)]="timeTo" pInputText class="inputfield w-full">
  </div>

  <!-- Description -->
  <div class="field col-12 mt-2 mb-0">
    <span class="p-float-label">
      <textarea id="description"
                rows="4"
                style="resize: none;"
                cols="30"
                pInputTextarea
                [(ngModel)]="exercise.details"
                class="inputfield w-full"></textarea>
      <label for="description">{{'COMMON.DESCRIPTION' | translate}}</label>
    </span>
  </div>

  <!-- Goals -->
  <div class="field col-12 mt-2 mb-0">
    <span class="p-float-label">
      <textarea id="goals"
                rows="4"
                style="resize: none;"
                cols="30"
                pInputTextarea
                [(ngModel)]="exercise.evaluation.goal"
                class="inputfield w-full"></textarea>
      <label for="goals">{{'EXERCISE.EVALUATION.GOAL' | translate}}</label>
    </span>
  </div>

</div>

<!-- Notification -->
<div class="grid col-12" *ngIf="!readOnly">
  <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
</div>

<!-- SMS - Notification -->
<div class="grid" *ngIf="!readOnly">
  <div class="field-checkbox col-4 flex align-items-center">
    <p-checkbox id="smsNotification" binary="true" [disabled]="readOnly"
                [(ngModel)]="exercise.smsNotification"></p-checkbox>
    <label for="smsNotification">{{'BUTTON.SMS' | translate}}</label>
  </div>

  <div class="field col-6">
    <p-inputNumber id="smsDaysBefore" [disabled]="!exercise.smsNotification || readOnly" [(ngModel)]="exercise.smsDaysBefore"
                   [showButtons]="true" [min]="1" [ngStyle]="validationSMSNotification()"
                   placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                   [max]="100" class="inputfield w-full"></p-inputNumber>
  </div>
</div>

<!-- Email - Notification -->
<div class="grid align-items-end" *ngIf="!readOnly">
  <div class="field-checkbox col-4 flex align-items-center">
    <p-checkbox id="emailNotification" [disabled]="readOnly" binary="true" [(ngModel)]="exercise.emailNotification"></p-checkbox>
    <label for="emailNotification">{{'PERSONAL.EMAIL' | translate}}</label>
  </div>

  <div class="field col-6">
    <p-inputNumber id="emailDaysBefore" [disabled]="!exercise.emailNotification || readOnly"
                   [(ngModel)]="exercise.emailDaysBefore" [showButtons]="true" [min]="1"
                   [ngStyle]="validationEmailNotification()" placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                   [max]="100" class="inputfield w-full"></p-inputNumber>
  </div>
</div>

<!-- BUTTONS -->
<div class="grid" style="margin-top: 10px">
  <div class="col">
    <button *ngIf="!readOnly" class="float-right p-button-raised" pButton icon="pi pi-bookmark"
            type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="save()"></button>
    <button *ngIf="!readOnly" pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
            (click)="close()" class="p-button-outlined float-right p-button-raised"></button>
  </div>
</div>
