import {Component, OnDestroy, OnInit} from '@angular/core';
import {FunctionModel} from "../../../model/function";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {Subscription} from "rxjs";
import {FilterService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {FunctionService} from "../../../services/service/function.service";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-add-function',
  templateUrl: './add-function.component.html',
  styleUrls: ['./add-function.component.scss']
})
export class AddFunctionComponent implements OnInit, OnDestroy {

  function: FunctionModel = {
    createdBy: "", createdDate: "", name: "", competencyTypes: {}
  };

  competencyTypes = [];
  ctList = [];
  selectedCT: any = '';

  output = [];
  ctMap = new Map();

  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private ctService: CompetencyTypeService,
    private filterService: FilterService,
    public translateService: TranslateService,
    private functionService: FunctionService,
    private authService: AuthService,
  ) {
    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.competencyTypes = cts.map(ct => {
          return {key: ct.key, ...ct.payload.val()};
        }).sort((a, b) => a.name > b.name ? 1 : -1);
      })
    );
  }


  ngOnInit(): void {}


  search(event: any) {
    this.output = this.filterService.filter(this.competencyTypes, ['name'], event.query, "contains");
  }

  addCompetencyType() {
    if(!this.ctMap.has(this.selectedCT.key)){
      this.ctList.push(this.selectedCT);
      this.ctMap.set(this.selectedCT.key, {validity: 0});
      this.ctList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
  }

  delete(ct) {
    const index = this.ctList.findIndex(Fct => Fct.key === ct.key);
    if(index >= 0){
      this.ctList.splice(index, 1);
      this.ctMap.delete(ct.key);
    }
  }

  addFunction() {
    this.authService.getUserUID().pipe(
      take(1),
    ).subscribe(uid => {
      this.function.createdBy = uid;
      this.function.createdDate = new Date().toISOString();
      this.function.competencyTypes = Object.fromEntries(this.ctMap);
      this.functionService.addFunction(this.function);
      this.ref.close();
    });
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
