<p-toast position="top-center"></p-toast>
<div class="grid">
    <div class="col-12 mt-3">
       <span class="p-float-label">
            <input id="email1" type="text" pInputText [(ngModel)]="eMail1" [style]="{'width' : '100%'}">
            <label for="email1">{{'PERSONAL.EMAIL' | translate}}</label>
        </span>
    </div>
    <div class="col-12 mt-2">
        <span class="p-float-label">
            <input id="email2" type="text" pInputText [(ngModel)]="eMail2" [style]="{'width' : '100%'}">
            <label for="email2">{{'MY_PAGE.REPEAT_EMAIL' | translate}}</label>
        </span>
    </div>
    <div class="col-12 flex justify-content-center mt-2">
        <button [disabled]="!eMail1" class="p-button-raised"
                pButton label="{{'MY_PAGE.UPDATE_EMAIL' | translate}}" (click)="updateEmail()"></button>
    </div>
</div>
