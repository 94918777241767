import { Pipe, PipeTransform } from '@angular/core';
import {EmergencyParticipant} from '../model/participant';

@Pipe({
  name: 'EventFilter'
})
export class EventFilterPipe implements PipeTransform {

  /**
   * Filter emergencies on address, person or station
   * @param Event Event[]
   * @param personNameMap key -> name link [{key: personId, name: person name}]
   * @param search
   */
  transform(Event: any[], personNameMap: Map<string, string>, search?: string): any {

    if(!Event || !search){
      return Event;
    } else {
      const addressSearch = Event.filter(emergency => emergency.location.address.toLowerCase().includes(search.toLowerCase()));
      const nameSearch = Event.filter(emergency => this.checkNames(emergency.participants, personNameMap, search));
      return addressSearch.filter(x => !nameSearch.includes(x)).concat(nameSearch.filter(x => !addressSearch.includes(x)));
    }
  }

  /**
   * Search trough participants for match
   * @param participants Emergency participants
   * @param personNameMap Person {key --> name} map
   * @param search Search string
   */
  checkNames(participants: EmergencyParticipant[], personNameMap, search){
    if(participants){
      return participants.some(participant =>
        personNameMap.get(participant.key).toLowerCase().includes(search.toLowerCase())
      );
    }
  }
}
