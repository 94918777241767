import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { AddComponent } from './add/add.component';
import { TypesComponent } from './types/types.component';
import {Route} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MenuModule} from 'primeng/menu';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import { ViewDeviationComponent } from './view-deviation/view-deviation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CheckboxModule} from 'primeng/checkbox';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CanActivateService} from '../../services/can-activate.service';

export const AssetsRoutes: Route = {
  path: 'assets', canActivate: [CanActivateService],
  children: [
    {path: 'add', component: AddComponent},
    {path: 'main', component: MainComponent},
    {path: 'types', component: TypesComponent},
  ]
};

@NgModule({
  declarations: [
    MainComponent,
    AddComponent,
    TypesComponent,
    ViewDeviationComponent,
  ],
    imports: [
        CommonModule,
        TranslateModule,
        MenuModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        CheckboxModule,
        SplitButtonModule,
        ToastModule,
        TableModule,
        AutoCompleteModule,
        DropdownModule,
        ReactiveFormsModule,
        SelectButtonModule,
        ConfirmDialogModule
    ]
})
export class AssetsModule { }
