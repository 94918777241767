import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompetenciesComponent } from './competencies/competencies.component';
import {Route} from "@angular/router";
import { CompetenciesListComponent } from './competencies-list/competencies-list.component';
import {TranslateModule} from "@ngx-translate/core";
import {TableModule} from "primeng/table";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from "primeng/inputtext";
import {PipesModule} from "../../pipes/pipes.module";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {BlockUIModule} from "primeng/blockui";
import { CompetencyTypesComponent } from './competency-types/competency-types.component';
import { CompetencyDisabledComponent } from './competency-disabled/competency-disabled.component';
import { CompetencyMissingComponent } from './competency-missing/competency-missing.component';
import { CompetencyCreateExerciseComponent } from './competency-create-exercise/competency-create-exercise.component';
import { EditCompetencyTypeComponent } from './edit-competency-type/edit-competency-type.component';
import {SplitButtonModule} from "primeng/splitbutton";
import {ToastModule} from "primeng/toast";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { MyCompetenciesComponent } from './my-competencies/my-competencies.component';
import { SetCompetenciesComponent } from './set-competencies/set-competencies.component';
import {TreeModule} from 'primeng/tree';
import {CalendarModule} from 'primeng/calendar';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CanActivateService} from '../../services/can-activate.service';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { CompetencyHistoryComponent } from './competency-history/competency-history.component';

export const CompetencyRoutes: Route = {
  path: 'competencies',
  children: [
    {path: 'active', component: CompetenciesComponent, canActivate: [CanActivateService]},
    {path: 'competencyTypes', component: CompetencyTypesComponent, canActivate: [CanActivateService]},
    {path: 'disabled', component: CompetencyDisabledComponent, canActivate: [CanActivateService]},
    {path: 'missing', component: CompetencyMissingComponent, canActivate: [CanActivateService]},
    {path: 'create', component: CompetencyCreateExerciseComponent, canActivate: [CanActivateService]},
    {path: 'my-competencies', component: MyCompetenciesComponent},
  ]
};


@NgModule({
    declarations: [
        CompetenciesComponent,
        CompetenciesListComponent,
        CompetencyTypesComponent,
        CompetencyDisabledComponent,
        CompetencyMissingComponent,
        CompetencyCreateExerciseComponent,
        EditCompetencyTypeComponent,
        MyCompetenciesComponent,
        SetCompetenciesComponent,
        CompetencyHistoryComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        PipesModule,
        ButtonModule,
        RippleModule,
        ProgressSpinnerModule,
        BlockUIModule,
        SplitButtonModule,
        ToastModule,
        ConfirmDialogModule,
        TreeModule,
        CalendarModule,
        ReactiveFormsModule,
        InputTextareaModule,
        AutoCompleteModule
    ]
})
export class CompetencyModule { }
