import { Component, OnInit } from '@angular/core';
import {AccessControlService} from "../../../services/service/access-control.service";
import {combineLatest, Subscription} from 'rxjs';
import {InformationService} from '../../../services/service/information.service';
import {PersonService} from '../../../services/service/person.service';
import {SmsService} from '../../../services/service/sms.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {OrderSMSComponent} from '../order-sms/order-sms.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [DialogService]
})
export class DashboardComponent implements OnInit {
    subscription = new Subscription();
    infoText: any[] = [];
    loading = true;
    availableSMS: number;
    isAdmin$: boolean;
    constructor(
        private infoService: InformationService,
        private personService: PersonService,
        private smsService: SmsService,
        private acService: AccessControlService,
        private dialogService: DialogService,
        private translateService: TranslateService,
    ) {
        this.subscription.add(combineLatest(
            [this.infoService.getInfoMessages(), this.personService.getOwnStation(),
                this.smsService.getAvailableSMS(), this.acService.isAdmin()])
            .subscribe(([infoMessages, ownStation, availableSMS, isAdmin]) => {
                infoMessages.map(messages => {
                    if (messages.key === ownStation.key){
                        messages.payload.val().forEach(message => {
                            this.infoText.push({text: message});
                        });
                    }
                });
                this.availableSMS = <number> availableSMS;
                this.isAdmin$ = isAdmin;
                this.loading = false;
            }));
    }

    /**
     * Order sms
     */
    orderSMS() {
        this.dialogService.open(OrderSMSComponent, {
            header: this.translateService.instant('DASHBOARD.ORDER_SMS (2)'),
            styleClass: "min-size-dialog"
        });
    }

    ngOnInit(): void {
    }
}
