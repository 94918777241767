import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService, SortEvent} from 'primeng/api';
import {AddRoleComponent} from '../add-role/add-role.component';
import {Roles, RoleType} from '../../../model/role';
import {combineLatest, Subscription} from 'rxjs';
import {RolesService} from '../../../services/service/roles.service';
import {PersonService} from '../../../services/service/person.service';
import {AccessControlService} from "../../../services/service/access-control.service";
import {RolesStationTeamFilterPipe} from '../../../pipes/roles-station-team-filter.pipe';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {SubjectService} from '../../../services/service/subject.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  providers: [ConfirmationService, DialogService, RolesStationTeamFilterPipe],
})
export class RoleComponent implements OnInit, OnDestroy {

  // Persons
  personNameMap = new Map(); // Map {{key: personKey_1, value: person_name}, ..., {key: personKey_n, value: person_name}}

  // Roles
  roleTypes: RoleType[];
  roleTypesMap = new Map<string, string>();

  tableRoles: Roles[] = [];
  addedRolesMap = new Map<string, Roles[]>();

  subscription = new Subscription();

  selectedYear: string = this.translateService.instant('ROLES.YEARS_ALL');
  years: any[] = [{YYYY: this.translateService.instant('ROLES.YEARS_ALL')}];
  search = '';

  isAdmin$;
  isSuper$;

  myPersonId: string;

  teamFilterMap; // Map of subjects under station / team
  groupedFilter: any[];
  selectedTeam: string;
  teamName: string;

  constructor(
      private translateService: TranslateService,
      private dialogService: DialogService,
      private rolesService: RolesService,
      private personService: PersonService,
      private acService: AccessControlService,
      private subjectService: SubjectService,
      private gSService: GroupedSubjectsService,
      private stFilter: RolesStationTeamFilterPipe,
  ) {
    let groupedSubjectFilter;

    this.subscription.add(
        combineLatest([
          this.personService.getOwnSubjectId(),
          this.rolesService.getRoles(),
          this.acService.getOwnAccessControl(),
          this.rolesService.getRoleTypes(),
          this.personService.getPersons(),
          this.subjectService.getTeams(),
          this.subjectService.getStations(),
          this.subjectService.getRelations(),
          this.acService.isAdmin(),
          this.acService.isSuperUser()
        ])
            .subscribe(([id, roles, ac, roleTypes, persons, teams, stations, relations, isAdmin, isSuper]) => {
              this.isAdmin$ = isAdmin;
              this.isSuper$ = isSuper;
              this.myPersonId = id;

              this.groupedFilter = this.gSService.build(teams, stations, relations)[3];
              const data = this.gSService.build(persons, teams, relations);
              this.personNameMap = data[0];
              groupedSubjectFilter = data[3];
              this.roleTypes = roleTypes.map(roleType => {
                this.roleTypesMap.set(roleType.key, roleType.payload.val().name);
                return {key: roleType.key, ...roleType.payload.val()};
              });

              const added = [];
              if (this.isAdmin$ || this.isSuper$) {
                this.addedRolesMap.set(this.translateService.instant('ROLES.YEARS_ALL'), []);
                roles.map(role => {
                  const roleDate = role.payload.val().date.split('.');
                  if (!added.includes(roleDate[2])){
                    added.push(roleDate[2]);
                    this.addedRolesMap.set(roleDate[2], [{key: role.key, ...role.payload.val()}]);
                    this.years.push({YYYY: roleDate[2]});
                  } else {
                    this.addedRolesMap.get(roleDate[2]).push({key: role.key, ...role.payload.val()});
                  }
                  this.addedRolesMap.get(this.translateService.instant('ROLES.YEARS_ALL')).push({key: role.key, ...role.payload.val()});
                  return {key: role.key, ...role.payload.val()};
                });
              } else {
                this.addedRolesMap.set(this.translateService.instant('ROLES.YEARS_ALL'), []);
                roles.map(role => {
                  if (ac.subjectId === role.payload.val().personKey) {
                    const roleDate = role.payload.val().date.split('.');
                    if (!added.includes(roleDate[2])) {
                      added.push(roleDate[2]);
                      this.addedRolesMap.set(roleDate[2], [{key: role.key, ...role.payload.val()}]);
                      this.years.push({YYYY: roleDate[2]});
                    } else {
                      this.addedRolesMap.get(roleDate[2]).push({key: role.key, ...role.payload.val()});
                    }
                    this.addedRolesMap.get(this.translateService.instant('ROLES.YEARS_ALL')).push({key: role.key, ...role.payload.val()});
                    this.tableRoles.push({key: role.key, ...role.payload.val()});
                  }
                });
              }

              relations.every(relation => {
                if (relation.childId === this.myPersonId){
                  this.selectedTeam = relation.parentId;
                } else {
                  return true;
                }
              });

              this.teamFilterMap = new Map<string, []>();
              groupedSubjectFilter.forEach(team => {
                relations.every( relation => {
                  if (relation.childId === team.value){
                    if (this.teamFilterMap.has(relation.parentId)){
                      this.teamFilterMap.get(relation.parentId).concat(team.items);
                    } else {
                      this.teamFilterMap.set(relation.parentId, team.items);
                    }
                  } else {
                    return true;
                  }
                });
              });
              groupedSubjectFilter.forEach(team => {
                this.teamFilterMap.set(team.value, team.items);
              });

              this.filterRoles();
            })
    );
  }

  ngOnInit() {

  }

  /**
   * Add new role
   */
  addRole() {
    this.dialogService.open(AddRoleComponent, {
      header: this.translateService.instant('ROLES.ROLES'),
      width: "300px"
    });
  }

  /**
   * Get person name
   * @param role  Role interface object
   */
  getPersonName(role: any) {
    return this.personNameMap.get(role.personKey);
  }

  /**
   * Get role type
   * @param role Role interface object
   */
  getRoleType(role: Roles) {
    return this.roleTypesMap.get(role.typekey);
  }

  /**
   * Sort by role type
   * @param event Click event
   */
  customSort(event: SortEvent) {
    event.data.sort((roleA, roleB) => {
      const value1 = roleA.typekey;
      const value2 = roleB.typekey;
      let result;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });
  }

  /**
   * Filter roles by station or team
   */
  filterRoles() {
    this.tableRoles = this.stFilter.transform(this.addedRolesMap.get(this.selectedYear), this.teamFilterMap.get(this.selectedTeam));
  }

  /**
   * Set station
   * @param object dropdown object
   */
  setStationTeam(object: any) {
    this.selectedTeam = object.value;
    this.teamName = object.label;
  }

  /**
   * Check screen size.
   */
  screenWidth() {
    return window.innerWidth < 441;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
