import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "primeng/api";
import {MenuService} from "../../services/service/menu.service";
import {TenantService} from "../../services/service/tenant.service";
import {Observable, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-side-nav',
  templateUrl: './main-frame.component.html',
  styleUrls: ['./main-frame.component.scss']
})
export class MainFrameComponent implements OnInit, OnDestroy {
  isExpanding = true;
  sideMenu: Observable<MenuItem[]>;
  selectedPerson: any;
  persons: string[] = ['TODO'];
  filteredPersons;
  tenantName: string;
  bigView = true;
  display = true;

  subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    public tenantService: TenantService,
    private menuService: MenuService,
    private router: Router
  ) {

    /**
     * Close side menu on router change on smaller devices
     */
    this.subscription.add(
      this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
          if(!window.matchMedia('(min-width: 1200px)').matches){
            this.isExpanding = false;
            this.bigView = false;
          }
        }
      })
    );
  }

  ngOnInit(): void {

    /**
     * Get tenantName
     */
    this.tenantService.getTenantName().pipe(
      take(1)
    ).subscribe(name => {
      this.tenantName = name;
    });

    /** Default hide side menu on media smaller than 1920px **/
    const mql = window.matchMedia('(min-width: 1920px)');
    this.isExpanding = mql.matches;

    /** Build onInit **/
    this.sideMenu = this.menuService.buildSideMenu();

    /** To use I18N on PrimeNG panelMenu we have to rebuild MenuItem on language change **/
    this.translateService.onLangChange.subscribe(() => {
      this.sideMenu = this.menuService.buildSideMenu();
    });
  }

  /**
   * Toggle sidebar
   */
  toggleSideBar() {
    this.isExpanding = !this.isExpanding;
  }

  /**
   * Search for a person in Persons
   * @param $event
   */
  searchPerson($event: any) {
    const query = $event.query;
    this.filteredPersons = this.persons.filter(name => name.toLowerCase().indexOf(query.toLowerCase()) === 0);
  }

  /**
   * Unsubscribe onDestroy to prevent memory leek
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
