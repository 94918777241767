import { Component, OnInit } from '@angular/core';
import {EmergencyService} from '../../../services/service/emergency.service';
import {Subscription} from 'rxjs';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {EmergencyTypePipe} from '../../../pipes/emergency-type.pipe';

@Component({
    selector: 'app-emergency-types',
    templateUrl: './emergency-types.component.html',
    styleUrls: ['./emergency-types.component.scss'],
    providers: [ConfirmationService, EmergencyTypePipe]
})
export class EmergencyTypesComponent implements OnInit {
    loading = true;
    types: any[];
    subscription = new Subscription();
    typeName: string;
    constructor(
        private emergencyService: EmergencyService,
        private translateService: TranslateService,
        private confirmationService: ConfirmationService,
        private emergencyTypeFilter: EmergencyTypePipe,
    ) { }

    ngOnInit(): void {
        this.subscription.add(this.emergencyService.getTypes().subscribe(types => {
                this.types = [];
                types.forEach(t => {
                    if(!t.payload.val().archived){
                        this.types.push({key: t.key, name: t.payload.val().name});
                    }
                });
                this.loading = false;
            })
        );
    }
    /**
     * Confirm Archive or of creation fileTag
     * @param type Tag Name string
     */
    confirm(type) {
        this.confirmationService.confirm({
            message: this.translateService.instant('WARNING.ARE_YOU_SURE_ARCHIVE'),
            acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
            rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
            accept: () => {
               this.archive(type);
            }
        });
    }

    archive(type) {
        const emergencyType = {
            name: type.name,
            type: "emergency",
            archived: true
        };
        this.emergencyService.updateType(type.key, emergencyType);
    }

    /**
     * Search for existing tag name.
     * @param $event
     */
    searchExisting($event: any) {
        this.types = this.emergencyTypeFilter.transform(this.types, $event.query);
    }

    save(typeName: string) {
        const emergencyType = {
            name: typeName,
            type: "emergency"
        };
        this.emergencyService.addType(emergencyType);
        this.typeName = null;
    }
}
