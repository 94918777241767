<div class="grid">
  <div class="col-12">

    <!-- PERSON TABLE -->
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="tableArray"
             dataKey="key">

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 2rem"></th>
          <th>{{'FDC.PERSONS' | translate}}</th>
          <th>
            <span class="flex justify-content-center">
              <i style="color: #F5A623" class="far fa-exclamation-triangle"></i>
            </span>
          </th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body" let-person>
        <tr [pRowToggler]="person">
          <td></td>
          <td>{{personNameMap.get(person.key)}}</td>
          <td>
            <span class="flex justify-content-center">
              {{countExposures(person.key)}}
            </span>
          </td>
        </tr>
      </ng-template>

      <!-- Row expansion -->
      <ng-template pTemplate="rowexpansion" let-person>
        <tr>
          <td colspan="3">
            <div class="grid">

              <!-- MOCK HEADER -->
              <div class="col-2 flex justify-content-center" style="background-color: #f8f9fa">
                <i style="color: #F5A623" class="far fa-exclamation-triangle"></i>
              </div>
              <div class="col-6" style="background-color: #f8f9fa">
                {{'EVENT.EXPOSURES_MAIN' | translate}}
              </div>
              <div class="col-4 flex justify-content-center" style="background-color: #f8f9fa">
                <i class="far fa-stopwatch"></i>
              </div>

              <!-- SOOT -->
              <div class="col-2 flex justify-content-center">
                <p-checkbox class="flex justify-content-center" [ngModel]="getTypeBool(person.key, 'Sot')"
                            [binary]="true" (onChange)="exposedMap.get(person.key).get('Sot').boolValue
                              = !exposedMap.get(person.key).get('Sot').boolValue">
                </p-checkbox>
              </div>
              <div class="col-6">{{exposedMap.get(person.key).get('Sot').label}}</div>
              <div *ngIf="exposedMap.get(person.key).get('Sot').boolValue; else elseBlock"
                   class="col-4 flex justify-content-center" (click)="changePersonTimePeriod(person.key, 'Sot')">
                {{printTime(exposedMap.get(person.key).get('Sot').time)}}
              </div>
              <ng-template #elseBlock><div class="col-4"></div></ng-template>

              <!-- SMOKE -->
              <div class="col-2 flex justify-content-center">
                <p-checkbox class="flex justify-content-center" [ngModel]="getTypeBool(person.key, 'Royk')"
                            [binary]="true" (onChange)="exposedMap.get(person.key).get('Royk').boolValue
                              = !exposedMap.get(person.key).get('Royk').boolValue">
                </p-checkbox>
              </div>
              <div class="col-6">{{exposedMap.get(person.key).get('Royk').label}}</div>
              <div *ngIf="exposedMap.get(person.key).get('Royk').boolValue; else elseBlock"
                   class="col-4 flex justify-content-center" (click)="changePersonTimePeriod(person.key, 'Royk')">
                {{printTime(exposedMap.get(person.key).get('Royk').time)}}
              </div>
              <ng-template #elseBlock><div class="col-4"></div></ng-template>

              <!-- ACCIDENT -->
              <div class="col-2 flex justify-content-center">
                <p-checkbox class="flex justify-content-center" [ngModel]="getTypeBool(person.key, 'Uhell')"
                            [binary]="true" (onChange)="exposedMap.get(person.key).get('Uhell').boolValue
                              = !exposedMap.get(person.key).get('Uhell').boolValue">
                </p-checkbox>
              </div>
              <div class="col-6">{{exposedMap.get(person.key).get('Uhell').label}}</div>
              <div class="col-4"></div>

              <!-- PSYCHOLOGICAL -->
              <div class="col-2 flex justify-content-center">
                <p-checkbox class="flex justify-content-center" [ngModel]="getTypeBool(person.key, 'Psykisk')"
                            [binary]="true" (onChange)="exposedMap.get(person.key).get('Psykisk').boolValue
                              = !exposedMap.get(person.key).get('Psykisk').boolValue">
                </p-checkbox>
              </div>
              <div class="col-6">{{exposedMap.get(person.key).get('Psykisk').label}}</div>
              <div class="col-4"></div>

              <!-- CHEMICALS-->
              <div class="col-2 flex justify-content-center">
                <p-checkbox class="flex justify-content-center" [ngModel]="getTypeBool(person.key, 'Kjemikalier')"
                            [binary]="true" (onChange)="exposedMap.get(person.key).get('Kjemikalier').boolValue
                              = !exposedMap.get(person.key).get('Kjemikalier').boolValue">
                </p-checkbox>
              </div>
              <div class="col-6">{{exposedMap.get(person.key).get('Kjemikalier').label}}</div>
              <div class="col-4"></div>

              <!-- ASBESTOS -->
              <div class="col-2 flex justify-content-center">
                <p-checkbox class="flex justify-content-center" [ngModel]="getTypeBool(person.key, 'Asbest')"
                            [binary]="true" (onChange)="exposedMap.get(person.key).get('Asbest').boolValue
                              = !exposedMap.get(person.key).get('Asbest').boolValue">
                </p-checkbox>
              </div>
              <div class="col-6">{{exposedMap.get(person.key).get('Asbest').label}}</div>
              <div class="col-4"></div>
            </div>
          </td>
        </tr>
      </ng-template>


    </p-table>


    <div class="col-12 flex justify-content-end">
      <p-button label="{{'BUTTON.SAVE' | translate}}"
                icon="pi pi-check"
                iconPos="right"
                (click)="save()"
      ></p-button>
    </div>

  </div>
</div>
