<div class="grid">

  <!-- STATION -->
  <div class="col-6">
    <div class="field">
      <label for="stationSelect">{{'COMMON.STATION' | translate}}</label>
      <select name="team" id="stationSelect" [(ngModel)]="selectedStation" (ngModelChange)="getTeams($event)" style="appearance: auto" class="w-full inputfield" pInputText>
        <option [ngValue]="null" [disabled]="true" >{{'COMMON.CHOOSE' | translate}}</option>
        <option *ngFor="let station of stations" [ngValue]="station">{{station.name}}</option>
      </select>
    </div>
  </div>

  <!-- TEAM -->
  <div class="col-6">
    <div class="field">
      <label for="teamSelect">{{'SUBJECTS.TYPES.FIRETEAM' | translate}}</label>
      <select  [disabled]="!selectedStation" name="team" id="teamSelect" [(ngModel)]="selectedTeam" style="appearance: auto" class="w-full inputfield" pInputText>
        <option [ngValue]="null" [disabled]="true" >{{'COMMON.CHOOSE' | translate}}</option>
        <option *ngFor="let team of teams" [ngValue]="team">{{team.name}}</option>
      </select>
    </div>
  </div>

  <div class="col-12 justify-content-end flex">
    <button  pButton [disabled]="!selectedStation && !selectedTeam" pRipple label="{{'BUTTON.SAVE' | translate}}"
             icon="pi pi-bookmark" class="p-button-raised" (click)="save()"></button>
  </div>
</div>


