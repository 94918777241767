import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isoDateASC'
})

/**
 * This emergencyFilter will sort ISO date ASC, (optional): remove X days before Date.now
 */
export class IsoDateASCPipe implements PipeTransform {

  transform(list, days?: number): any {
    if (!list) {
      return undefined
    } else {
      if(days){
        const date = new Date(Date.now() - days * 24 * 60 * 60 * 1000).toISOString()
        return list.filter(a => a.repeatDate > date)
      } else {
        return list
      }
    }
  }

}
