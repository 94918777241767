<div class="grid">



  <div class="col-12">

    <p-table [value]="ctStatusMap | keyvalue" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th>{{'COMMON.STATUS' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ct>
        <tr>
          <td *ngIf="ctNameMap.has(ct.key)">{{ctNameMap.get(ct.key)}}</td>
          <td *ngIf="!ctNameMap.has(ct.key)"><b>{{'COMMON.DELETED' | translate}}</b></td>
          <td *ngIf="ct.value.status!== 'REPEAT_VALID'"><i [className]="getIcon(ct.value.status)" [ngStyle]="getStatus(ct.value.status)"></i></td>
          <td *ngIf="ct.value.status === 'REPEAT_VALID'"><i (click)="op.toggle($event)" [className]="getIcon(ct.value.status)" [ngStyle]="getStatus(ct.value.status)"></i></td>
        </tr>
        <!-- OVERLAY -->
        <p-overlayPanel #op>
          <ng-template pTemplate>
            {{getRepeatDate(ct.key)}}
          </ng-template>
        </p-overlayPanel>
      </ng-template>
    </p-table>
  </div>
</div>
