<div class="grid" *ngIf="!loading">

    <div class="col-12 flex flex-wrap align-content-center">
        <h2 style="margin-top: 10px">{{'SMS.SMS_ARCHIVE' | translate}}</h2>
    </div>

    <div class="col-12 p-0">
        <div class="col-6">
            <p-calendar [(ngModel)]="dateRange" [readonlyInput]="true" [style]="{'width' : '100%'}"
                        showClear="true" inputId="range" selectionMode="range" dateFormat="dd.mm.yy"
                        (click)="filterSMS()" placeholder="{{'COMMON.TIMESPAN' | translate}}"></p-calendar>
        </div>
    </div>
    <ng-container *ngIf="allSMS.length > 0; else emptyBlock">
        <div *ngFor="let sms of sms" class="col-12 sm:col-6 md:col-4 lg:col-3" (click)="smsDetails(sms)">
            <p-card styleClass="p-card-shadow box">
                <ng-template pTemplate="header">
                    <div style="background-color: #16214D; min-height: 30px;"
                         class="flex align-content-center flex-wrap justify-content-center banner">
                        <div class="flex align-items-center" style="color: white">
                            {{getDate(sms.date)}}
                        </div>
                    </div>
                </ng-template>
                <div class="grid">
                    <div class="col-offset-1 col-6"> {{'SMS.NUMBER_OF_RECIPIENTS' | translate}} </div>
                    <div class="col-4"> {{sms.address.length}} </div>
                    <div class="col-offset-1 col-6"> {{'EXERCISE.MESSAGE' | translate}}</div>
                    <div class="col-offset-1 col-10">
                    <textarea [ngModel]="sms.message"
                              cols="30" disabled pInputTextarea rows="3" style="resize: none;"></textarea>
                    </div>
                </div>
            </p-card>
        </div>
    </ng-container>
    <ng-template #emptyBlock>
        <div class="col-12">
            <h4>{{'SMS.NO_SMS_SENT' | translate}}</h4>
        </div>
    </ng-template>
</div>
