import {Component, OnDestroy, OnInit} from '@angular/core';
import {FileService} from "../../../services/service/file.service";
import {HttpClient} from '@angular/common/http';
import {FileStorageService} from '../../../services/service/file-storage.service';
import {Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileData, FileTag, FileTagRelation} from '../../../model/fileRelated';
import * as moment from 'moment';
import {FileUploadReplaceComponent} from '../file-upload-replace/file-upload-replace.component';
import {Md5} from 'ts-md5';
import {AccessControlService} from '../../../services/service/access-control.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  fileName: string;
  file: File;
  selectedTags = [];
  adminOnly = false;

  tagRelations: FileTagRelation[];
  fileTags: FileTag[];
  files: FileData[];

  subscription = new Subscription();

  stateOptions: any[];
  description = "";
  replacesOldFiles = false;

  uploadProgress: Observable<number>;
  isAdmin$: boolean;

  constructor(
      private fileService: FileService,
      private http: HttpClient,
      private fileStorageService: FileStorageService,
      private translateService: TranslateService,
      private authService: AuthService,
      private ref: DynamicDialogRef,
      private dialogService: DialogService,
      private acService: AccessControlService,
  ) {
  }

  ngOnInit(): void {
    this.stateOptions = [{label: this.translateService.instant('FILE.ALL'), value: false},
      {label: this.translateService.instant('FILE.ADMIN_ONLY'), value: true}];

    // File tags
    this.fileTags = [];
    this.subscription.add(
        this.fileService.getFileTags().subscribe(tags => {
          tags.forEach(tag => {
            if (!tag.archived) {
              this.fileTags.push({key: tag.key, ...tag.payload.val()});
            }
          });
          const tagMap = new Map<string, {}>();
          this.fileTags.forEach(tag => {
            if (!tag.archived) {
              tagMap.set(tag.tagName, tag);
            }
          });
          const keys = [];
          for (const key of tagMap.keys()) {
            keys.push(key);
          }
          keys.sort();

          this.fileTags = [];
          keys.forEach(key => this.fileTags.push(<FileTag> tagMap.get(key)));
        })
    );

    this.subscription.add(this.acService.isAdmin().subscribe(isAdmin => this.isAdmin$ = isAdmin));

    // Files
    this.files = [];
    this.subscription.add(
        this.fileService.getAllFiles().subscribe(tags => {
          tags.forEach(file => {
            this.files.push({$key: file.key, ...file.payload.val()});
          });
        })
    );

    // Tag relations
    this.tagRelations = [];
    this.subscription.add(
        this.fileService.getFileTagRelations().subscribe(relations => {
          relations.forEach(relation =>
              this.tagRelations.push({key: relation.key, ...relation.payload.val()})
          );
        })
    );
  }

  /**
   * Upload new file and add file-info / tag-relation to tenant
   */
  upload(){
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      const fileData: FileData = {
        admin: this.adminOnly,
        archived: false,
        createdById: uid,
        date: moment().toISOString(),
        description: this.description,
        extension: this.fileName.substring(this.fileName.length - 3),
        name: this.fileName,
        progress: 0,
        restrictions: '',
        url: ''
      };
      if (this.replacesOldFiles){
        this.uploadProgress = this.fileStorageService.uploadNewFile(this.file, this.adminOnly, fileData);
        this.selectedTags.forEach(tag =>
            this.fileService.addFileTagRelation({fileId: Md5.hashStr(this.fileName).toString(), tagId: tag.key})
        );
        this.dialogService.open(FileUploadReplaceComponent, {
          header: this.translateService.instant('FILE.CHOOSE_TO_ARCHIVE'),
          styleClass: 'max-size-width-dialog',
          data: {
            fileTags: this.selectedTags,
            tagRelations: this.tagRelations,
            files: this.files
          }
        }).onClose.subscribe(fileList => {
          // set old files as archived
          if (fileList) {
            fileList.forEach(file => {
              this.subscription.add(this.fileService.getFile(file.key).subscribe(fData => {
                fData.archived = true;
                this.fileService.updateFile(file.key, fData);
              }));
            });
          }
          this.ref.close();
        });
      } else {
        this.uploadProgress = this.fileStorageService.uploadNewFile(this.file, this.adminOnly, fileData);
        this.selectedTags.forEach(tag =>
            this.fileService.addFileTagRelation({fileId: Md5.hashStr(this.fileName).toString(), tagId: tag.key})
        );
        this.ref.close(true);
      }
    });
  }

  /**
   * On file select set file value and fileName
   * @param $event
   */
  onFileSelected($event) {
    this.file = $event.target.files[0];
    this.fileName = this.file.name;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
