import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {map, mergeMap, publish} from "rxjs/operators";
import {CompetencyType} from "../../model/competencyType";

@Injectable({
  providedIn: 'root'
})
export class CompetencyTypeService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getCompetencyTypes(): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/competencyTypes`).snapshotChanges()
      )
    );
  }

  getArchivedCompetencyTypes(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/competencyTypes`, ref => {
              return ref.orderByChild('archived').equalTo(true);
            }).snapshotChanges()
        )
    );
  }

  addCompetencyType(competencyType: CompetencyType) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/competencyTypes`).push(competencyType)
      )
    )).connect();
  }

  addCompetencyTypeGetKey(competencyType: CompetencyType) {
    return this.tenantId$.pipe(
      map(tenantId => {
        const ref = this.db.list(`tenants/${tenantId}/competencyTypes`).push(competencyType);
        return ref.key;
      })
    );
  }

  updateCompetencyType(ctId:string, competencyType: CompetencyType) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/competencyTypes/${ctId}`).set(competencyType)
      )
    )).connect();
  }

  deleteCompetencyType(ctId: any) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/competencyTypes/${ctId}`).remove()
      )
    )).connect();
  }
}
