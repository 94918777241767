<p-toast position="top-right" styleClass="customToast"></p-toast>

<!-- Task Form -->
<form [formGroup]="appointmentForm" (ngSubmit)="onSubmit()">
    <div class="formgrid grid">

        <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <input id="name" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                           formControlName="name">
                    <label for="name">{{'EVENT.MISC' | translate}}*</label>
                </span>
        </div>
        <div class="col-6 mt-3">
            <p-dropdown [options]="appointmentTypes"
                        placeholder="{{'EVENT.CHOOSE_TYPE' | translate}}*"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        formControlName="typeKey"
                        optionLabel="name"
                        optionValue="key">
            </p-dropdown>
        </div>
        <div class="col-6">
            <p-calendar placeholder="{{'COMMON.SELECT_FROM' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        class="w-full inputfield"
                        formControlName="date"
            ></p-calendar>
        </div>
        <div class="col-6">
            <p-calendar placeholder="{{'COMMON.SELECT_TO' | translate}}"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        class="w-full inputfield"
                        formControlName="dateTo"
            ></p-calendar>
        </div>
        <!-- Large screen -->
        <ng-container *ngIf="windowWidth >= 400; else smallScreen">
            <div class="field col-3 mt-1">
                <label for="from">{{'COMMON.FROM' | translate}}*</label>
                <input type="time"
                       pInputText
                       id="from"
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       class="inputfield w-full"
                       [(ngModel)]="timeStart"
                       [ngModelOptions]="{standalone: true}"
                >
            </div>
            <div class="field col-3 mt-1">
                <label for="to">{{'COMMON.TO' | translate}}*</label>
                <input type="time"
                       pInputText
                       id="to"
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       class="inputfield w-full"
                       [(ngModel)]="timeEnd"
                       [ngModelOptions]="{standalone: true}"
                >
            </div>
            <div class="col-6 mt-3 flex flex-wrap align-content-evenly">
                <p-toggleButton [(ngModel)]="checked"
                                onLabel="{{'EVENT.ALL_DAY' | translate}}"
                                offLabel="{{'EVENT.ALL_DAY' | translate}}"
                                [ngModelOptions]="{standalone: true}"
                                [style]="{'height' : '42px'}"
                                (onChange)="setTime($event)"
                >
                </p-toggleButton>
            </div>
        </ng-container>
        <ng-template #smallScreen>
            <div class="field col-6 mt-1">
                <label for="fromSmall">{{'COMMON.FROM' | translate}}*</label>
                <input type="time"
                       pInputText
                       id="fromSmall"
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       class="inputfield w-full"
                       [(ngModel)]="timeStart"
                       [ngModelOptions]="{standalone: true}"
                >
            </div>
            <div class="field col-6 mt-1">
                <label for="toSmall">{{'COMMON.TO' | translate}}*</label>
                <input type="time"
                       pInputText
                       id="toSmall"
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       class="inputfield w-full"
                       [(ngModel)]="timeEnd"
                       [ngModelOptions]="{standalone: true}"
                >
            </div>
            <div class="col-6 mb-2">
                <p-toggleButton [(ngModel)]="checked"
                                onLabel="{{'EVENT.ALL_DAY' | translate}}"
                                offLabel="{{'EVENT.ALL_DAY' | translate}}"
                                [ngModelOptions]="{standalone: true}"
                                [style]="{'height' : '42px'}"
                                (onChange)="setTime($event)"
                >
                </p-toggleButton>
            </div>
        </ng-template>
        <div class="field col-12 mt-1">
                <span class="p-float-label">
                    <input id="location" type="text" pInputText [style]="{'width' : '100%'}" formControlName="location">
                    <label for="location">{{'EVENT.LOCATION' | translate}}</label>
                </span>
        </div>
        <div class="field col-12 mt-1">
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
            <textarea id="textarea"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      pInputTextarea
                      class="inputfield w-full"
                      formControlName="details">
            </textarea>
        </div>
        <!-- Person table -->
        <div class="col-12 flex">
            <p-table responsiveLayout="block"
                     breakpoint="200px"
                     [value]="selectedSubjects"
                     selectionMode="radiobutton"
            >
                <!-- Search bar -->
                <ng-template pTemplate="caption" class="pt-5">
                    <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                                    [(ngModel)]="selectedPerson"
                                    (onSelect)="addPerson(selectedPerson)"
                                    [ngModelOptions]="{standalone: true}"
                                    placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}"
                                    [dropdown]="true"
                                    (completeMethod)="filterPerson($event)"
                                    size="100%"
                                    field="label"
                                    type="search"
                                    scrollHeight="400px"
                                    class="inputfield w-full"
                                    appendTo="body"
                                    [forceSelection]="true"
                                    [group]="true"
                    >

                        <ng-template let-team pTemplate="group">
                            <div class="flex align-items-center p-2" (click)="addTeam(team)">
                                <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                            </div>
                        </ng-template>
                        <ng-template let-subject pTemplate="item">
                            <div class="person-item">
                                {{subject.label}}
                            </div>
                        </ng-template>

                    </p-autoComplete >
                </ng-template>
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{'FDC.PERSONS' | translate}}</th>
                        <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
                    </tr>
                </ng-template>
                <!-- Body -->
                <ng-template pTemplate="body" let-personId >
                    <tr>
                        <td>
                            {{personNameMap.get(personId)}}
                        </td>
                        <td (click)="removePerson(personId)">
                                <span class="flex justify-content-center">
                                    <i class="pi pi-trash" style="color: #D0021B"></i>
                                </span>
                        </td>
                    </tr>
                </ng-template>
                <!-- Shows message if table is empty -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">
                            {{'WARNING.NO_DATA' | translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <!-- Notification -->
        <div class="col-12">
            <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
        </div>
        <!-- SMS - Notification -->
        <div class="field-checkbox col-4 flex align-items-center">
            <p-checkbox id="smsNotification" binary="true"
                        [(ngModel)]="smsNotification"
                        [ngModelOptions]="{standalone: true}"></p-checkbox>
            <label for="smsNotification">{{'BUTTON.SMS' | translate}}</label>
        </div>
        <div class="field col-6">
            <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                           [ngModelOptions]="{standalone: true}"
                           [showButtons]="true" [min]="1"
                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                           [max]="100" class="inputfield w-full"></p-inputNumber>
        </div>
        <!-- Email - Notification -->
        <div class="field-checkbox col-4 flex align-items-center mb-3">
            <p-checkbox id="emailNotification" binary="true" [(ngModel)]="emailNotification"
                        [ngModelOptions]="{standalone: true}"></p-checkbox>
            <label for="emailNotification">{{'PERSONAL.EMAIL' | translate}}</label>
        </div>
        <div class="field col-6 mb-3">
            <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                           [max]="100" class="inputfield w-full"></p-inputNumber>
        </div>
        <div class="col-12">&nbsp;</div>
        <!-- Save button -->
        <div class="col-12 flex justify-content-end">
            <ng-container *ngIf="fromCalendar; else elseBlock">
                <button pButton icon="pi pi-trash" type="button" label="{{'FILE.DELETE' | translate}}"
                        (click)="delete()" class="p-button-outlined float-right p-button-raised"></button>
                <button class="float-right p-button-raised" pButton icon="pi pi-bookmark"
                        type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="onSubmit()"
                        [disabled]="!appointmentForm.valid || timeStart === null || timeEnd === null
                    || (smsNotification && smsDaysBefore < 1)
                    || (emailNotification && emailDaysBefore < 1)"></button>
            </ng-container>
            <ng-template #elseBlock>
                <button pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
                        (click)="close()" class="p-button-outlined float-right p-button-raised"></button>
                <button class="float-right p-button-raised" pButton icon="pi pi-bookmark"
                        type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="onSubmit()"
                        [disabled]="!appointmentForm.valid || timeStart === null || timeEnd === null
                    || (smsNotification && smsDaysBefore < 1)
                    || (emailNotification && emailDaysBefore < 1)"></button>
            </ng-template>
        </div>
    </div>
</form>
