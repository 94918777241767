import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {SubjectService} from '../../../services/service/subject.service';
import {PersonService} from '../../../services/service/person.service';
import {ExerciseService} from '../../../services/service/exercise.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {AccessControlService} from '../../../services/service/access-control.service';
import {take} from 'rxjs/operators';
import {ExerciseCompletedDetailComponent} from '../exercise-completed-detail/exercise-completed-detail.component';
import {Exercise} from '../../../model/exercise';
import * as moment from 'moment';

@Component({
  selector: 'app-exercise-completed',
  templateUrl: './exercise-completed.component.html',
  styleUrls: ['./exercise-completed.component.scss'],
  providers: [DialogService]
})
export class ExerciseCompletedComponent implements OnInit, OnDestroy {

  exercises: any;
  selectedExerciseFilter: any;
  exerciseLoading = true;
  exerciseFilter = [];
  userStatus = new Map<string, boolean>();

  subscription = new Subscription();
  innerWidth = window.innerWidth;

  constructor(
    private subjectService: SubjectService,
    private personService: PersonService,
    private exerciseService: ExerciseService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
  ) {
    this.acService.getOwnAccessControl().subscribe(value => {
      if (value.roles.admin){
        this.userStatus.set('admin', true);
      } else {
        this.userStatus.set('admin', false);
      }
      if (value.roles.superuser){
        this.userStatus.set('superUser', true);
      } else {
        this.userStatus.set('superUser', false);
      }
      if (value.roles.user){
        this.userStatus.set('user', true);
      } else {
        this.userStatus.set('user', false);
      }
    });
  }

  ngOnInit(): void {

    // Get all filters for dashboard, and set selectedTaskFilter and selectedExerciseFilter to default subjectId.
    combineLatest([this.subjectService.getStations(), this.personService.getOwnSubjectId()]).pipe(
      take(1)
    ).subscribe(([stationsPayload, subjectId]) => {
      this.exerciseFilter.push({key: '', name: ('CHECKLIST.SHOW_ALL')});
      if(!subjectId){
        this.selectedExerciseFilter = {key: '', name: ('CHECKLIST.SHOW_ALL')};
      } else {
        this.exerciseFilter.push({key: subjectId, name: ('EXERCISE.MY_EXERCISE')});
      }

      // Get completed exercises
      this.subscription.add(
        this.exerciseService.getCompletedExercises().subscribe(exercisePayload => {
          this.exercises = exercisePayload.map(exercise => {
            return {key: exercise.key, ...exercise.payload.val()};
          }).sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime());
          this.exerciseLoading = false;
          if (!this.userStatus.get('admin') && !this.userStatus.get('superUser')){
            const myExercises = [];
            this.exercises.forEach(exercise => {
              exercise.participants.forEach(participant => {
                if (participant.key === subjectId){
                  myExercises.push(exercise);
                }
              });
            });
            this.exercises = myExercises;
          }
        })
      );
    });

  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  /**
   * Exercise details
   * @param exercise Exercise object
   * @param newHeader Exercise header
   */
  exerciseDetails(exercise, newHeader?) {
    this.dialogService.open(ExerciseCompletedDetailComponent, {
      header: (newHeader) ? newHeader : exercise.name,
      styleClass: 'max-size-dialog',
      data: {
        exercise: exercise,
      },
    });
  }

  /**
   * Count competency's
   * @param exercise Exercise object
   */
  competencyCount(exercise: Exercise) {
    let counter = 0;
    if (exercise.competencyTypes){
      for (const key of Object.entries(exercise.competencyTypes)) {
       counter++;
      }
    }
    return counter;
  }

  /**
   * Count participants
   * @param exercise Exercise object
   */
  participantCount(exercise: Exercise) {
    if (exercise.participants){
      return exercise.participants.length;
    } else {
      return 0;
    }
  }

  printDate(exercise: Exercise) {

    const from = moment(exercise.date).format('DD.MM.YY HH:mm');
    const fromDate = moment(exercise.date).format('DD.MM.YY');
    const fromTime = moment(exercise.date).format('HH:mm');
    const to = moment(exercise.dateEnd).format('DD.MM.YY HH:mm');
    const toDate = moment(exercise.dateEnd).format('DD.MM.YY');
    const toTime = moment(exercise.dateEnd).format('HH:mm');

    if (fromDate === toDate){
      return fromDate + " :\t" + fromTime + " - " + toTime;
    } else {
      return from +`\n` + to;
    }

  }
}
