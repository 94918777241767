import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Exercise} from '../../../model/exercise';
import {Subscription} from 'rxjs';
import {SubjectService} from '../../../services/service/subject.service';
import {TranslateService} from '@ngx-translate/core';
import {SmsService} from '../../../services/service/sms.service';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';
import {SmsHelperService} from '../../../services/sharedService/sms-helper.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss']
})
export class SendSMSComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  exercise: Exercise;
  defaultLang;

  personNameMap = new Map<string, string>(); // All persons {{key: fbid, name: personName}, ... , {}}
  participantList: any[] = [];  // participant list [{key: fbid, name: personName}}
  selectedParticipants: any[];
  selected: number;
  subjects = new Map();

  availableSMS = 0;
  nrSMS: number;
  message = '';

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private smsService: SmsService,
    private subjectService: SubjectService,
    private translateService: TranslateService,
    private authService: AuthService,
    private smsHelper: SmsHelperService,
  ) {
    this.defaultLang = this.translateService.getDefaultLang();

    this.subscription.add(this.subjectService.getSubjects().subscribe(subjects => {
      this.subjects = new Map(subjects.map(subject => [subject.key, subject.payload.val()]))
      if (this.config.data.exercise.participants) {
        this.config.data.exercise.participants.forEach(person =>{
          let cellPhone = this.subjects.get(person.key).privateCell;
          if (cellPhone === undefined){
            cellPhone = '';
          }
          this.participantList.push({
            key: person.key,
            name: this.subjects.get(person.key).name,
            privateCell: cellPhone
          });
        });
      }
    }));

    this.smsService.getAvailableSMS().subscribe(sms => {
      this.availableSMS = <number> sms;
    });
  }

  ngOnInit(): void {
  }

  /**
   * Get number of sms
   * @param length Message length
   */
  numberOfSms(length: number){
    if (this.selectedParticipants){
      return this.smsHelper.numberOfSms(length, this.selectedParticipants.length);
    } else {
      return this.smsHelper.numberOfSms(length, 0);
    }
  }

  /**
   * Get scroll height for HTML person table
   */
  getScrollHeight() {
    if (screen.height > 858){
      return 480
    } else {
      return screen.height - 450;
    }
  }

  /**
   * Enable / Disable send button
   */
  enableDisable() {
    if (this.selectedParticipants){
      if (this.selectedParticipants.length > 0){
        return !(this.message.length >= 1);
      }
    } else {
      return true;
    }
  }

  /**
   * Send sms(s)
   */
  send() {
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      this.smsHelper.send(uid, this.selectedParticipants, this.message, this.numberOfSms(this.message.length), this.defaultLang,
          this.config.data.exerciseKey);
    });
    this.ref.close();
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
