import { Injectable } from '@angular/core';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Observable} from 'rxjs';
import {map, mergeMap, publish} from 'rxjs/operators';
import {CalendarEvent} from '../../model/calendarEvent';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  tenantId$: Observable<any>;

  constructor(
      private tenantService: TenantService,
      private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Add new calender event
   * @param event calender event
   * @param id Parent event Fbid
   */
  addCalendarEvent(event: CalendarEvent, id) {
    return publish()(this.tenantId$.pipe(
        map(tenantId => this.db.object(`tenants/${tenantId}/calendarEvents/${id}`).set(event)))).connect();
  }

  /**
   * Get all calender events
   */
  getCalendarEvents(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/calendarEvents`).snapshotChanges()
        )
    );

  }

  getCalendarEvent(key: string) {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/calendarEvents/${key}`).valueChanges()
        )
    );
  }

  /**
   * Get all calender events
   */
  getCompletedCalendarEvents(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/calendarEvents`, ref => {
              return ref.orderByChild('completed').equalTo(true);
            }).snapshotChanges()
        )
    );
  }
  /**
   * Delete calender event
   * @param key fbid
   */
  deleteCalendarEvent(key: string) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/calendarEvents/${key}`).remove()
        )
    )).connect();
  }

  /**
   * Update CalendarEvent.
   * @param calendarEvent Updated CalendarEvent.
   * @param key CalendarEvent <FBID>.
   */
  updateCalendarEvent(calendarEvent: CalendarEvent, key: string) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/calendarEvents/${key}`).update(calendarEvent)
        )
    )).connect();
  }

}
