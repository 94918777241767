import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupedSubjectsService {

  constructor() { }

  /**
   * Build select person/team dropdown
   * @param childrenArray personService.getPersons()
   * @param parentsArray subjectService.getTeams()
   * @param relations subjectService.getRelations()
   */
  build(childrenArray, parentsArray, relations){

    // Helper function
    function buildFilter(cNameMap, subject){
      return {
        label: cNameMap.get(subject),
        value: subject,
      };
    }

    let parentNameMap = new Map();
    let childNameMap = new Map();
    const teamParentNameMap = new Map();

    let groupedFilter;
    childNameMap = new Map(childrenArray.map(child => [child.key, child.payload.val().name]));
    parentNameMap = new Map(parentsArray.map(parent => [parent.key, parent.payload.val().name]));

    groupedFilter = [];
    const groupedMap = new Map();

    relations.forEach(relation => {
      if(parentNameMap.has(relation.parentId)){
        if(groupedMap.has(relation.parentId)){
          const children: any[] = groupedMap.get(relation.parentId);
          children.push(relation.childId);
          groupedMap.set(relation.parentId, children);
        } else {
          const children = [relation.childId];
          groupedMap.set(relation.parentId, children);
        }
      }
      if(childNameMap.has(relation.childId)){
        if(parentNameMap.has(relation.parentId)){
          teamParentNameMap.set(relation.childId, parentNameMap.get(relation.parentId));
        } else {
          teamParentNameMap.set(relation.childId, 'N/A');
        }
      }
    });
    groupedMap.forEach((children, key) => {
      const parentName = parentNameMap.get(key);
      const obj: any = {};
      obj.label = parentName;
      obj.value = key;
      obj.items = children.map(child => buildFilter(childNameMap, child));
      groupedFilter.push(obj);
    });

    groupedFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));


    return [childNameMap, parentNameMap, teamParentNameMap, groupedFilter];
  }


}
