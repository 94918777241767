import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Roles, RoleType} from '../../../model/role';
import {AuthService} from '../../../services/service/auth.service';
import {RolesService} from '../../../services/service/roles.service';
import {Subscription} from 'rxjs';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit, OnDestroy {

  roleConnections: Roles[];

  // Filter existing
  existingRoles: RoleType[] = [];
  roleTypes: RoleType[] = [];
  role: string;

  // Subscription
  subscription = new Subscription();

  constructor(
    private ref: DynamicDialogRef,
    private authService: AuthService,
    private rolesService: RolesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    // Get role types
    this.subscription.add(
      this.rolesService.getRoleTypes().subscribe(
        roleTypes => {
          this.roleTypes = roleTypes.map(roleType => {
            return {key: roleType.key, ...roleType.payload.val()};
          });
        }
      )
    );
  }

  ngOnInit(): void {
    // Get role connections
    this.subscription.add(this.rolesService.getRoles()
        .subscribe(roles => {this.roleConnections = roles.map(role => { return {key: role.key, ...role.payload.val()}; });}
    ));
  }

  /**
   * Delete role
   * @param role to delete
   */
  deleteRole(role: RoleType) {
    const index = this.roleTypes.findIndex(roleType => roleType.name === role.name);
    if (index >= 0){
      let connection = false;
      this.roleConnections.some(roleConnection => {
        this.roleTypes.some(r => {
          if(r.key === roleConnection.typekey){
            if (r.name === role.name){
              connection = true;
            }
          }
        });
      });
      if (!connection){
        this.roleTypes.splice(index, 1);
        this.rolesService.deleteRoleType(role);
      } else {
        this.messageService.add(
        {key: 'error', severity: 'error', detail: this.translateService.instant('ROLES.ERROR_MESSAGE'), life: 4000}
        );
      }
    }
  }

  /**
   * Add new role
   * @param role The role to add
   */
  addRole(role: string) {
    if(role) {
      let value = 0;
      this.existingRoles.findIndex( roleType => {
        if (roleType.name.toLowerCase() === role.toLowerCase()){
          value = 1;
          this.role = '';
        }
      });
      if (value === 0){
        this.rolesService.addRoleType({name: role});
        this.roleTypes.push({name: role});
        this.role = '';
      }
    }
  }

  /**
   * Search existing roles
   * @param $event Search query
   */
  searchExisting($event){
    const filtered: RoleType[] = [];
    const query = $event.query;

    this.roleTypes.forEach(role => {
      if (role.name.toLowerCase().indexOf(query.toLowerCase()) === 0){
        filtered.push(role);
      }
    });

    this.existingRoles = filtered;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
