import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChecklistService } from '../../../services/service/checklist.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/service/auth.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-item-archive',
  templateUrl: './item-archive.component.html',
  styleUrls: ['./item-archive.component.scss'],
  providers: [ConfirmationService],
})
export class ItemArchiveComponent implements OnInit, OnDestroy {
  archivedItems: any;
  checklistId: string;
  itemDeviationsMap: Map<string, any> = new Map()

  subscription = new Subscription();
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private checklistService: ChecklistService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.checklistId = this.config.data.checklistId;

    this.checklistService
      .getArchivedChecklistItems(this.checklistId)
      .subscribe((aItems: any) => {
        this.archivedItems = Object.entries(aItems).map(([key, value]: any) => {
          return { key: key, ...value };
        }).sort((a,b) => b.archivedDate.localeCompare(a.archivedDate));
      });

      this.checklistService.getChecklistDeviations().subscribe((deviations) => {
        // CREATE NEW MAP, so we dont get stacking values.
        this.itemDeviationsMap = new Map();

        deviations.forEach((dPayload) => {
          const deviation = dPayload.payload.val();

          // If map has key, push to array
          if (this.itemDeviationsMap.has(deviation.itemId)) {
            const dArray = this.itemDeviationsMap.get(deviation.itemId);
            dArray.push(deviation.deviationId);
            this.itemDeviationsMap.set(deviation.itemId, dArray);
          }
          // If not, create array and push.
          else {
            const dArray = [];
            dArray.push(deviation.deviationId);
            this.itemDeviationsMap.set(deviation.itemId, dArray);
          }
        });
      });
  }

  ngOnInit(): void {}

  // Unsubscribe on destroy
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  undo(item) {
    this.confirmationService.confirm({
      key: 'itemArchive',
      message: this.translateService.instant('WARNING.ARE_YOU_SURE'),
      accept: () => {
        this.authService
          .getUserUID()
          .pipe(take(1))
          .subscribe((uid) => {
            const key = item.key;
            delete(item.key);

            item.restoredBy = uid;
            item.restoredDate = new Date().toISOString();
            item.archive = false;


            this.checklistService.updateChecklistItem(
              this.checklistId,
              key,
              item
            );
          });
      },
    });
  }
}
