<!-- ConfirmDialog -->
<p-confirmDialog key="eTheme" [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12 flex justify-content-end">
    <p-splitButton [disabled]="!theme?.name" appendTo="body" label="{{'BUTTON.SAVE' | translate}}"
                   icon="pi pi-bookmark" (onClick)="save()" [model]="splitItems" class="p-button-raised"></p-splitButton>
  </div>
  <div class="col-12">
    <div class="field">
      <label for="name">{{'COMMON.NAME' | translate}}</label>
      <input id="name" [(ngModel)]="theme.name" type="text" class="inputfield w-full" pInputText>
    </div>
  </div>

  <div class="col-12 p-0 field">
    <label class="p-2" for="ctTable">{{'COMPETENCY.TYPES' | translate}}</label>
    <p-table
      [value]="theme?.competencyTypes"
      [loading]="loading"
      responsiveLayout="block"
      breakpoint="200px"
      id="ctTable">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-12">
            <p-autoComplete
              appendTo="body"
              [(ngModel)]="selectedCT"
              [suggestions]="output | removeExistingFilter: ctMap"
              (onSelect)="addCompetencyType()"
              field="name"
              type="search"
              placeholder="{{'COMPETENCY.ADD_COMPETENCY_TYPE' | translate}}"
              (completeMethod)="search($event)"
              size="100"
              forceSelection="true"
              [dropdown]="true">
            </p-autoComplete>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="body" let-ct>
        <tr>
          <td *ngIf="ctNameMap.has(ct)">{{ctNameMap.get(ct)}}</td>
          <td *ngIf="!ctNameMap.has(ct)"><b>{{'FILE.FILE_IS_DELETED_ALERT' | translate}}</b></td>
          <td style="width: 60px">
            <div class="flex justify-content-end">
              <i (click)="delete(ct)" class="pi pi-trash" style="color: #D0021B"></i>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
