import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {Subscription} from "rxjs";
import {ConfirmationService, FilterService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-edit-participant',
  templateUrl: './edit-participant.component.html',
  styleUrls: ['./edit-participant.component.scss']
})
export class EditParticipantComponent implements OnInit {
  output: any;
  acRole: any;

  participantCompetencyTypes: any[] = [];
  competencyTypes: any[] = [];
  ctNameMap = new Map();
  pCompetencyTypeMap = new Map();

  selectedCompetencyType: any;

  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private ctService: CompetencyTypeService,
    private filterService: FilterService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {
    if(this.config.data.participantCT){
      this.participantCompetencyTypes = Object.keys(JSON.parse(JSON.stringify(this.config.data.participantCT)));
      this.pCompetencyTypeMap = new Map(Object.keys(JSON.parse(JSON.stringify(this.config.data.participantCT))).map(key => [key, true]));
    } else {
      this.participantCompetencyTypes = [];
      this.pCompetencyTypeMap = new Map();
    }
    this.acRole = this.config.data.acRole;

    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        this.competencyTypes = cts.map(ct => {
          return {key: ct.key, ...ct.payload.val()};
        }).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.participantCompetencyTypes = this.participantCompetencyTypes.map(eCT => {
          if(eCT.key){
            return {key: eCT.key, name: this.ctNameMap.get(eCT.key)};
          } else {
            return {key: eCT, name: this.ctNameMap.get(eCT)};
          }
        }).sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      })
    );
  }

  ngOnInit(): void {
  }

  addCompetencyType() {
    if (!this.participantCompetencyTypes.includes(this.selectedCompetencyType.key)) {
      this.participantCompetencyTypes.push(
        {key: this.selectedCompetencyType.key, name: this.ctNameMap.get(this.selectedCompetencyType.key)}
      );
      this.participantCompetencyTypes.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      this.pCompetencyTypeMap.set(this.selectedCompetencyType.key, true);
    }
  }

  search(event: any) {
    const query = event.query;
    this.output = this.filterService.filter(this.competencyTypes, ['name'], query, "contains");
  }

  remove(ct) {
    const index = this.participantCompetencyTypes.findIndex(pCT => pCT.key === ct);
    if (index >= 0) {
      this.participantCompetencyTypes.splice(index, 1);
      this.pCompetencyTypeMap.delete(ct);
    }
  }

  save() {
    const ctObj = {};
    this.participantCompetencyTypes.forEach(pCT => {
       ctObj[pCT.key] = true;
    });
    this.ref.close(ctObj);
  }

  delete() {
    this.confirmationService.confirm({
      key: 'edit-participant',
      message: this.translateService.instant('WARNING.REMOVE'),
      accept: () => {
        this.ref.close(true);
      }
    });
  }
}
