import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsoDateASCPipe} from './iso-date-asc.pipe';
import {DashboardFilterPipe} from './dashboard-filter.pipe';
import {CompetencyPersonFilterPipe} from './competency-person-filter.pipe';
import {ExerciseParticipantsFilterPipe} from './exercise-participants-filter.pipe';
import {RemoveExistingFilterPipe} from './remove-existing-filter.pipe';
import {RolesPersonFilterPipe} from './roles-person-filter.pipe';
import {ChecklistFilterPipe} from './checklist-filter.pipe';
import { HideArchivedPipe } from './hide-archived.pipe';
import { NameSortPipe } from './name-sort.pipe';
import { EventFilterPipe } from './event-filter.pipe';
import { ExerciseEvalFilterPipe } from './exercise-eval-filter.pipe';
import { SelectedFileFilterPipe } from './selected-file-filter.pipe';
import { ExistingTagFilterPipe } from './existing-tag-filter.pipe';
import { RolesStationTeamFilterPipe } from './roles-station-team-filter.pipe';
import { AssetFilterPipe } from './asset-filter.pipe';
import { DeviationFilterPipe } from './deviation-filter.pipe';
import { SMSFilterPipe } from './smsfilter.pipe';
import { PersonFilterPipe } from './person-filter.pipe';
import { CompetencyTypeFilterPipe } from './competency-type-filter.pipe';
import { NameFilterPipe } from './name-filter.pipe';
import { EmergencyTypePipe } from './emergency-type.pipe';
import { StationFilterPipe } from './station-filter.pipe';


@NgModule({
  declarations: [
    IsoDateASCPipe,
    DashboardFilterPipe,
    CompetencyPersonFilterPipe,
    ExerciseParticipantsFilterPipe,
    RemoveExistingFilterPipe,
    RolesPersonFilterPipe,
    ChecklistFilterPipe,
    HideArchivedPipe,
    NameSortPipe,
    EventFilterPipe,
    ExerciseEvalFilterPipe,
    SelectedFileFilterPipe,
    ExistingTagFilterPipe,
    RolesStationTeamFilterPipe,
    AssetFilterPipe,
    DeviationFilterPipe,
    SMSFilterPipe,
    PersonFilterPipe,
    CompetencyTypeFilterPipe,
    NameFilterPipe,
    EmergencyTypePipe,
    StationFilterPipe,
  ],

    exports: [
        IsoDateASCPipe,
        DashboardFilterPipe,
        CompetencyPersonFilterPipe,
        ExerciseParticipantsFilterPipe,
        RemoveExistingFilterPipe,
        RolesPersonFilterPipe,
        ChecklistFilterPipe,
        HideArchivedPipe,
        NameSortPipe,
        EventFilterPipe,
        NameFilterPipe,
        StationFilterPipe
    ],

  imports: [
    CommonModule
  ]

})
export class PipesModule {
}
