import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChecklistService} from "../../../services/service/checklist.service";
import {AssetService} from "../../../services/service/asset.service";
import {SubjectService} from "../../../services/service/subject.service";
import {CronJob, Daily} from "../../../model/cronJob";
import {TranslateService} from "@ngx-translate/core";
import {combineLatest} from "rxjs";
import {take} from "rxjs/operators";
import {PersonService} from "../../../services/service/person.service";
import {DialogService} from "primeng/dynamicdialog";
import {ChecklistRepeatDetailComponent} from "../checklist-repeat-detail/checklist-repeat-detail.component";
import {AddChecklistComponent} from "../add-checklist/add-checklist.component";

@Component({
  selector: 'app-checklist-repeat',
  templateUrl: './checklist-repeat.component.html',
  styleUrls: ['./checklist-repeat.component.scss'],
  providers: [DialogService]
})
export class ChecklistRepeatComponent implements OnInit, OnDestroy {

  repeatingChecklists;
  selectedStation;
  repeatingLoading = true;
  repeatingFilter;
  repeatingSubscription;
  assetMap;
  assetSubscription;

  constructor(
      private checklistService: ChecklistService,
      private assetService: AssetService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
      private personService: PersonService,
      private dialogService: DialogService,
  ) {
  }

  ngOnInit(): void {
    /**
     * Get Own Station and set selectedStation.
     */
    this.personService.getOwnStation().pipe(
        take(1)
    ).subscribe(ownStation => {
      this.selectedStation = ownStation.key;
    });

    /**
     * Get Stations Filter
     */
    this.subjectService.getStations().pipe(
        take(1),
    ).subscribe(stationsPayload => {
      this.repeatingFilter = stationsPayload.map(
          station => {
            return ({key: station.key, name: station.payload.val().name});
          }
      );
    });
    /**
     * Create a assetName Map
     */
    this.assetSubscription =
        this.assetService.getAssets().subscribe(assets => {
          this.assetMap = new Map(assets.map(asset => {
            return [asset.key, asset.payload.val().name];
          }));
        });

    /**
     * Get all non paused repeating checklist
     */
    this.repeatingSubscription =
        this.checklistService.getRepeatingChecklists().subscribe(rcs => {
          this.repeatingChecklists = rcs;
          this.repeatingLoading = false;
        });
  }

  /**
   * Get the repeat info of the CronJob
   */
  public getCronJobMode(cron: CronJob) {
    const mon$ = this.translateService.get("COMMON.DATE.MON");
    const tue$ = this.translateService.get("COMMON.DATE.TUE");
    const wed$ = this.translateService.get('COMMON.DATE.WED');
    const thu$ = this.translateService.get('COMMON.DATE.THU');
    const fri$ = this.translateService.get('COMMON.DATE.FRI');
    const sat$ = this.translateService.get('COMMON.DATE.SAT');
    const sun$ = this.translateService.get('COMMON.DATE.SUN');

    const each$ = this.translateService.get('CHECKLIST.EACH');
    const week$ = this.translateService.get('COMMON.WEEK');
    const month$ = this.translateService.get('COMMON.MONTH');

    const first$ = this.translateService.get('COMMON.FIRST');
    const last$ = this.translateService.get('COMMON.LAST');

    const result = [];

    combineLatest([mon$, tue$, wed$, thu$, fri$, sat$, sun$, each$, week$, month$, first$, last$])
        .pipe(take(1))
        .subscribe(([mon, tue, wed, thu, fri, sat, sun, each, week, month, first, last]) => {

          /**
           * If new Cron (Weekly, and Monthly mode)
           */
          if (cron.newChecklistCron) {
            if (cron.weeklyMode) {
              const weekly = cron.weekly;
              const day = weekly.day;

              if (day.mon) {
                result.push(mon.substring(0, 3));
              }
              if (day.tue) {
                result.push(tue.substring(0, 3));
              }
              if (day.wed) {
                result.push(wed.substring(0, 3));
              }
              if (day.thu) {
                result.push(thu.substring(0, 3));
              }
              if (day.fri) {
                result.push(fri.substring(0, 3));
              }
              if (day.sat) {
                result.push(sat.substring(0, 3));
              }
              if (day.sun) {
                result.push(sun.substring(0, 3));
              }

              const interval = (weekly.interval === 1) ? each : each + ' ' + weekly.interval;
              result.push(' ' + interval + ' ' + week);
              return result;

            } else if (cron.monthlyMode) {
              const monthly = cron.monthly;
              // if (monthly){ // TODO Potential bug?
                if (monthly.first || monthly.last) {
                  const when = (monthly.first) ? first : last;
                  const interval = (monthly.interval === 1) ? each : each + ' ' + monthly.interval;

                  if (monthly.day === 'monday') {
                    result.push(when + ' ' + mon + ' ' + interval + ' ' + month);
                  }
                  if (monthly.day === 'tuesday') {
                    result.push(when + ' ' + tue + ' ' + interval + ' ' + month);
                  }
                  if (monthly.day === 'wednesday') {
                    result.push(when + ' ' + wed + ' ' + interval + ' ' + month);
                  }
                  if (monthly.day === 'thursday') {
                    result.push(when + ' ' + thu + ' ' + interval + ' ' + month);
                  }
                  if (monthly.day === 'friday') {
                    result.push(when + ' ' + fri + ' ' + interval + ' ' + month);
                  }
                  if (monthly.day === 'saturday') {
                    result.push(when + ' ' + sat + ' ' + interval + ' ' + month);
                  }
                  if (monthly.day === 'sunday') {
                    result.push(when + ' ' + sun + ' ' + interval + ' ' + month);
                  }

                  return result;
                // }
              } else {

                const interval = (monthly.interval === 1) ? each : each + ' ' + monthly.interval;
                result.push(monthly.date + '. ' + interval + " " + month);

                return result;
              }

              /**
               * If Old Cron, Daily and Monthly
               */
            } else {
              if(cron.repeat.daily){

                if(cron.repeat.daily.mon) { result.push(mon.substring(0,3)); }
                if(cron.repeat.daily.tue) { result.push(tue.substring(0,3)); }
                if(cron.repeat.daily.wed) { result.push(wed.substring(0,3)); }
                if(cron.repeat.daily.thu) { result.push(thu.substring(0,3)); }
                if(cron.repeat.daily.fri) { result.push(fri.substring(0,3)); }
                if(cron.repeat.daily.sat) { result.push(sat.substring(0,3)); }
                if(cron.repeat.daily.sun) { result.push(sun.substring(0,3)); }

                const interval = ' ' + each + ' ' + week;
                result.push(interval);

              } else if(cron.repeat.monthly){
                result.push(cron.repeat.monthly + '. ' + each + ' ' + month);
              }
              else{
                result.push('-');
              }

              return result;
            }
          }
        });
    return result.toString().toLowerCase();
  }

  /**
   * change format from yyyy-mm-dd to dd-mm-yy
   * @param date
   */
  changeDateFormat(date){
    if(date){
      const [yy, mm, dd] = date.split(/-/g);
      return `${dd}.${mm}.${yy}`;
    } else {
      return 'N/A';
    }
  }

  openRepeatChecklistDetail(checklist){
    this.dialogService.open(ChecklistRepeatDetailComponent, {
      header: checklist.name,
      styleClass: 'max-size-dialog',
      data: {
        rcId: checklist.key,
      }
    }).onClose.subscribe((updatedChecklist) => {

      // ReOpen Dialog to update checklist name
      if(updatedChecklist){
        this.openRepeatChecklistDetail(updatedChecklist);
      }
    });
  }

  /**
   * OnDestroy, unsubscribe
   */
  ngOnDestroy(): void {
    if (this.repeatingSubscription) {
      this.repeatingSubscription.unsubscribe();
    }
    if (this.assetSubscription) {
      this.assetSubscription.unsubscribe();
    }
  }

  addChecklist() {
    this.dialogService.open(AddChecklistComponent, {
      header: this.translateService.instant('CHECKLIST.ADD_CHECKLIST.NEW_CHECKLIST'),
      styleClass: 'max-size-width-dialog',
      data: {
        index: 1,
      }
    });
  }
}
