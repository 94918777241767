<p-toast position="top-right"></p-toast>
<p-confirmDialog header="{{'WARNING.CONFIRMATION' | translate}}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<!-- TABLET SCREEN-->
<ng-container *ngIf="!loading">
    <ng-container *ngIf="windowWidth > 500;  else cellphoneBlock">
        <!-- FLEXIT FILES -->
        <ng-container *ngIf="flexitFiles === 'flexit'; else internalFilesBlock">
            <div class="grid">
                <div class="col-12 flex justify-content-end">
                <span class="p-buttonset">
                    <button pButton type="button" icon="far fa-copy" label="{{'FILE.COPY' | translate}}" (click)="copy()"
                            class="p-button-outlined p-button-raised" disabled></button>
                    <button pButton class="p-button-raised" icon="far fa-download"  label="{{'FILE.DOWNLOAD' | translate}}"
                            (click)="downloadFile()"></button>
                </span>

                </div>
                <!-- File info -->
                <div class="col-6">
                    <div class="field mb-0">
                        <label for="createdByF">{{'COMMON.CREATED_BY' | translate}}</label>
                        <input id="createdByF" type="text" pInputText [(ngModel)]="subjectName"
                               class="inputfield w-full" disabled>
                    </div>
                </div>
                <div class="col-6">
                    <div class="field mb-0">
                        <label for="dateF">{{'COMMON.CREATED' | translate}}</label>
                        <input id="dateF" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full"
                               disabled>
                    </div>
                </div>
                <div class="col-12">
                    <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                    <textarea class="w-full" rows="2" cols="30" pInputTextarea
                              [(ngModel)]="file.description" disabled></textarea>
                </div>
            </div>
        </ng-container>
        <!-- INTERNAL FILES -->
        <ng-template #internalFilesBlock>
            <ng-container *ngIf="archived; else activeFilesBlock">
                <div class="grid">
                    <div class="col-12 flex justify-content-end">
                        <p-button label="{{'FILE.DOWNLOAD' | translate}}" (onClick)="downloadFile()"></p-button>
                    </div>
                    <!-- File info -->
                    <div class="col-6">
                        <div class="field mb-0">
                            <label for="createdByA">{{'COMMON.CREATED_BY' | translate}}</label>
                            <input id="createdByA" type="text" pInputText [(ngModel)]="subjectName"
                                   class="inputfield w-full" disabled>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="field mb-0">
                            <label for="dateA">{{'COMMON.CREATED' | translate}}</label>
                            <input id="dateA" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full"
                                   disabled>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                        <textarea class="w-full" rows="2" cols="30" pInputTextarea
                                  [(ngModel)]="file.description" disabled></textarea>
                    </div>
                    <div class="col-12 p-0">
                        <p-table [value]="fileTags | nameFilter: search" selectionMode="multiple"
                                 [(selection)]="selectedCategory"
                                 dataKey="key" responsiveLayout="scroll" [paginator]="true" [rows]="6">
                            <ng-template pTemplate="caption">
                                <div class="grid">
                                    <div class="col-12">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" [(ngModel)]="search"  class="inputfield"
                                                   placeholder="{{'FILE.CATEGORY_SEARCH' | translate}}"/>
                                        </span>
                                    </div>
                                </div>
                            </ng-template>

                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>{{'FILE.CATEGORIES' | translate}}</th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-tag let-rowIndex="rowIndex">
                                <tr [pSelectableRow]="tag" [pSelectableRowIndex]="rowIndex">
                                    <td>{{tag.tagName}}</td>
                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="2">
                                        {{'FILE.NO_CATEGORIES' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </ng-container>

            <ng-template #activeFilesBlock>
                <div class="grid">
                    <ng-container *ngIf="userStatus.get('admin') || userStatus.get('superUser'); else userBlock">

                        <ng-container *ngIf="userStatus.get('admin'); else superBlock">
                            <div class="col-6">
                                <button type="button" pButton icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
                                        (click)="menu.toggle($event)"></button>
                                <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                            </div>
                            <div class="col-6 flex justify-content-end">
                                <button pButton label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark"
                                        (click)="save()" [disabled]="changed()"></button>
                            </div>
                        </ng-container>

                        <ng-template #superBlock>
                            <div class="col-6">
                                <p-button label="{{'FILE.DOWNLOAD' | translate}}" (onClick)="downloadFile()"></p-button>
                            </div>
                            <div class="col-6 flex justify-content-end">
                                <p-button label="{{'BUTTON.SAVE' | translate}}" (onClick)="save()"
                                          [disabled]="changed()"></p-button>
                            </div>
                        </ng-template>

                        <!-- File info -->
                        <div class="col-6">
                            <div class="field mb-0">
                                <label for="createdByB">{{'COMMON.CREATED_BY' | translate}}</label>
                                <input id="createdByB" type="text" pInputText [(ngModel)]="subjectName"
                                       class="inputfield w-full" disabled>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field mb-0">
                                <label for="dateB">{{'COMMON.CREATED' | translate}}</label>
                                <input id="dateB" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full"
                                       disabled>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                            <textarea class="w-full" rows="2" cols="30" pInputTextarea
                                      [(ngModel)]="file.description" ></textarea>
                        </div>
                        <div class="col-12 p-0">
                            <p-table [value]="fileTags | nameFilter: search" selectionMode="multiple"
                                     [(selection)]="selectedCategory"
                                     dataKey="key" responsiveLayout="scroll" [paginator]="true" [rows]="6">
                                <ng-template pTemplate="caption">
                                    <div class="grid">
                                        <div class="col-12">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" [(ngModel)]="search"  class="inputfield"
                                                   placeholder="{{'FILE.CATEGORY_SEARCH' | translate}}"/>
                                        </span>
                                        </div>
                                    </div>
                                </ng-template>

                                <!-- Header -->
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>{{'FILE.CATEGORIES' | translate}}</th>
                                    </tr>
                                </ng-template>

                                <!-- Body -->
                                <ng-template pTemplate="body" let-tag let-rowIndex="rowIndex">
                                    <tr [pSelectableRow]="tag" [pSelectableRowIndex]="rowIndex" (click)="setTags(tag)">
                                        <td>{{tag.tagName}}</td>
                                    </tr>
                                </ng-template>

                                <!-- Shows message if table is empty -->
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="2">
                                            {{'FILE.NO_CATEGORIES' | translate}}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </ng-container>

                    <ng-template #userBlock>
                        <div class="col-12 flex justify-content-end">
                            <p-button label="{{'FILE.DOWNLOAD' | translate}}" (onClick)="downloadFile()"></p-button>
                        </div>

                        <!-- File info -->
                        <div class="col-6">
                            <div class="field mb-0">
                                <label for="createdByC">{{'COMMON.CREATED_BY' | translate}}</label>
                                <input id="createdByC" type="text" pInputText [(ngModel)]="subjectName"
                                       class="inputfield w-full" disabled>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="field mb-0">
                                <label for="dateC">{{'COMMON.CREATED' | translate}}</label>
                                <input id="dateC" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full"
                                       disabled>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                            <textarea class="w-full" rows="2" cols="30" pInputTextarea
                                      [(ngModel)]="file.description" disabled></textarea>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </ng-template>

    </ng-container>

    <!-- MOBILE SCREEN -->
    <ng-template #cellphoneBlock>
        <!-- FLEXIT FILES -->
        <ng-container *ngIf="flexitFiles === 'flexit'; else internalBlock">
            <div class="grid">
                <div class="col-12 flex justify-content-end">
                    <p-button label="{{'FILE.DOWNLOAD' | translate}}" (onClick)="downloadFile()"></p-button>
                </div>
                <!-- File info -->
                <div class="col-12">
                    <div class="field mb-0">
                        <label for="createdByFB">{{'COMMON.CREATED_BY' | translate}}</label>
                        <input id="createdByFB" type="text" pInputText [(ngModel)]="subjectName"
                               class="inputfield w-full" disabled>
                    </div>
                </div>
                <div class="col-12">
                    <div class="field mb-0">
                        <label for="dateFB">{{'COMMON.CREATED' | translate}}</label>
                        <input id="dateFB" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full"
                               disabled>
                    </div>
                </div>
                <div class="col-12">
                    <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                    <textarea class="w-full" rows="2" cols="30" pInputTextarea
                              [(ngModel)]="file.description" disabled></textarea>
                </div>
            </div>
        </ng-container>

        <!-- INTERNAL FILES -->
        <ng-template #internalBlock>
            <div class="grid">
                <ng-container *ngIf="userStatus.get('admin') || userStatus.get('superUser'); else userBlock">
                    <ng-container *ngIf="userStatus.get('admin'); else superBlock">
                        <div class="col-6">
                            <button type="button" pButton icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
                                    (click)="menu.toggle($event)"></button>
                            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                        </div>
                        <div class="col-6 flex justify-content-end">
                            <button pButton label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark" (click)="save()"
                                    [disabled]="changed()"></button>
                        </div>
                    </ng-container>
                    <ng-template #superBlock>
                        <div class="col-6">
                            <p-button label="{{'FILE.DOWNLOAD' | translate}}" (onClick)="downloadFile()"></p-button>
                        </div>
                        <div class="col-6 flex justify-content-end">
                            <p-button label="{{'BUTTON.SAVE' | translate}}" (onClick)="save()"
                                      [disabled]="changed()"></p-button>
                        </div>
                    </ng-template>
                    <!-- File info -->
                    <div class="col-6">
                        <div class="field mb-0">
                            <label for="SMcreatedByB">{{'COMMON.CREATED_BY' | translate}}</label>
                            <input id="SMcreatedByB" type="text" pInputText [(ngModel)]="subjectName"
                                   class="inputfield w-full" disabled>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="field mb-0">
                            <label for="SMdateB">{{'COMMON.CREATED' | translate}}</label>
                            <input id="SMdateB" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full"
                                   disabled>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                        <textarea class="w-full" rows="2" cols="30" pInputTextarea
                                  [(ngModel)]="file.description" ></textarea>
                    </div>
                    <div class="col-12 p-0">
                        <p-table [value]="fileTags | nameFilter: search" selectionMode="multiple"
                                 [(selection)]="selectedCategory"
                                 dataKey="key" responsiveLayout="scroll" [paginator]="true" [rows]="6">
                            <ng-template pTemplate="caption">
                                <div class="grid">
                                    <div class="col-12">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" [(ngModel)]="search"  class="inputfield"
                                                   placeholder="{{'FILE.CATEGORY_SEARCH' | translate}}"/>
                                        </span>
                                    </div>
                                </div>
                            </ng-template>

                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>{{'FILE.CATEGORIES' | translate}}</th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-tag let-rowIndex="rowIndex">
                                <tr [pSelectableRow]="tag" [pSelectableRowIndex]="rowIndex">
                                    <td>{{tag.tagName}}</td>
                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="2">
                                        {{'FILE.NO_CATEGORIES' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-container>
                <ng-template #userBlock>
                    <div class="col-12 flex justify-content-end">
                        <p-button label="{{'FILE.DOWNLOAD' | translate}}" (onClick)="downloadFile()"></p-button>
                    </div>
                    <div class="col-12">
                        <div class="field mb-0">
                            <label for="SMcreatedBy">{{'COMMON.CREATED_BY' | translate}}</label>
                            <input id="SMcreatedBy" type="text" pInputText [(ngModel)]="subjectName"
                                   class="inputfield w-full" disabled>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="field mb-0">
                            <label for="SMdate">{{'COMMON.CREATED' | translate}}</label>
                            <input id="SMdate" type="text" pInputText [(ngModel)]="dateString" class="inputfield w-full" disabled>
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="mb-2 mt-0">{{'FILE.DETAIL_DESCRIPTION' | translate}}</p>
                        <textarea class="w-full" rows="2" cols="30" pInputTextarea
                                  [(ngModel)]="file.description"></textarea>
                    </div>
                    <div class="col-12 p-0">
                        <p-table [value]="fileTags | nameFilter: search" selectionMode="multiple"
                                 [(selection)]="selectedCategory"
                                 dataKey="key" responsiveLayout="scroll" [paginator]="true" [rows]="6">
                            <ng-template pTemplate="caption">
                                <div class="grid">
                                    <div class="col-12">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search"></i>
                                            <input pInputText type="text" [(ngModel)]="search"  class="inputfield"
                                                   placeholder="{{'FILE.CATEGORY_SEARCH' | translate}}"/>
                                        </span>
                                    </div>
                                </div>
                            </ng-template>

                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>{{'FILE.CATEGORIES' | translate}}</th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-tag let-rowIndex="rowIndex">
                                <tr [pSelectableRow]="tag" [pSelectableRowIndex]="rowIndex">
                                    <td>{{tag.tagName}}</td>
                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="2">
                                        {{'FILE.NO_CATEGORIES' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>
            </div>
        </ng-template>
    </ng-template>

</ng-container>
