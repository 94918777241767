import { Component, OnInit } from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {PersonService} from "../../../services/service/person.service";
import {SubjectService} from "../../../services/service/subject.service";
import {GroupedSubjectsService} from "../../../services/sharedService/grouped-subjects.service";
import {FilterService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {ExerciseService} from "../../../services/service/exercise.service";
import {AppointmentService} from "../../../services/service/appointment.service";
import {EmergencyService} from "../../../services/service/emergency.service";
import {take} from "rxjs/operators";
import * as moment from "moment";
import {EmergencyResponse} from "../../../model/emergencyResponse";
import {DialogService} from "primeng/dynamicdialog";
import {PrintHoursComponent} from "../print-hours/print-hours.component";

@Component({
  selector: 'app-get-hours',
  templateUrl: './get-hours.component.html',
  styleUrls: ['./get-hours.component.scss'],
  providers: [DialogService]
})
export class GetHoursComponent implements OnInit {

  subscription = new Subscription();

  // Persons
  personMap = new Map();
  personNameMap = new Map<string, string>();
  outputPerson = [];
  selectedPerson = "";
  personArray = [];

  // Teams
  groupedSubjectFilter = [];
  teamPersonMap = new Map();
  teamNameMap = new Map();
  personParentNameMap = new Map();

  // Stations
  groupedStationFilter = [];
  stationNameMap = new Map();
  teamParentNameMap = new Map();
  outputStations = [];
  selectedStation = "";
  stationMap = new Map();

  // Data
  selectedDataModules = [];
  dataModules: any[];

  // Date range
  dateFrom: any;
  dateTo: any;

  // Data
  exercises = [];
  appointments = [];
  oldAppointments = [];
  events = [];

  // Person hour map
  personHourDataMap: Map<string, Map<string, [any]>> = new Map();

  // Event id new events
  emergencyId = new Map();
  emergencyYear = new Map<string, boolean>();

  constructor(
    private personService: PersonService,
    private subjectService: SubjectService,
    private gSService: GroupedSubjectsService,
    private filterService: FilterService,
    private translateService: TranslateService,
    private exerciseService: ExerciseService,
    private appointmentService: AppointmentService,
    private eventService: EmergencyService,
    private dialogService: DialogService,
  ) {
    this.subscription.add(
      combineLatest([
        this.personService.getPersons(),
        this.subjectService.getTeams(),
        this.subjectService.getStations(),
        this.subjectService.getRelations(),
      ]).subscribe(([persons, teams, stations, relations]) => {

        const data = this.gSService.build(persons, teams, relations);
        this.personNameMap = data[0];
        this.teamNameMap = data[1];
        this.personParentNameMap = data[2];
        this.groupedSubjectFilter = data[3];

        this.groupedSubjectFilter.forEach(group => {
          this.teamPersonMap.set(group.value, group.items);
        });
        const data2 =  this.gSService.build(teams, stations, relations);
        this.stationNameMap = data2[1];
        this.teamParentNameMap = data2[2];
        this.groupedStationFilter = data2[3];
      })
    );

    this.dataModules = [
      {name: this.translateService.instant('MAIN_FRAME.EXERCISE'), code: 0},
      {name: this.translateService.instant('EVENT.APPOINTMENTS'), code: 1},
      {name: this.translateService.instant('SHIFT_REPORT.EVENTS'), code: 2}
    ];
  }

  ngOnInit(): void {

  }

  /**
   * Add all persons belonging to selected station.
   * @param station Station object.
   */
  addStation(station: any) {
    station.items.forEach(team => {
      if (this.teamPersonMap.has(team.value)) {
        this.teamPersonMap.get(team.value).forEach(participant => this.addPerson(participant));
      }
    });
  }

  /**
   * Add all persons belonging to selected team.
   * @param team Team object.
   */
  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  /**
   * Add participant to table.
   * @param subject Person object.
   */
  addPerson(subject: any) {
    if (!this.personMap.has(subject.value)) {
      // Add person
      this.personMap.set(subject.value, true);
      this.personArray.push(subject.value);
      this.personHourDataMap.set(subject.value,
        new Map<string, [any]>());
      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a).toLowerCase().localeCompare(this.personNameMap.get(b).toLowerCase());
      });
      this.selectedPerson = '';
    }
  }

  /**
   * Remove selected person from table.
   * @param personId Person FBID.
   */
  deletePerson(personId: string) {

  }

  /**
   * Filter according to search.
   * @param $event Click event.
   */
  filterPerson($event) {
    const query = $event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Filter according to search.
   * @param $event Click event.
   */
  filterStations($event) {
    const query = $event.query;
    const filteredGroups = [];

    for (const group of this.groupedStationFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputStations = filteredGroups;
  }

  /**
   * Reset all
   */
  reset() {
    this.personArray = [];
    this.selectedPerson = "";

    this.outputStations = [];
    this.selectedStation = "";

    this.dateFrom = null;
    this.dateTo = null;

    this.selectedDataModules = [];

    this.events = [];
    this.appointments = [];
    this.oldAppointments = [];
    this.exercises = [];

    this.personHourDataMap = new Map();
    this.personMap = new Map();
  }

  /**
   * Check if needed data is present.
   */
  valid() {
    return !(this.dateFrom < this.dateTo && this.personArray.length >= 1 && this.selectedDataModules.length >= 1);
  }

  /**
   * Create report
   */
  createReport() {
    const date = moment(this.dateFrom).startOf('day').add(2, 'h').toISOString();
    const dateTo = moment(this.dateTo).endOf('day').add(2, 'h').toISOString();

    // Exercises
    if (this.selectedDataModules.includes(0)){
      const dModules = [];
      if (this.selectedDataModules.includes(1)){
        dModules.push(1);
      }
      if (this.selectedDataModules.includes(2)){
        dModules.push(2);
      }
      this.getExercises(date, dateTo, dModules);
    }
    // Appointments
    else if (this.selectedDataModules.includes(1)){
      const dModules = [];
      if (this.selectedDataModules.includes(2)){
        dModules.push(2);
      }
      this.getAppointments(date, dateTo, dModules);
    }
    // Events
    else {
      this.getEvents();
    }

  }

  /**
   * Get exercises then "get appointments then events" or "get events" or continue.
   * @param date : string
   * @param dateTo : string
   * @param dModules  : number[]
   * @private
   */
  private getExercises(date, dateTo, dModules: number[]){
    this.exerciseService.getCompletedExercises().pipe(take(1)).subscribe(exerciseList => {
      exerciseList.forEach(exercise => {
        if (exercise.payload.val().date >= date && exercise.payload.val().dateEnd <= dateTo){
          this.exercises.push({key: exercise.key, ...exercise.payload.val()});
        }
      });
      if (dModules.includes(1)){
        if (dModules.includes(2)){
          dModules = [2];
        }
        this.getAppointments(date, dateTo, dModules);
      } else if (dModules.includes(2)){
        this.getEvents();
      } else {
        this.processData();
      }
    });
  }

  /**
   * Get appointments then "get events" or continue.
   * @param date : string
   * @param dateTo : string
   * @param dModules  : number[]
   * @private
   */
  private getAppointments(date: string, dateTo: string, dModules: number[]){
    this.appointmentService.getAppointments().pipe(take(1)).subscribe(appointmentList => {
      appointmentList.forEach(appointment => {
        if (appointment.payload.val().date >= date && appointment.payload.val().dateTo <= dateTo){
          this.appointments.push({key: appointment.key, ...appointment.payload.val()});
        }
      });
      this.appointmentService.getOldAppointments().pipe(take(1)).subscribe(oldAppointmentList => {

        const from = moment(this.dateFrom).format("DD.MM.YYYY").split(".");
        const to = moment(this.dateTo).format("DD.MM.YYYY").split(".");

        oldAppointmentList.forEach(appointment => {
          let d = appointment.payload.val().date.split(".");
          d = [d[2], d[1], d[0]];
          const f = [from[2], from[1], from[0]];
          const t = [to[2], to[1], to[0]];
          if (moment(d, "YYYY/MM/DD").diff(moment(f, "YYYY/MM/DD"), 'days') >= 0
            && moment(d, "YYYY/MM/DD").diff(moment(t, "YYYY/MM/DD"), 'days') <= 0){
            this.oldAppointments.push({key: appointment.key, ...appointment.payload.val()});
          }
        });

        if (dModules.includes(2)){
          this.getEvents();
        } else {
          this.processData();
        }
      });
    });

  }

  /**
   * Get events
   * @private
   */
  private getEvents(){

    const from = moment(this.dateFrom).format("DD.MM.YYYY").split(".");
    const to = moment(this.dateTo).format("DD.MM.YYYY").split(".");

    this.eventService.getEmergenciesForReport().pipe(take(1)).subscribe(eventList => {
      let counter = 1; this.emergencyYear = new Map<string, boolean>();
      const emergencies = eventList.map(emergency => {
        return {key$: emergency.key, ...emergency.payload.val()};
      }).sort((a, b) =>{
        const aDate = moment(a.date.split('.').reverse().join("-") + " " + a.timeStart);
        const bDate = moment(b.date.split('.').reverse().join("-") + " " + b.timeStart);
        return bDate.diff(aDate);
      });
      emergencies.reverse().forEach(emergency => {
        const date = emergency.date.split('.');

        if (!this.emergencyYear.has(date[2])) {
          this.emergencyYear.set(date[2], true);
          const createdMap = new Map();
          this.emergencyId.set(date[2], createdMap.set(emergency.dateCreated, 1));
          counter = 1;
        }
        else {
          counter++;
          this.emergencyId.get(date[2]).set(emergency.dateCreated, counter);
        }
      });

      eventList.forEach(event => {
        let d = event.payload.val().date.split(".");
        d = [d[2], d[1], d[0]];
        const f = [from[2], from[1], from[0]];
        const t = [to[2], to[1], to[0]];


        if (moment(d, "YYYY/MM/DD").diff(moment(f, "YYYY/MM/DD"), 'days') >= 0
          && moment(d, "YYYY/MM/DD").diff(moment(t, "YYYY/MM/DD"), 'days') <= 0){
          this.events.push({key: event.key, ...event.payload.val()});
        }
      });
      this.processData();
    });

  }

  /**
   * Build PersonHourDataMap based on selected data modules {SubjectFBID, {DataModule, {name, date, from, to}}}
   * @private
   */
  private processData(){
    // Process exercises.
    this.exercises.forEach(exercise => {
      if (exercise.participants){

        const dates = [];
        if (moment(exercise.date).diff(moment(exercise.dateEnd), 'days') === 0){
          dates.push(moment(exercise.date).format("DD.MM.YYYY").toString());
        } else {
          for (let i = 0; i <= moment(exercise.dateEnd).diff(moment(exercise.date), 'days'); i++) {
            const d = moment(exercise.date).add(i, 'days');
            dates.push(d.format("DD.MM.YYYY").toString());
          }
        }

        const from = moment(exercise.date).format("HH:mm").toString();
        const to = moment(exercise.dateEnd).format("HH:mm").toString();

        exercise.participants.forEach(participant => {
          if (this.personHourDataMap.has(participant.key)){
            dates.forEach(date => {
              if (this.personHourDataMap.get(participant.key).has("exercise")){
                this.personHourDataMap.get(participant.key).get("exercise").push(
                  {name: exercise.name, date: date, from: from, to: to, absence: participant.absence}
                );
              } else {
                this.personHourDataMap.get(participant.key).set(
                  "exercise",
                  [{name: exercise.name, date: date, from: from, to: to, absence: participant.absence}]
                );
              }
            });
          }
        });
      }
    });
    // Process new appointments.
    this.appointments.forEach(appointment => {
      const dates = [];
      if (moment(appointment.date).diff(moment(appointment.dateTo), 'days') === 0){
        dates.push(moment(appointment.date).format("DD.MM.YYYY").toString());
      } else {
        for (let i = 0; i <= moment(appointment.dateTo).diff(moment(appointment.date), 'days'); i++) {
          const d = moment(appointment.date).add(i, 'days');
          dates.push(d.format("DD.MM.YYYY").toString());
        }
      }
      Object.keys(appointment.participants).forEach(key => {
        if (this.personHourDataMap.has(key)){
          dates.forEach(date => {
            if (this.personHourDataMap.get(key).has("appointment")){
              this.personHourDataMap.get(key).get("appointment")
                .push({name: appointment.name, date: date, from: appointment.timeStart, to: appointment.timeEnd});
            } else {
              this.personHourDataMap.get(key).set(
                "appointment",
                [{name: appointment.name, date: date, from: appointment.timeStart, to: appointment.timeEnd}]
              );
            }
          });
        }
      });
    });
    // Process new appointments.
    this.oldAppointments.forEach(appointment => {
      let f = appointment.date.split(".");
      f = [f[2], f[1], f[0]];
      let t = appointment.dateTo.split(".");
      t = [t[2], t[1], t[0]];

      const dates = [];
      if (moment(f).diff(moment(t), 'days') === 0){
        dates.push(moment(f).format("DD.MM.YYYY").toString());
      } else {
        for (let i = 0; i <= moment(t).diff(moment(f), 'days'); i++) {
          const d = moment(f).add(i, 'days');
          dates.push(d.format("DD.MM.YYYY").toString());
        }
      }
      appointment.participants.forEach(participant => {
        if (this.personHourDataMap.has(participant.key)){
          dates.forEach(date => {
            if (this.personHourDataMap.get(participant.key).has("appointment")){
              this.personHourDataMap.get(participant.key).get("appointment")
                .push({name: appointment.name, date: date, from: appointment.timeStart, to: appointment.timeEnd});
            } else {
              this.personHourDataMap.get(participant.key).set(
                "appointment",
                [{name: appointment.name, date: date, from: appointment.timeStart, to: appointment.timeEnd}]
              );
            }
          });
        }
      });
    });
    // Process events.
    this.events.forEach(event => {
      let date = event.date.split(".");
      if (date[1].startsWith(0)){
        date[1] = date[1].substring(1);
      }
      date = date[0] + "." + date[1] + "." + date[2];

      const eId = this.getCounterId(event);
      event.participants.forEach(participant => {
        if (this.personHourDataMap.has(participant.key)){
          if (participant.newData){
            if (this.personHourDataMap.get(participant.key).has("event")){
              this.personHourDataMap.get(participant.key).get("event")
                .push(
                  {
                    new: true,
                    id: eId,
                    date: date,
                    from: participant.timeStart,
                    to: participant.timeEnd,
                    total: participant.time
                  });
            } else {
              this.personHourDataMap.get(participant.key).set(
                "event",
                [
                  {
                    new: true,
                    id: eId,
                    date: date, from:
                    participant.timeStart,
                    to: participant.timeEnd,
                    total: participant.time
                  }
                ]
              );
            }
          } else {
            if (this.personHourDataMap.get(participant.key).has("event")){
              this.personHourDataMap.get(participant.key).get("event")
                .push(
                  {
                    new: false,
                    id: event.uniqueId,
                    date: date,
                    time: participant.time,
                    suppTime: participant.supplementaryTime,
                  }
                );
            } else {
              this.personHourDataMap.get(participant.key).set(
                "event",
                [
                  {
                    new: false,
                    id: event.uniqueId,
                    date: date,
                    time: participant.time,
                    suppTime: participant.supplementaryTime,
                  }
                ]
              );
            }
          }
        }
      });
    });

    const da = moment(this.dateFrom).format("DD.MM.YYYY");
    const dT = moment(this.dateTo).format("DD.MM.YYYY");

    this.dialogService.open(PrintHoursComponent, {
      header: da + " - " + dT,
      data: {
        personHourDataMap: this.personHourDataMap,
        personNameMap: this.personNameMap
      }
    }).onClose.subscribe(() => {
      this.reset();
    });
  }


  /**
   * Get Event number
   * @param emergency : EmergencyResponse
   */
  private getCounterId(emergency: EmergencyResponse) {
    const date = emergency.date.split('.');
    const list = this.emergencyId.get(date[2]);
    const YY = date[2].slice(-2);
    return list.get(emergency.dateCreated) + "/" + YY;
  }
}
