import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AccessControlService} from './service/access-control.service';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CanActivateService implements CanActivate{

  constructor(private acService: AccessControlService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
    return combineLatest([this.acService.isAdmin(), this.acService.isSuperUser()]).pipe(
        map(([isAdmin, isSuper]) => {
          if (isAdmin || isSuper) {
            return true;
          } else {
            return this.router.createUrlTree([""]);
          }
        })
    );
  }

}
