<p-toast position="top-right" styleClass="customToast"></p-toast>

<!-- Task Form -->
<form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
    <div class="formgrid grid">

        <!-- Large screen -->
        <ng-container *ngIf="windowWidth > 480; else smallScreenBlock">

            <!-- Task name -->
            <div class="field col-12 mt-3">
            <span class="p-float-label">
                <input id="name" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                       formControlName="taskName" aria-describedby="name-help">
                <label for="name">{{'TASK.TASK_TITLE' | translate}}*</label>
            </span>
                <small id="name-help" class="block">{{'DEVIATION.HELPER' | translate}}</small>
            </div>

            <!-- Asset/Station -->
            <div class="col-5">
                <p-dropdown [options]="groupedAssetsFilter"
                            placeholder="{{'ASSET.CHOOSE' | translate}}*"
                            [group]="true" [style]="{'width' : '100%', 'height' : '42px'}"
                            formControlName="assetId"
                >
                    <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                            <span><i class="far fa-warehouse"></i> {{group.label}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>

            <!-- Date -->
            <div class="col-4">
                <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                            dateFormat="dd.mm.yy"
                            [style]="{'width' : '100%', 'height' : '42px'}"
                            class="w-full inputfield"
                            formControlName="date"
                ></p-calendar>
            </div>

            <!-- Time -->
            <div class="field col-3">
                <input type="time"
                       pInputText
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       class="inputfield w-full"
                       formControlName="time"
                >
            </div>

            <!-- Description -->
            <div class="field col-12 mt-2">
                <span class="p-float-label">
                    <textarea id="textarea"
                              rows="4"
                              style="resize: none;"
                              cols="30"
                              pInputTextarea
                              class="inputfield w-full"
                              formControlName="description"></textarea>
                    <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}*</label>
                </span>
            </div>

            <!-- Person table -->
            <div class="col-12 flex">
                <p-table responsiveLayout="block"
                         breakpoint="200px"
                         [value]="selectedSubjects"
                         [(selection)]="selectedLeader"
                         selectionMode="radiobutton"
                >
                    <!-- Search bar -->
                    <ng-template pTemplate="caption" class="pt-5">
                        <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                                        [(ngModel)]="selectedPerson"
                                        (onSelect)="addPerson(selectedPerson)"
                                        [ngModelOptions]="{standalone: true}"
                                        placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}*"
                                        [dropdown]="true"
                                        (completeMethod)="filterPerson($event)"
                                        size="100%"
                                        field="label"
                                        type="search"
                                        scrollHeight="400px"
                                        class="inputfield w-full"
                                        appendTo="body"
                                        [forceSelection]="true"
                                        [group]="true"
                        >

                            <ng-template let-team pTemplate="group">
                                <div class="flex align-items-center p-2" (click)="addTeam(team)">
                                    <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                                </div>
                            </ng-template>
                            <ng-template let-subject pTemplate="item">
                                <div class="person-item">
                                    {{subject.label}}
                                </div>
                            </ng-template>

                        </p-autoComplete >
                    </ng-template>

                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                            <th>{{'FDC.PERSONS' | translate}}</th>
                            <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
                        </tr>
                    </ng-template>

                    <!-- Body -->
                    <ng-template pTemplate="body" let-personId >
                        <tr>
                            <td>
                                <p-tableRadioButton [value]="personId"></p-tableRadioButton>
                            </td>
                            <td>
                                {{personNameMap.get(personId)}}
                            </td>
                            <td (click)="removePerson(personId)">
                            <span class="flex justify-content-center">
                                <i class="pi pi-trash" style="color: #D0021B"></i>
                            </span>
                            </td>
                        </tr>
                    </ng-template>

                    <!-- Shows message if table is empty -->
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td></td>
                            <td colspan="2">
                                {{'WARNING.NO_DATA' | translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <!-- Notification -->
            <div class="col-12">
                <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
            </div>

            <!-- SMS - Notification -->
            <div class="field-checkbox col-4 flex align-items-center">
                <p-checkbox id="smsNotification" binary="true"
                            [(ngModel)]="smsNotification"
                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                <label for="smsNotification">{{'BUTTON.SMS' | translate}}</label>
            </div>
            <div class="field col-6">
                <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                               [ngModelOptions]="{standalone: true}"
                               [showButtons]="true" [min]="1"
                               placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                               [max]="100" class="inputfield w-full"></p-inputNumber>
            </div>

            <!-- Email - Notification -->
            <div class="field-checkbox col-4 flex align-items-center mb-3">
                <p-checkbox id="emailNotification" binary="true" [(ngModel)]="emailNotification"
                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                <label for="emailNotification">{{'PERSONAL.EMAIL' | translate}}</label>
            </div>

            <div class="field col-6 mb-3">
                <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                               placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                               [max]="100" class="inputfield w-full"></p-inputNumber>
            </div>
            <div class="col-12">&nbsp;</div>
            <!-- Save button -->
            <div class="col-12 flex justify-content-end">
                <button pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
                        (click)="close()" class="p-button-outlined float-right p-button-raised"></button>
                <button class="float-right p-button-raised" pButton icon="pi pi-bookmark"
                        type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="onSubmit()"
                        [disabled]="!taskForm.valid || personMap.size < 1 ||
                        (smsNotification && smsDaysBefore < 1) ||
                        (emailNotification && emailDaysBefore < 1)"></button>
            </div>
        </ng-container>

        <!-- Small screen -->
        <ng-template #smallScreenBlock>

            <!-- Task name -->
            <div class="field col-12 mt-3">
            <span class="p-float-label">
                <input id="nameSmall" type="text" pInputText
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       formControlName="taskName" aria-describedby="name-help">
                <label for="nameSmall">{{'TASK.TASK_TITLE' | translate}}*</label>
            </span>
                <small id="nameSmall-help" class="block">{{'DEVIATION.HELPER' | translate}}</small>
            </div>

            <!-- Date -->
            <div class="col-6">
                <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                            dateFormat="dd.mm.yy"
                            [style]="{'width' : '100%', 'height' : '42px'}"
                            class="w-full inputfield"
                            formControlName="date"
                ></p-calendar>
            </div>

            <!-- Time -->
            <div class="field col-6">
                <input type="time"
                       pInputText
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       class="inputfield w-full"
                       formControlName="time"
                >
            </div>

            <!-- Asset/Station -->
            <div class="col-12">
                <p-dropdown [options]="groupedAssetsFilter"
                            placeholder="{{'ASSET.CHOOSE' | translate}}*"
                            [group]="true"
                            [style]="{'width' : '100%', 'height' : '42px'}"
                            formControlName="assetId">
                    <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                            <span><i class="far fa-warehouse"></i> {{group.label}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>

            <!-- Description -->
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <textarea id="textareaSmall"
                              rows="4"
                              style="resize: none;"
                              cols="30"
                              pInputTextarea
                              class="inputfield w-full"
                              formControlName="description"></textarea>
                    <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}*</label>
                </span>
            </div>

            <!-- Person table -->
            <div class="col-12 flex">
                <p-table responsiveLayout="block"
                         breakpoint="200px"
                         [value]="selectedSubjects"
                         [(selection)]="selectedLeader"
                         selectionMode="radiobutton">

                    <!-- Search bar -->
                    <ng-template pTemplate="caption" class="pt-5">
                        <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                                        [(ngModel)]="selectedPerson"
                                        (onSelect)="addPerson(selectedPerson)"
                                        [ngModelOptions]="{standalone: true}"
                                        placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}*"
                                        [dropdown]="true"
                                        (completeMethod)="filterPerson($event)"
                                        size="100%"
                                        field="label"
                                        type="search"
                                        scrollHeight="400px"
                                        class="inputfield w-full"
                                        appendTo="body"
                                        [forceSelection]="true"
                                        [group]="true"
                        >

                            <ng-template let-team pTemplate="group">
                                <div class="flex align-items-center p-2" (click)="addTeam(team)">
                                    <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                                </div>
                            </ng-template>
                            <ng-template let-subject pTemplate="item">
                                <div class="person-item">
                                    {{subject.label}}
                                </div>
                            </ng-template>

                        </p-autoComplete >
                    </ng-template>

                    <!-- Header -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                            <th>{{'FDC.PERSONS' | translate}}</th>
                            <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
                        </tr>
                    </ng-template>

                    <!-- Body -->
                    <ng-template pTemplate="body" let-personId >
                        <tr>
                            <td>
                                <p-tableRadioButton [value]="personId"></p-tableRadioButton>
                            </td>
                            <td>
                                {{personNameMap.get(personId)}}
                            </td>
                            <td (click)="removePerson(personId)">
                                <span class="flex justify-content-center">
                                    <i class="pi pi-trash" style="color: #D0021B"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-template>

                    <!-- Shows message if table is empty -->
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td></td>
                            <td colspan="2">
                                {{'WARNING.NO_DATA' | translate}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <!-- Notification -->
            <div class="col-12">
                <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
            </div>

            <!-- SMS - Notification -->
            <div class="field-checkbox col-4 flex align-items-center">
                <p-checkbox id="smsNotificationSmall" binary="true"
                            [(ngModel)]="smsNotification"
                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                <label for="smsNotificationSmall">{{'BUTTON.SMS' | translate}}</label>
            </div>
            <div class="field col-6">
                <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                               [showButtons]="true" [min]="1"
                               placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                               [max]="100" class="inputfield w-full"
                               [ngModelOptions]="{standalone: true}"></p-inputNumber>
            </div>

            <!-- Email - Notification -->
            <div class="field-checkbox col-4 flex align-items-center mb-3">
                <p-checkbox id="emailNotificationSmall" binary="true" [(ngModel)]="emailNotification"
                            [ngModelOptions]="{standalone: true}"></p-checkbox>
                <label for="emailNotificationSmall">{{'PERSONAL.EMAIL' | translate}}</label>
            </div>
            <div class="field col-6 mb-3">
                <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                               placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                               [max]="100" class="inputfield w-full"></p-inputNumber>
            </div>
            <div class="col-12">&nbsp;</div>
            <!-- Save button -->
            <div class="col-12 flex justify-content-end">
                <button pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
                        (click)="close()" class="p-button-outlined float-right p-button-raised"
                ></button>
                <button class="float-right p-button-raised" pButton icon="pi pi-bookmark"
                        type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="onSubmit()"
                        [disabled]="!taskForm.valid || personMap.size < 1 ||
                        (smsNotification && smsDaysBefore < 1) ||
                        (emailNotification && emailDaysBefore < 1)"></button>
            </div>
        </ng-template>
    </div>
</form>
