import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {map, mergeMap, publish} from 'rxjs/operators';
import {Task} from '../../model/task';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    tenantId$: Observable<any>;

    constructor(
        private tenantService: TenantService,
        private db: AngularFireDatabase,
    ) {
        this.tenantId$ = this.tenantService.getTenantId();
    }


    /**
     * Get all tasks
     */
    getTasks(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/tasks/`).snapshotChanges()
            )
        );
    }

    /**
     * Get task
     * @param key <fbid>
     */
    getTask(key: string): Observable<any>  {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.object(`tenants/${tenantId}/tasks/${key}`).valueChanges()
            )
        );
    }

    /**
     * Get all active tasks
     */
    getActiveTasks(): Observable<any> {
        return this.tenantId$.pipe(
            mergeMap(
                tenantId => this.db.list(`tenants/${tenantId}/tasks/`, ref => {
                    return ref.orderByChild('completed').equalTo(null);
                }).snapshotChanges()
            )
        );
    }

    /**
     * Add new Task and return key
     * @param task Task object
     */
    addTaskGetKey(task: Task): Observable<string>{
        return this.tenantId$.pipe(
            map(tenantId => {
                const ref = this.db.list(`tenants/${tenantId}/tasks/`).push(task);
                return ref.key;
            })
        );
    }

    /**
     * Add subjects to Task
     * @param task task fbid
     * @param id subject fbid
     * @param bool true
     */
    addSubjectsToTask(task: string, id: string, bool: boolean){
        return publish()(this.tenantId$.pipe(
            map(tenantId => this.db.object(`tenants/${tenantId}/tasks/${task}/subjects/${id}`).set(bool)))).connect();
    }

    /**
     * Update task data
     * @param task task data
     * @param key task fbid
     */
    updateTask(task: Task, key: string){
        return publish()(this.tenantId$.pipe(
            map(
                tenantId => this.db.object(`tenants/${tenantId}/tasks/${key}`).set(task)
            )
        )).connect();
    }

    /**
     * Delete task
     * @param key task fbid
     */
    deleteTask(key: string) {
        return publish()(this.tenantId$.pipe(
            map(
                tenantId => this.db.object(`tenants/${tenantId}/tasks/${key}`).remove()
            )
        )).connect();
    }
}
