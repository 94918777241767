import {Injectable} from '@angular/core';
import {TenantService} from "./tenant.service";
import {Observable} from "rxjs";
import { map, mergeMap, publish, take} from "rxjs/operators";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AuthService} from "./auth.service";
const generator = require('secure-random-password');

@Injectable({
  providedIn: 'root'
})

/**
 * Handles everything that's about AccessControl
 */
export class AccessControlService {
  tenantId$: Observable<any>;

  constructor(
      private tenantService: TenantService,
      private db: AngularFireDatabase,
      private authService: AuthService
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getAccessControls(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenant => this.db.list(`tenants/${tenant}/accessControls`).valueChanges())
    );
  }

  getAccessControlsPayload(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenant => this.db.list(`tenants/${tenant}/accessControls`).snapshotChanges())
    );
  }

  getTenantUserAdmins() {
    return this.tenantId$.pipe(
        mergeMap(
            tenant => this.db.list(`tenantUserAdmin/${tenant}`).snapshotChanges())
    );
  }

  getTenantUserAdmin(key) {
    return this.tenantId$.pipe(
        mergeMap(
            tenant => this.db.object(`tenantUserAdmin/${tenant}/${key}`).valueChanges())
    );
  }

  getOwnAccessControl(): Observable<any> {
    return this.authService.getUserUID().pipe(
        mergeMap(uid => {
          return this.tenantId$.pipe(
              mergeMap(
                  tenant => this.db.object(`tenants/${tenant}/accessControls/${uid}`).valueChanges()
              ),
          );
        }),
    );
  }

  getSubjectIdFromUID(uid): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/accessControls/${uid}/subjectId`).valueChanges()
        )
    );
  }

  /**
   * Get subjectId
   */
  getSubjectId(): Observable<any> {
    return this.authService.getUserUID().pipe(
        mergeMap(uid => {
          return this.tenantId$.pipe(
              mergeMap(
                  tenantId => this.db.object(`tenants/${tenantId}/accessControls/${uid}/subjectId`).valueChanges()
              )
          );
        }),
    );
  }
  isAdmin(): Observable<boolean> {
    return this.getOwnAccessControl().pipe(
        map(ac => ac.roles.admin)
    );
  }

  isSuperUser(): Observable<boolean> {
    return this.getOwnAccessControl().pipe(
        map(ac => ac.roles.superuser)
    );
  }

  isUser(): Observable<boolean> {
    return this.getOwnAccessControl().pipe(
        map(ac => ac.roles.user)
    );
  }

  isObserver(): Observable<boolean> {
    return this.getOwnAccessControl().pipe(
        map(ac => ac.roles.observer)
    );
  }

  /**
   * @return list of roles {name: translate, value: {role: true}}
   */
  getRoles() {
    return [
      {name: 'ADMIN.ROLES.ADMIN', value: {admin: true}},
      {name: 'ADMIN.ROLES.SUPERUSER', value: {superuser: true}},
      {name: 'ADMIN.ROLES.USER', value: {user: true}},
    ];
  }

  getAccessControlsWithSubjectId(): Observable<any> {
    return this.getAccessControlsPayload().pipe(
        map(acs => acs.filter(ac => ac.payload.val().subjectId)),
    );
  }

  createUser(person, roleObject) {
    this.authService.getUserUID().pipe(
        take(1),
    ).subscribe(createdByUID => {
      this.authService.createUserWithEmailAndPassword(person.email, generator.randomPassword())
          .then(newUID => {
            this.setAccessControl(newUID, roleObject, person.key);
            this.setTenantUserAdmin(createdByUID, newUID, person.email);
          })
          .catch(error => {
            alert(error.message);
          });
    });
  }

  createTempUser(name: any, email, roleObject: any) {
    this.authService.getUserUID().pipe(
        take(1),
    ).subscribe(createdByUID => {
      this.authService.createUserWithEmailAndPassword(email, generator.randomPassword())
          .then(newUID => {
            this.setAccessControl(newUID, roleObject, null, name);
            this.setTenantUserAdmin(createdByUID, newUID, email);
          })
          .catch(error => {
            alert(error.message);
          });
    });
  }

  getTenantUserAdminDisabled() {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenantUserAdmin/${tenantId}`, ref => {
              return ref.orderByChild('operation').equalTo('disabled');
            }).snapshotChanges())
    );
  }

  updateRole(acKey, roleObject: any) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenants/${tenantId}/accessControls/${acKey}/roles`).set(roleObject)
        )
    )).connect();
  }

  getAccessControl(key) {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/accessControls/`, ref => {
              return ref.orderByChild('subjectId').equalTo(key);
            }).valueChanges())
    );
  }

  updateTenantUserAdmin(key: any, tua: any) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.object(`tenantUserAdmin/${tenantId}/${key}`).update(tua)
        )
    )).connect();
  }

  updateTenantUserAdminOperationStatus(personId, status) {

    this.getPersonUID(personId)
        .pipe(
            take(1),
        )
        .subscribe(uid => {
          return publish()(this.tenantId$.pipe(
              mergeMap(
                  tenantId => this.db.object(`tenantUserAdmin/${tenantId}/${uid}/operation`).set(status)
              ),
          )).connect();
        });
  }

  isDisabled(uid) {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenantUserAdmin/${tenantId}/${uid}/operation`).valueChanges()
        ),
        map(operation => operation === 'disabled')
    );
  }

  private getPersonUID(personId): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/accessControls/`, ref => {
              return ref.orderByChild('subjectId').equalTo(personId);
            }).snapshotChanges()
        ),
        map(acs => acs[0].key)
    );
  }


  private setAccessControl(newUID, roles, subjectId?, name?): any {
    let ac;

    if (subjectId) {
      ac = {
        subjectId: subjectId,
        roles: roles,
      };
    } else {
      ac = {
        name: name,
        roles: roles,
      };
    }
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenants/${tenantId}/accessControls/${newUID}`).set(ac)
        )
    )).connect();
  }

  private setTenantUserAdmin(createdByUID: any, newUID: string | void, email) {
    const tua = {
      createdBy: createdByUID,
      creationDate: new Date().toISOString(),
      operation: "add",
      email: email
    };
    return publish()(this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.object(`tenantUserAdmin/${tenantId}/${newUID}`).set(tua)
        )
    )).connect();
  }
}
