import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ChecklistService} from "../../../services/service/checklist.service";
import {CronJob} from "../../../model/cronJob";
import {Checklist} from "../../../model/checklist";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AssetService} from "../../../services/service/asset.service";
import {SubjectService} from "../../../services/service/subject.service";
import * as moment from "moment";
import {DateService} from "../../../services/service/date.service";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-edit-repeat-checklist',
  templateUrl: './edit-repeat-checklist.component.html',
  styleUrls: ['./edit-repeat-checklist.component.scss']
})
export class EditRepeatChecklistComponent implements OnInit, OnDestroy {

  checklistId: string;
  checklist: Checklist = {createdById: "", createdDate: "", name: ""};

  cronJobId: string;
  cronJob: CronJob = {archive: false, checklistId: "", newChecklistCron: false, pause: false};

  // FormGroup
  cronJobForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    assetId: new UntypedFormControl('', Validators.required),
    stationId: new UntypedFormControl('', Validators.required),

    weekly: new UntypedFormGroup({
      interval: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(1),
        Validators.max(8)
      ]),
      delay: new UntypedFormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(8)
      ]),
      day: new UntypedFormControl(0, Validators.required),
    }),

    monthly: new UntypedFormGroup({
      interval: new UntypedFormControl(1, Validators.required),
      date: new UntypedFormControl('', Validators.required),
      when: new UntypedFormControl('', Validators.required),
      day: new UntypedFormControl('', Validators.required),
      startMonth: new UntypedFormControl((moment().month()+1), Validators.required),
    })
  });

  // Option of Mon - Sun
  daysOption: any[];

  // Option of Jan - Dec
  monthOption: any[];

  // Option of days in the selected month, used in monthly.
  days;

  // Option of when, used in monthly
  whenOption;

  // Asset Options
  assetOptions: { key: string, name: string }[];

  // Maps
  stationNameMap: Map<string, string> = new Map();
  assetStationMap: Map<string, string> = new Map();

  // Tab index
  tabIndex = 0;

  dayOrWhen: any;

  // Subscription
  subscription = new Subscription();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private checklistService: ChecklistService,
    private assetService: AssetService,
    private subjectService: SubjectService,
    private dateService: DateService,
    private translateService: TranslateService,
  ) {
    // Get Input data.
    this.checklistId = this.config.data.checklistId;
    this.checklist = this.config.data.checklist;
    this.cronJob = this.config.data.cronJob;
    this.cronJobId = this.config.data.cronJobId;

    // Set the checklist part of the form.
    this.cronJobForm.get('name').setValue(this.checklist.name);
    this.cronJobForm.get('assetId').setValue(this.checklist.assetId);
    this.cronJobForm.get('stationId').setValue(this.checklist.stationId);


    // Set the current mode.
    if (this.cronJob.weeklyMode) {
      this.setWeekly(this.cronJob);
    } else if (this.cronJob.monthlyMode) {
      this.setMonthly(this.cronJob);
    }

    // Enable / Disable the form depending on weekly or monthly
    this.weeklyOrMonthly();


    // Get all assets
    this.assetService.getAssets().subscribe(
      assets => {
        this.assetStationMap = new Map(assets.map(asset => [asset.key, asset.payload.val().stationId]));
        this.assetOptions = assets.map(asset => {
          return {key: asset.key, name: asset.payload.val().name};
        });
      }
    );

    // StationMap
    this.subjectService.getStations().subscribe(stations => {
      this.stationNameMap = new Map(stations.map(station => [station.key, station.payload.val().name]));
    });
  }

  ngOnInit(): void {
    // Get days option
    this.daysOption = this.dateService.getDaysOptions();

    // Get month option
    this.monthOption = this.dateService.getMonthOptions();

    // create when option
    this.whenOption = [
      {name: this.translateService.instant('COMMON.FIRST'), value: 0},
      {name: this.translateService.instant('COMMON.LAST'), value: 1}
    ];
  }

  /**
   * Set Station on Asset change.
   * @param assetId
   */
  setStation(assetId) {
    this.cronJobForm.get('stationId').setValue(this.assetStationMap.get(assetId.value));
  }

  /**
   * Method to calculate days in the month for the selected date
   * @param month: 0 - 11;
   */
  getDays(month) {
    this.days = this.dateService.getDays(month);
    if (this.cronJobForm.get('monthly').value.date > this.days.length) {
      this.cronJobForm.get('monthly').get('date').setValue(null);
    }
  }

  /**
   * Enable/Disable weekly or monthly.
   */
  weeklyOrMonthly() {
    if (this.tabIndex === 0) {
      this.cronJob.weeklyMode = true;
      this.cronJob.monthlyMode = false;
      this.cronJobForm.get('weekly').enable();
      this.cronJobForm.get('monthly').disable();
    } else if (this.tabIndex === 1) {
      this.cronJob.weeklyMode = false;
      this.cronJob.monthlyMode = true;
      this.cronJobForm.get('weekly').disable();
      this.cronJobForm.get('monthly').enable();
      this.swapDisable();
    }
  }

  /**
   * CronJob when/date disable/enable control
   */
  swapDisable() {
    const group: any = this.cronJobForm.controls.monthly;

    switch (this.dayOrWhen) {
      case 0:
        group.controls.date.enable();
        group.controls.when.disable();
        group.controls.day.disable();
        break;
      case 1:
        group.controls.date.disable();
        group.controls.when.enable();
        group.controls.day.enable();
        break;
    }
  }

  save() {

    const value = this.cronJobForm.value;

    // Update checklist
    this.checklist.name = value.name;
    this.checklist.assetId = value.assetId;
    this.checklist.stationId = value.stationId;

    if (this.cronJob.weeklyMode) {

      // "Delete" Monthly
      this.cronJob.monthly = null;

      // Set Weekly
      this.cronJob.weekly = {
        day: this.dateService.convertDayNumberToDayObject(value.weekly.day),
        delay: value.weekly.delay,
        interval: value.weekly.interval,
      };

    } else if (this.cronJob.monthlyMode) {

      // "Delete" Weekly
      this.cronJob.weekly = null;

      // Set Shared Monthly
      this.cronJob.monthly =
        {
          interval: value.monthly.interval,
          startMonth: value.monthly.startMonth
        };

      // Check if when or date
      if (value.monthly.date) {
        delete (this.cronJob.monthly.day);
        delete (this.cronJob.monthly.first);
        delete (this.cronJob.monthly.last);
        this.cronJob.monthly.date = value.monthly.date;

      } else {
        delete (this.cronJob.monthly.date);
        this.cronJob.monthly = this.dateService.setWhen(this.cronJob.monthly, value.monthly.when);
        this.cronJob.monthly.day = this.dateService.convertDayNumberToEnglish(value.monthly.day);
      }
    }
    this.checklistService.updateChecklist(this.checklistId, this.checklist);
    this.checklistService.updateCronJob(this.cronJobId, this.cronJob);
    this.ref.close({key: this.checklistId, ...this.checklist});
  }

  // Unsubscribe on Destroy
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Set Weekly in form
   * @private
   */
  private setWeekly(cronJob) {
    this.tabIndex = 0;
    this.cronJobForm.get('weekly').get('interval').setValue(cronJob.weekly.interval);
    this.cronJobForm.get('weekly').get('delay').setValue((cronJob.weekly.delay) ? cronJob.weekly.delay : 0);
    this.cronJobForm.get('weekly').get('day').setValue(this.dateService.convertDayOptionToNumber(cronJob.weekly.day));

    // Get number of days in this month;
    this.getDays(this.cronJobForm.get('monthly').value.startMonth);
  }

  /**
   * Set Monthly in form
   * @private
   */
  private setMonthly(cronJob) {
    this.tabIndex = 1;

    this.cronJobForm.get('monthly').get('interval').setValue(cronJob.monthly.interval);
    this.cronJobForm.get('monthly').get('startMonth').setValue(cronJob.monthly.startMonth);

    // Get number of days in this month;
    this.getDays(cronJob.monthly.startMonth);


    if (cronJob.monthly.first || cronJob.monthly.last) {
      this.dayOrWhen = 1;
      if (cronJob.monthly.first) {
        this.cronJobForm.get('monthly').get('when').setValue(0);
      } else {
        this.cronJobForm.get('monthly').get('when').setValue(1);
      }
      this.cronJobForm.get('monthly').get('day').setValue(this.dateService.convertEnglishToDayNumber(this.cronJob.monthly.day));
    } else {
      this.dayOrWhen = 0;
      // If therefor some reason is a selected date higher than what the month contains, set it to the last date of the month.
      if (cronJob.monthly.date > this.days.length) {
        this.cronJobForm.get('monthly').get('date').setValue(this.days.length);
      } else {
        this.cronJobForm.get('monthly').get('date').setValue(cronJob.monthly.date);
      }
    }
  }
}
