import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-print-hours',
  templateUrl: './print-hours.component.html',
  styleUrls: ['./print-hours.component.scss'],
  providers: [TranslateService]
})
export class PrintHoursComponent implements OnInit, OnDestroy {

  personHourDataMap;
  personNameMap;

  exerciseRows = [];
  exerciseCols: any[];
  exerciseExportColumns: any[];

  appointmentRows = [];
  appointmentCols: any[];
  appointmentExportColumns: any[];

  eventRows = [];
  eventCols: any[];
  eventExportColumns: any[];

  eventRowsOld = [];
  eventColsOld: any[];
  eventExportColumnsOld: any[];

  constructor(
    private config: DynamicDialogConfig,
    private tService: TranslateService,
    private ref: DynamicDialogRef,
  ) {

    function hmsToSeconds(s) {
      const b = s.split(':');
      return b[0]*3600 + b[1]*60;
    }

    function secondsToHMS(secs) {
      function z(n){return (n<10?'0':'') + n;}
      const sign = secs < 0? '-':'';
      secs = Math.abs(secs);
      return sign + z(secs/3600 ) + ':' + z((secs%3600) / 60);
    }
    this.personHourDataMap = this.config.data.personHourDataMap;
    this.personNameMap = this.config.data.personNameMap;
    for (const key of this.personHourDataMap.keys()) {
      if (this.personHourDataMap.get(key).has("exercise")) {
        this.personHourDataMap.get(key).get("exercise").forEach(exercise => {
          const total = secondsToHMS(hmsToSeconds(exercise.to) - hmsToSeconds(exercise.from))
            .replace("-", "");
          this.exerciseRows.push(
            {
              [this.tService.instant("COMMON.NAME")]: this.personNameMap.get(key),
              [this.tService.instant("EXERCISE.EXERCISE")]: exercise.name,
              [this.tService.instant("COMMON.DATE.DATE")]: exercise.date,
              [this.tService.instant("COMMON.FROM")]: exercise.from,
              [this.tService.instant("COMMON.TO")]: exercise.to,
              [this.tService.instant("EVENT.NUMBER_OF_HOURS")]: total,
              [this.tService.instant("EXERCISE.ABSENCE")]: this.getAbsence(exercise.absence),
            }
          );
        });
      }
      if (this.personHourDataMap.get(key).has("appointment")) {
        this.personHourDataMap.get(key).get("appointment").forEach(appointment => {
          const total = secondsToHMS(hmsToSeconds(appointment.to) - hmsToSeconds(appointment.from))
            .replace("-", "");
          this.appointmentRows.push(
            {
              [this.tService.instant("COMMON.NAME")]: this.personNameMap.get(key),
              [this.tService.instant("EVENT.MISC")]: appointment.name,
              [this.tService.instant("COMMON.DATE.DATE")]: appointment.date,
              [this.tService.instant("COMMON.FROM")]: appointment.from,
              [this.tService.instant("COMMON.TO")]: appointment.to,
              [this.tService.instant("EVENT.NUMBER_OF_HOURS")]: total,
            }
          );
        });
      }
      if (this.personHourDataMap.get(key).has("event")) {
        this.personHourDataMap.get(key).get("event").forEach(event => {
          if (event.new) {
            this.eventRows.push(
              {
                [this.tService.instant("COMMON.NAME")]: this.personNameMap.get(key),
                [this.tService.instant("COMMON.EVENT_ID")]: event.id,
                [this.tService.instant("COMMON.DATE.DATE")]: event.date,
                [this.tService.instant("COMMON.FROM")]: event.from,
                [this.tService.instant("COMMON.TO")]: event.to,
                [this.tService.instant("EVENT.NUMBER_OF_HOURS")]: event.total,
              }
            );
          } else {
            this.eventRowsOld.push(
              {
                [this.tService.instant("COMMON.NAME")]: this.personNameMap.get(key),
                [this.tService.instant("COMMON.EVENT_ID")]: event.id,
                [this.tService.instant("COMMON.DATE.DATE")]: event.date,
                [this.tService.instant("EVENT.TIME")]: event.time,
                [this.tService.instant("EVENT.ERE.AFTERWORK")]: event.suppTime
              }
            );
          }
        });
      }
    }
    this.exerciseCols = [
      {field: this.tService.instant("COMMON.NAME"), header: this.tService.instant("COMMON.NAME")},
      {field: this.tService.instant("EXERCISE.EXERCISE"), header: this.tService.instant("EXERCISE.EXERCISE")},
      {field: this.tService.instant("COMMON.DATE.DATE"), header: this.tService.instant("COMMON.DATE.DATE")},
      {field: this.tService.instant("COMMON.FROM"), header: this.tService.instant("COMMON.FROM")},
      {field: this.tService.instant("COMMON.TO"), header: this.tService.instant("COMMON.TO")},
      {field: this.tService.instant("EVENT.NUMBER_OF_HOURS"), header: this.tService.instant("EVENT.NUMBER_OF_HOURS")},
      {field: this.tService.instant("EXERCISE.ABSENCE"), header: this.tService.instant("EXERCISE.ABSENCE")}
    ];
    this.eventExportColumns = this.exerciseCols.map(col => ({title: col.header, dataKey: col.field}));

    this.appointmentCols = [
      {field: this.tService.instant("COMMON.NAME"), header: this.tService.instant("COMMON.NAME")},
      {field: this.tService.instant("EVENT.MISC"), header: this.tService.instant("EVENT.MISC")},
      {field: this.tService.instant("COMMON.DATE.DATE"), header: this.tService.instant("COMMON.DATE.DATE")},
      {field: this.tService.instant("COMMON.FROM"), header: this.tService.instant("COMMON.FROM")},
      {field: this.tService.instant("COMMON.TO"), header: this.tService.instant("COMMON.TO")},
      {field: this.tService.instant("EVENT.NUMBER_OF_HOURS"), header: this.tService.instant("EVENT.NUMBER_OF_HOURS")}
    ];
    this.appointmentExportColumns = this.appointmentCols.map(col => ({title: col.header, dataKey: col.field}));

    this.eventCols = [
      {field: this.tService.instant("COMMON.NAME"), header: this.tService.instant("COMMON.NAME")},
      {field: this.tService.instant("COMMON.EVENT_ID"), header: this.tService.instant("COMMON.EVENT_ID")},
      {field: this.tService.instant("COMMON.DATE.DATE"), header: this.tService.instant("COMMON.DATE.DATE")},
      {field: this.tService.instant("COMMON.FROM"), header: this.tService.instant("COMMON.FROM")},
      {field: this.tService.instant("COMMON.TO"), header: this.tService.instant("COMMON.TO")},
      {field: this.tService.instant("EVENT.NUMBER_OF_HOURS"), header: this.tService.instant("EVENT.NUMBER_OF_HOURS")}
    ];
    this.eventExportColumns = this.eventCols.map(col => ({title: col.header, dataKey: col.field}));

    this.eventColsOld = [
      {field: this.tService.instant("COMMON.NAME"), header: this.tService.instant("COMMON.NAME")},
      {field: this.tService.instant("COMMON.EVENT_ID"), header: this.tService.instant("COMMON.EVENT_ID")},
      {field: this.tService.instant("COMMON.DATE.DATE"), header: this.tService.instant("COMMON.DATE.DATE")},
      {field: this.tService.instant("EVENT.TIME"), header: this.tService.instant("EVENT.TIME")},
      {field: this.tService.instant("EVENT.ERE.AFTERWORK"), header: this.tService.instant("EVENT.ERE.AFTERWORK")}
    ];
    this.eventExportColumnsOld = this.eventColsOld.map(col => ({title: col.header, dataKey: col.field}));

  }

  ngOnInit(): void {
  }

  /**
   * Export table as .xlsx
   * @private
   */
  exportExcel(data) {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array"
      });
      this.saveAsExcelFile(excelBuffer, "products");
    });
  }

  ngOnDestroy(): void {
    this.ref.close();
  }

  /**
   * Save .xlsx file
   * @param buffer : any
   * @param fileName : String
   * @private
   */
  private saveAsExcelFile(buffer: any, fileName: string): void {
      const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
  }

  /**
   * Get absence from boolean value
   * @param absence boolean
   * @private
   */
  private getAbsence(absence){
    if (absence === true){
      return this.tService.instant("CONFIRMATION.YES");
    } else if (absence === false){
      return this.tService.instant("CONFIRMATION.NO");
    } else {
      return absence;
    }
  }

}
