import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideArchived'
})

/**
 * This filter handles a list created by | keyvalue
 */
export class HideArchivedPipe implements PipeTransform {

  transform(array: any){
    if(!array){
      return array;
    } else { //
      return array.filter(item => !item.value.archive)
    }
  }

}
