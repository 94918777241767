import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeExistingFilter'
})

export class RemoveExistingFilterPipe implements PipeTransform {
  /**
   *
   * @param list of items. Must contain key value.
   * @param existingMap map containing {key, *}
   *
   * @return list of items not in existingMap
   */
  transform(list, existingMap) {
    if(list){
      if(existingMap){
        return list.filter(item => !existingMap.has(item.key));
      } else {
        return list;
      }
    }

  }

}
