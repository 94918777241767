import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {mergeMap, publish} from "rxjs/operators";
import {FunctionModel} from "../../model/function";

@Injectable({
  providedIn: 'root'
})
export class FunctionService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getFunctions(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.db.list(`tenants/${tenantId}/functions`).snapshotChanges()
      )
    );
  }

  addFunction(functionObj) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/functions`).push(functionObj)
      )
    )).connect();
  }

  getFunction(key): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.db.object(`tenants/${tenantId}/functions/${key}`).valueChanges()
      )
    );
  }

  updateFunction(functionKey, functionData: FunctionModel) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/functions`).update(functionKey, functionData)
      )
    )).connect();
  }

  getPersonCompetencyOnUpdate(fId, pId): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.db.list(`tenants/${tenantId}/functions/${fId}/subjects/${pId}/competencyTypes`).stateChanges(['child_changed'])
      )
    );
  }

  getFunctionCompetencyTypes(key) {
    return this.tenantId$.pipe(
      mergeMap(tenantId => this.db.object(`tenants/${tenantId}/functions/${key}/competencyTypes`).valueChanges()
      )
    );
  }

  getFunctionCompetencyType(fKey: string, ctKey: string) {
     return this.tenantId$.pipe(
       mergeMap(tenantId => this.db.object(`tenants/${tenantId}/functions/${fKey}/competencyTypes/${ctKey}`).valueChanges()
       )
     );
  }

  updateFunctionCompetencyType(fKey: string, ctKey: string, ct: any) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/functions/${fKey}/competencyTypes`).update(ctKey, ct)
      )
    )).connect();
  }

  deleteFunction(functionKey) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/functions`).remove(functionKey)
      )
    )).connect();
  }
}
