import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {SubjectService} from "../../../services/service/subject.service";
import {PersonService} from "../../../services/service/person.service";
import {ExerciseService} from "../../../services/service/exercise.service";
import {DialogService} from "primeng/dynamicdialog";
import {EditExerciseComponent} from "../edit-exercise/edit-exercise.component";
import {TranslateService} from "@ngx-translate/core";
import {ExerciseDetailComponent} from "../exercise-detail/exercise-detail.component";
import {AccessControlService} from "../../../services/service/access-control.service";
import {FileData} from '../../../model/fileRelated';
import {FileService} from '../../../services/service/file.service';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';

@Component({
  selector: 'app-exercises',
  templateUrl: './exercises.component.html',
  styleUrls: ['./exercises.component.scss'],
  providers: [DialogService]
})
export class ExercisesComponent implements OnInit, OnDestroy {
  @Input() fromDashboard;
  exercises: any[];
  selectedExerciseFilter: any;
  exerciseLoading = true;
  exerciseFilter = [];
  isUser = true;

  subscription = new Subscription();
  innerWidth = window.innerWidth;

  exerciseParticipantMap = new Map<string, any[]>;
  subjectNameMap: Map<string, string>;

  ctNameMap: Map<string, string>;
  fileNameMap: Map<string, string>;
  files: Map<string, FileData>;

  templateCTMap = new Map<string, string[]>();

  rows = 13;
  constructor(
    private subjectService: SubjectService,
    private personService: PersonService,
    private exerciseService: ExerciseService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private fileService: FileService,
    private ctService: CompetencyTypeService,
  ) {
    if (this.fromDashboard){
      this.rows = 4;
    }
    this.subscription.add(
      this.acService.isUser().subscribe(isUser => this.isUser = isUser)
    );
  }

  ngOnInit(): void {


    // Get all filters for dashboard, and set selectedTaskFilter and selectedExerciseFilter to default subjectId.
    combineLatest([this.personService.getOwnSubjectId(), this.personService.getPersons()]).pipe(
      take(1)
    ).subscribe(([subjectId, persons]) => {
      this.exerciseFilter.push({key: '', name: ('CHECKLIST.SHOW_ALL')});
      if(!subjectId){
        this.selectedExerciseFilter = {key: '', name: ('CHECKLIST.SHOW_ALL')};
      } else {
        if(this.fromDashboard){
          this.selectedExerciseFilter = {key: subjectId, name: ('EXERCISE.MY_EXERCISE')};
        } else {
          this.selectedExerciseFilter = {key: '', name: ('CHECKLIST.SHOW_ALL')};
        }
        this.exerciseFilter.push({key: subjectId, name: ('EXERCISE.MY_EXERCISE')});
      }
      this.subjectNameMap = new Map(persons.map( person => [person.key, person.payload.val().name]));
    });

    // Get active exercises
    this.subscription.add(
      this.exerciseService.getActiveExercises().subscribe(exercisePayload => {
        this.exercises = [];
        exercisePayload.map(exercise => {
          if (exercise.payload.val().template === false
              || exercise.payload.val().template === undefined){
            this.exercises.push({key: exercise.key, ...exercise.payload.val()});

            const tempExercise = {key: exercise.key, ...exercise.payload.val()};
            if (!tempExercise.competencyTypes){
              tempExercise.competencyTypes = [];
            }
            if (!tempExercise.exerciseFiles){
              tempExercise.exerciseFiles = [];
            }
            tempExercise.key = exercise.key;

            this.templateCTMap.set(exercise.key, []);
            if (Object.keys(tempExercise.competencyTypes).length > 0){
              Object.keys(tempExercise.competencyTypes).forEach(key => this.templateCTMap.get(exercise.key).push(key));
            }

            if (exercise.payload.val().participants){
              const participants = [];
              Object.entries(exercise.payload.val().participants).forEach(subject => {
                participants.push(subject[1]['key']);
              });
              this.exerciseParticipantMap.set(exercise.key, participants);
            } else {
              this.exerciseParticipantMap.set(exercise.key, []);
            }
          }
        });
        this.exercises.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        this.exerciseLoading = false;
      })
    );
    this.subscription.add(this.fileService.getAllFiles().subscribe(files => {
      this.fileNameMap = new Map(files.map(file => [file.key, file.payload.val().name]));
      this.files = new Map(files.map(file => [file.key, file.payload.val()]));
    }));
    this.subscription.add(
        this.ctService.getCompetencyTypes().subscribe(cts => {
          this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        })
    );
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Add exercise
   */
  addExercise() {
    this.dialogService.open(EditExerciseComponent, {
      header: this.translateService.instant('EXERCISE.NEW'),
      styleClass: 'max-size-width-dialog',
      data: {
        key: null,
        exercise: null,
      }
    });
  }

  /**
   * Exercise details
   * @param exercise Exercise object
   * @param newHeader Exercise header
   */
  exerciseDetails(exercise, newHeader?) {
    const modalRef = this.dialogService.open(ExerciseDetailComponent, {
      header: (newHeader) ? newHeader : exercise.name,
      styleClass: 'max-size-dialog',
      data: {
        key: exercise.key
      },
      closeOnEscape: false,
    });
    this.subscription.add(
      modalRef.onClose.subscribe(header => {
        if(header){
          this.exerciseDetails(exercise, header);
        }
      })
    );
  }

  participantLength(participants: any[]) {
    if (participants) {
      return Object.keys(participants).length;
    } else {
      return 0;
    }
  }

  cTLength(competencyTypes: any[]) {
    if (competencyTypes){
      return Object.keys(competencyTypes).length;
    } else {
      return 0;
    }
  }

  filesLength(files: any[]) {
    if (files){
      return files.length;
    } else {
      return 0;
    }
  }

  open(fileID: string) {
    window.open(this.files.get(fileID).url);
  }
}
