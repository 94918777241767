<div class="grid">
    <div class="col-6">
        <p-dropdown (onChange)="getTeams(selectedStation)" [(ngModel)]="selectedStation"
                    [options]="stations" [style]="{'width': '100%'}" optionLabel="name"
                    optionValue="key" placeholder="{{'COMMON.CHOOSE_STATION' | translate}}"></p-dropdown>
    </div>
    <div class="col-6">
        <p-dropdown [(ngModel)]="selectedTeam" [options]="teams" [style]="{'width': '100%'}"
                    optionLabel="name" optionValue="key" placeholder="{{'COMMON.CHOOSE_TEAM' | translate}}"></p-dropdown>
    </div>
    <div class="col-12 flex justify-content-end">
        <span class="p-buttonset">
            <button pButton type="button" label="{{'BUTTON.CANCEL' | translate}}" (click)="close()"
                    class="p-button-outlined p-button-raised"></button>
            <button pButton type="button" class="p-button-raised" label="{{'BUTTON.SAVE' | translate}}"
                    (click)="complete()" [disabled]="validator()"></button>
        </span>
    </div>
</div>
