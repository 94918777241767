import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {Appointment} from '../../../model/appointment';
import {Task} from '../../../model/task';
import {Exercise} from '../../../model/exercise';

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.scss']
})
export class ViewEventComponent implements OnInit {
  calendarType: string;
  eventId: string;
  event;

  constructor(
      private config: DynamicDialogConfig,

  ) {}

  ngOnInit(): void {
    if (this.config.data.type === 'EXERCISE'){
      this.event = <Exercise> this.config.data.event;
    }
    if (this.config.data.type === 'TASK'){
      this.event = <Task> this.config.data.event;
    }
    if (this.config.data.type === 'EVENT'){
      if (typeof this.config.data.event.location === "object"){
        this.config.data.event.location = this.config.data.event.location.location;
      }
      this.event = <Appointment> this.config.data.event;
    }
    this.calendarType = this.config.data.type;
    this.eventId = this.config.data.eventId;
    this.event = this.config.data.event;
  }
}
