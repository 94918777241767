<div class="grid">
  <div class="col-12">
    <!-- Person table -->
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="personArray">

      <!-- Filter bar -->
      <ng-template pTemplate="caption" class="pt-5">
        <div class="grid">
          <div class="col-6">
            <p-autoComplete (completeMethod)="filterStations($event)"
                            (onSelect)="addStation(selectedStation)"
                            [(ngModel)]="selectedStation"
                            [dropdown]="true"
                            [forceSelection]="true"
                            [suggestions]="outputStations | stationFilter: stationMap"
                            appendTo="body"
                            class="inputfield w-full"
                            field="label"
                            placeholder="{{'REPORT.ADD_STATION' | translate}}"
                            scrollHeight="400px"
                            size="100%"
                            type="search"
            ></p-autoComplete >
          </div>
          <div class="col-6">
            <p-autoComplete (completeMethod)="filterPerson($event)"
                            (onSelect)="addPerson(selectedPerson)"
                            [(ngModel)]="selectedPerson"
                            [dropdown]="true"
                            [forceSelection]="true"
                            [group]="true"
                            [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                            appendTo="body"
                            class="inputfield w-full"
                            field="label"
                            placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}"
                            scrollHeight="400px"
                            size="100%"
                            type="search"
            >

              <ng-template let-team pTemplate="group">
                <div class="flex align-items-center p-2" (click)="addTeam(team)">
                  <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                </div>
              </ng-template>
              <ng-template let-subject pTemplate="item">
                <div class="person-item">
                  {{subject.label}}
                </div>
              </ng-template>
            </p-autoComplete >
          </div>
          <div class="col-6">
            <p-multiSelect
              [(ngModel)]="selectedDataModules"
              [options]="dataModules"
              defaultLabel="{{'REPORT.CHOOSE_DATA' | translate}}"
              optionLabel="name"
              optionValue="code"
              class="inputfield w-full"
              [style]="{'width': '100%'}"
            >
            </p-multiSelect>
          </div>
          <div class="col-3">
            <p-calendar placeholder="{{'COMMON.FROM' | translate}}*"
                        dateFormat="dd.mm.yy"
                        appendTo="body"
                        [style]="{'width' : '100%'}"
                        class="w-full inputfield"
                        [(ngModel)]="dateFrom"
            ></p-calendar>
          </div>
          <div class="col-3">
            <p-calendar placeholder="{{'COMMON.TO' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%'}"
                        class="w-full inputfield"
                        appendTo="body"
                        [(ngModel)]="dateTo"
            ></p-calendar>
          </div>
        </div>
        <div class="col-12 flex justify-content-evenly">

            <button label="{{'REPORT.REMOVE_ALL' | translate}}"
                pButton
                icon="pi pi-times"
                iconPos="left"
                class="p-button-raised p-button-text"
                (click)="reset()"
            ></button>
            <button label="{{'REPORT.GENERATE_REPORT' | translate}}"
                icon="far fa-file-chart-line"
                iconPos="right"
                pButton
                class="p-button-raised"
                [disabled]="valid()"
                (click)="createReport()"
            ></button>
        </div>
      </ng-template>

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'FDC.PERSONS' | translate}}</th>
          <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body" let-personId>
        <tr>
          <td>
            {{personNameMap.get(personId)}}
          </td>
          <td (click)="deletePerson(personId)">
                   <span class="flex justify-content-center">
                      <i class="pi pi-trash" style="color: #D0021B"></i>
                  </span>
          </td>
        </tr>
      </ng-template>

      <!-- Shows message if table is empty -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">
            {{'WARNING.NO_DATA' | translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
