import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Asset} from '../../../model/asset';
import {AssetService} from '../../../services/service/asset.service';
import {SubjectService} from '../../../services/service/subject.service';
import {TaskService} from '../../../services/service/task.service';
import {DeviationService} from '../../../services/service/deviation.service';
import {Task} from '../../../model/task';
import {Deviation} from '../../../model/deviation';
import {DialogService} from 'primeng/dynamicdialog';
import {ViewDeviationComponent} from '../view-deviation/view-deviation.component';
import * as moment from 'moment';
import {ViewTaskComponent} from '../../task/view-task/view-task.component';
import {AddComponent} from '../add/add.component';
import {TypesComponent} from '../types/types.component';
import {AccessControlService} from '../../../services/service/access-control.service';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {AssetFilterPipe} from '../../../pipes/asset-filter.pipe';
import {BaseSubject} from '../../../model/subject';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [DialogService, AssetFilterPipe, ConfirmationService]
})
export class MainComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  menuItems: MenuItem[];

  assets: Asset[];
  allAssets: Asset[];

  assetMap;
  stationNameMap;
  assetTypeMap;
  deviations;
  tasks;

  noDeviations;
  noTasks;

  isAdmin$: boolean;
  isSuper$: boolean;

  search: string;
  stations: BaseSubject[];
  selectedStation: string;
  assetStationMap: Map<string, Asset[]>;

  constructor(
      private translateService: TranslateService,
      private assetService: AssetService,
      private subjectService: SubjectService,
      private taskService: TaskService,
      private deviationService: DeviationService,
      private dialogService: DialogService,
      private acService: AccessControlService,
      private gSService: GroupedSubjectsService,
      private aFilter: AssetFilterPipe,
  ) {
    this.builder();
  }

  builder(){
    this.subscription.add(combineLatest([this.assetService.getAssets(), this.assetService.getAssetTypes(),
      this.subjectService.getStations(), this.deviationService.getDeviations(), this.taskService.getActiveTasks(),
        this.acService.isAdmin(), this.acService.isSuperUser()])
        .subscribe(([assets, assetTypes, stations, deviations, tasks, isAdmin, isSuper]) => {


          this.isAdmin$ = isAdmin;
          this.isSuper$ = isSuper;
          this.assetMap = new Map<string, Asset>();
          this.stationNameMap = new Map<string, string>();
          this.assetTypeMap = new Map<string, string>();
          this.deviations = new Map<string, Deviation[]>();
          this.tasks = new Map<string, Task[]>();

          this.noTasks = []; this.noDeviations = [];
          this.noDeviations.push(this.translateService.instant('ASSET.ASSET_CARD.NO_DEVIATION'));
          this.noTasks.push(this.translateService.instant('ASSET.ASSET_CARD.NO_TASK'));

          this.stations = stations.map(station => {
            return {value: station.key, label: station.payload.val().name};
          });
          this.stationNameMap = new Map<string, string>(stations.map(station => [station.key, station.payload.val().name]));
          this.assetTypeMap = new Map<string, string>(assetTypes.map(assetType => [assetType.key, assetType.payload.val().name]));

          this.assets = [];
          this.allAssets = [];
          this.assetMap = new Map(assets.map(asset => [asset.key, asset.payload.val()]));
          this.assetStationMap = new Map<string, Asset[]>();
          for (const [key, asset] of this.assetMap.entries()){
            if (!asset.archived) {
              asset.key = key;
              this.assets.push(asset);
              this.allAssets.push(asset);
              if (this.assetStationMap.has(asset.stationId)) {
                this.assetStationMap.get(asset.stationId).push(asset);
              } else {
                this.assetStationMap.set(asset.stationId, [asset]);
              }
            }
          }

          deviations.forEach(deviation => {
            if (!deviation.payload.val().closed){
              if (deviation.payload.val().asset){
                const dev = deviation.payload.val();
                dev.key = deviation.key;
                if (this.deviations.has(deviation.payload.val().asset)){
                  this.deviations.get(deviation.payload.val().asset).push(dev);
                } else {
                  this.deviations.set(deviation.payload.val().asset, [dev]);
                }
              }
            }
          });

          tasks.forEach(task => {
            if (!task.payload.val().completed){
              if(task.payload.val().assetId){
                const t = task.payload.val();
                t.key = task.key;
                if (this.tasks.has(task.payload.val().assetId)){
                  this.tasks.get(task.payload.val().assetId).push(t);
                } else {
                  this.tasks.set(task.payload.val().assetId, [t]);
                }
              }
            }
          });
        })
    );
  }

  ngOnInit(): void {
    this.menuItems = [
      {   label: this.translateService.instant('ASSET.ASSET_MASTER.NEW_ASSET'),
        icon: 'pi pi-plus',
        command: () => {
          this.addAsset();
        }
      },
      {   label: this.translateService.instant('ASSET.EDIT_ASSET_TYPES.TITLE'),
        icon: 'far fa-truck',
        command: () => {
          this.editTypes();
        }
      },
    ];
  }

  /**
   * Filter assets on name search.
   * @param eventValue Search string.
   */
  assetFilter(eventValue: string) {
    this.assets = this.aFilter.transform(this.allAssets, eventValue);
  }

  /**
   * Set station filter
   */
  setStationFilter() {
    this.assets = this.assetStationMap.get(this.selectedStation);
  }

  /**
   * Check screen size.
   */
  screenWidth() {
    return window.innerWidth < 441;
  }

  /**
   * Edit asset types.
   */
  editTypes() {
    this.dialogService.open(TypesComponent, {
      header: this.translateService.instant('ASSET.ASSET_MASTER.EDIT_ASSET'),
      width: "300px"
    });
  }

  /**
   * Add new asset.
   */
  addAsset() {
    this.dialogService.open(AddComponent, {
      header: this.translateService.instant('ASSET.ASSET_MASTER.NEW_ASSET'),
      styleClass: 'max-size-width-dialog',
      data: {
        origin: "add"
      }
    });
  }

  /**
   * Get value from search event
   * @param $event Search event
   */
  getEventValue($event: Event): string {
    this.search = ($event.target as HTMLInputElement).value;
    return ($event.target as HTMLInputElement).value;
  }


  /**
   * Set asset color status.
   * @param asset Asset object.
   */
  setStatus(asset: Asset) {
    const success = '#73BF21';
    const warning = '#F5A623';
    const danger = '#D0021B';
    const white = '#FFFFFF';
    if (!asset.inCommission) {
      return {'background': danger, 'color': white};
    } else if (this.deviations.has(asset.key)) {
      return {'background': warning, 'color': white};
    } else {
      return {'background': success, 'color': white};
    }
  }

  /**
   * Get button activate / deactivate icon.
   * @param asset Asset object.
   */
  getIcon(asset: Asset) {
    if (asset.inCommission === false){
      return 'pi pi-check-circle';
    } else {
      return 'far fa-times-circle';
    }
  }

  /**
   * Get button text activate / deactivate.
   * @param asset Asset object.
   */
  getLabel(asset: Asset) {
    if (asset.inCommission === false){
      return this.translateService.instant('BUTTON.ACTIVATE');
    } else {
      return this.translateService.instant('BUTTON.DEACTIVATE');
    }
  }

  /**
   * Flip inCommission boolean.
   * @param asset Asset object.
   */
  changeInCommission(asset: Asset) {
    this.assetService.updateInCommission(asset.key, !asset.inCommission);
  }

  /**
   * Edit asset.
   * @param asset Asset object.
   */
  editAsset(asset: Asset) {
    this.dialogService.open(AddComponent, {
      header: this.translateService.instant('ASSET.EDIT'),
      styleClass: 'max-size-width-dialog',
      data: {
        origin: "edit",
        assetObj: asset,
      }
    });
  }

  /**
   * Get task date string.
   * @param task Task object.
   */
  getTaskDate(task: Task) {
    return moment(task.deadline).format('DD.MMM HH:mm');
  }

  /**
   * Open task.
   * @param task Task object.
   */
  openTask(task: Task) {
    this.dialogService.open(ViewTaskComponent, {
      header: task.name,
      styleClass: 'max-size-dialog-short-2',
      data: {
        task: task,
      }
    });
  }

  /**
   * Open deviation.
   * @param deviation Deviation object.
   */
  openDeviation(deviation: Deviation) {
    this.dialogService.open(ViewDeviationComponent, {
      header: deviation.name,
      styleClass: 'max-size-dialog-short-2',
      data: {
        deviation: deviation,
      }
    }).onClose.subscribe(result => {
        if (result) {
          this.builder();
        }
    });
  }
  /**
   * Unsubscribe all.
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
