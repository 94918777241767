import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Theme} from "../../../model/theme";
import {FilterService} from "primeng/api";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {ExerciseService} from "../../../services/service/exercise.service";

@Component({
  selector: 'app-add-theme',
  templateUrl: './add-theme.component.html',
  styleUrls: ['./add-theme.component.scss']
})
export class AddThemeComponent implements OnInit {

  theme: Theme = {
    competencyTypes: [], createdBy: "", createdDate: "", name: ""
  };
  selectedCT: any;
  output: any;
  ctMap = new Map();
  ctList = [];

  competencyTypes;
  subscription = new Subscription();
  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private filterService: FilterService,
    private ctService: CompetencyTypeService,
    private authService: AuthService,
    private exerciseService: ExerciseService
  ) {
    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.competencyTypes = cts.map(ct => {
          return {key: ct.key, ...ct.payload.val()};
        }).sort((a, b) => a.name > b.name ? 1 : -1);
      })
    );
  }

  ngOnInit(): void {
  }

  save() {
    this.authService.getUserUID().pipe(
      take(1),
    ).subscribe(uid => {
      this.theme.competencyTypes = Object.keys(Object.fromEntries(this.ctMap));
      this.theme.createdBy = uid;
      this.theme.createdDate = new Date().toISOString();

      this.exerciseService.addTheme(this.theme);
      this.ref.close();
    });
  }

  search(event: any) {
    this.output = this.filterService.filter(this.competencyTypes, ['name'], event.query, "contains");
  }

  addCompetencyType() {
    if(!this.ctMap.has(this.selectedCT.key)){
      this.ctList.push(this.selectedCT);
      this.ctMap.set(this.selectedCT.key, true);
      this.ctList.sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
  }

  delete(ct: any) {
    const index = this.ctList.findIndex(Fct => Fct.key === ct.key);
    if(index >= 0){
      this.ctList.splice(index, 1);
      this.ctMap.delete(ct.key);
    }
  }
}
