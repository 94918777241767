import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {SubjectService} from "../../../services/service/subject.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-move-subject',
  templateUrl: './move-subject.component.html',
  styleUrls: ['./move-subject.component.scss']
})
export class MoveSubjectComponent implements OnInit, OnDestroy {
  subjectId: string;
  selectedStation = null;
  selectedTeam = null;
  stations;
  teams;
  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private subjectService: SubjectService,
  ) {
    this.subjectId = this.config.data.subjectId;

    this.subscription.add(
      this.subjectService.getStations().subscribe(stations => {
        this.stations = stations.map(station => {
          return {key: station.key, ...station.payload.val()};
        });
      })
    );
  }

  ngOnInit(): void {
  }

  /**
   * Get station teams
   * @param station Selected station
   */
  getTeams(station) {
    this.teams = [];
    this.selectedTeam = null;
    this.subjectService.getChildren(station.key).pipe(
      take(1),
    ).subscribe(children => {
      children.forEach(child => {
        if (child.data === 'FIRETEAM') {
          this.teams.push({key: child.key, name: child.label});
        }
      });
    });
  }

  /**
   * Save relocation of subject
   */
  save() {
    this.subjectService.getRelationByChildId(this.subjectId).pipe(
      take(1),
    ).subscribe(relations => {
      const relation = relations[0];
      if (this.selectedTeam) {
        this.subjectService.updateRelation(relation.key, {childId: this.subjectId, parentId: this.selectedTeam.key});
      } else if (this.selectedStation) {
        this.subjectService.updateRelation(relation.key, {childId: this.subjectId, parentId: this.selectedStation.key});
      }
      console.log("move-subject DONE");
      this.ref.close('moved');
    });
  }

  /**
   * Unsubscribe when done
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
