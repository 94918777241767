import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoleComponent } from './role/role.component';
import { AddRoleComponent } from './add-role/add-role.component';
import {Route} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ToastModule} from 'primeng/toast';
import {DropdownModule} from 'primeng/dropdown';
import {PipesModule} from '../../pipes/pipes.module';
import {CascadeSelectModule} from 'primeng/cascadeselect';

export const RoleRoutes: Route = {
  path: 'roles',
  children: [
    {path: 'role', component: RoleComponent}
  ]
}
@NgModule({
  declarations: [
    RoleComponent,
    AddRoleComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        SplitButtonModule,
        TableModule,
        InputTextModule,
        FormsModule,
        AutoCompleteModule,
        ToastModule,
        DropdownModule,
        PipesModule,
        CascadeSelectModule,
    ]
})
export class RolesModule { }
