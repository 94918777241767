import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
/**
 * Sets admin rights.
 */
export class AdminComponent implements OnInit {

  adminFilter = [];
  selectedFilter = {name: 'ADMIN.ADD_RIGHTS', value: 0}

  constructor() { }

  ngOnInit(): void {

    this.adminFilter = [
      {name: 'ADMIN.ADD_RIGHTS', value: 0},
      {name: 'ADMIN.USER_OVERVIEW_COMPONENT.TEMP_USERS', value: 1},
      {name: 'ADMIN.USER_OVERVIEW', value: 2},
    ]
  }

}
