import { Component, OnInit } from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FunctionService} from "../../../services/service/function.service";
import {
  ExerciseAddCompetencyTypesFromThemeComponent
} from "../exercise-add-competencytypes-from-theme/exercise-add-competency-types-from-theme.component";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {ExerciseService} from "../../../services/service/exercise.service";

@Component({
  selector: 'app-exercise-themes-list',
  templateUrl: './exercise-themes-list.component.html',
  styleUrls: ['./exercise-themes-list.component.scss'],
  providers: [DialogService]
})
export class ExerciseThemesListComponent implements OnInit {
  eKey: string;
  themes: any[];

  subscription = new Subscription();
  loading = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private exerciseService: ExerciseService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {
    this.eKey = this.config.data.eKey;

    this.subscription.add(
      this.exerciseService.getThemes().subscribe(themes => {
        this.themes = themes.map(theme => {
          return {key: theme.key, ...theme.payload.val()}
        })
        this.loading = false;
      })
    )
  }

  ngOnInit(): void {
  }

  getLength(theme) {
    return (theme.competencyTypes) ? theme.competencyTypes.length : 0;
  }

  addCompetencyTypesFromTheme(theme) {
    this.dialogService.open(ExerciseAddCompetencyTypesFromThemeComponent, {
      header: this.translateService.instant('COMPETENCY.ADD_COMPETENCY_TYPE'),
      styleClass: 'max-size-dialog',
      data: {
        eKey: this.eKey,
        tKey: theme.key,
      }
    })
  }
}
