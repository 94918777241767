<div class="grid">
    <div class="col-6">
        <h2 style="margin-top: 10px">{{'MAIN_FRAME.MY_PAGE' | translate}}</h2>
    </div>
    <div class="col-6 flex justify-content-end">
        <button class="p-button-raised" type="button" pButton icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
                (click)="menu.toggle($event)"></button>
        <p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
    </div>
    <ng-container *ngIf="!loading">
        <ng-container *ngIf="screenWidth(); else smallBlock">
            <div class="col-6 mt-3">
                <span class="p-float-label">
                    <input type="text" id="name" pInputText [(ngModel)]="subject.name" [style]="{'width' : '100%'}">
                    <label for="name">{{'COMMON.NAME' | translate}}</label>
                </span>
            </div>
            <div class="col-3 mt-3">
                <span class="p-float-label">
                    <input type="text" id="employeeID" pInputText [(ngModel)]="subject.userId" [style]="{'width' : '100%'}">
                    <label for="employeeID">{{'SUBJECTS.E_NUM' | translate}}</label>
                </span>
            </div>
            <div class="col-3 mt-3">
                <span class="p-float-label">
                    <input type="text" id="issi" pInputText [(ngModel)]="subject.issi" [style]="{'width' : '100%'}">
                    <label for="issi">{{'SUBJECTS.ISSI' | translate}}</label>
                </span>
            </div>
        </ng-container>

        <ng-template #smallBlock>
            <div class="col-12 mt-3">
                <span class="p-float-label">
                    <input type="text" id="nameS" pInputText [(ngModel)]="subject.name" [style]="{'width' : '100%'}">
                    <label for="nameS">{{'COMMON.NAME' | translate}}</label>
                </span>
            </div>
            <div class="col-6 mt-2">
                <span class="p-float-label">
                    <input type="text" id="employeeIDS" pInputText
                           [(ngModel)]="subject.userId" [style]="{'width' : '100%'}">
                    <label for="employeeIDS">{{'SUBJECTS.E_NUM' | translate}}</label>
                </span>
            </div>
            <div class="col-6 mt-2">
                <span class="p-float-label">
                    <input type="text" id="issiS" pInputText [(ngModel)]="subject.issi" [style]="{'width' : '100%'}">
                    <label for="issiS">{{'SUBJECTS.ISSI' | translate}}</label>
                </span>
            </div>
        </ng-template>
        <div class="col-6 mt-2">
            <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%'}"
                        class="w-full inputfield"
                        [(ngModel)]="subject.dateOfBirth"
            ></p-calendar>
        </div>
        <div class="col-6 mt-2">
          <span class="p-float-label">
            <input type="text" id="title" pInputText [ngModel]="subject.title" [style]="{'width' : '100%'}"
                   disabled>
            <label for="title">{{'COMMON.TITLE' | translate}}</label>
          </span>
        </div>
        <div class="col-6 mt-2">
                <span class="p-float-label">
                    <input type="text" id="email1" pInputText disabled [(ngModel)]="subject.email" [style]="{'width' :
                    '100%'}">
                    <label for="email1">{{'PERSONAL.EMAIL' | translate}}</label>
                </span>
        </div>
        <div class="col-6 mt-2">
                <span class="p-float-label">
                    <input type="text" id="email2" pInputText [(ngModel)]="subject.email2" [style]="{'width' : '100%'}">
                    <label for="email2">{{'SUBJECTS.ALT_EMAIL' | translate}}</label>
                </span>
        </div>
        <div class="col-6 mt-2">
                <span class="p-float-label">
                    <input type="text" id="address1" pInputText [(ngModel)]="subject.address" [style]="{'width' : '100%'}">
                <label for="address1">{{'SUBJECTS.ADDRESS' | translate}}</label>
            </span>
        </div>
        <div class="col-6 mt-2">
            <span class="p-float-label">
                <input type="text" id="address2" pInputText [(ngModel)]="subject.address2" [style]="{'width' : '100%'}">
                <label for="address2">{{'SUBJECTS.SEC_ADDRESS' | translate}}</label>
            </span>
        </div>
        <div class="col-6 mt-2">
            <span class="p-float-label">
                <input type="text" id="postal" pInputText [(ngModel)]="subject.postal" [style]="{'width' : '100%'}">
                <label for="postal">{{'SUBJECTS.POSTAL' | translate}}</label>
            </span>
        </div>
        <div class="col-6 mt-2">
            <span class="p-float-label">
                <input type="text" id="zip" pInputText [(ngModel)]="subject.zip" [style]="{'width' : '100%'}">
                <label for="zip">{{'SUBJECTS.ZIP' | translate}}</label>
            </span>
        </div>
        <div class="col-12 mt-2">
            <span class="p-float-label">
                <textarea id="notes" rows="5" cols="30" pInputTextarea
                          [(ngModel)]="subject.notes" [style]="{'width' : '100%'}"></textarea>
                <label for="notes">{{'COMMON.NOTES' | translate}}</label>
            </span>
        </div>
        <div class="col-12 flex justify-content-end">
            <button [disabled]="!subject.name" class="p-button-raised"
                    pButton label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark" (click)="save()"></button>
        </div>
    </ng-container>
</div>
