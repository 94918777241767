import {Component, OnDestroy, OnInit} from '@angular/core';
import {EmergencyService} from '../../../services/service/emergency.service';
import {combineLatest, Subscription} from 'rxjs';
import * as moment from 'moment';
import {DialogService} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {EmergencyAddComponent} from "../emergency-add/emergency-add.component";
import {EmergencyReadComponent} from "../emergency-read/emergency-read.component";
import {AccessControlService} from "../../../services/service/access-control.service";
import {EmergencyEditComponent} from "../emergency-edit/emergency-edit.component";
import {EmergencyResponse} from '../../../model/emergencyResponse';
import { ExposureService } from 'src/app/services/service/exposure.service';
import {Exposure} from '../../../model/exposure';
import {EmergencyEval} from '../../../model/emergencyEval';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {PersonService} from '../../../services/service/person.service';
import {RolesService} from '../../../services/service/roles.service';
import {Roles} from '../../../model/role';
import {EventFilterPipe} from '../../../pipes/event-filter.pipe';
import {SubjectService} from '../../../services/service/subject.service';
import {EmergencyTypesComponent} from '../emergency-types/emergency-types.component';
import {EventStationSortService} from "../../../services/sharedService/event-station-sort.service";

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss'],
  providers: [DialogService, EventFilterPipe],
})
export class EmergencyComponent implements OnInit, OnDestroy{

  // Teams
  groupedFilter = [];
  teamNameMap = new Map();
  stationNameMap = new Map();
  parentChildMap = new Map();
  personParentNameMap = new Map();
  personNameMap: Map<string, string>;

  emergencies: EmergencyResponse[];
  emergenciesMap = new Map<string, EmergencyResponse[]>();
  exposures: Exposure[];
  evaluations: EmergencyEval[];

  emergencyId = new Map();
  emergencyYear = new Map<string, boolean>();

  isAdmin$: boolean;
  isSuper$: boolean;

  subscription = new Subscription();

  counter = 1;

  roles: Roles[];
  selectedStation = 'All';
  search = '';

  items: any[];
  loading = false;

  constructor(
    private emergencyService: EmergencyService,
    private exposureService: ExposureService,
    private evaluationService: EvaluationService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private personService: PersonService,
    private roleService: RolesService,
    private eventFilter: EventFilterPipe,
    private subjectService: SubjectService,
    private esSort: EventStationSortService,
  ) {

    this.acService.getOwnAccessControl().subscribe(rValue => {
      if (rValue.roles.admin) {
        this.isAdmin$ = rValue.roles.admin;
      } else {
        this.isAdmin$ = false;
      }
      if (rValue.roles.superuser) {
        this.isSuper$ = rValue.roles.superuser;
      } else {
        this.isSuper$ = false;
      }
    });
    this.subscription.add(
      combineLatest([
        this.personService.getPersons(), this.subjectService.getTeams(), this.subjectService.getStations(),
        this.subjectService.getRelations(), this.emergencyService.getEmergencies(), this.personService.getOwnTeam(),
        this.exposureService.getExposures(), this.evaluationService.getEmergencyEvaluations(), this.roleService.getRoles()
      ]).subscribe(([persons, teams, stations, relations, emergencies, ownTeam, exposures, evaluations, roles]) => {

        this.exposures = exposures.map(exposure => {
          return {key: exposure.key, ...exposure.payload.val()};
        });

        this.evaluations = evaluations.map(evaluation => {
          return {key: evaluation.key, ...evaluation.payload.val()};
        });

        this.roles = roles.map(role => {
          return {key: role.key, ...role.payload.val()};
        });

        this.counter = 1; this.emergencyYear = new Map<string, boolean>();
        this.emergencies = emergencies.map(emergency => {
          return {key$: emergency.key, ...emergency.payload.val()};
        }).sort((a, b) =>{
          const aDate = moment(a.date.split('.').reverse().join("-") + " " + a.timeStart);
          const bDate = moment(b.date.split('.').reverse().join("-") + " " + b.timeStart);
          return bDate.diff(aDate);
        });
        this.emergencies.reverse().forEach(emergency => {
          const date = emergency.date.split('.');

          if (!this.emergencyYear.has(date[2])) {
            this.emergencyYear.set(date[2], true);
            const createdMap = new Map();
            this.emergencyId.set(date[2], createdMap.set(emergency.dateCreated, 1));
            this.counter = 1;
          }
          else {
            this.counter++;
            this.emergencyId.get(date[2]).set(emergency.dateCreated, this.counter);
          }
        });

        this.emergencies.reverse();

        this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
        this.teamNameMap = new Map(teams.map(team => [team.key, team.payload.val().name]));
        this.stationNameMap = new Map(stations.map(station => [station.key, station.payload.val().name]));

        this.parentChildMap = new Map();
        this.groupedFilter = [];

        const rData = this.esSort.sortOnStation(this.emergencies, relations, this.stationNameMap, this.teamNameMap, this.personNameMap);
        this.groupedFilter = Array.from(rData[0]);
        this.emergenciesMap = new Map(rData[1]);
        const teamStationMap = new Map(rData[2]);

        this.groupedFilter.unshift({label: this.translateService.instant('ROLES.ALL_STATIONS'), value: 'All'});

        if(ownTeam){
          this.selectedStation = teamStationMap.get(ownTeam.key);
        } else {
          this.selectedStation = 'All';
        }
        this.setStationFilter();
      })
    );
  }

  ngOnInit(): void {
    this.items= [
      {   label: this.translateService.instant('EVENT.NEW_EMERGENCY'),
        icon: 'pi pi-plus',
        command: () => {
          this.addEmergency();
        }
      },
      {   label: this.translateService.instant('EVENT.EMERGENCY_TYPES'),
        icon: 'pi pi-cog',
        command: () => {
          this.editTypes();
        }
      },
    ];
  }

  /**
   * Build persons station object
   * @param subject Person
   */
  buildFilter(subject) {
    return {
      label: this.personNameMap.get(subject),
      value: subject,
    };
  }

  /**
   * Add station filter
   */
  setStationFilter() {
    this.emergencyFilter(this.search);
  }

  /**
   * Calculate duration of event
   * @param date Date of event
   * @param start Started at
   * @param end Ended at
   */
  calculateDuration(date: string, start: string, end: string){
    const dateSplit = date.split(".");
    date = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
    const eventStart = moment(date + " " + start);
    let eventEnd = moment(date + " " + end);

    let diff = eventEnd.diff(eventStart, 'minutes');

    if (diff < 0){
      eventEnd =  eventEnd.add(1, 'days');
      diff = eventEnd.diff(eventStart, 'minutes');
    }

    if (diff > 60){
      const min = diff % 60;
      if (min !== 0){
        return String(eventEnd.diff(eventStart, 'hours')) + " h " + min + " min";
      }else {
        return String(eventEnd.diff(eventStart, 'hours')) + " h ";
      }
    }
    else {
      return String(eventEnd.diff(eventStart, 'minutes')) + " min";
    }

  }

  /**
   * Count number of participants
   * @param participants
   */
  numberOfCrews(participants: any){
    if (participants === undefined){
      return 0;
    }
    return participants.length;
  }

  /**
   * Add new emergency
   */
  addEmergency(){
    this.dialogService.open(EmergencyAddComponent, {
      header: this.translateService.instant('EVENT.NEW'),
      styleClass: 'max-size-width-dialog',
    });
  }

  /**
   * More info on specific emergency
   * @param emergency Emergency data
   */
  moreInfo(emergency: any){
    const date = emergency.date.split('.');

    let thisEvaluation: EmergencyEval;
    for (const e of this.evaluations) {
      if (e.emergencyId === emergency.key$){
        thisEvaluation = e;
      }
    }
    this.dialogService.open(EmergencyReadComponent, {
      header: this.translateService.instant('EVENT.EMERGENCY') + ": "
        + this.emergencyId.get(date[2]).get(emergency.dateCreated) + "/" + date[2].slice(-2),
      styleClass: 'max-size-width-dialog',
      data: {
        emergency: emergency,
        exposures: this.exposures,
        evaluation: thisEvaluation
      }
    });
  }

  /**
   * Edit emergency
   * @param emergency Emergency data
   */
  editInfo(emergency: any) {
    const date = emergency.date.split('.');
    let thisEvaluation: EmergencyEval;
    for (const e of this.evaluations) {
      if (e.emergencyId === emergency.key$){
        thisEvaluation = e;
      }
    }
    this.dialogService.open(EmergencyEditComponent, {
      header: this.translateService.instant('EVENT.EMERGENCY') + ": "
        + this.emergencyId.get(date[2]).get(emergency.dateCreated) + "/" + date[2].slice(-2),
      styleClass: 'max-size-width-dialog',
      closable: false,
      data: {
        emergency: emergency,
        exposures: this.exposures,
        roles: this.roles,
        evaluation: thisEvaluation,
        personNameMap: this.personNameMap,
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3500);
      } else {
        location.reload();
      }
    });
  }


  editTypes() {
    this.dialogService.open(EmergencyTypesComponent, {
      header: this.translateService.instant('EVENT.EMERGENCY_TYPES'),
      width: "400px",
    });
  }

  /**
   * Get Event number
   * @param emergency The emergency response
   */
  getCounterId(emergency: EmergencyResponse) {
    const date = emergency.date.split('.');
    const list = this.emergencyId.get(date[2]);
    const YY = date[2].slice(-2);
    return list.get(emergency.dateCreated) + "/" + YY;
  }

  /**
   * Check if emergency is newer than 7 days.
   * @param dateCreated Emergency creation date
   * @param admin Is admin
   */
  expired(dateCreated: string, admin: boolean) {
    if (admin){
      return moment(moment()).diff(dateCreated, 'days') < 65;
    } else {
      return moment(moment()).diff(dateCreated, 'days') < 35;
    }
  }

  /**
   * Filter emergencies on name/address
   * @param search Search string
   */
  emergencyFilter(search: string) {
    this.emergencies = this.eventFilter.transform(this.emergenciesMap.get(this.selectedStation), this.personNameMap, search);
  }

  /**
   * Get value from search event
   * @param $event Search event
   */
  getEventValue($event: Event): string {
    this.search = ($event.target as HTMLInputElement).value;
    return ($event.target as HTMLInputElement).value;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  screenWidth() {
    return window.innerWidth < 441;
  }

}
