import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FunctionService} from "../../../services/service/function.service";
import {Subscription} from "rxjs";
import {FunctionModel} from "../../../model/function";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {ConfirmationService, FilterService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {PersonService} from "../../../services/service/person.service";
import {AuthService} from "../../../services/service/auth.service";
import {publish, take} from "rxjs/operators";
import {
  EditCompetencyTypesValidityComponent
} from "../edit-competency-types-validity/edit-competency-types-validity.component";
import {TenantService} from "../../../services/service/tenant.service";
import {AngularFireFunctions} from "@angular/fire/compat/functions";

@Component({
  selector: 'app-edit-function',
  templateUrl: './edit-function.component.html',
  styleUrls: ['./edit-function.component.scss'],
  providers: [ConfirmationService]
})
export class EditFunctionComponent implements OnInit, OnDestroy {

  // Function
  function: FunctionModel = {
    competencyTypes: {}, createdBy: "", createdDate: "", name: "", subjects: {}
  };
  functionKey;

  // Person
  persons = [];
  outputPerson = [];
  selectedPerson = '';
  personMap = new Map();
  personArray = [];
  personNameMap = new Map();


  // CompetencyType
  competencyTypes = [];
  outputCT = [];
  selectedCT = '';
  ctMap = new Map();
  ctArray = [];
  ctNameMap = new Map();


  // Loading value for Table.
  loading = true;

  // Subscription
  subscription = new Subscription();

  splitMenu: any;
  tenantId: any;

  // Used for Firebase Function Call
  changed = false;

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    public functionService: FunctionService,
    public ctService: CompetencyTypeService,
    public filterService: FilterService,
    public translateService: TranslateService,
    public personService: PersonService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
    private tenantService: TenantService,
    private aff: AngularFireFunctions
  ) {
    this.splitMenu = [
      {
        label: translateService.instant('BUTTON.REMOVE'),
        icon: 'pi pi-trash',
        command: () => {
          this.deleteFunction();
        }
      }
    ];

    this.functionKey = this.config.data.key;

    // Used for Call Function
    this.subscription.add(
      this.tenantService.getTenantId().subscribe(tenantId => {
        this.tenantId = tenantId;
      })
    );


    this.subscription.add(
      this.functionService.getFunction(this.functionKey).subscribe(functionData => {
        this.function = JSON.parse(JSON.stringify(functionData));

        if (functionData.competencyTypes) {
          this.ctMap = new Map(Object.entries(functionData.competencyTypes));
          this.ctArray = Array.from(this.ctMap, ([key, validity]) => ({key, ...validity}));
        }

        if (functionData.subjects) {
          this.personMap = new Map(Object.entries(functionData.subjects));
          this.personArray = Array.from(this.personMap, ([key, addedDate]) => ({key, ...addedDate}));
        }
        this.loading = false;
      })
    );

    // Get all Persons
    this.subscription.add(
      this.personService.getPersons().subscribe(persons => {
        this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
        this.persons = persons.map(person => {
          return {key: person.key, ...person.payload.val()};
        }).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      })
    );
  }

  ngOnInit(): void {

    // Get All Competency Types
    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(competencyTypes => {
        this.ctNameMap = new Map(competencyTypes.map(ct => [ct.key, ct.payload.val().name]));
        this.competencyTypes = competencyTypes.map(ct => {
          return {key: ct.key, ...ct.payload.val()};
        }).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.ctArray.sort((a, b) => {
          return this.ctNameMap.get(a.key).toLowerCase().localeCompare(this.ctNameMap.get(b.key).toLowerCase());
        });
      })
    );
  }


  // Add Person
  addPerson(person) {
    if (!this.personMap.has(person.key)) {
      this.changed = true;
      this.personMap.set(person.key, {addedDate: new Date().toISOString()});
      this.personArray.push({key: person.key});
      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a.key).toLowerCase().localeCompare(this.personNameMap.get(b.key).toLowerCase());
      });
    }
  }

  // Add CT
  addCT(ct) {
    if (!this.ctMap.has(ct.key)) {
      this.changed = true;
      this.ctMap.set(ct.key, {validity: 0});
      this.ctArray.push({key: ct.key});
      this.ctArray.sort((a, b) => {
        return this.ctNameMap.get(a.key).toLowerCase().localeCompare(this.ctNameMap.get(b.key).toLowerCase());
      });
    }
  }

  // Filter CT
  filterCT(event: any) {
    const query = event.query;
    this.outputCT = this.filterService.filter(this.competencyTypes, ['name'], query, "contains");
  }

  // Filter Person
  filterPerson(event: any) {
    const query = event.query;
    this.outputPerson = this.filterService.filter(this.persons, ['name'], query, "contains");
  }


  // Save and Close
  save() {
    this.authService.getUserUID().pipe(
      take(1),
    ).subscribe(uid => {
      const ctObject: any = {};
      for (const key of this.ctMap.keys()) {
        ctObject[key] = {status: 'WAITING', exists: true, addedDate: new Date().toISOString()};
      }
      this.personMap.forEach(((value) => {
        if (value.competencyTypes) {
          Object.keys(ctObject).forEach(key => {
            if (!Object.keys(value.competencyTypes).includes(key)) {
              value.competencyTypes[key] = ctObject[key];
            }
          });
          Object.keys(value.competencyTypes).forEach(key => {
            if (!Object.keys(ctObject).includes(key)) {
              value.competencyTypes[key] = null;
            }
          });
        } else {
          value.competencyTypes = ctObject;
        }
      }));
      this.function.competencyTypes = Object.fromEntries(this.ctMap);
      this.function.subjects = Object.fromEntries(this.personMap);
      this.function.updatedBy = uid;


      // Trigger if added new competency, person or changed validity on ct
      if(this.changed){
        publish()(this.aff.httpsCallable('putFunctionSubjectCompetency')
        (
          {
            tenantId: this.tenantId,
            functionId: this.functionKey,
          })).connect();
      }

      this.functionService.updateFunction(this.functionKey, this.function);
      this.ref.close();
    });
  }

  // Unsubscribe on Destroy
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Delete Person from List
  deletePerson(pKey: any) {
    this.confirmationService.confirm({
      key: 'eFunction',
      message: this.translateService.instant('WARNING.REMOVE') + ':  <br/>' + this.personNameMap.get(pKey),
      accept: () => {
        this.personMap.delete(pKey);
        this.personArray = Array.from(this.personMap, ([key, addedDate]) => ({key, ...addedDate}));
        this.changed = true;
      }
    });

  }

  // Delete CT from List
  deleteCT(cKey: any) {
    this.confirmationService.confirm({
      key: 'eFunction',
      message: this.translateService.instant('WARNING.REMOVE') + ':  <br/>' + this.ctNameMap.get(cKey),
      accept: () => {
        this.ctMap.delete(cKey);
        this.ctArray = Array.from(this.ctMap, ([key, validity]) => ({key, ...validity}));
      }
    });
  }

  // Edit validity on competency types
  editCT(ctKey) {
    this.subscription.add(
      this.dialogService.open(EditCompetencyTypesValidityComponent, {
        header: this.ctNameMap.get(ctKey),
        data: {
          ctKey: ctKey,
          fKey: this.functionKey,
        }
      }).onClose.subscribe((changed: boolean) => {
          if(changed) {
            this.changed = true;
          }
      })
    );
  }

  private deleteFunction() {
    this.functionService.deleteFunction(this.functionKey);
    this.ref.close();
  }
}
