import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChecklistService} from "../../../services/service/checklist.service";
import {Subscription} from "rxjs";
import {Checklist} from "../../../model/checklist";
import {SubjectService} from "../../../services/service/subject.service";
import {PersonService} from "../../../services/service/person.service";
import {AssetService} from "../../../services/service/asset.service";
import * as moment from "moment";
import {DialogService} from "primeng/dynamicdialog";
import {CompletedChecklistDetailComponent} from "../completed-checklist-detail/completed-checklist-detail.component";

@Component({
  selector: 'app-completed-checklists',
  templateUrl: './completed-checklists.component.html',
  styleUrls: ['./completed-checklists.component.scss'],
  providers: [DialogService]
})
export class CompletedChecklistsComponent implements OnInit, OnDestroy {
  completedChecklists: Checklist[];

  stationOptions: [];
  selectedStation: string;
  assetMap: Map<string, string>;

  loading = true;

  subscription = new Subscription();
  constructor(
    private checklistService: ChecklistService,
    private subjectService: SubjectService,
    private personService: PersonService,
    private assetService: AssetService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit(): void {

    // Get users current station to pre-filter checklists
    this.subscription.add(
      this.personService.getOwnStation().subscribe(station => {
        if(station){
          this.selectedStation = station.key;
        } else {
          this.selectedStation = '';
        }
      })
    );

    // Get completed checklists
    this.subscription.add(
        this.checklistService.getCompletedChecklistWithoutDeviations().subscribe(checklists => {
          this.completedChecklists = checklists.map(checklist => {
            return {key: checklist.key, ...checklist.payload.val()};
          }).sort((a,b) => moment(b.dateOfCompletion).diff(moment(a.dateOfCompletion)));
          this.loading = false;
        })
    );

    // Get stations for stationOptions.
    this.subscription.add(
        this.subjectService.getStations().subscribe(stations => {
          this.stationOptions = stations.map(station => {
            return {key: station.key, name: station.payload.val().name};
          });
        })
    );

    // Get assets for assetMap
    this.subscription.add(
        this.assetService.getAssets().subscribe(assets => {
          this.assetMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
        })
    );
  }

  /**
   * Unsubscribe on Destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openDetail(checklist: any) {
    this.dialogService.open(CompletedChecklistDetailComponent, {
      header: checklist.name,
      data: {
        checklistId: checklist.key
      },
      styleClass: 'max-size-dialog'
    });
  }
}
