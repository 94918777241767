<!-- ConfirmDialog -->
<p-confirmDialog header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle"></p-confirmDialog>

<form [formGroup]="assetForm" (ngSubmit)="onSubmit()">
    <div class="grid">
        <div class="col-12 mt-3">
          <span class="p-float-label">
            <input type="text"
                   id="assetName"
                   class="inputfield w-full"
                   pInputText
                   formControlName="name">
            <label for="assetName">{{'COMMON.NAME' | translate}}</label>
          </span>
        </div>
        <div class="col-12">
            <p-dropdown [options]="stations"
                        [style]="{'width' : '100%'}"
                        formControlName="stationId"
                        placeholder="{{'COMMON.STATION' | translate}}*"
                        optionLabel="name"
                        optionValue="key">
            </p-dropdown>
        </div>
        <div class="col-12">
            <p-dropdown [options]="assetTypes"
                        [style]="{'width' : '100%'}"
                        formControlName="type"
                        placeholder="{{'ASSET.ASSET_TYPE' | translate}}*"
                        optionLabel="name"
                        optionValue="key">
            </p-dropdown>
        </div>
        <div class="col-6">
            {{'ASSET.EDIT_ASSET.INCOMMISSION' | translate}}
        </div>
        <div class="col-6 flex justify-content-end">
            <p-selectButton [options]="stateOptions" formControlName="inCommission" optionLabel="label"
                            optionValue="value" class="p-button-raised"></p-selectButton>
        </div>
        <ng-container *ngIf="origin==='add'; else editBlock">
            <div class="col-12 flex justify-content-end">
                <button pButton class="p-button-raised" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark"
                           (click)="onSubmit()"></button>
            </div>
        </ng-container>
        <ng-template #editBlock>
            <div class="col-6">
                <ng-container *ngIf="connections; else deleteBlock">
                    <button pButton label="{{'BUTTON.ARCHIVE' | translate}}" icon="far fa-archive"
                            (click)="confirm(assetObj, 'archive')"></button>
                </ng-container>
                <ng-template #deleteBlock>
                    <button pButton label="{{'FILE.DELETE' | translate}}" icon="pi pi-trash"
                            (click)="confirm(assetObj, 'delete')" class="p-button-raised"></button>
                </ng-template>
            </div>
            <div class="col-6 flex justify-content-end">
                <button class="p-button-raised" pButton label="{{'BUTTON.SAVE' | translate}}"
                        icon="pi pi-bookmark" (click)="onSubmit()"></button>
            </div>
        </ng-template>
    </div>
</form>
