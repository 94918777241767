import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase, SnapshotAction} from "@angular/fire/compat/database";
import {map, mergeMap, publish} from "rxjs/operators";
import {Exercise, ExerciseTemplate} from '../../model/exercise';
import {Theme} from "../../model/theme";

@Injectable({
  providedIn: 'root'
})
export class ExerciseService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get all exercises.
   */
  getAllExercises(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/exercises`).snapshotChanges()
        )
    );
  }
  /**
   * Get all active exercises.
   */
  getActiveExercises(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/exercises`, ref => {
          return ref.orderByChild('completed').equalTo(false);
        }).snapshotChanges()
      )
    );
  }

  /**
   *
   */
  getTemplates(): Observable<any>{
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/exercises`, ref => {
              return ref.orderByChild('template').equalTo(true);
            }).snapshotChanges()
        )
    );
  }
  /**
   * Get all completed exercises.
   */
  getCompletedExercises(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/exercises`, ref => {
          return ref.orderByChild('completed').equalTo(true);
        }).snapshotChanges()
      )
    );
  }
  /**
   * Add exercise.
   * @param exercise Exercise object.
   */
  addExercise(exercise: Exercise) {
    return publish()(this.tenantId$.pipe(
        map(
            tenantId => this.db.list(`tenants/${tenantId}/exercises`).push(exercise)
        )
    )).connect();
  }

  /**
   * Add exercise.
   * @param exercise Exercise object.
   */
  addExerciseGetKey(exercise: Exercise) {
    return this.tenantId$.pipe(
        map(tenantId => {
          const ref = this.db.list(`tenants/${tenantId}/exercises`).push(exercise);
          return ref.key;
        })
    );
  }

  /**
   * Add exercise template.
   * @param template Template object.
   */
  addExerciseTemplateGetKey(template: ExerciseTemplate) {
    return this.tenantId$.pipe(
        map(tenantId => {
          const ref = this.db.list(`tenants/${tenantId}/exercises`).push(template);
          return ref.key;
        })
    );
  }

  /**
   * Get exercise.
   * @param key Exercise fbid.
   */
  getExercise(key): Observable<Exercise> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/exercises/${key}`).valueChanges()
      )
    ) as Observable<Exercise>;
  }

  getEFRelations() {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/exerciseFileRelations`).snapshotChanges()
      )
    );
  }

  /**
   * Update exercise.
   * @param exerciseId Exercise fbid.
   * @param exercise Updated exercise object.
   */
  updateExercise(exerciseId, exercise) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/exercises/${exerciseId}`).set(exercise)
      )
    )).connect();
  }

  /**
   * Get competency types.
   * @param exerciseId exercise fbid.
   */
  getExerciseCompetencyTypes(exerciseId: string) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/exercises/${exerciseId}/competencyTypes`).valueChanges()
      )
    );
  }

  /**
   * Delete exercise.
   * @param key exercise fbid.
   */
  deleteExercise(key: string) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/exercises/${key}`).remove()
      )
    )).connect();
  }

  /**
   * Update exercise competency types.
   * @param exerciseId Exercise fbid.
   * @param competencyTypes Competency type list.
   */
  updateExerciseCompetencyTypes(exerciseId, competencyTypes: { [p: string]: any }) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/exercises/${exerciseId}/competencyTypes`).set(competencyTypes)
      )
    )).connect();
  }

  /**
   * Add new theme.
   * @param theme Theme object.
   */
  addTheme(theme: Theme) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/themes`).push(theme)
      )
    )).connect();
  }

  /**
   * @return Snapshot of Themes.
   */
  getThemes(): Observable<SnapshotAction<Theme>[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/themes`).snapshotChanges()
      )
    ) as Observable<SnapshotAction<Theme>[]>;
  }

  /**
   * Get theme.
   * @param key Theme fbid.
   */
  getTheme(key): Observable<Theme> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/themes/${key}`).valueChanges()
      )
    ) as Observable<Theme>;
  }

  /**
   * Update theme.
   * @param key Theme fbid.
   * @param theme Updated object.
   */
  updateTheme(key, theme) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/themes/${key}`).update(theme)
      )
    )).connect();
  }

  /**
   * Delete theme.
   * @param key Theme fbid.
   */
  deleteTheme(key) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/themes/${key}`).remove()
      )
    )).connect();
  }
}
