<div class="grid">
  <div class="col-12 flex justify-content-end">
    <button pButton [disabled]="(!function.name) || !(ctMap.size > 0)" class="p-button-raised"
              icon="pi pi-bookmark" (click)="addFunction()" label="{{'BUTTON.SAVE' | translate}}"></button>
  </div>
  <div class="col-12">
    <div class="field">
      <label for="name">{{'COMMON.NAME' | translate}}</label>
      <input id="name" [(ngModel)]="function.name" type="text" class="inputfield w-full" pInputText>
    </div>
  </div>

  <div class="col-12 p-0 field">
    <label class="p-2" for="ctTable">{{'COMPETENCY.TYPES' | translate}}</label>
    <p-table [value]="ctList" responsiveLayout="scroll" id="ctTable">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-12">
            <p-autoComplete
              appendTo="body"
              [(ngModel)]="selectedCT"
              [suggestions]="output | removeExistingFilter: ctMap"
              (onSelect)="addCompetencyType()"
              field="name"
              type="search"
              placeholder="{{'COMPETENCY.ADD_COMPETENCY_TYPE' | translate}}"
              (completeMethod)="search($event)"
              size="100"
              forceSelection="true"
              [dropdown]="true">
            </p-autoComplete>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="body" let-ct>
        <tr>
          <td>{{ct.name}}</td>
          <td style="size: 60px">
            <div class="flex justify-content-end">
              <i (click)="delete(ct)" class="pi pi-trash" style="color: #D0021B"></i>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
