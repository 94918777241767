import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChecklistService} from "../../../services/service/checklist.service";
import {Subscription} from "rxjs";
import * as moment from "moment";
import {AssetService} from "../../../services/service/asset.service";
import {SubjectService} from "../../../services/service/subject.service";
import {AuthService} from "../../../services/service/auth.service";
import {AccessControlService} from "../../../services/service/access-control.service";
import {PersonService} from "../../../services/service/person.service";
import {DialogService} from "primeng/dynamicdialog";
import {CompletedChecklistDetailComponent} from "../completed-checklist-detail/completed-checklist-detail.component";

@Component({
  selector: 'app-not-finished-checklists',
  templateUrl: './not-finished-checklists.component.html',
  styleUrls: ['./not-finished-checklists.component.scss'],
  providers: [DialogService]
})
export class NotFinishedChecklistsComponent implements OnInit, OnDestroy {
  notFinishedChecklists: any[];
  selectedStation = '';
  stationOptions: [];
  assetMap = new Map();

  subscription = new Subscription();

  constructor(
    private checklistService: ChecklistService,
    private assetService: AssetService,
    private subjectService: SubjectService,
    private authService: AuthService,
    private acService: AccessControlService,
    private personService: PersonService,
    private dialogService: DialogService,
  ) {

    // Get own station for pre station filter.
    this.subscription.add(
      this.personService.getOwnStation().subscribe(ownStation => {
        this.selectedStation = ownStation.key;
      })
    );

    // Get Unfinished checklists and sort newest date first.
    this.subscription.add(
      this.checklistService.getNotFinishedChecklists().subscribe(nfChecklists => {
        this.notFinishedChecklists = nfChecklists.map(checklist => {
          return {key: checklist.key, ...checklist.payload.val()};
        }).sort((a, b) => moment(b.dateOfCompletion).diff(moment(a.dateOfCompletion)));
        console.log(this.notFinishedChecklists);
      })
    );

    // Asset Map
    this.subscription.add(
      this.assetService.getAssets().subscribe(assets => {
        this.assetMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
      })
    );

    // Station Options
    this.subscription.add(
      this.subjectService.getStations().subscribe(stations => {
        this.stationOptions = stations.map(station => {
          return {key: station.key, name: station.payload.val().name};
        });
      })
    );
  }

  ngOnInit(): void {

  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openDetail(checklist) {
    this.dialogService.open(CompletedChecklistDetailComponent, {
      header: checklist.name,
      data: {
        checklistId: checklist.key
      },
      styleClass: 'max-size-dialog'
    });
  }
}
