import { Component, OnInit } from '@angular/core';
import {ExerciseService} from '../../../services/service/exercise.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ExerciseEvalFilterPipe} from '../../../pipes/exercise-eval-filter.pipe';

@Component({
  selector: 'app-experience-sharing',
  templateUrl: './experience-sharing.component.html',
  styleUrls: ['./experience-sharing.component.scss'],
  providers: [ExerciseEvalFilterPipe]
})
export class ExperienceSharingComponent implements OnInit {

  subscription = new Subscription();

  exercises: any[];
  evaluations: any[] = [];
  evaluationsAll: any[] = [];

  search = "";

  constructor(
    private exerciseService: ExerciseService,
    private evaluationsFilter: ExerciseEvalFilterPipe,
  ) {

    // Get completed exercises
    this.subscription.add(
      this.exerciseService.getCompletedExercises().subscribe(exercisePayload => {
        this.exercises = exercisePayload.map(exercise => {
          return {key: exercise.key, ...exercise.payload.val()};
        }).sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        this.exercises.forEach(exercise => {
          if (exercise.evaluation){
            if(exercise.evaluation.evaluation || exercise.evaluation.other || exercise.evaluation.learningPoints) {
              let timeSpan;
              if (moment(exercise.date).format('DD.MM.YY') === moment(exercise.dateEnd).format('DD.MM.YY')){
                timeSpan = moment(exercise.date).format('DD.MM.YY') + ": "
                  + moment(exercise.date).format('HH:mm') + " — " + moment(exercise.dateEnd).format('HH:mm');
              } else {
                timeSpan = moment(exercise.date).format('DD.MM.YY HH:mm') + " — "
                  + moment(exercise.dateEnd).format('DD.MM.YY HH:mm');
              }
              const exposureEval = {
                date: timeSpan,
                name: exercise.name,
                goals: exercise.evaluation.goal,
                evaluation: exercise.evaluation.evaluation,
                learningPoints: exercise.evaluation.learningPoints,
                other: exercise.evaluation.other
              };
              this.evaluations.push(exposureEval);
              this.evaluationsAll.push(exposureEval);
            }
          }
        });
      })
    );

  }

  ngOnInit(): void {
  }

  evalFilter(search: any) {
    this.evaluations = this.evaluationsFilter.transform(this.evaluations, this.evaluationsAll, search);
  }

  /**
   * Get event string value
   * @param $event Search event
   */
  getEventValue($event: Event) {
    this.search = ($event.target as HTMLInputElement).value;
    return ($event.target as HTMLInputElement).value;
  }
}
