import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private authService: AuthService
  ) { }

  public getUserUID(): Observable<any>{
    return this.authService.getAuthState().pipe(
      map(auth => {
        return auth.uid;
      })
    );
  }
}
