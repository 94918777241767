import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileData} from '../../../model/fileRelated';
import {combineLatest, Subscription} from 'rxjs';
import {FileService} from '../../../services/service/file.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import {PersonService} from '../../../services/service/person.service';
import * as moment from 'moment';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ExerciseService} from '../../../services/service/exercise.service';
import {FileSetCategoryComponent} from '../../archive/file-set-category/file-set-category.component';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {FileStorageService} from '../../../services/service/file-storage.service';


@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss'],
  providers: [ConfirmationService, MessageService, DialogService]
})
export class FileViewComponent implements OnInit, OnDestroy{
  subscription = new Subscription();
  windowWidth = window.innerWidth;

  userStatus = new Map<string, boolean>();

  file: FileData;
  fileKey: string;
  fileTags: any[] = [];
  sharedFileTags: any[] = [];
  tagRelations = new Map();
  selectedTagsMap: Map<string, string[]> = new Map<string, string[]>();
  tagCounter = 0;

  flexitFiles: string;
  subjectName: string;
  dateString: string;
  description: string;
  change = false;
  archived: boolean;

  items: MenuItem[];

  allExerciseFiles: string[] = [];
  relations: string[] = [];
  loading = true;
  active = false;
  search: string;
  selectedCategory: any[] = [];

  constructor(private dialogRef: DynamicDialogRef,
              private config: DynamicDialogConfig,
              private fileService: FileService,
              private acService: AccessControlService,
              private personService: PersonService,
              private translateService: TranslateService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private exerciseService: ExerciseService,
              private dialogService: DialogService,
              private fileStorageService: FileStorageService,
  ) {
    this.items= [
      {   label: this.translateService.instant('FILE.DOWNLOAD'),
        icon: 'far fa-cloud-download',
        command: () => {
          this.downloadFile();
        }
      },
      {   label: this.translateService.instant('FILE.DELETE'),
        icon: 'pi pi-trash',
        command: () => {
          this.confirm();
        }
      },
    ];

  }

  ngOnInit(): void {
    this.file = this.config.data.file;
    this.fileKey = this.config.data.fileKey;
    this.dateString = moment(this.file.date).format('DD.MM.YYYY');
    this.description = this.file.description;
    this.flexitFiles = this.config.data.flexitFiles;
    this.archived = this.file.archived;


    this.subscription.add(combineLatest([this.fileService.getFileTagRelations(), this.fileService.getFileTags(),
      this.personService.getPersons(), this.fileService.getSharedFileTags(), this.exerciseService.getAllExercises(),

      this.acService.getAccessControlsPayload()])
      .subscribe(([fileTagRelations, fileTags, persons, sharedTags, allExercises, acs]) => {
        this.acService.getOwnAccessControl().subscribe(value => {
          if (value.roles.admin){
            this.userStatus.set('admin', true);
          } else {
            this.userStatus.set('admin', false);
          }
          if (value.roles.superuser){
            this.userStatus.set('superUser', false);
          } else {
            this.userStatus.set('superUser', true);
          }
        });

        allExercises.forEach(exercise => {
          if (exercise.payload.val().exerciseFiles){

            this.allExerciseFiles = this.allExerciseFiles.concat(exercise.payload.val().exerciseFiles);
            this.allExerciseFiles = [...this.allExerciseFiles];
          }
        });
        const personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
        let subjectId = 'FlexitFire';
        const acMap = acs.map(ac => {
          return {key: ac.key, ...ac.payload.val()};
        });
        acMap.forEach(ac => {
          if (ac.key === this.file.createdById){
            subjectId = ac.subjectId;
          }
        });
        if (subjectId === 'FlexitFire'){
          this.subjectName = 'FlexitFire';
        } else {
          this.subjectName = <string> personNameMap.get(subjectId);
        }
        fileTags.forEach(tag => {
          if (!tag.archived) {
            this.fileTags.push({key: tag.key, ...tag.payload.val()});
          }
        });
        sharedTags.forEach(tag => {
          this.sharedFileTags.push({key: tag.key, ...tag.payload.val()});
        });

        fileTagRelations.forEach(relation => {
          if (relation.payload.val().fileId === this.fileKey){
            if (this.selectedTagsMap.has(this.fileKey)){
              // For deleting relations
              this.relations.push(relation.payload.key);
              // For tag selection
              this.tagRelations.set(relation.payload.val().tagId, relation.payload.key);
              // Selected tags
              this.selectedTagsMap.get(this.fileKey).push(relation.payload.val().tagId);
              this.selectedTagsMap.get('copy').push(relation.payload.val().tagId);
              this.tagCounter = this.selectedTagsMap.get(this.fileKey).length;
            } else {
              // For deleting relations
              this.relations.push(relation.payload.key);
              // For tag selection
              this.tagRelations.set(relation.payload.val().tagId, relation.payload.key);
              // Selected tags
              this.selectedTagsMap.set(this.fileKey, [relation.payload.val().tagId]);
              this.selectedTagsMap.set('copy', [relation.payload.val().tagId]);
              this.tagCounter = this.selectedTagsMap.get(this.fileKey).length;
            }
          }
        });
        if (this.selectedTagsMap.has(this.fileKey)){
          this.selectedTagsMap.get(this.fileKey).forEach(tagId => {
            this.fileTags.forEach(element => {
              if (element.key === tagId){
                this.selectedCategory.push(element);
              }
            });
          });
        }
        for (let i = 0; i < this.fileTags.length; i++) {
          if (this.selectedCategory.includes(this.fileTags[i])){
            this.fileTags.splice(i, 1);
          }
        }
        this.fileTags.sort((a, b) => {
          const nameA = a.tagName.toLowerCase(); // ignore upper and lowercase
          const nameB = b.tagName.toLowerCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

        this.fileTags = this.selectedCategory.concat(this.fileTags);

        this.loading = false;

      }));
  }

  /**
   * Confirm deletion of file
   */
  confirm() {
    this.confirmationService.confirm({
      message: this.translateService.instant('FILE.CONFIRM_DELETION_ALERT') + " " + this.file.name,
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.deleteFile();
      }
    });
  }

  /**
   * DeleteFile
   */
  deleteFile() {
    if (!this.allExerciseFiles.includes(this.fileKey)) {
      this.relations.forEach(relation => this.fileService.deleteFileTagRelation(relation));
      this.fileService.deleteFile(this.fileKey);
      this.dialogRef.close('Deleted');

    } else {
      this.messageService.add(
        {
          severity: 'error',
          summary: this.translateService.instant('TOAST.ERROR'),
          detail: this.file.name + " " + this.translateService.instant('FILE.FILE_IS_IN_USE_ALERT')
        }
      );
    }

  }

  /**
   * Set selected file tags
   * @param tag Selection event
   */
  setTags(tag: any) {
    if (this.selectedTagsMap.get(this.fileKey).includes(tag.key)){
      const tagList = this.selectedTagsMap.get(this.fileKey);
      for (let i = 0; i < tagList.length; i++) {
        if (tagList[i] === tag.key){
          tagList.splice(i, 1);
          break;
        }
      }
      if (this.selectedTagsMap.get('copy').length !== this.selectedTagsMap.get(this.fileKey).length) {
        this.change = true;
      }
    } else {
      this.selectedTagsMap.get(this.fileKey).push(tag.key);
      this.tagCounter = this.selectedTagsMap.get(this.fileKey).length;
      if (this.selectedTagsMap.get('copy').length !== this.selectedTagsMap.get(this.fileKey).length) {
        this.change = true;
      } else {
        this.selectedTagsMap.get('copy').some(key => {
          if (!this.selectedTagsMap.get(this.fileKey).includes(key)) {
            this.change = true;
          }
        });
      }
    }
  }

  /**
   * Download selected file
   */
  downloadFile() {
    window.open(this.file.url);
  }

  /**
   * Check for changes.
   */
  changed() {
    if (this.change){
      return false;
    } else if (this.description !== this.file.description){
      return false;
    }
    return true;
  }

  /**
   * Copy to internal files
   */
  copy() {
    this.dialogService.open(FileSetCategoryComponent,{
      header: this.translateService.instant('FILE.CATEGORIES'),
      width: "500px",
      closable: false,
    }).onClose.subscribe(fileTag => {
      if (fileTag) {
        const storage = getStorage();
        getDownloadURL(ref(storage, "/-LZEFZF04TDDaFBZtw6_/felles/" + this.file.name))
          .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = () => {
              const blob = xhr.response;
              blob.name = this.file.name;
              console.log(blob, this.file.admin, this.file);
              this.fileStorageService.uploadNewFile(blob, this.file.admin, this.file);
              this.fileService.addFileTagRelation({fileId: this.fileKey, tagId: fileTag.key});
            };
            xhr.open('GET', url);
            xhr.send();
          })
          .catch((error) => {
            switch (error.code) {
              case 'storage/object-not-found':
                console.log("1");
                break;
              case 'storage/unauthorized':
                console.log("2");
                break;
              case 'storage/canceled':
                console.log("3");
                break;

              case 'storage/unknown':
                console.log("4");
                // Unknown error occurred, inspect the server response
                break;
            }
          });
        this.dialogRef.close();
      }
    });
  }

  /**
   * Save file changes
   */
  save() {
    // Delete relation
    this.selectedTagsMap.get('copy').forEach(tagId => {
      if (!this.selectedTagsMap.get(this.fileKey).includes(tagId)){
        this.fileService.deleteFileTagRelation(this.tagRelations.get(tagId));
      }
    });
    // Create relation
    this.selectedTagsMap.get(this.fileKey).forEach(tagId => {
      if(!this.selectedTagsMap.get('copy').includes(tagId)){
        this.fileService.addFileTagRelation({fileId: this.fileKey, tagId: tagId});
      }
    });
    this.fileService.updateFile(this.fileKey, this.file);
    this.dialogRef.close('Save');
  }

  /**
   * Restore archived file
   */
  restore() {
    this.file.archived = false;
    this.fileService.updateFile(this.fileKey, this.file);
    this.dialogRef.close(true);
  }

  /**
   * On destroy unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  print() {
  }
}
