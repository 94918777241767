import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-evaluations',
  templateUrl: './add-evaluation.component.html',
  styleUrls: ['./add-evaluation.component.scss']
})
export class AddEvaluationComponent implements OnInit{

  evaluation = new Map();

  description = '';
  measuresOnSite = '';
  learningPoints = '';
  various = '';

  fromExpo: boolean;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.fromExpo = this.config.data.fromExpo;

    if (this.config.data.evaluation.description !== this.config.data.evaluationCopy.description){
      this.description = this.config.data.evaluationCopy.description;
    } else {
      this.description = this.config.data.evaluation.description;
    }

    if (this.config.data.evaluation.measuresOnSite !== this.config.data.evaluationCopy.measuresOnSite){
      this.measuresOnSite = this.config.data.evaluationCopy.measuresOnSite;
    } else {
      this.measuresOnSite = this.config.data.evaluation.measuresOnSite;
    }

    if (this.config.data.evaluation.learningPoints !== this.config.data.evaluationCopy.learningPoints){
      this.learningPoints = this.config.data.evaluationCopy.learningPoints;
    } else {
      this.learningPoints = this.config.data.evaluation.learningPoints;
    }

    if (this.config.data.evaluation.various !== this.config.data.evaluationCopy.various){
      this.various = this.config.data.evaluationCopy.various;
    } else {
      this.various = this.config.data.evaluation.various;
    }
  }

  ngOnInit(): void {
  }

  /**
   * Save and close
   */
  save(){
    this.ref.close(
      this.evaluation
        .set('description', this.description)
        .set('measuresOnSite', this.measuresOnSite)
        .set('learningPoints', this.learningPoints)
        .set('various', this.various)
    );
  }

}
