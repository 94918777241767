import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {DeviationService} from '../../../services/service/deviation.service';
import {Deviation} from '../../../model/deviation';
import {AssetService} from '../../../services/service/asset.service';
import {PersonService} from '../../../services/service/person.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import * as moment from 'moment';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {AddDeviationComponent} from '../add-deviation/add-deviation.component';
import {take} from 'rxjs/operators';
import {UserService} from '../../../services/service/user.service';
import {CommentModalComponent} from '../../checklist/comment-modal/comment-modal.component';
import {DeviationFilterPipe} from '../../../pipes/deviation-filter.pipe';
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-deviations-main',
  templateUrl: './deviations-main.component.html',
  styleUrls: ['./deviations-main.component.scss'],
  providers: [DialogService, DeviationFilterPipe, ConfirmationService]
})
export class DeviationsMainComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  deviations: Deviation[];
  allDeviations: Deviation[];
  assetsMap = new Map<string, string>();
  personNameMap = new Map<string, string>();
  uidPersonIdMap = new Map<string, string>();

  search: string;
  filterOptions: any[];
  selectedFilter = this.translateService.instant('CHECKLIST.SHOW_ALL');
  assetDeviationMap: Map<string, Deviation[]>;

  constructor(
      private deviationService: DeviationService,
      private assetService: AssetService,
      private personService: PersonService,
      private acService: AccessControlService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private userService: UserService,
      private dFilter: DeviationFilterPipe,
      private confirmationService: ConfirmationService
  ) {

    this.subscription.add(combineLatest([this.deviationService.getOpenDeviationsPayload(), this.assetService.getAssets(),
      this.personService.getPersons(), this.acService.getAccessControlsPayload()])
        .subscribe(([deviations, assets, persons, acControls]) => {

          this.deviations = [];
          deviations.map(deviation => {
            if (!deviation.payload.val().closed) {
              this.deviations.push({key: deviation.key, ...deviation.payload.val()});
            }
          });
          this.allDeviations = this.deviations;

          this.assetsMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
          this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
          this.uidPersonIdMap = new Map(acControls.map(ac => [ac.key, ac.payload.val().subjectId]));

          this.assetDeviationMap = new Map<string, Deviation[]>();
          this.filterOptions = [];
          this.deviations.forEach(deviation => {

            if (this.assetDeviationMap.has(this.selectedFilter)) {
              this.assetDeviationMap.get(this.selectedFilter).push(deviation);
            } else {
              this.filterOptions.push({label: this.selectedFilter, value: this.selectedFilter});
              this.assetDeviationMap.set(this.selectedFilter, [deviation]);
            }

            if (this.assetDeviationMap.has(this.getAsset(deviation.asset))) {
              this.assetDeviationMap.get(this.getAsset(deviation.asset)).push(deviation);
            } else {
              this.filterOptions.push({label: this.getAsset(deviation.asset), value: this.getAsset(deviation.asset)});
              this.assetDeviationMap.set(this.getAsset(deviation.asset), [deviation]);
            }
          });
        })
    );

  }

  ngOnInit(): void {
  }

  /**
   * Filter assets on name search.
   * @param eventValue Search string.
   */
  deviationFilter(eventValue: string) {
    this.deviations = this.dFilter.transform(this.allDeviations, eventValue);
  }

  /**
   * Set Asset filter
   */
  setDeviationFilter() {
    this.deviations = this.assetDeviationMap.get(this.selectedFilter);
  }

  /**
   * Check screen size.
   */
  screenWidth() {
    return window.innerWidth < 441;
  }

  /**
   * Get value from search event
   * @param $event Search event
   */
  getEventValue($event: Event): string {
    this.search = ($event.target as HTMLInputElement).value;
    return ($event.target as HTMLInputElement).value;
  }

  /**
   * Format creation date
   * @param created ISOString
   */
  getCreatedDate(created: string) {
    return moment(created).format("DD. MMM YYYY");
  }

  /**
   * Format updated date if exists
   * @param created ISOString
   */
  getUpdatedDate(created: string) {
    if (created){
      return moment(created).format("DD. MMM YYYY");
    } else {
      return "";
    }
  }

  /**
   * Add new deviation
   */
  addDeviation() {
    this.dialogService.open(AddDeviationComponent, {
      header: this.translateService.instant('DEVIATION.NEW'),
      styleClass: 'min-size-dialog',
      data: {
        itemId: "",
        checklist: "",
        item: "",
        checklistId: "",
        from: "main"
      }
    });
  }

  /**
   * Open comments.
   * @param deviation Deviation object
   */
  openComments(deviation: Deviation) {
    const comments = this.deviationService.getComments(deviation.key);
    this.dialogService.open(CommentModalComponent, {
      header: this.translateService.instant('COMMON.COMMENTS'),
      data: {
        comments: comments,
        id: deviation.key,
        mode: 'deviation'

      },
      styleClass: 'max-size-sm-dialog-long'
    });
  }

  /**
   * Close task
   * @param deviation Deviation object
   */
  closeDeviation(deviation: Deviation) {
    this.userService.getUserUID().pipe(
        take(1),
    ).subscribe(uid => {
      const key = deviation.key;
      delete deviation.key;
      deviation.closedById = uid;
      deviation.closed = moment().toISOString();
      this.deviationService.updateDeviation(key, deviation);
    });

  }
  /**
   * Confirm deletion of station
   */
  confirm(deviation: Deviation) {
      this.confirmationService.confirm({
        message: this.translateService.instant('DEVIATION.CLOSING') + " " + deviation.name,
        acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
        rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
        accept: () => {
          this.closeDeviation(deviation);
        }
      });
  }
  /**
   * Get asset or set label if none are present
   * @param asset AssetId
   */
  getAsset(asset: string) {
    if (asset) {
      return this.assetsMap.get(asset);
    } else {
      return this.translateService.instant('EVENT.EVAL.VARIOUS');
    }
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  editDeviation(deviation: Deviation) {
    this.dialogService.open(AddDeviationComponent, {
      header: this.translateService.instant('DEVIATION.NEW'),
      styleClass: 'min-size-dialog',
      data: {
        deviation: deviation,
        from: "edit"
      }
    });
  }
}
