<!-- Toast -->
<p-toast key='team-detail' position="top-right" styleClass="customToast"></p-toast>
<p-confirmDialog header="{{'WARNING.CONFIRMATION' | translate}}" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="grid">
  <div class="col-12">
    <p-splitButton styleClass="float-right" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-check"
                   (onClick)="save()" [model]="splitButton" class="p-button-raised"></p-splitButton>
  </div>
</div>

<div class="grid">
  <!-- NAME -->
  <div class="col-12">
    <div class="field">
      <label for="name">{{"COMMON.NAME" | translate}}</label>
      <input id="name" type="text" pInputText [(ngModel)]="subject.name" class="inputfield w-full">
    </div>
  </div>
</div>

