import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {map} from "rxjs/operators";
import {DialogService} from "primeng/dynamicdialog";
import {Subscription} from "rxjs";
import {EditCompetencyTypeComponent} from "../edit-competency-type/edit-competency-type.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-competency-types',
  templateUrl: './competency-types.component.html',
  styleUrls: ['./competency-types.component.scss'],
  providers: [DialogService]
})
export class CompetencyTypesComponent implements OnInit, OnDestroy {

  ctList: any[] = [];
  loading = true;

  subscriptions = new Subscription();
  docTypeMap;

  constructor(
    private competencyTypeService: CompetencyTypeService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {

    this.subscriptions.add(
      this.competencyTypeService.getCompetencyTypes().pipe(
        map(competencyTypes => {
          this.loading = true;
          return competencyTypes.map(ct => {
            return {key: ct.key, ...ct.payload.val()};
          }).sort((a,b) => a.name > b.name ? 1 : -1);
        })
      ).subscribe(ctList => {
        for (let i = 0; i < ctList.length; i++) {
          if (ctList[i].archived){
            ctList.splice(i, 1);
            i--;
          }
        }
        this.ctList = ctList;
        this.loading = false;
      })
    );
  }

  ngOnInit(): void {
    this.docTypeMap = new Map<string, string>();
    this.docTypeMap.set('LICENCE', this.translateService.instant("SUBJECTS.DOC_TYPES.LICENCE"));
    this.docTypeMap.set('EDUCATION', this.translateService.instant("SUBJECTS.DOC_TYPES.EDUCATION"));
    this.docTypeMap.set('CERTIFICATE', this.translateService.instant("SUBJECTS.DOC_TYPES.CERTIFICATE"));
    this.docTypeMap.set('COURSE', this.translateService.instant("SUBJECTS.DOC_TYPES.COURSE"));
    this.docTypeMap.set('TOOLS', this.translateService.instant("ASSET.ASSET"));
    this.docTypeMap.set('EXERCISE', this.translateService.instant("EXERCISE.EXERCISE"));
    this.docTypeMap.set('CHECKOUT', this.translateService.instant("SUBJECTS.DOC_TYPES.CHECKOUT"));
    this.docTypeMap.set('TRAINING', this.translateService.instant("SUBJECTS.DOC_TYPES.TRAINING"));
  }

  addCompetencyType() {
    this.dialogService.open(EditCompetencyTypeComponent, {
      header: this.translateService.instant('COMPETENCY.ADD_COMPETENCY_TYPE'),
      styleClass: 'max-size-width-dialog',
      data: {
        competencyType: {},
      }
    });
  }

  editCompetencyType(ct: any) {
    this.dialogService.open(EditCompetencyTypeComponent, {
      header: this.translateService.instant('COMPETENCY.EDIT_COMPETENCY_TYPE'),
      data: {
        competencyType: ct
      },
      styleClass: 'max-size-width-dialog'
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getType(type) {
    return this.docTypeMap.get(type);
  }
}
