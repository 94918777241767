import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {AssetService} from '../../../services/service/asset.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-types',
  templateUrl: './types.component.html',
  styleUrls: ['./types.component.scss']
})
export class TypesComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  assetConnections = new Map();

  // Filter existing
  existingTypes: any[] = [];
  assetTypes: any[] = [];
  typeName: string;

  constructor(
      private assetService: AssetService,
      private messageService: MessageService,
      private translateService: TranslateService,
  ) {
    this.subscription.add(combineLatest([this.assetService.getAssets(), this.assetService.getAssetTypes()])
        .subscribe(([assets, assetTypes]) => {
          this.assetTypes = assetTypes.map(assetType => {
            return {key: assetType.key, name: assetType.payload.val().name};
          });
          assets.forEach(asset => {
            if (this.assetConnections.has(asset.payload.val().type)){
              this.assetConnections.get(asset.payload.val().type).push(asset.key);
            } else {
              this.assetConnections.set(asset.payload.val().type, [asset.key]);
            }
          });
        })
    );
  }

  ngOnInit(): void {
  }

  deleteAssetType(assetType: any) {
    if (this.assetConnections.has(assetType.key)){
      this.messageService.add(
          {
            key: 'error', severity: 'error',
            detail: this.translateService.instant('ARCHIVE.ASSET_ACTIVE_CONNECTION_MESSAGE (2)'), life: 4000}
      );
    } else {
      this.assetService.deleteType(assetType.key);
    }
  }

  /**
   * Add new asset type.
   * @param typeName Name
   */
  addAssetType(typeName: string) {
    if(typeName) {
      let value = 0;
      this.existingTypes.findIndex( type => {
        if (type.name.toLowerCase() === typeName.toLowerCase()){
          value = 1;
          this.typeName = '';
        }
      });
      if (value === 0){
        this.assetService.addAssetType({name: typeName});
        this.typeName = '';
      }
    }
  }

  /**
   * Search for existing type.
   * @param $event Search event.
   */
  searchExisting($event: any) {
    const filtered: any[] = [];
    const query = $event.query;

    this.assetTypes.forEach(type => {
      if (type.name.toLowerCase().indexOf(query.toLowerCase()) === 0){
        filtered.push(type);
      }
    });

    this.existingTypes = filtered;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
