import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {PersonService} from "../../../services/service/person.service";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationService} from "primeng/api";
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {ExerciseService} from "../../../services/service/exercise.service";
import {AttestationService} from "../../../services/service/attestation.service";
import {Exercise} from "../../../model/exercise";
import {CalendarService} from '../../../services/service/calendar.service';

@Component({
  selector: 'app-exercise-approve',
  templateUrl: './exercise-approve.component.html',
  styleUrls: ['./exercise-approve.component.scss']
})
export class ExerciseApproveComponent implements OnInit, OnDestroy {
  exercise: Exercise;
  participants: {key: string, competencyTypes: any, absence: any}[];
  abcenceReason: {key: string, competencyTypes: any, absence: any}[];
  currentPage = 0;
  personMap = new Map();
  competencyTypesMap = new Map();


  subscription = new Subscription();
  stateOptions: any;
  absenceSelect: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private personService: PersonService,
    private authService: AuthService,
    private competencyTypeService: CompetencyTypeService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private exerciseService: ExerciseService,
    private attestationService: AttestationService,
    private calendarService: CalendarService,
  ) {
      this.key = config.data.key;
      this.exercise = JSON.parse(JSON.stringify(config.data.exercise));

      this.absenceSelect = [
        {name: 'Ingen Fravær', value: false},
        {name: 'Avspasering', value: 'Avspasering'},
        {name: 'Egenmelding', value: 'Egenmelding'},
        {name: 'Ferie', value: 'Ferie'},
        {name: 'Kurs', value: 'Kurs'},
        {name: 'Syk', value: 'Syk'},
        {name: 'Sykemeldt', value: 'Sykemeldt'},
        {name: 'Hjemme med sykt barn', value: 'Hjemme med sykt barn'},
        {name: 'Utrykning', value: 'Utrykning'},
        {name: 'Tjenestereise',value: 'Tjenestereise'},
        {name: 'Ureglementert fravær', value: 'Ureglementert fravær'},
        {name: 'Permisjon', value: 'Permisjon'},
        {name: 'Møte', value: 'Møte'},
        {name: 'Vaktbytte', value: 'Vaktbytte'},
        {name: 'Fikk ikke fri', value: 'Fikk ikke fri'},
        {name: 'Annet', value: 'Annet'},
      ];

    this.participants = this.exercise.participants.map(participant => {
      return {key: participant.key, absence: false, competencyTypes: Object.entries(participant.competencyTypes).map(entry => {
          return {key: entry[0], value: entry[1]};
        })};
    });

      this.subscription.add(
        this.personService.getPersons().subscribe(persons => {
          this.personMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
        })
      );

      this.subscription.add(
        this.competencyTypeService.getCompetencyTypes().subscribe(cts => {
          this.competencyTypesMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        })
      );

      this.stateOptions = [
          {icon: 'pi pi-check', value: true},
          {icon: 'pi pi-times', value: false},
      ];

  }

  ngOnInit(): void {
  }

  onPageChange(event: any) {
    this.currentPage = event.page;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAbsenceChange(event: any) {
   if(!event){
     this.participants[this.currentPage].competencyTypes.forEach(ct => ct.value = true);
   } else {
     this.participants[this.currentPage].competencyTypes.forEach(ct => ct.value = false);
   }
  }

  approveExercise() {
    this.confirmationService.confirm({
      key: 'eApprove',
      message: this.translateService.instant('CONFIRMATION.APPROVE_EXERCISE'),
      accept: () => {
        this.authService.getUserUID().pipe(
          take(1),
        ).subscribe(uid => {
          this.exercise.participants = this.participants.map(participant => {
            return {key: participant.key, absence: participant.absence,
              competencyTypes: Object.assign({}, ...participant.competencyTypes.map(ct => {
                return {[ct.key]: ct.value};
              }))};
          });
          this.exercise.completed = true;
          this.exercise.approvedById = uid;
          this.calendarService.deleteCalendarEvent(this.key);
          this.exerciseService.updateExercise(this.key, this.exercise);
          this.attestationService.addFromExercise(this.key, this.exercise);
          this.ref.close(true);
        });
      }
    });
  }
  private readonly key: any;
}
