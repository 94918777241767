import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Checklist, ChecklistItem} from '../../../model/checklist';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {combineLatest, Subscription} from 'rxjs';
import {AccessControlService} from '../../../services/service/access-control.service';
import {ChecklistService} from '../../../services/service/checklist.service';
import {TranslateService} from '@ngx-translate/core';
import {AddNewItemComponent} from '../add-new-item/add-new-item.component';
import {AddChecklistComponent} from '../add-checklist/add-checklist.component';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
  providers: [ConfirmationService]
})
export class EditTemplateComponent implements OnInit, OnDestroy{

  subscription = new Subscription();
  menuItems: MenuItem[];

  userStatus = new Map<string, boolean>();

  template: Checklist;
  checklistKey: string;
  templateItems: any[] = [];
  templateKeyMap = new Map<string, string>();

  constructor(
      private config: DynamicDialogConfig,
      private acService: AccessControlService,
      private checklistService: ChecklistService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private confirmationService: ConfirmationService,
      private ref: DynamicDialogRef,
  ) {
    this.checklistKey = this.config.data.templateKey;

    this.subscription.add(
        combineLatest([this.acService.isSuperUser(), this.acService.isAdmin(),
          this.checklistService.getChecklist(this.config.data.templateKey), this.checklistService.getChecklistTemplatesWithKeys()])
            .subscribe(([isSuperUser, isAdmin, checklist, templates]) => {
              this.userStatus.set('superUser', isSuperUser);
              this.userStatus.set('admin', isAdmin);
              this.template = checklist;

              templates.map(i => this.templateKeyMap.set(i.payload.val().checklistId, i.key));


              // Check if checklist has Items
              if (!checklist.items) {
                this.templateItems = [];
              } else {
                this.templateItems = [];
                Object.keys(checklist.items)
                    .forEach(key => {
                      this.templateItems.push({key: key, item: checklist.items[key]})
                });
              }
        })
    );
  }

  ngOnInit(): void {

    this.menuItems = [
      { label: this.translateService.instant('BUTTON.NEW_ITEM'),
        icon: 'pi pi-plus-circle',
        command: () => {
          this.addItem();
        }
      },
      { label: this.translateService.instant('BUTTON.COPY_TEMPLATE'),
        icon: 'far fa-clone',
        command: () => {
          this.copyTemplate();
        }
      },
      {
        separator: true,
      },
      { label: this.translateService.instant('CHECKLIST.DELETE_CHECKLISTS'),
        icon: 'pi pi-trash',
        command: () => {
          this.confirmDeleteTemplate();
        }
      },

    ];
  }
  /**
   * Confirm deletion of checklistItem
   */
  confirmDeleteItem(element: {key: string, item: ChecklistItem}) {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE_ITEM') + ": " + element.item.name,
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.deleteItem(element.key);
      }
    });
  }
  /**
   * Confirm deletion of checklistItem
   */
  confirmDeleteTemplate() {
    this.confirmationService.confirm({
      message: this.translateService.instant('CHECKLIST.DELETE_CHECKLISTS') + ": " + this.template.name,
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.deleteTemplate(this.templateKeyMap.get(this.checklistKey), this.checklistKey);
      }
    });
  }
  /**
   * Delete item from template.
   * @param key ItemId
   */
  deleteItem(key: string) {
    this.checklistService.deleteChecklistItem(this.checklistKey, key);
  }

  /**
   * Add new item to template
   */
  addItem() {
    this.dialogService.open(AddNewItemComponent, {
      header: this.translateService.instant('BUTTON.NEW_ITEM'),
      styleClass: 'max-size-sm-dialog',
      data: {
        checklistId: this.checklistKey,
      }
    });
  }

  /**
   * Copy template
   */
  copyTemplate() {
    this.dialogService.open(AddChecklistComponent, {
      header: this.translateService.instant('BUTTON.COPY_TEMPLATE'),
      styleClass: 'max-size-sm-dialog',
      data: {
        checklist: this.template,
        index: 2,
      }
    });
    this.ref.close()
  }

  /**
   * Delete template
   */
  deleteTemplate(templateID: string, checklistID: string) {
    this.checklistService.deleteTemplate(templateID);
    this.checklistService.deleteChecklist(checklistID);
    this.ref.close();
  }

  /**
   * Edit template Item
   * @param element Object containing item key and item.
   *                {key: string, item: ChecklistItem}
   */
  editItem(element: {key: string, item: ChecklistItem}) {
    this.dialogService.open(AddNewItemComponent, {
      header: this.translateService.instant('BUTTON.NEW_ITEM'),
      styleClass: 'max-size-sm-dialog',
      data: {
        checklistId: this.checklistKey,
        element: element,
      }
    });
  }

  /**
   * Unsubscribe on close
   */
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
