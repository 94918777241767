<div class="grid" *ngIf="!loading">
    <div class="col-6 mt-4">
        <span class="p-float-label">
            <input [ngModel]="getDate()"
                   class="w-full" disabled id="sent" pInputText type="text">
            <label for="sent">{{'SMS.SENT' | translate}}</label>
        </span>
    </div>
    <div class="col-6 mt-4">
        <span class="p-float-label">
            <input [ngModel]="personsUIDNameMap.get(sms.createdBy)"
                   class="w-full" disabled id="sentBy" pInputText type="text">
            <label for="sentBy">{{'SMS.SENT_BY' | translate}}</label>
        </span>
    </div>
    <div class="col-12 mt-2">
        <span class="p-float-label">
            <textarea [cols]="30" [ngModel]="getRecipients()" [rows]="5"
                      class="inputfield w-full" disabled id="recipients" pInputTextarea style="resize: none;"></textarea>
            <label for="recipients">{{'SMS.RECIPIENTS' | translate}}</label>
        </span>
    </div>
    <div class="col-12 mt-2">
        <span class="p-float-label">
            <textarea [cols]="30" [ngModel]="sms.message" [rows]="5"
                      class="inputfield w-full" disabled id="message" pInputTextarea style="resize: none;"></textarea>
            <label for="message">{{'EXERCISE.MESSAGE' | translate}}</label>
        </span>
    </div>
</div>
