<!-- Toast -->
<p-toast key='ct' position="top-right" styleClass="customToast"></p-toast>

<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>


<div class="grid">
  <!-- NAME -->
  <div class="col-6 mt-3">
    <span class="p-float-label">
      <input [ngStyle]="checkName()" id="ctName" type="text" [(ngModel)]="competencyType.name" pInputText class="inputfield w-full">
      <label for="ctName">{{'COMMON.NAME' | translate}}</label>
    </span>
  </div>

  <!-- Doc type -->
  <div  class="col-6 mt-3">
    <p-dropdown [options]="documentationTypes" [(ngModel)]="competencyType.type"
                placeholder="{{'COMPETENCY.TYPE' | translate}}*"
                optionValue="value" optionLabel="label"
                [style]="{'width' : '100%'}">
    </p-dropdown>
  </div>

  <!-- Notification Period -->
  <div class="col-12 mt-2">
      <div class="p-inputgroup">
        <span class="p-float-label">
          <input [ngStyle]="checkValue(competencyType.timeToExpire)" [min]="0" id="timeToExpire" type="number" [(ngModel)]="competencyType.timeToExpire" pInputText class="inputfield w-full">
          <span class="p-inputgroup-addon">{{'COMMON.MONTHS.MONTHS' | translate}}</span>
          <label for="timeToExpire">{{'COMPETENCY.NOTIFICATION_PERIOD' | translate}}</label>
        </span>
      </div>
  </div>

  <!-- Validity -->
  <div class="col-12 mt-2">
      <div class="p-inputgroup">
        <span class="p-float-label">
          <input [ngStyle]="checkValue(competencyType.defaultValidity)" [min]="0" id="validity" type="number" [(ngModel)]="competencyType.defaultValidity" pInputText class="inputfield w-full">
          <span class="p-inputgroup-addon">{{'COMMON.MONTHS.MONTHS' | translate}}</span>
          <label for="validity">{{'COMPETENCY.VALIDITY' | translate}}</label>
        </span>
      </div>
  </div>
</div>

<!-- BUTTONS -->
<div class="grid">
  <div class="col-12 flex justify-content-end">
    <button pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"  (click)="close()"
            class="p-button-outlined float-right"></button>
    <button *ngIf="newCT" [disabled]="!competencyType.name || !competencyType.type" class="p-button-raised"
              pButton label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark" (click)="save()"></button>
    <p-splitButton *ngIf="!newCT" appendTo="body"
                   styleClass="float-right" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark" (onClick)="save()"
                   [model]="splitItems"></p-splitButton>
  </div>
</div>
