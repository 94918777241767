<!-- Toast -->
<p-toast key='error' position="top-right" styleClass="customToast"></p-toast>

<div class="grid">
  <div class="col-12 p-0">
    <!-- Role table -->
    <p-table [value]="roleTypes" dataKey="name" responsiveLayout="block" breakpoint="200px">

      <!-- Add bar -->
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-10">
            <p-autoComplete [(ngModel)]="role" [suggestions]="existingRoles"
                            (completeMethod)="searchExisting($event)"
                            field="name" [minLength]="1">
            </p-autoComplete>
          </div>
          <div class="col-2">
            <button pButton type="button" icon="pi pi-plus" (click)="addRole(role)" [disabled]="!role"
                    class="p-button-rounded p-button-raised"></button>
          </div>
        </div>
      </ng-template>

      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'ROLES.ROLES' | translate}}</th>
          <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body" let-role>
        <tr>
          <td>{{role.name}}</td>
          <td (click)="deleteRole(role)">
        <span class="flex justify-content-center">
          <i class="pi pi-trash" style="color: #D0021B"></i>
        </span>
          </td>
        </tr>
      </ng-template>

      <!-- Shows message if table is empty -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">
            {{'ROLES.NO_ROLES' | translate}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
