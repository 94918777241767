import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {map, mergeMap, pluck, publish, share} from "rxjs/operators";
import {Deviation} from "../../model/deviation";
import {ChecklistDeviation} from "../../model/checklist";

@Injectable({
  providedIn: 'root'
})
/**
 * Handles everything about Deviation
 */
export class DeviationService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get OpenDeviations as Value (No Key)
   */
  getOpenDeviations(): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/deviations`, ref => {
          return ref.orderByChild('closed').equalTo(null);
        }).valueChanges()
      )
    );
  }

  /**
   * Get open deviations as payload
   */
  getOpenDeviationsPayload(): Observable<any>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/deviations`).snapshotChanges()
      )
    );
  }

  /**
   * Get OpenDeviations as a map to check if asset have open deviation.
   */
  getOpenDeviationsAsMap(): Observable<any>{
    return this.getOpenDeviations().pipe(
      map(openDeviations => {
        const deviationMap = new Map();
        openDeviations.forEach(deviation => {
          deviationMap.set(deviation.asset, true);
        });
        return deviationMap;
      })
    );
  }

  /**
   * @param deviation
   * @return new deviation id;
   */
  addDeviation(deviation: Deviation){
    const newDeviation = this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/deviations`).push(deviation)),
      pluck('key'),
      share(),
    );
    publish()(newDeviation).connect();
    return newDeviation;
  }

  addChecklistDeviation(deviationId, checklistId, itemId) {
    const checklistDeviation: ChecklistDeviation = {
      checklistId: checklistId, deviationId: deviationId, itemId: itemId
    };
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/checklistDeviations`).push(checklistDeviation)
      )
    )).connect();
  }

  getDeviation(deviationId): Observable<Deviation> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/deviations/${deviationId}`).valueChanges()
      )
    ) as Observable<Deviation>;
  }

  setComments(id, comments: any[]) {
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.object(`tenants/${tenantId}/deviations/${id}/comments`).set(comments)
      )
    )).connect();
  }

  getComments(deviationId) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/deviations/${deviationId}/comments`).valueChanges()
      )
    ) as Observable<any>;
  }

  updateDeviation(deviationId: string, deviation: Deviation) {
    if (deviationId) {
      return publish()(this.tenantId$.pipe(
        map(
          tenantId => this.db.object(`tenants/${tenantId}/deviations/${deviationId}`).update(deviation)
        )
      )).connect();
    }
  }

  isClosed(deviationId) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/deviations/${deviationId}/closed`).valueChanges()
      )
    ) as Observable<any>;
  }

  getDeviations(): Observable<any> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/deviations`).snapshotChanges()
      )
    );
  }

}
