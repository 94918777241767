import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSubject} from "../../../model/subject";
import {Subscription} from "rxjs";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {SubjectService} from "../../../services/service/subject.service";
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {TranslateService} from "@ngx-translate/core";
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-station-detail',
  templateUrl: './station-detail.component.html',
  styleUrls: ['./station-detail.component.scss'],
  providers: [ConfirmationService]
})
/**
 * These components handle the showing and editing of a stations details
 */
export class StationDetailComponent implements OnInit, OnDestroy {
  subject: BaseSubject = {
    name: "", type: ""
  };
  oldSubject;
  subscription = new Subscription();
  subjectId;
  splitButton: MenuItem[];
  connections = false;

  constructor(
      public ref: DynamicDialogRef, public config: DynamicDialogConfig,
      private subjectService: SubjectService,
      private messageService: MessageService,
      private translateService: TranslateService,
      private confirmationService: ConfirmationService
  ) {
    this.subjectId = this.config.data.subjectId;

    this.subscription.add(
        this.subjectService.getPerson(this.subjectId).subscribe(subject => {
          this.subject = {...subject};
          this.oldSubject = {...subject};
        })
    );
    // Build split button on create.
    this.buildSplitButton();


    // Update split button on language change
    this.subscription.add(
        this.translateService.onLangChange.pipe().subscribe(() => {
          this.buildSplitButton();
        })
    );

    this.subscription.add(this.subjectService.getSubjectsChildren(this.subjectId).pipe(take(1))
        .subscribe(result => {this.connections = result.length > 0;})
    );
  }

  ngOnInit(): void {
  }

  /**
   * Save addedParticipants and send a signal to subject list to update nodes.
   */
  save() {
    if(!(JSON.stringify(this.subject) === JSON.stringify(this.oldSubject))){
      this.subjectService.updateSubject(this.subjectId, this.subject);
      this.ref.close(this.subjectId);
    } else {
      this.messageService.add(
          {key: 'station-detail', severity: 'warn', detail: this.translateService.instant('WARNING.NO_DATA_CHANGED'), life: 4000}
      );
    }
  }

  /**
   * Confirm deletion of station
   */
  confirm() {
    if (!this.connections) {
      this.confirmationService.confirm({
        message: this.translateService.instant('WARNING.REMOVE') + " " + this.subject.name,
        acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
        rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
        accept: () => {
          this.delete();
        }
      });
    } else {
      this.messageService.add(
          {
            key: 'station-detail', severity:'error', summary: 'Error', life: 2000,
            detail: this.translateService.instant('WARNING.CANNOT_DELETE')
          }
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private buildSplitButton() {
    this.splitButton = [
        {
          label: this.translateService.instant('BUTTON.REMOVE'),
          icon: 'pi pi-trash',
          command: () => {
            this.confirm();
          }
        }];
  }

  private delete() {
    this.subjectService.deleteSubject(this.subjectId);
    this.subjectService.removeRelationWithChildId(this.subjectId);
    this.ref.close();
  }
}
