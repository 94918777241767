import {Component, OnDestroy, OnInit} from '@angular/core';
import {SmsService} from '../../../services/service/sms.service';
import {SubjectService} from '../../../services/service/subject.service';
import {TenantService} from '../../../services/service/tenant.service';
import {combineLatest, Subscription} from 'rxjs';
import {AccessControlService} from '../../../services/service/access-control.service';
import {OrderSMS} from '../../../model/orderSMS';
import * as moment from 'moment';
import {MessageService} from 'primeng/api';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-order-sms',
  templateUrl: './order-sms.component.html',
  styleUrls: ['./order-sms.component.scss'],
  providers: [MessageService]
})
export class OrderSMSComponent implements OnInit, OnDestroy {

  subscription = new Subscription();

  smsOrder: OrderSMS = {
    numberOfSMS: null,
    tenantName: "",
    tenantId: "",
    orderDate: "",
    orderedByName: "",
    orderedById: "",
  }
  amountOptions: any[];

  constructor(
      private smsService: SmsService,
      private subjectService: SubjectService,
      private tenantService: TenantService,
      private acService: AccessControlService,
      private ref: DynamicDialogRef,
  ) {
    this.subscription.add(combineLatest(
        [this.tenantService.getTenantId(), this.tenantService.getTenantName(), this.acService.getSubjectId()])
        .subscribe(([tenantId, tenantName, subjectId]) => {
          this.smsOrder.orderedById = subjectId;
          this.smsOrder.tenantId = tenantId;
          this.smsOrder.tenantName = tenantName;
          this.subscription.add(this.subjectService.getPerson(subjectId).subscribe(
              subject => {this.smsOrder.orderedByName = subject.name;})
          );
        })
    );
  }

  ngOnInit(): void {
    this.amountOptions = [
      {name: '500', value: 500},
      {name: '1000', value: 1000},
      {name: '1500', value: 1500},
      {name: '2000', value: 2000},
    ];
  }

  /**
   * Place sms order in database
   */
  orderSMS() {
    this.smsOrder.orderDate = moment().toISOString();
    this.smsService.placeOrder(this.smsOrder);
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
