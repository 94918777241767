<!-- Toast -->
<p-toast key='error' position="top-right" styleClass="customToast"></p-toast>

<!-- ConfirmDialog -->
<p-confirmDialog
        [baseZIndex]="10000" [style]="{width: '50vw'}"
        header="{{'WARNING.CONFIRMATION' | translate}}"
        icon="pi pi-exclamation-triangle"
        rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>

<div class="grid">
    <div class="col-12 p-0">
        <p-table (onLazyLoad)="loadFileTags($event)" [lazy]="true" [loading]="loading" [paginator]="true" [rows]="11"
                 [totalRecords]="totalRecords" [value]="tableTags" responsiveLayout="scroll"
                 dataKey="name"
                 >

            <!-- Add bar -->
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-10">
                        <p-autoComplete (completeMethod)="searchExisting($event)" [(ngModel)]="tagName"
                                        [inputStyle]="{'width':'100%'}"
                                        [minLength]="1" [style]="{'width':'100%'}"
                                        [suggestions]="existingTags"
                                        field="tagName" placeholder="{{'FILE.ADD_CATEGORY' | translate}}">
                        </p-autoComplete>
                    </div>
                    <div class="col-2 flex justify-content-end">
                        <button (click)="confirm(tagName)" [disabled]="!tagName" class="p-button-rounded" icon="pi pi-plus"
                                pButton type="button"></button>
                    </div>
                </div>
            </ng-template>

            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'FILE.CATEGORIES' | translate}}</th>
                    <th style="width: 50px"><i class="far fa-archive"></i></th>
                </tr>
            </ng-template>

            <!-- Body -->
            <ng-template pTemplate="body" let-tag>
                <tr>
                    <td>{{tag.tagName}}</td>
                    <td (click)="confirm(tagName, tag)">
                        <span class="flex justify-content-center">
                            <i class="far fa-archive"></i>
                        </span>
                    </td>
                </tr>
            </ng-template>

            <!-- Shows message if table is empty -->
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        {{'FILE.NO_CATEGORIES' | translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

