import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {FilterService} from 'primeng/api';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {map, mergeMap, publish} from 'rxjs/operators';
import {Roles, RoleType} from '../../model/role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private filterService: FilterService,
    private db: AngularFireDatabase
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  // ROLES
  /**
   * Get all Roles
   */
  getRoles(): Observable<any[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/roles/role`).snapshotChanges()
      )
    );
  }

  /**
   * Add new Role
   * @param role: Role
   */
  addRole(role: Roles){
    return publish()(this.tenantId$.pipe(
      map(tenantId => this.db.list(`tenants/${tenantId}/roles/role`).push(role))
    )).connect();
  }

  /**
   * Delete role
   * @param role Role to delete
   */
  deleteRole(role: Roles) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/roles/role/${role.key}`).remove()
      )
    )).connect();
  }

  // ROLE TYPES

  /**
   * Get all Role types
   */
  getRoleTypes(): Observable<any[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/roles/roleType`).snapshotChanges()
      )
    );
  }

  /**
   * Add new Role type
   * @param roleType: Role type
   */
  addRoleType(roleType: RoleType) {
    return publish()(this.tenantId$.pipe(
      map(tenantId => this.db.list(`tenants/${tenantId}/roles/roleType`).push(roleType))
    )).connect();
  }

  /**
   * Delete role type
   * @param roleType Role type to delete
   */
  deleteRoleType(roleType: RoleType) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.object(`tenants/${tenantId}/roles/roleType/${roleType.key}`).remove()
      )
    )).connect();
  }

}
