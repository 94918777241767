<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-circle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12">

    <!-- Exposure form -->
    <form [formGroup]="exposureForm" (ngSubmit)="onSubmit()">
      <div class="formgrid grid">

        <!-- Date -->
        <div class="field col-6">
          <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                      dateFormat="dd.mm.yy"
                      [style]="{'width' : '100%'}"
                      class="w-full inputfield"
                      formControlName="date"
          ></p-calendar>
        </div>

        <!-- Select type -->
        <div class="col-6">
          <p-dropdown
            placeholder="{{'EVENT.EXPOSURE' | translate}}*"
            [options]="exposureTypes"
            formControlName="exposureType"
            optionLabel="label"
            optionValue="key"
            [style]="{'width' : '100%'}"
            class="w-full"
          ></p-dropdown>
        </div>

        <!-- Time From -->
        <div class="field col-6">
          <label for="timeStart">{{'COMMON.FROM' | translate}}*</label>
          <input type="time"
                 id="timeStart"
                 pInputText
                 class="inputfield w-full"
                 formControlName="timeStart"
          >
        </div>

        <!-- To Time -->
        <div class="field col-6">
          <label for="timeEnd">{{'COMMON.TO' | translate}}*</label>
          <input type="time"
                 id="timeEnd"
                 pInputText
                 class="inputfield w-full"
                 formControlName="timeEnd"
          >
        </div>

        <!-- Address -->
        <div class="field col-12 mt-2">
          <span class="p-float-label">
            <input type="text" id="address"
                   pInputTextarea
                   class="inputfield w-full"
                   formControlName="address">
            <label for="address">{{'SUBJECTS.ADDRESS' | translate}}*</label>
          </span>
        </div>

        <!-- Zip -->
        <div class="field col-6 mt-2">
          <span class="p-float-label">
            <input type="text" id="zip"
                   pInputTextarea
                   class="inputfield w-full"
                   formControlName="zip">
            <label for="zip">{{'SUBJECTS.ZIP' | translate}}</label>
          </span>
        </div>

        <!-- Postal -->
        <div class="field col-6 mt-2">
          <span class="p-float-label">
              <input type="text" id="postal"
                     pInputTextarea
                     class="inputfield w-full"
                     formControlName="postal">
            <label for="postal">{{'SUBJECTS.POSTAL' | translate}}</label>
          </span>
        </div>

        <!-- Description -->
        <div class="field col-12 mt-2">
          <span class="p-float-label">
            <textarea id="textarea"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      pInputTextarea
                      formControlName="details"
                      class="inputfield w-full"></textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
          </span>
        </div>

        <!-- Bris number -->
        <div class="field col-12 mt-2">
            <span class="p-float-label">
            <input type="text" id="bris"
                   pInputTextarea
                   class="inputfield w-full"
                   formControlName="brisNumber">
            <label for="bris">{{'EVENT.BRIS' | translate}}</label>
          </span>
        </div>
      </div>

      <!-- Add roles & evaluations -->
      <div class="flex justify-content-start mb-2">
        <span class="p-buttonset">
          <button pButton type="button" label="{{'COMMON.EVALUATION' | translate}}"
                  icon="far fa-clipboard-check"
                  (click)="addEvaluation()"
          ></button>
          <button pButton type="button" label="{{'ROLES.ROLES' | translate}}"
                  icon="far fa-clipboard-user"
                  [disabled]="personArray.length < 1"
                  (click)="addRoles()"
          ></button>
        </span>
      </div>

      <!-- Person table -->
      <p-table responsiveLayout="block"
               breakpoint="200px"
               [value]="personArray">

        <!-- Search bar -->
        <ng-template pTemplate="caption" class="pt-5">
          <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                          [(ngModel)]="selectedPerson"
                          (onSelect)="addPerson(selectedPerson)"
                          [ngModelOptions]="{standalone: true}"
                          placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}"
                          [dropdown]="true"
                          (completeMethod)="filterPerson($event)"
                          size="100%"
                          field="label"
                          type="search"
                          scrollHeight="400px"
                          class="inputfield w-full"
                          appendTo="body"
                          [forceSelection]="true"
                          [group]="true"
          >

            <ng-template let-team pTemplate="group">
              <div class="flex align-items-center p-2" (click)="addTeam(team)">
                <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
              </div>
            </ng-template>
            <ng-template let-subject pTemplate="item">
              <div class="person-item">
                {{subject.label}}
              </div>
            </ng-template>

          </p-autoComplete >
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header">
          <tr>
            <th>{{'FDC.PERSONS' | translate}}</th>
            <th>
              <div *ngIf="exposureForm.value.exposureType === 'Sot' || exposureForm.value.exposureType === 'Royk' ">
                <span class="flex justify-content-center">
                  <i class="far fa-stopwatch"></i>
                </span>
              </div>
            </th>
            <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
          </tr>
        </ng-template>

        <!-- Body -->
        <ng-template pTemplate="body" let-personId>
          <tr>
            <td>
              {{personNameMap.get(personId)}}
            </td>
              <td (click)="exposureTime(personId)" class="flex justify-content-center">
                <div *ngIf="exposureForm.value.exposureType === 'Sot' || exposureForm.value.exposureType === 'Royk'">
                  {{printTime(personId)}}
                </div>
              </td>

            <td (click)="deletePerson(personId)">
              <span class="flex justify-content-center">
                <i class="pi pi-trash" style="color: #D0021B"></i>
              </span>
            </td>
          </tr>
        </ng-template>

        <!-- Shows message if table is empty -->
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">
              {{'WARNING.NO_DATA' | translate}}
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="col-12 flex justify-content-end">
        <p-button label="{{'BUTTON.SAVE' | translate}}"
                  icon="pi pi-check"
                  iconPos="right"
                  [disabled]="!exposureForm.valid || personMap.size < 1"
                  (onClick)="onSubmit()"
        ></p-button>
      </div>
    </form>
  </div>
</div>
