import { Component, OnInit } from '@angular/core';
import {DialogService, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {AccessControlService} from '../../../services/service/access-control.service';
import {Exercise} from '../../../model/exercise';
import {Subscription} from 'rxjs';
import {PersonService} from '../../../services/service/person.service';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';
import {ExerciseEvaluationComponent} from '../exercise-evaluation/exercise-evaluation.component';
import {ExerciseFilesComponent} from "../exercise-files/exercise-files.component";

@Component({
  selector: 'app-exercise-completed-detail',
  templateUrl: './exercise-completed-detail.component.html',
  styleUrls: ['./exercise-completed-detail.component.scss']
})
export class ExerciseCompletedDetailComponent implements OnInit {

  subscription = new Subscription();
  innerWidth = window.innerWidth;

  participants: any[] = [];
  personNameMap: Map<string, string>;

  exercise: Exercise;

  competencyTypeMap: Map<string, string>;
  competencyTypeArray: any[] = [];

  constructor(
    private personService: PersonService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private config: DynamicDialogConfig,
    private competencyTypeService: CompetencyTypeService,
    private dialogService: DialogService,
  ) {

    this.exercise = this.config.data.exercise;

    this.subscription.add(
      this.competencyTypeService.getCompetencyTypes().subscribe(competencyTypes => {
        this.competencyTypeMap = new Map(competencyTypes.map(type => [type.key, type.payload.val().name]));
      })
    );

    if (this.exercise.competencyTypes) {
      for (const competencyType of Object.entries(this.exercise.competencyTypes)){
        this.competencyTypeArray.push({type: competencyType[0], completed: competencyType[1]});
      }
    }

    this.subscription.add(
      this.personService.getPersons().subscribe(persons => {
        this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));

        function competencyCounter(competencyTypes) {
          if (!competencyTypes){ return 0; }
          let counter = 0;
          for (const entry of Object.entries(competencyTypes)) {
            if (entry[1] === true){
              counter++;
            }
          }
          return counter;
        }

        function generateMap(competencyTypes) {
          const competencyMap = new Map<string, boolean>();
          if (competencyTypes) {
            for (const competencyTypeList of Object.entries(competencyTypes)) {
              competencyMap.set(competencyTypeList[0], <boolean> competencyTypeList[1]);
            }
          }
          return competencyMap;
        }

        const pCT = [];
        const ctKeys = [];
        if (this.exercise.participants){
          // tslint:disable-next-line:forin
          for (const participantsKey in this.exercise.participants) {
            if (this.exercise.participants[participantsKey].competencyTypes){
              for (const competencyTypeList of Object.entries(this.exercise.participants[participantsKey].competencyTypes)) {
                if (!ctKeys.includes(competencyTypeList[0])){
                  ctKeys.push(competencyTypeList[0]);
                  pCT.push({type: competencyTypeList[0], completed: true});
                }
              }
            }
            this.participants.push({
              key: participantsKey,
              name: this.personNameMap.get(this.exercise.participants[participantsKey].key),
              absence: this.exercise.participants[participantsKey].absence,
              competencyCount: competencyCounter(this.exercise.participants[participantsKey].competencyTypes),
              competencyTypes: generateMap(this.exercise.participants[participantsKey].competencyTypes),
            });
          }
        }
        if (pCT.length > this.competencyTypeArray.length){
          this.competencyTypeArray = pCT;
        }
      })
    );
  }

  ngOnInit(): void {

  }
  /**
   * Get absence from boolean value
   * @param absence boolean
   * @private
   */
  getAbsence(absence){
    if (absence === true){
      return this.translateService.instant("CONFIRMATION.YES");
    } else if (absence === false){
      return this.translateService.instant("CONFIRMATION.NO");
    } else {
      return absence;
    }
  }
  /**
   * Compare person competency count to exercise competency count.
   * @param participant A participant.
   */
  completedAll(participant) {
    let counter = 0;
    if (this.competencyTypeArray) {
      for (const competencyType of this.competencyTypeArray) {
        if (!this.competencyTypeMap.get(competencyType.type).includes(this.translateService.instant("COMMON.INSTRUCTOR"))){
          counter++;
        }
      }
    }
    let instructor = 0;
    for (const competencyTypeList of participant.competencyTypes) {
      if (this.competencyTypeMap.get(competencyTypeList[0]).includes(this.translateService.instant("COMMON.INSTRUCTOR"))
        && competencyTypeList[1] === true){
        instructor = counter;
      }
    }
    if (instructor !== 0){
      return instructor;
    } else {
      return counter === participant.competencyCount;
    }
  }

  /**
   * See Evaluation
   */
  evalInfo(exercise: Exercise){
    this.dialogService.open(ExerciseEvaluationComponent, {
      header: this.translateService.instant('EXERCISE.EVALUATION.EVALUATION'),
      styleClass: 'max-size-width-dialog',
      data: {
        exercise: exercise,
        fromInfo: true,
      }
    });
  }

  screenSize() {
    return innerWidth >= 414;
  }

  /**
   * View exercise files
   * @param exercise exercise object
   */
  viewFiles(exercise: Exercise) {
    this.dialogService.open(ExerciseFilesComponent, {
      header: this.translateService.instant('FILE.FILES'),
      styleClass: 'max-size-width-dialog',
      data: {
        exercise: exercise,
        fromInfo: true,
      }
    });
  }
}
