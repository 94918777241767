import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SmsService} from '../../../services/service/sms.service';
import * as moment from 'moment';
import {SendSMS} from '../../../model/sendSMS';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ViewSmsComponent} from '../view-sms/view-sms.component';
import {SMSFilterPipe} from '../../../pipes/smsfilter.pipe';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss'],
  providers: [DialogService, SMSFilterPipe]
})
export class SmsComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  allSMS: SendSMS[];
  sms: SendSMS[];
  dateRange: any[];
  search: string[];

  loading = true;

  constructor(
      private smsService: SmsService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private SMSFilter: SMSFilterPipe,
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.smsService.getAllSMS().subscribe(
        smsPayload => {

          this.sms = smsPayload.map(sms => {
            return {key: sms.key, ...sms.payload.val()};
          }).sort((a, b) =>{
            const aDate = moment(a.date);
            const bDate = moment(b.date);
            return bDate.diff(aDate);
          });

          this.allSMS = this.sms;
          this.loading = false;
        })
    );
  }

  /**
   * Format date
   * @param date ISO string
   */
  getDate(date: string) {
    return moment(date).format("DD.MM.YYYY");
  }

  /**
   * View SMS
   * @param sms Object
   */
  smsDetails(sms: SendSMS) {
    this.dialogService.open(ViewSmsComponent, {
      header: this.translateService.instant('SMS.SMS_INFO'),
      styleClass: 'small-size-dialog',
      data: {
        sms: sms,
      }
    });
  }

  /**
   * Filter sms array by date span
   */
  filterSMS() {
    if (this.dateRange) {
      this.sms = this.SMSFilter.transform(this.allSMS, this.dateRange);
    }
  }

  /**
   * unsubscribe on destroy.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
