import {Component, OnDestroy, OnInit} from '@angular/core';
import * as moment from 'moment';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {ExposureService} from '../../../services/service/exposure.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {ExposureAddComponent} from '../exposure-add/exposure-add.component';
import {Exposure} from '../../../model/exposure';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {ExposureReadComponent} from '../exposure-read/exposure-read.component';
import {AccessControlService} from '../../../services/service/access-control.service';
import {ExposureEval} from '../../../model/exposureEval';
import {ExposureEditComponent} from '../exposure-edit/exposure-edit.component';
import {SubjectService} from '../../../services/service/subject.service';
import {PersonService} from '../../../services/service/person.service';
import {EventFilterPipe} from '../../../pipes/event-filter.pipe';
import {EventStationSortService} from "../../../services/sharedService/event-station-sort.service";

@Component({
  selector: 'app-exposure',
  templateUrl: './exposure.component.html',
  styleUrls: ['./exposure.component.scss'],
  providers: [DialogService, EventFilterPipe],
})

export class ExposureComponent implements OnInit, OnDestroy{

  // Teams
  groupedFilter: any[] = [];
  teamNameMap = new Map();
  parentChildMap = new Map();
  personParentNameMap = new Map();
  personNameMap: Map<string, string>;
  exposuresMap = new Map<string, Exposure[]>();

  exposures: Exposure[];
  exposureTypes: any[];
  evaluations: ExposureEval[];

  subscription = new Subscription();
   isAdmin$: Observable<boolean>;

  selectedStation = '';
  search = '';
  stationNameMap = new Map();

  constructor(
    private exposureService: ExposureService,
    private evaluationService: EvaluationService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private subjectService: SubjectService,
    private personService: PersonService,
    private eventFilter: EventFilterPipe,
    private esSort: EventStationSortService,
  ) {

    this.isAdmin$ = this.acService.isAdmin();

    this.exposureTypes = this.exposureService.getExposureTypes();

    this.subscription.add(
      combineLatest([
        this.personService.getPersons(),
        this.subjectService.getTeams(),
        this.subjectService.getRelations(),
        this.personService.getOwnTeam(),
        this.exposureService.getExposures(),
        this.evaluationService.getExposureEvaluations(),
        this.subjectService.getStations(),

      ]).subscribe(([persons, teams, relations, ownTeam, eventExposures, evaluations, stations]) => {


        this.exposures = eventExposures.map(exposure => {
          return {key: exposure.key, ...exposure.payload.val()};
        }).sort((a, b) =>{
          const aDate = moment(a.date.split('.').reverse().join("-") + " " + a.timeStart);
          const bDate = moment(b.date.split('.').reverse().join("-") + " " + b.timeStart);
          return bDate.diff(aDate);
        });

        this.evaluations = evaluations.map(evaluation => {
          return {key: evaluation.key, ...evaluation.payload.val()};
        });

        this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
        this.teamNameMap = new Map(teams.map(team => [team.key, team.payload.val().name]));
        this.stationNameMap = new Map(stations.map(station => [station.key, station.payload.val().name]));

        this.parentChildMap = new Map();
        this.groupedFilter = [];

        const rData = this.esSort.sortOnStation(this.exposures, relations, this.stationNameMap, this.teamNameMap, this.personNameMap);
        this.groupedFilter = Array.from(rData[0]);
        this.exposuresMap = new Map(rData[1]);
        const teamStationMap = new Map(rData[2]);

        this.groupedFilter.unshift({label: this.translateService.instant('ROLES.ALL_STATIONS'), value: 'All'});

        if(ownTeam){
          this.selectedStation = teamStationMap.get(ownTeam.key);
        } else {
          this.selectedStation = 'All';
        }
        this.setStationFilter();
      })
    );
  }

  ngOnInit(): void {
  }


  /**
   * Build persons station object
   * @param subject Person
   */
  buildFilter(subject) {
    return {
      label: this.personNameMap.get(subject),
      value: subject,
    };
  }

  /**
   * Add station filter
   */
  setStationFilter() {
    this.exposures = this.exposuresMap.get(this.selectedStation);
  }

  /**
   * Count number of participants
   * @param participants Participant array
   */
  numberOfCrews(participants: any){
    if (participants === undefined){
      return 0;
    }
    return participants.length;
  }

  /**
   * Add new Exposure
   */
  addExposure() {
    this.dialogService.open(ExposureAddComponent, {
      header: this.translateService.instant('EVENT.NEW_EXPOSURE'),
      styleClass: 'max-size-width-dialog',
    });
  }

  /**
   * More info on exposure
   * @param exposure Exposure object
   */
  moreInfo(exposure: Exposure) {

    let thisEvaluation: ExposureEval;
    for (const e of this.evaluations) {
      if (e.exposureId === exposure.key){
        thisEvaluation = e;
      }
    }

    this.dialogService.open(ExposureReadComponent, {
      header: this.translateService.instant('EVENT.EXPOSURE') + ": " + this.getTypeName(exposure),
      styleClass: 'max-size-width-dialog',
      data: {
        exposure: exposure,
        exposureType: this.getTypeName(exposure),
        evaluation: thisEvaluation
      }
    });
  }

  /**
   * Edit exposure if newer than 7 days
   * @param exposure Exposure object
   */
  editInfo(exposure: Exposure){
    let thisEvaluation: ExposureEval;
    for (const e of this.evaluations) {
      if (e.exposureId === exposure.key){
        thisEvaluation = e;
      }
    }

    this.dialogService.open(ExposureEditComponent, {
      header: this.translateService.instant('EVENT.EXPOSURE') + ": " + this.getTypeName(exposure),
      styleClass: 'max-size-width-dialog',
      closable: false,
      data: {
        exposure: exposure,
        evaluation: thisEvaluation,
        groupedFilter: this.groupedFilter,
        personNameMap: this.personNameMap,
      }
    });
  }

  /**
   * Get exposure translated type name
   * @param exposure Exposure object
   */
  getTypeName(exposure: Exposure){
    let type = '';
    this.exposureTypes.forEach(t => {
      if (t.key === exposure.exposureType){
        type = t.label;
      }
    });
    return type;
  }

  /**
   * Check if exposure is newer than 7 days.
   * @param exposure Exposure object
   */
  expired(exposure) {
    if (exposure.dateCreated) {
      return moment(moment()).diff(exposure.dateCreated, 'days') < 7;
    } else {
      return moment(moment()).diff(new Date(exposure.date.split('.').reverse().join('-')), 'days') < 7;
    }
  }

  /**
   * Filter emergencies on name/address
   * @param search Search string
   */
  exposureFilter(search: string) {
    this.exposures = this.eventFilter.transform(this.exposuresMap.get(this.selectedStation), this.personNameMap, search);
  }

  /**
   * Get value from search event
   * @param $event Search event
   */
  getEventValue($event: Event): string {
    this.search = ($event.target as HTMLInputElement).value;
    return ($event.target as HTMLInputElement).value;
  }

  /**
   * Get screen width
   */
  screenWidth() {
    return window.innerWidth < 441;
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
