<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid" *ngIf="!loading">

    <div class="col-12 flex flex-wrap align-content-center">
        <h2 style="margin-top: 10px">{{'ARCHIVE.PERSONS_ARCHIVE' | translate}}</h2>
    </div>

    <div class="col-12 p-0">
        <div class="col-6">
            <input (ngModelChange)="filterPersons(search)" [(ngModel)]="search" [style]="{'width' : '100%'}"
                   pInputText placeholder="{{'ASSET.SEARCH_ASSET' | translate}}" type="text"/>
        </div>
    </div>
    <ng-container *ngIf="allArchivedPersons.length > 0; else emptyBlock">
        <div *ngFor="let person of archivedPersons" class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-card styleClass="p-card-shadow box">
                <ng-template pTemplate="header">
                    <div style="background-color: #16214D; min-height: 30px;"
                         class="flex align-content-center flex-wrap justify-content-center banner">
                        <div class="flex align-items-center text" style="color: white">
                            {{person.name}}
                        </div>
                    </div>
                </ng-template>
                <div class="grid">
                    <div class="col-offset-1 col-3"> {{'PERSONAL.EMAIL' | translate}}:</div>
                    <div class="col-8"> {{person.email}} </div>
                    <div class="col-offset-1 col-3"> {{'ARCHIVE.MOBILE' | translate}}:</div>
                    <div class="col-8"> {{getCell(person.privateCell)}} </div>
                </div>
                <div class="col-12 flex justify-content-center">
                    <button label="{{'ARCHIVE.RESTORE' | translate}}" (click)="confirm(person)"
                            class="p-button-outlined p-button-raised" pButton type="button"></button>
                </div>
            </p-card>
        </div>
    </ng-container>
    <ng-template #emptyBlock>
        <div class="col-12">
            <h4>{{'FILES.NO_ARCHIVED_FILES' | translate}}</h4>
        </div>
    </ng-template>
</div>
