import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {TenantService} from './tenant.service';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {map, mergeMap, publish} from 'rxjs/operators';
import {ShiftReport} from '../../model/shiftReport';

@Injectable({
  providedIn: 'root'
})
export class ShiftReportService {
  tenantId$: Observable<any>;

  constructor(
      private tenantService: TenantService,
      private db: AngularFireDatabase,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get all Shift reports.
   */
  getAllShiftReports(): Observable<any> {
    return this.tenantId$.pipe(
        mergeMap(
            tenantId => this.db.list(`tenants/${tenantId}/shiftReports/`).snapshotChanges()
        )
    );
  }

  /**
   * Add new shift report and return key
   * @param stationId   Station <fbid>
   * @param date        Date: ddmmyyyy
   * @param shiftReport ShiftReport object
   */
  addShiftReportGetKey(stationId: string, date: string, shiftReport: ShiftReport): Observable<string>{
    return this.tenantId$.pipe(
        map(tenantId => {
          const ref = this.db.list(`tenants/${tenantId}/shiftReports/${stationId}/${date}/`).push(shiftReport);
          return ref.key;
        })
    );
  }

  /**
   * Add subject to shift report.
   * @param stationId Station <fbid>
   * @param date      Date: ddmmyyyy
   * @param id        Shift report <fbid>
   * @param subjectId Subject <fbid>
   * @param subject   Subject: {name: string, role: roleType <fbid>}
   */
  addSubjectsToShiftReport(stationId: string, date: string, id: string, subjectId: string,  subject: object){
    return publish()(this.tenantId$.pipe(
        map(tenantId =>
            this.db.object(`tenants/${tenantId}/shiftReports/${stationId}/${date}/${id}/subjects/${subjectId}/`).set(subject)
        ))
    ).connect();
  }

  /**
   * Update description.
   * @param stationId   Station <fbid>
   * @param date        Date: ddmmyyyy
   * @param id          Shift report <fbid>
   * @param description String
   */
  updateDescription(stationId: string, date: string, id: string, description: string) {
    return publish()(this.tenantId$.pipe(
        map(tenantId =>
            this.db.object(`tenants/${tenantId}/shiftReports/${stationId}/${date}/${id}/description`).set(description)
        ))
    ).connect();
  }

  updateShiftReport(stationId: string, date: string, id: string, shiftReport: ShiftReport) {
    return publish()(this.tenantId$.pipe(
        map(tenantId =>
            this.db.object(`tenants/${tenantId}/shiftReports/${stationId}/${date}/${id}`).set(shiftReport)
        ))
    ).connect();
  }
}
