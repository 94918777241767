<div class="grid">
    <div class="col-12">
      <p-tree [value]="fileTree"
              selectionMode="checkbox" [filter]="true" loading
              filterPlaceholder="{{'COMMON.SEARCH' | translate}}" (onNodeSelect)="nodeSelectArchive($event)"
              (onNodeUnselect)="nodeUnselectArchive($event)">
      </p-tree>
  </div>
    <div class="col-12 flex justify-content-end">
        <p-button label="{{'BUTTON.SAVE' | translate}}" (onClick)="updateFiles()"></p-button>
    </div>
</div>
