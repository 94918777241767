<ng-container *ngIf="!loading">
    <!-- ConfirmDialog -->
    <p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                     icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

    <!-- Toast -->
    <p-toast key='toast' position="top-left" styleClass="customToast"></p-toast>

    <div class="grid">
        <div class="col-12">
            <div class="p-buttonset float-right" *ngIf="!acRole?.user">
                <p-splitButton #sb appendTo="body" label="{{'COMMON.MENU' | translate}}" icon="pi pi-list"
                               (onClick)="sb.onDropdownButtonClick(null)" styleClass="p-button-help" [model]="splitLeft"
                >
                </p-splitButton>
                <p-button label="{{'BUTTON.COMPLETE' | translate}}" icon="pi pi-check-circle"
                          (onClick)="confirmComplete()"
                >
                </p-button>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!acRole?.user; else userBlock">
        <!-- Task Form -->
        <form [formGroup]="taskForm" (ngSubmit)="save()">
            <div class="formgrid grid">
                <!-- Large screen -->
                <ng-container *ngIf="windowWidth > 480; else smallScreenBlock">
                    <!-- Task name -->
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <input id="name" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                                   formControlName="taskName">
                            <label for="name">{{'TASK.TASK_TITLE' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Asset/Station -->
                    <div class="col-5">
                        <p-dropdown [options]="groupedAssetsFilter"
                                    placeholder="{{'ASSET.CHOOSE' | translate}}*"
                                    [group]="true" [style]="{'width' : '100%', 'height' : '42px'}"
                                    formControlName="assetId"
                        >
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span><i class="far fa-warehouse"></i> {{group.label}}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <!-- Date -->
                    <div class="col-4">
                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                    dateFormat="dd.mm.yy"
                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                    class="w-full inputfield"
                                    formControlName="date"
                        ></p-calendar>
                    </div>
                    <!-- Time -->
                    <div class="field col-3">
                        <input type="time"
                               pInputText
                               [style]="{'width' : '100%', 'height' : '42px'}"
                               class="inputfield w-full"
                               formControlName="time"
                        >
                    </div>
                    <!-- Description -->
                    <div class="field col-12 mt-2">
                        <span class="p-float-label">
                            <textarea id="textarea"
                                      rows="4"
                                      style="resize: none;"
                                      cols="30"
                                      pInputTextarea
                                      class="inputfield w-full"
                                      formControlName="description"></textarea>
                            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Person table -->
                    <div class="col-12 flex">
                        <p-table responsiveLayout="block"
                                 breakpoint="200px"
                                 [value]="selectedSubjects"
                                 [(selection)]="selectedLeader"
                                 selectionMode="radiobutton"
                        >
                            <!-- Search bar -->
                            <ng-template pTemplate="caption" class="pt-5">
                                <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                                                [(ngModel)]="selectedPerson"
                                                (onSelect)="addPerson(selectedPerson)"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}*"
                                                [dropdown]="true"
                                                (completeMethod)="filterPerson($event)"
                                                size="100%"
                                                field="label"
                                                type="search"
                                                scrollHeight="400px"
                                                class="inputfield w-full"
                                                appendTo="body"
                                                [forceSelection]="true"
                                                [group]="true"
                                >

                                    <ng-template let-team pTemplate="group">
                                        <div class="flex align-items-center p-2" (click)="addTeam(team)">
                                            <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                                        </div>
                                    </ng-template>
                                    <ng-template let-subject pTemplate="item">
                                        <div class="person-item">
                                            {{subject.label}}
                                        </div>
                                    </ng-template>

                                </p-autoComplete >
                            </ng-template>

                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                                    <th>{{'FDC.PERSONS' | translate}}</th>
                                    <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-personId >
                                <tr>
                                    <td>
                                        <p-tableRadioButton [value]="personId"></p-tableRadioButton>
                                    </td>
                                    <td>
                                        {{personNameMap.get(personId)}}
                                    </td>
                                    <td (click)="removePerson(personId)">
                                        <span class="flex justify-content-center">
                                            <i class="pi pi-trash" style="color: #D0021B"></i>
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td></td>
                                    <td colspan="2">
                                        {{'WARNING.NO_DATA' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- Notification -->
                        <div class="col-12">
                            <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
                        </div>

                        <!-- SMS - Notification -->
                        <div class="field-checkbox col-4 flex align-items-center">
                            <p-checkbox id="smsNotificationM" binary="true"
                                        [(ngModel)]="smsNotification"
                                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                            <label for="smsNotificationSmallM">{{'BUTTON.SMS' | translate}}</label>
                        </div>
                        <div class="field col-6">
                            <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                                           [showButtons]="true" [min]="1"
                                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                           [max]="100" class="inputfield w-full"
                                           [ngModelOptions]="{standalone: true}"></p-inputNumber>
                        </div>

                        <!-- Email - Notification -->
                        <div class="field-checkbox col-4 flex align-items-center mb-3">
                            <p-checkbox id="emailNotificationM" binary="true" [(ngModel)]="emailNotification"
                                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                            <label for="emailNotificationM">{{'PERSONAL.EMAIL' | translate}}</label>
                        </div>
                        <div class="field col-6 mb-3">
                            <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                           [max]="100" class="inputfield w-full"></p-inputNumber>
                        </div>
                    </div>
                </ng-container>

                <!-- Small screen -->
                <ng-template #smallScreenBlock>
                    <!-- Task name -->
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <input id="nameSmall" type="text" pInputText
                                   [style]="{'width' : '100%', 'height' : '42px'}"
                                   formControlName="taskName">
                            <label for="nameSmall">{{'TASK.TASK_TITLE' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Date -->
                    <div class="col-6">
                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                    dateFormat="dd.mm.yy"
                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                    class="w-full inputfield"
                                    formControlName="date"
                        ></p-calendar>
                    </div>
                    <!-- Time -->
                    <div class="field col-6">
                        <input type="time"
                               pInputText
                               [style]="{'width' : '100%', 'height' : '42px'}"
                               class="inputfield w-full"
                               formControlName="time"
                        >
                    </div>
                    <!-- Asset/Station -->
                    <div class="col-12">
                        <p-dropdown [options]="groupedAssetsFilter"
                                    placeholder="{{'ASSET.CHOOSE' | translate}}*"
                                    [group]="true"
                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                    formControlName="assetId">
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span><i class="far fa-warehouse"></i> {{group.label}}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <!-- Description -->
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <textarea id="textareaSmall"
                                      rows="4"
                                      style="resize: none;"
                                      cols="30"
                                      pInputTextarea
                                      class="inputfield w-full"
                                      formControlName="description">
                            </textarea>
                            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Person table -->
                    <div class="col-12 flex">
                        <p-table responsiveLayout="block"
                                 breakpoint="200px"
                                 [value]="selectedSubjects"
                                 [(selection)]="selectedLeader"
                                 selectionMode="radiobutton"
                        >

                            <!-- Search bar -->
                            <ng-template pTemplate="caption" class="pt-5">
                                <p-autoComplete [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                                                [(ngModel)]="selectedPerson"
                                                (onSelect)="addPerson(selectedPerson)"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}*"
                                                [dropdown]="true"
                                                (completeMethod)="filterPerson($event)"
                                                size="100%"
                                                field="label"
                                                type="search"
                                                scrollHeight="400px"
                                                class="inputfield w-full"
                                                appendTo="body"
                                                [forceSelection]="true"
                                                [group]="true"
                                >

                                    <ng-template let-team pTemplate="group">
                                        <div class="flex align-items-center p-2" (click)="addTeam(team)">
                                            <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                                        </div>
                                    </ng-template>
                                    <ng-template let-subject pTemplate="item">
                                        <div class="person-item">
                                            {{subject.label}}
                                        </div>
                                    </ng-template>

                                </p-autoComplete >
                            </ng-template>

                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                                    <th>{{'FDC.PERSONS' | translate}}</th>
                                    <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-personId >
                                <tr>
                                    <td>
                                        <p-tableRadioButton [value]="personId"></p-tableRadioButton>
                                    </td>
                                    <td>
                                        {{personNameMap.get(personId)}}
                                    </td>
                                    <td (click)="removePerson(personId)">
                            <span class="flex justify-content-center">
                                <i class="pi pi-trash" style="color: #D0021B"></i>
                            </span>
                                    </td>
                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td></td>
                                    <td colspan="2">
                                        {{'WARNING.NO_DATA' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-template>

                <!-- Notification -->
                <div class="col-12">
                    <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
                </div>

                <!-- SMS - Notification -->
                <div class="field-checkbox col-4 flex align-items-center">
                    <p-checkbox id="smsNotificationSmall" binary="true"
                                [(ngModel)]="smsNotification"
                                [ngModelOptions]="{standalone: true}"></p-checkbox>
                    <label for="smsNotificationSmall">{{'BUTTON.SMS' | translate}}</label>
                </div>
                <div class="field col-6">
                    <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                                   [showButtons]="true" [min]="1"
                                   placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                   [max]="100" class="inputfield w-full"
                                   [ngModelOptions]="{standalone: true}"></p-inputNumber>
                </div>

                <!-- Email - Notification -->
                <div class="field-checkbox col-4 flex align-items-center mb-3">
                    <p-checkbox id="emailNotificationSmall" binary="true" [(ngModel)]="emailNotification"
                                [ngModelOptions]="{standalone: true}"></p-checkbox>
                    <label for="emailNotificationSmall">{{'PERSONAL.EMAIL' | translate}}</label>
                </div>
                <div class="field col-6 mb-3">
                    <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                                   [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                                   placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                   [max]="100" class="inputfield w-full"></p-inputNumber>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-template #userBlock>
        <!-- Task Form -->
        <form [formGroup]="taskForm" (ngSubmit)="save()">
            <div class="formgrid grid">
                <!-- Large screen -->
                <ng-container *ngIf="windowWidth > 480; else smallScreenBlock">
                    <!-- Task name -->
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <input id="nameA" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                                   formControlName="taskName"
                                   disabled>
                            <label for="nameA">{{'TASK.TASK_TITLE' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Asset/Station -->
                    <div class="col-5">
                        <p-dropdown [options]="groupedAssetsFilter"
                                    placeholder="{{'ASSET.CHOOSE' | translate}}*"
                                    [group]="true" [style]="{'width' : '100%', 'height' : '42px'}"
                                    formControlName="assetId"
                                    disabled
                        >
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span><i class="far fa-warehouse"></i> {{group.label}}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <!-- Date -->
                    <div class="col-4">
                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                    dateFormat="dd.mm.yy"
                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                    class="w-full inputfield"
                                    formControlName="date"
                                    disabled
                        ></p-calendar>
                    </div>
                    <!-- Time -->
                    <div class="field col-3">
                        <input type="time"
                               pInputText
                               [style]="{'width' : '100%', 'height' : '42px'}"
                               class="inputfield w-full"
                               formControlName="time"
                               disabled
                        >
                    </div>
                    <!-- Description -->
                    <div class="field col-12 mt-2">
                        <span class="p-float-label">
                            <textarea id="textareaA"
                                      rows="4"
                                      style="resize: none;"
                                      cols="30"
                                      pInputTextarea
                                      class="inputfield w-full"
                                      formControlName="description"
                                      disabled
                            ></textarea>
                            <label for="textareaA">{{'COMMON.DESCRIPTION' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Person table -->
                    <div class="col-12 flex">
                        <p-table responsiveLayout="block"
                                 breakpoint="200px"
                                 [value]="selectedSubjects"
                                 [(selection)]="selectedLeader"
                                 selectionMode="radiobutton"

                        >

                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                                    <th>{{'FDC.PERSONS' | translate}}</th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-personId >
                                <tr>
                                    <td>
                                        <p-tableRadioButton [value]="personId"></p-tableRadioButton>
                                    </td>
                                    <td>
                                        {{personNameMap.get(personId)}}
                                    </td>
                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td></td>
                                    <td colspan="2">
                                        {{'WARNING.NO_DATA' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- Notification -->
                        <div class="col-12">
                            <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
                        </div>

                        <!-- SMS - Notification -->
                        <div class="field-checkbox col-4 flex align-items-center">
                            <p-checkbox id="smsNotificationSmallA" binary="true"
                                        [(ngModel)]="smsNotification"
                                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                            <label for="smsNotificationSmallA">{{'BUTTON.SMS' | translate}}</label>
                        </div>
                        <div class="field col-6">
                            <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                                           [showButtons]="true" [min]="1"
                                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                           [max]="100" class="inputfield w-full"
                                           [ngModelOptions]="{standalone: true}"></p-inputNumber>
                        </div>

                        <!-- Email - Notification -->
                        <div class="field-checkbox col-4 flex align-items-center mb-3">
                            <p-checkbox id="emailNotificationSmallA" binary="true" [(ngModel)]="emailNotification"
                                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                            <label for="emailNotificationSmallA">{{'PERSONAL.EMAIL' | translate}}</label>
                        </div>
                        <div class="field col-6 mb-3">
                            <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                           [max]="100" class="inputfield w-full"></p-inputNumber>
                        </div>
                    </div>
                </ng-container>

                <!-- Small screen -->
                <ng-template #smallScreenBlock>
                    <!-- Task name -->
                    <div class="field col-12 mt-3">
                        <span class="p-float-label">
                            <input id="nameSmallU" type="text" pInputText
                                   [style]="{'width' : '100%', 'height' : '42px'}"
                                   formControlName="taskName"
                                   disabled
                            >
                            <label for="nameSmallU">{{'TASK.TASK_TITLE' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Date -->
                    <div class="col-6">
                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                    dateFormat="dd.mm.yy"
                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                    class="w-full inputfield"
                                    formControlName="date"
                                    disabled
                        ></p-calendar>
                    </div>
                    <!-- Time -->
                    <div class="field col-6">
                        <input type="time"
                               pInputText
                               [style]="{'width' : '100%', 'height' : '42px'}"
                               class="inputfield w-full"
                               formControlName="time"
                               disabled
                        >
                    </div>
                    <!-- Asset/Station -->
                    <div class="col-12">
                        <p-dropdown [options]="groupedAssetsFilter"
                                    placeholder="{{'ASSET.CHOOSE' | translate}}*"
                                    [group]="true"
                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                    formControlName="assetId"
                                    disabled
                        >
                            <ng-template let-group pTemplate="group">
                                <div class="flex align-items-center">
                                    <span><i class="far fa-warehouse"></i> {{group.label}}</span>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <!-- Description -->
                    <div class="field col-12">
                        <span class="p-float-label">
                            <textarea id="textareaSmallU"
                                      rows="4"
                                      style="resize: none;"
                                      cols="30"
                                      pInputTextarea
                                      class="inputfield w-full"
                                      disabled
                                      formControlName="description"></textarea>
                            <label for="textareaSmallU">{{'COMMON.DESCRIPTION' | translate}}*</label>
                        </span>
                    </div>
                    <!-- Person table -->
                    <div class="col-12 flex">
                        <p-table responsiveLayout="block"
                                 breakpoint="200px"
                                 [value]="selectedSubjects"
                                 [(selection)]="selectedLeader"
                                 selectionMode="radiobutton"
                        >
                            <!-- Header -->
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                                    <th>{{'FDC.PERSONS' | translate}}</th>
                                </tr>
                            </ng-template>

                            <!-- Body -->
                            <ng-template pTemplate="body" let-personId >
                                <tr>
                                    <td>
                                        <p-tableRadioButton [value]="personId"></p-tableRadioButton>
                                    </td>
                                    <td>
                                        {{personNameMap.get(personId)}}
                                    </td>

                                </tr>
                            </ng-template>

                            <!-- Shows message if table is empty -->
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td ></td>
                                    <td colspan="2">
                                        {{'WARNING.NO_DATA' | translate}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <!-- Notification -->
                        <div class="col-12">
                            <p><b>{{'NOTIFICATIONS.NOTIFICATION' | translate}}:</b></p>
                        </div>

                        <!-- SMS - Notification -->
                        <div class="field-checkbox col-4 flex align-items-center">
                            <p-checkbox id="smsNotificationSmallB" binary="true"
                                        [(ngModel)]="smsNotification"
                                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                            <label for="smsNotificationSmallB">{{'BUTTON.SMS' | translate}}</label>
                        </div>
                        <div class="field col-6">
                            <p-inputNumber [disabled]="!smsNotification" [(ngModel)]="smsDaysBefore"
                                           [showButtons]="true" [min]="1"
                                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                           [max]="100" class="inputfield w-full"
                                           [ngModelOptions]="{standalone: true}"></p-inputNumber>
                        </div>

                        <!-- Email - Notification -->
                        <div class="field-checkbox col-4 flex align-items-center mb-3">
                            <p-checkbox id="emailNotificationSmallB" binary="true" [(ngModel)]="emailNotification"
                                        [ngModelOptions]="{standalone: true}"></p-checkbox>
                            <label for="emailNotificationSmallB">{{'PERSONAL.EMAIL' | translate}}</label>
                        </div>
                        <div class="field col-6 mb-3">
                            <p-inputNumber [disabled]="!emailNotification" [ngModelOptions]="{standalone: true}"
                                           [(ngModel)]="emailDaysBefore" [showButtons]="true" [min]="1"
                                           placeholder="{{'NOTIFICATIONS.DAYS_BEFORE' | translate}}"
                                           [max]="100" class="inputfield w-full"></p-inputNumber>
                        </div>
                    </div>
                </ng-template>
            </div>
        </form>
    </ng-template>
</ng-container>

