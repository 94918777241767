import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportAddComponent } from './report-add/report-add.component';
import { ReportViewComponent } from './report-view/report-view.component';
import {Route} from '@angular/router';
import {ButtonModule} from 'primeng/button';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {PipesModule} from '../../pipes/pipes.module';
import {DropdownModule} from 'primeng/dropdown';
import {TabMenuModule} from 'primeng/tabmenu';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {CardModule} from 'primeng/card';
import {CanActivateService} from '../../services/can-activate.service';

export const ShiftReportRoutes: Route = {
  path: 'shift-report', canActivate: [CanActivateService],
  children: [
    {path: 'report-view', component: ReportViewComponent}
  ]
};

@NgModule({
  declarations: [
    ReportAddComponent,
    ReportViewComponent
  ],
    imports: [
        CommonModule,
        ButtonModule,
        TranslateModule,
        TableModule,
        CalendarModule,
        FormsModule,
        InputTextModule,
        ToggleButtonModule,
        InputTextareaModule,
        AutoCompleteModule,
        PipesModule,
        DropdownModule,
        TabMenuModule,
        RadioButtonModule,
        CheckboxModule,
        SelectButtonModule,
        CardModule
    ]
})
export class ShiftReportModule { }
