import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";

import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})

/**
 * AuthService: Handle everything that has to do with Authentication
 */
export class AuthService {

  auth$: Observable<any>;

  constructor(
    private angularFireAuth: AngularFireAuth,
  ) {
    this.auth$ = this.angularFireAuth.authState;
  }

  public getAuthState(): Observable<any> {
    return this.auth$;
  }

  public canActivate() {
    return this.auth$.pipe(map(auth => !!(auth)));
  }

  public createUserWithEmailAndPassword(email: string, password: string){
    const secondConnection = firebase.initializeApp(environment, 'secondary');
    return secondConnection.auth().createUserWithEmailAndPassword(email, password)
      .then(userCred => {
        const newUID = userCred.user.uid;
        firebase.auth().sendPasswordResetEmail(email).catch();
        secondConnection.auth().signOut().catch();
        secondConnection.delete().catch();
        return newUID;
      })
      .catch(error => {
        secondConnection.auth().signOut().catch();
        secondConnection.delete().catch();
        throw error;
      });
  }

  public login(email: string, password: string) {
    return this.angularFireAuth.signInWithEmailAndPassword(email, password);
  }

  public logout() {
    this.angularFireAuth.signOut()
      .catch();
  }

  public resetPassword(email: string){
    return this.angularFireAuth.sendPasswordResetEmail(email);
  }

  public getUserUID(): Observable<any>{
    return this.auth$.pipe(
      map(auth => {
        return auth.uid;
      })
    );
  }
  public getUser(): Observable<any>{
    return this.auth$;
  }
}
