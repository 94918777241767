import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {SubjectService} from "../../../services/service/subject.service";
import {Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {AccessControlService} from "../../../services/service/access-control.service";
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-new-subject',
  templateUrl: './new-subject.component.html',
  styleUrls: ['./new-subject.component.scss']
})
export class NewSubjectComponent implements OnInit, OnDestroy {

  name = '';
  email = '';
  selectedFilter: any;
  subjectTypesFilter;
  stations;
  teams: any[];
  usedEmails;

  selectedStation = null;
  selectedTeam = null;

  subscription = new Subscription();


  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private subjectService: SubjectService,
    private acService: AccessControlService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    const type = this.config.data.type;
    this.setPredefinedType(type);

    this.subscription.add(
      this.acService.getTenantUserAdmins().subscribe(users => {
        this.usedEmails = users.map((user: any) => user.payload.val().email);
      })
    );

    this.subscription.add(
      this.subjectService.getStations().subscribe(stations => {
        this.stations = stations.map(station => {
          return {key: station.key, ...station.payload.val()};
        });
      })
    );
  }

  ngOnInit(): void {

  }

  add() {
    let relation = {
      childId: '',
      parentId: '',
    };

    if (this.selectedFilter.value === 'PERSON') {
      if (!this.usedEmails.includes(this.email)) {

        const person = {
          dateOfBirth: new Date().toISOString(),
          name: this.name,
          email: this.email,
          type: this.selectedFilter.value,
        };
        this.subjectService.addSubject(person).pipe(
          take(1),
        ).subscribe(newKey => {

          relation = {
            childId: newKey,
            parentId: (this.selectedTeam) ? (this.selectedTeam.key) : (this.selectedStation.key)

          };
          this.subjectService.addRelation(relation);
        });
      } else {
        this.messageService.add(
          {key: 'subject-list', severity: 'warn', detail: this.translateService.instant('WARNING.EMAIL_EXIST'), life: 4000});
      }
    } else if (this.selectedFilter.value === 'FIRETEAM') {

      const team = {
        name: this.name,
        type: this.selectedFilter.value,
      };
      this.subjectService.addSubject(team).pipe(
        take(1),
      ).subscribe(newKey => {
        relation = {
          childId: newKey,
          parentId: this.selectedStation.key
        };
        this.subjectService.addRelation(relation);
      });
    } else if (this.selectedFilter.value === 'FIRESTATION') {

      const station = {
        name: this.name,
        type: this.selectedFilter.value,
      };

      this.subjectService.addSubject(station).pipe(
        take(1),
      ).subscribe(newKey => {
        this.subjectService.getAncestor().pipe(
          take(1),
        ).subscribe(ancestors => {
          relation = {
            childId: newKey,
            parentId: ancestors[0].key
          };
          this.subjectService.addRelation(relation);
        });
      });
    }
    this.ref.close();
  }

  close() {
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getTeams(station) {
    if(this.selectedFilter.value === 'PERSON'){
      this.teams = [];
      this.selectedTeam = null;
      this.subjectService.getChildren(station.key).pipe(
        take(1),
      ).subscribe(children => {
        children.forEach(child => {
          if (child.data === 'FIRETEAM') {
            this.teams.push({key: child.key, name: child.label});
          }
        });
      });
    }
  }

  // RETURN true if we dont have all the required info
  validator() {
    if (this.selectedFilter.value === 'PERSON') {
      return !(this.name && this.email && this.selectedStation);
    } else if (this.selectedFilter.value === 'FIRETEAM') {
      return !(this.name && this.selectedStation);
    } else if (this.selectedFilter.value === 'FIRESTATION') {
      return !(this.name);
    } else {
      return true;
    }
  }

  private setPredefinedType(type: any) {
    this.subjectTypesFilter = [
      {name: 'ARCHIVE.SUBJECT', value: 'PERSON'},
      {name: 'SUBJECTS.TYPES.FIRETEAM', value: 'FIRETEAM'},
      {name: 'COMMON.STATION', value: 'FIRESTATION'},
    ];
    if (!type || type === 'PERSON') {
      this.selectedFilter = this.subjectTypesFilter[0];
    } else if (type === 'FIRETEAM') {
      this.selectedFilter = this.subjectTypesFilter[1];
    } else if (type === 'FIRESTATION') {
      this.selectedFilter = this.subjectTypesFilter[2];
    }
  }
}
