import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {AccessControlService} from '../../../services/service/access-control.service';
import {ConfirmationService, FilterService, MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Task} from '../../../model/task';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {SubjectService} from '../../../services/service/subject.service';
import {AssetService} from '../../../services/service/asset.service';
import {PersonService} from '../../../services/service/person.service';
import {AuthService} from '../../../services/service/auth.service';
import {TaskService} from '../../../services/service/task.service';
import {CalendarService} from '../../../services/service/calendar.service';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {TaskSharedService} from '../../../services/sharedService/task-shared.service';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {CalendarEvent} from '../../../model/calendarEvent';
import {SendSmsComponent} from '../send-sms/send-sms.component';

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss'],
  providers: [ConfirmationService, DialogService]
})
export class ViewTaskComponent implements OnInit, OnDestroy {
  @Input() fromCalendarEventKey;
  acRole;
  splitLeft: MenuItem[];
  subscription = new Subscription();
  task: Task;

  assetNameMap;
  stationAssetMap;
  assetStationMap;
  personNameMap;
  teamNameMap;
  personParentNameMap;

  groupedSubjectFilter;
  groupedAssetsFilter;

  selectedSubjects: any[] = [];
  selectedPerson: '';
  selectedLeader = "";

  outputPerson = [];
  personMap = new Map();

  taskForm: UntypedFormGroup;

  windowWidth;

  loading = true;
  smsNotification: boolean;
  smsDaysBefore: number;
  emailNotification: boolean;
  emailDaysBefore: number;

  constructor(
      private subjectService: SubjectService,
      private assetService: AssetService,
      private personService: PersonService,
      private filterService: FilterService,
      private authService: AuthService,
      private translateService: TranslateService,
      private taskService: TaskService,
      private calendarService: CalendarService,
      private ref: DynamicDialogRef,
      private gSService: GroupedSubjectsService,
      private config: DynamicDialogConfig,
      private acService: AccessControlService,
      private tsService: TaskSharedService,
      private confirmationService: ConfirmationService,
      private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
        this.acService.getOwnAccessControl().subscribe(ac => {
          this.acRole = ac.roles;

          if (!this.acRole.user) {
            this.splitLeft = [
              {
                label: this.translateService.instant('BUTTON.SAVE'),
                icon: 'pi pi-bookmark',
                command: () => {
                  this.save();
                }
              },
              {
                label: this.translateService.instant('BUTTON.CANCEL'),
                icon: 'pi pi-times',
                command: () => {
                  this.close();
                }
              },
              {
                label: this.translateService.instant('SMS.SEND'),
                icon: 'far fa-sms',
                command: () => {
                  this.sendSMS();
                }
              },
              {
                separator: true
              },
              {
                label: this.translateService.instant('BUTTON.REMOVE'),
                icon: 'pi pi-trash',
                command: () => {
                  this.confirmDelete();
                }
              },
            ];
          }
        })
    );

    if (this.config.data.task) {
      this.task = this.config.data.task;
      this.selectedLeader = this.task.responsible;

      this.emailNotification = this.task.emailNotification;
      this.smsNotification = this.task.smsNotification;

      this.emailDaysBefore = this.task.emailDaysBefore;
      this.smsDaysBefore = this.task.smsDaysBefore;

      this.taskForm = new UntypedFormGroup({
        taskName: new UntypedFormControl(this.task.name, Validators.required),
        assetId: new UntypedFormControl(this.task.assetId),
        date: new UntypedFormControl(moment(this.task.deadline).format('DD.MM.YYYY'), Validators.required),
        time: new UntypedFormControl(moment(this.task.deadline).format('HH:mm')),
        description: new UntypedFormControl(this.task.description, Validators.required),
      });
      this.loadSubjects();
      this.loading = false;
    } else {
      this.taskService.getTask(this.fromCalendarEventKey).subscribe(task => {

        this.task = task;
        this.selectedLeader = this.task.responsible;
        this.taskForm = new UntypedFormGroup({
          taskName: new UntypedFormControl(this.task.name, Validators.required),
          assetId: new UntypedFormControl(this.task.assetId),
          date: new UntypedFormControl(moment(this.task.deadline).format('DD.MM.YYYY'), Validators.required),
          time: new UntypedFormControl(moment(this.task.deadline).format('HH:mm')),
          description: new UntypedFormControl(this.task.description, Validators.required),
        });
        this.loadSubjects();
        this.loading = false;
      });
    }
  }

  /**
   * Load when data
   */
  loadSubjects(){
    this.subscription.add(combineLatest([
      this.personService.getPersons(),
      this.subjectService.getTeams(),
      this.subjectService.getStations(),
      this.subjectService.getRelations(),
      this.assetService.getAssets(),])
        .subscribe(([persons, teams, stations, relations, assets]) => {

          const data = this.gSService.build(persons, teams, relations);
          this.personNameMap = data[0];
          this.teamNameMap = data[1];
          this.personParentNameMap = data[2];
          this.groupedSubjectFilter = data[3];

          const assetData = this.tsService.buildAssets(assets, stations);
          this.stationAssetMap = assetData[0];
          this.assetStationMap = assetData[1];
          this.assetNameMap = assetData[2];
          this.groupedAssetsFilter = assetData[3];

          for (const subjectsKey in this.task.subjects) {
            if (!this.personMap.has(subjectsKey)) {
              this.personMap.set({label: this.personNameMap.get(subjectsKey), value: subjectsKey}, true);
              this.selectedSubjects.push(subjectsKey);
            }
          }
        })
    );
  }
  /**
   * Add team
   * @param team Team object
   */
  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  /**
   * Add Person
   * @param subject Subject to add
   */
  addPerson(subject){
    if (!this.personMap.has(subject)) {
      this.personMap.set(subject, true);
      this.selectedSubjects.push(subject.value);
    }
  }

  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Remove person
   * @param personId
   */
  removePerson(personId: any) {
    this.personMap.delete(personId);

    const index = this.selectedSubjects.findIndex(pId => pId === personId);
    if(index >= 0) {
      this.selectedSubjects.splice(index, 1);
    }
    if (this.selectedLeader === personId){
      this.selectedLeader = "";
    }
  }

  /**
   * Confirm completion
   */
  confirmComplete() {
    this.confirmationService.confirm({
      message: this.translateService.instant('TASK.CONFIRM.COMPLETE') + " " + this.task.name,
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.complete();
      }
    });
  }

  /**
   * Confirm delete
   */
  confirmDelete() {
    this.confirmationService.confirm({
      message: this.translateService.instant('TASK.CONFIRM_DELETE') + " " + this.task.name,
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.delete();
      }
    });
  }
  /**
   * Complete exercise
   */
  complete() {
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

      this.task.completed = moment().toISOString();
      let key;
      if(this.fromCalendarEventKey){
        key = this.fromCalendarEventKey;
      } else {
        key = this.task.key;
      }
      this.calendarService.getCalendarEvent(key).pipe(take(1)).subscribe(event => {
        const cEvent = event;
        const updatedCalendarEvent: CalendarEvent = {
          calendarType: "TASK",
          completed: true,
          start: cEvent[2].toString(),
          end: cEvent[3].toString(),
          title: cEvent[5].toString(),
        };

        this.calendarService.updateCalendarEvent(updatedCalendarEvent, key);
        delete this.task.key;
        this.task.completedById = uid;
        this.taskService.updateTask(this.task, key);

        this.ref.close();
      });
    });
  }

  /**
   * Delete task
   */
  delete(){
    this.taskService.deleteTask(this.task.key);
    this.calendarService.deleteCalendarEvent(this.task.key);
    this.ref.close();
  }

  sendSMS() {
    this.dialogService.open(SendSmsComponent, {
      header: this.translateService.instant('EXERCISE.SEND_SMS'),
      styleClass: 'max-size-dialog',
      data: {
        task: this.task,
      }
    });
  }

  /**
   * Save changes
   */
  save(){

    const ongoingForm = this.taskForm.value;
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

      if(ongoingForm.time === ""){
        ongoingForm.time = '23:59:59';
      }

      let date;
      if (ongoingForm.date.length === 10){
        if (ongoingForm.date.charAt(2) === '.'){
          const flip = ongoingForm.date.split('.');
          date = flip[2] + '-' + flip[1] + '-' + flip[0];
        }
      } else {
        date = moment(ongoingForm.date).format("YYYY-MM-DD");

      }
      const deadline = moment(date + " " + ongoingForm.time);
      let assetId = ongoingForm.assetId;
      let station = this.assetStationMap.get(ongoingForm.assetId);
      if (this.stationAssetMap.has(ongoingForm.assetId)){
        assetId = "";
        station = ongoingForm.assetId;
      }

      const updatedTask: Task = {
        description: ongoingForm.description,
        name: ongoingForm.taskName,
        createdById: uid,
        responsible: this.selectedLeader,
        emailNotification: this.emailNotification,
        smsNotification: this.smsNotification,
        emailDaysBefore: this.emailDaysBefore,
        smsDaysBefore: this.smsDaysBefore,
        assetId: assetId,
        station: station,
        deadline: deadline.toISOString(),
        subjects: []
      };

      if (updatedTask.emailNotification === undefined){
        delete updatedTask.emailNotification;
      }
      if (updatedTask.smsNotification === undefined){
        delete updatedTask.smsNotification;
      }
      if (updatedTask.smsDaysBefore === undefined){
        delete updatedTask.smsDaysBefore;
      }
      if (updatedTask.emailDaysBefore === undefined){
        delete updatedTask.emailDaysBefore;
      }
      const subjectMap = new Map<string, boolean>();
      this.selectedSubjects.forEach(subjectId => {
        subjectMap.set(subjectId, true);
      });
      updatedTask.subjects.push(subjectMap.entries());

      const updatedCalendarEvent: CalendarEvent = {
        calendarType: "TASK",
        completed: false,
        start: deadline.toISOString(),
        end: deadline.toISOString(),
        title: ongoingForm.taskName,
      };

      this.taskService.updateTask(updatedTask, this.task.key);
      this.calendarService.updateCalendarEvent(updatedCalendarEvent, this.fromCalendarEventKey);
      this.selectedSubjects.forEach(subjectId => {
        this.taskService.addSubjectsToTask(this.task.key, subjectId, true);
      });

      this.ref.close();
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.ref.close();
  }
}
