import {Component, OnDestroy, OnInit} from '@angular/core';
import {FunctionService} from "../../../services/service/function.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-edit-competency-types-validity',
  templateUrl: './edit-competency-types-validity.component.html',
  styleUrls: ['./edit-competency-types-validity.component.scss']
})
export class EditCompetencyTypesValidityComponent implements OnInit, OnDestroy {
  ctKey: string;
  fKey: string;
  ct: any = '';

  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private functionService: FunctionService,
  ) { }

  ngOnInit(): void {
    this.ctKey = this.config.data.ctKey;
    this.fKey = this.config.data.fKey;


    this.subscription.add(
      this.functionService.getFunctionCompetencyType(this.fKey, this.ctKey)
        .subscribe((ct) => {
          this.ct = ct;
        })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save() {
    this.functionService.updateFunctionCompetencyType(this.fKey, this.ctKey, this.ct);
    this.ref.close(true);
  }
}
