import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {RoleType} from '../../../model/role';
import {RolesService} from '../../../services/service/roles.service';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit, OnDestroy {

  // Subscription
  subscription = new Subscription();

  // Persons
  personNameMap = new Map(); // Map {{key: personKey_1, value: person_name}, ..., {key: personKey_n, value: person_name}}
  personArray: []; // [personKey_1, personKey_2, ..., personKey_n]
  tableArray = []; // {key: personKey}

  roleTypes: RoleType[]; // Role {key, name}
  roleMap: Map<string, string[]>;  // RoleMap example {key: "smoke diver", persons: [personKey_1, ..., personKey_n]}
  selectedRolesMap: Map<string, string[]> = new Map<string, string[]>();

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    public translateService: TranslateService,
    private roleService: RolesService,
  ) {


    this.personArray = this.config.data.personArray;
    this.personNameMap = this.config.data.personNameMap;
    this.selectedRolesMap = this.config.data.selectedRolesMap;
    this.roleMap = new Map<string, string[]>();

    // Get roles
    this.subscription.add(
      this.roleService.getRoleTypes().subscribe( roles => {
        this.roleTypes = roles.map(role => {
          this.roleMap.set(role.key, []);
          return {key: role.key, ...role.payload.val()}
        });
      })
    );

    this.personArray.forEach(personId => {
      this.tableArray.push({key: personId});
    });
  }

  ngOnInit(): void {
  }

  /**
   * Generate role map and send to emergency-add
   */
  save() {
    for (const [person, keys] of this.selectedRolesMap){
      keys.forEach(key => this.roleMap.get(key).push(person));
    }
    this.ref.close([this.roleMap, this.selectedRolesMap]);
  }

  /**
   * Set selected roles to person
   * @param $event Selected roles
   * @param personId Unique personId
   */
  setRoles($event: any, personId) {
    this.selectedRolesMap.set(personId, $event.value);
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
