import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSort'
})
export class NameSortPipe implements PipeTransform {
  /**
   * This Sort can be used with list that we can call .key and a personNameMap.
   * @param list: List we are going to filter, have to include .key to work
   * @param personNameMap: Map containing [key, personName]
   */
  transform(list, personNameMap): any {
    if(!list || personNameMap.size < 1){
      return list;
    } else {
      return list.sort((a,b) => (personNameMap.get(a.key).toLowerCase()).localeCompare(personNameMap.get(b.key).toLowerCase()));
    }
  }
}
