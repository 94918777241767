import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exerciseEvalFilter'
})
export class ExerciseEvalFilterPipe implements PipeTransform {

  /**
   * Filter Exercise evaluations on name
   * @param event Event[]
   * @param unFiltered Original list
   * @param search Search string
   */
  transform(event: any[], unFiltered: any[], search?: string)
    : any {

    if(!event || !search){
      return unFiltered;
    } else {
      return event.filter(evaluation => evaluation.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
