import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {Deviation} from '../../../model/deviation';
import {DeviationService} from '../../../services/service/deviation.service';
import {AssetService} from '../../../services/service/asset.service';
import {PersonService} from '../../../services/service/person.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {CommentModalComponent} from '../../checklist/comment-modal/comment-modal.component';
import {DeviationFilterPipe} from '../../../pipes/deviation-filter.pipe';
import {SubjectService} from "../../../services/service/subject.service";

@Component({
  selector: 'app-deviations-complete',
  templateUrl: './deviations-complete.component.html',
  styleUrls: ['./deviations-complete.component.scss'],
  providers: [DialogService, DeviationFilterPipe]
})
export class DeviationsCompleteComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  deviations: Deviation[];
  allDeviations: Deviation[];
  assetsMap = new Map<string, string>();
  stationAssetMap = new Map<string, string>();
  stationAssetsMap = new Map<string, any[]>();
  stations: any[];
  personNameMap = new Map<string, string>();
  uidPersonIdMap = new Map<string, string>();

  search: string;
  filterOptions: any[];
  selectedFilter = this.translateService.instant('COMMON.SHOW_ALL');
  assetDeviationMap: Map<string, Deviation[]>;
  loading = true;
  selectedStation = this.translateService.instant('ROLES.ALL_STATIONS');

  constructor(
      private deviationService: DeviationService,
      private assetService: AssetService,
      private personService: PersonService,
      private acService: AccessControlService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private dFilter: DeviationFilterPipe,
      private subjectService: SubjectService,
  ) {

    this.subscription.add(combineLatest([this.deviationService.getDeviations(), this.assetService.getAssets(),
      this.personService.getPersons(), this.acService.getAccessControlsPayload(), this.subjectService.getStations()])
        .subscribe(([deviations, assets, persons, acControls, stations]) => {

          this.deviations = [];
          this.allDeviations = [];
          deviations.map(deviation => {
            if (deviation.payload.val().closed){
              this.deviations.push({key: deviation.key, ...deviation.payload.val()});
            }
          });
          this.deviations.sort((a,b) => moment(b.closed).diff(moment(a.closed)));
          this.allDeviations = this.deviations;

          this.assetsMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
          this.stationAssetMap = new Map(assets.map(asset => [asset.key, asset.payload.val().stationId]));
          this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]));
          this.uidPersonIdMap = new Map(acControls.map(ac => [ac.key, ac.payload.val().subjectId]));

          this.stationAssetsMap.set(this.translateService.instant('ROLES.ALL_STATIONS'), []);
          // fill station/assets
          stations.forEach(station => {
            const a = [];
            this.stationAssetMap.forEach((stationId, assetId) => {
              if (stationId === station.key){
                a.push({label: this.getAsset(assetId), value: assetId});
              }
            });
            this.stationAssetsMap.set(station.key, a);
          });
          // Fill "all stations"/assets
          this.stationAssetMap.forEach((stationId, assetId) => {
            this.stationAssetsMap.get(this.translateService.instant('ROLES.ALL_STATIONS'))
              .push({label: this.getAsset(assetId), value: assetId});
          });

          this.stations = [];
          this.stations.push({
            value: this.translateService.instant('ROLES.ALL_STATIONS'),
            label: this.translateService.instant('ROLES.ALL_STATIONS')
          });
          stations.map(station => {
            this.stations.push({value: station.key, label: station.payload.val().name});
          });

          // Sort deviations
          this.assetDeviationMap = new Map<string, Deviation[]>();
          this.filterOptions = [];
          this.deviations.forEach(deviation => {

            if (this.assetDeviationMap.has(this.selectedFilter)) {
              this.assetDeviationMap.get(this.selectedFilter).push(deviation);
            } else {
              this.filterOptions.push({label: this.selectedFilter, value: this.selectedFilter});
              this.assetDeviationMap.set(this.selectedFilter, [deviation]);
            }
            if (this.assetDeviationMap.has(deviation.asset)) {
              this.assetDeviationMap.get(deviation.asset).push(deviation);
            } else {
              this.filterOptions.push({label: this.getAsset(deviation.asset), value: deviation.asset});
              this.assetDeviationMap.set(deviation.asset, [deviation]);
            }
          });
          for (let i = 0; i < this.filterOptions.length; i++) {
            if (this.filterOptions[i].value === undefined || this.filterOptions[i].label === undefined){
              this.filterOptions.splice(i, 1);
            }
          }
          this.loading = false;
        })
    );

  }

  ngOnInit(): void {
  }
  /**
   * Filter assets on name search.
   * @param eventValue Search string.
   */
  deviationFilter(eventValue: string) {
    this.deviations = this.dFilter.transform(this.deviations, eventValue);
  }

  /**
   * Set Asset filter
   */
  setDeviationFilter() {
    this.deviations = this.assetDeviationMap.get(this.selectedFilter);
    console.log(this.selectedFilter);
  }

  /**
   * Set station filter
   */
  setDeviationStationFilter() {
    this.loading = true;
    this.selectedFilter = this.translateService.instant('COMMON.SHOW_ALL');
    this.filterOptions = this.stationAssetsMap.get(this.selectedStation);
    this.deviations = this.assetDeviationMap.get(this.selectedFilter);
    this.loading = false;
    console.log(this.filterOptions);
  }

  /**
   * Check screen size.
   */
  screenWidth() {
    return window.innerWidth;
  }

  /**
   * Get value from search event
   * @param $event Search event
   */
  getEventValue($event: Event): string {
    this.search = ($event.target as HTMLInputElement).value;
    return ($event.target as HTMLInputElement).value;
  }
  /**
   * Format creation date
   * @param created ISOString
   */
  getCreatedDate(created: string) {
    return moment(created).format("DD. MMM YYYY");
  }

  /**
   * Format updated date if exists
   * @param created ISOString
   */
  getUpdatedDate(created: string) {
    if (created){
      return moment(created).format("DD. MMM YYYY");
    } else {
      return "";
    }
  }

  /**
   * Open comments.
   * @param deviation Deviation object
   */
  openComments(deviation: Deviation) {
    const comments = this.deviationService.getComments(deviation.key);
    this.dialogService.open(CommentModalComponent, {
      header: this.translateService.instant('COMMON.COMMENTS'),
      data: {
        comments: comments,
        id: deviation.key,
        mode: 'deviation'

      },
      styleClass: 'max-size-sm-dialog-long'
    });
  }

  /**
   * Get asset or set label if none are present
   * @param asset AssetId
   */
  getAsset(asset: string) {
    if (asset) {
      return this.assetsMap.get(asset);
    } else {
      return this.translateService.instant('EVENT.EVAL.VARIOUS');
    }
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Get comment button label
   * @param deviation
   */
  getLabel(deviation:Deviation) {
    if (deviation.comments){
      return this.translateService.instant('COMMON.COMMENTS') + " (" + deviation.comments.length + ")";
    } else {
      return this.translateService.instant('COMMON.COMMENTS') + " (0)";
    }
  }
}
