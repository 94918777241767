import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {SendSMS} from '../../model/sendSMS';
import {SmsService} from '../service/sms.service';

@Injectable({
    providedIn: 'root'
})
export class SmsHelperService {

    constructor(
        private smsService: SmsService,
    ) { }

    /**
     * Calculate total number of SMS
     * @param length Message length
     * @param recipients Number of selected recipients
     */
    numberOfSms(length: number, recipients: number) {

        if(length < 161){
            if(length === 0 || recipients === 0){
                return 0;
            } else {
                return recipients;
            }
        } else {
            return  Math.ceil(length/153) * recipients;
        }
    }

    /**
     * Send SMS
     * @param uid Access control fbid
     * @param recipients Recipients array
     * @param message SMS message
     * @param nrSMS Total number of SMS
     * @param defaultLang 'nb' / 'is'
     * @param exerciseKey exercise fbid
     */
    send(uid: string, recipients: any[], message: string, nrSMS: number, defaultLang, exerciseKey?: string) {
        const today = moment(new Date()).toISOString();
        let sms: SendSMS;
        if (exerciseKey){
            sms = {
                address: [],
                createdBy: uid,
                date: today,
                exerciseId: exerciseKey,
                message: message,
                totalSMS: nrSMS
            };
        } else {
            sms = {
                address: [],
                createdBy: uid,
                date: today,
                message: message,
                totalSMS: nrSMS
            };
        }
        recipients.forEach(participant => {
            const privateCell = participant.privateCell.replace(' ', '').replace(' ', '');
            if (this.validation(participant, defaultLang)){
                sms.address.push(privateCell);
            }
        });
        this.smsService.addSendSMS(sms);
    }

    /**
     * Validate person and cellphone number
     * @param person Person
     * @param defaultLang Language code
     */
    validation(person, defaultLang) {
        if(person.archive){
            return false;
        } else {
            const privateCell = person.privateCell.replace(' ', '').replace(' ', '');
            if(privateCell.length === 8 && defaultLang === 'nb'){
                return true;
            } else { return privateCell.length === 7 && defaultLang === 'is'; }
        }
    }
}
