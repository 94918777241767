import { Component, OnInit } from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {SubjectService} from "../../../services/service/subject.service";
import {InformationService} from "../../../services/service/information.service";
import {PersonService} from "../../../services/service/person.service";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationService, MessageService} from "primeng/api";

@Component({
  selector: 'app-i-m',
  templateUrl: './i-m.component.html',
  styleUrls: ['./i-m.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class IMComponent implements OnInit {
  subscription = new Subscription();
  stations: any[];
  stationMap = new Map();
  infoMessageMap; // {station <fbid>: [message_1, ..., message_n]}
  selectedStation: string;
  loading = true;
  message: string;

  constructor(
    private subjectService: SubjectService,
    private personService: PersonService,
    private infoService: InformationService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.subscription.add(combineLatest(
      [this.subjectService.getStations(), this.infoService.getInfoMessages(), this.personService.getOwnStation()]).subscribe(
        ([stations, infoMessages, ownStation]) => {
          this.selectedStation = ownStation.key;
        this.stations = stations.map(station => {
          return {key: station.key, ...station.payload.val()};
        });
        this.stations.forEach(station => {
          this.stationMap.set(station.key, station.name);
        });
        this.infoMessageMap = new Map<string, string[]>();
        infoMessages.map(messages => {
          this.infoMessageMap.set(messages.key, []);
          let counter = 1;
          messages.payload.val().forEach(message => {
            this.infoMessageMap.get(messages.key).push({nr: counter, text: message});
            counter++;
          });
        });
        this.loading = false;
    }));
  }

  ngOnInit(): void {
  }

  /**
   * Confirm deletion of file
   */
  confirmDelete(message: string) {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE'),
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.deleteMessage(message);
      }
    });
  }

  /**
   * Confirm deletion of file
   */
  confirmStation() {
    this.confirmationService.confirm({
      message: this.translateService.instant('CONFIRMATION.STATION') + "&nbsp;" + this.stationMap.get(this.selectedStation),
      acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
      rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
      accept: () => {
        this.addMessage();
      }
    });
  }

  /**
   * Delete message
   * @param message Message to delete
   */
  deleteMessage(message: string) {
    const index = this.infoMessageMap.get(this.selectedStation).indexOf(message);
    this.infoMessageMap.get(this.selectedStation).splice(index, 1);
    const temp = [];
    this.infoMessageMap.get(this.selectedStation).forEach(element =>{
      temp.push(element.text);
    });
    this.infoService.updateInfoMessages(this.selectedStation, temp);
  }

  /**
   * Add message
   */
  addMessage() {
    if (this.message.length <= 80){
      const temp = [];
      if (this.infoMessageMap.has(this.selectedStation)) {
        this.infoMessageMap.get(this.selectedStation).forEach(element => {
          temp.push(element.text);
        });
      }
      temp.push(this.message);
      this.infoService.updateInfoMessages(this.selectedStation, temp);
      this.message = "";
    } else {
      this.messageService.add({severity:'error', summary: this.translateService.instant('TOAST.ERROR'),
        detail: this.translateService.instant('ERROR.INVALID_LENGTH')});
    }
  }
}
