import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig} from "primeng/dynamicdialog";
import {Subscription} from "rxjs";
import {AttestationService} from "../../../services/service/attestation.service";
import * as moment from "moment";
import {ExerciseService} from "../../../services/service/exercise.service";

@Component({
  selector: 'app-competency-history',
  templateUrl: './competency-history.component.html',
  styleUrls: ['./competency-history.component.scss']
})
export class CompetencyHistoryComponent implements OnInit {

  subscription = new Subscription();
  competencyTypeId: string;
  subjectId: string;
  relevantAttestations: any[] = [];
  exerciseNameMap: Map<string, string> = new Map();

  constructor(
    private config: DynamicDialogConfig,
    private attestationService: AttestationService,
    private exerciseService: ExerciseService,
  ) {
    this.competencyTypeId = this.config.data.competency.competencyTypeId;
    this.subjectId = this.config.data.competency.personId;

    this.subscription.add(
      this.attestationService.getPersonAttestationsSnapshot(this.subjectId).subscribe(payload => {
        this.exerciseService.getCompletedExercises().subscribe(exercises => {

          exercises.forEach(exercise => {
            this.exerciseNameMap.set(exercise.key, exercise.payload.val().name);
          });

          const attestations = payload.map(attestation => {
            return {key: attestation.key, ...attestation.payload.val()};
          });

          attestations.forEach(attestation => {
            if (attestation.competencyTypeId === this.competencyTypeId){
              this.relevantAttestations.push(attestation);
            }
          });
          console.log(this.relevantAttestations);
        });
      })
    );
  }

  ngOnInit(): void {

  }

  /**
   * Format ISOString to readable date
   * @param ISOString : string
   */
  getDate(ISOString: string) {
    return moment(ISOString).format("DD.MM.YYYY");
  }

  /**
   * Get exercise name form FBID
   * @param exerciseId : string
   */
  getExerciseName(exerciseId: string) {
    return this.exerciseNameMap.get(exerciseId);
  }
}
