<div class="grid">
  <div class="col-12" style="padding: 0">
    <p-table [value]="exercise?.participants" breakpoint="200px">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col">
            <p-autoComplete (completeMethod)="search($event)"
                            (onSelect)="addPerson(selectedSubject)"
                            [(ngModel)]="selectedSubject"
                            [dropdown]="true"
                            [group]="true"
                            [suggestions]="output | exerciseParticipantsFilter: participantsMap"
                            appendTo="body"
                            class="inputfield w-full"
                            field="label"
                            placeholder="{{'EVENT.ADD_PERSON' | translate}}"
                            scrollHeight="400px"
                            size="100%"
                            type="search"
            >
              <ng-template let-team pTemplate="group">
                <div class="flex align-items-center p-2" (click)="addTeam(team)">
                  <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                </div>
              </ng-template>
              <ng-template let-subject pTemplate="item">
                <div class="subject-item">
                  {{subject.label}}
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
          <div class="col-fixed" style="width: 90px">
            <p-button label="{{'BUTTON.SAVE' | translate}}" (onClick)="save()"></p-button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 40%">{{'COMMON.NAME' | translate}}</th>
          <th style="width: 40%">{{'SUBJECTS.TYPES.FIRETEAM' | translate}}</th>
          <th><i class="far fa-graduation-cap"></i></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-participant>
        <tr (click)="remove(participant)">
          <td>{{personNameMap.get(participant.key)}}</td>
          <td>{{personParentNameMap.get(participant.key)}}</td>
          <td>{{(participant.competencyTypes) ? getSize(participant.competencyTypes) : 0}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'REPORT.NO_SUBJECTS' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
