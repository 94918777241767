import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ExposureService} from '../../../services/service/exposure.service';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {AccessControlService} from '../../../services/service/access-control.service';
import {Exposure} from '../../../model/exposure';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Roles, RoleType} from '../../../model/role';
import {RolesService} from '../../../services/service/roles.service';
import {ConfirmationService, FilterService} from 'primeng/api';
import * as moment from 'moment';
import {EmergencyAddOvertimeComponent} from '../emergency-add-overtime/emergency-add-overtime.component';
import {AddRolesComponent} from '../add-roles/add-roles.component';
import {AddEvaluationComponent} from '../add-evaluation/add-evaluation.component';

import {LocationData} from '../../../model/locationData';
import {ExposureParticipant} from '../../../model/participant';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';
import * as _ from 'lodash';
import {EmergencyEval} from "../../../model/emergencyEval";

@Component({
  selector: 'app-exposure-edit',
  templateUrl: './exposure-edit.component.html',
  styleUrls: ['./exposure-edit.component.scss'],
  providers: [DialogService, ConfirmationService],
})
export class ExposureEditComponent implements OnInit, OnDestroy {

  addedParticipants: any[] = [];

  // Exposure
  exposureForm: UntypedFormGroup;
  exposure: Exposure;
  exposureCopy: Exposure;
  exposureTypes: any[]; // {key: "Royk", label: Røyk}
  exposureTimeMap = new Map<string, string>(); // {key: personId, value: time in hours and minutes}

  // Subscription
  subscription = new Subscription();

  // Person
  personMap = new Map();
  personNameMap = new Map<string, string>();
  outputPerson = []; // [{items: {label: Per Person, value: personId}, label: Station 1 Team 1, value: stationId}]
  personArray = []; // [name-1, name-2, ..., name-n]
  personArrayCopy = []; // [name-1, name-2, ..., name-n]
  selectedPerson: '';

  // Teams
  groupedFilter = [];

  // Roles
  isRoles = false;
  roleTypes: RoleType[];
  roles: Roles[];
  roleMap: Map<string, string[]> = new Map<string, string[]>(); // {role --> names}
  selectedRolesMap: Map<string, string[]> = new Map<string, string[]>(); // {name --> roles}
  existingRolesMap = new Map<string, string[]>();

  // Evaluation
  evaluation: EmergencyEval;
  evaluationCopy: EmergencyEval;

  // Event time space
  workTimeMap = new Map<string, {hours, minutes}>();
  timeMap = new Map<string, {start, end}>();
  timePeriodChange = new Map<string, boolean>();

  constructor(
    private exposureService: ExposureService,
    private evaluationService: EvaluationService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private config: DynamicDialogConfig,
    private roleService: RolesService,
    private confirmationService: ConfirmationService,
    private filterService: FilterService,
    private authService: AuthService,
    private ref: DynamicDialogRef,
  ) {
    this.exposure = this.config.data.exposure;
    this.exposureCopy = _.clone(this.exposure);

    this.exposureTypes = this.exposureService.getExposureTypes();
    this.groupedFilter = this.config.data.groupedFilter;
    this.personNameMap = this.config.data.personNameMap;
    if (this.config.data.evaluation) {
      this.evaluation = this.config.data.evaluation;
    }

    this.evaluationCopy = this.evaluation;

    // Get roles
    this.subscription.add(
      this.roleService.getRoleTypes().subscribe( roleTypes => {
        this.roleTypes = roleTypes.map(type => {
          return {key: type.key, ...type.payload.val()};
        });
      })
    );

    // Get roles
    this.subscription.add(
      this.roleService.getRoles().subscribe( roles => {
        this.roles = roles.map(role => {
          return {key: role.key, ...role.payload.val()};
        });

        this.roles.forEach(role => {
          if (role.eventKey === this.exposure.key){
            if (this.existingRolesMap.has(role.personKey)){
              this.existingRolesMap.get(role.personKey).push(role.typekey);
            } else {
              this.existingRolesMap.set(role.personKey, [role.typekey]);
            }
          }
        });

      })
    );

    this.exposure.participants.forEach(participant => {
      if (participant.exposureStart && participant.exposureEnd) {
        this.workTimeMap.set(participant.key, this.getHoursAndMin(participant.exposureStart, participant.exposureEnd));
        this.timeMap.set(participant.key, {start: participant.exposureStart, end: participant.exposureEnd});
        this.timePeriodChange.set(participant.key, true);
      } else {
        this.workTimeMap.set(participant.key, this.getHoursAndMin(this.exposure.timeStart, this.exposure.timeEnd));
        this.timeMap.set(participant.key, {start: this.exposure.timeStart, end: this.exposure.timeEnd});
        this.timePeriodChange.set(participant.key, false);
        this.exposureTimeMap.set(participant.key, "00:00");
      }

      if (participant.roles) {
        participant.roles.forEach(role => {
          if(this.roleMap.has(role)) {
            this.roleMap.get(role).push(participant.key);
          } else {
            this.roleMap.set(role, [participant.key]);
          }
        });
        this.selectedRolesMap.set(participant.key, participant.roles);
      } else {
        this.selectedRolesMap.set(participant.key, []);
      }
      this.personMap.set(participant.key, true);
      this.personArray.push(participant.key);
      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a).toLowerCase().localeCompare(this.personNameMap.get(b).toLowerCase());
      });
    });

    this.personArrayCopy = _.clone(this.personArray);
  }

  /**
   * On initialization crate exposure form.
   */
  ngOnInit(): void {
    this.exposureForm = new UntypedFormGroup({
      brisNumber: new UntypedFormControl(this.exposure.brisNumber),
      date: new UntypedFormControl(this.exposure.date, Validators.required),
      details: new UntypedFormControl(this.exposure.details),
      exposureType: new UntypedFormControl(this.exposure.exposureType, Validators.required),
      timeStart: new UntypedFormControl(this.exposure.timeStart, Validators.required),
      timeEnd: new UntypedFormControl(this.exposure.timeEnd, Validators.required),
      address: new UntypedFormControl(this.exposure.location.address, Validators.required),
      zip: new UntypedFormControl(this.exposure.location.zip),
      postal: new UntypedFormControl(this.exposure.location.postal),
    });
  }

  /**
   * Add team to table
   * @param team Team array
   */
  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  /**
   * Add person to table
   * @param subject Input person
   */
  addPerson(subject) {
    if (!this.personMap.has(subject.value)) {
      // For work hours
      this.workTimeMap.set(subject.value, this.getHoursAndMin(this.exposureForm.value.timeStart, this.exposureForm.value.timeEnd));
      this.timeMap.set(subject.value, {start: this.exposureForm.value.timeStart, end: this.exposureForm.value.timeEnd});
      this.timePeriodChange.set(subject.value, false);

      this.personMap.set(subject.value, true);
      this.exposureTimeMap.set(subject.value, "00:00");
      this.personArray.push(subject.value);
      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a).toLowerCase().localeCompare(this.personNameMap.get(b).toLowerCase());
      });
      this.selectedPerson = '';
    }
  }

  /**
   * Remove person from table
   * @param personId person to remove
   */
  deletePerson(personId: string) {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE')
        + ':  <br/><br/>' + this.personNameMap.get(personId)  + '<br/><br/>'
        + this.translateService.instant('EVENT.EDIT.DELETE_PERSON (2)'),
      accept: () => {
        this.personMap.delete(personId);
        this.selectedRolesMap.delete(personId);
        this.roleMap.forEach(persons => {
          const i = persons.findIndex(pId => pId === personId);
          if(i >= 0) {
            persons.splice(i, 1);
          }
        });
        this.exposureTimeMap.delete(personId);
        const j = this.personArray.findIndex(pId => pId === personId);
        if(j >= 0) {
          this.personArray.splice(j, 1);
        }
      }
    });

  }

  /**
   * Get hours and minutes from moment
   * @param s Start moment
   * @param e End moment
   */
  getHoursAndMin(s, e){

    if (s && e) {
      const start = moment(this.exposure.date.split('.').reverse().join('-')).add(s);
      const end = moment(this.exposure.date.split('.').reverse().join('-')).add(e);
      if (end.diff(start) < 0) {
        end.add(1, 'days');
      }
      return {hours: end.diff(start, "hours"), minutes: end.diff(start, "minutes") % 60};
    } else {
      return {hours: 0, minutes: 0};
    }
  }

  /**
   * Add exposed time interval.
   * @param personId Unique person
   */
  exposureTime(personId: string) {
    if (this.exposureForm.value.exposureType === 'Sot' || this.exposureForm.value.exposureType === 'Royk') {
      let s;
      let e;
      if (this.timePeriodChange.get(personId)) {
        s = this.timeMap.get(personId).start;
        e = this.timeMap.get(personId).end;
      } else {
        s = this.exposureForm.value.timeStart;
        e = this.exposureForm.value.timeEnd;
      }

      this.dialogService.open(EmergencyAddOvertimeComponent, {
        header: this.personNameMap.get(personId) + ': ' + this.translateService.instant('EVENT.CHANGE_EXPOSURETIME'),
        width: "300px",
        data: {
          start: s,
          end: e
        }
      }).onClose.subscribe(time => {
        if (time) {
          const date = this.exposureForm.value.date;
          this.workTimeMap.set(personId, this.getHoursAndMin(moment(date).add(time.start), moment(date).add(time.end)));
          this.timeMap.set(personId, {start: time.start, end: time.end});
          this.timePeriodChange.set(personId, true);
        }
      });
    }
  }

  /**
   * Add roles to persons
   */
  addRoles() {
    this.dialogService.open(AddRolesComponent, {
      header: this.translateService.instant('ROLES.ADD_ROLE'),
      styleClass: 'max-size-width-dialog',
      data: {
        personArray: this.personArray,
        personNameMap: this.personNameMap,
        selectedRolesMap: this.selectedRolesMap,
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.roleMap = data[0];
        this.selectedRolesMap = data[1];

        this.selectedRolesMap.forEach(personRoles => {
          if (personRoles.length > 0){
            this.isRoles = true;
          }
        });
      }
    });
  }

  /**
   * Display exposed time in hours and minutes.
   * @param personId Unique person
   */
  printTime(personId: string) {

    const hours = this.workTimeMap.get(personId).hours;
    const minutes = this.workTimeMap.get(personId).minutes;

    if (hours !== 0){
      if (minutes !== 0){
        return hours + this.translateService.instant('EVENT.HOUR') + " " + minutes + this.translateService.instant('EVENT.MINUTES');
      } else {
        return hours + this.translateService.instant('EVENT.HOUR');
      }
    } else {
      return minutes + this.translateService.instant('EVENT.MINUTES');
    }

  }

  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Add evaluations
   */
  addEvaluation() {
    this.dialogService.open(AddEvaluationComponent, {
      header: this.translateService.instant('COMMON.EVALUATE'),
      styleClass: 'max-size-width-dialog',
      data: {
        evaluation: this.evaluation,
        evaluationCopy: this.evaluationCopy,
        fromExpo: true,
      }
    }).onClose.subscribe(evaluation => {
      if (evaluation) {
        if (evaluation) {
          this.evaluationCopy.description = evaluation.get("description");
          this.evaluationCopy.learningPoints = evaluation.get("learningPoints");
          this.evaluationCopy.measuresOnSite = evaluation.get("measuresOnSite");
          this.evaluationCopy.various = evaluation.get("various");
        }
      }
    });
  }

  /**
   * On submit save roles and exposure.
   */
  onSubmit() {

    const deletedPersons = [];
    this.personArrayCopy.forEach(Id => {
      if(!this.personArray.includes(Id)){
        deletedPersons.push(Id);
      }
    });
    const addedPersons = [];
    this.personArray.forEach(Id => {
      if(!this.personArrayCopy.includes(Id)){
        addedPersons.push(Id);
      }
    });

    const rolesToRemove = new Map();
    const rolesToCreate = new Map();

    // Check for deleted persons and compare existing ones
    for(const [personId, roleList] of this.existingRolesMap){
      if(!this.selectedRolesMap.has(personId)){
        rolesToRemove.set(personId, roleList);
      } else {
        const roleChange = this.selectedRolesMap.get(personId);
        // Get elements in roleList that are not in roleChange
        const oldRoles = roleList.filter(role => !roleChange.includes(role));
        // Get elements in roleChange that are not in roleList
        const newRoles = roleChange.filter(role => !roleList.includes(role));

        if (oldRoles.length > 0) {
          rolesToRemove.set(personId, oldRoles);
        }
        if (newRoles.length > 0) {
          rolesToCreate.set(personId, newRoles);
        }
      }
    }

    // Add new persons to create list
    for(const [personId, roleList] of this.selectedRolesMap){
      if (!this.existingRolesMap.has(personId)){
        rolesToCreate.set(personId, roleList);
      }
    }

    const today = new Date().toISOString();
    const ongoing = this.exposureForm.value;

    if (rolesToCreate){
      this.addRolesToDB(this.exposure.key, rolesToCreate, this.exposure.date, today);
    }
    if (rolesToRemove){
      this.deleteRoles(this.exposure.key, rolesToRemove);
    }

    const locationData: LocationData = {
      address: ongoing.address,
      postal: ongoing.postal,
      zip: ongoing.zip

    };

    const exposureParticipants: ExposureParticipant[] = [];

    this.personArray.forEach(personKey => {
      const roles = [];
      for(const [roleKey, list] of this.roleMap){
        if (list.includes(personKey)){
          roles.push(roleKey);
        }
      }
      if (ongoing.exposureType === 'Sot' || ongoing.exposureType === 'Royk'){
        if (roles.length > 0) {
          const participant = {
            key: personKey,
            newData: true,
            exposureEnd: this.timeMap.get(personKey).end,
            exposureStart: this.timeMap.get(personKey).start,
            roles: roles
          };
          this.pushChange(personKey, participant, addedPersons);
          exposureParticipants.push(participant);
        } else {
          const participant = {
            key: personKey,
            newData: true,
            exposureEnd: this.timeMap.get(personKey).end,
            exposureStart: this.timeMap.get(personKey).start,
          };
          this.pushChange(personKey, participant, addedPersons);
          exposureParticipants.push(participant);
        }
      } else {
        if (roles.length > 0) {
          const participant = {
            key: personKey,
            newData: true,
            exposureEnd: '',
            exposureStart: '',
            roles: roles
          };
          this.pushChange(personKey, participant, addedPersons);
          exposureParticipants.push(participant);
        } else{
          const participant = {
            key: personKey,
            newData: true,
            exposureEnd: '',
            exposureStart: ''
          };
          this.pushChange(personKey, participant, addedPersons);
          exposureParticipants.push(participant);
        }
      }
    });

    const deletedParticipants = [];
    if (deletedPersons.length > 0){
      this.exposure.participants.forEach(participant => {
        if (deletedPersons.includes(participant.key)){
          deletedParticipants.push(participant);
        }
      });
    }

    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

      let changeLog = [];
      if (addedPersons || deletedPersons) {
        if (this.exposure.changeLog) {
          changeLog = this.exposure.changeLog;
        }

        changeLog.push({changedBy: uid, changedDate: today, added: this.addedParticipants, deleted: deletedParticipants});
      }

      let evalId = '';
      if (this.exposure.evaluationId){
        evalId = this.exposure.evaluationId;
      }
      let date = this.exposure.date;
      if (ongoing.date !== date){
        date = moment(ongoing.date).format("DD.MM.YYYY");
      }
      const exposure: Exposure = {
        brisNumber: ongoing.brisNumber,
        changeLog: changeLog,
        createdById: this.exposure.createdById,
        date: date,
        dateCreated: this.exposure.dateCreated,
        details: ongoing.details,
        exposureType: ongoing.exposureType,
        location: locationData,
        evaluationId: evalId,
        participants: exposureParticipants,
        timeEnd: ongoing.timeEnd,
        timeStart: ongoing.timeStart,
      };

      if (_.isEqual(this.evaluation, this.evaluationCopy) && this.checkForChanges(exposure)){
        this.exposureService.updateExposure(this.exposure.key, exposure);
      } else {
        // const exposureEval: ExposureEval = {
        //   createdById: uid,
        //   dateCreated: today,
          // exposureId: this.exposure.key,
          // description: this.evaluation.get('description'),
          // measuresOnSite: this.evaluation.get('measuresOnSite'),
          // learningPoints: this.evaluation.get('learningPoints'),
          // various: this.evaluation.get('various'),
        // };
        // if (this.exposure.evaluationId) {
        //   this.evaluationService.updateExposureEvaluation(evalId, exposureEval);
        // } else {
        //   this.evaluationService.addExposureEvaluationGetKey(exposureEval).pipe(take(1)).subscribe(
        //     evalKey => {
        //       this.updateExposure(evalKey, this.exposure.key);
        //     });
        // }
      }
    });
    this.ref.close();
  }

  /**
   * Check if person is added or removed
   * @param personKey Person fbid
   * @param participant Participant object
   * @param addedPersons List of added persons fbid
   */
  pushChange(personKey, participant, addedPersons){
    if (addedPersons.includes(personKey)){
      this.addedParticipants.push(participant);
    }
  }

  /**
   * Add all selected roles to DB
   * @param exposureKey EmergencyId
   * @param rolesToCreate New roles to save
   * @param date Date for event
   * @param today Date created
   */
  addRolesToDB(exposureKey, rolesToCreate, date, today){
    for(const [key, list] of rolesToCreate){
      list.forEach(roleType => {
        const role: Roles = {
          shiftReport: false, eventKey: exposureKey, dateCreated: today, date: date, personKey: key, typekey: roleType
        };
        this.roleService.addRole(role);
      });
    }
  }

  /**
   * Add all selected roles to DB
   * @param exposureKey EmergencyId
   * @param rolesToRemove Roles to delete
   */
  deleteRoles(exposureKey, rolesToRemove){
    this.roles.forEach(role => {
      if (role.eventKey === exposureKey && rolesToRemove.get(role.personKey)){
        if (rolesToRemove.get(role.personKey).includes(role.typekey)){
          this.roleService.deleteRole(role);
        }
      }
    });
  }

  /**
   * Returns true if any form data is changed
   * (Does not check evaluations addedParticipants or addedParticipants to exposures)
   * @param exposureForm Form data
   */
  checkForChanges(exposureForm: Exposure){
    for (const exposureValueField in exposureForm) {
      if (exposureValueField !== "createdById" && exposureValueField !== "dateCreated"
        && exposureValueField !== "changeLog" && exposureValueField !== "evaluationId") {
        if (!_.isEqual(exposureForm[exposureValueField], this.exposureCopy[exposureValueField])) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Updating exposure with evaluations key.
   * @param evaluationKey Unique evaluations key
   * @param exposureKey Unique exposure key
   */
  updateExposure(evaluationKey, exposureKey) {
        this.exposure.evaluationId = evaluationKey;
        delete this.exposure.key;
        this.exposureService.updateExposure(exposureKey, this.exposure);
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.ref.close();
  }
}
