<div class="grid">
    <div class="col-12 p-0">
        <p-table [value]="fileTags | nameFilter: search" selectionMode="multiple" [(selection)]="selectedCategory"
                 dataKey="key" responsiveLayout="scroll" [paginator]="true" [rows]="11">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-12">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" [(ngModel)]="search"  class="inputfield"
                                placeholder="{{'FILE.FILENAME_SEARCH' | translate}}"/>
                        </span>
                    </div>
                </div>
            </ng-template>

            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'FILE.CATEGORIES' | translate}}</th>
                </tr>
            </ng-template>

            <!-- Body -->
            <ng-template pTemplate="body" let-tag let-rowIndex="rowIndex">
                <tr [pSelectableRow]="tag" [pSelectableRowIndex]="rowIndex">
                    <td>{{tag.name}}</td>
                </tr>
            </ng-template>

            <!-- Shows message if table is empty -->
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        {{'FILE.NO_CATEGORIES' | translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col-12 flex justify-content-end">
        <span class="p-buttonset">
            <button pButton type="button" label="{{'BUTTON.CANCEL' | translate}}" (click)="close()"
                    class="p-button-outlined p-button-raised"></button>
            <button pButton type="button" class="p-button-raised" label="{{'BUTTON.SAVE' | translate}}"
                    (click)="complete()" [disabled]="!selectedCategory"></button>
        </span>
    </div>
</div>
