<div class="grid">
    <div class="col-12">
        <h2>{{'TASK.COMPLETED' | translate}}</h2>
    </div>

    <!-- TASK TABLE -->
    <div class="col-12 pr-0 pl-0">
        <p-table #att [value]="tasks | dashboardFilter: 'task' : selectedTaskFilter?.key : stations" [rowHover]="true"
                 [loading]="taskLoading" [globalFilterFields]="['name']" responsiveLayout="stack" breakpoint="200px"
                 >
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="3">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="att.filterGlobal($event.target.value, 'contains')"
                                placeholder="{{'COMMON.SEARCH' | translate}}"/>
                        </span>
                    </th>
                    <th colspan="2">
                        <div class="flex justify-content-end">
                            <p-dropdown [options]="taskFilter" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"
                                        [(ngModel)]="selectedTaskFilter" optionLabel="name">
                                <ng-template pTemplate="selectedItem">
                                    <div class="task-item task-item-value" *ngIf="selectedTaskFilter">
                                        <div>{{selectedTaskFilter.name | translate}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-filter pTemplate="item">
                                    <div class="filter-item">
                                        <div>{{filter.name | translate}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th colspan="2">{{'COMMON.NAME' | translate}}</th>
                    <th>{{'ASSET.ASSET' | translate}}</th>
                    <th>{{'COMMON.DATE.DATE' | translate}}</th>
                    <th>{{'COMMON.TIME' | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-task>
                <tr (click)="viewTask(task)">
                    <td colspan="2">{{task.name}}</td>
                    <td>{{assetNameMap.get(task.assetId)}}</td>
                    <td>{{getDate(task.deadline)}}</td>
                    <td>{{task.deadline | amDateFormat: 'HH:mm'}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">{{'DASHBOARD.TASK_CARD_NOT_FOUND' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

