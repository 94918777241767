import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Competency} from "../../../model/competency";
import {Table} from "primeng/table";
import {AccessControlService} from "../../../services/service/access-control.service";
import {SubjectService} from "../../../services/service/subject.service";
import {PersonService} from "../../../services/service/person.service";
import {Subscription} from "rxjs";
import {take} from "rxjs/operators";
import * as moment from 'moment';
import {SetCompetenciesComponent} from "../set-competencies/set-competencies.component";
import {DialogService} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {CompetencyHistoryComponent} from "../competency-history/competency-history.component";

@Component({
  selector: 'app-competencies-list',
  templateUrl: './competencies-list.component.html',
  styleUrls: ['./competencies-list.component.scss'],
})


export class CompetenciesListComponent implements OnInit, OnDestroy {
  @Input() competencies: Competency[];
  @Input() parentChildMap;
  @Input() groupedFilter: any[];// A hierarchy dropdown of stations and teams
  selectedSubject: any;
  subscription = new Subscription();
  isAdmin = false;
  isSuperuser = false;
  loading = true;
  search = '';


  constructor(
    private acService: AccessControlService,
    private subjectService: SubjectService,
    private personService: PersonService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.acService.getOwnAccessControl().subscribe(ownAC => {
        this.loading = true;

        if (ownAC.roles.admin) {
          this.isAdmin = true;
          this.isSuperuser = false;
          this.personService.getOwnStation().pipe(
            take(1),
          ).subscribe(station => {
            this.selectedSubject = station.key;
            this.loading = false;
          });
        } else if (ownAC.roles.superuser) {
          this.isSuperuser = true;
          this.isAdmin = false;
          this.personService.getOwnTeam().pipe(
            take(1),
          ).subscribe(team => {
            this.selectedSubject = team.key;
            this.loading = false;
          });
        }
      })
    );
  }


  openHistory(competency: any) {
    // this.dialogService.open(CompetencyHistoryComponent, {
    //   header: this. translateService.instant('COMMON.COMPETENCY_HISTORY'),
    //   styleClass: "max-width-dialog",
    //   data: {
    //     competency: competency
    //   }
    // });
  }

  /**
   * Check screen size.
   */
  screenWidth() {
    return window.innerWidth < 441;
  }

  newDate(ISO_string) {
    return moment(ISO_string).format('D MMM   y');
  }

  setStatus(status: number) {
    const success = '#73BF21';
    const warning = '#F5A623';
    const danger = '#D0021B';
    const white = '#fff';
    if (status === 1) {
      return {'background': danger, 'color': white};
    } else if (status === 2) {
      return {'background': warning, 'color': white};
    } else {
      return {'background': success, 'color': white};
    }
  }

  filter(ct: Table) {
    ct.filterGlobal(this.search, 'contains');
  }

  resetSort(ct: Table) {
    this.search = '';
    ct.filterGlobal(this.search, 'contains');
    ct._value.sort((a, b) => a.status - b.status);
    ct.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
