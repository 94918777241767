import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Deviation} from '../../../model/deviation';
import {AssetService} from '../../../services/service/asset.service';
import {combineLatest, Subscription} from 'rxjs';
import {Asset} from '../../../model/asset';
import * as moment from 'moment';
import {PersonService} from '../../../services/service/person.service';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CommentModalComponent} from '../../checklist/comment-modal/comment-modal.component';
import {DeviationService} from '../../../services/service/deviation.service';

@Component({
  selector: 'app-view-deviation',
  templateUrl: './view-deviation.component.html',
  styleUrls: ['./view-deviation.component.scss'],
  providers: [DialogService]
})
export class ViewDeviationComponent implements OnInit, OnDestroy {
  splitButton: MenuItem[];

  deviation: Deviation;
  inCommission: boolean;

  subscription = new Subscription();

  assetNameMap = new Map<string, string>();
  assetsMap = new Map<string, Asset>();

  personNameMap = new Map<string, string>();

  loading = true;
  constructor(
      private config: DynamicDialogConfig,
      private ref: DynamicDialogRef,
      private assetService: AssetService,
      private personService: PersonService,
      private translateService: TranslateService,
      private dialogService: DialogService,
      private deviationService: DeviationService,
  ) {
    this.deviation = this.config.data.deviation;

    this.subscription.add(combineLatest([this.assetService.getAssets(), this.personService.getUIDPersonNameMap()]).subscribe(
        ([assets, uidNameMap]) =>{
          this.assetNameMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));
          assets.forEach(asset => {
            this.assetsMap.set(asset.key, asset.payload.val());
          });
          this.personNameMap = uidNameMap;
          this.loading = false;
        })

    );
  }

  ngOnInit(): void {
  }

  /**
   * Return correct date format
   * @param deviation Object
   */
  getDate(deviation: Deviation) {
    return moment(deviation.created).format("DD.MM YYYY")
  }

  /**
   * Set status checkbox
   * @param deviation Object
   */
  getStatus(deviation) {
    return this.assetsMap.get(deviation.asset).inCommission
  }

  /**
   * Get person name
   * @param deviation Object
   */
  getName(deviation: Deviation) {
    return this.personNameMap.get(deviation.createdById)
  }

  /**
   * Open comments
   */
  openComments() {
    this.dialogService.open(CommentModalComponent, {
      header: this.deviation.name,
      styleClass: 'max-size-dialog-short-2',
      data: {
        id: this.deviation.key,
        mode: "deviation"
      }
    });
  }

  /**
   * Complete deviation
   */
  complete() {
    this.deviation.closed = moment().toISOString();
    this.deviationService.updateDeviation(this.deviation.key, this.deviation);
    this.ref.close(true);
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getNumber() {
    const number = '(' + this.deviation?.comments?.length + ')';
    if (number !== '(null)' && number !== '(undefined)'){
      return number;
    } else {
      return '(0)';
    }
  }
}
