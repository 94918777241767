import {Component, OnInit} from '@angular/core';
import {SubjectService} from "../../../services/service/subject.service";
import {Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {PersonSubject} from "../../../model/subject";
import {ConfirmationService, MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {MoveSubjectComponent} from "../move-subject/move-subject.component";
import {CompetencyService} from "../../../services/service/competency.service";
import {AttestationService} from "../../../services/service/attestation.service";
import {SdaService} from "../../../services/service/sda.service";
import {AuthService} from "../../../services/service/auth.service";
import {SubjectDisableAttestations} from "../../../model/subjectDisableAttestation";
import {AccessControlService} from "../../../services/service/access-control.service";
import {TenantService} from '../../../services/service/tenant.service';

@Component({
  selector: 'app-person-detail',
  templateUrl: './person-detail.component.html',
  styleUrls: ['./person-detail.component.scss'],
  providers: [DialogService, ConfirmationService]
})

/**
 * This component handles the showing and editing of a persons details.
 */
export class PersonDetailComponent implements OnInit {
  subject: PersonSubject = {
    address: "",
    address2: "",
    issi: "",
    mainEmployer: "",
    archive: false,
    dateOfBirth: undefined,
    email: "",
    email2: "",
    name: "",
    notes: "",
    postal: "",
    privateCell: "",
    title: "",
    type: "",
    workCell: "",
    zip: ""
  };

  oldSubject;
  subjectId;
  showCalendar = false;
  jobTitles = [];

  subscription = new Subscription();
  splitButton: any;
  stations: any[];

  projectType: string;

  constructor(
    private subjectService: SubjectService,
    public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private translateService: TranslateService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private competencyService: CompetencyService,
    private sdaService: SdaService,
    private attestationService: AttestationService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private acService: AccessControlService,
    private tenantService: TenantService,
  ) {
    this.subjectId = this.config.data.subjectId;

    this.subscription.add(
      this.subjectService.getPerson(this.subjectId).subscribe(subject => {
        this.subject = {...subject};
        this.subject.dateOfBirth = this.convertDateToString(new Date(subject.dateOfBirth));
        this.oldSubject = {...this.subject};
      })
    );

    this.stations = [];
    this.subscription.add(
      this.subjectService.getStations().subscribe(stations => {
        this.stations = stations.map(station => {
          return {key: station.key, ...station.payload.val()};
        });
      })
    );

    // Build split button on create.
    this.buildSplitButton();


    // Update split button on language change
    this.subscription.add(
      this.translateService.onLangChange.pipe().subscribe(() => {
        this.buildSplitButton();
      })
    );
  }

  ngOnInit(): void {

    // Get Job Titles
    this.subjectService.getJobTitles().pipe(
      take(1),
    ).subscribe(jobTitles => {
      this.jobTitles = jobTitles;
    });

    // Get project type
    this.tenantService.getProjectType().pipe(take(1)).subscribe(projectType => {
      this.projectType = projectType;
    });

  }

  /**
   * Takes inn a new Date() object and returns YYYY-MM-DD
   */
  convertDateToString(date){
    return date.getFullYear() + '-' + ('0'+(date.getMonth()+1)).slice(-2) + '-' + ('0'+ date.getDate()).slice(-2);
  }

  /**
   * Saves and send a signal to subject list to update nodes.
   */
  save() {
    if (!(JSON.stringify(this.subject) === JSON.stringify(this.oldSubject))) {
      this.subject.dateOfBirth = new Date(this.subject.dateOfBirth).toISOString();
      this.subjectService.updateSubject(this.subjectId, this.subject);
      this.ref.close(this.subjectId);
    } else {
      this.messageService.add(
        {key: 'function-person-detail', severity: 'warn', detail: this.translateService.instant('WARNING.NO_DATA_CHANGED'), life: 4000}
      );
    }
  }

  /**
   * Archive subject
   */
  archive() {
    if (!this.subject.archive) {
      this.confirmationService.confirm({
        message: this.translateService.instant('WARNING.ARE_YOU_SURE'),
        acceptLabel: this.translateService.instant('CONFIRMATION.YES'),
        rejectLabel: this.translateService.instant('CONFIRMATION.NO'),
        accept: () => {

          // ADD SDA for each Competency a person has.
          this.authService.getUserUID()
            .pipe(
              take(1),
            ).subscribe(uid => {
            this.competencyService.getCompetenciesForPerson(this.subjectId)
              .pipe(
                take(1),
              ).subscribe(competencies => {
              competencies.forEach(competency => {
                const sda: SubjectDisableAttestations = {
                  activePersonId: this.subjectId,
                  closedBy: "",
                  competencyTypeId: competency.competencyTypeId,
                  createdBy: uid,
                  dateStart: new Date().toISOString(),
                  status: true,
                  subjectId: this.subjectId,
                  type: "Arkivert"
                };
                this.sdaService.addSDA(sda);
              });
            });
          });

          this.acService.updateTenantUserAdminOperationStatus(this.subjectId, 'disabled');
          this.subjectService.removeRelationWithChildId(this.subjectId);
          this.subject.archive = true;
          this.subjectService.updateSubject(this.subjectId, this.subject);
          this.ref.close();
        }
      });
    }
  }

  /**
   * Move subject
   * @private
   */
  private moveSubject() {
    const moveSubject = this.dialogService.open(MoveSubjectComponent, {
      header: this.translateService.instant('SUBJECTS.MOVE') + ' : ' + this.subject.name,
      data: {
        subjectId: this.subjectId
      },
    });

    moveSubject.onClose.pipe(
      take(1),
    ).subscribe(moved => {
      if (moved) {
        this.ref.close(moved);
      }
    });
  }

  /**
   * Build split button
   * @private
   */
  private buildSplitButton() {
    this.splitButton = [
      {
        label: this.translateService.instant('BUTTON.ARCHIVE'), icon: 'far fa-archive', command: () => {
          this.archive();
        }
      },
      {separator: true},
      {
        label: this.translateService.instant('SUBJECTS.MOVE'), icon: 'far fa-retweet', command: () => {
          this.moveSubject();
        }
      },
    ];
  }
}
