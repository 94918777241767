import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Exercise} from '../../../model/exercise';
import {ExerciseService} from '../../../services/service/exercise.service';

@Component({
  selector: 'app-exercise-evaluations',
  templateUrl: './exercise-evaluation.component.html',
  styleUrls: ['./exercise-evaluation.component.scss']
})
export class ExerciseEvaluationComponent implements OnInit {

  exercise: Exercise;

  goal: string;
  evaluation?: string;
  learningPoints?: string;
  other: string;
  fromInfo: boolean;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private exerciseService: ExerciseService,
  ) {
    this.exercise = this.config.data.exercise;
    if (this.exercise.evaluation){

      this.goal = this.exercise.evaluation.goal;
      this.evaluation = this.exercise.evaluation.evaluation;
      this.learningPoints = this.exercise.evaluation.learningPoints;
      this.other = this.exercise.evaluation.other;
      this.fromInfo = this.config.data.fromInfo;

    }
  }

  ngOnInit(): void {
  }

  save() {

    let goal = "";
    let evaluation = "";
    let learningPoints = "";
    let other = "";

    if (this.goal){
      goal = this.goal;
    }
    if (this.evaluation){
      evaluation = this.evaluation;
    }
    if (this.learningPoints){
      learningPoints = this.learningPoints;
    }
    if (this.other){
      other = this.other;
    }
    this.exercise.evaluation = {
      goal: goal,
      evaluation: evaluation,
      learningPoints: learningPoints,
      other: other
    };

    this.exerciseService.updateExercise(this.config.data.exerciseKey, this.exercise);

    this.ref.close();
  }
}
