import { Component, OnInit } from '@angular/core';
import { getAuth, updateEmail } from "firebase/auth";
import {SubjectService} from '../../../services/service/subject.service';
import {take} from 'rxjs/operators';
import {PersonSubject} from '../../../model/subject';
import {AuthService} from '../../../services/service/auth.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-change-mail',
  templateUrl: './change-mail.component.html',
  styleUrls: ['./change-mail.component.scss'],
  providers: [MessageService, TranslateService]
})
export class ChangeMailComponent implements OnInit {
  eMail1: string;
  eMail2: string;

  subject: PersonSubject;
  subjectTUA: any;
  subjectUID: string;
  subjectId: string;
  constructor(
      private acService: AccessControlService,
      private authService: AuthService,
      private subjectService: SubjectService,
      private messageService: MessageService,
      private translateService: TranslateService,
      private ref: DynamicDialogRef,

  ) { }

  ngOnInit(): void {
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      this.acService.getSubjectIdFromUID(uid).pipe(take(1)).subscribe(subjectId => {
        this.subjectId = subjectId;
        this.subjectUID = uid;
        this.acService.getTenantUserAdmin(uid).pipe(take(1)).subscribe(tua => {
          this.subjectTUA = tua;
        });
        this.subjectService.getPerson(subjectId).pipe(take(1)).subscribe(subject => {
          this.subject = subject;
        });
      })
    });
  }

  updateEmail() {
    if (this.eMail1 === this.eMail2) {
      const auth = getAuth();
      updateEmail(auth.currentUser, this.eMail1).then(() => {

        this.messageService.add({severity:'success', summary: this.translateService.instant('TOAST.SUCCESS'),
          detail: this.translateService.instant('MY_PAGE.CHANGE_EMAIL_SUCCESS')});
        this.subject.email = this.eMail1;
        this.subjectTUA.email = this.eMail1;
        this.acService.updateTenantUserAdmin(this.subjectUID, this.subjectTUA);
        this.subjectService.updateSubject(this.subjectId, this.subject);
        this.ref.close();

      }).catch((error) => {
        this.messageService.add({severity:'error', summary: this.translateService.instant('TOAST.ERROR'),
          detail: this.translateService.instant('MY_PAGE.CHANGE_EMAIL_ERROR'), life: 10000});
        console.log(error);
      });
    } else {
      this.messageService.add({severity:'error', summary: this.translateService.instant('TOAST.ERROR'),
        detail: this.translateService.instant('MY_PAGE.INPUT_DONT_MATCH')});
    }
  }
}
