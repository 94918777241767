<div class="grid">
    <div class="col-6">
        <h2 style="margin-top: 10px">{{'MAIN_FRAME.DASHBOARD' | translate}}</h2>
    </div>
    <ng-container *ngIf="isAdmin$">
        <div class="col-6 flex justify-content-end">
            <button pButton type="button"
                    label="{{'DASHBOARD.SMS_OVERVIEW' | translate}}:&nbsp;&nbsp;{{availableSMS}}"
                    class="p-button-outlined p-button-raised" (click)="orderSMS()" disabled></button>
        </div>
    </ng-container>
</div>
<div class="grid">
    <div class="col-12">
        <!-- Info Messages -->
        <p-card styleClass="p-card-shadow box" class="m-2">
            <div class="grid">
                <div class="col-12 w-full banner flex justify-content-center" style="background-color: #16214D">
                    <h3 style="color: white" class="m-1">{{'COMMON.INFO_MESSAGE' | translate}}</h3>
                </div>
                <div class="col-12" *ngIf="!loading">
                    <p-carousel [value]="infoText" [numVisible]="1" [numScroll]="1">
                        <ng-template let-message pTemplate="item">
                            <div class="col-12 w-full flex justify-content-center">
                                <p>{{message.text}}</p>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </p-card>
        <!-- EXERCISE TABLE -->
        <p-card styleClass="p-card-shadow box" class="m-2">
            <app-exercises [fromDashboard]="true"></app-exercises>
        </p-card>
        <!-- TASK TABLE -->
        <p-card styleClass="p-card-shadow box" class="m-2">
            <app-active-tasks [fromDashboard]="true"></app-active-tasks>
        </p-card>
        <!-- GET ONGOING CHECKLISTS TABLE -->
        <p-card styleClass="p-card-shadow box" class="m-2">
            <app-ongoing-checklists [fromDashboard]="true"></app-ongoing-checklists>
        </p-card>
    </div>
</div>
