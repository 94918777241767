import {Component, OnDestroy, OnInit} from '@angular/core';
import {EmergencyResponse} from "../../../model/emergencyResponse";
import {combineLatest, Subscription} from 'rxjs';
import {EmergencyService} from "../../../services/service/emergency.service";
import {PersonService} from "../../../services/service/person.service";
import {ConfirmationService, FilterService, MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {EmergencyAddExposuresComponent} from '../emergency-add-exposures/emergency-add-exposures.component';
import * as moment from 'moment';
import {EmergencyAddOvertimeComponent} from '../emergency-add-overtime/emergency-add-overtime.component';
import {AddEvaluationComponent} from '../add-evaluation/add-evaluation.component';
import {AuthService} from "../../../services/service/auth.service";
import {take} from "rxjs/operators";
import {LocationData} from "../../../model/locationData";
import {Exposure} from "../../../model/exposure";
import {EmergencyEval} from "../../../model/emergencyEval";
import {EmergencyParticipant, ExposureParticipant} from 'src/app/model/participant';
import {ExposureService} from '../../../services/service/exposure.service';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {Roles, RoleType} from '../../../model/role';
import {AddRolesComponent} from '../add-roles/add-roles.component';
import {RolesService} from '../../../services/service/roles.service';
import {SubjectService} from '../../../services/service/subject.service';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';

@Component({
  selector: 'app-emergency-add',
  templateUrl: './emergency-add.component.html',
  styleUrls: ['./emergency-add.scss'],
  providers: [ConfirmationService, DialogService],
})

/**
 * Add new emergency
 */
export class EmergencyAddComponent implements OnInit, OnDestroy {

  // Menu items
  menuItems: MenuItem[];

  // Address
  address = this.translateService.instant('FDC.ADDRESS') + "*";

  // Teams
  groupedSubjectFilter = [];
  teamNameMap = new Map();
  personParentNameMap = new Map();

  // Person
  persons: [];
  personMap = new Map();
  personNameMap = new Map<string, string>();
  outputPerson = [];
  personArray = [];
  selectedPerson: '';

  addedPersons = [];

  // Loading value for Table.
  loading = true;

  // Subscription
  subscription = new Subscription();

  // Validation check
  completed = false;

  // Event type
  types: [];

  // Roles
  isRoles: boolean;
  roleTypes: RoleType[];
  roleMap: Map<string, string[]> = new Map<string, string[]>();
  selectedRolesMap: Map<string, string[]> = new Map<string, string[]>();

  // Event time space
  workTimeMap = new Map<string, {hours, minutes}>();
  timeMap = new Map<string, {start, end}>();
  timePeriodChange = new Map<string, boolean>();

  // Emergency response interface
  emergency: EmergencyResponse;

  // New emergency form
  emergencyForm: UntypedFormGroup;

  // Exposure
  exposureTypes: any[];
  exposedMap = new Map<string, Map<string, {boolValue, time: {start, end}}>>();
  exposureTimeChange = new Map<string, Map<string, boolean>>();


  // Evaluation
  evaluation = new Map<string, string>();

  constructor(
      private emergencyService: EmergencyService,
      private exposureService: ExposureService,
      private evaluationService: EvaluationService,
      private personService: PersonService,
      private filterService: FilterService,
      private confirmationService: ConfirmationService,
      public translateService: TranslateService,
      private dialogService: DialogService,
      private authService: AuthService,
      private ref: DynamicDialogRef,
      private config: DynamicDialogConfig,
      private roleService: RolesService,
      private subjectService: SubjectService,
      private gSService: GroupedSubjectsService,
  ) {
    this.exposureTypes = exposureService.getExposureTypes();

    // Get emergency types
    this.subscription.add(
        this.emergencyService.getTypes().subscribe(
            eventTypes => {
              this.types = eventTypes.map(myType => {
                return {key: myType.key, ...myType.payload.val()};
              });
            }
        )
    );

    this.subscription.add(
        combineLatest([
          this.personService.getPersons(),
          this.subjectService.getTeams(),
          this.subjectService.getRelations(),
        ]).subscribe(([persons, teams, relations]) => {

          const data = this.gSService.build(persons, teams, relations);
          this.personNameMap = data[0];
          this.teamNameMap = data[1];
          this.personParentNameMap = data[2];
          this.groupedSubjectFilter = data[3];
      })
    );

    // Get roles
    this.subscription.add(
        this.roleService.getRoleTypes().subscribe( roles => {
          this.roleTypes = roles.map(role => {
            return {key: role.key, ...role.payload.val()};
          });
        })
    );

  }

  addTeam(team: any) {
    team.items.forEach(participant => this.addPerson(participant));
  }

  buildFilter(subject) {
    return {
      label: this.personNameMap.get(subject),
      value: subject,
    };
  }

  updateMenu(){
    this.menuItems = [
      {
        label: this.translateService.instant('EVENT.EXPOSURE'),
        icon: 'far fa-exclamation-triangle',
        disabled: this.personArray.length < 1,
        command: () => {this.addExposures();}
      },
      {
        label: this.translateService.instant('ROLES.ROLES'),
        icon: 'far fa-clipboard-user',
        disabled: this.personArray.length < 1,
        command: () => {this.addRoles();}
      },
      {
        label: this.translateService.instant('COMMON.EVALUATE'),
        icon: 'far fa-clipboard-check',
        command: () => {this.addEvaluation();}
      }
    ];
  }

  /**
   * OnInit create form
   */
  ngOnInit(): void {
    this.emergencyForm = new UntypedFormGroup({
      date: new UntypedFormControl(new Date(), Validators.required),
      startTime: new UntypedFormControl('', Validators.required),
      endTime: new UntypedFormControl('', Validators.required),
      address: new UntypedFormControl('', Validators.required),
      typeKey: new UntypedFormControl(''),
      brisNumber: new UntypedFormControl(''),
      zip: new UntypedFormControl(''),
      postal: new UntypedFormControl(''),
      description: new UntypedFormControl(''),
      emergencyType: new UntypedFormControl(''),
      leaderKey: new UntypedFormControl({value: '', disabled: true}),
    });

    combineLatest(
        [this.emergencyForm.get('startTime').valueChanges, this.emergencyForm.get('endTime').valueChanges]
    ).subscribe(time => this.timePeriod(time));

    this.updateMenu();
  }


  /**
   * Add roles to persons
   */
  addRoles() {
    this.dialogService.open(AddRolesComponent, {
      header: this.translateService.instant('ROLES.ADD_ROLE'),
      styleClass: 'max-size-width-dialog',
      data: {
        personArray: this.personArray,
        personNameMap: this.personNameMap,
        selectedRolesMap: this.selectedRolesMap,
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.roleMap = data[0];
        this.selectedRolesMap = data[1];

        this.isRoles = false;
        this.selectedRolesMap.forEach(personRoles => {
          if (personRoles.length > 0){
            this.isRoles = true;
          }
        });
      }
    });
  }

  /**
   * Filter person by name
   * @param event Search event
   */
  filterPerson(event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of this.groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    this.outputPerson = filteredGroups;
  }

  /**
   * Add person to table
   * @param subject Input person
   */
  addPerson(subject) {

    if (!this.personMap.has(subject.value)) {

      // For work hours
      this.workTimeMap.set(subject.value, this.getHoursAndMin(this.emergencyForm.value.startTime, this.emergencyForm.value.endTime));
      this.timeMap.set(subject.value, {start: this.emergencyForm.value.startTime, end: this.emergencyForm.value.endTime});
      this.timePeriodChange.set(subject.value, false);

      // Exposure hours
      const eMap = new Map<string, {boolValue, time: {start, end}, label}>();
      const tMap = new Map<string, boolean>();
      this.exposureTypes.forEach(type => {
        const label = type.label;
        eMap.set(type.key, {boolValue: false, time:
              {start: this.emergencyForm.value.startTime, end: this.emergencyForm.value.endTime}, label});
        this.exposedMap.set(subject.value, eMap);

        tMap.set(type.key, false);
        this.exposureTimeChange.set(subject.value, tMap);
      });
      // Add person
      this.personMap.set(subject.value, true);
      this.personArray.push(subject.value);

      // For selecting leader
      this.addedPersons.push({name: this.personNameMap.get(subject.value), personKey: subject.value});

      this.emergencyForm.get('leaderKey').enable();

      this.personArray.sort((a, b) => {
        return this.personNameMap.get(a).toLowerCase().localeCompare(this.personNameMap.get(b).toLowerCase());
      });
      this.selectedPerson = '';
    }

    this.updateMenu();
  }

  /**
   * Remove person from table
   * @param personId person to remove
   */
  deletePerson(personId: string) {
    this.confirmationService.confirm({
      message: this.translateService.instant('WARNING.REMOVE') + ':  <br/>' + this.personNameMap.get(personId),
      accept: () => {
        this.personMap.delete(personId);
        this.exposedMap.delete(personId);
        this.workTimeMap.delete(personId);
        this.timePeriodChange.delete(personId);

        const index = this.personArray.findIndex(pId => pId === personId);
        if(index >= 0) {
          this.personArray.splice(index, 1);
        }
      }
    });
    this.updateMenu();
  }

  /**
   * Quick add exposures
   */
  addExposures() {
    this.dialogService.open(EmergencyAddExposuresComponent, {
      header: this.translateService.instant('EVENT.ADD_EXPOSURES'),
      styleClass: 'max-size-width-dialog',
      data: {
        personArray: this.personArray,
        personNameMap: this.personNameMap,
        exposedMap: this.exposedMap,
        exposureTimeChange: this.exposureTimeChange,
        date: this.emergencyForm.value.date
      }
    }).onClose.subscribe(data => {
      if (data) {
        this.exposedMap = data[0];
        this.exposureTimeChange = data[1];
      }
    });
  }

  /**
   * Change person time period.
   * @param personId unique person ID
   */
  changePersonTimePeriod(personId: string){

    let s; let e;
    if (this.timePeriodChange.get(personId)){
      s = this.timeMap.get(personId).start;
      e = this.timeMap.get(personId).end;
    } else {
      s = this.emergencyForm.value.startTime;
      e = this.emergencyForm.value.endTime;
    }

    this.dialogService.open(EmergencyAddOvertimeComponent, {
      header: this.personNameMap.get(personId) + ': ' + this.translateService.instant('EVENT.CHANGE_TIME_SPACE'),
      width: "300px",
      data: {
        start: s,
        end: e
      }
    }).onClose.subscribe(time => {
      if(time) {
        const date = this.emergencyForm.value.date;
        this.workTimeMap.set(personId, this.getHoursAndMin(moment(date).add(time.start), moment(date).add(time.end)));
        this.timeMap.set(personId, {start: time.start, end: time.end});
        this.timePeriodChange.set(personId, true);
      }
    });
  }

  /**
   * Count Exposures
   * @param personId Unique person ID
   */
  exposureCounter(personId: string){
    const personExpo = this.exposedMap.get(personId);
    let counter = 0;
    for (const [, list] of personExpo) {
      if (list.boolValue === true){
        counter++;
      }
    }
    return counter;
  }

  /**
   * Set time period
   */
  timePeriod(time: [string, string]) {
    const start = moment(this.emergencyForm.value.date).add(time[0]);
    const end = moment(this.emergencyForm.value.date).add(time[1]);

    this.emergencyForm.value.startTime = start;
    this.emergencyForm.value.endTime= end;

    if (end.diff(start) < 0) {
      end.add(1, 'days');
    }
    for (const personId in this.workTimeMap) {
      if (!this.timePeriodChange.has(personId)){
        this.workTimeMap.set(personId, this.getHoursAndMin(start, end));
        this.timeMap.set(personId, {start: start, end: end});
      }
    }
    for (const [, Map] of this.exposedMap) {
      for(const [, data] of Map){
        if (data.boolValue === false){
          data.time.start = time[0];
          data.time.end = time[1];
        }
      }
    }
  }

  /**
   * Get hours and minutes from moment
   * @param s Start moment
   * @param e End moment
   */
  getHoursAndMin(s, e){

    if (s && e) {

      const start = moment(this.emergencyForm.value.date).add(s);
      const end = moment(this.emergencyForm.value.date).add(e);

      if (end.diff(start) < 0) {
        end.add(1, 'days');
      }
      return {hours: end.diff(start, "hours"), minutes: end.diff(start, "minutes") % 60};

    } else {
      return {hours: 0, minutes: 0};
    }
  }

  /**
   * Print work hours
   * @param personId Unique person ID
   */
  printTime(personId: string) {

    const hours = this.workTimeMap.get(personId).hours;
    const minutes = this.workTimeMap.get(personId).minutes;

    if (hours !== 0){
      if (minutes !== 0){
        return hours + this.translateService.instant('EVENT.HOUR') + " " + minutes + this.translateService.instant('EVENT.MINUTES');
      } else {
        return hours + this.translateService.instant('EVENT.HOUR');
      }
    } else {
      return minutes + this.translateService.instant('EVENT.MINUTES');
    }
  }

  /**
   * Set address to "First responder"
   */
  setAddress(){
    this.emergencyForm.controls.address.setValue(this.translateService.instant('EVENT.FIRST_RESPONDER'));
  }

  /**
   * Check that time is set before allowing to select participants
   */
  timeSet() {
    return !(this.emergencyForm.value.startTime && this.emergencyForm.value.endTime);
  }

  /**
   * Add event evaluations
   */
  addEvaluation(){
    this.dialogService.open(AddEvaluationComponent, {
      header: this.translateService.instant('COMMON.EVALUATE'),
      styleClass: 'max-size-width-dialog',
      data: {
        evaluation: this.evaluation,
        evaluationCopy: this.evaluation,
        fromExpo: false,
      }
    }).onClose.subscribe(evaluation => {
      if (evaluation) {
        this.evaluation = evaluation;
      }
    });
  }

  /**
   * save and exit
   */
  onSubmit() {

    const today = moment().toISOString(true);
    const ongoing = this.emergencyForm.value;

    const locationData: LocationData = {
      address: ongoing.address,
      postal: ongoing.postal,
      zip: ongoing.zip

    };
    const emergencyParticipants: EmergencyParticipant[] = [];
    const exposureParticipants: ExposureParticipant[] = [];

    this.personArray.forEach(personKey => {

      const time = this.workTimeMap.get(personKey).hours + ":" + this.workTimeMap.get(personKey).minutes;
      const startEnd = this.timeMap.get(personKey);

      const roles = [];
      for(const [roleKey, list] of this.roleMap){
        list.forEach(pKey => {
          if (pKey === personKey){roles.push(roleKey);}
        });
      }

      emergencyParticipants.push({
        key: personKey,
        newData: true,
        time: time,
        timeEnd: startEnd.end,
        timeStart: startEnd.start,
        exposureIds: [],
        roles: roles
      });

      exposureParticipants.push({
        key: personKey,
        newData: true,
        exposureEnd: '',
        exposureStart: '',
        roles: roles
      });

    });

    const dateMoment = moment(ongoing.date).format("DD.MM.YYYY");

    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {

      const emergencyResponse: EmergencyResponse = {
        brisNumber: ongoing.brisNumber,
        createdById: uid,
        date: dateMoment,
        dateCreated: today,
        details: ongoing.description,
        leaderKey: ongoing.leaderKey,
        evaluationId: '',
        location: locationData,
        participants: emergencyParticipants,
        timeEnd: ongoing.endTime,
        timeStart: ongoing.startTime,
        typeKey: ongoing.typeKey,
      };

      let isExposures = false;
      for (const [, Map] of this.exposedMap) {
        if (isExposures){break;}
        for(const [, data] of Map){
          if (data.boolValue === true){
            isExposures = true;
            break;
          }
        }
      }

      // If there are no evaluations.
      if (this.evaluation.size === 0) {
        // If there are no exposures & no roles add emergency
        if (!isExposures && !this.isRoles) {
          this.emergencyService.addEmergency(emergencyResponse);
        }
        // Else add exposures
        else {
          let emergencyKey = "";
          this.emergencyService.addEmergencyGetKey(emergencyResponse)
              .pipe(take(1))
              .subscribe(emkey => {
                emergencyKey = emkey;
                if (this.isRoles){this.addRolesToDB(emergencyKey, dateMoment, today);}
                this.saveExposures('', uid, dateMoment, emergencyKey, emergencyParticipants, exposureParticipants,
                    ongoing, locationData, emergencyResponse, today);
              });
        }
      }
      // Else add evaluations
      else {
        let emergencyKey = "";
        this.emergencyService.addEmergencyGetKey(emergencyResponse)
            .pipe(take(1))
            .subscribe(emkey => {
              emergencyKey = emkey;
              if (this.isRoles){this.addRolesToDB(emergencyKey, dateMoment, today);}
              this.saveEvaluation(uid, dateMoment, emergencyKey, emergencyParticipants, exposureParticipants,
                  ongoing, locationData, emergencyResponse, this.evaluation, isExposures, today);
            });
      }

      this.ref.close();
    });

  }

  /**
   * Add all selected roles to DB
   * @param emergencyKey EmergencyId
   * @param date Date for event
   * @param today Date created
   */
  addRolesToDB(emergencyKey, date, today){
    for(const [key, list] of this.roleMap){
      list.forEach(person => {
        const role: Roles = {
          shiftReport: false, eventKey: emergencyKey, dateCreated: today, date: date, personKey: person, typekey: key
        };
        this.roleService.addRole(role);
      });
    }
  }

  /**
   * Create Evaluation and returns key for emergency update.
   * @param emergencyParticipants
   * @param uid Created by
   * @param dateMoment
   * @param emergencyKey Emergency key
   * @param exposureParticipants
   * @param ongoing
   * @param locationData
   * @param emergencyResponse Emergency data
   * @param evaluationData Evaluation data
   * @param isExposures Boolean = true if there are exposures
   * @param today Date created
   */
  saveEvaluation(uid, dateMoment, emergencyKey, emergencyParticipants, exposureParticipants,
                 ongoing, locationData, emergencyResponse, evaluationData, isExposures, today) {

    const emergencyEval: EmergencyEval = {
      createdById: uid,
      dateCreated: dateMoment,
      emergencyId: emergencyKey,
      description: evaluationData.get('description'),
      measuresOnSite: evaluationData.get('measuresOnSite'),
      learningPoints:	evaluationData.get('learningPoints'),
      various: evaluationData.get('various'),
    };
    let evaluationKey = '';
    this.evaluationService.addEmergencyEvaluationGetKey(emergencyEval).pipe(take(1))
        .subscribe(evalKey => {
          evaluationKey = evalKey;
          if (!isExposures) {
            this.updateEmergency(evaluationKey, emergencyKey);
          } else {
            this.saveExposures(evaluationKey, uid, dateMoment, emergencyKey, emergencyParticipants,
                exposureParticipants, ongoing, locationData, emergencyResponse, today);
          }
        });
  }

  /**
   * Updating emergency with evaluations key.
   * @param evaluationKey Unique evaluations key
   * @param emergencyKey Unique emergency key
   */
  updateEmergency(evaluationKey, emergencyKey) {
    this.emergencyService.getEmergency(emergencyKey).subscribe(emergency => {
      emergency.evaluationId = evaluationKey;
      this.emergencyService.updateEmergency(emergencyKey, emergency);
    });
  }

  /**
   * Create Exposures and returns keys for emergency update.
   * @param evaluationKey
   * @param uid Created by
   * @param dateMoment Creation date
   * @param emergencyKey Emergency db key
   * @param emergencyParticipants List of emergency participants
   * @param exposureParticipants List of exposure participants
   * @param ongoing Ongoing emergency form
   * @param locationData Location data
   * @param emergencyResponse Emergency data
   * @param today Date created
   */
  saveExposures(evaluationKey, uid, dateMoment, emergencyKey, emergencyParticipants, exposureParticipants,
                ongoing, locationData, emergencyResponse, today){
    const exposures = new Map<string, string[]>();
    for (const [key, Map] of this.exposedMap) {
      for (const [typeKey, data] of Map) {
        if (data.boolValue === true){
          if (exposures.has(typeKey)){
            exposures.get(typeKey).push(key);
          } else {
            exposures.set(typeKey, [key]);
          }
        }
      }
    }
    const exposureKeys = new Map<string, any>();
    for (const [key, values] of exposures){

      const theseParticipants = [];

      for (const participant of exposureParticipants) {
        if (values.includes(participant.key)){
          if (key === 'Sot' || key === 'Royk'){
            participant.exposureStart = this.exposedMap.get(participant.key).get(key).time.start;
            participant.exposureEnd = this.exposedMap.get(participant.key).get(key).time.end;
          }
          theseParticipants.push(participant);
        }
      }

      const exposure: Exposure = {
        brisNumber: ongoing.brisNumber,
        createdById: uid,
        date: dateMoment,
        dateCreated: today,
        details: ongoing.description,
        evaluationId: evaluationKey,
        exposureType: key,
        location: locationData,
        participants: theseParticipants,
        timeEnd: ongoing.endTime,
        timeStart: ongoing.startTime,
        emergencyId: emergencyKey
      };

      let exposureKey = '';
      this.exposureService.addExposureGetKey(exposure)
          .pipe(take(1))
          .subscribe(expoKey => {
            exposureKey = expoKey;
            for (const participant of theseParticipants) {
              if (!exposureKeys.has(participant.key)) {
                exposureKeys.set(participant.key, [exposureKey]);
              } else {
                exposureKeys.get(participant.key).push(exposureKey);
              }
            }
            this.updateEmergencyExposureIds(evaluationKey, emergencyKey, emergencyParticipants, exposureKeys);
          });
    }
  }

  /**
   * Update newly created emergency
   * @param evaluationKey
   * @param emergencyKey Emergency db key
   * @param emergencyParticipants Emergency participants
   * @param exposureKeys Map of persons with corresponding exposure db keys
   */
  updateEmergencyExposureIds(evaluationKey, emergencyKey, emergencyParticipants, exposureKeys){
    for (const participant of emergencyParticipants) {
      if (exposureKeys.has(participant.key)) {
        participant.exposureIds = exposureKeys.get(participant.key);
      }
    }

    this.emergencyService.getEmergency(emergencyKey).subscribe(emergency => {
      emergency.evaluationId = evaluationKey;
      emergency.participants = emergencyParticipants;
      this.emergencyService.updateEmergency(emergencyKey, emergency);
    });
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
