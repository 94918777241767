import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectListComponent } from './subject-list/subject-list.component';
import {Route, RouterModule} from "@angular/router";
import {TreeModule} from "primeng/tree";
import {TranslateModule} from "@ngx-translate/core";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {SplitButtonModule} from "primeng/splitbutton";
import {ToastModule} from "primeng/toast";
import { NewSubjectComponent } from './new-subject/new-subject.component';
import { PersonDetailComponent } from './person-detail/person-detail.component';
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {CalendarModule} from "primeng/calendar";
import {MomentModule} from "ngx-moment";
import {VirtualScrollerModule} from "primeng/virtualscroller";
import {ScrollTopModule} from "primeng/scrolltop";
import {ScrollPanelModule} from "primeng/scrollpanel";
import { TeamDetailComponent } from './team-detail/team-detail.component';
import { StationDetailComponent } from './station-detail/station-detail.component';
import { MoveSubjectComponent } from './move-subject/move-subject.component';
import {CanActivateService} from '../../services/can-activate.service';

export const SubjectRoutes: Route = {
  path: 'subjects', component: SubjectListComponent, canActivate: [CanActivateService],
  children: [
    {path: 'person/:key', component: PersonDetailComponent},
    {path: 'fireteam/:key', component: TeamDetailComponent},
    {path: 'firestation/:key', component: StationDetailComponent}
  ]
};


@NgModule({
    declarations: [
        SubjectListComponent,
        NewSubjectComponent,
        PersonDetailComponent,
        TeamDetailComponent,
        StationDetailComponent,
        MoveSubjectComponent
    ],
    imports: [
        CommonModule,
        TreeModule,
        TranslateModule,
        RippleModule,
        ButtonModule,
        SplitButtonModule,
        ToastModule,
        InputTextModule,
        FormsModule,
        DropdownModule,
        ConfirmDialogModule,
        CalendarModule,
        MomentModule,
        VirtualScrollerModule,
        ScrollTopModule,
        ScrollPanelModule,
        RouterModule,
    ]
})
export class SubjectModule { }
