import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentComponent } from './appointment/appointment.component';
import {Route} from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import { ViewEventComponent } from './view-event/view-event.component';
import {TaskModule} from '../task/task.module';
import {ExerciseModule} from '../exercise/exercise.module';
import {MenuModule} from 'primeng/menu';
import { AppointmentTypesComponent } from './appointment-types/appointment-types.component';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {PipesModule} from '../../pipes/pipes.module';
import {ColorPickerModule} from 'primeng/colorpicker';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {CheckboxModule} from 'primeng/checkbox';
import {InputNumberModule} from 'primeng/inputnumber';
import {CanActivateService} from '../../services/can-activate.service';


export const CalendarRoutes: Route = {
  path: 'calender', canActivate: [CanActivateService],
  children: [
    {path: 'appointment', component: AppointmentComponent},
    {path: 'calendar', component: CalendarComponent}
  ]
};

@NgModule({
  declarations: [
    AppointmentComponent,
    CalendarComponent,
    ViewEventComponent,
    AppointmentTypesComponent
  ],
    imports: [
        CommonModule,
        FullCalendarModule,
        TranslateModule,
        ButtonModule,
        TaskModule,
        ExerciseModule,
        MenuModule,
        TableModule,
        ToastModule,
        AutoCompleteModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        DropdownModule,
        CalendarModule,
        PipesModule,
        ColorPickerModule,
        InputTextareaModule,
        ToggleButtonModule,
        CheckboxModule,
        InputNumberModule,

    ]
})
export class CalenderModule { }
