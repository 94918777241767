<div class="grid col-12">
  <ng-container *ngIf="exerciseRows.length > 0">
    <p-table #dt [columns]="exerciseCols" [value]="exerciseRows"
             responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button type="button" pButton icon="pi pi-file-excel" (click)="exportExcel(exerciseRows)"
                  class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="appointmentRows.length > 0">
    <p-table #dt [columns]="appointmentCols" [value]="appointmentRows"
             responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button type="button" pButton icon="pi pi-file-excel" (click)="exportExcel(appointmentRows)"
                  class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="eventRows.length > 0">
    <p-table #dt [columns]="eventCols" [value]="eventRows"
             responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button type="button" pButton icon="pi pi-file-excel" (click)="exportExcel(eventRows)" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="eventRowsOld.length > 0">
    <p-table #dt [columns]="eventColsOld" [value]="eventRowsOld"
             responsiveLayout="scroll">
      <ng-template pTemplate="caption">
        <div class="flex">
          <button type="button" pButton icon="pi pi-file-excel" (click)="exportExcel(eventRowsOld)" class="p-button-success mr-2"  pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>
