import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboardFilter'
})
export class DashboardFilterPipe implements PipeTransform {

  transform(list, filter: 'checklist'|'task'|'exercise', id?, stations?): unknown {
    if(!id){
      return list;
    }
    if (list){
      if(filter === 'checklist'){
        return list.filter(checklist => checklist.stationId === id);
      } else if (filter === 'task'){
        if(stations.includes(id)){
          return list.filter(task => task.station === id);
        } else {
          return list.filter(task => (task.subjects) ? (Object.keys(task.subjects).includes(id)) : false);
        }
      } else if (filter === 'exercise'){
        return list.filter(exercise => (exercise.participants)? exercise.participants.some(participant => participant.key === id) : false);
      } else {
        return list;
      }
    }
  }

}
