<!-- ConfirmDialog -->
<p-confirmDialog key="eFunction" [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12 flex justify-content-end">
    <p-splitButton [model]="splitMenu" class="p-button-raised" label="{{'BUTTON.SAVE' | translate}}"
                   [disabled]="!function.name" (onClick)="save()"></p-splitButton>
  </div>
  <div class="col-12">
    <div class="field">
      <label for="name">{{'COMMON.NAME' | translate}}</label>
      <input id="name" [(ngModel)]="function.name" type="text" class="inputfield w-full" pInputText>
    </div>
  </div>

  <div class="col-12 p-0">
    <!-- TAB VIEW -->
    <p-tabView>
      <!-- PERSON TABLE -->
      <p-tabPanel header="{{'FDC.PERSONS' | translate}}">
        <p-table [loading]="loading" [value]="personArray" responsiveLayout="block"
                 breakpoint="200px">

          <!-- CAPTION -->
          <ng-template pTemplate="caption">
            <div class="flex justify-content-end">
              <p-autoComplete
                size="100"
                placeholder="{{'EVENT.ADD_PERSON' | translate}}"
                [(ngModel)]="selectedPerson"
                [suggestions]="outputPerson | removeExistingFilter: personMap"
                (completeMethod)="filterPerson($event)"
                field="name"
                type="search"
                (onSelect)="addPerson(selectedPerson)"
                appendTo="body"
                [forceSelection]="true"
                [dropdown]="true">
                <ng-template let-person pTemplate="item">
                  <div class="country-item">
                    {{person.name}}
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </ng-template>

          <!-- HEADER -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'COMMON.NAME' | translate}}</th>
              <th style="width: 50px"></th>
            </tr>
          </ng-template>

          <!-- BODY -->
          <ng-template pTemplate="body" let-person>
            <tr>
              <td>{{personNameMap.get(person.key)}}</td>
              <td (click)="deletePerson(person.key)"><i class="pi pi-trash" style="color: #D0021B"></i></td>
            </tr>
          </ng-template>

          <!-- EMPTY MESSAGE -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2">{{'REPORT.NO_SUBJECTS' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>

      <!-- Competency Type Table -->
      <p-tabPanel header="{{'COMPETENCY.TYPES' | translate}}">
        <p-table [loading]="loading" [value]="ctArray" responsiveLayout="block" breakpoint="200px">
          <!-- CAPTION -->
          <ng-template pTemplate="caption">
            <div class="flex justify-content-end">
              <p-autoComplete
                size="100"
                placeholder="{{'COMPETENCY.ADD_COMPETENCY_TYPE' | translate}}"
                [(ngModel)]="selectedCT"
                [suggestions]="outputCT | removeExistingFilter: ctMap"
                (completeMethod)="filterCT($event)"
                field="name"
                type="search"
                (onSelect)="addCT(selectedCT)"
                appendTo="body"
                [forceSelection]="true"
                [dropdown]="true">
                <ng-template let-ct pTemplate="item">
                  <div class="country-item">
                    {{ct.name}}
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </ng-template>
          <!--HEADER -->
          <ng-template pTemplate="header">
            <tr>
              <th>{{'COMMON.NAME' | translate}}</th>
              <th style="width: 50px"></th>
            </tr>
          </ng-template>
          <!-- BODY -->
          <ng-template pTemplate="body" let-ct>
            <tr>
              <td *ngIf="ctNameMap.has(ct.key)" (click)="editCT(ct.key)">{{ctNameMap.get(ct.key)}}</td>
              <td *ngIf="!ctNameMap.has(ct.key)"><b>{{'COMMON.DELETED' | translate}}</b></td>
              <td (click)="deleteCT(ct.key)"><i class="pi pi-trash" style="color: #D0021B"></i></td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
