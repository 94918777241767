<div class="grid" *ngIf="!loading">
    <div class="col-12 flex flex-wrap align-content-center">
        <h2 style="margin-top: 10px">{{'ARCHIVE.ASSET_ARCHIVE' | translate}}</h2>
    </div>

    <div class="col-12 p-0">
        <div class="col-6">
            <input (ngModelChange)="filterAssets(search)" [(ngModel)]="search" [style]="{'width' : '100%'}"
                   pInputText placeholder="{{'ASSET.SEARCH_ASSET' | translate}}" type="text"/>
        </div>
    </div>
    <ng-container *ngIf="allArchivedAssets.length > 0; else emptyBlock">
        <div *ngFor="let asset of archivedAssets" class="col-12 sm:col-6 md:col-4 lg:col-3">
            <p-card styleClass="p-card-shadow box">
                <ng-template pTemplate="header">
                    <div style="background-color: #16214D; min-height: 30px;"
                         class="flex align-content-center flex-wrap justify-content-center banner">
                        <div class="flex align-items-center text" style="color: white">
                            {{asset.name}}
                        </div>
                    </div>
                </ng-template>
                <div class="grid">
                    <div class="col-offset-1 col-3">{{'COMMON.TYPE' | translate}}:</div>
                    <div class="col-8"> {{getType(asset.type)}} </div>
                    <div class="col-offset-1 col-3">{{'COMMON.STATION' | translate}}:</div>
                    <div class="col-8"> {{getStation(asset.stationId)}} </div>
                </div>
                <div class="col-12 flex justify-content-center">
                    <button label="{{'ARCHIVE.RESTORE' | translate}}" (click)="restore(asset)"
                            class="p-button-outlined p-button-raised" pButton type="button"></button>
                </div>
            </p-card>
        </div>
    </ng-container>
    <ng-template #emptyBlock>
        <div class="col-12">
            <h4>{{'ARCHIVE.ARCHIVED_NO_ASSETS' | translate}}</h4>
        </div>
    </ng-template>
</div>
