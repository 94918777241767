<div class="grid" *ngIf="!loading">
    <ng-container *ngIf="windowWidth > 480; else smallScreenBlock">
        <div class="col-2 mt-2 flex flex-wrap align-content-center">{{'COMMON.FROM' | translate}}*</div>
        <div class="col-5 mt-2">
            <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        class="w-full inputfield"
                        [(ngModel)]="shiftReport.date"
                        [disabled]="edit"
            ></p-calendar>
        </div>
        <div class="field mt-2 col-5 mb-0">
            <input [(ngModel)]="shiftReport.timeStart"
                   [style]="{'width' : '100%', 'height' : '42px'}"
                   class="inputfield w-full"
                   pInputText
                   type="time"
            >
        </div>
        <div class="col-2 mt-2 flex flex-wrap align-content-center">{{'COMMON.TO' | translate}}*</div>
        <div class="col-5 mt-2">
            <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        class="w-full inputfield"
                        [(ngModel)]="shiftReport.dateTo"
            ></p-calendar>
        </div>
        <div class="field mt-2 col-5 mb-0">
            <input [(ngModel)]="shiftReport.timeEnd"
                   [style]="{'width' : '100%', 'height' : '42px'}"
                   class="inputfield w-full"
                   pInputText
                   type="time"
            >
        </div>
    </ng-container>
    <ng-template #smallScreenBlock>
        <div class="col-1 mt-2 flex flex-wrap align-content-center">{{'COMMON.FROM' | translate}}*</div>
        <div class="col-6 mt-2">
            <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        class="w-full inputfield"
                        [(ngModel)]="shiftReport.date"
            ></p-calendar>
        </div>
        <div class="field mt-2 col-5 mb-0">
            <input [(ngModel)]="shiftReport.timeStart"
                   [style]="{'width' : '100%', 'height' : '42px'}"
                   class="inputfield w-full"
                   pInputText
                   type="time"
            >
        </div>
        <div class="col-1 mt-2 flex flex-wrap align-content-center">{{'COMMON.TO' | translate}}*</div>
        <div class="col-6 mt-2">
            <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                        dateFormat="dd.mm.yy"
                        [style]="{'width' : '100%', 'height' : '42px'}"
                        class="w-full inputfield"
                        [(ngModel)]="shiftReport.dateTo"
            ></p-calendar>
        </div>
        <div class="field mt-2 col-5 mb-0">
            <input [(ngModel)]="shiftReport.timeEnd"
                   [style]="{'width' : '100%', 'height' : '42px'}"
                   class="inputfield w-full"
                   pInputText
                   type="time"
            >
        </div>
    </ng-template>
    <div class="field col-12 mb-0 mt-2">
        <span class="p-float-label">
            <textarea [(ngModel)]="shiftReport.description"
                      class="inputfield w-full"
                      cols="30"
                      id="textarea"
                      pInputTextarea
                      rows="4"
                      style="resize: none;"
            ></textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
        </span>
    </div>
    <!-- Person table -->
    <div class="col-12 flex">
        <p-table responsiveLayout="block"
                 breakpoint="200px"
                 [value]="selectedSubjects"
                 selectionMode="radiobutton"
        >
            <!-- Search bar -->
            <ng-template pTemplate="caption" class="pt-5">

                <input [ngModel]="getStation(shiftReport.station)" [style]="{'width':'50%'}" disabled pInputText
                       placeholder="{{'COMMON.STATION' | translate}}"
                       type="text"/>

                <input [ngModel]="(getTeam(shiftReport.team))" [style]="{'width':'50%'}" disabled pInputText
                       placeholder="{{'SUBJECTS.TYPES.FIRETEAM' |translate}}"
                       type="text"/>

                <p-autoComplete (completeMethod)="filterPerson($event)"
                                (onSelect)="addPerson(selectedPerson)"
                                [(ngModel)]="selectedPerson"
                                [dropdown]="true"
                                [group]="true"
                                [suggestions]="outputPerson | exerciseParticipantsFilter: personMap"
                                appendTo="body"
                                class="inputfield w-full"
                                field="label"
                                placeholder="{{'EVENT.ADD_PERSON_OR_TEAM' | translate}}"
                                scrollHeight="400px"
                                size="100%"
                                type="search"
                >

                    <ng-template let-team pTemplate="group">
                        <div class="flex align-items-center p-2" (click)="addTeam(team)">
                            <b>&nbsp;<i class="far fa-users"></i>&nbsp;{{team.label}}</b>
                        </div>
                    </ng-template>
                    <ng-template let-subject pTemplate="item">
                        <div class="person-item">
                            {{subject.label}}
                        </div>
                    </ng-template>
                </p-autoComplete >
            </ng-template>
            <!-- Header -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <i class="far fa-users-crown"></i>
                    </th>
                    <th>{{'COMMON.NAME' | translate}}</th>
                    <th>{{'ROLES.ROLE' | translate}}</th>
                    <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
                </tr>
            </ng-template>
            <!-- Body -->
            <ng-template pTemplate="body" let-personId>
                <tr>
                    <td>
                        <p-tableRadioButton [value]="personId" (click)="setLeader(personId)"></p-tableRadioButton>
                    </td>
                    <td>
                        {{personNameMap.get(personId)}}
                    </td>
                    <td>
                        <p-dropdown [(ngModel)]="selectedSubjectsMap.get(personId).role"
                                    [options]="roleTypes" [style]="{'width':'100%'}" optionLabel="name" optionValue="key"
                                    placeholder="{{'SHIFT_REPORT.CHOOSE_ROLE' |
                                    translate}}"></p-dropdown>
                    </td>
                    <td (click)="removePerson(personId)">
                                <span class="flex justify-content-center">
                                    <i class="pi pi-trash" style="color: #D0021B"></i>
                                </span>
                    </td>
                </tr>
            </ng-template>
            <!-- Shows message if table is empty -->
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">
                        {{'WARNING.NO_DATA' | translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!-- Save button -->
    <div class="col-12 flex justify-content-end">
        <button pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
                (click)="close()" class="p-button-outlined float-right p-button-raised"></button>
        <button class="float-right p-button-raised" pButton icon="pi pi-bookmark"
                type="button" label="{{'BUTTON.SAVE' | translate}}" [disabled]="validate(shiftReport)"
                (click)="save()"></button>
    </div>
</div>
