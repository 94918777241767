import { Pipe, PipeTransform } from '@angular/core';
import {Roles} from '../model/role';

@Pipe({
  name: 'rolesStationTeamFilter'
})
export class RolesStationTeamFilterPipe implements PipeTransform {

  transform(roles: Roles[], selectedTeam: [{label: string, value: string}]): any {

    if (!roles || !selectedTeam){
      return [];
    } else {
      const filtered = [];
      roles.forEach(role => {
        selectedTeam.forEach(subject => {
          if (role.personKey === subject.value){
            filtered.push(role);
          }
        });
      });
      return filtered;
    }
  }

}
