import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {DialogService} from "primeng/dynamicdialog";
import {TranslateService} from "@ngx-translate/core";
import {AccessControlService} from "../../../services/service/access-control.service";
import {ConfirmationService, MessageService} from "primeng/api";

@Component({
  selector: 'app-temp-login',
  templateUrl: './temp-login.component.html',
  styleUrls: ['./temp-login.component.scss'],
  providers: [DialogService, ConfirmationService]
})
export class TempLoginComponent implements OnInit {
  name = "";
  email = "";

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
  }

  add() {
    const control = new UntypedFormControl(this.email, Validators.email);
    if (!control.errors?.email) {
      this.confirmationService.confirm({
        message: this.translateService.instant('WARNING.TEMP_WARNING'),
        accept: () => {
          const role = {observer: true};
          this.acService.createTempUser(this.name, this.email, role);
          this.name = '';
          this.email = '';
        }
      });
    } else {
      this.messageService.add(
        {key: 'temp-login-error', severity: 'error', detail: this.translateService.instant('PERSONAL.EMAIL_ERROR'), life: 4000}
      );
    }

  }
}

