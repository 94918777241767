export interface BaseAttestation {
  type: string;
  $key?: string;
  time: string;
  comment?: string;
  personId: string;
  competencyTypeId?: string;
  attachments?: any[];
  createdById?:string;
}

export interface AddAttestation extends BaseAttestation {
  validFrom?: string;
  validTo?: string;
  type: string;
}

export interface ValidityAttestation extends BaseAttestation {
  date: string;
  valid: boolean;
  type: 'VALIDITY';
}

export interface ExerciseAttestation extends BaseAttestation {
  exerciseId: string;
  validFrom?: string;
  validTo?: string;
  granted: boolean;
  type: 'EXERCISE';
}

export interface EducationAttestation extends BaseAttestation {
  exerciseId?: string;
  validFrom?: string;
  validTo?: string;
  type: 'EDUCATION';
}

export interface LicenceAttestation extends BaseAttestation {
  exerciseId?: string;
  validFrom?: string;
  validTo?: string;
  type: 'LICENCE';
}

export interface EventAttestation extends BaseAttestation {
  exerciseId?: string;
  validFrom?: string;
  validTo?: string;
  type: 'EVENT';
}

export interface CertificateAttestation extends BaseAttestation {
  exerciseId?: string;
  validFrom?: string;
  validTo?: string;
  type: 'CERTIFICATE';
}

export interface CourseAttestation extends BaseAttestation {
  validFrom?: string;
  validTo?: string;
  type: 'COURSE';
}

export interface RegistrationAttestation extends BaseAttestation {
  validFrom?: string;
  validTo?: string;
  type: 'REGISTRATION';
}

export type Attestation = ExerciseAttestation
  | ValidityAttestation
  | EducationAttestation
  | LicenceAttestation
  | EventAttestation
  | CertificateAttestation
  | CourseAttestation
  | RegistrationAttestation
  | AddAttestation;


export function isExerciseAttestation(x: Attestation): x is ExerciseAttestation {
  return isBaseAttestation(x) && x.type === 'EXERCISE';
}

export function isValidityAttestation(x: Attestation): x is ValidityAttestation {
  return isBaseAttestation(x) && x.type === 'VALIDITY';
}

export function isEducationAttestation(x: Attestation): x is EducationAttestation {
  return isBaseAttestation(x) && x.type === 'EDUCATION';
}

export function isLicenceAttestation(x: Attestation): x is LicenceAttestation {
  return isBaseAttestation(x) && x.type === 'LICENCE';
}

export function isEventAttestation(x: Attestation): x is EventAttestation {
  return isBaseAttestation(x) && x.type === 'EVENT';
}

export function isCertificateAttestation(x: Attestation): x is CertificateAttestation {
  return isBaseAttestation(x) && x.type === 'CERTIFICATE';
}

export function isCourseAttestation(x: Attestation): x is CourseAttestation {
  return isBaseAttestation(x) && x.type === 'COURSE';
}

export function isRegistrationAttestation(x: Attestation): x is RegistrationAttestation {
  return isBaseAttestation(x) && x.type === 'REGISTRATION';
}

export function isAttestation(x: any): x is Attestation {
  return isExerciseAttestation(x)
    || isValidityAttestation(x)
    || isEducationAttestation(x)
    || isLicenceAttestation(x)
    || isEventAttestation(x)
    || isCertificateAttestation(x)
    || isCourseAttestation(x)
    || isRegistrationAttestation(x);
}

function isBaseAttestation(x: any): x is BaseAttestation {
  const typeOk = x['type'] && typeof x['type'] === 'string';
  const $keyOk = x['$key'] ? typeof x['$key'] === 'string' : true;
  const commentOk = x['comment'] ? typeof x['comment'] === 'string' : true;
  const personIdOk = x['personId'] && typeof x['personId'] === 'string';
  const competencyTypeIdOk = x['competencyTypeId'] && typeof x['competencyTypeId'] === 'string';

  return typeOk && $keyOk && commentOk && personIdOk && competencyTypeIdOk;
}
