<div class="grid">
  <div class="col-12 flex justify-content-end">
    <button class="p-button-raised" pButton label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark"
            (click)="save()"></button>
  </div>
  <div class="col-12 p-0">
    <p-table [loading]="!ctNameMap" [value]="exerciseCT" breakpoint="200px">
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-12">
            <p-autoComplete
              [suggestions]="output | removeExistingFilter: eCompetencyTypeMap"
              (completeMethod)="search($event)"
              (onSelect)="addCompetencyType($event)"
              [dropdown]="true"
              placeholder="{{'COMPETENCY.ADD_COMPETENCY_TYPE' | translate}}"
              field="name"
              scrollHeight="300px"
              [style]="{'width':'100%'}"
              type="search"
              class="inputfield w-full"
              appendTo="body"
              emptyMessage="{{'EVENT.NO_RESULT' | translate}}"
              [forceSelection]="true"
            ></p-autoComplete>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 85%">{{'COMMON.NAME' | translate}}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-competencyType>
        <tr>
          <td>{{competencyType.name}}</td>
          <td (click)="remove(competencyType)"><i style="color: #D0021B" class="pi pi-trash"></i></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'COMMON.NO_COMPETENCY' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

