import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {SendSMS} from '../../../model/sendSMS';
import {Subscription} from 'rxjs';
import {PersonService} from '../../../services/service/person.service';
import * as moment from 'moment';

@Component({
  selector: 'app-view-sms',
  templateUrl: './view-sms.component.html',
  styleUrls: ['./view-sms.component.scss']
})
export class ViewSmsComponent implements OnInit, OnDestroy {

  subscription = new Subscription();

  sms: SendSMS;

  cellNameMap = new Map<string, string>();
  personsUIDNameMap = new Map<string, string>();

  loading = true;

  constructor(
      private config: DynamicDialogConfig,
      private personService: PersonService,
  ) { }

  ngOnInit(): void {
    this.sms = this.config.data.sms;
    this.subscription.add(this.personService.getPersons().subscribe(
        persons => {
          persons.map(person => {
            if (person.payload.val().privateCell){
              this.cellNameMap.set(person.payload.val().privateCell, person.payload.val().name);
            }
          });
          this.loading = false;
        })
    );
    this.subscription.add(this.personService.getUIDPersonNameMap().subscribe(
        personsUIDNameMap => {
         this.personsUIDNameMap = personsUIDNameMap;
        })
    );
  }

  ngOnDestroy() {
  }

  getDate() {
    return moment(this.sms.date).format("DD.MM.YYYY HH:MM");
  }

  getRecipients() {
    let recipientString = "";
    this.sms.address.forEach(number => {
      console.log(this.cellNameMap.get(number));
      recipientString = recipientString + this.cellNameMap.get(number) + ", ";
    });

    return recipientString.slice(0, recipientString.length - 2);
  }
}
