import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {CompetencyTypeService} from "../../../services/service/competency-type.service";
import {Subscription} from "rxjs";
import {ExerciseService} from "../../../services/service/exercise.service";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-exercise-add-competencytypes-from-theme',
  templateUrl: './exercise-add-competency-types-from-theme.component.html',
  styleUrls: ['./exercise-add-competency-types-from-theme.component.scss']
})
export class ExerciseAddCompetencyTypesFromThemeComponent implements OnInit, OnDestroy {
  eKey;
  tKey;
  ctKeys;
  existingCTMap = new Map();
  ctNameMap;
  removedCTMap = new Map();

  loading = true;
  subscription = new Subscription();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private ctService: CompetencyTypeService,
    private exerciseService: ExerciseService,
  ) {
    // Get Exercise and Theme ID
    this.eKey = this.config.data.eKey;
    this.tKey = this.config.data.tKey;

    // Get the Competency Types that are connected to the theme.
    this.subscription.add(
      this.exerciseService.getTheme(this.tKey)
        .subscribe(theme => {
          this.ctKeys = theme.competencyTypes;
          this.loading = false;
        })
    );
  }

  ngOnInit(): void {
    // Get all Competency Types for a [key, name] Map
    this.subscription.add(
      this.ctService.getCompetencyTypes().subscribe(cts => {
        this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        this.ctKeys.sort((a, b) => {
          return this.ctNameMap.get(a).toLowerCase().localeCompare(this.ctNameMap.get(b).toLowerCase());
        });
      })
    );

    // Get all Competency Types that is already added to the exercise
    this.subscription.add(
      this.exerciseService.getExerciseCompetencyTypes(this.eKey).subscribe(eCT => {
        if (eCT) {
          this.existingCTMap = new Map(Object.entries(eCT));
        }
      })
    );
  }

  /**
   * Unsubscribe on Destroy
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Update competency list
   * @param ctKey Competency type key
   */
  updateCTList(ctKey) {
    if (this.existingCTMap.has(ctKey)) {
      this.existingCTMap.delete(ctKey);
      this.removedCTMap.set(ctKey, true);
    } else {
      this.existingCTMap.set(ctKey, true);
      if (this.removedCTMap.has(ctKey)) {
        this.removedCTMap.delete(ctKey);
      }
    }
  }

  /**
   * Get competency status
   * @param ctKey Competency type key
   */
  getStatus(ctKey: any) {
    if (this.existingCTMap.has(ctKey)) {
      return 'pi pi-check-circle';
    } else {
      return '';
    }
  }

  /**
   * Save competency types to exercise.
   */
  save() {
    const competencyTypes = Object.fromEntries(this.existingCTMap);

    this.exerciseService.getExercise(this.eKey)
      .pipe(take(1))
      .subscribe(exercise => {
        exercise.competencyTypes = competencyTypes;

        if (exercise.participants) {
          exercise.participants.forEach(participant => {
            participant.competencyTypes = {...participant.competencyTypes, ...competencyTypes};
            if (this.removedCTMap.size > 0) {
              Object.keys(participant.competencyTypes).forEach(ctKey => {
                if (this.removedCTMap.has(ctKey)) {
                  delete participant.competencyTypes[ctKey];
                }
              });
            }
          });
        }
        this.exerciseService.updateExercise(this.eKey, exercise);
        this.ref.close();
      });
  }

  /**
   * Add all competency types to competency list
   */
  addAll() {
    this.ctKeys.forEach(ct => {
      if(this.ctNameMap.has(ct)){
        this.existingCTMap.set(ct, true);
      }
    });
  }
}
