import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubjectService} from '../../../services/service/subject.service';
import {PersonService} from '../../../services/service/person.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/service/auth.service';
import {combineLatest, Subscription} from 'rxjs';
import {PersonSubject} from '../../../model/subject';
import {TreeNode} from 'primeng/api';
import {GroupedSubjectsService} from '../../../services/sharedService/grouped-subjects.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AddAttestation} from '../../../model/attestation';
import * as moment from 'moment';
import {map, take} from 'rxjs/operators';
import {AttestationService} from '../../../services/service/attestation.service';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-set-competencies',
  templateUrl: './set-competencies.component.html',
  styleUrls: ['./set-competencies.component.scss']
})
export class SetCompetenciesComponent implements OnInit, OnDestroy {

  crewTree: TreeNode[];
  subscription = new Subscription();
  personMap;

  cTypeMap = new Map<string, string>();
  cValidityMap = new Map<string, string>();

  groupedSubjects: any[];
  selectedSubject: PersonSubject;
  competencyForm: UntypedFormGroup;
  ctList: any[];

  constructor(
      private subjectService: SubjectService,
      private personService: PersonService,
      private translateService: TranslateService,
      private authService: AuthService,
      private gsService: GroupedSubjectsService,
      private attestationService: AttestationService,
      private ctService: CompetencyTypeService,
      private ref: DynamicDialogRef,
  ) {

    this.subscription.add(
        combineLatest([
          this.personService.getPersons(), this.subjectService.getTeams(),
          this.subjectService.getRelations()
        ]).subscribe(([persons, teams, relations]) => {
          this.crewTree = [];
          this.personMap = new Map<string, PersonSubject>(persons.map(person => [person.key, person.payload.val()]));
          const data = this.gsService.build(persons, teams, relations);
          this.groupedSubjects = data[3];

        })
    );
    this.subscription.add(
        this.ctService.getCompetencyTypes().pipe(
            map(competencyTypes => {
              return competencyTypes.map(ct => {
                return {key: ct.key, ...ct.payload.val()};
              }).sort((a,b) => a.name > b.name ? 1 : -1);
            })
        ).subscribe(ctList => {
          this.ctList = ctList;
          ctList.forEach(ct => {
            if (ct.type) {
              this.cTypeMap.set(ct.key, ct.type);
            } else {
              this.cTypeMap.set(ct.key, "");
            }
            const validity = (ct.defaultValidity) ? ct.defaultValidity : 1200;
            this.cValidityMap.set(ct.key, validity);

          });
        })
    );
  }

  ngOnInit(): void {

    this.competencyForm = new UntypedFormGroup({
      personId: new UntypedFormControl(null, Validators.required),
      competencyTypeId: new UntypedFormControl(null, Validators.required),
      validFrom: new UntypedFormControl(''),
      validTo: new UntypedFormControl(''),
      comment: new UntypedFormControl(''),
    });
  }

  onSubmit() {
    const ongoingForm = this.competencyForm.value;
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      let t;
      if (this.cTypeMap.get(ongoingForm.competencyTypeId) === "EFFORT-EXERCISE"
          || this.cTypeMap.get(ongoingForm.competencyTypeId) === "SKILL-EXERCISE" ){
        t = 'EXERCISE';
      } else {
        t = this.cTypeMap.get(ongoingForm.competencyTypeId);
      }
      const attestation: AddAttestation = {
        comment: ongoingForm.comment,
        competencyTypeId: ongoingForm.competencyTypeId,
        createdById: uid,
        personId: ongoingForm.personId,
        time: moment().toISOString(),
        type: t,
        validFrom: '',
        validTo: '',
      };

      if (ongoingForm.validFrom !== ''){
        attestation.validFrom = ongoingForm.validFrom.toISOString();
      }
      attestation.validTo =
          moment(ongoingForm.validFrom).add(this.cValidityMap.get(ongoingForm.competencyTypeId), 'months').toISOString();

      this.attestationService.addAttestation(attestation);
      this.ref.close();
    });

  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.ref.close();
  }
}
