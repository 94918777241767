import {Component, OnDestroy, OnInit} from '@angular/core';
import {Competency} from '../../../model/competency';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {CompetencyService} from '../../../services/service/competency.service';
import {SdaService} from '../../../services/service/sda.service';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';
import {PersonService} from '../../../services/service/person.service';
import {SubjectService} from '../../../services/service/subject.service';
import {AccessControlService} from '../../../services/service/access-control.service';
import * as moment from 'moment';

@Component({
    selector: 'app-my-competencies',
    templateUrl: './my-competencies.component.html',
    styleUrls: ['./my-competencies.component.scss']
})
export class MyCompetenciesComponent implements OnInit, OnDestroy {
    competencies: Competency[] = [];
    subscription = new Subscription();
    groupedFilter = [];
    parentChildMap;

    isAdmin$: Observable<boolean>;
    isSuper$: Observable<boolean>;

    myId: string;
    loading = true;

    constructor
    (
        private competencyService: CompetencyService,
        private sdaService: SdaService,
        private competencyTypeService: CompetencyTypeService,
        private personService: PersonService,
        private subjectService: SubjectService,
        private acService: AccessControlService
    ) {

        this.subscription.add(
            combineLatest([
                this.competencyTypeService.getCompetencyTypes(), this.personService.getPersons(),
                this.competencyService.getCompetencies(), this.sdaService.getActiveSDA(), this.acService.getSubjectId()])
                .pipe(
                    map(([cts, persons, competencies, activeSDAs, myId]) => {
                        const competencyTypeMap = new Map();
                        const personIdMap = new Map();
                        const sdaMap = new Map();
                        this.myId = myId;

                        cts.forEach(ct => competencyTypeMap.set(ct.key, ct.payload.val().name));
                        persons.forEach(person => personIdMap.set(person.key, person.payload.val().name));
                        activeSDAs.forEach(sda => sdaMap.set(sda.subjectId + sda.competencyTypeId, true));

                        const activeCompetencies = competencies.filter(competency =>
                            !sdaMap.has(competency.personId + competency.competencyTypeId));

                        const result = [];
                        let status;

                        activeCompetencies.forEach(competency => {
                            let validDateIso;
                            if (!competency.valid && !competency.expiring) {
                                validDateIso = competency.expiredSince;
                                status = 1; // Expired
                            } else {
                                if (competency.expiring) {
                                    status = 2; // Expiring
                                } else {
                                    status = 3; // Valid
                                }
                                validDateIso = competency.validTo;
                            }
                            result.push({
                                ...competency,
                                competencyType: competencyTypeMap.get(competency.competencyTypeId),
                                person: personIdMap.get(competency.personId),
                                validDate: validDateIso,
                                status: status
                            });
                        });
                        return result;
                    }),
                ).subscribe(competencies => {
                const tempMap = new Map();
                tempMap.set(1, []); // Expired
                tempMap.set(2, []); // Expiring
                tempMap.set(3, []); // Valid
                competencies.forEach(competency => {
                    if (competency.personId === this.myId){
                        if (!competency.valid){
                            tempMap.get(1).push(competency);
                        } else if(competency.expiring){
                            tempMap.get(2).push(competency);
                        } else {
                            tempMap.get(3).push(competency);
                        }
                    }
                });
                tempMap.get(1).forEach(element => this.competencies.push(element));
                tempMap.get(2).forEach(element => this.competencies.push(element));
                tempMap.get(3).forEach(element => this.competencies.push(element));

                this.loading = false;
            })
        );

    }

    ngOnInit(): void {
    }

    setStatus(status: number) {
        const success = '#73BF21';
        const warning = '#F5A623';
        const danger = '#D0021B';
        const white = '#fff';
        if (status === 1) {
            return {'background': danger, 'color': white};
        } else if (status === 2) {
            return {'background': warning, 'color': white};
        } else {
            return {'background': success, 'color': white};
        }
    }

    newDate(ISO_string) {
        return moment(ISO_string).format('D MMM   y');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
