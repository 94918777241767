<div class="formgrid grid">

    <!-- Time From -->
    <div class="field col-6">
      <label for="startTime">{{'COMMON.FROM' | translate}}*</label>
      <input type="time"
             id="startTime"
             pInputText
             class="inputfield w-full"
             [(ngModel)]="start"
      >
    </div>

    <!-- To Time -->
    <div class="field col-6">
      <label for="endTime">{{'COMMON.TO' | translate}}*</label>
      <input type="time"
             id="endTime"
             pInputText
             class="inputfield w-full"
             [(ngModel)]="end"
      >
    </div>

  <div class="col-12 mt-4 flex justify-content-end">
    <p-button label="{{'BUTTON.SAVE' | translate}}"
              icon="pi pi-check"
              iconPos="right"
              (onClick)="save()"
    ></p-button>
  </div>

</div>
