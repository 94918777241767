import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {mergeMap, publish, take} from "rxjs/operators";
import {
  Attestation,
  EducationAttestation,
  EventAttestation,
  ExerciseAttestation,
  isValidityAttestation,
  LicenceAttestation,
  ValidityAttestation
} from "../../model/attestation";
import {TimeRange} from "../../model/timeRange";
import {CompetencyTypeService} from "./competency-type.service";
import {CompetencyType} from "../../model/competencyType";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AttestationService {
  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
    private ctService: CompetencyTypeService
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  getPersonAttestations(subjectId): Observable<Attestation[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/attestations`, ref => {
          return ref.orderByChild('personId').equalTo(subjectId);
        }).valueChanges()
      )
    ) as Observable<Attestation[]>;
  }
  getPersonAttestationsSnapshot(subjectId): Observable<any[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/attestations`, ref => {
          return ref.orderByChild('personId').equalTo(subjectId);
        }).snapshotChanges()
      )
    );
  }
  getAttestations(): Observable<Attestation[]> {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/attestations`).valueChanges()
      )
    ) as Observable<Attestation[]>;
  }

  findValidDateRanges(atts: any): TimeRange[] {

    const boundarypts = [];

    atts
      .filter(att => att.type !== 'VALIDITY')
      .filter(att => {
        att = <(ExerciseAttestation | EducationAttestation | LicenceAttestation | EventAttestation)>att;
        return att.validFrom && att.validTo ? att.validFrom !== att.validTo : true;
      })
      .forEach(att => {
        att = <(ExerciseAttestation | EducationAttestation | LicenceAttestation | EventAttestation)>att;
        boundarypts.push({
          attestation: att,
          type: 'start',
          date: att.validFrom ? Date.parse(att.validFrom) : -Infinity
        });
        boundarypts.push({
          attestation: att,
          type: 'end',
          date: att.validTo ? Date.parse(att.validTo) : Infinity
        });
      });

    boundarypts.sort((a, b) => {
      if (a.date !== b.date) {
        return a.date - b.date;
      }
      if (b.type === a.type) {
        return 0;
      }
      if (b.type === 'start') {
        return -1;
      }
      return 1;
    });
    // Final ranges
    const ranges = new Array<TimeRange>();

    // Algorithm is based on the following insight: Sort the boundary points by date,
    // and let { b1, b2jeg er , ... } be the resulting sorted set, so that b_n comes before or coincides in time with b_{n+1}.
    // * If b_k ... b_{k+m} constitute a range, then b_{k+m+1} is the start of another range
    // * If we assign a valence v(b_n) = +1 if b is the start of an interval and v(b_n) = -1 is the end of an interval,
    // then a range has the property that  v(b_1) + ... + v(b_{k-1}) = 0,

    for (let i = 0; i < boundarypts.length;) {
      let boundarycnt = 0;
      const range: TimeRange = {};

      if (boundarypts[i].date !== -Infinity) {
        range.validFrom = new Date(boundarypts[i].date).toISOString();
      }

      do {
        boundarycnt += boundarypts[i].type === 'start' ? 1 : -1;
        ++i;
      } while (boundarycnt > 0);

      if (boundarypts[i - 1].date !== Infinity) {
        range.validTo = new Date(boundarypts[i - 1].date).toISOString();
      }

      ranges.push(range);
    }

    return ranges;
  }

  currentRange(validRanges: TimeRange[]): { currentlyInRange: boolean, validFrom?: string, validTo?: string } {
    const currentRange: any = {currentlyInRange: false};
    const currentTime = new Date().toISOString();

    validRanges.forEach(range => {
      const pastValidFrom = range.validFrom ? range.validFrom <= currentTime : true;
      const beforeValidTo = range.validTo ? currentTime <= range.validTo : true;
      if (pastValidFrom && beforeValidTo) {
        if (range.validFrom) {
          currentRange.validFrom = range.validFrom;
        }
        if (range.validTo) {
          currentRange.validTo = range.validTo;
        }

        currentRange.currentlyInRange = true;
      }
    });

    return currentRange;
  }

  competencyInvalidated(attestations: Attestation[]): boolean {
    const validations = attestations
      .filter(attestation => isValidityAttestation(attestation))
      .sort((a, b) => (<ValidityAttestation>b).date.localeCompare((<ValidityAttestation>a).date));

    return validations.length > 0 ? !(<ValidityAttestation>validations[0]).valid : false;
  }

  lastExpiryDate(validRanges: TimeRange[]): string | null {
    const now = new Date().toISOString();

    validRanges.sort((a, b) => {
      if (!a.validTo && !b.validTo) {
        return 0;
      }
      if (!a.validTo) {
        return -1;
      }
      if (!b.validTo) {
        return 1;
      }
      if (a.validTo === b.validTo) {
        return 0;
      }
      return a.validTo > b.validTo ? -1 : 1;
    });

    validRanges = validRanges.filter(range => range.validTo && range.validTo < now);
    return validRanges[0] ? validRanges[0].validTo : null;
  }

  getAttestationsByCompetencyTypeId(ctId) {
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/attestations`, ref => {
          return ref.orderByChild('competencyTypeId').equalTo(ctId);
        }).snapshotChanges()
      )
    );
  }

  addFromExercise(eKey, exercise: any) {
    this.ctService.getCompetencyTypes().pipe(
      take(1),
    ).subscribe(cts => {
      const ctMap: Map<string, CompetencyType> = new Map(cts.map(ct => [ct.key, ct.payload.val()]));

      exercise.participants
        .filter(p => p.competencyTypes)
        .forEach(participant => {
          Object.keys(participant.competencyTypes)
            .forEach(ctKey => {
              if (participant.competencyTypes[ctKey] === true && ctMap.has(ctKey)) {
                const validity = (ctMap.get(ctKey).defaultValidity) ? ctMap.get(ctKey).defaultValidity : 1200;

                const exerciseAttestation: ExerciseAttestation = {
                  competencyTypeId: ctKey,
                  exerciseId: eKey,
                  granted: true,
                  personId: participant.key,
                  time: exercise.dateEnd,
                  type: "EXERCISE",
                  validFrom: exercise.dateEnd,
                  validTo: moment(exercise.dateEnd).add(validity, 'months').toISOString()
                };
                this.addAttestation(exerciseAttestation);
              }
            });
        });
    });
  }

  addAttestation(attestation: Attestation) {
    return publish()(this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/attestations`).push(attestation)
      )
    )).connect();
  }
}
