import {Component, OnDestroy, OnInit} from '@angular/core';
import {PersonService} from "../../../services/service/person.service";
import {AccessControlService} from "../../../services/service/access-control.service";
import {DialogService} from "primeng/dynamicdialog";
import {AddRoleDialogComponent} from "../add-role-dialog/add-role-dialog.component";
import {TranslateService} from "@ngx-translate/core";
import {take} from "rxjs/operators";
import {ConfirmationService} from "primeng/api";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-login-list',
  templateUrl: './login-list.component.html',
  styleUrls: ['./login-list.component.scss'],
  providers: [DialogService, ConfirmationService]

})
export class LoginListComponent implements OnInit, OnDestroy {
  adminFilter;
  selectedFilter = {name: 'ADMIN.USER_OVERVIEW_COMPONENT.ACTIVE_USERS', value: 0};
  persons;
  personsWithAccess;
  disabledPersons;
  tempPersons;
  disabledTempPersons;
  loading = true;
  roles;
  bigView;


  subscriptions = new Subscription;


  constructor(
    private personService: PersonService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private acService: AccessControlService,
    private confirmationService: ConfirmationService
  ) {

    this.bigView = window.matchMedia('(min-width: 1920px)').matches;

    this.adminFilter = [
      {name: 'ADMIN.USER_OVERVIEW_COMPONENT.ACTIVE_USERS', value: 0},
      {name: 'ADMIN.USER_OVERVIEW_COMPONENT.DEACTIVATED_USERS', value: 1},
      {name: 'ADMIN.USER_OVERVIEW_COMPONENT.TEMP_USERS', value: 2},
      {name: 'ADMIN.USER_OVERVIEW_COMPONENT.DEACTIVATE_TEMP_USERS', value: 3},
    ]

    this.subscriptions.add(
      this.personService.getPersonsWithAccess().subscribe(persons => {
        this.personsWithAccess = persons;

        if (!this.persons || this.selectedFilter.value === 0) {
          this.persons = JSON.parse(JSON.stringify(this.personsWithAccess))
        }

        this.loading = false;
      })
    );

    this.subscriptions.add(
      this.personService.getDisabledPersons().subscribe(persons => {
        this.disabledPersons = persons;

        if (this.selectedFilter.value === 1) {
          this.persons = JSON.parse(JSON.stringify(this.disabledPersons))
        }

      }));

    this.subscriptions.add(
      this.personService.getTemp().subscribe(persons => {
        this.tempPersons = persons;

        if (this.selectedFilter.value === 2) {
          this.persons = JSON.parse(JSON.stringify(this.tempPersons))
        }
      }));

    this.subscriptions.add(
      this.personService.getDisabledTemp().subscribe(persons => {
        this.disabledTempPersons = persons;

        if (this.selectedFilter.value === 3) {
          this.persons = JSON.parse(JSON.stringify(this.disabledTempPersons))
        }
      }));
  }

  ngOnInit(): void {
  }

  getRole(roles: any) {
    if (roles.admin) {
      return 'USER_ROLES.ADMIN'
    } else if (roles.superuser) {
      return 'USER_ROLES.SUPERUSER'
    } else if (roles.user) {
      return 'USER_ROLES.USER'
    } else if (roles.observer){
      return 'USER_ROLES.OBSERVER'
    } else {
      return 'NOT CHOSEN'
    }

  }

  changeRole(person) {
    if (this.selectedFilter.value === 0) {
      const role = this.dialogService.open(AddRoleDialogComponent, {
        header: this.translateService.instant('ADMIN.NEW_EXISTING_USER_COMPONENT.USER_ACCESS') + ': ' + person.name,
        data: {
          role: person.roles
        },
      })

      role.onClose.pipe(
        take(1),
      ).subscribe(roleObject => {
        if (roleObject) {
          this.acService.updateRole(person.acKey, roleObject)
        }
      })
    }
  }

  /**
   * Disable Person
   * @param person
   */
  disable(person: any) {
    this.confirmationService.confirm({
      message: this.translateService.instant('ADMIN.USER_OVERVIEW_COMPONENT.ALERTS.DEACTIVATE') + ': ' + person.name,
      accept: () => {
        this.acService.getTenantUserAdmin(person.acKey).pipe(
          take(1),
        ).subscribe((tua: any) => {
          tua.operation = 'disabled';
          this.acService.updateTenantUserAdmin(person.acKey, tua)
        })
      }
    })
  }

  changeFilter() {
    this.loading = true;

    // Persons With Access
    if (this.selectedFilter.value === 0) {
      this.persons = JSON.parse(JSON.stringify(this.personsWithAccess))
      this.loading = false;

      // Disabled Persons
    } else if (this.selectedFilter.value === 1) {
      this.persons = JSON.parse(JSON.stringify(this.disabledPersons))
      this.loading = false;

      // Temp Persons
    } else if (this.selectedFilter.value === 2) {
      this.persons = JSON.parse(JSON.stringify(this.tempPersons))
      this.loading = false;

      // Disabled Temp Persons
    } else if (this.selectedFilter.value === 3) {
      this.persons = JSON.parse(JSON.stringify(this.disabledTempPersons))
      this.loading = false;
    }
  }

  activate(person: any) {
    this.confirmationService.confirm({
      message: this.translateService.instant('ADMIN.USER_OVERVIEW_COMPONENT.ALERTS.REACTIVATE') + ': ' + person.name,
      accept: () => {
        this.acService.getTenantUserAdmin(person.acKey).pipe(
          take(1),
        ).subscribe((tua: any) => {
          tua.operation = 'active';
          this.acService.updateTenantUserAdmin(person.acKey, tua)
        })
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
