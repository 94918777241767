import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExercisesComponent } from './exercises/exercises.component';
import { TableModule } from "primeng/table";
import { PipesModule } from "../../pipes/pipes.module";
import { DropdownModule } from "primeng/dropdown";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule } from "@angular/forms";
import { MomentModule } from "ngx-moment";
import { InputTextModule } from "primeng/inputtext";
import { Route, RouterModule } from '@angular/router';
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { EditExerciseComponent } from './edit-exercise/edit-exercise.component';
import { CalendarModule } from "primeng/calendar";
import { InputMaskModule } from "primeng/inputmask";
import { InputTextareaModule } from "primeng/inputtextarea";
import { CheckboxModule } from "primeng/checkbox";
import { InputNumberModule } from "primeng/inputnumber";
import { ToastModule } from "primeng/toast";
import { ExerciseDetailComponent } from './exercise-detail/exercise-detail.component';
import { SplitButtonModule } from "primeng/splitbutton";
import { AutoCompleteModule } from "primeng/autocomplete";
import { EditParticipantComponent } from './edit-participant/edit-participant.component';
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ExerciseCompetencyTypeDetailComponent } from './exercise-competency-type-detail/exercise-competency-type-detail.component';
import { ExerciseFilesComponent } from './exercise-files/exercise-files.component';
import { ExerciseEvaluationComponent } from './exercise-evaluation/exercise-evaluation.component';
import { ExerciseApproveComponent } from './exercise-approve/exercise-approve.component';
import {PaginatorModule} from "primeng/paginator";
import { SelectButtonModule } from "primeng/selectbutton";
import { ExerciseThemesListComponent } from './exercise-themes-list/exercise-themes-list.component';
import { ExerciseAddCompetencyTypesFromThemeComponent } from './exercise-add-competencytypes-from-theme/exercise-add-competency-types-from-theme.component';
import { ThemesComponent } from './themes/themes.component';
import { AddThemeComponent } from './add-theme/add-theme.component';
import { EditThemeComponent } from './edit-theme/edit-theme.component';
import { AddParticipantsComponent } from './add-participants/add-participants.component';
import { SendSMSComponent } from './send-sms/send-sms.component';
import { ExerciseCompletedComponent } from './exercise-completed/exercise-completed.component';
import { ExperienceSharingComponent } from './experience-sharing/experience-sharing.component';
import { ExerciseCompletedDetailComponent } from './exercise-completed-detail/exercise-completed-detail.component';
import { PanelModule } from 'primeng/panel';
import {CardModule} from 'primeng/card';
import {TreeModule} from 'primeng/tree';
import {MenuModule} from 'primeng/menu';
import { ExerciseFileUploadComponent } from './exercise-file-upload/exercise-file-upload.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { ExerciseTemplateComponent } from './exercise-template/exercise-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';

export const ExerciseRoutes: Route = {
  path: 'exercises',
  children: [
    {path: 'list', component: ExercisesComponent},
    {path: 'themes', component: ThemesComponent},
    {path: 'exercise-completed', component: ExerciseCompletedComponent},
    {path: 'experience-sharing', component: ExperienceSharingComponent},
    {path: 'templates', component: ExerciseTemplateComponent},
  ]
};



@NgModule({
  declarations: [
    ExercisesComponent,
    EditExerciseComponent,
    ExerciseDetailComponent,
    EditParticipantComponent,
    ExerciseCompetencyTypeDetailComponent,
    ExerciseFilesComponent,
    ExerciseEvaluationComponent,
    ExerciseApproveComponent,
    ExerciseThemesListComponent,
    ExerciseAddCompetencyTypesFromThemeComponent,
    ThemesComponent,
    AddThemeComponent,
    EditThemeComponent,
    AddParticipantsComponent,
    SendSMSComponent,
    ExerciseCompletedComponent,
    ExperienceSharingComponent,
    ExerciseCompletedDetailComponent,
    ExerciseFileUploadComponent,
    ExerciseTemplateComponent,
    EditTemplateComponent,
  ],
    exports: [
        ExercisesComponent,
        ThemesComponent,
        ExerciseDetailComponent,
    ],
    imports: [
        CommonModule,
        TableModule,
        PipesModule,
        DropdownModule,
        TranslateModule,
        FormsModule,
        MomentModule,
        InputTextModule,
        ButtonModule,
        RippleModule,
        CalendarModule,
        InputMaskModule,
        InputTextareaModule,
        CheckboxModule,
        InputNumberModule,
        ToastModule,
        SplitButtonModule,
        AutoCompleteModule,
        ConfirmDialogModule,
        PaginatorModule,
        SelectButtonModule,
        RouterModule,
        PanelModule,
        CardModule,
        TreeModule,
        MenuModule,
        MultiSelectModule,
        ProgressBarModule,
        ProgressSpinnerModule
    ]
})
export class ExerciseModule { }
