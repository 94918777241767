import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddDeviationComponent} from "./add-deviation/add-deviation.component";
import {ReadDeviationComponent} from "./read-deviation/read-deviation.component";
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {CheckboxModule} from "primeng/checkbox";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {TranslateModule} from "@ngx-translate/core";
import {RippleModule} from "primeng/ripple";
import {MomentModule} from "ngx-moment";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {PanelModule} from "primeng/panel";
import {BlockUIModule} from "primeng/blockui";
import {FieldsetModule} from "primeng/fieldset";
import {SplitButtonModule} from "primeng/splitbutton";
import { DeviationsMainComponent } from './deviations-main/deviations-main.component';
import {Route} from '@angular/router';
import { DeviationsCompleteComponent } from './deviations-complete/deviations-complete.component';
import {CardModule} from 'primeng/card';
import {ToastModule} from 'primeng/toast';
import {TableModule} from "primeng/table";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {DividerModule} from "primeng/divider";
import {ScrollTopModule} from "primeng/scrolltop";

export const DeviationRoutes: Route = {
  path: 'deviation',
  children: [
    {path: 'deviations-main', component: DeviationsMainComponent},
    {path: 'deviations-complete', component: DeviationsCompleteComponent},
  ]
};

@NgModule({
  declarations: [
    AddDeviationComponent,
    ReadDeviationComponent,
    DeviationsMainComponent,
    DeviationsCompleteComponent
  ],
  exports: [
    AddDeviationComponent,
    ReadDeviationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    TranslateModule,
    RippleModule,
    MomentModule,
    ConfirmDialogModule,
    PanelModule,
    BlockUIModule,
    FieldsetModule,
    SplitButtonModule,
    CardModule,
    ToastModule,
    TableModule,
    ScrollPanelModule,
    DividerModule,
    ScrollTopModule,
  ]
})
export class DeviationModule { }
