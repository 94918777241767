<div class="formgrid grid">

  <input type="file" class="file-input"
         (change)="onFileSelected($event)" #fileUpload>

  <!-- Upload file -->
  <div class="file-upload col-12 formgroup-inline pb-2" style="height:48px">
    <div class="col-fixed p-0" style="width:39px">
      <button class="upload-btn" pButton type="button" icon="far fa-cloud-upload" (click)="fileUpload.click()"></button>
    </div>
    <div class="field col-10 pl-0">
      <input  class="w-full" type="text" pInputText placeholder="{{'FILE.CHOOSE_FILE' | translate }}"
              [(ngModel)]="fileName"/>
    </div>
  </div>

  <div class="col-6">
    <p-multiSelect [options]="fileTags" [(ngModel)]="selectedTags"
                   defaultLabel="{{'FILE.ADD_CATEGORY' | translate}}" styleClass="p-dropdown-item"
                   optionLabel="tagName" selectedItemsLabel="{0} items selected" [maxSelectedLabels]="1" appendTo="body"
    ></p-multiSelect>
  </div>
  <div class="col-6 flex align-content-center justify-content-end">
    <ng-container *ngIf="isAdmin$">
      <p-selectButton [options]="stateOptions" [(ngModel)]="adminOnly" optionLabel="label" optionValue="value"></p-selectButton>
    </ng-container>
  </div>

  <div class="col-12 mt-2">
    <textarea placeholder="{{'FILE.DESCRIPTION' | translate}}" [(ngModel)]="description"
              id="float-input" rows="5" class="w-full" pInputTextarea></textarea>
  </div>

  <div class="field-checkbox col-12 flex align-content-center mt-2 mb-2">
    <p-checkbox [(ngModel)]="replacesOldFiles" [binary]="true" inputId="binary"></p-checkbox>
    <label for="binary">{{'FILE.REPLACE' | translate}}</label>
  </div>

  <div class="col-12 flex justify-content-end">
    <p-button label="{{'BUTTON.UPLOAD' | translate}}" (onClick)="upload()" [disabled]="selectedTags.length < 1"></p-button>
  </div>

</div>
