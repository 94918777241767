import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {TenantService} from "./tenant.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {TranslateService} from "@ngx-translate/core";
import {map, mergeMap, publish} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  tenantId$: Observable<any>;

  constructor(
    private tenantService: TenantService,
    private db: AngularFireDatabase,
    public translateService: TranslateService,
  ) {
    this.tenantId$ = this.tenantService.getTenantId();
  }

  /**
   * Get shared fileTags
   */
  getInfoMessages(): Observable<any[]>{
    return this.tenantId$.pipe(
      mergeMap(
        tenantId => this.db.list(`tenants/${tenantId}/information/`).snapshotChanges()
      )
    );
  }

  /**
   * Update info message list
   * @param station Station fbid
   * @param messages message text
   */
  updateInfoMessages(station: string, messages: string[]) {
    console.log(station, messages);
    return publish()(this.tenantId$.pipe(
      map(
        tenantId => this.db.list(`tenants/${tenantId}/information/`).set(station, messages)
      )
    )).connect();
  }
}
