import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompetencyTypeComponent } from './competency-type/competency-type.component';
import { PersonComponent } from './person/person.component';
import { AssetComponent } from './asset/asset.component';
import { SmsComponent } from './sms/sms.component';
import {Route} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CardModule} from 'primeng/card';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {FormsModule} from '@angular/forms';
import { ViewSmsComponent } from './view-sms/view-sms.component';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { PersonSetStationTeamComponent } from './person-set-station-team/person-set-station-team.component';
import {DropdownModule} from 'primeng/dropdown';
import { FilesComponent } from './files/files.component';
import {TableModule} from 'primeng/table';
import {PipesModule} from '../../pipes/pipes.module';
import { FileSetCategoryComponent } from './file-set-category/file-set-category.component';
import {AutoCompleteModule} from 'primeng/autocomplete';


export const ArchiveRoutes: Route = {
  path: 'archive',
  children: [
    {path: 'competency', component: CompetencyTypeComponent},
    {path: 'files', component: FilesComponent},
    {path: 'asset', component: AssetComponent},
    {path: 'person', component: PersonComponent},
    {path: 'sms', component: SmsComponent},
  ]
};

@NgModule({
  declarations: [
    CompetencyTypeComponent,
    PersonComponent,
    AssetComponent,
    SmsComponent,
    ViewSmsComponent,
    PersonSetStationTeamComponent,
    FilesComponent,
    FileSetCategoryComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        CardModule,
        InputTextareaModule,
        FormsModule,
        InputTextModule,
        CalendarModule,
        ConfirmDialogModule,
        DropdownModule,
        TableModule,
        PipesModule,
        AutoCompleteModule,
    ]
})
export class ArchiveModule { }
