import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChecklistService} from "../../../services/service/checklist.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Checklist} from "../../../model/checklist";
import {Subscription} from "rxjs";
import {SubjectService} from "../../../services/service/subject.service";
import {PersonService} from "../../../services/service/person.service";
import {filter, map} from "rxjs/operators";
import {FilterService} from "primeng/api";

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit, OnDestroy {
  cId: string; // Checklist Id

  checklist: Checklist = {
    EmailNotification: false,
    SMSNotification: false,
    createdById: "",
    createdDate: "",
    name: "",
    participants: []
  };

  teamRelationsMap: Map<string, any[]> // Holds Team Relations
  personMap: Map<string, any> = new Map(); // Map for personKey, Person
  personNameMap: Map<string, string> = new Map(); // Map for personKey, PersonName

  participants = new Map<string, any>(); // Map that holds participants

  persons: any; // List of all !archive persons
  personsSuggestions = []; // Suggestions matching search for autocomplete
  teams: any // List of teams
  teamsSuggestions = []; // Suggestions matching search for autocomplete

  subscription = new Subscription();


  constructor(
    private checklistService: ChecklistService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private personService: PersonService,
    private subjectService: SubjectService,
    private filterService: FilterService,
  ) {
    this.cId = this.config.data.cId

    this.subscription.add(
      this.checklistService.getChecklist(this.cId).subscribe(checklist => {
        this.checklist = checklist;
        if (checklist.participants) {
          this.participants =
            new Map(checklist.participants
              .map(participant =>
                [participant.key, {
                  email: participant.email ? participant.email : '',
                  privateCell: (participant.privateCell) ? (participant.privateCell) : ''
                }]
              ))
        }
      })
    );

    // Get Persons - Filter out archive
    this.personService.getPersons().subscribe(persons => {
      this.persons = persons
        .map((person: any) => {
          return {key: person.key, ...person.payload.val()}
        })
        .filter((person: any) => !person.archive);

      this.personNameMap = new Map(persons.map(person => [person.key, person.payload.val().name]))
      this.personMap = new Map(persons.map(person => [person.key, person.payload.val()]))
    });

    // Get Team Relations Map for addTeam
    this.subjectService.getTeamRelationsMap().subscribe(relationMap => {
      this.teamRelationsMap = relationMap;
    })

    // Get Teams
    this.subjectService.getTeams().subscribe(teams => {
      this.teams = teams.map(team => {
        return {key: team.key, ...team.payload.val()}
      });
    });

  }

  ngOnInit(): void {
  }

  /**
   * Return suggestions for team autocomplete
   * @param event: Get search query
   */
  teamSearch(event: any) {
    this.teamsSuggestions
      = this.filterService.filter(this.teams, ['name'], event.query, 'contains')
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  /**
   * Return suggestions for person autocomplete
   * @param event
   */
  personSearch(event: any) {
    this.personsSuggestions
      = this.filterService.filter(this.persons, ['name'], event.query, 'contains')
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  /**
   * Add Team to participants
   * @param team
   */
  addTeam(team) {
    const childs = this.teamRelationsMap.get(team.key);
    childs.forEach(child => {
      if (this.personMap.has(child)) {
        const value = this.personMap.get(child);
        if (!value.archive) {
          this.addPerson({key: child, ...value})
        }
      }
    })
  }

  /**
   * Add Person to participants
   * @param person
   */
  addPerson(person: any) {
    this.participants.set(person.key, {
      email: person.email ? person.email : '',
      privateCell: (person.privateCell) ? (person.privateCell) : ''
    });
  }

  // Unsubscribe on destroy.
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Update and Close
  save() {
    this.checklist.participants = [];
    this.participants.forEach((value, key) => {
      this.checklist.participants.push({key: key, ...value})
    });
    this.checklistService.updateChecklist(this.cId, this.checklist);
    this.ref.close();
  }

  remove(key: any) {
    this.participants.delete(key);
  }
}
