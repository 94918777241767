<div class="grid">
  <div class="col-12">
    <h2>{{'FUNCTION.FUNCTIONS' | translate}}</h2>
  </div>
  <div class="col-12 p-0">
    <p-table #ft1
             [value]="functions"
             sortField="name"
             dataKey="name"
             rowGroupMode="subheader"
             [loading]="loading"
             groupRowsBy="name"
             breakpoint="200px"
             responsiveLayout="block"
             [globalFilterFields]="['name']"
    >
      <!-- CAPTION -->
      <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center">
              <span class="p-input-icon-left p-ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText
                               type="text"
                               (input)="ft1.filterGlobal($event.target.value, 'contains')"
                               placeholder="{{'COMMON.SEARCH' | translate}}"
                        />
              </span>
          <button pButton
                  pRipple
                  (click)="addFunction()"
                  type="button"
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-raised">
          </button>
        </div>
      </ng-template>


      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th style="width: 140px">
            <div class="flex justify-content-center">
              {{'COMMON.STATUS' | translate}}
            </div>
          </th>
        </tr>
      </ng-template>

      <!-- GROUP HEADER -->
      <ng-template pTemplate="groupheader" let-functionData let-expanded="expanded">
        <tr pRowGroupHeader>
          <td class="pl-2">
            <div class="flex align-content-center">
              <!-- Expand Button -->
              <button type="button"
                      pButton pRipple
                      [pRowToggler]="functionData"
                      class="p-button-text p-button-rounded p-button-plain p-mr-2 flex align-items-center"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">

              </button>

              <!-- FUNCTION NAME -->
              <span (click)="editFunction(functionData)" class="p-text-bold p-ml-2 flex align-items-center" style="font-weight: 500">
                {{functionData.name}}
              </span>
            </div>
          </td>
          <td>
            <div class="flex justify-content-evenly">
              <!-- FUNCTIONS Nr of Subjects -->
              <span style="width: 50px" class="flex justify-content-evenly">
                <i class="far fa-users"></i>&nbsp;{{getObjectSize(functionData.subjects)}}
              </span>

              <!-- FUNCTIONS Nr of CompetencyTypes -->
              <span class="ml-1 flex justify-content-evenly" style="width: 50px">
                <i [ngStyle]="getStatus(functionData.valid)" class="far fa-graduation-cap"></i>&nbsp;{{getObjectSize(functionData.competencyTypes)}}
              </span>

<!--              &lt;!&ndash; FUNCTIONS Nr of CompetencyTypes &ndash;&gt;-->
<!--              <span class="ml-1 flex justify-content-evenly" style="width: 50px">-->
<!--                <i [ngStyle]="getRepeatStatus(functionData.subjects)" class="pi pi-replay"></i>&nbsp;-->
<!--                {{getCount(functionData.competencyTypes)}}-->
<!--              </span>-->
            </div>
          </td>
        </tr>
      </ng-template>

      <!-- Show Function Subjects -->
      <ng-template pTemplate="rowexpansion" let-functionData>
        <tr *ngFor="let subject of functionData.subjects | keyvalue">
          <td (click)="personDetail(functionData.key, subject.key)">{{personNameMap.get(subject.key)}}</td>
          <td *ngIf="subject.value.valid"><i class="pi pi-check-circle flex justify-content-center pr-2" style="color: #73BF21"></i></td>
          <td *ngIf="!subject.value.valid"><i class="pi pi-exclamation-circle  flex justify-content-center pr-2" style="color: #F5A623"></i></td>
        </tr>
      </ng-template>

      <!-- EMPTY MESSAGE -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
