import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FileService} from '../../../services/service/file.service';
import {take} from 'rxjs/operators';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-file-set-category',
  templateUrl: './file-set-category.component.html',
  styleUrls: ['./file-set-category.component.scss'],
})
export class FileSetCategoryComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  totalRecords: number;
  fileTags: any[] = [];
  search = "";

  loading = true;
  results: any[];
  selectedCategory: any[];

  constructor(
      private fileService: FileService,
      private ref: DynamicDialogRef,
  ) { }


  ngOnInit(): void {
    this.loading = true;
    this.subscription.add(this.fileService.getFileTags().pipe(take(1)).subscribe(
        fileTags => {
          this.fileTags = fileTags.map(tag => {
            if (!tag.archived) {
              return {key: tag.key, name: tag.payload.val().tagName};
            }
          });
          this.totalRecords = this.fileTags.length;
          this.fileTags.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          });
        })
    );
  }

  /**
   * Return selected category
   */
  complete() {
    this.ref.close(this.selectedCategory);
  }

  /**
   * Close
   */
  close() {
    this.ref.close();
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
