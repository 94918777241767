import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Route} from "@angular/router";
import { DashboardComponent } from './dashboard/dashboard.component';
import {TranslateModule} from "@ngx-translate/core";
import {ToastModule} from "primeng/toast";
import {TableModule} from "primeng/table";
import {MomentModule} from "ngx-moment";
import {PipesModule} from "../../pipes/pipes.module";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {CarouselModule} from "primeng/carousel";
import {ChecklistModule} from "../checklist/checklist.module";
import {TaskModule} from "../task/task.module";
import {ExerciseModule} from "../exercise/exercise.module";
import {CardModule} from "primeng/card";
import {ButtonModule} from 'primeng/button';
import { OrderSMSComponent } from './order-sms/order-sms.component';
import {InputTextModule} from 'primeng/inputtext';
import {InputNumberModule} from 'primeng/inputnumber';
import {SelectButtonModule} from 'primeng/selectbutton';

export const DashboardRoutes: Route = {
  path: '', component: DashboardComponent
}

@NgModule({
  declarations: [DashboardComponent, OrderSMSComponent],
    imports: [
        CommonModule,
        TranslateModule,
        ToastModule,
        TableModule,
        MomentModule,
        PipesModule,
        DropdownModule,
        FormsModule,
        CarouselModule,
        ChecklistModule,
        TaskModule,
        ExerciseModule,
        CardModule,
        ButtonModule,
        InputTextModule,
        InputNumberModule,
        SelectButtonModule
    ]
})
export class DashboardModule { }
