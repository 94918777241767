import { Injectable } from '@angular/core';
import {PersonSubject} from '../../model/subject';
import {TreeNode} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class PersonTreeService {


  constructor() {}

  getCrewTree(persons, teams, stations, relations){

    const crewTree: TreeNode[] = [];
    const personNameMap = new Map<string, string>(persons.map(person => [person.key, person.payload.val().name]));
    const personMap = new Map<string, PersonSubject>(persons.map(person => [person.key, person.payload.val()]));
    const teamNameMap = new Map<string, string>(teams.map(team => [team.key, team.payload.val().name]));
    const stationNameMap = new Map<string, string>(stations.map(station => [station.key, station.payload.val().name]));

    const tempMap = new Map<string, string[]>();
    const stationTeamMap = new Map<string, string[]>();

    relations.forEach(relation => {
      if(teamNameMap.has(relation.parentId)){
        if(tempMap.has(relation.parentId)){
          const children: any[] = tempMap.get(relation.parentId);
          children.push(relation.childId);
          tempMap.set(relation.parentId, children);
        } else {
          const children = [relation.childId];
          tempMap.set(relation.parentId, children);
        }
      }
      if (stationNameMap.has(relation.parentId)){
        if (stationTeamMap.has(relation.parentId)){
          stationTeamMap.get(relation.parentId).push(relation.childId);
        } else {
          stationTeamMap.set(relation.parentId, [relation.childId]);
        }
      }
    });

    const parentChildMap = new Map<string, Map<string, string[]>>();
    for (const [stationKey, list] of stationTeamMap.entries()) {
      list.forEach(teamKey => {
        if (parentChildMap.has(stationKey)){
          parentChildMap.get(stationKey).set(teamKey, tempMap.get(teamKey));
        } else {
          parentChildMap.set(stationKey, new Map().set(teamKey, tempMap.get(teamKey)));
        }
      });
    }

    for (const [sKey, teamMap] of parentChildMap.entries()) {
      const stationNode: TreeNode = {
        label: stationNameMap.get(sKey),
        leaf: false,
        selectable: true,
        children: [] = [],
        key: sKey,
      };

      for (const [tKey, pList] of teamMap.entries()){
        const teamNode: TreeNode = {
          label: teamNameMap.get(tKey),
          leaf: false,
          children: [] = [],
          parent: stationNode,
          key: tKey,
        };

        if (pList){
          pList.forEach(personId => {
            if (personMap.has(personId)) {
              const personNode: TreeNode = {
                label: personNameMap.get(personId),
                data: personMap.get(personId).privateCell,
                leaf: true,
                parent: teamNode,
                key: personId,
                selectable: true,
              };
              if (personMap.get(personId).privateCell) {
                teamNode.children.push(personNode);
              }
            }
          })
        }
        if (teamNode.children.length > 0) {
          stationNode.children.push(teamNode);
        }
      }
      if (stationNode.children.length > 0) {
        crewTree.push(stationNode);
      }
    }
    return crewTree;
  }
}
