<!-- Toast -->
<p-toast key='new-subject' position="top-right" styleClass="customToast"></p-toast>

<div class="grid">

  <!-- NAME -->
  <div class="col-12">
    <div class="field">
      <label for="name">{{'COMMON.NAME' | translate}}</label>
      <input id="name" type="text" pInputText [(ngModel)]="name" class="inputfield w-full">
    </div>
  </div>

  <!-- EMAIL - ONLY FOR PERSON TYPE -->
  <div class="col-12" *ngIf="selectedFilter.value === 'PERSON'">
    <div class="field">
      <label for="email">{{'PERSONAL.EMAIL' | translate}}</label>
      <input id="email" type="email" pInputText [(ngModel)]="email" class="inputfield w-full">
    </div>
  </div>

  <!-- STATION FOR TEAM AND PERSON-->
  <div class="col-6 field" *ngIf="['PERSON', 'FIRETEAM'].includes(selectedFilter.value) > 0">
    <label for="stationSelect">{{'COMMON.STATION' | translate}}</label>
    <select name="station" id="stationSelect" (ngModelChange)="getTeams($event)" [(ngModel)]="selectedStation" style="appearance: auto" class="w-full inputfield" pInputText>
      <option [ngValue]="null" [disabled]="true" >{{'COMMON.CHOOSE' | translate}}</option>
      <option *ngFor="let station of stations" [ngValue]="station">{{station.name}}</option>
    </select>
  </div>

  <!-- TEAM ONLY FOR PERSON -->
  <div class="col-6" *ngIf="selectedFilter.value === 'PERSON'">
    <div class="field">
      <label for="teamSelect">{{'SUBJECTS.TYPES.FIRETEAM' | translate}}</label>
      <select  [disabled]="!selectedStation" name="team" id="teamSelect" [(ngModel)]="selectedTeam" style="appearance: auto" class="w-full inputfield" pInputText>
        <option [ngValue]="null" [disabled]="true" >{{'COMMON.CHOOSE' | translate}}</option>
        <option *ngFor="let team of teams" [ngValue]="team">{{team.name}}</option>
      </select>
    </div>
  </div>

  <!-- TYPE -->
  <div class="col-12 field">
    <label for="dropdown">{{'COMMON.TYPE' | translate}}</label>
    <select name="stFilter" id="dropdown" [(ngModel)]="selectedFilter" style="appearance: auto" class="w-full inputfield" pInputText>
      <option *ngFor="let st of subjectTypesFilter" [ngValue]="st">{{st.name | translate}}</option>
    </select>
  </div>
</div>


<!-- FOOTER -->
<div class="grid">
  <div class="col-12" style="margin-top: 35px">
  <span class="p-buttonset float-right">
      <button pButton type="button" label="{{'BUTTON.CLOSE' | translate}}" (click)="close()"
              class="p-button-outlined p-button-raised"></button>
      <button pButton type="button" class="p-button-raised" label="{{'BUTTON.SAVE' | translate}}"
              (click)="add()" [disabled]="validator()"></button>
    </span>
  </div>
</div>
