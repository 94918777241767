import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventStationSortService {

  constructor() { }

  sortOnStation(events, relations, stationNameMap, teamNameMap, personNameMap){

    const groupedMap = new Map(); const teamStationMap = new Map(); const personParentNameMap = new Map();
    const groupedFilter = []; const eventMap = new Map();

    eventMap.set("All", events);

    relations.forEach(relation => {
      if(stationNameMap.has(relation.parentId)){
        teamStationMap.set(relation.childId, relation.parentId);
      }
    });

    relations.forEach(relation => {
      if(teamNameMap.has(relation.parentId)){
        if(groupedMap.has(teamStationMap.get(relation.parentId))){
          const children: any[] = groupedMap.get(teamStationMap.get(relation.parentId));
          children.push(relation.childId);
          groupedMap.set(teamStationMap.get(relation.parentId), children);
        } else {
          const children = [relation.childId];
          groupedMap.set(teamStationMap.get(relation.parentId), children);
        }
      }
      if(personNameMap.has(relation.childId)){
        if(stationNameMap.has(teamStationMap.get(relation.parentId))){
          personParentNameMap.set(relation.childId, stationNameMap.get(teamStationMap.get(relation.parentId)));
        } else {
          personParentNameMap.set(relation.childId, 'N/A');
        }
      }
    });
    groupedMap.forEach((children, key) => {
      const teamName = stationNameMap.get(key);
      const obj: any = {};
      obj.label = teamName;
      obj.value = key;
      obj.items = children.map(child => this.buildFilter(personNameMap, child));
      groupedFilter.push(obj);
    });
    groupedFilter.sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    groupedFilter.forEach(station => {
      eventMap.set(station.value, []);
      events.forEach(emergency => {
        const personIds = [];
        if (emergency.participants){
          emergency.participants.forEach(participant => personIds.push(participant.key));
        }
        let counter = 0;
        station.items.forEach(element =>{
          if (personIds.includes(element.value)){
            counter++;
          }
        });
        if (counter > personIds.length / 2) {
          if (!eventMap.get(station.value).includes(emergency)) {
            eventMap.get(station.value).push(emergency);
          }
        }
      });
    });

    return [groupedFilter, eventMap, teamStationMap];
  }

  /**
   * Build persons station object
   * @param personNameMap {key: string, name: string}
   * @param subject Person
   */
  buildFilter(personNameMap, subject) {
    return {
      label: personNameMap.get(subject),
      value: subject,
    };
  }

}
