<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid body">
  <div class="col-12 main">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <div class="col-12 relative" *ngFor="let comment of comments | async, let i = index">
        <div style="word-break: break-all">{{comment.commentText}}</div>
        <small class="text-muted float-right" style="margin-top: 10px; margin-bottom: 3px">
          {{comment.commenter.name}}&nbsp;{{("COMMON.FOR" | translate).toLowerCase()}}&nbsp;{{ comment.time | amTimeAgo }}</small>
        <p-divider></p-divider>
      </div>
      <p *ngIf="(comments | async)?.length < 1">{{'COMMON.NO_COMMENTS' | translate}}</p>
      <p-scrollTop target="parent" [threshold]="100"></p-scrollTop>
    </p-scrollPanel>
  </div>

  <div class="col-12 footer">
    <textarea style="width: 100%; max-height: 100px" [disabled]="isObserver" [(ngModel)]="commentText" [rows]="4"
              pInputTextarea autoResize="autoResize"></textarea>
    <span class="p-buttonset float-right mt-1">
      <button pRipple pButton type="button" label="{{'BUTTON.CLOSE' | translate}}" (click)="close()"
              class="p-button-outlined p-button-raised"></button>
      <button pButton class="p-button-raised" [disabled]="isObserver"
              type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="save(commentText)"></button>
    </span>
  </div>
</div>

