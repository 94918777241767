<!-- Toast -->
<p-toast key='exercise-add' position="top-right" styleClass="customToast"></p-toast>

<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>

<div class="grid">

    <!-- Name -->
    <div class="field col-12 mb-0 mt-3">
          <span class="p-float-label">
            <input [ngStyle]="validation(template.name)" [readOnly]="readOnly" id="name" type="text"
                   [(ngModel)]="template.name" pInputText class="inputfield w-full">
            <label for="name">{{'COMMON.NAME' | translate}}</label>
          </span>
    </div>

    <!-- Description -->
    <div class="field col-12 mt-2 mb-0">
        <span class="p-float-label">
            <textarea id="descriptionL"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      pInputTextarea
                      [(ngModel)]="template.details"
                      class="inputfield w-full"></textarea>
            <label for="descriptionL">{{'COMMON.DESCRIPTION' | translate}}</label>
        </span>
    </div>
    <!-- Goals -->
    <div class="field col-12 mt-2 mb-0">
        <span class="p-float-label">
            <textarea id="goalsL"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      pInputTextarea
                      [(ngModel)]="template.evaluation.goal"
                      class="inputfield w-full"></textarea>
            <label for="goalsL">{{'EXERCISE.EVALUATION.GOAL' | translate}}</label>
        </span>
    </div>
    <!-- Competency table -->
    <div class="col-12 p-0">
        <p-table [loading]="!ctNameMap" [value]="templateCT" breakpoint="200px">
            <ng-template pTemplate="caption">
                <div class="grid">
                    <div class="col-12 pb-0">
                        <p-autoComplete
                                [suggestions]="outputTheme | removeExistingFilter: themeMap"
                                (completeMethod)="searchTheme($event)"
                                (onSelect)="addTheme($event)"
                                [dropdown]="true"
                                [(ngModel)]="selectedTheme"
                                placeholder="{{'EXERCISE.ADD_THEME' | translate}}"
                                field="name"
                                scrollHeight="300px"
                                [style]="{'width':'100%'}"
                                type="search"
                                class="inputfield w-full"
                                appendTo="body"
                                emptyMessage="{{'EVENT.NO_RESULT' | translate}}"
                                [forceSelection]="true"
                        ></p-autoComplete>
                    </div>
                    <div class="col-12">
                        <p-autoComplete
                                [suggestions]="outputCT | removeExistingFilter: competencyTypeMap"
                                (completeMethod)="searchCT($event)"
                                (onSelect)="addCompetencyType($event)"
                                [dropdown]="true"
                                [(ngModel)]="selectedCT"
                                placeholder="{{'COMPETENCY.AND_OR_COMPETENCY_TYPE' | translate}}"
                                field="name"
                                scrollHeight="300px"
                                [style]="{'width':'100%'}"
                                type="search"
                                class="inputfield w-full"
                                appendTo="body"
                                emptyMessage="{{'EVENT.NO_RESULT' | translate}}"
                                [forceSelection]="true"
                        ></p-autoComplete>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 85%">{{'COMMON.NAME' | translate}}</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-competencyType>
                <tr>
                    <td>{{competencyType.name}}</td>
                    <td (click)="removeCompetency(competencyType)"><i style="color: #D0021B" class="pi pi-trash"></i></td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">{{'COMMON.NO_COMPETENCY' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <!-- File table -->
    <div class="col-12">
        <p-table [value]="template.exerciseFiles" dataKey="key" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'FILE.ATTACHED_FILES' | translate}}</th>
                    <th style="width: 2rem">
                        <button pButton (click)="addFiles()" type="button" icon="pi pi-plus"
                                class="p-button-rounded p-button-raised"></button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-fileID>
                <tr>
                    <td colspan="2">
                        {{fileNameMap.get(fileID)}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">{{'FILE.NO_FILES' | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<!-- BUTTONS -->
<div class="grid" style="margin-top: 10px">
    <div class="col-6">
        <button *ngIf="!readOnly && !tempCreation" pButton icon="far fa-trash-alt" type="button"
                label="{{'BUTTON.REMOVE' | translate}}" style="color: red"
                (click)="confirm()" class="p-button-outlined p-button-raised"></button>
    </div>
    <div class="col-6 flex justify-content-end">
        <button *ngIf="!readOnly" pButton icon="pi pi-times" type="button" label="{{'BUTTON.CANCEL' | translate}}"
                (click)="close()" class="p-button-outlined p-button-raised"></button>
        <button *ngIf="!readOnly" class="p-button-raised" pButton icon="pi pi-bookmark"
                type="button" label="{{'BUTTON.SAVE' | translate}}" (click)="save()"></button>
    </div>
</div>
