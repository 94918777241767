<!-- Toast -->
<p-toast key='toast' position="top-left" styleClass="customToast"></p-toast>
<p-confirmDialog header="{{'WARNING.CONFIRMATION' | translate}}" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="grid col-12">
  <h2 style="margin-top: 10px">{{'COMMON.INFO_MESSAGE' | translate}}</h2>
</div>
<div class="grid">
  <div class="col-12">
    <p-dropdown [options]="stations"
                [style]="{'width' : '100%'}"
                placeholder="{{'COMMON.STATION' | translate}}"
                optionLabel="name"
                optionValue="key"
                [(ngModel)]="selectedStation">
    </p-dropdown>
  </div>
  <div class="col-11">
    <input type="text" class="w-full" placeholder="{{'COMMON.MESSAGE_MAX80' | translate}}" [(ngModel)]="message" pInputText/>
  </div>
  <div class="col-1">
    <button pButton type="button" icon="pi pi-plus" (click)="confirmStation()"
            class="p-button-rounded p-button-raised" [disabled]="!message"></button>
  </div>
  <div class="col-12" *ngIf="!loading">
    <p-table [value]="infoMessageMap.get(selectedStation)" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 50px">
              <span class="flex justify-content-center">#</span>
            </th>
            <th>{{"COMMON.MESSAGE" | translate}}</th>
            <th style="width: 50px">
              <span class="flex justify-content-center">
                <i class="pi pi-trash" style="color: #D0021B"></i>
              </span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-message>
          <tr>
            <td>{{message.nr}}</td>
            <td>{{message.text}}</td>
            <td (click)="confirmDelete(message)">
              <span class="flex justify-content-center">
                <i class="pi pi-trash" style="color: #D0021B"></i>
              </span>
            </td>
          </tr>
        </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td></td>
          <td colspan="2">{{'COMMON.NO_INFO_MESSAGE' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
