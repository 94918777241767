// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiKey: 'AIzaSyCDsGp9SGE21W34yuqaJgkle0B9ayX0zvY',
  authDomain: 'flexitfire-dev.firebaseapp.com',
  projectId: 'flexitfire-dev',
  databaseURL: 'https://flexitfire-dev-default-rtdb.europe-west1.firebasedatabase.app/',
  storageBucket: 'flexitfire-dev.appspot.com',
  messagingSenderId: '306651188649',
  appId: '1:306651188649:web:91e6fba0cd14b58b9548b8',
  measurementId: 'G-R842E83PVL'

  // production: true,
  // apiKey: "AIzaSyAvr8HYlPHZjKp5bAJnI9hYDrsG8TsJR4Y",
  // authDomain: "flexitfire-prod.firebaseapp.com",
  // databaseURL: "https://flexitfire-prod-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "flexitfire-prod",
  // storageBucket: "flexitfire-prod.appspot.com",
  // messagingSenderId: "112339474961",
  // appId: "1:112339474961:web:c05aa22561759a35c5efb0",
  // measurementId: "G-D6YS26Q7C4"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
