import { Component, OnInit } from '@angular/core';
import {Exercise, ExerciseTemplate} from '../../../model/exercise';
import {Subscription} from 'rxjs';
import {ExerciseService} from '../../../services/service/exercise.service';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {EditTemplateComponent} from '../edit-template/edit-template.component';
import {FileService} from '../../../services/service/file.service';
import {FileData} from '../../../model/fileRelated';
import {CompetencyTypeService} from '../../../services/service/competency-type.service';
import {take} from 'rxjs/operators';
import {AuthService} from '../../../services/service/auth.service';

@Component({
  selector: 'app-exercise-template',
  templateUrl: './exercise-template.component.html',
  styleUrls: ['./exercise-template.component.scss'],
  providers: [DialogService]
})
export class ExerciseTemplateComponent implements OnInit {
  subscription = new Subscription();
  templates: Exercise[];
  template: ExerciseTemplate = null;
  templateKey = null;
  selectedExerciseFilter: any;
  templateLoading = true;

  ctNameMap: Map<string, string>;
  fileNameMap: Map<string, string>;
  files: Map<string, FileData>;

  templateCTMap = new Map<string, string[]>();

  constructor(
      private exerciseService: ExerciseService,
      private dialogService: DialogService,
      private translateService: TranslateService,
      private fileService: FileService,
      private ctService: CompetencyTypeService,
      private authService: AuthService,
  ) { }

  ngOnInit(): void {
    // Get templates
    this.subscription.add(
        this.exerciseService.getTemplates().subscribe(templatesPayload => {
          this.templates = templatesPayload.map(template => {
            const tempTemplate = template.payload.val();
            if (!tempTemplate.competencyTypes){
              tempTemplate.competencyTypes = [];
            }
            if (!tempTemplate.exerciseFiles){
              tempTemplate.exerciseFiles = [];
            }
            tempTemplate.key = template.key;

            this.templateCTMap.set(template.key, []);
            if (Object.keys(tempTemplate.competencyTypes).length > 0){
              Object.keys(tempTemplate.competencyTypes).forEach(key => this.templateCTMap.get(template.key).push(key));
            }

            return tempTemplate;
          }).sort((a,b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          this.templateLoading = false;
        })
    );
    this.subscription.add(this.fileService.getAllFiles().subscribe(files => {
      this.fileNameMap = new Map(files.map(file => [file.key, file.payload.val().name]));
      this.files = new Map(files.map(file => [file.key, file.payload.val()]));
    }));
    this.subscription.add(
        this.ctService.getCompetencyTypes().subscribe(cts => {
          this.ctNameMap = new Map(cts.map(ct => [ct.key, ct.payload.val().name]));
        })
    );
  }

  /**
   * Add new template.
   */
  addTemplate() {
    this.dialogService.open(EditTemplateComponent, {
      header: this.translateService.instant('EXERCISE.NEW_EXERCISE_TEMPLATE'),
      styleClass: 'max-size-width-dialog',
      closable: false,
      data: {
        key: this.templateKey,
        template: this.template,
      },
    });
  }

  /**
   * View/Change template details.
   * @param template Template object.
   */
  templateDetails(template: Exercise) {
    this.dialogService.open(EditTemplateComponent, {
      header: this.translateService.instant('EXERCISE.EXERCISE_TEMPLATE'),
      styleClass: 'max-size-width-dialog',
      closable: false,
      data: {
        key: template.key,
        template: template,
      },
    });
  }

  /**
   * Return length of CT object
   * @param competencyTypes Object
   */
  cTLength(competencyTypes: any[]) {
    return Object.keys(competencyTypes).length;
  }

  create(exercise: Exercise) {
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      exercise.createdById = uid;
      exercise.created = new Date().toISOString();
      exercise.createdById = uid;
      exercise.originId = exercise.key;
      exercise.template = false;
      delete exercise.key;
      this.exerciseService.addExercise(exercise);
    });
  }
}
