<div class="grid">
  <div class="col-12 flex align-items-center justify-content-between">
    <h2 style="margin-top: 10px">{{'CHECKLIST.REPETITIVE' | translate}}</h2>
    <div class="justify-content-end">
      <button pButton (click)="addChecklist()" type="button" icon="pi pi-plus"
              class="p-button-rounded p-button-raised"></button>
    </div>
  </div>
  <div class="col-12" style="padding-right: 0; padding-left: 0;">
    <p-table
      #cr
      [value]="repeatingChecklists | dashboardFilter: 'checklist' : selectedStation"
      [rowHover]="true"
      [globalFilterFields]="['name']"
      [loading]="repeatingLoading"
      breakpoint="200px">
      <ng-template pTemplate="caption" style="max-width: 100%">
        <div class="formgrid grid">
          <div class="field col" style="min-width: 100px; max-width: 400px">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                class="inputfield w-full"
                pInputText
                type="text"
                size="100"
                (input)="cr.filterGlobal($event.target.value, 'contains')"
                placeholder="{{'COMMON.SEARCH' | translate}}"/>
            </div>
          </div>

          <div class="field col">
            <p-dropdown
              [options]="repeatingFilter"
              [(ngModel)]="selectedStation"
              optionLabel="name"
              class="inputfield w-full"
              optionValue="key"
              [style]="{'width':'100%'}">
            </p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1rem"><i class="far fa-clock"></i></th>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th>{{'ASSET.ASSET' | translate}}</th>
          <th>{{'COMMON.DATE.DATE' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-checklist>
        <tr>
          <td>
            <ng-container *ngIf="checklist.cron.pause; then pause else play"></ng-container>
            <ng-template #pause>
              <i class="far fa-pause" style="color: #F5A623"></i>
            </ng-template>
            <ng-template #play>
              <i class="far fa-play" style="color: #73BF21;"></i>
            </ng-template>
          </td>
          <td (click)="openRepeatChecklistDetail(checklist)">{{checklist.name}}</td>
          <td>{{assetMap.get(checklist.assetId)}}</td>
          <td><span pTooltip="{{changeDateFormat(checklist.nextDate)}}"
                    tooltipPosition="top"
                    tabindex="0"
                    tooltipEvent="focus"
                    style="outline: 0;">{{getCronJobMode(checklist.cron)}}</span></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">{{'CHECKLIST.NO_CHECKLISTS' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
