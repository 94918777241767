import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {DashboardRoutes} from "./modules/dashboard/dashboard.module";
import {MainFrameComponent} from "./components/main-frame/main-frame.component";
import {AuthGuard} from "./auth.guard";
import {ChecklistRoutes} from "./modules/checklist/checklist.module";
import {AdminRoutes} from "./modules/admin/admin.module";
import {SubjectRoutes} from "./modules/subject/subject.module";
import {CompetencyRoutes} from "./modules/competency/competency.module";
import {ExerciseRoutes} from "./modules/exercise/exercise.module";
import {FunctionRoutes} from "./modules/function/function.module";
import {EventRoutes} from './modules/events/events.module';
import {CalendarRoutes} from './modules/calender/calender.module';
import {RoleRoutes} from './modules/roles/roles.module';
import {FilesRoutes} from './modules/files/files.module';
import {SmsRoutes} from './modules/sms/sms.module';
import {AssetsRoutes} from './modules/assets/assets.module';
import {TaskRoutes} from './modules/task/task.module';
import {DeviationRoutes} from './modules/deviation/deviation.module';
import {MyPageRoutes} from './modules/my-page/my-page.module';
import {InfoMessageRoutes} from "./modules/info-message/info-message.module";
import {ShiftReportRoutes} from './modules/shift-report/shift-report.module';
import {ArchiveRoutes} from './modules/archive/archive.module';
import {ReportRoutes} from "./modules/reports/reports.module";

const routes: Routes = [

  {
    path: 'login', component: LoginComponent
  },
  {
    path: '', component: MainFrameComponent,
    canActivate: [AuthGuard],
    children: [
      DashboardRoutes, ChecklistRoutes, AdminRoutes, SubjectRoutes, CompetencyRoutes,
      ExerciseRoutes, FunctionRoutes, CalendarRoutes, RoleRoutes, FilesRoutes,
      SmsRoutes, AssetsRoutes, TaskRoutes, DeviationRoutes, MyPageRoutes, InfoMessageRoutes,
      ShiftReportRoutes, EventRoutes, ArchiveRoutes, ReportRoutes
    ],
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
