<div class="formgrid grid">

  <!-- Date -->
  <div class="field col-6 mt-3">
        <span class="p-float-label">
            <input type="text" id="date"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="emergency.date"
            >
            <label for="date">{{'COMMON.DATE.DATE' | translate}}</label>
        </span>
  </div>

  <!-- Select type -->
  <div class="col-6 mt-3">
    <span class="p-float-label">
            <input type="text" id="type"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="getType()">

            <label for="type">{{'COMMON.TYPE' | translate}}</label>
        </span>
  </div>

  <!-- Time From -->
  <div class="field col-6 mt-3">
        <span class="p-float-label">
          <input type="text"
                 id="startTime"
                 pInputText
                 class="inputfield w-full"
                 readonly
                 [ngModel]="emergency.timeStart"
          >
          <label for="startTime">{{'COMMON.FROM' | translate}}*</label>
        </span>
  </div>

  <!-- To Time -->
  <div class="field col-6 mt-3">
         <span class="p-float-label">
           <input type="text"
                  id="endTime"
                  pInputText
                  class="inputfield w-full"
                  readonly
                  [ngModel]="emergency.timeEnd"
           >
           <label for="endTime">{{'COMMON.TO' | translate}}*</label>
         </span>
  </div>

  <!-- Address -->
  <div class="field col-12 mt-2">
    <div class="p-inputgroup">
          <span class="p-float-label">
            <input type="text" id="address"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="emergency.location.address"
            >
            <label for="address">{{'SUBJECTS.ADDRESS' | translate}}</label>
          </span>
    </div>
  </div>

  <!-- Zip -->
  <div class="field col-6 mt-2">
          <span class="p-float-label">
            <input type="text" id="zip"
                   pInputTextarea
                   class="inputfield w-full"
                   [ngModel]="emergency.location.zip">
            <label for="zip">{{'SUBJECTS.ZIP' | translate}}</label>
          </span>
  </div>

  <!-- Postal -->
  <div class="field col-6 mt-2">
          <span class="p-float-label">
              <input type="text" id="postal"
                     pInputTextarea
                     class="inputfield w-full"
                     readonly
                     [ngModel]="emergency.location.postal"
              >
            <label for="postal">{{'SUBJECTS.POSTAL' | translate}}</label>
          </span>
  </div>

  <!-- Description -->
  <div class="field col-12 mt-2 mb-2">
          <span class="p-float-label">
            <textarea id="textarea"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      class="w-full"
                      pInputTextarea
                      readonly
                      [ngModel]="emergency.details"
            >
            </textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
          </span>
  </div>

  <!-- Bris number -->
  <div class="field col-12 mt-3">
            <span class="p-float-label">
            <input type="text" id="bris"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="emergency.brisNumber">
            <label for="bris">{{'EVENT.BRIS' | translate}}</label>
          </span>
  </div>

  <!-- Emergency leader -->
  <div class="col-6 mt-2 mb-2">
      <span class="p-float-label">
            <input type="text" id="emergencyLeader"
                   pInputTextarea
                   class="inputfield w-full"
                   readonly
                   [ngModel]="getLeader()">
            <label for="emergencyLeader">{{'EVENT.ERE.EMERGENCY_LEADER' | translate}}</label>
      </span>
  </div>

  <!-- Person table -->
  <div class="col-12 flex w-full">
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="personArray"
             dataKey="$key"
    >
      <!-- Header -->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 45%">{{'FDC.PERSONS' | translate}}</th>
          <th style="width: 30%">
                  <span class="flex justify-content-center">
                    <i class="far fa-stopwatch"></i>
                  </span>
          </th>
          <th style="width: 25%">
                  <span class="flex justify-content-center">
                    <i style="color: #F5A623" class="far fa-exclamation-triangle"></i>
                  </span>
          </th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body" let-person>
        <tr [pRowToggler]="person">
          <td>
            <i class="far fa-angle-down"></i> {{getName(person)}}
          </td>
          <td>
            <div class="flex justify-content-center">
              {{printTime(person)}}
            </div>
          </td>
          <td>
            <div class="flex justify-content-center">
              {{exposureCounter(person)}}
            </div>
          </td>
        </tr>
      </ng-template>

      <!-- Row toggle -->
      <ng-template pTemplate="rowexpansion" let-person>

        <!-- If new data -->
        <ng-template [ngIf]="person.newData" [ngIfElse]="elseBlock">
          <tr>
            <td colspan="1">
              <!-- Time From -->
              <div class="field w-full">
                <label style="height: 2rem" for="timeFrom">
                  {{'COMMON.PARTICIPATED' | translate}} {{'COMMON.FROM_SMALL_F' | translate}}
                </label>
                <input type="time"
                       id="timeFrom"
                       pInputText
                       readonly
                       class="inputfield w-full"
                       [ngModel]="person.timeStart"
                >
              </div>
            </td>
            <td colspan="2">
              <!-- To Time -->
              <div class="field w-full">
                <label style="height: 2rem" for="timeTo">
                  {{'COMMON.TO' | translate}}
                </label>
                <input type="time"
                       id="timeTo"
                       pInputText
                       readonly
                       class="inputfield w-full"
                       [ngModel]="person.timeEnd"
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <p><b>{{'EVENT.EXPOSURES_MAIN' | translate}}:</b> {{printExposures(person)}}</p>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <p><b>{{'ROLES.ROLES' | translate}}:</b> {{printNewRoles(person)}}</p>
            </td>
          </tr>
        </ng-template>

        <!-- Else old data -->
        <ng-template #elseBlock>
          <tr>
            <td colspan="1">
              <!-- Time From -->
              <div class="field w-full">
                <label style="height: 2rem" for="pTime">
                  {{'EVENT.EMERGENCY' | translate}} ({{'EVENT.NUMBER_OF_HOURS' | translate}})
                </label>
                <input type="time"
                       id="pTime"
                       pInputText
                       readonly
                       class="inputfield w-full"
                       [ngModel]="person.time"
                >
              </div>
            </td>
            <td colspan="2">
              <!-- To Time -->
              <div class="field w-full">
                <label style="height: 2rem" for="suppTime">
                  {{'EVENT.ERE.AFTERWORK' | translate}} ({{'EVENT.NUMBER_OF_HOURS' | translate}})
                </label>
                <input type="time"
                       id="suppTime"
                       pInputText
                       readonly
                       class="inputfield w-full"
                       [ngModel]="person.supplementaryTime"
                >
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3">
              <p><b>{{'ROLES.ROLES' | translate}}:</b> {{person.role}}</p>
            </td>
          </tr>
        </ng-template>
      </ng-template>


      <!-- Shows message if table is empty -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">
            {{'WARNING.NO_DATA' | translate}}
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>

  <!-- Evaluation table -->
  <div class="col-12 flex w-full">
    <p-table responsiveLayout="block"
             breakpoint="200px"
             [value]="evalBool"

    >
      <!-- Header -->
      <ng-template pTemplate="header" class="w-full">
        <tr>
          <th>{{'COMMON.EVALUATION' | translate}}</th>
        </tr>
      </ng-template>

      <!-- Body -->
      <ng-template pTemplate="body">
        <tr>
          <td>
            <div class="grid">
              <div class="col-12">
                <span class="p-float-label mt-3">
                  <textarea id="description" class="w-full" rows="5"
                            pInputTextarea [ngModel]="evaluation.description" readonly></textarea>
                  <label for="description">{{'COMMON.DESCRIPTION' | translate}}</label>
                </span>
                <span class="p-float-label mt-4">
                  <textarea id="measuresOnSite" class="w-full" rows="5"
                            pInputTextarea [ngModel]="evaluation.measuresOnSite" readonly></textarea>
                  <label for="measuresOnSite">{{'EVENT.EVAL.MEASURES_ON_SITE' | translate}}</label>
                </span>
                <span class="p-float-label mt-4">
                  <textarea id="learningPoints" class="w-full" rows="5"
                            pInputTextarea [ngModel]="evaluation.learningPoints" readonly></textarea>
                  <label for="learningPoints">{{'EVENT.EVAL.LEARNING_POINTS' | translate}}</label>
                </span>
                <span class="p-float-label mt-4">
                  <textarea id="various" class="w-full" rows="5"
                            pInputTextarea [ngModel]="evaluation.various" readonly></textarea>
                  <label for="various">{{'EVENT.EVAL.VARIOUS' | translate}}</label>
                </span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>

      <!-- Shows message if table is empty -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td>
            {{'WARNING.NO_DATA' | translate}}
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>

</div>
