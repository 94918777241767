<div class="grid">
  <div class="col-12 flex justify-content-start">
    <div class="flex align-items-center mr-5 ml-3">
      <div class="mr-2">{{'BUTTON.SMS' | translate}}</div>
      <p-inputSwitch [(ngModel)]="checklist.SMSNotification"></p-inputSwitch>
    </div>
    <div class="flex align-items-center">
      <div class="mr-2">{{'PERSONAL.EMAIL' | translate}}</div>
      <p-inputSwitch [(ngModel)]="checklist.EmailNotification"></p-inputSwitch>
    </div>
  </div>

  <div class="col-12 pl-0 pr-0">
    <p-table [value]="participants | keyvalue | nameSort: personNameMap"
             responsiveLayout="block"
             breakpoint="200px">
      <ng-template pTemplate="caption">
        <div class="grid p-fluid">
          <div class="col-6">
            <p-autoComplete
              field="name"
              appendTo="body"
              type="search"
              (onSelect)="addPerson($event)"
              dropdown="true"
              placeholder="{{'EVENT.ADD_PERSON' | translate}}"
              [suggestions]="personsSuggestions | removeExistingFilter: participants"
              (completeMethod)="personSearch($event)">
            </p-autoComplete>
          </div>
          <div class="col-6">
            <p-autoComplete
              field="name"
              appendTo="body"
              (onSelect)="addTeam($event)"
              dropdown="true"
              type="search"
              placeholder="{{'BUTTON.ADD_TEAM' | translate}}"
              [suggestions]="teamsSuggestions"
              (completeMethod)="teamSearch($event)">
            </p-autoComplete>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>{{'COMMON.NAME' | translate}}</th>
          <th>{{'ARCHIVE.MOBILE' | translate}}</th>
          <th>{{'PERSONAL.EMAIL' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-participant>
        <tr (click)="remove(participant.key)">
          <td>{{(personNameMap.get(participant.key))}}</td>
          <td>{{(participant.value?.privateCell) ? (participant.value?.privateCell) : '-' }}</td>
          <td>{{(participant.value?.email) ? (participant.value?.email) : '-' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="col-12 flex justify-content-end">
    <button
      pButton
      class="p-button-raised"
      label="{{'BUTTON.SAVE' | translate}}"
      icon="pi pi-bookmark"
      (click)="save()"
    ></button>
  </div>
</div>
