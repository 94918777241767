<div class="grid">
  <div class="col-12">
    <p-table #ct
             [value]="competencies | competencyPersonFilter: selectedSubject : parentChildMap : loading"
             sortMode="single"
             breakpoint="200px"
             [globalFilterFields]="['person','competencyType']"
             sortField="status"
             [rows]="13" [showCurrentPageReport]="true" [loading]="loading" responsiveLayout="scroll"
             [paginator]="true" currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'MAIN_FRAME.COMPETENCIES_LOWERCASE' | translate}}"
    >
      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="field col-12 sm:col-6 lg:col-4 xl:col-3 mb-0">
            <input
                    pInputText
                    type="text"
                    class="inputfield w-full"
                    [(ngModel)]="search"
                    (ngModelChange)="filter(ct)"
                    showClear
                    placeholder="{{'COMMON.ADVANCED_SEARCH' | translate}}"
            />
          </div>

          <div class="field col-12 sm:col-6 lg:col-4 xl:col-3 mb-0">
            <p-dropdown [options]="groupedFilter"
                        [(ngModel)]="selectedSubject"
                        placeholder="{{'BUTTON.CHOOSE_GROUP' | translate}}"
                        [showClear]="isAdmin"
                        styleClass="width-100"
            >
              <ng-template let-subject pTemplate="item">
                <div class="subject-item">
                  <div *ngIf="subject.type === 'FIRESTATION'"><b>{{subject.label}}</b></div>
                  <div *ngIf="subject.type === 'FIRETEAM'">&nbsp;{{subject.label}}</div>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>

        <div class="col-12 flex justify-content-end pr-0">
          <button pButton type="button" class="p-button-raised" label="{{'TABLE.RESET_SORT' | translate}}"
                  (click)="resetSort(ct)"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 45%" pSortableColumn="competencyType">
            {{'COMPETENCY.COMPETENCY' | translate}}<p-sortIcon field="competencyType"></p-sortIcon>
          </th>
          <th pSortableColumn="person">
            {{'COMMON.NAME' | translate}}<p-sortIcon field="person"></p-sortIcon>
          </th>
          <th pSortableColumn="validDate">
            {{'COMMON.DATE.DATE' | translate}}<p-sortIcon field="validDate"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-competency>
        <tr [ngStyle]="setStatus(competency.status)" (click)="openHistory(competency)">
          <td>{{competency.competencyType}}</td>
          <td>{{competency.person}}</td>
          <td>{{newDate(competency.validDate)}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="3">{{'EVENT.NO_RESULT' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
