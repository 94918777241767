import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';


// AngularFire
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';

import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgxUiLoaderConfig, NgxUiLoaderModule} from "ngx-ui-loader";
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuard} from "./auth.guard";
import {LoginComponent} from "./components/login/login.component";
import {MenubarComponent} from "./components/menubar/menubar.component";
import {DashboardModule} from "./modules/dashboard/dashboard.module";
import {MainFrameComponent} from './components/main-frame/main-frame.component';
import {MenubarModule} from "primeng/menubar";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {PasswordModule} from "primeng/password";
import {AutoCompleteModule} from "primeng/autocomplete";
import {PanelMenuModule} from "primeng/panelmenu";
import {ServicesModule} from "./services/services.module";
import {ToastModule} from "primeng/toast";
import {BadgeModule} from "primeng/badge";
import {CarouselModule} from "primeng/carousel";
import {SidebarModule} from "primeng/sidebar";
import {ChecklistModule} from "./modules/checklist/checklist.module";
import {DeviationModule} from "./modules/deviation/deviation.module";
import {RippleModule} from "primeng/ripple";
import {CompetencyModule} from "./modules/competency/competency.module";
import {RouterModule} from "@angular/router";
import {AngularFireFunctionsModule, REGION} from "@angular/fire/compat/functions";
import {SmsModule} from './modules/sms/sms.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import {TenantService} from "./services/service/tenant.service";
import {Subscription} from "rxjs";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#00ACC1",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 11,
  "fgsColor": "#e84d14",
  "fgsPosition": "center-center",
  "fgsSize": 100,
  "fgsType": "three-strings",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 200,
  "logoUrl": "./assets/icons/icon-512x512.png",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgb(255,255,255)",
  "pbColor": "#e84d14",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
};


// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     swipe: {direction: Hammer.DIRECTION_HORIZONTAL}
//   }
//
// }
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  listPlugin,

]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MenubarComponent,
    MainFrameComponent,
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    AngularFireModule.initializeApp(environment),
    AngularFireFunctionsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nb',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServicesModule,
    DashboardModule,
    MenubarModule,
    DialogModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    PasswordModule,
    AutoCompleteModule,
    PanelMenuModule,
    ToastModule,
    BadgeModule,
    HammerModule,
    CarouselModule,
    SidebarModule,
    ChecklistModule,
    DeviationModule,
    RippleModule,
    CompetencyModule,
    RouterModule,
    SmsModule,
    FullCalendarModule
  ],
  providers: [
    AuthGuard,
    { provide: REGION, useValue: 'europe-west1'}
    // {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  subscription = new Subscription();
  constructor(private tenantService: TenantService,
              private translateService: TranslateService) {
    this.subscription.add(this.tenantService.getDefaultLang().subscribe(value => {
      if (value === null){
        value = "nb";
      }
      this.translateService.use(value);
    }));
  }
}

