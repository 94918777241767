import { Component, OnInit } from '@angular/core';
import {DialogService} from "primeng/dynamicdialog";
import {AddThemeComponent} from "../add-theme/add-theme.component";
import {TranslateService} from "@ngx-translate/core";
import {EditThemeComponent} from "../edit-theme/edit-theme.component";
import {ExerciseService} from "../../../services/service/exercise.service";
import {Theme} from "../../../model/theme";

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss'],
  providers: [DialogService]
})
export class ThemesComponent implements OnInit {
  loading = true;
  themes: Theme[];

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private exerciseService: ExerciseService,
  ) {
    this.exerciseService.getThemes().subscribe(themes => {
      this.themes = [];
      this.themes = themes.map(theme => {
        return {key: theme.key, ...theme.payload.val()}
      });
      this.loading = false;
    })
  }

  ngOnInit(): void {}

  addTheme() {
    console.log("here")
    this.dialogService.open(AddThemeComponent, {
      header: this.translateService.instant('EXERCISE.ADD_THEME'),
      styleClass: 'max-size-width-dialog',
    })
  }
  editTheme(theme) {
    this.dialogService.open(EditThemeComponent, {
      header: this.translateService.instant('BUTTON.EDIT') + ': ' + theme.name,
      styleClass: 'max-size-width-dialog',
      data: {
        key: theme.key
      }
    })
  }
}
