<div class="grid">
  <ng-container *ngIf="(userStatus.get('admin') || userStatus.get('superUser')) && !fromInfo; else showEFTable1">
    <div class="col-6">
      <button type="button" pButton class="p-button-raised" icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
              (click)="menu.toggle($event)"></button>
      <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
    </div>
    <ng-container *ngIf="selectedAction === 'addFiles'">
      <div class="col-6 flex justify-content-end">
        <button pButton (click)="addSelectedFiles()" type="button" icon="pi pi-plus"
                class="p-button-rounded p-button-raised" [disabled]="selectedFiles.length === 0"></button>
      </div>
    </ng-container>
    <ng-container  *ngIf="selectedAction === 'addFiles'; else showEFTable2">
        <!-- MOCK HEADER -->
        <div class="col-12 flex justify-content-center" style="background-color: #f1f2f3">
          <p-selectButton [options]="fileOptions"
                          [(ngModel)]="fileSelect"
                          optionLabel="name"
                          optionValue="value" >
          </p-selectButton>
        </div>
        <div class="col-12">
          <ng-container *ngIf="fileSelect === 'flexit'">
            <p-tree [value]="fileTree[2]"
                    selectionMode="checkbox" [filter]="true" loading
                    filterPlaceholder="{{'COMMON.SEARCH' | translate}}" (onNodeSelect)="nodeSelect($event)"
                    (onNodeUnselect)="nodeUnselect($event)">
            </p-tree>
          </ng-container>
          <ng-container *ngIf="fileSelect === 'internal'">
            <p-tree [value]="fileTree[0]"
                    selectionMode="checkbox" [filter]="true" loading
                    filterPlaceholder="{{'COMMON.SEARCH' | translate}}" (onNodeSelect)="nodeSelect($event)"
                    (onNodeUnselect)="nodeUnselect($event)">
            </p-tree>
          </ng-container>
        </div>

    </ng-container>

    <ng-template #showEFTable2>
      <div class="col-12">
        <p-table [value]="exerciseFiles" dataKey="key" responsiveLayout="scroll" [loading]="loading">
          <ng-template pTemplate="header">
            <tr>
              <th>{{'FILE.ATTACHED_FILES' | translate}}</th>
              <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-element>
            <tr>
              <td (click)="openFile(element)">
                  {{element.file.name}}
              </td>
              <td (click)="deleteFile(element)">
                  <span class="flex justify-content-center">
                    <i class="pi pi-trash" style="color: #D0021B"></i>
                  </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2">{{'FILE.NO_FILES' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #showEFTable1>
    <div class="col-12">
      <p-table [value]="exerciseFiles" dataKey="key" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th>{{'FILE.ATTACHED_FILES' | translate}}</th>
            <th style="width: 50px"><i class="pi pi-trash" style="color: #D0021B"></i></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-element>
          <tr>
            <td (click)="openFile(element.file)">
                {{element.file.name}}
            </td>
            <td (click)="deleteFile(element.file)">
              <span class="flex justify-content-center">
                <i class="pi pi-trash" style="color: #D0021B"></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">{{'FILE.NO_FILES' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</div>
