import {Component, OnDestroy, OnInit} from '@angular/core';
import {CalendarOptions} from '@fullcalendar/angular';
import {TranslateService} from '@ngx-translate/core';
import {TenantService} from '../../../services/service/tenant.service';
import {combineLatest, Subscription} from 'rxjs';
import {CalendarService} from '../../../services/service/calendar.service';
import * as moment from 'moment';
import {DialogService} from 'primeng/dynamicdialog';
import {ViewEventComponent} from '../view-event/view-event.component';
import {ExerciseService} from '../../../services/service/exercise.service';
import {TaskService} from '../../../services/service/task.service';
import {AppointmentComponent} from '../appointment/appointment.component';
import {MenuItem} from 'primeng/api';
import {EditExerciseComponent} from '../../exercise/edit-exercise/edit-exercise.component';
import {AddTaskComponent} from '../../task/add-task/add-task.component';
import {AppointmentTypesComponent} from '../appointment-types/appointment-types.component';
import {AppointmentService} from '../../../services/service/appointment.service';
import {AccessControlService} from '../../../services/service/access-control.service';

@Component({
    selector: 'app-calender',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [TranslateService, DialogService]
})
export class CalendarComponent implements OnInit, OnDestroy{

    subscription = new Subscription();
    defaultLang: string;

    calendarOptions: CalendarOptions;

    eventMap = new Map<string, Map<string, object>>();

    loading = true;

    windowHeight: number;
    colorMap = new Map<string, string>();
    menuItems: MenuItem[];

    constructor(
        private translateService: TranslateService,
        private tenantService: TenantService,
        private exerciseService: ExerciseService,
        private taskService: TaskService,
        private calendarService: CalendarService,
        private dialogService: DialogService,
        private acService: AccessControlService,
        private appointmentService: AppointmentService,
    ) {
        this.subscription.add(combineLatest(
                [
                    this.tenantService.getDefaultLang(),
                    this.calendarService.getCalendarEvents(),
                    this.taskService.getTasks(),
                    this.exerciseService.getActiveExercises(),
                    this.appointmentService.getOldAppointments(),
                    this.appointmentService.getAppointments(),
                ]).subscribe(([defaultLang, cEvents, tasks, exercises, oldAppointments, appointments]) => {
                const taskMap = new Map();
                const exerciseMap = new Map();
                const appointmentMap = new Map();
                tasks.forEach(task => {
                    taskMap.set(task.key, task.payload.val());
                });
                exercises.forEach(exercise => {
                    exerciseMap.set(exercise.key, exercise.payload.val());
                });
                // TODO Retire 2023
                oldAppointments.forEach(appointment => {
                    appointmentMap.set(appointment.key, appointment.payload.val());
                });
                appointments.forEach(appointment => {
                    appointmentMap.set(appointment.key, appointment.payload.val());
                });
                this.eventMap.set('EXERCISE', exerciseMap);
                this.eventMap.set('TASK', taskMap);
                this.eventMap.set('EVENT', appointmentMap);


                const temp = [];
                cEvents.forEach(e => {
                    if (!e.completed) {
                        const event = e.payload.val();
                        const tempStart = moment(event.start);
                        const tempEnd = moment(event.end);

                        if (!event.calendarType){
                            if (event.taskId) {
                                event.calendarType = "TASK";
                            }else if (event.exerciseId) {
                                event.calendarType = "EXERCISE";
                            } else {
                                event.calendarType = "EVENT";
                            }
                        }

                        const cE = {
                            id: e.key,
                            allDay: false,
                            start: tempStart.toISOString(true),
                            end: tempEnd.toISOString(true),
                            title: event.title,
                            color: this.colorMap.get(event.calendarType),
                            description: event.description,
                            extendedProps: {
                                event: this.eventMap.get(event.calendarType).get(e.key),
                                cT: event.calendarType
                            },
                        };
                        if (moment(event.start).format('YYYY-MM-DD') !== moment(event.end).format('YYYY-MM-DD')) {
                            cE.allDay = true;
                        }
                        if (!event.completed) {
                            temp.push(cE);
                        }
                    }
                });
                if (defaultLang) {
                    this.defaultLang = <string> defaultLang;
                } else {
                    this.defaultLang = 'nb';
                }
                this.calendarOptions = {
                    locale: this.defaultLang,
                    timeZone: 'UTC+1',
                    editable: true,
                    selectable:true,
                    selectMirror: true,
                    dayMaxEvents: true,
                    allDayText: this.translateService.instant('EVENT.ALL_DAY'),
                    buttonText: {
                        today: this.translateService.instant('SHIFT_REPORT.TODAY'),
                        month: this.translateService.instant('COMMON.MONTH'),
                        week: this.translateService.instant('COMMON.WEEK'),
                        day: this.translateService.instant('COMMON.DAY'),
                    },
                    events: temp,
                    eventClick: function(info, ) {
                        dialogService.open(ViewEventComponent,{
                            header: info.event.extendedProps.event.name,
                            styleClass: 'max-width-dialog',
                            data: {
                                type: info.event.extendedProps.cT,
                                eventId: info.event.id,
                                event: info.event.extendedProps.event
                            }
                        });
                    }

                };
                if (window.innerWidth < 700 || window.innerHeight < 500){
                    this.calendarOptions.initialView = 'timeGridDay';
                    this.calendarOptions.headerToolbar = {
                        left: 'prev,next,today',
                        center: '',
                        right: 'title'
                    };
                    this.calendarOptions.contentHeight = (window.innerHeight - 250) + 'px';
                } else {
                    this.calendarOptions.initialView = 'dayGridMonth';
                    this.calendarOptions.headerToolbar = {
                        left: 'prev,next,today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    };
                    this.calendarOptions.contentHeight = (window.innerHeight - 250) + 'px';
                }

                this.loading = false;
            })

        );
    }


    ngOnInit(): void {
        this.menuItems = [
            {   label: this.translateService.instant('BUTTON.NEW_EXERCISE'),
                icon: 'pi pi-plus',
                command: () => {
                    this.newExercise();
                }
            },
            {   label: this.translateService.instant('BUTTON.NEW_TASK'),
                icon: 'pi pi-plus',
                command: () => {
                    this.newTask();
                }
            },
            {   label: this.translateService.instant('BUTTON.NEW_EVENT'),
                icon: 'pi pi-plus',
                command: () => {
                    this.newAppointment();
                }
            },
            {   label: this.translateService.instant('EVENT.MISC_TYPES'),
                icon: 'pi pi-cog',
                command: () => {
                    this.editAppointmentTypes();
                }
            },
        ];
        this.windowHeight = window.innerHeight;
        this.colorMap.set('EXERCISE', 'blue');
        this.colorMap.set('TASK', 'purple');
        this.colorMap.set('EVENT', 'green');
        // this.colorMap.set('ON-DUTY', 'green');
        // this.colorMap.set('INCIDENT', 'red');
    }
    /**
     * Add new appointment
     */
    newAppointment() {
        this.dialogService.open(AppointmentComponent, {
            header: this.translateService.instant('BUTTON.NEW_EVENT'),
            styleClass: 'max-size-width-dialog',
        });
    }
    /**
     * Edit appointment types
     */
    editAppointmentTypes() {
        this.dialogService.open(AppointmentTypesComponent, {
            header: this.translateService.instant('BUTTON.NEW_EVENT'),
            width: "300px"
        });
    }
    /**
     * Add new appointment
     */
    newExercise() {
        this.dialogService.open(EditExerciseComponent, {
            header: this.translateService.instant('BUTTON.NEW_EXERCISE'),
            styleClass: 'max-size-width-dialog',
            data: {
                key: null,
                exercise: null,
            }
        });
    }
    /**
     * Add new appointment
     */
    newTask() {
        this.dialogService.open(AddTaskComponent, {
            header: this.translateService.instant('BUTTON.NEW_TASK'),
            styleClass: 'max-size-width-dialog',
        });
    }
    /**
     * Unsubscribe on close.
     */
    ngOnDestroy(): void{
        this.subscription.unsubscribe();
    }
}
