import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { NewLoginComponent } from './new-login/new-login.component';
import { TempLoginComponent } from './temp-login/temp-login.component';
import { LoginListComponent } from './login-list/login-list.component';
import {Route} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {RippleModule} from "primeng/ripple";
import {ButtonModule} from "primeng/button";
import {TableModule} from "primeng/table";
import { AddRoleDialogComponent } from './add-role-dialog/add-role-dialog.component';
import {ToastModule} from "primeng/toast";
import {TooltipModule} from "primeng/tooltip";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {CardModule} from "primeng/card";

export const AdminRoutes: Route = {
  path: 'admin', component: AdminComponent
};

@NgModule({
    declarations: [
        AdminComponent,
        NewLoginComponent,
        TempLoginComponent,
        LoginListComponent,
        AddRoleDialogComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        DropdownModule,
        FormsModule,
        AutoCompleteModule,
        InputTextModule,
        PasswordModule,
        DividerModule,
        RippleModule,
        ButtonModule,
        TableModule,
        ToastModule,
        TooltipModule,
        ConfirmDialogModule,
        CardModule
    ]
})
export class AdminModule { }
