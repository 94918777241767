<div class="grid" style="height: 100vh; width: 100vw" id="background">

  <!-- LOGIN MENUBAR -->
  <p-menubar styleClass="transparent" [model]="menuItems"></p-menubar>

  <!-- LOGO -->
  <img src="assets/icons/icon-192x192.png" class="logo" alt="">

  <!-- RESET EMAIL DIALOG -->
  <p-dialog header="{{'LOGIN.PASSWORD_RESET' | translate}}" [(visible)]="display" [modal]="true" [style]="{width: '350px'}" [baseZIndex]="10000"
            [draggable]="false" [resizable]="false">

    <div class="grid">
      <div class="col">
        <input style="width: 100%" id="resetEmail" autocomplete="off" type="email" pInputText placeholder="{{'PERSONAL.EMAIL' | translate}}" [(ngModel)]="resetEmail" />
      </div>
    </div>

    <div class="grid mt-1">
      <div class="col">
        <p-button styleClass="float-right primaryButton" (click)="resetPassword(resetEmail)" label="{{'BUTTON.SEND' | translate}}"></p-button>
      </div>
    </div>
  </p-dialog>

  <!-- LOGIN FORM -->
  <form id="login" #loginRef="ngForm" (ngSubmit)="login(loginRef.value)">

    <div class="grid">
      <div class="col">
        <input style="width: 100%" id="email"  autocomplete="email" name="email" type="email" pInputText placeholder="{{'PERSONAL.EMAIL' | translate}}" [(ngModel)]="email" />
      </div>
    </div>

    <div class="grid mt-1">
      <div class="col">
        <input style="width: 100%" id="password" autocomplete="current-password" name="password" type="password" placeholder="{{'PERSONAL.PASSWORD' | translate}}" pPassword [feedback]="false" [(ngModel)]="password"/>
      </div>
    </div>

    <div class="grid mt-1">
      <div class="col">
        <em>
          <p-button label="{{'LOGIN.FORGOT_PASSWORD' | translate}}" (onClick)="showDialog(true)" styleClass="p-button-link float-right"></p-button>
        </em>
      </div>
    </div>

    <div class="grid mt-1">
      <div class="col-12">
        <button style="width: 100%" pButton pRipple type="submit" label="{{'LOGIN.SIGN_IN' | translate}}" class="p-button-rounded"></button>

      </div>
    </div>
  </form>
</div>

<!-- ERROR TOAST -->
<p-toast key='login' position="top-right" styleClass="customToast"></p-toast>
