import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stationFilter'
})
export class StationFilterPipe implements PipeTransform {

  transform(output, stationMap){
    const copyOutput = JSON.parse(JSON.stringify(output));
    const filteredOutput = [];
    copyOutput.forEach(station => {
      if(station.items){
        station.items = station.items.filter(team => !stationMap.has(team.value));
        if(station.items.length > 0){
          filteredOutput.push(station);
        }
      }
    });
    return filteredOutput;
  }

}
