import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'deviationFilter'
})
export class DeviationFilterPipe implements PipeTransform {

  transform(deviations, search?: string): any {
    if (!search){
      return deviations;
    } else {
      return deviations.filter(deviation => deviation.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
