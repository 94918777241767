<!--SMALL VIEW MAX WIDTH 1920px -->
<div class="grid" *ngIf="!bigView">
  <p-sidebar
    [(visible)]="isExpanding"
    [modal]="true"

    styleClass="p-sidebar-sm"
  >
    <div class="grid-nogutter">
      <div class="col-12">
        <h2 class="text-center" style="color: white">{{tenantName}}</h2>
      </div>

<!--      &lt;!&ndash; Search for Person &ndash;&gt;-->
<!--      <div class="col-12" style="margin-top: 10px">-->
<!--        <p-autoComplete-->
<!--          [(ngModel)]="selectedPerson"-->
<!--          placeholder="{{'COMMON.SEARCH_PERSON' | translate}}"-->
<!--          [suggestions]="filteredPersons"-->
<!--          (completeMethod)="searchPerson($event)"-->
<!--          [minLength]="1"-->
<!--          [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"-->
<!--        >-->
<!--        </p-autoComplete>-->
<!--      </div>-->

      <!-- SideMenu -->
      <div class="col-12" style="margin-top: 10px">
        <p-panelMenu [model]="sideMenu | async" [multiple]="false" [style]="{'width': '100%'}"></p-panelMenu>
      </div>
    </div>
  </p-sidebar>

  <app-menubar class="menubar" (toggle)="toggleSideBar()"></app-menubar>

  <div class="col-12">
    <div class="outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- BIG VIEW OVER 1920px -->
<div class="grid" *ngIf="bigView">
  <div class="col-fixed shadow-2" style="width: 320px; height: 100vh; padding: 0">
    <p-sidebar
      [(visible)]="display"
      [modal]="false"
      [showCloseIcon]="false"
      [closeOnEscape]="false"
      [dismissible]="false"
      class="shadow-1"
      styleClass="p-sidebar-sm">

      <div class="grid-nogutter">
        <div class="col-12">
          <h2 class="text-center" style="color: white">{{tenantName}}</h2>
        </div>
<!--        &lt;!&ndash; Search for Person &ndash;&gt;-->
<!--        <div class="col-12">-->
<!--          <p-autoComplete-->
<!--            [(ngModel)]="selectedPerson"-->
<!--            placeholder="{{'COMMON.SEARCH_PERSON' | translate}}"-->
<!--            [suggestions]="filteredPersons"-->
<!--            (completeMethod)="searchPerson($event)"-->
<!--            [minLength]="1"-->
<!--            [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}"-->
<!--          >-->
<!--          </p-autoComplete>-->
<!--        </div>-->

        <!-- SideMenu -->
        <div class="col-12" style="padding: 2px 0 0;">
          <p-panelMenu [model]="sideMenu | async" [multiple]="false"></p-panelMenu>
        </div>
      </div>
    </p-sidebar>
  </div>

  <div class="col pl-0">
    <app-menubar class="menubar"></app-menubar>
    <div class="outlet pl-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>


