import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ReportAddComponent} from '../report-add/report-add.component';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Subscription} from 'rxjs';
import {ShiftReportService} from '../../../services/service/shift-report.service';
import {SubjectService} from '../../../services/service/subject.service';
import {ShiftReport} from '../../../model/shiftReport';
import * as moment from 'moment';
import {PersonService} from '../../../services/service/person.service';
import {RolesService} from '../../../services/service/roles.service';
import {EmergencyService} from '../../../services/service/emergency.service';
import {EmergencyResponse} from '../../../model/emergencyResponse';
import {EmergencyEval} from '../../../model/emergencyEval';
import {EmergencyReadComponent} from '../../events/emergency-read/emergency-read.component';
import {ExposureService} from '../../../services/service/exposure.service';
import {EvaluationService} from '../../../services/service/evaluation.service';
import {Exposure} from '../../../model/exposure';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss'],
  providers: [DialogService]
})
export class ReportViewComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  shiftReports: any[];
  shiftReportMap: Map<string, Map<number, Map<number, ShiftReport[]>>>; // Map< Station, Map< Year, Map< Month, ShiftReport[]>>>
  srSubjectsMap: Map<string, [{key$: string, name: string, role: string, leader: boolean}]>;

  stateOptions: any[];
  activeState;
  months: any[];
  symMap: Map<string, Map<number, any[]>>;
  years: number[];
  stations: object[];

  stationNameMap: Map<string, string>;
  selStation: string;
  selYear = Number(moment().format("YYYY"));
  selMonth = Number(moment().format("M"));
  windowWidth: number;
  roleTypes: Map<string, string>;
  ownStationKey: string;

  emergencyMap: Map<string, EmergencyResponse[]>;
  relevantEventsMap: Map<string, EmergencyResponse[]> = new Map();
  relevantEvents: EmergencyResponse[];
  emergencyId = new Map();

  subjectStation: Map<string, string> = new Map();

  evaluations: EmergencyEval[];
  exposures: Exposure[];

  loading = true;

  stationYearMonthMap = new Map<string, Map<number, number[]>>();
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private shService: ShiftReportService,
    private subjectService: SubjectService,
    private personService: PersonService,
    private roleService: RolesService,
    private emergencyService: EmergencyService,
    private exposureService: ExposureService,
    private evalService: EvaluationService,
  ) {
  }

  ngOnInit(): void {

    this.windowWidth = window.innerWidth;
    this.stateOptions = [
      {label: this.translateService.instant("COMMON.INFORMATION"), icon: 'pi pi-book'},
      {label: this.translateService.instant("SHIFT_REPORT.EVENTS"), icon: 'pi pi-exclamation-triangle'}
    ];
    this.activeState = this.stateOptions[0];

    this.load();
  }

  load(){
    const monthMap = new Map();
    monthMap.set(1, {name: this.translateService.instant("COMMON.MONTHS.JANUARY"), value: 1});
    monthMap.set(2, {name: this.translateService.instant("COMMON.MONTHS.FEBRUARY"), value: 2});
    monthMap.set(3, {name: this.translateService.instant("COMMON.MONTHS.MARCH"), value: 3});
    monthMap.set(4, {name: this.translateService.instant("COMMON.MONTHS.APRIL"), value: 4});
    monthMap.set(5, {name: this.translateService.instant("COMMON.MONTHS.MAY"), value: 5});
    monthMap.set(6, {name: this.translateService.instant("COMMON.MONTHS.JUNE"), value: 6});
    monthMap.set(7, {name: this.translateService.instant("COMMON.MONTHS.JULY"), value: 7});
    monthMap.set(8, {name: this.translateService.instant("COMMON.MONTHS.AUGUST"), value: 8});
    monthMap.set(9, {name: this.translateService.instant("COMMON.MONTHS.SEPTEMBER"), value: 9});
    monthMap.set(10, {name: this.translateService.instant("COMMON.MONTHS.OCTOBER"), value: 10});
    monthMap.set(11, {name: this.translateService.instant("COMMON.MONTHS.NOVEMBER"), value: 11});
    monthMap.set(12, {name: this.translateService.instant("COMMON.MONTHS.DECEMBER"), value: 12});

    this.subscription.add(combineLatest([this.shService.getAllShiftReports(), this.subjectService.getStations(),
      this.personService.getOwnStation(), this.roleService.getRoleTypes(), this.emergencyService.getEmergencies(),
      this.evalService.getEmergencyEvaluations(), this.exposureService.getExposures(), this.subjectService.getRelations()])
      .subscribe(([shReports, stations, ownStation, roleTypes, emergencies, evaluations, exposures, relations]) => {
        this.years = []; this.stations = []; this.emergencyMap = new Map();
        this.shiftReportMap = new Map(); this.symMap = new Map(); this.srSubjectsMap = new Map();

        this.roleTypes = new Map(roleTypes.map(type => [type.key, type.payload.val().name]));
        this.stationNameMap = new Map(stations.map(station => [station.key, station.payload.val().name]));

        const teamStation: Map<string, string> = new Map();
        relations.forEach(relation => {
            if (this.stationNameMap.has(relation.parentId)) {
              teamStation.set(relation.childId, relation.parentId);
            }
          }
        );

        relations.forEach(relation => {
            if (teamStation.has(relation.parentId)) {
              this.subjectStation.set(relation.childId, teamStation.get(relation.parentId));
            }
          }
        );

        emergencies.map(emergency => {
          const em = emergency.payload.val();
          em.key$ = emergency.key;

          if (this.emergencyMap.has(em.date)){
            this.emergencyMap.get(em.date).push(em);
            this.emergencyMap.get(em.date).sort((a, b) =>{
              const aDate = moment(a.date.split('.').reverse().join("-") + " " + a.timeStart);
              const bDate = moment(b.date.split('.').reverse().join("-") + " " + b.timeStart);
              return bDate.diff(aDate);
            });
          } else {
            this.emergencyMap.set(em.date, [em]);
          }
        });

        let counter = 1; const emergencyYear = new Map<string, boolean>();

        const e = emergencies.map(emergency => {
          return {key: emergency.key, ...emergency.payload.val()};
        }).sort((a, b) =>{
          const aDate = moment(a.date.split('.').reverse().join("-") + " " + a.timeStart);
          const bDate = moment(b.date.split('.').reverse().join("-") + " " + b.timeStart);
          return bDate.diff(aDate);
        });
        e.reverse().forEach(emergency => {
          const date = emergency.date.split('.');

          if (!emergencyYear.has(date[2])) {
            emergencyYear.set(date[2], true);
            const createdMap = new Map();
            this.emergencyId.set(date[2], createdMap.set(emergency.dateCreated, 1));
            counter = 1;
          }
          else {
            counter++;
            this.emergencyId.get(date[2]).set(emergency.dateCreated, counter);
          }
        });

        this.evaluations = evaluations.map(evaluation => {
          return {key: evaluation.key, ...evaluation.payload.val()};
        });

        this.exposures = exposures.map(exposure => {
          return {key: exposure.key, ...exposure.payload.val()};
        });

        shReports.forEach(station => {
          this.stations.push({value: station.key, name: this.stationNameMap.get(station.key)});
          this.shiftReportMap.set(station.key, new Map());
          this.symMap.set(station.key, new Map());
          this.stationYearMonthMap.set(station.key, new Map());
          const addedMonths = new Map<number, Map<number, boolean>>();
          Object.entries(station.payload.val()).map(date => {
            const year = Number(date[0].substring(4,8));
            let month;

            if (date[0].charAt(2) === "0"){
              month = Number(date[0].charAt(3));
            } else {
              month = Number(date[0].substring(2,4));
            }

            if(!this.years.includes(year)){
              this.years.push(year);
            }

            if (!addedMonths.has(year)){
              addedMonths.set(year, new Map());
            }

            if (this.stationYearMonthMap.get(station.key).has(year)){
              if (!this.stationYearMonthMap.get(station.key).get(year).includes(month)){
                this.stationYearMonthMap.get(station.key).get(year).push(month);
              }
            } else {
              this.stationYearMonthMap.get(station.key).set(year, [month]);
            }
            // console.log(this.stationYearMonthMap)

            const keys = Object.keys(date[1]);
            const srs = Object.values(date[1]);

            let index = 0;
            if(keys.length > 1){
              keys.forEach(key => {
                srs[index].key$ = key;
                if (srs[index].subjects){
                  Object.entries(srs[index].subjects).map(subject => {
                    let l = false;
                    if (subject[0] === srs[index].leader){
                      l = true;
                    }
                    const temp = <{key$: string, name: string, role: string}> subject[1];
                    if (this.srSubjectsMap.has(key)){
                      if (temp.role.length === 20 && temp.role.startsWith("-")){
                        this.srSubjectsMap.get(key).push(
                          {key$: subject[0], name: temp.name, role: this.roleTypes.get(temp.role), leader: l}
                        );
                      } else {
                        this.srSubjectsMap.get(key).push(
                          {key$: subject[0], name: temp.name, role: temp.role, leader: l}
                        );
                      }
                    } else {
                      if (temp.role.length === 20 && temp.role.startsWith("-")){
                        this.srSubjectsMap.set(key,
                          [
                            {key$: subject[0], name: temp.name, role: this.roleTypes.get(temp.role), leader: l}
                          ]
                        );
                      } else {
                        this.srSubjectsMap.set(key,
                          [
                            {key$: subject[0], name: temp.name, role: temp.role, leader: l}
                          ]
                        );
                      }
                    }
                  });
                }
                index++;
              });
            } else {
              srs[0].key$ = keys[0];
              if (srs[0].subjects) {
                Object.entries(srs[index].subjects).map(subject => {
                  let l = false;
                  if (subject[0] === srs[index].leader){
                    l = true;
                  }
                  const temp = <{key$: string, name: string, role: string}> subject[1];
                  if (this.srSubjectsMap.has(keys[0])){
                    if (temp.role.length === 20 && temp.role.startsWith("-")){
                      this.srSubjectsMap.get(keys[0]).push(
                        {key$: subject[0], name: temp.name, role: this.roleTypes.get(temp.role), leader: l}
                      );
                    } else {
                      this.srSubjectsMap.get(keys[0]).push(
                        {key$: subject[0], name: temp.name, role: temp.role, leader: l}
                      );
                    }
                  } else {
                    if (temp.role.length === 20 && temp.role.startsWith("-")){
                      this.srSubjectsMap.set(keys[0],
                        [
                          {key$: subject[0], name: temp.name, role: this.roleTypes.get(temp.role), leader: l}
                        ]
                      );
                    } else {
                      this.srSubjectsMap.set(keys[0],
                        [
                          {key$: subject[0], name: temp.name, role: temp.role, leader: l}
                        ]
                      );
                    }
                  }
                });
              }
            }
            if (this.shiftReportMap.get(station.key).has(year)){
              // Adding month objects to dropdown select
              if (!addedMonths.get(year).has(month)) {
                this.symMap.get(station.key).get(year).push(monthMap.get(month));
                this.symMap.get(station.key).get(year).sort((a, b) => {
                  const nameA = Object.values(a);
                  const nameB = Object.values(b);
                  if (nameA[1] < nameB[1]) {
                    return -1;
                  }
                  if (nameA[1] > nameB[1]) {
                    return 1;
                  }
                  return 0;
                });
                addedMonths.get(year).set(month, true);
              }

              if (this.shiftReportMap.get(station.key).get(year).has(month)){
                if (srs.length === 1) {
                  this.shiftReportMap.get(station.key).get(year).get(month).push(<ShiftReport> srs[0]);
                } else {
                  srs.forEach(sh => {
                    this.shiftReportMap.get(station.key).get(year).get(month).push(<ShiftReport> sh);
                  });
                }
              } else {
                this.shiftReportMap.get(station.key).get(year).set(month, srs);
              }
            } else {
              // this.stationYearMonthMap.set(station.key, new Map<number, number[]>());
              // this.stationYearMonthMap.get(station.key).set(year, []);
              this.shiftReportMap.get(station.key).set(year, new Map());
              this.symMap.get(station.key).set(year, []);
              if (this.shiftReportMap.get(station.key).get(year).has(month)){
                if (srs.length === 1) {
                  this.shiftReportMap.get(station.key).get(year).get(month).push(<ShiftReport> srs[0]);
                } else {
                  srs.forEach(sh => {
                    this.shiftReportMap.get(station.key).get(year).get(month).push(<ShiftReport> sh);
                  });
                }
              } else {
                // this.stationYearMonthMap.get(station.key).get(year).push(month);
                this.shiftReportMap.get(station.key).set(year, new Map());
                this.shiftReportMap.get(station.key).get(year).set(month, srs);
                this.selStation = station.key;

                // Adding month objects to dropdown select
                if (!addedMonths.get(year).has(month)) {
                  this.symMap.get(station.key).get(year).push(monthMap.get(month));
                  addedMonths.get(year).set(month, true);
                }
              }
            }
          });
        });

        this.selStation = ownStation.key;

        if (!this.stationYearMonthMap.get(this.selStation).has(this.selYear)){
          this.shiftReports = [];
        } else {
          const months = this.stationYearMonthMap.get(this.selStation).get(this.selYear).sort((a, b) => {
            if (a < b) {return 1;} if (a > b) {return -1;} });
          this.selMonth = months[0];

          this.shiftReports = this.shiftReportMap.get(this.selStation).get(this.selYear).get(this.selMonth);

          this.filterReports(this.selStation, this.selYear, this.selMonth);

          if(this.symMap.get(this.selStation).has(this.selYear)){
            this.months = this.symMap.get(this.selStation).get(this.selYear);
          } else {
            this.months = [];
          }

        }
        this.loading = false;
      }));
  }
  /**
   * Calculate duration of event
   * @param date Date of event
   * @param start Started at
   * @param end Ended at
   */
  calculateDuration(date: string, start: string, end: string){
    const dateSplit = date.split(".");
    date = dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit[0];
    const eventStart = moment(date + " " + start);
    let eventEnd = moment(date + " " + end);

    let diff = eventEnd.diff(eventStart, 'minutes');

    if (diff < 0){
      eventEnd =  eventEnd.add(1, 'days');
      diff = eventEnd.diff(eventStart, 'minutes');
    }

    if (diff > 60){
      const min = diff % 60;
      if (min !== 0){
        return String(eventEnd.diff(eventStart, 'hours')) + " h " + min + " min";
      }else {
        return String(eventEnd.diff(eventStart, 'hours')) + " h ";
      }
    }
    else {
      return String(eventEnd.diff(eventStart, 'minutes')) + " min";
    }

  }

  /**
   * Count number of participants
   * @param participants
   */
  numberOfCrews(participants: any){
    if (participants === undefined){
      return 0;
    }
    return participants.length;
  }

  /**
   * Get Event number
   * @param emergency The emergency response
   */
  getCounterId(emergency: EmergencyResponse) {
    const date = emergency.date.split('.');
    const list = this.emergencyId.get(date[2]);
    const YY = date[2].slice(-2);
    return list.get(emergency.dateCreated) + "/" + YY;
  }

  /**
   * More info on specific emergency
   * @param emergency Object
   */
  moreInfo(emergency: any){
    const date = emergency.date.split('.');

    let thisEvaluation: EmergencyEval;
    for (const e of this.evaluations) {
      if (e.emergencyId === emergency.key){
        thisEvaluation = e;
      }
    }
    this.dialogService.open(EmergencyReadComponent, {
      header: this.translateService.instant('EVENT.EMERGENCY') + ": "
        + this.emergencyId.get(date[2]).get(emergency.dateCreated) + "/" + date[2].slice(-2),
      styleClass: 'max-size-width-dialog',
      data: {
        emergency: emergency,
        exposures: this.exposures,
        evaluation: thisEvaluation
      }
    });
  }

  /**
   * Add new shiftReport
   */
  addShiftReport() {
    this.dialogService.open(ReportAddComponent, {
      header: this.translateService.instant('SHIFT_REPORT.NEW'),
      styleClass: 'max-size-width-dialog',
      data: {
        edit: false,
      }
    });
  }

  /**
   * Filter table content.
   * @param selStation  Station <fbid>
   * @param selYear     Year
   * @param selMonth    Month
   */
  filterReports(selStation: string, selYear: number, selMonth: number) {
    this.loading = true;

    if(this.symMap.get(selStation).has(selYear)){
      this.months = this.symMap.get(selStation).get(selYear);
      const months = this.stationYearMonthMap.get(selStation).get(selYear).sort((a, b) => {
        if (a < b) {return 1;} if (a > b) {return -1;} });
      if (!months.includes(selMonth)) {
        selMonth = months[0];
        this.selMonth = months[0];
      }
    } else {
      this.months = [];
    }

    if (this.months.length > 0) {

      this.shiftReports = this.shiftReportMap.get(selStation).get(selYear).get(selMonth)
        .sort((a,b) => {
          if (a.date > b.date){
            return -1;
          } else if (a.date < b.date){
            return 1;
          } else {
            if (a.timeStart > b.timeStart){
              return -1;
            } else if (a.timeStart < b.timeStart){
              return 1;
            } else {
              return 0;
            }
          }
        });

      this.shiftReports.forEach(report => {

        const from = report.date.split(".");
        let to;
        if (report.dateTo) {
          to = report.dateTo.split(".");
        } else {
          to = report.date.split(".");
        }
        const a = moment([from[2], from[1], from[0]]);
        const b = moment([to[2], to[1], to[0]]).add(1, 'd');
        const dateDifferance = b.diff(a, "days");
        for (let i = 0; i < dateDifferance; i++) {
          const dtString = from[2] + "-" + from[1] + "-" + from[0] + "T" + report.timeEnd;
          const date = moment(dtString).add(i, 'days').format('DD.MM.YYYY').toString();

          if (this.emergencyMap.has(date)) {
            this.emergencyMap.get(date).forEach(event => {

              let stationSubjects = 0;
              let otherStationSubjects = 0;
              if (event.participants) {
                event.participants.forEach(participant => {
                  if (this.subjectStation.get(participant.key) === selStation) {
                    stationSubjects++;
                  } else {
                    otherStationSubjects++;
                  }
                });
              }
              if (stationSubjects >= otherStationSubjects) {
                if (i === 0) {
                  if (this.emergencyMap.has(date)) {
                    if (this.relevantEventsMap.has(report.key$)) {
                      this.relevantEventsMap.get(report.key$).push(event);
                    } else {
                      this.relevantEventsMap.set(report.key$, [event]);
                    }
                  }
                } else if (i === dateDifferance) {
                  if (this.emergencyMap.has(date)) {

                    if (event.timeStart >= report.timeStart) {
                      if (this.relevantEventsMap.has(report.key$)) {
                        this.relevantEventsMap.get(report.key$).push(event);
                      } else {
                        this.relevantEventsMap.set(report.key$, [event]);
                      }
                    }
                  }
                } else {
                  if (this.relevantEventsMap.has(report.key$)) {
                    this.relevantEventsMap.get(report.key$).push(event);
                  } else {
                    this.relevantEventsMap.set(report.key$, [event]);
                  }
                }
              }
            });
          }
        }
      });
    } else {
      this.shiftReports = [];
    }
    this.loading = false;
  }

  /**
   * Set tab state.
   */
  setTabState(report) {
    this.getRelevant(report.key$);
    if (this.activeState === this.stateOptions[0]){
      this.activeState = this.stateOptions[1];
    } else {
      this.activeState = this.stateOptions[0];
    }
  }

  /**
   * Edit ShiftReport
   * @param report Object
   */
  editShiftReport(report: ShiftReport) {
    this.dialogService.open(ReportAddComponent, {
      header: this.translateService.instant('SHIFT_REPORT.NEW'),
      styleClass: 'max-size-width-dialog',
      data: {
        shiftReport: report,
        edit: true,
      }
    });
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Update report description
   * @param shiftReport Object
   */
  updateDescription(shiftReport: ShiftReport) {
    this.shService.updateDescription(
      shiftReport.station,
      shiftReport.date.replace(".", "").replace(".", ""),
      shiftReport.key$,
      shiftReport.description
    );
  }

  getRelevant(key) {
    this.relevantEvents = this.relevantEventsMap.get(key);
  }
}
