<div class="grid">
  <div class="col-12">
    <p-table [value]="themes" [globalFilterFields]="['name']" [sortField]="'name'" [sortOrder]="1" [loading]="loading" breakpoint="200px">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">{{'COMMON.NAME' | translate}}<p-sortIcon field="name"></p-sortIcon></th>
          <th style="width: 80px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-theme>
        <tr (click)="addCompetencyTypesFromTheme(theme)">
          <td>{{theme?.name}}</td>
          <td><i class="far fa-graduation-cap"></i>&nbsp;{{getLength(theme)}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'WARNING.NO_DATA' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
