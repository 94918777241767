import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SmsService} from '../../../services/service/sms.service';
import {SubjectService} from '../../../services/service/subject.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/service/auth.service';
import {take} from 'rxjs/operators';
import {PersonService} from '../../../services/service/person.service';
import {TreeNode} from 'primeng/api';
import {PersonSubject} from '../../../model/subject';
import {SmsHelperService} from '../../../services/sharedService/sms-helper.service';
import {PersonTreeService} from '../../../services/sharedService/person-tree.service';

@Component({
  selector: 'app-send-sms',
  templateUrl: './send-sms.component.html',
  styleUrls: ['./send-sms.component.scss'],
  providers: [DynamicDialogConfig, DynamicDialogRef]
})
export class SendSmsComponent implements OnInit, OnDestroy{

  crewTree: TreeNode[];

  subscription = new Subscription();
  defaultLang;

  selectedPersons: any[] = [];
  selected: number;
  subjects;

  availableSMS = 0;
  message = '';

  personMap = new Map();


  enabled: boolean;

  constructor(
      private config: DynamicDialogConfig,
      private ref: DynamicDialogRef,
      private smsService: SmsService,
      private subjectService: SubjectService,
      private personService: PersonService,
      private translateService: TranslateService,
      private authService: AuthService,
      private smsHelper: SmsHelperService,
      private pTreeService: PersonTreeService,
  ) {
    this.defaultLang = this.translateService.getDefaultLang();

    this.subscription.add(
        combineLatest([
          this.personService.getPersons(), this.subjectService.getTeams(), this.subjectService.getStations(),
          this.subjectService.getRelations(), this.smsService.getAvailableSMS()
        ]).subscribe(([persons, teams, stations, relations, availableSmS]) => {

          this.selectedPersons = [];
          this.crewTree = [];
          this.availableSMS = <number> availableSmS;
          this.personMap = new Map<string, PersonSubject>(persons.map(person => [person.key, person.payload.val()]));
          this.crewTree = this.pTreeService.getCrewTree(persons, teams, stations, relations);

        })
    );
  }

  ngOnInit(): void {
  }

  /**
   * Get number of sms
   * @param length Message length
   */
  numberOfSms(length: number){
    if (this.selectedPersons){
      return this.smsHelper.numberOfSms(length, this.selectedPersons.length);
    } else {
      return this.smsHelper.numberOfSms(length, 0);
    }
  }

  /**
   * Select recipients
   * @param $event Select event
   */
  nodeSelect($event: any) {
    if ($event.node.leaf) {

      this.selectedPersons.push(this.personMap.get($event.node.key));
    } else {
      const children = $event.node.children;
      children.forEach(child => {
        if (child.leaf) {
          this.selectedPersons.push(this.personMap.get(child.key));
        } else {
          child.children.forEach(grandChild => {
            this.selectedPersons.push(this.personMap.get(grandChild.key));
          });
        }
      });
    }
  }

  /**
   * Deselect recipients
   * @param $event Unselect event
   */
  nodeUnselect($event: any) {
    if ($event.node.leaf){
      const index = this.selectedPersons.indexOf(this.personMap.get($event.node.key));
      this.selectedPersons.splice(index, 1);
    } else {
      $event.node.children.forEach(child => {
        if (child.leaf) {
          const index = this.selectedPersons.indexOf(this.personMap.get(child.key));
          this.selectedPersons.splice(index, 1);
        } else {
          child.children.forEach(grandChild => {
            const index = this.selectedPersons.indexOf(this.personMap.get(grandChild.key));
            this.selectedPersons.splice(index, 1);
          });
        }
      });
    }
  }

  /**
   * Send sms(s)
   */
  send() {
    this.authService.getUserUID().pipe(take(1)).subscribe(uid => {
      this.smsHelper.send(uid, this.selectedPersons, this.message, this.numberOfSms(this.message.length), this.defaultLang);
    });
    this.ref.close();
  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
