<div class="grid">

  <div class="col-8 flex flex-wrap align-content-center">
    <h2 style="margin-top: 10px">{{'SUBJECTS.DOC_TYPES.EVENT' | translate}}</h2>
  </div>
  <ng-container *ngIf="isAdmin$; else user">
    <div class="col-4 flex justify-content-end">
        <button type="button" class="p-button-raised" pButton icon="pi pi-bars" label="{{'COMMON.MENU' | translate}}"
                (click)="menu.toggle($event)"></button>
        <p-menu #menu [popup]="true" [model]="items"></p-menu>
    </div>
  </ng-container>
  <ng-template #user>
    <div class="col-4 flex justify-content-end align-content-center">
      <button pButton (click)="addEmergency()" type="button" icon="pi pi-plus"
              class="p-button-rounded p-button-raised"></button>
    </div>
  </ng-template>

  <ng-container *ngIf="screenWidth(); else elseBlock">
    <div class="col-12 w-full">
      <span class="p-float-label">
        <input class="w-full" id="search" type="text" pInputText (input)="emergencyFilter(getEventValue($event))">
        <label for="search" class="breakDots">{{'EVENT.SEARCH.ERE' | translate}}</label>
      </span>
    </div>
    <div class="col-12 w-full">
      <p-dropdown (onChange)="setStationFilter()" [(ngModel)]="selectedStation" [options]="groupedFilter"
                  [style]="{'width':'100%'}" optionLabel="label" optionValue="value"></p-dropdown>
    </div>
  </ng-container>
  <ng-template #elseBlock>
    <div class="col-6">
      <span class="p-float-label">
        <input class="w-full" id="search2" type="text" pInputText (input)="emergencyFilter(getEventValue($event))">
        <label for="search2">{{'EVENT.SEARCH.ERE' | translate}}</label>
      </span>
    </div>
    <div class="col-6">
      <p-dropdown (onChange)="setStationFilter()" [(ngModel)]="selectedStation" [options]="groupedFilter"
                  [style]="{'width':'100%'}" optionLabel="label" optionValue="value"></p-dropdown>
    </div>
  </ng-template>


  <div *ngFor="let emergency of emergencies" class="col-12 sm:col-6 md:col-4 lg:col-3">
    <p-card styleClass="p-card-shadow box">
      <ng-template pTemplate="header">
        <div style="background-color: #16214D; min-height: 30px;"
             class="flex align-content-center flex-wrap justify-content-center banner">
          <div class="flex align-items-center" style="color: white">
            {{'EVENT.EMERGENCY' | translate}}: {{getCounterId(emergency)}}
          </div>
        </div>
      </ng-template>
      <div class="grid">
        <div class="col-offset-1 col-5 ">
          {{'EVENT.BRIS' | translate}}:
        </div>
        <div class="col-6" *ngIf="emergency.brisNumber">
          {{emergency.brisNumber}}
        </div>
        <div class="col-6" *ngIf="!emergency.brisNumber"></div>
        <div class="col-offset-1 col-5 ">
          {{'COMMON.DATE.DATE' | translate}}:
        </div>
        <div class="col-6">
          {{emergency.date}}
        </div>
        <div class="col-offset-1 col-5">
          {{'EVENT.TIME_PERIOD' | translate}}:
        </div>
        <div class="col-5">
          {{emergency.timeStart}} - {{emergency.timeEnd}}
        </div>
        <div class="col-offset-1 col-5">
          {{'FDC.ADDRESS' | translate}}: </div>
        <div class="col-6 surface-overlay text pb-0 breakWord align-content-center">
          {{emergency.location.address}}
        </div>
        <div class="col-offset-1 col-5">
          {{'EVENT.DURATION' | translate}}:
        </div>
        <div class="col-6">
          {{calculateDuration(emergency.date, emergency.timeStart, emergency.timeEnd)}}
        </div>
        <div class="col-offset-1 col-5">
          {{'EVENT.NUMBER_OF_CREWS' | translate}}:
        </div>
        <div class="col-6">
          {{numberOfCrews(emergency.participants)}}
        </div>
      </div>
      <ng-template pTemplate="footer">
        <ng-container *ngIf="isAdmin$ && expired(emergency.dateCreated, true) || isSuper$ &&
        expired(emergency.dateCreated, false); else read">
          <div class="flex justify-content-center" >
            <button pButton (click)="editInfo(emergency)" type="button" icon="pi pi-cog"
                    class="p-button-rounded" [disabled]="loading"></button>
          </div>
        </ng-container>

        <ng-template #read>
          <div class="flex justify-content-center" >
            <button pButton (click)="moreInfo(emergency)" type="button" icon="pi pi-book"
                    class="p-button-rounded"></button>
          </div>
        </ng-template>

      </ng-template>
    </p-card>
  </div>
</div>
