<!-- Toast -->
<p-toast key='person-detail' position="top-right" styleClass="customToast"></p-toast>
<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>
<div class="grid" style="height: 100%; overflow: auto">
  <div class="col-12">
    <p-splitButton styleClass="float-right" label="{{'BUTTON.SAVE' | translate}}" icon="pi pi-bookmark"
                   (onClick)="save()" [model]="splitButton" class="p-button-raised"></p-splitButton>
  </div>
  <!-- NAME -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="name">{{"COMMON.NAME" | translate}}</label>
      <input id="name" type="text" pInputText [(ngModel)]="subject.name" class="inputfield w-full">
    </div>
  </div>
  <ng-container *ngIf="projectType === 'Fire'">
    <!-- ID -->
    <div class="col-12 sm:col-6">
      <div class="field mb-0">
        <label for="userId">{{"SUBJECTS.E_NUM" | translate}}</label>
        <input id="userId" type="number" pInputText [(ngModel)]="subject.userId" class="inputfield w-full">
      </div>
    </div>
    <!-- ISSI -->
    <div class="col-12 sm:col-6">
      <div class="field mb-0">
        <label for="ISSI">{{"SUBJECTS.ISSI" | translate}}</label>
        <input id="ISSI" type="text" pInputText [(ngModel)]="subject.issi" class="inputfield w-full">
      </div>
    </div>
    <!-- main employer -->
    <div class="col-12 sm:col-6">
      <div class="field mb-0">
        <label for="mainEmployer">{{"SUBJECTS.MAIN_EMPLOYER" | translate}}</label>
        <select id="mainEmployer" class="w-full inputfield" style="appearance: auto" [(ngModel)]="subject.mainEmployer" pInputText>
          <option *ngFor="let station of stations" [value]="station.key">{{station.name}}</option>
        </select>
      </div>
    </div>
  </ng-container>
  <!-- Date of Birth -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="dobField">{{'SUBJECTS.DOB' | translate}}</label>
      <input id="dobField" type="date" [(ngModel)]="subject.dateOfBirth" pInputText class="inputfield w-full">
    </div>
  </div>
  <ng-container *ngIf="projectType === 'Fire'">
    <!-- Title -->
    <div class="col-12 sm:col-6">
      <div class="field mb-0">
        <label for="title">{{"COMMON.TITLE" | translate}}</label>
        <select id="title" class="w-full inputfield" style="appearance: auto" [(ngModel)]="subject.title" pInputText>
          <option *ngFor="let title of jobTitles" [value]="title.title">{{title.title}}</option>
        </select>
      </div>
    </div>
  </ng-container>
  <!-- Email-->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="email">{{"PERSONAL.EMAIL" | translate}}</label>
      <input type="email" id="email" pInputText [(ngModel)]="subject.email" class="inputfield w-full">
    </div>
  </div>
  <!-- Alt - Email-->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="alt-email">{{"SUBJECTS.ALT_EMAIL" | translate}}</label>
      <input type="email" id="alt-email" pInputText [(ngModel)]="subject.email2" class="inputfield w-full">
    </div>
  </div>
  <!--Private Cell -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="privateCell">{{"SUBJECTS.CELL_PRIVATE" | translate}}</label>
      <input type="text" id="privateCell" pInputText [(ngModel)]="subject.privateCell" class="inputfield w-full">
    </div>
  </div>
  <!--Work Cell -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="workCell">{{"SUBJECTS.WORK_PHONE" | translate}}</label>
      <input type="number" id="workCell" pInputText [(ngModel)]="subject.workCell" class="inputfield w-full">
    </div>
  </div>
  <!--Address -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="address">{{"SUBJECTS.ADDRESS" | translate}}</label>
      <input type="text" id="address" pInputText [(ngModel)]="subject.address" class="inputfield w-full">
    </div>
  </div>
  <!--Address2 -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="address2">{{"SUBJECTS.SEC_ADDRESS" | translate}}</label>
      <input type="text" id="address2" pInputText [(ngModel)]="subject.address2" class="inputfield w-full">
    </div>
  </div>
  <!--Postal -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="postal">{{"SUBJECTS.POSTAL" | translate}}</label>
      <input type="text" id="postal" pInputText [(ngModel)]="subject.postal" class="inputfield w-full">
    </div>
  </div>
  <!--ZIP -->
  <div class="col-12 sm:col-6">
    <div class="field mb-0">
      <label for="zip">{{"SUBJECTS.ZIP" | translate}}</label>
      <input type="number" id="zip" pInputText [(ngModel)]="subject.zip" class="inputfield w-full">
    </div>
  </div>
  <!-- NOTES -->
  <div class="col-12">
    <div class="field mb-0">
      <label for="notes">{{"COMMON.NOTES" | translate}}</label>
      <textarea type="text" rows="4" id="notes" pInputText [(ngModel)]="subject.notes"
                class="inputfield w-full"></textarea>
    </div>
  </div>
  <p-scrollTop class="ml-auto mr-3 mb-2" target="parent" [threshold]="50"></p-scrollTop>
</div>



