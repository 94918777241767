import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Route, RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {TabViewModule} from "primeng/tabview";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import { OngoingChecklistsComponent } from './ongoing-checklists/ongoing-checklists.component';
import { ChecklistTemplatesComponent } from './checklist-templates/checklist-templates.component';
import { ChecklistRepeatComponent } from './checklist-repeat/checklist-repeat.component';
import {TableModule} from "primeng/table";
import {PipesModule} from "../../pipes/pipes.module";
import {MomentModule} from "ngx-moment";
import {RippleModule} from "primeng/ripple";
import {TooltipModule} from "primeng/tooltip";
import { ChecklistDetailComponent } from './checklist-detail/checklist-detail.component';
import {PickListModule} from "primeng/picklist";
import {OrderListModule} from "primeng/orderlist";
import {TreeTableModule} from "primeng/treetable";
import {CheckboxModule} from "primeng/checkbox";
import {BadgeModule} from "primeng/badge";
import {SplitButtonModule} from "primeng/splitbutton";
import {BlockUIModule} from "primeng/blockui";
import {PanelModule} from "primeng/panel";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import {InputTextareaModule} from "primeng/inputtextarea";
import {DividerModule} from "primeng/divider";
import {ScrollPanelModule} from "primeng/scrollpanel";
import { AddNewItemComponent } from './add-new-item/add-new-item.component';
import {InputTextModule} from "primeng/inputtext";
import {ToastModule} from "primeng/toast";
import { EditItemComponent } from './edit-item/edit-item.component';
import {MenuModule} from "primeng/menu";
import {ScrollTopModule} from "primeng/scrolltop";
import { AddChecklistComponent } from './add-checklist/add-checklist.component';
import {InputSwitchModule} from "primeng/inputswitch";
import {RadioButtonModule} from "primeng/radiobutton";
import {InputNumberModule} from "primeng/inputnumber";
import { CompletedChecklistsComponent } from './completed-checklists/completed-checklists.component';
import {VirtualScrollerModule} from "primeng/virtualscroller";
import { NotFinishedChecklistsComponent } from './not-finished-checklists/not-finished-checklists.component';
import { DeviationsChecklistsComponent } from './deviations-checklists/deviations-checklists.component';
import { CompletedChecklistDetailComponent } from './completed-checklist-detail/completed-checklist-detail.component';
import {FieldsetModule} from "primeng/fieldset";
import {CardModule} from "primeng/card";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import { ChecklistRepeatDetailComponent } from './checklist-repeat-detail/checklist-repeat-detail.component';
import { ItemDeviationDetailComponent } from './item-deviation-detail/item-deviation-detail.component';
import { EditRepeatChecklistComponent } from './edit-repeat-checklist/edit-repeat-checklist.component';
import { ItemArchiveComponent } from './item-archive/item-archive.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import {AutoCompleteModule} from "primeng/autocomplete";
import { EditTemplateComponent } from './edit-template/edit-template.component';

export const ChecklistRoutes: Route = {
  path: 'checklists',
  children: [
    {path: 'active', component: OngoingChecklistsComponent},
    {path: 'repetitive', component: ChecklistRepeatComponent},
    {path: 'template', component: ChecklistTemplatesComponent},
    {path: 'completed', component: CompletedChecklistsComponent},
    {path: 'not-finished', component: NotFinishedChecklistsComponent},
    {path: 'deviations', component: DeviationsChecklistsComponent}
  ]
};

@NgModule({
    declarations: [
        OngoingChecklistsComponent,
        ChecklistTemplatesComponent,
        ChecklistRepeatComponent,
        ChecklistDetailComponent,
        CommentModalComponent,
        AddNewItemComponent,
        EditItemComponent,
        AddChecklistComponent,
        CompletedChecklistsComponent,
        NotFinishedChecklistsComponent,
        DeviationsChecklistsComponent,
        CompletedChecklistDetailComponent,
        ChecklistRepeatDetailComponent,
        ItemDeviationDetailComponent,
        EditRepeatChecklistComponent,
        ItemArchiveComponent,
        NotificationDialogComponent,
        EditTemplateComponent,
    ],
    exports: [
        OngoingChecklistsComponent,
        CommentModalComponent,
        AddNewItemComponent,
        EditItemComponent,
        AddChecklistComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        TabViewModule,
        DropdownModule,
        FormsModule,
        ButtonModule,
        TableModule,
        PipesModule,
        MomentModule,
        RippleModule,
        TooltipModule,
        PickListModule,
        OrderListModule,
        TreeTableModule,
        CheckboxModule,
        BadgeModule,
        RouterModule,
        SplitButtonModule,
        BlockUIModule,
        PanelModule,
        ConfirmDialogModule,
        InputTextareaModule,
        DividerModule,
        ScrollPanelModule,
        InputTextModule,
        ToastModule,
        MenuModule,
        ScrollTopModule,
        InputSwitchModule,
        RadioButtonModule,
        InputNumberModule,
        ReactiveFormsModule,
        VirtualScrollerModule,
        FieldsetModule,
        CardModule,
        TriStateCheckboxModule,
        AutoCompleteModule,
    ]
})
export class ChecklistModule { }
