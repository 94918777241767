<div class="formgrid grid">
    <!-- Large screen -->
    <ng-container *ngIf="windowWidth > 480; else smallScreenBlock">
        <!-- Task name -->
        <div class="field col-12 mt-3">
            <span class="p-float-label">
                <input id="name" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                       [ngModel]="task.name" disabled>
                <label for="name">{{'TASK.TASK_TITLE' | translate}}*</label>
            </span>
        </div>
        <!-- Asset/Station -->
        <div class="col-5 mt-3">
             <span class="p-float-label">
                <input id="asset" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                       [ngModel]="getAssetStation(task.assetId)" disabled>
                <label for="asset">{{'ASSET.ASSET' | translate}} {{'COMMON.OR' | translate}} {{'COMMON.STATION' |
                    translate}}</label>
            </span>
        </div>
        <!-- Date -->
        <div class="col-4 mt-3">
             <span class="p-float-label">
                <input id="date" type="text" pInputText disabled [ngModel]="getDate(task.deadline)"
                       [style]="{'width' : '100%', 'height' : '42px'}" >
                   <label for="date">{{'COMMON.DATE.DATE' | translate}}</label>
            </span>
        </div>
        <!-- Time -->
        <div class="field col-3 mt-3">
             <span class="p-float-label">
                <input id="time" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                       [ngModel]="getTime(task.deadline)" disabled>
                <label for="time">{{'COMMON.TIME' | translate}}</label>
            </span>
        </div>
        <!-- Description -->
        <div class="field col-12 mt-3">
                <span class="p-float-label">
                    <textarea id="textarea"
                              rows="4"
                              style="resize: none;"
                              cols="30"
                              pInputTextarea
                              disabled
                              class="inputfield w-full"
                              [ngModel]="task.description"></textarea>
                    <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}*</label>
                </span>
        </div>
        <!-- Person table -->
        <div class="col-12 flex">
            <p-table responsiveLayout="block"
                     breakpoint="200px"
                     [value]="selectedSubjects"
                     [(selection)]="selectedLeader"
            >
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                        <th>{{'FDC.PERSONS' | translate}}</th>
                    </tr>
                </ng-template>

                <!-- Body -->
                <ng-template pTemplate="body" let-personId >
                    <tr>
                        <td>
                            <p-checkbox [ngModel]="getLeader(personId)" [binary]="true" inputId="binary"
                                        disabled></p-checkbox>
                        </td>
                        <td>
                            {{personNameMap.get(personId)}}
                        </td>
                    </tr>
                </ng-template>

                <!-- Shows message if table is empty -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td></td>
                        <td colspan="1">
                            {{'WARNING.NO_DATA' | translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-container>

    <!-- Small screen -->
    <ng-template #smallScreenBlock>
        <!-- Task name -->
        <div class="field col-12 mt-3">
            <span class="p-float-label">
                <input id="nameS" type="text" pInputText
                       [style]="{'width' : '100%', 'height' : '42px'}"
                       disabled
                       [ngModel]="task.name">
                <label for="nameS">{{'TASK.TASK_TITLE' | translate}}*</label>
            </span>
        </div>
        <!-- Date -->
        <div class="field col-6  mt-3">
            <span class="p-float-label">
                <input id="dateS" type="text" pInputText disabled [ngModel]="getDate(task.deadline)"
                       [style]="{'width' : '100%', 'height' : '42px'}" >
                   <label for="dateS">{{'COMMON.DATE.DATE' | translate}}</label>
            </span>
        </div>
        <!-- Time -->
        <div class="field col-6 mt-3">
            <span class="p-float-label">
                <input id="timeS" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                       [ngModel]="getTime(task.deadline)" disabled>
                <label for="timeS">{{'COMMON.TIME' | translate}}</label>
            </span>
        </div>
        <!-- Asset/Station -->
        <div class="field col-12 mt-3">
           <span class="p-float-label">
                <input id="assetS" type="text" pInputText [style]="{'width' : '100%', 'height' : '42px'}"
                       [ngModel]="getAssetStation(task.assetId)" disabled>
                <label for="assetS">{{'ASSET.ASSET' | translate}} {{'COMMON.OR' | translate}} {{'COMMON.STATION' |
                    translate}}</label>
            </span>
        </div>
        <!-- Description -->
        <div class="field col-12 mt-3">
          <span class="p-float-label">
            <textarea id="textareaSmall"
                      rows="4"
                      style="resize: none;"
                      cols="30"
                      pInputTextarea
                      disabled
                      class="inputfield w-full"
                      [ngModel]="task.description"></textarea>
            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}*</label>
          </span>
        </div>
        <!-- Person table -->
        <div class="col-12 flex">
            <p-table responsiveLayout="block"
                     breakpoint="200px"
                     [value]="selectedSubjects"
                     [(selection)]="selectedLeader"
            >
                <!-- Header -->
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"> <i class="far fa-user"></i> </th>
                        <th>{{'FDC.PERSONS' | translate}}</th>
                    </tr>
                </ng-template>

                <!-- Body -->
                <ng-template pTemplate="body" let-personId >
                    <tr>
                        <td>
                            <p-checkbox [ngModel]="getLeader(personId)" [binary]="true" inputId="binary"
                                        disabled></p-checkbox>
                        </td>
                        <td>
                            {{personNameMap.get(personId)}}
                        </td>
                    </tr>
                </ng-template>

                <!-- Shows message if table is empty -->
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td ></td>
                        <td colspan="2">
                            {{'WARNING.NO_DATA' | translate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-template>
</div>
