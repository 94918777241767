<div class="grid">
    <div class="col-12">
        <h2 style="margin-top: 10px">{{'SMS.SEND' | translate}}</h2>
    </div>
    <div  class=" col-12">
        <p-tree class="w-full" [value]="crewTree" selectionMode="checkbox" [filter]="true" loading
                filterPlaceholder="{{'COMMON.SEARCH' | translate}}"
                (onNodeSelect)="nodeSelect($event)" (onNodeUnselect)="nodeUnselect($event)"></p-tree>
    </div>
    <div class="col-12 mt-2">
     <span class="p-float-label">
        <textarea class="w-full" id="float-input" rows="5" cols="30" pInputTextarea [(ngModel)]="message"></textarea>
        <label for="float-input">{{'EXERCISE.MESSAGE' | translate}}</label>
      </span>
    </div>
    <div class="row">
        <div class="col">
            <span>{{"EXERCISE.SMS_LENGTH" | translate}}: <b>{{message.length}}</b></span>
            <span style="margin-left: 20px">{{"EXERCISE.RECIPIENTS" | translate}}: <b>{{selectedPersons?.length}}</b></span>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <span>{{"EXERCISE.TOTAL_SMS" | translate}}: <b>{{numberOfSms(message?.length)}}</b></span>
            <span style="margin-left: 20px">{{"EXERCISE.REMAINING_SMS" |translate}}: <b>{{availableSMS}}</b></span>
        </div>
    </div>
    <div class="col-12 flex justify-content-end">
        <p-button label="{{'SMS.SEND' | translate}}"
                  icon="pi pi-check"
                  iconPos="right"
                  [disabled]="selectedPersons.length < 1 || message.length < 1 || availableSMS < selectedPersons.length"
                  (onClick)="send()"
        ></p-button>
    </div>
</div>
