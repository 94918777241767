import {Injectable} from '@angular/core';
import {TenantService} from "./tenant.service";
import {MenuItem} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {SideMenuConfig} from "../../model/sideMenuConfig";
import {combineLatest, Observable} from "rxjs";
import {AccessControlService} from "./access-control.service";
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

/**
 * This service handles the different alternatives of sidebar projects can have.
 */
export class MenuService{
  smConfig$: Observable<SideMenuConfig>;
  isRegional$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isSuperUser$: Observable<boolean>;
  isUser$: Observable<boolean>;
  isIUA$: Observable<boolean>;
  isFDC$: Observable<boolean>;
  isRNT$: Observable<boolean>;

  projectType: string;
  components: {roles: boolean, events: boolean, sharedFiles: boolean};

  constructor(
    private tenantService: TenantService,
    private translateService: TranslateService,
    private accessControlService: AccessControlService,
  ) {
    this.smConfig$ = this.tenantService.getSideMenuConfig();
    this.isRegional$ = this.tenantService.isRegional();
    this.isAdmin$ = this.accessControlService.isAdmin();
    this.isSuperUser$ = this.accessControlService.isSuperUser();
    this.isUser$ = this.accessControlService.isUser();
    this.isIUA$ = this.tenantService.isIUA();
    this.isFDC$ = this.tenantService.isFDC();
    this.isRNT$ = this.tenantService.isRNT();

    // Get project type
    this.tenantService.getProjectType().pipe(take(1)).subscribe(projectType => {
      this.projectType = projectType;
    });

    // Get components
    this.tenantService.getComponents().pipe(take(1)).subscribe(components => {
      this.components = components;
    });
  }


  /**
   * Build side menu based on userAccessLevel and status from tenant.
   * Since firebase is false by default we work with !status. Variable as the condition for including corresponding menu.
   */
  buildSideMenu(): Observable<MenuItem[]> {
    return combineLatest([
      this.smConfig$,
      this.isAdmin$,
      this.isSuperUser$,
      this.isUser$,
      this.isRegional$,
      this.isIUA$,
      this.isFDC$,
      this.isRNT$
    ]).pipe(
      map(([
             smConfig,
             isAdmin,
             isSuperUser,
             isUser,
             isRegional,
             isIUA,
             isFDC,
             isRNT,
           ]) => {
        if (isRegional) {
          // return this.buildRegional(isUser)
        } else if (isFDC) {
          // return this.buildFDC(isUser)
        } else {
          return this.buildRegular(smConfig, isUser, isAdmin, isSuperUser, isIUA, isRNT);
        }
      })
    );
  }

  /**
   * Builds the Regular SideMenu
   *
   * @param smConfig: An object of what menu items this projects is gonna show
   * @param isUser: check if AccessLevel is User
   * @param isAdmin: check if AccessLevel is Admin
   * @param isSuperUser: check if AccessLevel is Superuser
   * @param isIUA: check if project is IUA
   * @param isRNT: check if project is RNT
   */
  public buildRegular(smConfig: any, isUser: any, isAdmin: any, isSuperUser: any, isIUA: any, isRNT: any): MenuItem[] {
    const sideMenu: MenuItem[] = [];
    /**
     * Dashboard
     */
    if (!smConfig?.dashboard) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.DASHBOARD'),
          icon: 'far fa-tachometer-alt-fast',
          routerLink: [''],
          replaceUrl: true
        },
      );
    }
    /**
     * Crew
     */
    if (!smConfig?.crew && !isUser) {
      if (isRNT){
        sideMenu.push(
          {
            label: this.translateService.instant('FDC.PERSONS'),
            icon: 'far fa-users',
            routerLink: ['subjects'],
            replaceUrl: true
          },
        );
      } else {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.CREW'),
            icon: 'far fa-users',
            routerLink: ['subjects'],
            replaceUrl: true
          },
        );
      }
    }
    /**
     * MyPage
     */
    if (!smConfig?.myPage) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.MY_PAGE'),
          icon: 'far fa-user-shield',
          routerLink: ['my-page/my-info'],
          replaceUrl: true,
        },
      );
    }
    /**
     * Checklist
     */
    if (!smConfig?.checklists) {
      if (!isUser) {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.CHECKLISTS'),
            icon: 'far fa-list',
            items: [
              {
                label: this.translateService.instant('CHECKLIST.ONGOING'),
                routerLink: ['/checklists/active'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('CHECKLIST.REPETITIVE'),
                routerLink: ['/checklists/repetitive'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('CHECKLIST.TEMPLATE'),
                routerLink: ['/checklists/template'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('COMMON.COMPLETED'),
                routerLink: ['/checklists/completed'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('CHECKLIST.NOT_FINISHED'),
                routerLink: ['/checklists/not-finished'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('CHECKLIST.WITH_DEVIATIONS'),
                routerLink: ['/checklists/deviations'],
                replaceUrl: true,
              },
            ]
          },
        );
      } else {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.CHECKLISTS'),
            icon: 'far fa-list',
            items: [
              {
                label: this.translateService.instant('CHECKLIST.ONGOING'),
                routerLink: ['/checklists/active'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('CHECKLIST.TEMPLATE'),
                routerLink: ['/checklists/template'],
                replaceUrl: true,
              }
            ]
          },
        );
      }
    }
    /**
     * Deviations
     */
    if (!smConfig?.deviations) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.DEVIATIONS'),
          icon: 'far fa-exclamation-triangle',
          items: [
            {
              label: this.translateService.instant('DEVIATION.ONGOING'),
              routerLink: ['/deviation/deviations-main'],
              replaceUrl: true
            },
            {
              label: this.translateService.instant('DEVIATION.COMPLETED'),
              routerLink: ['/deviation/deviations-complete'],
              replaceUrl: true
            },
          ]
        },
      );
    }
    /**
     * Assets
     */
    if (!smConfig?.assets && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.ASSETS'),
          icon: 'far fa-truck',
          routerLink: ['/assets/main'],
        },
      );
    }
    /**
     * Exercise
     */
    if (!smConfig?.exercises) {
      if (!isUser) {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.EXERCISE'),
            icon: 'far fa-flag',
            items: [
              {
                label: this.translateService.instant('EXERCISE.PLANNED'),
                routerLink: ['/exercises/list'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('CHECKLIST.TEMPLATE'),
                routerLink: ['/exercises/templates'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('EXERCISE.THEME'),
                routerLink: ['exercises/themes'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('EXERCISE.COMPLETED'),
                routerLink: ['exercises/exercise-completed'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('EXERCISE.SHARING_EXPERIENCE'),
                routerLink: ['exercises/experience-sharing'],
                replaceUrl: true,
              },
            ]
          },
        );
      } else {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.EXERCISE'),
            icon: 'far fa-flag',
            items: [
              {
                label: this.translateService.instant('EXERCISE.PLANNED'),
                routerLink: ['/exercises/list'],
                replaceUrl: true,
              }
            ]
          },
        );
      }
    }
    /**
     * Tasks
     */
    if (!smConfig?.tasks) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.TASKS'),
          icon: 'far fa-check-square',
          items: [
            {
              label: this.translateService.instant('MAIN_FRAME.TASKS'),
              routerLink: ["task/active-tasks"],
              replaceUrl: true,
            },
            {
              label: this.translateService.instant('TASK.COMPLETED'),
              routerLink: ["task/completed-tasks"],
              replaceUrl: true,
            },
          ]
        },
      );
    }
    /**
     * Competencies
     */
    if (!smConfig?.competencies) {
      if(!isUser) {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.COMPETENCIES'),
            icon: 'far fa-graduation-cap',
            items: [
              {
                label: this.translateService.instant('MAIN_FRAME.MY_COMPETENCIES'),
                routerLink: ['/competencies/my-competencies'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('MAIN_FRAME.COMPETENCIES'),
                routerLink: ['/competencies/active'],
                replaceUrl: true,
              },
              {
                label: this.translateService.instant('COMPETENCY.TYPES'),
                routerLink: ['/competencies/competencyTypes'],
                replaceUrl: true,
              }
            ]
          },
        );
      } else {
        sideMenu.push(
          {
            label: this.translateService.instant('MAIN_FRAME.COMPETENCIES'),
            icon: 'far fa-graduation-cap',
            items: [
              {
                label: this.translateService.instant('MAIN_FRAME.MY_COMPETENCIES'),
                routerLink: ['/competencies/my-competencies'],
                replaceUrl: true,
              },
            ]
          }
        );
      }
    }
    /**
     * Functions
     */
    if (!smConfig?.functions && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('FUNCTION.FUNCTIONS'),
          icon: 'far fa-code',
          routerLink: ['/functions'],
          replaceUrl: true
        }
      );
    }
    /**
     * Calendar
     */
    if (!smConfig?.calendar && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.CALENDAR'),
          icon: 'far fa-calendar-alt',
          routerLink: ['/calender/calendar'],
          replaceUrl: true
        },
      );
    }
    /**
     * Files
     */
    if (!smConfig?.files) {
      sideMenu.push(
        {
          label: this.translateService.instant('MAIN_FRAME.FILES'),
          icon: 'far fa-file',
          routerLink: ['/files/files-main'],
          replaceUrl: true
        },
      );
    }
    /**
     * Event
     */
    if (!smConfig?.event && !isUser) {

      sideMenu.push(
        {
          label: this.translateService.instant('SUBJECTS.DOC_TYPES.EVENT'),
          icon: 'far fa-fire',
          items: [
            {
              label: this.translateService.instant('EVENT.EMERGENCY'),
              routerLink: ['/events/emergency'],
              replaceUrl: true
            },
            {
              label: this.translateService.instant('EVENT.EXPOSURE'),
              routerLink: ['/events/exposure'],
              replaceUrl: true
            },
          ]
        },
      );

    }
    /**
     * Roles
     */
    if (!smConfig?.crew) {
      sideMenu.push(
        {
          label:
            this.translateService.instant('ROLES.ROLES') + " "
            + this.translateService.instant('SUBJECTS.DOC_TYPES.EVENT'),
          icon: 'far fa-user-tag',
          routerLink: ['roles/role'],
          replaceUrl: true
        },
      );

    }
    /**
     * Shift Report
     */
    if (!smConfig?.shiftReport && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('SHIFT_REPORT.SHIFT_REPORT'),
          icon: 'far fa-book',
          routerLink: ['/shift-report/report-view'],
          replaceUrl: true
        },
      );
    }
    /**
     * Archive
     */
    if (!smConfig?.archive && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('ARCHIVE.ARCHIVE'),
          icon: 'far fa-archive',
          items: [
            {
              label: this.translateService.instant('COMPETENCY.TYPE'),
              routerLink: ['/archive/competency'],
              replaceUrl: true
            },
            {
              label: this.translateService.instant('FILE.FILES'),
              routerLink: ['/archive/files'],
              replaceUrl: true
            },
            {
              label: this.translateService.instant('ARCHIVE.SUBJECT'),
              routerLink: ['/archive/person'],
              replaceUrl: true
            },
            {
              label: this.translateService.instant('ASSET.ASSET'),
              routerLink: ['/archive/asset'],
              replaceUrl: true
            },
            {
              label: this.translateService.instant('BUTTON.SMS'),
              routerLink: ['/archive/sms'],
              replaceUrl: true
            },
          ]
        },
      );
    }
    /**
     * Report
     */
    if (!smConfig?.report && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('REPORT.REPORT'),
          icon: 'far fa-file-chart-line',
          items: [
            {
              label: this.translateService.instant('REPORT.HOURLY_REPORT'),
              routerLink: ['/reports/hours'],
              replaceUrl: true
            },
            // {label: this.translateService.instant('REPORT.EXERCISE_REPORT')},
            // {label: this.translateService.instant('REPORT.COMPETENCY')},
            // {
            //   label: this.translateService.instant('REPORT.HOURLY_REPORT')
            //     + ' ' + this.translateService.instant('EVENT.EMERGENCY')
            // },
            // {label: this.translateService.instant('REPORT.EMERGENCY_REPORT')},
            // {label: this.translateService.instant('REPORT.WEEKLY_REPORT')},
          ]
        },
      );
    }
    /**
     * IUA
     */
    if (isIUA) {
      // sideMenu.push(
      //   {
      //     label: 'IUA',
      //     icon: 'far fa-sitemap',
      //     items: [
      //       {label: this.translateService.instant('IUA.PERSON_OVERVIEW')},
      //       {label: this.translateService.instant('IUA.ASSET_OVERVIEW')},
      //     ]
      //   }
      // )
    }
    /**
     * Info message
     */
    if (!smConfig?.infoMessage && !isUser) {
      sideMenu.push(
        {
          label: this.translateService.instant('COMMON.INFO_MESSAGE'),
          icon: 'far fa-info',
          routerLink: ['/info-message/i-m'],
          replaceUrl: true
        },
      );
    }
    return sideMenu;
  }

  // /**
  //  * Builds the Regional side menu
  //  *
  //  * @param isUser: check if AccessLevel is User
  //  */
  // public buildRegional(isUser: any): MenuItem[] {
  //   const sideMenu: MenuItem[] = [];
  //
  //   sideMenu.push(
  //     {
  //       label: this.translateService.instant('MAIN_FRAME.DASHBOARD'),
  //       icon: 'far fa-tachometer-alt-fast',
  //     },
  //   )
  //
  //   if (!isUser) {
  //     sideMenu.push(
  //       {
  //         label: this.translateService.instant('MAIN_FRAME.CREW'),
  //         icon: 'far fa-users',
  //       },
  //       {
  //         label: this.translateService.instant('MAIN_FRAME.ASSETS'),
  //         icon: 'far fa-truck',
  //       },
  //       {
  //         label: this.translateService.instant('MAIN_FRAME.COMPETENCIES'),
  //         icon: 'far fa-graduation-cap',
  //       },
  //     )
  //
  //   }
  //   return sideMenu;
  // }


  // /**
  //  * Builds the FireDocumentation sideMenu
  //  *
  //  * @param isUser: check if AccessLevel is User
  //  */
  // public buildFDC(isUser: any) {
  //   const sideMenu: MenuItem[] = [];
  //
  //   sideMenu.push(
  //     {
  //       label: this.translateService.instant('MAIN_FRAME.HOMEPAGE'),
  //       icon: 'far fa-tachometer-alt-fast',
  //     },
  //   )
  //   if (!isUser) {
  //     sideMenu.push(
  //       {
  //         label: this.translateService.instant('FDC.PERSONS'),
  //         icon: 'far fa-users',
  //       },
  //     )
  //   }
  //   return sideMenu;
  // }
}
