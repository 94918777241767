<div class="grid">

  <div class="col-12 flex flex-wrap align-content-center">
    <h2 style="margin-top: 10px">{{'DEVIATION.COMPLETED' | translate}}</h2>
  </div>

  <ng-container *ngIf=" screenWidth() > 1000; else mediumBlock">
    <!-- FULLSCREEN BLOCK-->
    <div class="col-12">
      <p-table #att [globalFilterFields]="['name']" [loading]="loading" [paginator]="true" [rows]="25"
               [showCurrentPageReport]="true" [value]="deviations" breakpoint="200px"
               currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'MAIN_FRAME.DEVIATIONS' | translate}}"
               responsiveLayout="scroll" rowExpandMode="single" dataKey="key"
      >
        <ng-template style="max-width: 100%" pTemplate="caption">
          <div class="formgrid grid pl-0 pr-0">
            <div class="field col pl-0" style="min-width: 100px; max-width: 400px">
              <div class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input (input)="att.filterGlobal($event.target.value, 'contains')" class="inputfield w-full"
                       pInputText placeholder="{{'COMMON.SEARCH' | translate}}" size="100" type="text"/>
              </div>
            </div>

            <div class="field col pr-0">
              <p-dropdown (onChange)="setDeviationStationFilter()" [(ngModel)]="selectedStation" [options]="stations"
                          [style]="{'width':'100%'}" class="inputfield w-full" optionLabel="label"
                          optionValue="value" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"></p-dropdown>
            </div>
            <div class="field col pr-0">
              <p-dropdown (onChange)="setDeviationFilter()" [(ngModel)]="selectedFilter" [options]="filterOptions"
                          [style]="{'width':'100%'}" class="inputfield w-full" optionLabel="label"
                          optionValue="value" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"></p-dropdown>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th colspan="2" class="p-2">{{'COMMON.NAME' | translate}}</th>
            <th class="p-2">{{'ASSET.ASSET' | translate}}</th>
            <th class="p-2">{{'COMMON.CREATED' | translate}}</th>
            <th class="p-2">{{'TASK.COMPLETED_ON' | translate}}</th>
            <th class="p-2">{{'CHECKLIST.COMPLETED_BY' | translate}}</th>
            <th style="width: 3rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-deviation let-expanded="expanded">
          <tr>
            <td colspan="2" class="p-2">{{deviation.name}}</td>
            <td class="p-2">{{getAsset(deviation.asset)}}</td>
            <td class="p-2">{{getCreatedDate(deviation.created)}}</td>
            <td class="p-2 color">{{getCreatedDate(deviation.closed)}}</td>
            <td class="p-2">{{personNameMap.get(uidPersonIdMap.get(deviation.closedById))}}</td>
            <td>
              <button type="button" pButton [pRowToggler]="deviation"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-selDeviation>
          <tr>
            <td colspan="7">
              <div class="grid p-3">
                <div class="field col-12 mb-0 mt-2">
                  <span class="p-float-label">
                    <textarea [(ngModel)]="selDeviation.description"
                              class="inputfield w-full"
                              cols="30"
                              id="textarea1"
                              pInputTextarea
                              rows="4"
                              autoResize="true"
                              style="resize: none;"
                              disabled
                    ></textarea>
                    <label for="textarea1">{{'COMMON.DESCRIPTION' | translate}}</label>
                  </span>
                </div>
                <div class="col-12 main">
                  <ng-container *ngIf="selDeviation.comments">
                    <b>{{'COMMON.COMMENTS' | translate}}:</b>
                    <p-divider></p-divider>
                    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
                      <div class="col-12 relative" *ngFor="let comment of selDeviation.comments, let i = index">
                        <div style="word-break: break-all">{{comment.commentText}}</div>
                        <small class="text-muted float-right" style="margin-top: 10px; margin-bottom: 3px">
                          {{comment.commenter.name}}&nbsp;{{("COMMON.FOR" | translate).toLowerCase()}}&nbsp;{{ comment.time | amTimeAgo }}</small>
                        <p-divider></p-divider>
                      </div>
                      <p *ngIf="(selDeviation.comments)?.length < 1">{{'COMMON.NO_COMMENTS' | translate}}</p>
                      <p-scrollTop target="parent" [threshold]="100"></p-scrollTop>
                    </p-scrollPanel>
                  </ng-container>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="4">{{'DASHBOARD.TASK_CARD_NOT_FOUND' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-container>
  <ng-template #mediumBlock>
    <!-- MOBILE BLOCK -->
    <ng-container *ngIf="screenWidth() < 700; else tabletBlock">
      <div class="col-12">
        <p-table #att [globalFilterFields]="['name']" [loading]="loading" [paginator]="true" [rows]="11"
                 [showCurrentPageReport]="true" [value]="deviations" breakpoint="200px"
                 currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'MAIN_FRAME.DEVIATIONS' | translate}}"
                 responsiveLayout="scroll" rowExpandMode="single" dataKey="key"
        >
          <ng-template style="max-width: 100%" pTemplate="caption">
            <div class="formgrid grid pl-0 pr-0">
              <div class="field col pl-0" style="min-width: 100px; max-width: 400px">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input (input)="att.filterGlobal($event.target.value, 'contains')" class="inputfield w-full"
                         pInputText placeholder="{{'COMMON.SEARCH' | translate}}" size="100" type="text"/>
                </div>
              </div>

              <div class="field col pr-0">
                <p-dropdown (onChange)="setDeviationStationFilter()" [(ngModel)]="selectedStation" [options]="stations"
                            [style]="{'width':'100%'}" class="inputfield w-full" optionLabel="label"
                            optionValue="value" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"></p-dropdown>
              </div>
              <div class="field col pr-0">
                <p-dropdown (onChange)="setDeviationFilter()" [(ngModel)]="selectedFilter" [options]="filterOptions"
                            [style]="{'width':'100%'}" class="inputfield w-full" optionLabel="label"
                            optionValue="value" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"></p-dropdown>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th colspan="2" class="p-2">{{'COMMON.NAME' | translate}}</th>
              <th class="p-2">{{'ASSET.ASSET' | translate}}</th>
              <th class="p-2">{{'TASK.COMPLETED_ON' | translate}}</th>
              <th style="width: 3rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-deviation let-expanded="expanded">
            <tr>
              <td colspan="2" class="p-2">{{deviation.name}}</td>
              <td class="p-2">{{getAsset(deviation.asset)}}</td>
              <td class="p-2 color">{{getCreatedDate(deviation.closed)}}</td>
              <td>
                <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" [pRowToggler]="deviation"
                        class="p-button-text p-button-rounded p-button-plain" pButton type="button"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-selDeviation>
            <tr>
              <td colspan="7">
                <div class="grid p-3">
                  <div class="field col-6 mb-0 mt-2">
                    <span class="p-float-label">
                      <input [ngModel]="getCreatedDate(selDeviation.created)" [style]="{width: '100%'}"
                             disabled id="created2" pInputText type="text"/>
                      <label for="created2">{{'COMMON.CREATED' | translate}}</label>
                    </span>
                  </div>
                  <div class="field col-6 mb-0 mt-2">
                    <span class="p-float-label">
                      <input [ngModel]="personNameMap.get(uidPersonIdMap.get(selDeviation.closedById))"
                             [style]="{width: '100%'}" disabled id="completedBy2" pInputText
                             type="text"/>
                      <label for="completedBy2">{{'CHECKLIST.COMPLETED_BY' | translate}}</label>
                    </span>
                  </div>
                  <div class="field col-12 mb-0 mt-2">
                  <span class="p-float-label">
                    <textarea [(ngModel)]="selDeviation.description"
                              class="inputfield w-full"
                              cols="30"
                              id="textarea2"
                              pInputTextarea
                              rows="4"
                              autoResize="true"
                              style="resize: none;"
                              disabled
                    ></textarea>
                    <label for="textarea2">{{'COMMON.DESCRIPTION' | translate}}</label>
                  </span>
                  </div>
                  <div class="col-12 main">
                    <ng-container *ngIf="selDeviation.comments">
                      <b>{{'COMMON.COMMENTS' | translate}}:</b>
                      <p-divider></p-divider>
                      <p-scrollPanel [style]="{width: '100%', height: '100%'}">
                        <div class="col-12 relative" *ngFor="let comment of selDeviation.comments, let i = index">
                          <div style="word-break: break-all">{{comment.commentText}}</div>
                          <small class="text-muted float-right" style="margin-top: 10px; margin-bottom: 3px">
                            {{comment.commenter.name}}&nbsp;{{("COMMON.FOR" | translate).toLowerCase()}}&nbsp;{{ comment.time | amTimeAgo }}</small>
                          <p-divider></p-divider>
                        </div>
                        <p *ngIf="(selDeviation.comments)?.length < 1">{{'COMMON.NO_COMMENTS' | translate}}</p>
                        <p-scrollTop target="parent" [threshold]="100"></p-scrollTop>
                      </p-scrollPanel>
                    </ng-container>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">{{'DASHBOARD.TASK_CARD_NOT_FOUND' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
    <ng-template #tabletBlock>
      <!-- TABLET BLOCK -->
      <div class="col-12">
        <p-table #att [globalFilterFields]="['name']" [loading]="loading" [paginator]="true" [rows]="11"
                 [showCurrentPageReport]="true" [value]="deviations" breakpoint="200px"
                 currentPageReportTemplate="{{'TABLE.SHOW' | translate}} {first} {{'TABLE.TO' | translate}} {last} {{'TABLE.OF' | translate}} {totalRecords} {{'MAIN_FRAME.DEVIATIONS' | translate}}"
                 responsiveLayout="scroll" rowExpandMode="single" dataKey="key"
        >
          <ng-template style="max-width: 100%" pTemplate="caption">
            <div class="formgrid grid pl-0 pr-0">
              <div class="field col pl-0" style="min-width: 100px; max-width: 400px">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input (input)="att.filterGlobal($event.target.value, 'contains')" class="inputfield w-full"
                         pInputText placeholder="{{'COMMON.SEARCH' | translate}}" size="100" type="text"/>
                </div>
              </div>

              <div class="field col pr-0">
                <p-dropdown (onChange)="setDeviationStationFilter()" [(ngModel)]="selectedStation" [options]="stations"
                            [style]="{'width':'100%'}" class="inputfield w-full" optionLabel="label"
                            optionValue="value" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"></p-dropdown>
              </div>
              <div class="field col pr-0">
                <p-dropdown (onChange)="setDeviationFilter()" [(ngModel)]="selectedFilter" [options]="filterOptions"
                            [style]="{'width':'100%'}" class="inputfield w-full" optionLabel="label"
                            optionValue="value" placeholder="{{'FILTER.CHOOSE_FILTER' | translate}}"></p-dropdown>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th colspan="2" class="p-2">{{'COMMON.NAME' | translate}}</th>
              <th class="p-2">{{'ASSET.ASSET' | translate}}</th>
              <th class="p-2">{{'TASK.COMPLETED_ON' | translate}}</th>
              <th style="width: 3rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-deviation let-expanded="expanded">
            <tr>
              <td colspan="2" class="p-2">{{deviation.name}}</td>
              <td class="p-2">{{getAsset(deviation.asset)}}</td>
              <td class="p-2 color">{{getCreatedDate(deviation.closed)}}</td>
              <td>
                <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" [pRowToggler]="deviation"
                        class="p-button-text p-button-rounded p-button-plain" pButton type="button"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-selDeviation>
            <tr>
              <td colspan="7">
                <div class="grid p-3">
                  <div class="field col-6 mb-0 mt-2">
                    <span class="p-float-label">
                      <input [ngModel]="getCreatedDate(selDeviation.created)" [style]="{width: '100%'}"
                             disabled id="created" pInputText type="text"/>
                      <label for="created">{{'COMMON.CREATED' | translate}}</label>
                    </span>
                  </div>
                  <div class="field col-6 mb-0 mt-2">
                    <span class="p-float-label">
                      <input [ngModel]="personNameMap.get(uidPersonIdMap.get(selDeviation.closedById))"
                             [style]="{width: '100%'}" disabled id="completedBy" pInputText
                             type="text"/>
                      <label for="completedBy">{{'CHECKLIST.COMPLETED_BY' | translate}}</label>
                    </span>
                  </div>
                  <div class="field col-12 mb-0 mt-2">
                  <span class="p-float-label">
                    <textarea [(ngModel)]="selDeviation.description"
                              class="inputfield w-full"
                              cols="30"
                              id="textarea"
                              pInputTextarea
                              rows="4"
                              autoResize="true"
                              style="resize: none;"
                              disabled
                    ></textarea>
                    <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
                  </span>
                  </div>
                  <div class="col-12 main">
                    <ng-container *ngIf="selDeviation.comments">
                      <b>{{'COMMON.COMMENTS' | translate}}:</b>
                      <p-divider></p-divider>
                      <p-scrollPanel [style]="{width: '100%', height: '100%'}">
                        <div class="col-12 relative" *ngFor="let comment of selDeviation.comments, let i = index">
                          <div style="word-break: break-all">{{comment.commentText}}</div>
                          <small class="text-muted float-right" style="margin-top: 10px; margin-bottom: 3px">
                            {{comment.commenter.name}}&nbsp;{{("COMMON.FOR" | translate).toLowerCase()}}&nbsp;{{ comment.time | amTimeAgo }}</small>
                          <p-divider></p-divider>
                        </div>
                        <p *ngIf="(selDeviation.comments)?.length < 1">{{'COMMON.NO_COMMENTS' | translate}}</p>
                        <p-scrollTop target="parent" [threshold]="100"></p-scrollTop>
                      </p-scrollPanel>
                    </ng-container>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">{{'DASHBOARD.TASK_CARD_NOT_FOUND' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-template>
  </ng-template>
</div>
