import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService} from "primeng/dynamicdialog";
import {FileUploadComponent} from "../file-upload/file-upload.component";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem, MessageService, TreeNode} from 'primeng/api';
import {FileService} from '../../../services/service/file.service';
import {combineLatest, Subscription} from 'rxjs';
import {AccessControlService} from '../../../services/service/access-control.service';
import {FileData} from '../../../model/fileRelated';
import {FileViewComponent} from '../file-view/file-view.component';
import {FileTagsComponent} from '../file-tags/file-tags.component';
import {TenantService} from '../../../services/service/tenant.service';

@Component({
  selector: 'app-files-main',
  templateUrl: './files-main.component.html',
  styleUrls: ['./files-main.component.scss'],
  providers: [DialogService, MessageService]
})
export class FilesMainComponent implements OnInit, OnDestroy {

  components: {roles: boolean, events: boolean, sharedFiles: boolean};
  userStatus = new Map<string, boolean>();

  stateOptions: any[];

  archiveOptions = [
    {name: this.translateService.instant("FILE.BROWSE"), value: false},
    {name: this.translateService.instant("BUTTON.ARCHIVE"), value: true},
  ];
  items: MenuItem[];

  windowWidth;
  fileTree: [TreeNode[], TreeNode[], TreeNode[]] = [[], [], []];

  fileTags = new Map<string, {key: string, createdById: string, tagName: string}>();
  sharedTags = new Map<string, {key: string, createdById: string, tagName: string}>();
  allFilesMap = new Map<string, FileData>();
  sharedFileMap = new Map<string, FileData>();

  parentChildMap = new Map<string, string[]>();
  commonParentChildMap = new Map<string, string[]>();

  tagFbidMap = new Map<string, string[]>();

  subscription = new Subscription();

  archiveFiles = false;
  fileSelect = "";
  selectedFile: TreeNode;
  selectedFiles: string[] = [];

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private fileService: FileService,
    private acService: AccessControlService,
    private tenantService: TenantService,
    private messageService: MessageService,
  ) {
    this.windowWidth = window.innerWidth;

    this.subscription.add(combineLatest([this.fileService.getAllFiles(), this.fileService.getFileTagRelations(),
      this.fileService.getSharedFiles(), this.fileService.getSharedFileTagRelations(), this.fileService.getFileTags(),
      this.fileService.getSharedFileTags()])
      .subscribe(([allFiles, fileTagRelations, sharedFiles, sharedFileTagRelations,
                    fileTags, sharedTags]) => {

        this.acService.getOwnAccessControl().subscribe(value => {
          if (value.roles.admin){
            this.userStatus.set('admin', true);
          } else {
            this.userStatus.set('admin', false);
          }
          if (value.roles.superuser){
            this.userStatus.set('superUser', true);
          } else {
            this.userStatus.set('superUser', false);
          }
        });

        this.allFilesMap = new Map(allFiles.map(file => [file.key, file.payload.val()]));
        this.sharedFileMap = new Map(sharedFiles.map(file => [file.key, file.payload.val()]));
        this.fileTags = new Map(fileTags.map(tag => [tag.key, tag.payload.val()]));
        this.sharedTags = new Map(sharedTags.map(tag => [tag.key, tag.payload.val()]));

        this.parentChildMap = new Map<string, string[]>();
        fileTagRelations.forEach(relation => {
          const tagId = relation.payload.val().tagId;
          const fileId = relation.payload.val().fileId;
          if (this.parentChildMap.has(tagId)){
            this.parentChildMap.get(tagId).push(fileId);
          } else {
            this.parentChildMap.set(tagId, [fileId]);
          }
        });
        this.commonParentChildMap = new Map<string, string[]>();
        if(sharedFileTagRelations) {
          sharedFileTagRelations.forEach(relation => {
            if (this.commonParentChildMap.has(relation.payload.val().tagId)) {
              this.commonParentChildMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
              this.tagFbidMap.get(relation.payload.val().tagId).push(relation.payload.val().fileId);
            } else {
              this.commonParentChildMap.set(relation.payload.val().tagId, [relation.payload.val().fileId]);
              this.tagFbidMap.set(relation.payload.val().tagId, [relation.payload.val().key]);
            }
          });
        }

        this.fileTree = this.buildTree(this.parentChildMap, this.commonParentChildMap, this.allFilesMap,
          this.sharedFileMap, this.fileTags, this.translateService, this.userStatus.get('admin'), this.sharedTags);

        if(this.fileTags.size > 0){
          this.items= [
            {   label: this.translateService.instant('FILE.ADD_FILES'),
              icon: 'far fa-cloud-upload',
              command: () => {
                this.addFile();
              }
            },
            {   label: this.translateService.instant('FILE.EDIT_CATEGORIES'),
              icon: 'pi pi-cog',
              command: () => {
                this.editTags();
              }
            },
          ];
        }
        else {
          this.items= [
            {   label: this.translateService.instant('FILE.EDIT_CATEGORIES'),
              icon: 'pi pi-cog',
              command: () => {
                this.editTags();
              }
            },
          ];
        }
      })
    );



  }

  /**
   * On init get active components, and build menu.
   */
  ngOnInit(): void {
    this.stateOptions = [
      {name: this.translateService.instant("FILE.INTERNAL"), value: "internal"},
      {name: this.translateService.instant("FILE.ADD_FLEXIT_FILES"), value: "flexit"}
    ];
  }

  /**
   * Build tree
   * @param parentChildMap        {tagId, [fileId_1, ..., fileId_n]} internal file relations.
   * @param publicParentChildMap  {tagId, [fileId_1, ..., fileId_n]} external file relations.
   * @param allFilesMap           {fileId, {file object}} file map with admin files.
   * @param sharedFileMap         {fileId, {file object}} file map shared flexitfire files.
   * @param fileTags              {tagId, {createdById, tagName}} tag map linking tagId and name.
   * @param translateS            TranslateService
   * @param isAdmin               Boolean
   * @param sharedTags            FlexitFire tags
   */
  buildTree(parentChildMap: Map<string, any[]>, publicParentChildMap: Map<string, any[]>,
            allFilesMap: Map<string, FileData>, sharedFileMap: Map<string, FileData>,
            fileTags: Map<string, {key: string, createdById: string, tagName: string}>,
            translateS, isAdmin, sharedTags) {

    let fileTree: [TreeNode[], TreeNode[], TreeNode[]] = [[], [], []];

    if (parentChildMap.size > 0 && publicParentChildMap.size > 0 && allFilesMap.size > 0 &&
      sharedFileMap.size > 0 && fileTags.size > 0) {

      // User and admin files
      if (isAdmin) {
        for (const [key, files] of parentChildMap.entries()) {
          const tagNode: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: false,
            children: [] = [],
            key: key,
          };
          const admin: TreeNode = {
            label: translateS.instant('FILE.ADMIN_FILER'),
            leaf: false,
            selectable: false,
            children: [],
            parent: tagNode,
          };
          const tagNodeArchived: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: false,
            children: [] = [],
            key: key,
          };
          const adminArchived: TreeNode = {
            label: translateS.instant('FILE.ADMIN_FILER'),
            leaf: false,
            selectable: false,
            children: [],
            parent: tagNode,
          };
          files.forEach(fileId => {
            if (allFilesMap.get(fileId)) {
              if (!allFilesMap.get(fileId).archived && !allFilesMap.get(fileId).exerciseFile) {
                if (allFilesMap.get(fileId).admin) {
                  const fileNode1: TreeNode = {
                    label: allFilesMap.get(fileId).name,
                    data: allFilesMap.get(fileId),
                    leaf: true,
                    parent: admin,
                    key: fileId,
                  };
                  admin.children.push(fileNode1);
                } else {
                  const fileNode: TreeNode = {
                    label: allFilesMap.get(fileId).name,
                    data: allFilesMap.get(fileId),
                    leaf: true,
                    parent: tagNode,
                    key: fileId,
                  };
                  tagNode.children.push(fileNode);
                }
              }
            }
          });

          if (admin.children.length > 0) {
            tagNode.children.push(admin);
          }
          if (tagNode.children.length > 0) {
            fileTree[0].push(tagNode);
            fileTree[1].push(tagNode);
          }

          if (adminArchived.children.length > 0) {
            tagNodeArchived.children.push(adminArchived);
          }

        }
      }
      // User files
      else {
        for (const [key, files] of parentChildMap.entries()) {
          const tagNode1: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: false,
            children: [] = [],
            key: key,
          };
          const tagNode2: TreeNode = {
            label: fileTags.get(key).tagName,
            leaf: false,
            selectable: true,
            children: [] = [],
            key: key,
          };
          files.forEach(fileId => {
            if (!allFilesMap.get(fileId).admin && !allFilesMap.get(fileId).exerciseFile && !allFilesMap.get(fileId).archived) {
              const fileNode1: TreeNode = {
                label: allFilesMap.get(fileId).name,
                data: allFilesMap.get(fileId),
                leaf: true,
                parent: tagNode1,
                key: fileId,
              };
              const fileNode2: TreeNode = {
                label: allFilesMap.get(fileId).name,
                data: allFilesMap.get(fileId),
                leaf: true,
                parent: tagNode2,
                key: fileId,
              };
              tagNode1.children.push(fileNode1);
              tagNode2.children.push(fileNode2);
            }
          });
          fileTree[0].push(tagNode1);
          fileTree[1].push(tagNode2);
        }
      }

      // Flexit fire files
      for (const [key, files] of publicParentChildMap.entries()) {
        const tagNode: TreeNode = {
          label: sharedTags.get(key).tagName,
          leaf: false,
          selectable: false,
          children: [] = [],
          key: key,
        };
        files.forEach(fileId => {
          const fileNode: TreeNode = {
            label: sharedFileMap.get(fileId).name,
            data: sharedFileMap.get(fileId),
            leaf: true,
            parent: tagNode,
            key: fileId,
          };
          if (!sharedFileMap.get(fileId).archived) {
            tagNode.children.push(fileNode);
          }
        });

        if (tagNode.children.length > 0) {
          fileTree[2].push(tagNode);
        }
      }
      // Sort trees by name
      fileTree.forEach(tree => {
        tree.sort((a, b) =>{

          const nameA = a['label'].toLowerCase(); // ignore upper and lowercase
          const nameB = b['label'].toLowerCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      });
      if (fileTree[0].length > 0){
        this.fileSelect = "internal";
      }
      return fileTree;
    } else {
      fileTree = [[], [], []];
      return fileTree;
    }
  }

  /**
   * Add new file
   */
  addFile(){
    this.dialogService.open(FileUploadComponent, {
      header: this.translateService.instant('FILE.UPLOAD_NEW_FILE'),
      styleClass: 'max-size-dialog-short',
    }).onClose.subscribe(done => {
      if (done) {
        this.fileTree = this.buildTree(this.parentChildMap, this.commonParentChildMap, this.allFilesMap,
          this.sharedFileMap, this.fileTags, this.translateService, this.userStatus.get('admin'), this.sharedTags);
      }
    });
  }

  /**
   * Add, Edit or remove Tags
   */
  editTags(){
    this.dialogService.open(FileTagsComponent, {
      header: this.translateService.instant('FILE.EDIT_CATEGORIES'),
      styleClass: 'max-size-dialog',
    });
  }

  /**
   * Open file
   * @param $event File selected
   */
  nodeSelect($event: any) {
    this.dialogService.open(FileViewComponent, {
      header: $event.node.data.name,
      styleClass: 'max-size-dialog',
      data: {
        file: $event.node.data,
        fileKey: $event.node.key,
        flexitFiles: this.fileSelect,
      },
    }).onClose.subscribe(result => {
      if (result === 'Save') {
        this.fileTree = this.buildTree(this.parentChildMap, this.commonParentChildMap, this.allFilesMap,
          this.sharedFileMap, this.fileTags, this.translateService, this.userStatus.get('admin'), this.sharedTags);
      }
      if (result === 'Deleted') {
        this.messageService.add(
          {
            severity: 'success',
            summary: this.translateService.instant('TOAST.SUCCESS'),
            detail: $event.node.data.name + " " + this.translateService.instant('FILE.FILE_IS_DELETED_ALERT')
          }
        );
        this.fileTree = this.buildTree(this.parentChildMap, this.commonParentChildMap, this.allFilesMap,
          this.sharedFileMap, this.fileTags, this.translateService, this.userStatus.get('admin'), this.sharedTags);
      }
    });
  }

  /**
   * Add selected file to archive queue.
   * @param $event File selected
   */
  nodeSelectArchive($event: any) {
    if ($event.node.leaf){
      this.selectedFiles.push($event.node.key);
    } else {
      const children = $event.node.children;
      children.forEach(child => {
        if (child.leaf) {
          this.selectedFiles.push(child.key);
        } else{
          child.children.forEach(grandChild => {
            this.selectedFiles.push(grandChild.key);
          });
        }
      });
    }
  }

  /**
   * Remove unselected file key from archive queue.
   * @param $event File selected
   */
  nodeUnselectArchive($event: any) {
    if ($event.node.leaf){
      const index = this.selectedFiles.indexOf($event.node.key);
      this.selectedFiles.splice(index, 1);
    } else {
      $event.node.children.forEach(child => {
        if (child.leaf) {
          const index = this.selectedFiles.indexOf(child.key);
          this.selectedFiles.splice(index, 1);
        } else {
          child.children.forEach(grandChild => {
            const index = this.selectedFiles.indexOf(grandChild.key);
            this.selectedFiles.splice(index, 1);
          });
        }
      });
    }
  }

  /**
   * Archive all selected files
   */
  setArchived() {
    this.selectedFiles.forEach(key => {
      const file = this.allFilesMap.get(key);
      file.archived = true;
      this.fileService.updateFile(key, file);

    });

    this.fileTree = this.buildTree(this.parentChildMap, this.commonParentChildMap, this.allFilesMap,
      this.sharedFileMap, this.fileTags, this.translateService, this.userStatus.get('admin'), this.sharedTags);

  }

  /**
   * Unsubscribe all
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
