import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emergencyType'
})
export class EmergencyTypePipe implements PipeTransform {

  transform(tags: any[], search?: string): any {
    if(!tags || !search){
      return tags;
    } else {
      return tags.filter(tag => tag.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
