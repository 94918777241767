<!-- ConfirmDialog -->
<p-confirmDialog key="eApprove" [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<div class="grid">
  <div class="col-12 flex justify-content-end">
    <button pButton class="p-button-raised" label="{{'EXERCISE.APPROVE_PARTICIPATION' | translate}}"
            (click)="approveExercise()"></button>
  </div>

  <!-- PAGEINATOR -->
  <div class="col-12 pb-0">
    <p-paginator [rows]="1" [totalRecords]="participants.length" (onPageChange)="onPageChange($event)"
                 dropdownAppendTo="body" [showJumpToPageDropdown]="true" [showPageLinks]="false">
    </p-paginator>
  </div>

  <!-- PARTICIPANT -->
  <div class="col-12 flex justify-content-center">
    <h3><i class="pi pi-user"></i>&nbsp;{{personMap.get(this.participants[currentPage].key)}}</h3>
  </div>

  <!-- TABLE -->
  <div class="col-12 pt-0">
    <p-table [value]="participants[currentPage].competencyTypes" breakpoint="200px">

      <!-- CAPTION -->
      <ng-template pTemplate="caption">

        <!-- ABSENCE SELECT -->
        <div class="flex justify-content-end">
          <p-dropdown [options]="absenceSelect" [(ngModel)]="participants[currentPage].absence" (ngModelChange)="onAbsenceChange($event)" optionLabel="name"
                      optionValue="value"></p-dropdown>
        </div>
      </ng-template>

      <!-- HEADER -->
      <ng-template pTemplate="header">
        <tr>
          <th>{{'MAIN_FRAME.COMPETENCIES' | translate}}</th>
          <th style="width: 150px"></th>
        </tr>
      </ng-template>

      <!-- BODY -->
      <ng-template pTemplate="body" let-competencyType>
        <tr>
          <td>{{competencyTypesMap.get(competencyType.key)}}</td>
          <td>
            <p-selectButton [options]="stateOptions" [(ngModel)]="competencyType.value"
                            optionLabel="icon" optionValue="value">
              <ng-template let-item>
                <i [class]="item.icon"></i>
              </ng-template>
            </p-selectButton>
          </td>
        </tr>
      </ng-template>

      <!-- EMPTY MESSAGE -->
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">{{'COMMON.NO_COMPETENCY' | translate}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
