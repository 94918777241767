import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from "./service/auth.service";
import {MessageService} from "primeng/api";
import {MenuService} from "./service/menu.service";
import {TenantService} from "./service/tenant.service";
import {UserService} from "./service/user.service";
import {AccessControlService} from "./service/access-control.service";
import {PersonService} from "./service/person.service";
import {SubjectService} from "./service/subject.service";
import {ChecklistService} from "./service/checklist.service";
import {ExerciseService} from "./service/exercise.service";
import {TaskService} from "./service/task.service";
import {AssetService} from "./service/asset.service";
import {CompetencyService} from "./service/competency.service";
import {AttestationService} from "./service/attestation.service";
import {CompetencyTypeService} from "./service/competency-type.service";
import {SdaService} from "./service/sda.service";
import {EmergencyService} from "./service/emergency.service";


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    AuthService,
    MessageService,
    MenuService,
    TenantService,
    UserService,
    AccessControlService,
    PersonService,
    SubjectService,
    ChecklistService,
    ExerciseService,
    TaskService,
    AssetService,
    CompetencyService,
    AttestationService,
    CompetencyTypeService,
    SdaService,
    EmergencyService
  ]
})
export class ServicesModule { }
