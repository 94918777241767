import { Injectable } from '@angular/core';
import {FilterService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class TaskSharedService {

  constructor(
      private filterService: FilterService,
  ) { }

  buildAssets(assets, stations) {

    const stationAssetMap = new Map();
    const assetStationMap = new Map();

    assets.forEach(asset => {
      if (stationAssetMap.has(asset.payload.val().stationId)){
        stationAssetMap.get(asset.payload.val().stationId).push(asset.key);
      } else {
        stationAssetMap.set(asset.payload.val().stationId, [asset.key]);
      }
      assetStationMap.set(asset.key, asset.payload.val().stationId);
    });

    const assetNameMap = new Map(assets.map(asset => [asset.key, asset.payload.val().name]));

    const groupedAssetsFilter: any[] = [];

    stations.forEach(station => {
      const group = {
        label: station.payload.val().name,
        value: station.key,
        items: []
      }
      group.items.push(
          {
            label: station.payload.val().name,
            value: station.key
          }
      );

      if (stationAssetMap.get(station.key)) {
        if (stationAssetMap.get(station.key).length > 1) {
          stationAssetMap.get(station.key).forEach(assetId => {
            group.items.push({label: assetNameMap.get(assetId), value: assetId});
          });
        } else {
          group.items.push(
              {
                label: assetNameMap.get(stationAssetMap.get(station.key)),
                value: stationAssetMap.get(station.key)
              }
          );
        }
      }

      if (group.items.length > 0) {
        groupedAssetsFilter.push(group);
      }
    });

    return [stationAssetMap, assetStationMap, assetNameMap, groupedAssetsFilter];
  }


  /**
   * Add Person
   * @param personMap Person map
   * @param selectedSubjects Array
   * @param subject Subject to add
   */
  addPerson(personMap, selectedSubjects, subject){
    if (!personMap.has(subject)) {
      personMap.set(subject, true);
      selectedSubjects.push(subject.value);
    }
    return [personMap, selectedSubjects];
  }

  /**
   * Filter person by name
   * @param groupedSubjectFilter Array
   * @param event Search event
   */
  filterPerson(groupedSubjectFilter, event) {
    const query = event.query;
    const filteredGroups = [];

    for (const group of groupedSubjectFilter) {
      if(group.label.toLowerCase().indexOf(query.toLowerCase()) !== -1){
        filteredGroups.push(group);
      } else {
        const filteredSubOptions = this.filterService.filter(group.items, ['label'], query, "contains");
        if (filteredSubOptions && filteredSubOptions.length) {
          filteredGroups.push({
            label: group.label,
            value: group.value,
            items: filteredSubOptions
          });
        }
      }
    }
    return filteredGroups;
  }

  /**
   * Remove person
   * @param personMap Person map
   * @param selectedSubjects Array
   * @param selectedLeader Fbid
   * @param personId
   */
  removePerson(personMap, selectedSubjects, selectedLeader, personId: any) {
    personMap.delete(personId);

    const index = selectedSubjects.findIndex(pId => pId === personId)
    if(index >= 0) {
      selectedSubjects.splice(index, 1);
    }
    if (selectedLeader === personId){
       selectedLeader = "";
    }
    return [personMap, selectedSubjects, selectedLeader];
  }

}
