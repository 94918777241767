<div class="grid">
  <div class="col-12" *ngFor="let id of deviations">
    <ng-container *ngIf="deviationMap.get(id)?.closed; then closed; else created"></ng-container>

    <ng-template #closed>
      <p-card [style]="{'width':'100%'}" class="p-0" (click)="openDeviation(id)">
        <ng-template pTemplate="header">
          <div
            style="background-color: #73BF21;
            color: white;
            min-height: 30px"
            class="flex justify-content-center align-items-center flex-wrap">{{deviationMap.get(id)?.name}}</div>
        </ng-template>

        <ul style="list-style-type: none" class="p-0">
          <!-- CLOSED -->
          <li class="mb-1">{{'COMMON.CLOSED' | translate}}{{':'}}&nbsp;
            <b>{{deviationMap.get(id)?.closed | amDateFormat: 'DD.MM.yy'}}</b>
          </li>
          <!-- CLOSED BY -->
          <li class="mb-1">
            {{'COMMON.CLOSED_BY' | translate}}{{':'}}&nbsp;
            <b>{{personMap.get(deviationMap.get(id)?.closedById)}}</b>
          </li>
          <!-- DESCRIPTION -->
          <li class="mb-1 ellipsis">
            {{'COMMON.DESCRIPTION' | translate}}{{':'}}&nbsp;
            <b>{{deviationMap.get(id)?.description}}</b>
          </li>
        </ul>
      </p-card>
    </ng-template>

    <ng-template #created>
      <p-card [style]="{'width':'100%'}" class="p-0" (click)="openDeviation(id)">
        <ng-template pTemplate="header">
          <div
            style="background-color: #f5a623;
            color: white;
            min-height: 30px"
            class="flex justify-content-center align-items-center flex-wrap">{{deviationMap.get(id)?.name}}</div>
        </ng-template>

        <ul style="list-style-type: none" class="p-0">
          <!-- CREATED -->
          <li class="mb-1">{{'COMMON.CREATED' | translate}}{{':'}}&nbsp;
            <b>{{deviationMap.get(id)?.created | amDateFormat: 'DD.MM.yy'}}</b>
          </li>
          <!--CREATED BY -->
          <li class="mb-1">
            {{'COMMON.CREATED_BY' | translate}}{{':'}}&nbsp;
            <b>{{personMap.get(deviationMap.get(id)?.createdById)}}</b>
          </li>
          <!--DESCRIPTION -->
          <li class="mb-1 ellipsis">
            {{'COMMON.DESCRIPTION' | translate}}{{':'}}&nbsp;
            <b>{{deviationMap.get(id)?.description}}</b>
          </li>
        </ul>
      </p-card>
    </ng-template>
  </div>
</div>
