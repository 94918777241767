<div class="grid">

    <div class="col-8 flex flex-wrap align-content-center">
        <h2 style="margin-top: 10px">{{'SHIFT_REPORT.SHIFT_REPORT' | translate}}</h2>
    </div>
    <div class="col-4 flex justify-content-end align-content-center">
        <button pButton (click)="addShiftReport()" type="button" icon="pi pi-plus"
                class="p-button-rounded p-button-raised"></button>
    </div>
    <div class="col-12">
        <p-table [value]="shiftReports" dataKey="key$" responsiveLayout="scroll" rowExpandMode="single" [loading]="loading">
            <ng-template pTemplate="caption" class="pt-5">
                <div class="grid">
                    <div class="col-4 pl-0">
                        <p-dropdown (onChange)="filterReports(selStation, selYear, selMonth)"
                                    [(ngModel)]="selStation" [style]="{'width':'100%'}"
                                    [options]="stations"
                                    optionLabel="name" optionValue="value"></p-dropdown> </div>
                    <div class="col-4">
                        <p-dropdown (onChange)="filterReports(selStation, selYear, selMonth)"
                                    [(ngModel)]="selYear" [options]="years" [style]="{'width':'100%'}"></p-dropdown> </div>
                    <div class="col-4 pr-0">
                        <p-dropdown (onChange)="filterReports(selStation, selYear, selMonth)"
                                    [(ngModel)]="selMonth" [options]="months" [style]="{'width':'100%'}"
                                    optionLabel="name" optionValue="value" placeholder="{{'COMMON.MONTH' | translate}}">
                        </p-dropdown>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <div class="flex justify-content-center">
                            <i class="far fa-cog"></i>
                        </div>
                    </th>
                    <th>{{"COMMON.DATE.DATE" | translate}}</th>
                    <th style="width: 3rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-report let-expanded="expanded">
                <tr>
                    <td>
                        <button [icon]="'far fa-cog'" class="p-button-text p-button-rounded p-button-plain"
                                pButton type="button" (click)="editShiftReport(report)">
                        </button>
                    </td>
                    <td>{{report.date}}</td>
                    <td>
                        <button [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                [pRowToggler]="report" class="p-button-text p-button-rounded p-button-plain"
                                pButton type="button" (click)="getRelevant(report.key$)">
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-shiftReport>
                <tr>
                    <td colspan="3">
                        <div class="grid p-3">
                            <div class="col-12  flex justify-content-center">
                                <p-tabMenu [model]="stateOptions" [activeItem]="activeState"
                                           (click)="setTabState(shiftReport)"></p-tabMenu>
                            </div>
                            <ng-container *ngIf="activeState === stateOptions[0]; else eventBlock">
                                <ng-container *ngIf="windowWidth > 480; else smallScreenBlock">
                                    <div class="col-2 mt-2 flex flex-wrap align-content-center">{{'COMMON.FROM' | translate}}*</div>
                                    <div class="col-5 mt-2">
                                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                                    dateFormat="dd.mm.yy"
                                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                                    class="w-full inputfield"
                                                    [(ngModel)]="shiftReport.date"
                                                    disabled
                                        ></p-calendar>
                                    </div>
                                    <div class="field mt-2 col-5 mb-0">
                                        <input [(ngModel)]="shiftReport.timeStart"
                                               [style]="{'width' : '100%', 'height' : '42px'}"
                                               class="inputfield w-full"
                                               pInputText
                                               type="time"
                                               disabled
                                        >
                                    </div>
                                    <div class="col-2 mt-2 flex flex-wrap align-content-center">{{'COMMON.TO' | translate}}*</div>
                                    <div class="col-5 mt-2">
                                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                                    dateFormat="dd.mm.yy"
                                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                                    class="w-full inputfield"
                                                    [(ngModel)]="shiftReport.dateTo"
                                                    disabled
                                        ></p-calendar>
                                    </div>
                                    <div class="field mt-2 col-5 mb-0">
                                        <input [(ngModel)]="shiftReport.timeEnd"
                                               [style]="{'width' : '100%', 'height' : '42px'}"
                                               class="inputfield w-full"
                                               pInputText
                                               type="time"
                                               disabled
                                        >
                                    </div>
                                </ng-container>
                                <ng-template #smallScreenBlock>
                                    <div class="col-12 mt-2">
                                        <p-calendar placeholder="{{'EVENT.CHOOSE_DATE' | translate}}*"
                                                    dateFormat="dd.mm.yy"
                                                    [style]="{'width' : '100%', 'height' : '42px'}"
                                                    class="w-full inputfield"
                                                    [(ngModel)]="shiftReport.date"
                                                    disabled
                                        ></p-calendar>
                                    </div>
                                    <div class="col-2 mt-2 flex flex-wrap align-content-center justify-content-end">{{'COMMON.FROM' | translate}}*
                                    </div>
                                    <div class="field mt-2 col-4 mb-0">
                                        <input [(ngModel)]="shiftReport.timeStart"
                                               [style]="{'width' : '100%', 'height' : '42px'}"
                                               class="inputfield w-full"
                                               pInputText
                                               type="time"
                                               disabled
                                        >
                                    </div>
                                    <div class="col-2 mt-2 flex flex-wrap align-content-center justify-content-end">{{'COMMON.TO' | translate}}*</div>
                                    <div class="field mt-2 col-4 mb-0">
                                        <input [(ngModel)]="shiftReport.timeEnd"
                                               [style]="{'width' : '100%', 'height' : '42px'}"
                                               class="inputfield w-full"
                                               pInputText
                                               type="time"
                                               disabled
                                        >
                                    </div>
                                </ng-template>
                                <div class="field col-12 mb-0 mt-2">
                                        <span class="p-float-label">
                                            <textarea [(ngModel)]="shiftReport.description"
                                                      class="inputfield w-full"
                                                      cols="30"
                                                      id="textarea"
                                                      pInputTextarea
                                                      rows="4"
                                                      autoResize="true"
                                                      style="resize: none;"
                                            ></textarea>
                                            <label for="textarea">{{'COMMON.DESCRIPTION' | translate}}</label>
                                        </span>
                                </div>
                                <div class="col-12 mb-0">
                                    <button (click)="updateDescription(shiftReport)"
                                            class="float-right p-button-raised" icon="pi pi-bookmark"
                                            label="{{'BUTTON.SAVE' | translate}}" pButton type="button"></button>
                                </div>
                                <div class="col-12 flex">
                                    <!-- MOCK HEADER -->
                                    <div class="col-2 flex justify-content-center" style="background-color: #f8f9fa">
                                        <i class="far fa-users-crown"></i>
                                    </div>
                                    <div class="col-5" style="background-color: #f8f9fa">
                                        {{'COMMON.NAME' | translate}}
                                    </div>
                                    <div class="col-5 flex justify-content-center" style="background-color: #f8f9fa">
                                        {{'ROLES.ROLE' | translate}}
                                    </div>
                                </div>
                                <div class="col-12 flex" *ngFor="let subject of srSubjectsMap.get(shiftReport.key$);
                                    index as i">
                                    <div class="col-2 flex justify-content-center">
                                        <p-checkbox binary="true" [ngModel]="subject.leader" disabled></p-checkbox>
                                    </div>
                                    <div class="col-5">
                                        {{subject.name}}
                                    </div>
                                    <div class="col-5 flex justify-content-center">
                                        {{subject.role}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #eventBlock>
                                <div *ngFor="let emergency of relevantEvents"
                                     class="col-12 sm:col-6 md:col-4 lg:col-3">
                                    <p-card styleClass="p-card-shadow box">
                                        <ng-template pTemplate="header">
                                            <div style="background-color: #16214D; min-height: 30px;"
                                                 class="flex align-content-center flex-wrap justify-content-center banner">
                                                <div class="flex align-items-center" style="color: white">
                                                    {{'EVENT.EMERGENCY' | translate}}: {{getCounterId(emergency)}}
                                                </div>
                                            </div>
                                        </ng-template>
                                        <div class="grid">
                                            <div class="col-offset-1 col-5 ">
                                                {{'EVENT.BRIS' | translate}}:
                                            </div>
                                            <div class="col-6" *ngIf="emergency.brisNumber">
                                                {{emergency.brisNumber}}
                                            </div>
                                            <div class="col-6" *ngIf="!emergency.brisNumber"></div>
                                            <div class="col-offset-1 col-5 ">
                                                {{'COMMON.DATE.DATE' | translate}}:
                                            </div>
                                            <div class="col-6">
                                                {{emergency.date}}
                                            </div>
                                            <div class="col-offset-1 col-5">
                                                {{'EVENT.TIME_PERIOD' | translate}}:
                                            </div>
                                            <div class="col-5">
                                                {{emergency.timeStart}} - {{emergency.timeEnd}}
                                            </div>
                                            <div class="col-offset-1 col-5">
                                                {{'FDC.ADDRESS' | translate}}: </div>
                                            <div class="col-6 surface-overlay text pb-0 breakWord align-content-center">
                                                {{emergency.location.address}}
                                            </div>
                                            <div class="col-offset-1 col-5">
                                                {{'EVENT.DURATION' | translate}}:
                                            </div>
                                            <div class="col-6">
                                                {{calculateDuration(emergency.date, emergency.timeStart, emergency.timeEnd)}}
                                            </div>
                                            <div class="col-offset-1 col-5">
                                                {{'EVENT.NUMBER_OF_CREWS' | translate}}:
                                            </div>
                                            <div class="col-6">
                                                {{numberOfCrews(emergency.participants)}}
                                            </div>
                                        </div>
                                        <ng-template pTemplate="footer">
                                            <div class="flex justify-content-center" >
                                                <button pButton (click)="moreInfo(emergency)" type="button" icon="pi pi-book"
                                                        class="p-button-rounded"></button>
                                            </div>
                                        </ng-template>
                                    </p-card>
                                </div>
                            </ng-template>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <!-- Shows message if table is empty -->
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3">
                        {{'WARNING.NO_DATA' | translate}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
