import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeviationService} from "../../../services/service/deviation.service";
import {take} from "rxjs/operators";
import {PersonService} from "../../../services/service/person.service";
import {SubjectService} from "../../../services/service/subject.service";
import {ChecklistService} from "../../../services/service/checklist.service";
import {AssetService} from "../../../services/service/asset.service";
import {DialogService} from "primeng/dynamicdialog";
import {ChecklistDetailComponent} from "../checklist-detail/checklist-detail.component";
import {AddChecklistComponent} from "../add-checklist/add-checklist.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-ongoing-checklists',
  templateUrl: './ongoing-checklists.component.html',
  styleUrls: ['./ongoing-checklists.component.scss'],
  providers: [DialogService]
})
export class OngoingChecklistsComponent implements OnInit, OnDestroy {
  @Input() fromDashboard;
  checklists;
  checklistLoading = true;
  checklistSubscription;
  checklistFilter = [];
  selectedStation;

  deviationMap = new Map();
  deviationMapSubscription;
  stationsMap = new Map();

  rows = 13;

  constructor(
    private deviationService: DeviationService,
    private personService: PersonService,
    private subjectService: SubjectService,
    private checklistService: ChecklistService,
    private assetService: AssetService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {

    if (this.fromDashboard){
      this.rows = 4;
    }

    /**
     * Create a map that contains assetId's that has open deviations.
     */
    this.deviationMapSubscription =
      this.deviationService.getOpenDeviationsAsMap().subscribe(dMap => {
        this.deviationMap = dMap;
      });

    /**
     * Get Own Station and set selectedStation.
     */
    this.personService.getOwnStation().pipe(
      take(1)
    ).subscribe(ownStation => {
      if(!ownStation){
        this.selectedStation = '';
      } else {
        this.selectedStation = ownStation.key;
      }
    });

    /**
     * Get Stations Filter
     */
    this.subjectService.getStations().pipe(
      take(1),
    ).subscribe(stationsPayload => {
      this.checklistFilter = stationsPayload.map(
        station => {
          return ({key: station.key, name: station.payload.val().name});
        }
      );
      this.stationsMap = new Map(stationsPayload.map(i => [i.key, i.payload.val().name]));
    });

    /**
     * Get Open checklists combined with its corresponding asset.
     */
    this.checklistSubscription =
      this.checklistService.getActiveChecklistsWithAsset().subscribe(cl => {
        this.checklists =
          cl.sort((a,b) => (new Date(a.repeatDate).getTime() || -Infinity) - (new Date(b.repeatDate).getTime() || -Infinity));
        this.checklistLoading = false;
      });
  }

  /**
   * Set the status color of the asset (danger, warning og success)
   * @param checklist
   */
  setStatus(checklist: any) {
    return this.assetService.getAssetStatus(checklist.asset, this.deviationMap);
  }

  onTableClick(checklist) {
    this.dialogService.open(ChecklistDetailComponent, {
      header: checklist.name,
      data: {
        checklistId: checklist.key
      },
      styleClass: 'max-size-dialog'
    });
  }

  /**
   * Unsubscribe on destroy
   */
  ngOnDestroy(): void {
    if (this.checklistSubscription) {
      this.checklistSubscription.unsubscribe();
    }
  }

  addChecklist() {
    this.dialogService.open(AddChecklistComponent, {
      header: this.translateService.instant('CHECKLIST.ADD_CHECKLIST.NEW_CHECKLIST'),
      styleClass: 'max-size-width-dialog',
      data: {

        index: 0,
      }
    });
  }
}
