<!-- ConfirmDialog -->
<p-confirmDialog [style]="{minWidth: '350px'}" header="{{'WARNING.CONFIRMATION' | translate}}"
                 icon="pi pi-exclamation-triangle" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>

<!-- Toast -->
<p-toast key='subject-list' position="top-right" styleClass="customToast"></p-toast>

<div class="grid pb-2">
  <div class="col-12">
    <p-splitButton label="{{'SUBJECTS.NEW_PERSON' | translate}}" icon="far fa-user"
                   (onClick)="addSubject('PERSON')"
                   [model]="splitButton" class="p-button-raised"></p-splitButton>
  </div>
</div>

<div class="grid">

  <div class="col-12 xl:col-6">
    <p-tree
      [value]="subjects"
      [draggableNodes]="true"
      [droppableNodes]="true"
      draggableScope="self"
      droppableScope="self"
      [filter]="true"
      filterPlaceholder="{{'COMMON.SEARCH' | translate}}"
      selectionMode="single" [(selection)]="selectedSubject"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)"
      (onNodeExpand)="nodeExpand($event)"
      (onNodeDrop)="nodeDrop($event)"
      [loading]="loading">
    </p-tree>
  </div>

  <div class="xl:col-6">
<!--    <router-outlet></router-outlet>-->
  </div>
</div>

