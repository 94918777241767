import { Pipe, PipeTransform } from '@angular/core';
import {Asset} from '../model/asset';

@Pipe({
  name: 'assetFilter'
})
export class AssetFilterPipe implements PipeTransform {

  transform(assets: Asset[], search?: string): any {
    if ( !search){
      return assets;
    } else {
      return assets.filter(asset => asset.name.toLowerCase().includes(search.toLowerCase()));
    }
  }

}
