<div class="grid">
  <div class="col-8 flex flex-wrap align-content-center">
    <h2 style="margin-top: 10px">{{'EVENT.EXPOSURES_MAIN' | translate}}</h2>
  </div>
  <div class="col-4 flex justify-content-end">
    <button pButton pRipple (click)="addExposure()" type="button" icon="pi pi-plus"
            class="p-button-rounded"></button>
  </div>

  <div class="col-6" *ngIf="screenWidth(); else elseBlock">
    <span class="p-float-label">
      <input class="w-full" id="search" type="text" pInputText (input)="exposureFilter(getEventValue($event))">
      <label for="search" class="breakDots">{{'EVENT.SEARCH.ERE' | translate}}</label>
    </span>
  </div>
  <ng-template #elseBlock>
    <div class="col-6">
      <span class="p-float-label">
        <input class="w-full" id="search2" type="text" pInputText (input)="exposureFilter(getEventValue($event))">
        <label for="search2">{{'EVENT.SEARCH.ERE' | translate}}</label>
      </span>
    </div>
  </ng-template>
  <div class="col-6">
    <p-dropdown [options]="groupedFilter" [(ngModel)]="selectedStation" [style]="{'width':'100%'}"
                (onChange)="setStationFilter()" optionLabel="label" optionValue="value"></p-dropdown>
  </div>

  <div *ngFor="let exposure of exposures" class="col-12 sm:col-6 lg:col-4 xl:col-3">
    <p-card  styleClass="p-card-shadow box">
      <ng-template pTemplate="header">
        <div style="background-color: #16214D; min-height: 30px" class="banner">
          <div class="grid m-0" *ngIf="exposure.emergencyId; else elseBlock">
            <div class="col-2">
              <i (click)="op.show($event)" class="far fa-link" style="color: white"></i>
            </div>
            <div class="col-8 flex justify-content-center" style="color: white">
              {{'EVENT.EXPOSURE' | translate}}: {{exposure.exposureType}}
            </div>
          </div>
          <ng-template #elseBlock>
            <div class="grid m-0">
              <div class="col-offset-2 col-8 flex justify-content-center" style="color: white">
                {{'EVENT.EXPOSURE' | translate}}: {{exposure.exposureType}}
              </div>
            </div>
          </ng-template>
          <!-- OVERLAY -->
          <p-overlayPanel #op>
            <ng-template>
              {{'EVENT.EMERGENCY_LINK' | translate}}
            </ng-template>
          </p-overlayPanel>
        </div>
      </ng-template>
      <div class="grid">
        <div class="col-offset-1 col-5 ">
          {{'EVENT.BRIS' | translate}}:
        </div>
        <div class="col-6" *ngIf="exposure.brisNumber; else elseBlock">
          {{exposure.brisNumber}}
        </div>
        <ng-template #elseBlock>
          <div class="col-6"></div>
        </ng-template>
        <div class="col-offset-1 col-5">
          {{'COMMON.DATE.DATE' | translate}}:
        </div>
        <div class="col-5">
          {{exposure.date}}
        </div>
        <div class="col-offset-1 col-5">
          {{'EVENT.TIME_PERIOD' | translate}}:
        </div>
        <div class="col-5">
          {{exposure.timeStart}} - {{exposure.timeEnd}}
        </div>
        <div class="col-offset-1 col-5">
          {{'FDC.ADDRESS' | translate}}: </div>
        <div class="col-5 surface-overlay text pb-0 breakWord align-content-center">
          {{exposure.location.address}}
        </div>
        <div class="col-offset-1 col-5">
          {{'EVENT.NUMBER_OF_EXPOSURES' | translate}}
        </div>
        <div class="col-5">
          {{numberOfCrews(exposure.participants)}}
        </div>
      </div>
      <ng-template pTemplate="footer">
        <ng-container *ngIf="(isAdmin$ | async) && expired(exposure) && !exposure.emergencyId;
        then edit; else read"></ng-container>

        <ng-template #read>
          <div class="flex justify-content-center" >
            <button pButton pRipple (click)="moreInfo(exposure)" type="button" icon="pi pi-book"
                    class="p-button-rounded"></button>
          </div>
        </ng-template>

        <ng-template #edit>
          <div class="flex justify-content-center" >
            <button pButton pRipple (click)="editInfo(exposure)" type="button" icon="pi pi-cog"
                    class="p-button-rounded"></button>
          </div>
        </ng-template>
      </ng-template>
    </p-card>
  </div>
</div>

