import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveTasksComponent } from './active-tasks/active-tasks.component';
import {TableModule} from "primeng/table";
import {PipesModule} from "../../pipes/pipes.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from "primeng/dropdown";
import {MomentModule} from "ngx-moment";
import {InputTextModule} from "primeng/inputtext";
import {Route} from '@angular/router';
import { CompletedTasksComponent } from './completed-tasks/completed-tasks.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import { AddTaskComponent } from './add-task/add-task.component';
import {CalendarModule} from 'primeng/calendar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { ViewTaskComponent } from './view-task/view-task.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import { SendSmsComponent } from './send-sms/send-sms.component';
import { ViewCompletedComponent } from './view-completed/view-completed.component';
import {CheckboxModule} from 'primeng/checkbox';
import {InputNumberModule} from 'primeng/inputnumber';

export const TaskRoutes: Route = {
    path: 'task',
    children: [
        {path: 'active-tasks', component: ActiveTasksComponent},
        {path: 'completed-tasks', component: CompletedTasksComponent},
    ]
};

@NgModule({
  declarations: [ActiveTasksComponent, CompletedTasksComponent, AddTaskComponent,
      ViewTaskComponent, SendSmsComponent, ViewCompletedComponent],
    exports: [
        ActiveTasksComponent,
        ViewTaskComponent
    ],
    imports: [
        CommonModule,
        TableModule,
        PipesModule,
        TranslateModule,
        FormsModule,
        DropdownModule,
        MomentModule,
        InputTextModule,
        ButtonModule,
        RippleModule,
        CalendarModule,
        AutoCompleteModule,
        InputTextareaModule,
        ReactiveFormsModule,
        SplitButtonModule,
        ConfirmDialogModule,
        ToastModule,
        CheckboxModule,
        InputNumberModule
    ]
})
export class TaskModule { }
